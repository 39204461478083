import React from 'react';
import { Box, Group, Stack, Text } from '@mantine/core';
import { getString } from 'strings/translation';
import { FiEdit } from 'react-icons/fi';
import styles from './Mapbook.module.css';
import { NO_DATA } from 'constants/defaultValues';
import { NOT_ANALYZED, AWAITING_RESULTS, HIGH_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { RISK_FILL_COLORS, DARK_BLUE } from 'util/mapImageryColors';

type Props = {
  language: string;
  isModifyRecommendation: boolean;
  risk: string;
  toggleEditRecommendation: (value: boolean) => void;
  recommendationText?: string;
};

const MapbookRecommendations = ({
  language,
  isModifyRecommendation,
  risk,
  toggleEditRecommendation,
  recommendationText = '',
}: Props) => {
  return (
    <Stack gap="xs">
      <Group gap="xs">
        <Text fw={500}>{getString('recommendation', language)}:</Text>
        {isModifyRecommendation && (
          <FiEdit
            onClick={() =>
              toggleEditRecommendation(![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk))
            }
          />
        )}
      </Group>
      <Box
        className={styles.Recommendation}
        style={{
          color:
            risk === HIGH_RISK || risk === MODERATE_RISK ? RISK_FILL_COLORS.HIGH_RISK : DARK_BLUE,
        }}
        onClick={() =>
          isModifyRecommendation &&
          toggleEditRecommendation(![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk))
        }
      >
        <Text>{recommendationText}</Text>
      </Box>
    </Stack>
  );
};

export default MapbookRecommendations;
