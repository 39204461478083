import React from 'react';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Agency, ContractType } from 'store/agencies/types';

import { CreateContractModal } from './CreateContractModal';

type CreateContractPropsType = {
  contract?: ContractType;
  agency: Agency;
};

export const CreateContract = ({ contract, agency }: CreateContractPropsType) => {
  const language = useBroswerLanguage();
  const [opened, { open, close }] = useDisclosure(false);
  const modalTitle = contract ? 'editContract' : 'createNewContract';

  return (
    <>
      <Modal opened={opened} onClose={close} size="90%" title={getString(modalTitle, language)}>
        <CreateContractModal contract={contract} agency={agency} onClose={close} />
      </Modal>
      <Button variant="outline" onClick={open}>
        {getString(modalTitle, language)}
      </Button>
    </>
  );
};
