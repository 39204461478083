import React from 'react';
import { ApprovedPaymentsType } from 'store/payments/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import ActionDialog from '../common/Dialog/ActionDialog';

type SettlementControllerPropsType = {
  firstName: string;
  lastName: string;
  type: ApprovedPaymentsType['payment_type'];
  amount: number;
  onSubmit: () => void;
  onClose: () => void;
};

const SettlementController = ({
  firstName,
  lastName,
  type,
  amount,
  onSubmit,
  onClose,
}: SettlementControllerPropsType) => {
  const language = useBroswerLanguage();
  return (
    <ActionDialog
      actionTitle={getString('markSettled', language)}
      name={`${firstName} ${lastName}`}
      type={type}
      amount={amount}
      description={getString('markSettledMsg', language)}
      buttonName={getString('markRecorded', language)}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default SettlementController;
