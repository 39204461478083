import React from 'react';
import { OperationDetailsType } from 'store/salesFlow/types';
import { getString } from 'strings/translation';
import { Label, Selector, InputWithUnits } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getUnitBuAc, getAcreageUnitFromLang, getUnitDollarBu } from 'util/units';
import { SelectType } from 'util/generalTypes';
import { readyToSampleOptions } from 'constants/samplePlanning';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CORN } from 'constants/variables';
import SlideStyles from '../Shared.module.css';
import Styles from './Container.module.css';

interface OperationDetailsPropTypes {
  cropText: string;
  operationDetails: OperationDetailsType;
  updateOperationDetails: (crop: string, key: string, value: SelectType | string | boolean) => void;
  icon: React.ReactNode;
  crop: string;
}

const CropInfo = ({
  cropText,
  operationDetails,
  updateOperationDetails,
  icon,
  crop,
}: OperationDetailsPropTypes) => {
  const language = useBroswerLanguage();

  const { salesFlowMeta } = useSelector((state: RootState) => ({
    salesFlowMeta: state.salesFlow.meta,
  }));
  const traits = salesFlowMeta?.[crop].traits;
  const seedRisks = salesFlowMeta?.[crop].seed_disease_risks;
  const inFurrows = salesFlowMeta?.[crop].in_furrow;
  const seedTreatments = salesFlowMeta?.[crop].seed_treatment;

  if (!salesFlowMeta) return null;

  return (
    <div className={Styles.Section}>
      <div className={Styles.CropRow}>
        <div className={Styles.CropIconText}>
          {icon}
          <div className={Styles.IconText}>
            <b>{cropText}</b>
          </div>
        </div>
        <InputWithUnits
          type="number"
          className={SlideStyles.InputShort}
          placeholder={getString('acres', language)}
          onChange={(e) => updateOperationDetails(crop, 'acreage', e.target.value)}
          value={String(operationDetails[crop].acreage)}
          units={getAcreageUnitFromLang(language)}
        />
        <InputWithUnits
          type="number"
          className={SlideStyles.InputShort}
          placeholder={getString('yield', language)}
          onChange={(e) => updateOperationDetails(crop, 'target_yield', e.target.value)}
          value={String(operationDetails[crop].target_yield)}
          units={getUnitBuAc('', language)}
        />
        <InputWithUnits
          type="number"
          className={SlideStyles.InputShort}
          placeholder={getString('price', language)}
          onChange={(e) => updateOperationDetails(crop, 'price_per_bushel', e.target.value)}
          value={String(operationDetails[crop].price_per_bushel)}
          units={getUnitDollarBu('', language)}
        />
      </div>
      <div className={Styles.CropRow}>
        {crop === CORN && (
          <Label className={Styles.Label} label={getString('traits', language)}>
            <Selector
              className={Styles.SelectorLabel}
              onChange={(idx) => updateOperationDetails(crop, 'traits', traits[idx].value)}
              activeIndex={traits.findIndex(
                (trait) => operationDetails[crop].traits === trait.value,
              )}
              options={traits}
            />
          </Label>
        )}
        <Label className={Styles.Label} label={getString('seedTreatment', language)}>
          <Selector
            className={Styles.SelectorLabel}
            onChange={(idx) =>
              updateOperationDetails(crop, 'seed_treatment', seedTreatments[idx].value)
            }
            activeIndex={seedTreatments.findIndex(
              (seedTreat) => operationDetails[crop].seed_treatment === seedTreat.value,
            )}
            options={seedTreatments}
          />
        </Label>
        <Label className={Styles.Label} label={getString('inFurrow', language)}>
          <Selector
            className={Styles.SelectorLabel}
            onChange={(idx) => updateOperationDetails(crop, 'in_furrow', inFurrows[idx].value)}
            activeIndex={inFurrows.findIndex(
              (furrow) => operationDetails[crop].in_furrow === furrow.value,
            )}
            options={inFurrows}
          />
        </Label>
        <Label className={Styles.Label} label={getString('foliarFungicide', language)}>
          <Selector
            className={Styles.SelectorLabel}
            onChange={(idx) =>
              updateOperationDetails(crop, 'foliar_fungicide', readyToSampleOptions[idx].value)
            }
            activeIndex={readyToSampleOptions.findIndex(
              (option) => operationDetails[crop].foliar_fungicide === option.value,
            )}
            options={readyToSampleOptions}
          />
        </Label>
        <Label className={Styles.Label} label={getString('seedRisks', language)}>
          <Selector
            className={Styles.SelectorLabel}
            onChange={(idx) =>
              updateOperationDetails(crop, 'seed_disease_risk', seedRisks[idx].value)
            }
            activeIndex={seedRisks.findIndex(
              (risk) => operationDetails[crop].seed_disease_risk === risk.value,
            )}
            options={seedRisks}
          />
        </Label>
      </div>
    </div>
  );
};

export default CropInfo;
