import { handleJsonResponse, requestPost, SERVICE_URL } from 'util/request';

export const requestLogin = async (body) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/login`, { body }));

export const requestLogout = async () =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/logout`));

export const requestPasswordChange = (body) =>
  requestPost(`${SERVICE_URL}/password`, { body }).then(handleJsonResponse);

export const refreshToken = async () =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/refresh`));

export const sendResetEmail = (body) =>
  requestPost(`${SERVICE_URL}/reset_password_link`, { body }).then(handleJsonResponse);

export const resetForgottenPassword = (body) =>
  requestPost(`${SERVICE_URL}/reset_password`, { body }).then(handleJsonResponse);
