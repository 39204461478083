import { AppThunk } from 'store';
import showToast from 'actions/toastActions';
import { requestConversion, receiveConversion, conversionRequestError } from './actions';
import { getConversion } from './requests';

export const getRealsToDollars = (): AppThunk => async (dispatch) => {
  dispatch(requestConversion);
  try {
    const response = await getConversion();
    dispatch(receiveConversion(response));
  } catch (error) {
    showToast(error.message, 'error');
    dispatch(conversionRequestError);
  }
};
