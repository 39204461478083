import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Group, Input, Space, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useElementSize } from '@mantine/hooks';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import {
  Agency,
  ContractPriceBucketType,
  ContractSubmitType,
  ContractType,
} from 'store/agencies/types';
import {
  createContract,
  deleteAgencyFromContract,
  deleteContract,
  postAgencyToContract,
  updateContract,
} from 'store/agencies/requests';
import { getAgencyContracts } from 'store/agencies/thunks';
import {
  AGENCY,
  contractDensities,
  CONTRACT_10_AC,
  LIST,
  MSRP,
  contractEditableProductsMap,
} from 'constants/products';
import {
  checkProductPriceRequirements,
  getProductPriceDisplay,
  removeUnusedPriceBucketKeys,
} from 'util/pricing';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import { capitalize } from 'util/stringUtils';
import showToast from 'actions/toastActions';

import { ParentContractSelect } from './FormInputs/ParentContractSelect';
import { ContractTypeSelect } from './FormInputs/ContractTypeSelect';

type CreateContractPropsType = {
  contract?: ContractType;
  agency: Agency;
  onClose?: () => void;
};

export const CreateContractModal = ({ contract, agency, onClose }: CreateContractPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { ref, height } = useElementSize();
  const productIndices = contractEditableProductsMap[AGENCY].map((_, pindex) => pindex);

  const { min_max_prices } = useSelector((state: RootState) => ({
    min_max_prices: state.agencies.min_max_prices,
  }));

  const [contractForm, setContractForm] = useState<Partial<ContractType>>(contract || {});
  const [savingContract, toggleSavingContract] = useState(false);
  const [endingContract, toggleEndingContract] = useState(false);

  const handleOnValueChange = (
    attributeKey: keyof ContractType,
    newValue: string | string[] | { [key: string]: any } | number,
  ) => {
    setContractForm((form) => ({ ...form, [attributeKey]: newValue }) as ContractType);
  };

  const handlePriceBucketChange = (
    attributeKey: keyof ContractPriceBucketType,
    newValue: number,
    density?: number,
  ) => {
    setContractForm((currentForm) => {
      const newBuckets = { ...currentForm.price_buckets };
      (density ? [density] : contractDensities).forEach((d) => {
        newBuckets[d] = {
          ...(currentForm.price_buckets?.[d] || ({} as ContractPriceBucketType)),
          [attributeKey]: newValue,
        };
      });
      return {
        ...currentForm,
        price_buckets: newBuckets,
      };
    });
  };

  const editPriceBucketComponent = (pindex: number, density?: number) => (
    <>
      {[MSRP, AGENCY, LIST].map((type) => {
        const editableProduct = contractEditableProductsMap[type][pindex];
        const { priceType, label } = getProductPriceDisplay(editableProduct, type, language);
        const priceTypeValue = contractForm.price_buckets?.[density || CONTRACT_10_AC]?.[priceType];
        return (
          <Input.Wrapper size="xs" label={label} key={type} pos="relative">
            {type === LIST && pindex === productIndices[productIndices.length - 1] && (
              <Divider
                pos="absolute"
                h={height}
                left="-0.5rem"
                bottom="0"
                size="sm"
                color="blue"
                variant="dotted"
                orientation="vertical"
              />
            )}
            <Input
              type="number"
              onChange={(e) =>
                e.target.value &&
                handlePriceBucketChange(priceType, Number(e.target.value), density)
              }
              value={String(priceTypeValue)}
              disabled={contractForm?.is_not_editable}
            />
          </Input.Wrapper>
        );
      })}
    </>
  );

  const saveContract = async () => {
    if (contractForm) {
      const { signed_at, expired_at, price_buckets } = contractForm;

      if (signed_at && expired_at && price_buckets) {
        const { complete_bio_list, pressure_panel_list, pattern_360_list } =
          price_buckets[CONTRACT_10_AC];
        if (complete_bio_list && pressure_panel_list && pattern_360_list) {
          try {
            toggleSavingContract(true);
            const productPriceError = checkProductPriceRequirements(
              contractForm as ContractType,
              min_max_prices,
              language,
            );
            if (productPriceError) {
              throw Error(productPriceError);
            }

            const submissionForm = {
              ...removeUnusedPriceBucketKeys(contractForm as ContractType),
              agency_id: agency.id,
              signed_at: new Date(signed_at),
              expired_at: new Date(expired_at),
            } as ContractSubmitType;

            if (submissionForm.parent_contract_id) {
              await postAgencyToContract(agency.id, submissionForm.parent_contract_id);
            } else if (contractForm?.id) {
              await updateContract(submissionForm);
            } else {
              await createContract(submissionForm);
            }
            dispatch(getAgencyContracts(agency.id));
            showToast(getString('contractUpdatedSuccessMsg', language), 'success');
          } catch (e) {
            // Error message coming from backend
            showToast(e.message, 'error', 7000);
          } finally {
            onClose && onClose();
          }
        }
      }
    }
  };

  const endContract = async () => {
    try {
      if (contractForm?.id) {
        toggleSavingContract(true);
        if (contractForm.agency_id !== agency.id) {
          await deleteAgencyFromContract(agency.id, contractForm.id);
        } else {
          await deleteContract(contractForm.id);
        }
        showToast((getString('contractEndedSuccessMsg', language), 'success'));
      }
    } catch (e) {
      showToast(e.message, 'error', 7000);
    } finally {
      dispatch(getAgencyContracts(agency.id));
      onClose && onClose();
    }
  };

  const missingFields = !contractForm.expired_at || !contractForm.signed_at;

  return (
    <Stack>
      <Group grow>
        <ParentContractSelect
          agency={agency}
          contract={contractForm}
          setContractForm={setContractForm}
        />
        <Space />
        <Space />
        <Space />
      </Group>
      <Group align="flex-end" grow>
        <ContractTypeSelect
          contract={contractForm}
          setContractForm={setContractForm}
          agencyId={agency.id}
        />
        <DatePickerInput
          label={getString('signedAt', language)}
          value={contractForm.signed_at ? new Date(contractForm.signed_at) : undefined}
          onChange={(date) => handleOnValueChange('signed_at', String(date))}
          disabled={contractForm?.is_not_editable}
          w="100%"
        />
        <DatePickerInput
          label={getString('expiredOn', language)}
          value={contractForm.expired_at ? new Date(contractForm.expired_at) : undefined}
          onChange={(date) => handleOnValueChange('expired_at', String(date))}
          disabled={contractForm?.is_not_editable}
          w="100%"
        />
        <Input.Wrapper label={getString('committed_samples', language)}>
          <Input
            type="number"
            onChange={(e) =>
              e.target.value && handleOnValueChange('committed_samples', Number(e.target.value))
            }
            value={String(contractForm?.committed_samples)}
            disabled={contractForm?.is_not_editable}
          />
        </Input.Wrapper>
      </Group>
      <Group align="flex-end" grow>
        {contractDensities.map((density: number, dindex: number) => {
          return (
            <Stack key={density} pos="relative">
              <Text ta="center" fw="bold">
                {`${acToHectares(Number(density), language)} ${getAcreageUnitFromLang(language)} ${capitalize(getString('density', language))}`}
              </Text>
              <Stack ref={ref}>
                {productIndices.map((pindex: number) => {
                  return (
                    <Group key={pindex} pos="relative" align="flex-end" wrap="nowrap" grow>
                      {editPriceBucketComponent(pindex, density)}
                    </Group>
                  );
                })}
              </Stack>
              {dindex === 0 && (
                <Divider
                  pos="absolute"
                  h="100%"
                  right="-0.6rem"
                  size="sm"
                  color="blue"
                  variant="solid"
                  orientation="vertical"
                />
              )}
            </Stack>
          );
        })}
      </Group>
      <Space />
      <Group justify="space-between">
        <Group>
          {contractForm.id && !endingContract && (
            <Button color="darkRed" onClick={() => toggleEndingContract(true)}>
              {getString('endContract', language)}
            </Button>
          )}
          {contractForm.id && endingContract && (
            <>
              <Text>{getString('areYouSure', language)}?</Text>
              <Group gap="xl">
                <Button variant="filled" onClick={endContract} color="darkRed">
                  {getString('endContract', language)}
                </Button>
                <Button onClick={() => toggleEndingContract(false)}>
                  {getString('doNotEnd', language)}
                </Button>
              </Group>
            </>
          )}
        </Group>
        <Group justify="flex-end" gap="xl">
          <Button
            disabled={missingFields || savingContract}
            loading={savingContract}
            onClick={saveContract}
          >
            {getString('save', language)}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};
