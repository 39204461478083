import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import Styles from './Container.module.css';

interface BarChartProps {
  barColor: string;
  chartData: { name: string; value: number; label: string; dollar: string }[];
  domainRange: number[];
}

const ReportChart = ({ barColor, chartData, domainRange }: BarChartProps) => {
  return (
    <div className={Styles.Chart}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 20,
          }}
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tickFormatter={() => ''}
            scale="point"
            padding={{ left: 10, right: 40 }}
          />
          <YAxis domain={domainRange} tick={false} axisLine={false} tickLine={false} />
          <Bar barSize={50} dataKey="value" fill={barColor} radius={[5, 5, 0, 0]}>
            <LabelList dataKey="label" position="bottom" />
            <LabelList dataKey="dollar" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportChart;
