import React from 'react';

import { Dialog } from 'common';

import PaymentForm from './ActionForm';
import styles from './ActionDialog.module.css';

type ActionDialogPropsType = {
  actionTitle: string;
  name: string | null;
  type: string | null;
  amount: number | null;
  description: string;
  buttonName: string;
  onSubmit: (term?: { displayName: string; id: number }) => void;
  onClose: () => void;
  selectorOptions?: { displayName: string; id: number }[];
  secondButtonName?: string;
};

const ActionDialog = ({
  actionTitle,
  name,
  type,
  amount,
  description,
  buttonName,
  onSubmit,
  onClose,
  selectorOptions,
  secondButtonName,
}: ActionDialogPropsType) => {
  return (
    <Dialog className={styles.AdjustmentDialog} onClose={onClose}>
      <div className={styles.InternalContainer}>
        <PaymentForm
          actionTitle={actionTitle}
          name={name}
          type={type}
          amount={amount}
          description={description}
          buttonName={buttonName}
          onSubmit={onSubmit}
          onClose={onClose}
          selectorOptions={selectorOptions}
          secondButtonName={secondButtonName}
        />
      </div>
    </Dialog>
  );
};

export default ActionDialog;
