import React from 'react';
import { useSelector } from 'react-redux';
import { user as userSelector } from 'store/user/selectors';
import { UserRole } from 'store/user/types';
import { getUserRoleDisplayName } from 'util/userRoles';

import WelcomeAgent from './WelcomeAgent';
import WelcomeOperator from './WelcomeOperator';

const Container = () => {
  const user = useSelector(userSelector);
  if (
    user &&
    (getUserRoleDisplayName(user) === UserRole.Agent ||
      getUserRoleDisplayName(user) === UserRole.AgencyAdmin)
  ) {
    return <WelcomeAgent />;
  }

  return <WelcomeOperator />;
};

export default Container;
