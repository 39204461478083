import React, { useState } from 'react';
import { Button } from '@mantine/core';
import showToast from 'actions/toastActions';
import { LAST_SCANNED_BARCODE } from 'constants/recieving';
import { LabSampleType } from 'store/labSamples/types';
import { requestQRCode } from 'store/samples/requests';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getLocalStorage, setLocalStorage } from 'util/localStorage';
import { printSampleBarcode } from 'util/printFormat';

import { DoubleSamplePrintingModal } from './DoubleSamplePrintingModal';

type SampleQRCodePropsType = {
  sample: LabSampleType;
};

export const SampleQRCode = ({ sample }: SampleQRCodePropsType) => {
  const language = useBroswerLanguage();
  const [showModal, setShowModal] = useState(false);

  const handlePrint = async () => {
    try {
      const response = await requestQRCode(sample.barcode);
      const qrCodeUrl = response.url;
      const printWindow = window.open('', '_blank');
      const qrCodeImage = new Image();
      qrCodeImage.src = qrCodeUrl;
      qrCodeImage.onload = () => {
        printWindow?.document.open();
        printWindow?.document.write(printSampleBarcode(sample.barcode, qrCodeUrl));
        printWindow?.document.close();
      };
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  const handlePrintWithAlert = async () => {
    try {
      if (getLocalStorage(LAST_SCANNED_BARCODE) === sample.barcode) {
        setShowModal(true);
      } else {
        handlePrint();
        setLocalStorage(LAST_SCANNED_BARCODE, sample.barcode);
      }
    } catch (error) {
      showToast(error.message, 'error');
    }
  };

  return (
    <>
      <Button variant="outline" onClick={handlePrintWithAlert}>
        {getString('printQRCode', language)}
      </Button>
      {showModal && (
        <DoubleSamplePrintingModal handlePrint={handlePrint} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};
