export type NaptControlType = {
  id: number;
  control_code: string;
  updated_at: string;
  created_at: string;
  p: number;
  b: number;
  fe: number;
  mg: number;
  cu: number;
  ca: number;
  zn: number;
  k: number;
  s: number;
  na: number;
  ph: number;
  bph: number;
  n: number;
};

export type NaptControlsStateType = {
  naptControls: NaptControlType[];
  hasFetched: boolean;
  isFetching: boolean;
  hasFailed: boolean;
};

export const REQUEST_NAPT_CONTROLS = 'REQUEST_NAPT_CONTROLS';
export const RECEIVE_NAPT_CONTROLS = 'RECEIVE_NAPT_CONTROLS';
export const NAPT_CONTROLS_REQUEST_ERROR = 'NAPT_CONTROLS_REQUEST_ERROR';

type RequestNaptControlsActionType = {
  type: typeof REQUEST_NAPT_CONTROLS;
};

type ReceiveNaptControlsActionType = {
  type: typeof RECEIVE_NAPT_CONTROLS;
  payload: {
    controls: NaptControlType[];
  };
};

type NaptControlRequestErrorType = {
  type: typeof NAPT_CONTROLS_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type NaptControlsActionType =
  | RequestNaptControlsActionType
  | ReceiveNaptControlsActionType
  | NaptControlRequestErrorType;
