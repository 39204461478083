import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import center from '@turf/center';
import { v4 as uuid } from 'uuid';

import { BRAZIL_VIEWPORT, US_MIDWEST_VIEWPORT } from 'constants/mapViewport';
import { Button } from 'common';
import { ENGLISH } from 'constants/languages';
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  MultiPolygon,
  Polygon,
  Position,
} from 'geojson';
import { getString } from 'strings/translation';
import DrawingTools from 'apps/Orders/FieldBoundary/DrawingTools';
import { MODES } from 'constants/mapbox';
import { multiPolygon, polygon } from '@turf/helpers';
import showToast from 'actions/toastActions';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useMapboxGl from 'common/MapHooks';
import { User } from 'store/user/types';

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import styles from './Container.module.css';

interface TerritoryMapProps {
  hideModal: (event: MouseEvent) => void;
  submit: (geom: Feature<MultiPolygon>) => void;
  user: User;
  userTerritory: Feature<MultiPolygon | null>;
}

const TerritoryMap = ({ hideModal, submit, user, userTerritory }: TerritoryMapProps) => {
  const drawRef = useRef<any>(null);
  const mapRef = useRef<mapboxgl.Map>(null);
  const mapContainerRef = useRef(null);

  const language = useBroswerLanguage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const localeViewport = user.user_locale === ENGLISH ? US_MIDWEST_VIEWPORT : BRAZIL_VIEWPORT;
  const [viewport, setViewport] = useState(localeViewport);
  const [mode, setMode] = useState(MODES.SELECT);

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, setMode, true, true);

  const addTerritoryToMap = (territory: Feature<MultiPolygon | null>) => {
    const { geometry } = territory;
    if (geometry) {
      geometry.coordinates.forEach((geom: Position[][]) =>
        drawRef.current.add(
          polygon(geom, {
            id: uuid(),
            renderType: 'Polygon',
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (userTerritory.geometry !== null && mapRef.current) {
      // get initial viewport and display on map
      const bbox = turfBbox(userTerritory);
      const bboxPoly = turfBboxPolygon(bbox);
      const [longitude, latitude] = center(bboxPoly).geometry.coordinates;
      // @ts-ignore
      mapRef.current.fitBounds(bbox, { duration: 0, padding: 150 });
      const zoom = mapRef.current.getZoom();
      setViewport({
        latitude,
        longitude,
        zoom,
      });

      addTerritoryToMap(userTerritory);
    }
  }, []);

  const drawModeSetter = (drawType: string) => () => {
    setMode(drawType);
    drawRef.current.changeMode(drawType);
  };

  const handleDelete = () => {
    const selectedIds = drawRef.current.getSelectedIds();
    drawRef.current.delete(selectedIds);
  };

  const polygonCoordinates = (() => {
    const polygons: FeatureCollection<Polygon, GeoJsonProperties> | undefined =
      drawRef.current?.getAll();
    if (!polygons) {
      return [];
    }
    const polyFeatures = polygons.features.filter((feature) => {
      // drawing tools insert a [[null]] coordinate polygon when you enter draw mode
      const nonNullCoords = feature.geometry.coordinates.filter((coords) => coords !== null);
      return nonNullCoords.length && feature.geometry.type === 'Polygon';
    });
    return polyFeatures.map((feature) => feature.geometry.coordinates);
  })();

  const submitMap = async () => {
    try {
      setIsSubmitting(true);
      if (
        polygonCoordinates.length &&
        userTerritory.properties?.users.length &&
        userTerritory.properties?.name
      ) {
        const payload = multiPolygon(polygonCoordinates);
        await submit(payload);
      } else {
        showToast(getString('missingInfoMsg', language), 'error');
      }
    } catch (error) {
      showToast(getString('requestFailed', language));
    }
    setIsSubmitting(false);
  };

  return (
    <div className={styles.MapWrapper}>
      <div className={styles.MapActions}>
        <Button primary className={styles.MapSubmit} disabled={isSubmitting} onClick={submitMap}>
          {getString('submit', language)}
        </Button>
        <Button onClick={hideModal}>{getString('cancel', language)}</Button>
      </div>
      <div className={styles.Map}>
        <div ref={mapContainerRef} className={styles.Map} />
        <DrawingTools
          drawModeSetter={drawModeSetter}
          handleDelete={handleDelete}
          mode={mode}
          language={language}
        />
      </div>
    </div>
  );
};

export default TerritoryMap;
