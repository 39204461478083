import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { PlateType } from 'store/plates/types';
import { PlatesList } from './PlateList/PlateList';

type ParentPlatesPropsType = {
  plate: PlateType;
};

export const ParentPlates = ({ plate }: ParentPlatesPropsType) => {
  const language = useBroswerLanguage();
  const title = getString('parentPlates', language);
  return <PlatesList plates={plate.related_plates?.parents_plates} title={title} />;
};
