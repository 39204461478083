import { RootState } from 'store';
import {
  RECEIVE_ROLES,
  ROLE_REQUEST_ERROR,
  RoleType,
  RoleActionTypes,
  REQUEST_ROLES,
} from 'store/roles/types';

export const receiveRoles = (payload: RoleType[]): RoleActionTypes => ({
  type: RECEIVE_ROLES,
  payload,
});

export const requestRoles = { type: REQUEST_ROLES };

export const roleRequestError = { type: ROLE_REQUEST_ERROR };

export const selectRoles = (state: RootState) => state.roles.roles;
