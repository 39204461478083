import React, { ChangeEvent, useEffect, useState } from 'react';

import { Button, Input, Label, LegendIcon } from 'common';

import styles from './Legend.module.css';

export type LegendDataType = {
  maxMin?: {
    max: number;
    min: number;
  };
  rows: { color: string; label: string }[];
  title?: string;
  unit?: string;
};

export interface LegendProps {
  data?: LegendDataType;
  isEditable?: boolean;
  setUserSelectedValues?: (values: any) => void;
}

const Legend = ({ data, isEditable, setUserSelectedValues }: LegendProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLegendVisible, setIsLegendVisible] = useState(true);
  const [localMax, setLocalMax] = useState<number | string>();
  const [localMin, setLocalMin] = useState<number | string>();

  useEffect(() => {
    if (data && data.maxMin) {
      setLocalMax(data.maxMin.max);
      setLocalMin(data.maxMin.min);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const handleBodyClick = () => !isEditing && setIsLegendVisible(false);
  const isFormValid = () => {
    const numericMax = Number(localMax);
    const numericMin = Number(localMin);

    if (!numericMax || !numericMin) {
      return false;
    }
    return numericMin >= 1 && numericMax > numericMin;
  };

  const submit = () => {
    if (setUserSelectedValues) {
      setUserSelectedValues({ max: Number(localMax), min: Number(localMin) });
    }
    setIsEditing(false);
  };

  const legendBody = isEditing ? (
    <>
      <Label label="Max">
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLocalMax(e.target.value)}
          value={localMax?.toString()}
        />
      </Label>
      <Label label="Min">
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLocalMin(e.target.value)}
          value={localMin?.toString()}
        />
      </Label>
      <div className={styles.EditConfirmationButtons}>
        <Button
          onClick={() => {
            setIsEditing(false);
            setLocalMax(data.maxMin?.max);
            setLocalMin(data.maxMin?.min);
          }}
        >
          Cancel
        </Button>
        <Button disabled={!isFormValid()} onClick={submit} primary>
          Save
        </Button>
      </div>
    </>
  ) : (
    <>
      {data.title?.replace(' ', '\n')}
      {data.maxMin && isEditable && (
        <Button
          className={styles.EditButton}
          primary
          onClick={(e: any) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          Edit
        </Button>
      )}
      {data.unit && <span className={styles.Unit}>{data.unit}</span>}
      {data.rows.map((item) => (
        <span key={`${item.color}${item.label}`}>
          <div className={styles.LegendDot} style={{ backgroundColor: item.color }} />
          {item.label}
        </span>
      ))}
    </>
  );

  return (
    <div className={styles.Wrapper}>
      {!isLegendVisible && (
        <Button circle onClick={() => setIsLegendVisible(!isLegendVisible)}>
          <LegendIcon className={styles.Icon} />
        </Button>
      )}
      {isLegendVisible && (
        <div className={styles.Legend} onClick={handleBodyClick}>
          {legendBody}
        </div>
      )}
    </div>
  );
};

export default Legend;
