import {
  RECEIVE_SAMPLING_HISTORY,
  REQUEST_SAMPLING_HISTORY,
  SamplingDateType,
  SamplingHistoryActionsType,
  SAMPLING_HISTORY_REQUEST_ERROR,
} from './types';

export const requestSamplingHistory = (): SamplingHistoryActionsType => ({
  type: REQUEST_SAMPLING_HISTORY,
});

export const receiveSamplingHistory = (payload: {
  page: number;
  total: number;
  per_page: number;
  items: SamplingDateType[];
}): SamplingHistoryActionsType => {
  return {
    type: RECEIVE_SAMPLING_HISTORY,
    payload,
  };
};

export const samplingHistoryRequestError = (payload: {
  message: string;
}): SamplingHistoryActionsType => ({
  type: SAMPLING_HISTORY_REQUEST_ERROR,
  payload,
});
