import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Select, SelectProps } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { getPlateTypeOptions } from 'util/plates';
import { creationPlateTypes } from 'constants/plates';
import { PlateType } from 'store/plates/types';

type PlateTypeSelectorPropsType = SelectProps & {
  plateType?: string;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
    clearForm: boolean,
  ) => void;
};

export const PlateTypeSelector = ({
  plateType,
  onValueChange,
  ...props
}: PlateTypeSelectorPropsType) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const plateTypeOptions = getPlateTypeOptions(language, creationPlateTypes);

  useEffect(() => {
    if (plateType) {
      onValueChange('plate_type', plateType, true);
    }
  }, [plateType]);

  const handleOnChange = (value: string | null) => {
    if (value) {
      navigate(`/lab/plates/create/${value}`, {
        state: location.state,
      });
    }
  };

  return (
    <Select
      onChange={handleOnChange}
      data={plateTypeOptions}
      value={plateType}
      label={getString('plateType', language)}
      {...props}
    />
  );
};
