import { Agency } from 'store/agencies/types';
import { OperationType } from 'store/operation/types';

const traverseChildren = (agency: Agency, agencyList: Agency[] = []) => {
  agencyList.push(agency);
  if (agency.child_agencies?.length) {
    for (const child_agency of agency.child_agencies) {
      traverseChildren(child_agency, agencyList);
    }
  }
  return agencyList;
};

export const flattenAgencyHeirarchy = (parentAgency: Agency) => traverseChildren(parentAgency);

export const agenciesAllowedForSamplePlan = (
  operation: OperationType,
  operationAgencies: Agency[],
) => {
  const billingAgency = operationAgencies?.find(
    (agency) => agency.id === operation.billing_agency_id,
  );
  if (billingAgency) {
    const agenciesInHierarchy = flattenAgencyHeirarchy(billingAgency).map((agency) => agency.id);
    return operationAgencies.filter((agency) => agenciesInHierarchy.includes(agency.id));
  }
  return [];
};
