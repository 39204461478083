import React from 'react';
import { FieldType, SamplingPlanType } from 'store/fields/types';
import { AnalyticType } from 'store/analytics/types';
import { NO_DATA, lowModHighRiskLevels } from 'constants/fieldRisks';
import { Paper, Box, Text, Center } from '@mantine/core';
import { getOverviewResultsConfig } from 'util/overviewResultsDisplay';
import styles from './AnalyticChart.module.css';
import { COMPACTION, COMPACTION_ID } from 'constants/results';

type AnalysisRowPropsType = {
  field: FieldType;
  activeAnalytic: AnalyticType;
  samplingPlan?: SamplingPlanType;
  openChartModal: (
    field: FieldType,
    analytic: AnalyticType | null,
    samplingPlan: SamplingPlanType | undefined,
    risk: string,
    isFoliarWithResults: boolean | undefined,
  ) => void;
  isPrinting?: boolean;
  allCategoryAnalytics: AnalyticType[];
};

const AnalyticChart = ({
  field,
  samplingPlan,
  openChartModal,
  activeAnalytic,
  isPrinting,
  allCategoryAnalytics,
}: AnalysisRowPropsType) => {
  const {
    risk,
    analyticValue,
    overviewBarValue,
    overviewBackgroundColor,
    isNDAndZero,
    isNDWithValue,
    overviewBoxStyle,
    overviewShowLevels,
  } = getOverviewResultsConfig(activeAnalytic, samplingPlan);

  const getBarHeightPercent = (barValue: number) => {
    if (barValue === 0) {
      return '1%';
    }
    return `${(100 * barValue) / 3}%`;
  };

  const handleOpenChart = () => {
    const chartAnalytic =
      activeAnalytic.category === COMPACTION
        ? allCategoryAnalytics.find((analytic) => analytic.id === COMPACTION_ID)
        : activeAnalytic;
    openChartModal(field, chartAnalytic || activeAnalytic, samplingPlan, risk, isNDWithValue);
  };

  return (
    <div data-test-id="single-analytic-box" className={styles.SingleAnalytic}>
      {overviewShowLevels ? (
        <div className={styles.SquareWrapper}>
          <Paper
            shadow={isPrinting ? 'none' : 'sm'}
            withBorder
            onClick={handleOpenChart}
            className={styles.PaperContainer}
          >
            <div className={styles.Line} />
            <div className={styles.Line} />
            <Box
              className={`${styles.Box} ${styles.TopBox}`}
              data-test-id="analytic-slim-box"
              style={{
                height: getBarHeightPercent(overviewBarValue),
                backgroundColor: overviewBackgroundColor,
                borderBottomLeftRadius: overviewBarValue === 3 ? 3 : 0,
                borderBottomRightRadius: overviewBarValue === 3 ? 3 : 0,
              }}
            />
          </Paper>
          {isNDAndZero && <div className={styles.NDOverlay}>ND</div>}
        </div>
      ) : (
        <Paper
          shadow="sm"
          withBorder
          onClick={() => {
            if (lowModHighRiskLevels.includes(risk)) {
              openChartModal(field, activeAnalytic, samplingPlan, risk, isNDWithValue);
            }
          }}
        >
          <Box className={`${styles.ValueBox} ${overviewBoxStyle}`}>
            <Center h="3rem">
              <Text size="xs">{risk === NO_DATA ? '' : analyticValue?.quantity}</Text>
            </Center>
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default AnalyticChart;
