import { naptElements } from './controls';

export const GENERIC = 'generic';

export const RESULTS = 'results';

export const IndexSetFileColumns = [
  'name',
  'Well',
  'I7_index_ID',
  'index',
  'rc_index',
  'I5_Index_ID',
  'index2',
  'rc_index2',
];

export const NaptFileColumns = ['NAPT Samples', ...naptElements];
