import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Header, TabbedController, PlateSearchBar } from 'common';
import {
  chemistryExtractionList,
  GENOMIC,
  genomicAnalysisList,
  M3,
  NUTRIENT,
} from 'constants/analysis';
import { batchStatusList, chemistryBatchTypes, genomicBatchTypes } from 'constants/batches';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import {
  basicPlateStatusList,
  chemistryPlateTypes,
  genomicPlateTypes,
  HOMOGENIZATION,
  M3_ANALYSIS,
  plateStatusList,
} from 'constants/plates';

import { PlatesOverview } from '../Plates/Overview/PlateOverview';
import { BatchOverview } from '../Batches/Overview/BatchOverview';
import styles from './Container.module.css';

const PLATES = 'plates';
const BATCHES = 'batches';

const ProcessingOverviewContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const { analysis, view } = useParams<{
    analysis: 'genomic' | 'nutrient';
    view: 'plates' | 'batches';
  }>();
  const [searchString, setSearchString] = useState<string>();

  useEffect(() => {
    if (!analysis || ![GENOMIC, NUTRIENT].includes(analysis)) {
      navigate(`/lab/${GENOMIC}/${PLATES}`);
    } else if (!view || ![PLATES, BATCHES].includes(view)) {
      navigate(`/lab/${analysis}/${PLATES}`);
    }
  }, [analysis, view]);

  const viewOptions = [
    {
      id: 1,
      displayName: getString(PLATES, language),
      value: PLATES,
    },
    {
      id: 2,
      displayName: getString(BATCHES, language),
      value: BATCHES,
    },
  ];

  const getTitle = () => {
    if (analysis === GENOMIC) {
      return getString('genomicProcessing', language);
    }
    if (analysis === NUTRIENT) {
      return getString('nutrientProcessing', language);
    }
    return NOT_APPLICABLE;
  };

  const getCreateButton = () =>
    view === PLATES ? (
      <Button
        dataTestId="new-plate"
        primary
        onClick={() =>
          navigate(`/lab/plates/create/${analysis === GENOMIC ? HOMOGENIZATION : M3_ANALYSIS}`)
        }
      >
        {getString('newPlate', language)}
      </Button>
    ) : (
      <Button
        dataTestId="new-batch"
        primary
        onClick={() => navigate(`/lab/batches/create/${analysis === GENOMIC ? GENOMIC : M3}`)}
      >
        {getString('newBatch', language)}
      </Button>
    );

  const plateTypes = analysis === GENOMIC ? genomicPlateTypes : chemistryPlateTypes;
  const analysisTypes = analysis === GENOMIC ? genomicAnalysisList : chemistryExtractionList;
  const plateStatuses = analysis === GENOMIC ? plateStatusList : basicPlateStatusList;
  const batchTypes = analysis === GENOMIC ? genomicBatchTypes : chemistryBatchTypes;

  return (
    <div>
      <Header title={getTitle()}>
        <div className={styles.Toolbar}>
          {view === PLATES && (
            <PlateSearchBar
              className={styles.SearchBar}
              onSelect={setSearchString}
              onDeselect={() => setSearchString('')}
              placeholder={getString('searchByBarcode', language)}
              plateTypes={plateTypes}
              leftSection={<FiSearch />}
              forceSelect
            />
          )}
        </div>
      </Header>
      <div className={styles.MenuBar}>
        <TabbedController
          options={viewOptions}
          onChange={(idx: number) => {
            navigate(`/lab/${analysis}/${viewOptions[idx].value}`);
          }}
          activeIndex={viewOptions.findIndex((v) => v.value === view)}
        />
        {getCreateButton()}
      </div>
      {view === PLATES && (
        <PlatesOverview
          plateBarcode={searchString}
          plateTypes={plateTypes}
          analysisTypes={analysisTypes}
          plateStatuses={plateStatuses}
          analysis={analysis}
        />
      )}
      {view === BATCHES && (
        <BatchOverview batchTypes={batchTypes} batchStatuses={batchStatusList} />
      )}
    </div>
  );
};

export default ProcessingOverviewContainer;
