import { AppThunk } from 'store';
import { getLocalStorage, setLocalStorage } from 'util/localStorage';
import { ACTIVE_USER_ID } from 'constants/auth';
import {
  receiveAllAgencies,
  receiveAgencyUsers,
  receiveSingleAgency,
  requestAgencies,
  setActiveAgency as setActiveAgencyAction,
  agencyRequestError,
  requestAgencyAssociatedUsers,
  receiveAgencyContracts,
  requestAgencyContracts,
  receiveMyAgencies,
  requestMyAgencies,
  receiveAgencyResultPreferences,
  receiveAgencyContractMetrics,
  receiveContractTiers,
} from './actions';
import {
  requestAgencyUsers,
  requestAllAgencies,
  requestAgencyDetails,
  updateAgencyDetails,
  requestAgenciesforOperation,
  requestContracts,
  requestMyAgenciesGet,
  requestAgencyPutResultPreferences,
  getAgencyContractMetrics,
  requestGetAgencyContractTiers,
} from './requests';
import { Agency, USER_AGENCY_ID } from './types';
import { User } from '../user/types';
import { ResultPreferencesType } from 'store/operation/types';

export const getAllAgencies = (): AppThunk => async (dispatch) => {
  dispatch(requestAgencies());
  try {
    const response = await requestAllAgencies();
    return dispatch(receiveAllAgencies(response));
  } catch (e) {
    return dispatch(agencyRequestError());
  }
};

export const getMyAgencies = (): AppThunk => async (dispatch) => {
  dispatch(requestMyAgencies());
  try {
    const response = await requestMyAgenciesGet();
    return dispatch(receiveMyAgencies(response));
  } catch (e) {
    return dispatch(agencyRequestError());
  }
};

export const setActiveAgency =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      if (user.agencies.length && Number(getLocalStorage(ACTIVE_USER_ID)) === user.id) {
        setLocalStorage(USER_AGENCY_ID, user.agencies[0].id);
        return await dispatch(setActiveAgencyAction(user.agencies[0].id));
      }
    } catch (e) {
      return dispatch(agencyRequestError(user.agencies[0].id));
    }
  };

export const getAgencyAssociatedUsers =
  (agencyId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestAgencyAssociatedUsers());
    try {
      const response = await requestAgencyUsers(agencyId);
      return dispatch(receiveAgencyUsers(agencyId, response.users));
    } catch (e) {
      return dispatch(agencyRequestError());
    }
  };

export const getAgency =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestAgencies(id));
    try {
      const response = await requestAgencyDetails(id);
      return dispatch(receiveSingleAgency(response));
    } catch (e) {
      return dispatch(agencyRequestError(id));
    }
  };

export const getOperationAllAgencies =
  (operationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestAgencies());
    try {
      const response = await requestAgenciesforOperation(operationId);
      return dispatch(receiveAllAgencies(response.agencies));
    } catch (e) {
      return dispatch(agencyRequestError());
    }
  };

export const submitAgencyDetails =
  (agencyId: number, body: Partial<Agency>): AppThunk =>
  async (dispatch) => {
    try {
      const response = await updateAgencyDetails(agencyId, body);
      dispatch(receiveSingleAgency(response));
    } catch (e) {
      throw dispatch(agencyRequestError(agencyId));
    }
  };

export const getAgencyContracts =
  (agencyId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestAgencyContracts());
      const response = await requestContracts(agencyId);
      return dispatch(receiveAgencyContracts(agencyId, response));
    } catch (e) {
      return dispatch(agencyRequestError(agencyId));
    }
  };

export const updateAgencyResultPreferences =
  (agencyId: number, body: Partial<ResultPreferencesType>): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestAgencyPutResultPreferences(agencyId, body);
      dispatch(receiveAgencyResultPreferences(response, agencyId));
    } catch (error) {
      dispatch(agencyRequestError(agencyId));
    }
  };

export const getContractMetricsByAgency =
  (agencyId: number, contractId: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await getAgencyContractMetrics(agencyId, contractId);
      return dispatch(receiveAgencyContractMetrics(agencyId, contractId, response));
    } catch (e) {
      return dispatch(agencyRequestError(agencyId));
    }
  };

export const getSubContractTiers =
  (agencyId: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestGetAgencyContractTiers(agencyId);
      dispatch(receiveContractTiers(agencyId, response));
    } catch (e) {
      dispatch(agencyRequestError(agencyId));
    }
  };
