import React from 'react';
import { Table, TableCol, TableRow } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { displayPriceOrBar } from 'util/salesFlow';
import { PATTERN_SAMPLE_COST } from 'constants/salesFlow';
import Styles from './Container.module.css';

interface OfferTableProps {
  operationId: number;
}

const OfferTable = ({ operationId }: OfferTableProps) => {
  const language = useBroswerLanguage();
  const { operationCropInfo } = useSelector((state: RootState) => ({
    operationCropInfo: state.salesFlow.byOperationId[operationId],
  }));

  const { average_total_return, total_acres } = operationCropInfo.summary;
  const estimatedBenefit = average_total_return * total_acres;
  const patternCost = PATTERN_SAMPLE_COST * total_acres;

  return (
    <Table>
      <TableRow header>
        <TableCol />
        <TableCol className={Styles.Column}>$ / ac</TableCol>
        <TableCol className={Styles.Column}>{getString('total', language)}</TableCol>
        <TableCol />
      </TableRow>
      <TableRow>
        <TableCol>{getString('estBenefit', language)}</TableCol>
        <TableCol className={Styles.Column}>${average_total_return}</TableCol>
        <TableCol className={Styles.Column}>{displayPriceOrBar(estimatedBenefit, '')}</TableCol>
        <TableCol />
      </TableRow>
      <TableRow>
        <TableCol>{getString('cost', language)}</TableCol>
        <TableCol className={Styles.Column}>${PATTERN_SAMPLE_COST}</TableCol>
        <TableCol className={Styles.Column}>
          {displayPriceOrBar(PATTERN_SAMPLE_COST * total_acres, '')}
        </TableCol>
        <TableCol />
      </TableRow>
      <TableRow>
        <TableCol>
          <b>{getString('return', language)}</b>
        </TableCol>
        <TableCol className={Styles.Column}>
          <b>{displayPriceOrBar(average_total_return - PATTERN_SAMPLE_COST, '')}</b>
        </TableCol>
        <TableCol className={Styles.Column}>
          <b>{displayPriceOrBar(estimatedBenefit - patternCost, '')}</b>
        </TableCol>
        <TableCol>
          <b>{(average_total_return / PATTERN_SAMPLE_COST).toFixed(1)}x</b>
        </TableCol>
      </TableRow>
    </Table>
  );
};

export default OfferTable;
