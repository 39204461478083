import { handleJsonResponse, requestGet, requestPost, SERVICE_URL } from 'util/request';
import { ParcelDimensionType, PartialShippingAddress } from './types';

export const requestAllShippingAddresses = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/shipping_address/`));

export const requestBatchLabels = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/shipping_label_batch/`));

export const requestCreateShippingLabelBatch = async (body: {
  address_id: number;
  lab_id: number;
  parcel: ParcelDimensionType;
  num_of_labels: number;
}) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/shipping_label_batch/`, {
      body,
    }),
  );

export const requestCreateShippingAddress = async (address: PartialShippingAddress) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/shipping_address/`, {
      body: address,
    }),
  );

export const downloadRegulatedSoilPDF = async (form: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/soil_regulation_download/${form}`));
