import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';

import { FeatureFlagType } from './types';

export const getFeatureFlags = () =>
  requestGet(`${SERVICE_URL}/feature_flags/`).then(handleJsonResponse);

export const postFeatureFlag = (body: FeatureFlagType) =>
  requestPost(`${SERVICE_URL}/feature_flag/`, {
    body,
  }).then(handleJsonResponse);

export const putFeatureFlag = (id: number, body: FeatureFlagType) =>
  requestPut(`${SERVICE_URL}/feature_flag/${id}`, {
    body,
  }).then(handleJsonResponse);
