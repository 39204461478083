import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Button, Header } from 'common';

import LabShipmentTable from './ShipmentTable/ShipmentTable';

const LabShippingContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  return (
    <div>
      <Header title={getString('interLabShipping', language)}>
        <Button onClick={() => navigate(`/lab/shipping/create`)}>
          {getString('createLabShipment', language)}
        </Button>
      </Header>
      <LabShipmentTable />
    </div>
  );
};

export default LabShippingContainer;
