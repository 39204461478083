import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Stack, Text } from '@mantine/core';

import { PrescriptionType } from 'store/prescriptions/types';
import { getString } from 'strings/translation';
import { getNutrientName, getPrescriptionSummaryValues } from 'util/prescription';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import styles from './Map.module.css';

interface MapScriptProps {
  prescription: PrescriptionType | undefined;
  field: FieldType;
}

const MapScriptInfo = ({ prescription, field }: MapScriptProps) => {
  const language = useBroswerLanguage();

  const { inputs } = useSelector((state: RootState) => ({
    inputs: state.inputs.inputs,
  }));

  if (!prescription) {
    return null;
  }

  const { basedOn } = getPrescriptionSummaryValues(prescription, inputs, field, language);

  return (
    <Stack gap="xs" className={styles.CreationInfo}>
      <Text fw={700}>{prescription.name}</Text>
      <Flex align="center">
        <Text fw={FONT_WEIGHT_BOLD}>{getString('nutrient', language)}: </Text>
        <Text>{getNutrientName(prescription.input)}</Text>
      </Flex>
      <Flex>
        <Text fw={FONT_WEIGHT_BOLD}>{getString('from', language)}: </Text>
        <Text>{basedOn}</Text>
      </Flex>
    </Stack>
  );
};

export default MapScriptInfo;
