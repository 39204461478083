import { PlateType } from 'store/plates/types';

export type BatchUploadedFileType = {
  id: number;
  batch_id: number;
  file_name: string;
  updated_at: string;
  created_at: string;
  upload_url: string;
  download_url: string;
  uploaded: boolean;
};

export type BatchType = {
  id: number;
  updated_at: string;
  created_at: string;
  sent_for_processing_at?: string;
  estimation_date?: string | null;
  status: string;
  type: string;
  plates: Array<PlateType>;
  sample_sheet_url: string | null;
  miseq_sample_sheet_url: string | null;
  sample_sheet_name: string | null;
  uploaded_files: BatchUploadedFileType[];
};

export type BatchFormType = {
  id?: number;
  type: string;
  plates: {
    barcode: string;
    lanes?: number[];
  }[];
};

// TODO: deprecate
export type BatchSamplesResponseType = {
  id: number;
  barcode: string;
  batch_id: number;
  field_name: string;
  operation_name: string;
  primary: boolean;
  sample_uuid: string;
  sampling_plan_id: number;
  status: string;
  well_row_column: string;
};

export type BatchStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  errorMessage: string;
  page: number;
  /**
   * Cancels the effects of outdated requests
   */

  fetchRequestNumber: number;
  totalPages: number;
  byId: {
    [key: string]: BatchType;
  };
  batches: BatchType[];
};

export const REQUEST_BATCHES = 'REQUEST_BATCHES';
export const RECEIVE_BATCHES = 'RECEIVE_BATCHES';
export const RECEIVE_BATCH = 'RECEIVE_BATCH';
export const RECEIVE_BATCH_FILES = 'RECEIVE_BATCH_FILES';
export const BATCH_REQUEST_ERROR = 'REQUEST_BATCHES_ERROR';

type RequestBatchesActionType = {
  type: typeof REQUEST_BATCHES;
};

type ReceiveBatchesActionType = {
  type: typeof RECEIVE_BATCHES;
  payload: {
    page: number;
    per_page: number;
    total: number;
    items: BatchType[];
    fetchRequestNumber: number;
  };
};

type ReceiveBatchActionType = {
  type: typeof RECEIVE_BATCH;
  payload: BatchType;
};

type ReceiveBatchFilesActionType = {
  type: typeof RECEIVE_BATCH_FILES;
  payload: {
    id: number;
    files: BatchUploadedFileType[];
  };
};

type BatchReceiveErrorType = {
  type: typeof BATCH_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type BatchActionsType =
  | RequestBatchesActionType
  | ReceiveBatchesActionType
  | ReceiveBatchActionType
  | ReceiveBatchFilesActionType
  | BatchReceiveErrorType;
