import React from 'react';
import { useDispatch } from 'react-redux';
import { featureCollection } from '@turf/helpers';
import { Button, TooltipWrapper, AdminAccess } from 'common';
import showToast from 'actions/toastActions';
import { deleteSample, markNotAnalyzed } from 'store/samples/requests';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { getLabSamples } from 'store/labSamples/thunks';
import { getFieldGeometry } from 'store/fields/thunks';
import {
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PERFORMANCE_PANEL,
  PRESSURE_PANEL,
} from 'constants/products';

import styles from './CancelSampleModal.module.css';

type CancelSampleModalProps = {
  sample: LabSampleType;
};

export const CancelSampleModal = ({ sample }: CancelSampleModalProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const cancelSample = async () => {
    try {
      await deleteSample(
        sample.id,
        featureCollection([
          {
            geometry: {
              geometries: [],
            },
            type: 'Feature',
            properties: {
              ...sample,
              notes: 'Removed from plan', // No translation, BE data
            },
          },
        ]),
      );
      dispatch(getLabSamples(sample.field_id));
      dispatch(getFieldGeometry(sample.field_id, true));
      showToast(getString('sampleCancelledSuccessMsg', language));
    } catch (e) {
      showToast(getString('sampleCancelledFailureMsg', language), 'error');
    }
  };

  const cancelAnalysis = async (...products: string[]) => {
    try {
      if (products.includes(NUTRIENT_PANEL) && Boolean(sample.nutrient_panel)) {
        await markNotAnalyzed(sample.sample_uuid, NUTRIENT_PANEL);
      }
      if (products.includes(NITRATE_PANEL) && Boolean(sample.nitrate_panel)) {
        await markNotAnalyzed(sample.sample_uuid, NITRATE_PANEL);
      }
      if (products.includes(PRESSURE_PANEL) && Boolean(sample.pressure_panel)) {
        await markNotAnalyzed(sample.sample_uuid, PRESSURE_PANEL);
      }
      if (products.includes(PERFORMANCE_PANEL) && Boolean(sample.performance_panel)) {
        await markNotAnalyzed(sample.sample_uuid, PERFORMANCE_PANEL);
      }

      dispatch(getLabSamples(sample.field_id));
      dispatch(getFieldGeometry(sample.field_id, true));
      showToast(getString('sampleCancelledSuccessMsg', language));
    } catch (e) {
      showToast(`${getString('sampleCancelledFailureMsg', language)}: ${e.message}`, 'error');
    }
  };

  const disableNutrient = !sample.nutrient_panel || sample.nutrient_panel_not_analyzed;
  const disableNitrate = !sample.nitrate_panel || sample.nitrate_panel_not_analyzed;
  const disablePressure = !sample.pressure_panel || sample.pressure_panel_not_analyzed;
  const disablePerformance = !sample.performance_panel || sample.performance_panel_not_analyzed;

  const cancelActions = (
    <div className={styles.TooltipContent}>
      <Button light square onClick={cancelSample}>
        {getString('cancelEntireSample', language)}
      </Button>
      <AdminAccess>
        <Button
          light
          square
          onClick={() => cancelAnalysis(NUTRIENT_PANEL)}
          disabled={disableNutrient}
        >
          {getString('cancelNutrient', language)}
        </Button>
        <Button
          light
          square
          onClick={() => cancelAnalysis(NITRATE_PANEL)}
          disabled={disableNitrate}
        >
          {getString('cancelNitrate', language)}
        </Button>
        <Button
          light
          square
          onClick={() => cancelAnalysis(PRESSURE_PANEL)}
          disabled={disablePressure}
        >
          {getString('cancelPressure', language)}
        </Button>
        <Button
          light
          square
          onClick={() => cancelAnalysis(PERFORMANCE_PANEL)}
          disabled={disablePerformance}
        >
          {getString('cancelPerformance', language)}
        </Button>
      </AdminAccess>
    </div>
  );

  return (
    <>
      <TooltipWrapper right content={cancelActions}>
        <Button dataTestId="cancel" danger>
          {getString('cancel', language)}
        </Button>
      </TooltipWrapper>
    </>
  );
};
