import {
  CatalogActionsType,
  CatalogType,
  CATALOG_REQUEST_ERROR,
  RECEIVE_CATALOG,
  RECEIVE_CATALOGS,
  REQUEST_CATALOGS,
} from './types';

export const receiveCatalogs = (catalogs: CatalogType[]): CatalogActionsType => ({
  type: RECEIVE_CATALOGS,
  payload: {
    catalogs,
  },
});

export const receiveSingleCatalog = (catalog: CatalogType): CatalogActionsType => ({
  type: RECEIVE_CATALOG,
  payload: {
    catalog,
  },
});

export const requestCatalogs = (): CatalogActionsType => ({
  type: REQUEST_CATALOGS,
});

export const catalogRequestError = (): CatalogActionsType => ({
  type: CATALOG_REQUEST_ERROR,
});
