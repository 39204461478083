import React from 'react';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FieldType } from 'store/fields/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { OperationType } from 'store/operation/types';
import { OptimizePlansModal } from './OptimizePlansModal';

type OptimizePlansModalPropsType = {
  operation: OperationType;
  fields: FieldType[];
};

export const OptimizePlans = ({ operation, fields }: OptimizePlansModalPropsType) => {
  const language = useBroswerLanguage();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button onClick={open} disabled={!operation.catalog_id}>
        {getString('optimizePlans', language)}
      </Button>
      <OptimizePlansModal
        onClose={close}
        opened={opened}
        operationId={operation.id}
        fields={fields}
      />
    </>
  );
};
