export const RECEIVE_INPUTS = 'RECEIVE_INPUTS';
export const REQUEST_INPUTS = 'REQUEST_INPUTS';
export const REQUEST_INPUTS_ERROR = 'REQUEST_INPUTS_ERROR';

export type InputType = {
  id: number;
  name: string;
  density_unit: string;
  cost: number;
  nutrient: string;
  chemical_formula: string;
  concentration: number;
  customizeable: boolean;
  unit?: 'percent' | 'lbs_per_ton';
};

export interface InputsState {
  hasFailed: boolean;
  hasFetched: boolean;
  inputs: InputType[];
  isFetching: boolean;
}

export interface RequestInputsAction {
  type: typeof REQUEST_INPUTS;
}

export interface RequestInputsErrorAction {
  type: typeof REQUEST_INPUTS_ERROR;
}

export interface ReceiveInputsAction {
  type: typeof RECEIVE_INPUTS;
  payload: {
    inputs: InputType[];
  };
}

export type InputsActionType = RequestInputsAction | ReceiveInputsAction | RequestInputsErrorAction;
