import { FormikErrors } from 'formik';
import { BillingAddress, FarmAccountInfo, RegisterAgentForm, UserInfo } from 'store/user/types';
import { Agency } from 'store/agencies/types';
import { RoleType } from 'store/roles/types';
import { getString } from 'strings/translation';
import { checkRole, userIsAgencyAdmin, userIsAgent } from './userRoles';

export const phoneRegExp = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const zipCodeExp = /^[0-9.-]*$/;

export const billingAddressValidate = (values: BillingAddress, language: string) => {
  if (values.zip && !validateZipCode(values.zip)) {
    return getString('invalidZipcode', language);
  }
  if (
    Object.values(values).some((value) => value) &&
    !Object.values(values).every((value) => value)
  ) {
    return getString('billingAddressMissingMsg', language);
  }
};

export const cleanPhoneNumber = (phone: string) => {
  return phone.replace(/\D/g, '');
};

export const validateZipCode = (zip: string) => {
  return /^\d{5}$/.test(zip) || /^\d{8}$/.test(zip) || /^\d{5}-\d{3}$/.test(zip);
};

export const registerFormValidate = (
  values: Partial<UserInfo>,
  selectedAgency: Agency | { id: number; displayName: string } | null,
  hasExistingAgency: string,
  roles: RoleType[],
  language: string,
) => {
  if (!values.first_name || !values.last_name || !values.email || !values.role_id) {
    return getString('fillOutRequiredFields', language);
  }

  if (!emailRegExp.test(values.email)) {
    return getString('enterValidEmail', language);
  }

  const cleanCell = values.cell && cleanPhoneNumber(values.cell);
  if (cleanCell && cleanCell.length < 10) {
    return getString('enterValidPhone', language);
  }
  if (values.billing_address) {
    const billingAddressError = billingAddressValidate(values.billing_address, language);
    if (billingAddressError) {
      return billingAddressError;
    }
  }

  if (checkRole(roles, Number(values.role_id), userIsAgencyAdmin) && !hasExistingAgency) {
    return getString('fillOutRequiredFields', language);
  }

  if (
    checkRole(roles, Number(values.role_id), userIsAgencyAdmin) &&
    hasExistingAgency === 'no' &&
    !values.agency_name
  ) {
    return getString('enterAgencyName', language);
  }

  if (
    ((checkRole(roles, Number(values.role_id), userIsAgencyAdmin) && hasExistingAgency === 'yes') ||
      checkRole(roles, Number(values.role_id), userIsAgent)) &&
    !selectedAgency
  ) {
    return getString('addAnAgency', language);
  }
};

export const createAgentFormValidate = (values: RegisterAgentForm, language: string) => {
  if (!values.first_name || !values.last_name || !values.email || !values.role_id) {
    return getString('fillOutRequiredFields', language);
  }

  if (!emailRegExp.test(values.email)) {
    return getString('enterValidEmail', language);
  }

  if (values.cell && values.cell.length < 10) {
    return getString('enterValidPhone', language);
  }
};

export const createFarmAccountValidate = (values: FarmAccountInfo) => {
  const errors: FormikErrors<FarmAccountInfo> = {};
  const { name } = values;

  if (!name) {
    errors.name = 'This field is required.';
  }

  return errors;
};
