import React from 'react';
import { SALES_FLOW_COLORS } from 'util/mapImageryColors';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { displayPriceOrBar } from 'util/salesFlow';
import SlideStyles from '../Shared.module.css';
import ReportChart from './ReportChart';
import Styles from './Container.module.css';
import ReportTable from './ReportTable/ReportTable';

interface ReportContainerProps {
  operationId: number;
}

const ReportContainer = ({ operationId }: ReportContainerProps) => {
  const language = useBroswerLanguage();
  const { operationCropInfo } = useSelector((state: RootState) => ({
    operationCropInfo: state.salesFlow.byOperationId[operationId],
  }));

  const {
    average_optimize_spend,
    average_total_return,
    average_boost_yield,
    base_crop_protection,
    average_acre_revenue,
    total_acres,
  } = operationCropInfo.summary;

  const yieldChartData = [
    {
      name: 'Base Case',
      label: 'Base Rev',
      value: average_acre_revenue,
      dollar: `$${average_acre_revenue}`,
    },
    {
      name: 'Pattern 360',
      label: 'Rev w Pattern',
      value: average_acre_revenue + average_boost_yield,
      dollar: `$${average_acre_revenue + average_boost_yield}`,
    },
  ];

  const absBaseCost = Math.abs(base_crop_protection);
  const optimizeSpendChartData = [
    {
      name: 'Base Case',
      label: 'Base CP Spend',
      value: absBaseCost,
      dollar: `$${absBaseCost}`,
    },
    {
      name: 'Pattern CP Spend',
      label: 'CP w Pattern',
      value: absBaseCost - average_optimize_spend,
      dollar: `$${absBaseCost - average_optimize_spend}`,
    },
  ];

  const yieldDomain = [yieldChartData[0].value - 50, yieldChartData[1].value + 50];

  const spendDomain = (() => {
    // Either bar can be higher
    const min = Math.min(...optimizeSpendChartData.map((item) => item.value));
    const max = Math.max(...optimizeSpendChartData.map((item) => item.value));
    if (min - 20 < 0) {
      return [0, max + 20];
    }
    return [min - 20, max + 20];
  })();

  const firstInfo = operationCropInfo.crop_plans[0];
  return (
    <div>
      <div className={SlideStyles.Header}>
        <b>
          {firstInfo.operation.name} {getString('report', language)} - {firstInfo.county.county},{' '}
          {firstInfo.county.state}
        </b>
      </div>
      <div className={SlideStyles.BorderBottom} />
      <div className={SlideStyles.Images}>
        <div className={Styles.ChartAndMeta}>
          <div className={Styles.Chart}>
            <div className={Styles.ChartInfoText}>
              <b>
                {getString('estReturn', language)}:{' '}
                {displayPriceOrBar(average_total_return, '/ ac')}
              </b>
            </div>
            <div className={Styles.ChartInfo} />
            <div className={Styles.SmallChartBorderWrapper}>
              <div className={Styles.SmallChartText}>
                <div>{getString('boostYield', language)}</div>
                <div>{displayPriceOrBar(average_boost_yield, '/ ac')}</div>
                <div className={Styles.SmallChartBorder} />
              </div>
              <div className={Styles.SmallChartText}>
                <div>{getString('optimizeSpend', language)}</div>
                <div>{displayPriceOrBar(average_optimize_spend, '/ ac')}</div>
                <div className={Styles.SmallChartBorder} />
              </div>
            </div>
            <div className={Styles.Charts}>
              <div className={Styles.ChartWrapper}>
                <ReportChart
                  barColor={SALES_FLOW_COLORS.GREEN}
                  chartData={yieldChartData}
                  domainRange={yieldDomain}
                />
              </div>
              <div className={Styles.ChartWrapperCenterBorder} />
              <div className={Styles.ChartWrapper}>
                <ReportChart
                  barColor={SALES_FLOW_COLORS.RED}
                  chartData={optimizeSpendChartData}
                  domainRange={spendDomain}
                />
              </div>
            </div>
          </div>
          <div className={Styles.DataWrapper}>
            <ReportTable
              operationCropInfo={operationCropInfo.crop_plans}
              totalAcres={total_acres}
              averageOptimizeSpend={average_optimize_spend}
              averageBoostYield={average_boost_yield}
              averageTotalReturn={average_total_return}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportContainer;
