import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Tabs.module.css';

type TabsProps = {
  activeIndex?: number;
  className?: string;
  items: { displayName: string }[];
  onChange?: Function;
  'data-test-id'?: string;
  children?: ReactNode;
};

export const Tabs: FunctionComponent<TabsProps> = ({
  activeIndex = 0,
  children,
  className = '',
  items,
  onChange = () => {},
  'data-test-id': testId,
}) => (
  <>
    <div className={classNames(styles.Tabs, className)} data-test-id={testId}>
      {items.map((item, i) => (
        <div
          key={item.displayName}
          onClick={() => onChange(i)}
          className={classNames(styles.Tab, {
            [styles.Active]: activeIndex === i,
          })}
        >
          {item.displayName}
        </div>
      ))}
    </div>
    {children}
  </>
);
