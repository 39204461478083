import React, { Fragment } from 'react';
import { Divider, Group, Stack, Table, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Header } from 'common/Components/Mantine/Header';
import { ContractType } from 'store/agencies/types';
import { AGENCY, contractEditableProductsMap, LIST, MSRP } from 'constants/products';
import { getSamplePriceDisplay } from 'util/pricing';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';

type PriceListProps = {
  contract: ContractType;
};

export const PriceList = ({ contract }: PriceListProps) => {
  const language = useBroswerLanguage();
  const agencyMsrpProducts = Array.from(new Set([...contractEditableProductsMap[AGENCY], ...contractEditableProductsMap[MSRP]]))
  return (
    <Stack>
      <Header title={getString('priceList', language)} order={4} divider="sm" />
      <Group wrap="nowrap" align="flex-start">
        <Table>
          <Table.Tbody>
            <Table.Tr>
              <Table.Th><Text inherit style={{ textWrap: 'nowrap' }}>{getString('panel', language)}</Text></Table.Th>
              <Table.Th><Text inherit style={{ textWrap: 'nowrap' }}>{getString('msrp', language)}</Text></Table.Th>
              <Table.Th><Text inherit style={{ textWrap: 'nowrap' }}>{getString('agencyCost', language)}</Text></Table.Th>
            </Table.Tr>
            {Object.entries(contract.price_buckets).map(([density, priceBucket]) => (
              <Fragment key={density}>
                <Table.Tr>
                  <Table.Td
                    fw="bold"
                    colSpan={3}
                  ><Text inherit style={{ textWrap: 'nowrap' }}>{`${acToHectares(Number(density), language)} ${getAcreageUnitFromLang(language)}`}</Text></Table.Td>
                </Table.Tr>
                {agencyMsrpProducts.map((panel) => (
                  <Table.Tr key={panel}>
                    <Table.Td><Text inherit style={{ textWrap: 'nowrap' }}>{getString(panel, language)}</Text></Table.Td>
                    <Table.Td><Text inherit style={{ textWrap: 'nowrap' }}>{getSamplePriceDisplay(`${panel}_${MSRP}`, priceBucket, language)}</Text></Table.Td>
                    <Table.Td><Text inherit style={{ textWrap: 'nowrap' }}>{getSamplePriceDisplay(`${panel}_${AGENCY}`, priceBucket, language)}</Text></Table.Td>
                  </Table.Tr>
                ))}
              </Fragment>
            ))}
          </Table.Tbody>
        </Table>
        <Divider size="sm" color="blue" variant="dashed" orientation="vertical" />
        <Table>
          <Table.Tbody>
            <Table.Tr>
              <Table.Th />
              <Table.Th><Text inherit style={{ textWrap: 'nowrap' }}>{getString('listPrice', language)}</Text></Table.Th>
            </Table.Tr>
            {Object.entries(contract.price_buckets).map(([density, priceBucket]) => (
              <Fragment key={density}>
                <Table.Tr>
                  <Table.Td
                    c="transparent"
                    fw="bold"
                    colSpan={3}
                  ><Text inherit style={{ textWrap: 'nowrap' }}>{`${acToHectares(Number(density), language)} ${getAcreageUnitFromLang(language)}`}</Text></Table.Td>
                </Table.Tr>
                {contractEditableProductsMap[LIST].map((panel) => (
                  <Table.Tr key={panel}>
                    <Table.Td><Text inherit style={{ textWrap: 'nowrap' }}>{getString(panel, language)}</Text></Table.Td>
                    <Table.Td><Text inherit style={{ textWrap: 'nowrap' }}>{getSamplePriceDisplay(`${panel}_${LIST}`, priceBucket, language)}</Text></Table.Td>
                  </Table.Tr>
                ))}
              </Fragment>
            ))}
          </Table.Tbody>
        </Table>
      </Group>
    </Stack>
  );
};
