import { User } from '../user/types';

export const RECEIVE_SAMPLE_PRICES = 'RECEIVE_SAMPLE_PRICES';
export const REQUEST_SAMPLE_PRICES = 'REQUEST_SAMPLE_PRICES';
export const SAMPLE_PRICE_REQUEST_ERROR = 'SAMPLE_PRICE_REQUEST_ERROR';

export type PriceRange = {
  user_id: number;
  max_density: number;
  price: number;
};

export type SamplePrice = {
  user: User;
  pricing_ranges: PriceRange[];
};

export type SamplePricePost = {
  users: Number[];
  max_values: { value: number; price: number; index: number }[];
};

export type SamplePricesResponse = { [user_id: number]: SamplePrice };

export type SamplePricesStateType = {
  hasFailed: boolean;
  isFetching: boolean;
  samplePrices: SamplePricesResponse;
};

interface RequestSamplePricesAction {
  type: typeof REQUEST_SAMPLE_PRICES;
}

interface ReceiveSamplePricesAction {
  type: typeof RECEIVE_SAMPLE_PRICES;
  payload: {
    samplePrices: SamplePricesResponse;
  };
}

interface SamplePricesRequestError {
  type: typeof SAMPLE_PRICE_REQUEST_ERROR;
  payload: {
    message: string;
  };
}

export type SamplePricesActionType =
  | RequestSamplePricesAction
  | ReceiveSamplePricesAction
  | SamplePricesRequestError;
