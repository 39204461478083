export const RECEIVE_TIMINGS = 'RECEIVE_TIMINGS';
export const REQUEST_TIMINGS = 'REQUEST_TIMINGS';
export const TIMINGS_REQUEST_ERROR = 'TIMINGS_REQUEST_ERROR';

export type TimingsType = {
  id: number;
  value: string;
  displayName: string;
};

export type TimingsResponseType = {
  value: string;
  display_name: string;
};

type ReceiveTimings = {
  type: typeof RECEIVE_TIMINGS;
  payload: {
    current_crop_year: number;
    current_timing: string;
    sampling_timings: TimingsResponseType[];
  };
};

type RequestTimings = {
  type: typeof REQUEST_TIMINGS;
};

type TimingsRequestError = {
  type: typeof TIMINGS_REQUEST_ERROR;
};

export type TimingActionsType = ReceiveTimings | RequestTimings | TimingsRequestError;
