import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Center, Switch, Space, Select } from '@mantine/core';
import { Header, AnalysisMethodPreference, SuperAdminAccess } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { submitAgencyDetails } from 'store/agencies/thunks';
import { RootState } from 'store';
import showToast from 'actions/toastActions';

type AgencySamplingPropTypes = {
  agencyId: number;
};

export const AgencySampling = ({ agencyId }: AgencySamplingPropTypes) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { agency } = useSelector((state: RootState) => ({
    agency: state.agencies.byId[agencyId],
  }));

  const recSetLists = agency?.possible_recommendation_sets?.map((recSet) => ({
    value: String(recSet.id),
    label: recSet.name,
  }));

  const submitSelfSampling = async (event, key) => {
    try {
      if (agency) {
        const body = { [key]: event.currentTarget.checked };
        await dispatch(submitAgencyDetails(agency.id, body));
        showToast(getString('updatingAgencySuccessMsg', language));
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const submitMultiplePlans = async (event) => {
    try {
      if (agency) {
        const body = { allow_multiple_plans: event.currentTarget.checked };
        await dispatch(submitAgencyDetails(agency.id, body));
        showToast(getString('updatingAgencySuccessMsg', language));
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const submitRecommendationSet = async (value, option) => {
    try {
      if (agency && option) {
        const body = { recommendation_set_id: Number(option.value) };
        await dispatch(submitAgencyDetails(agency.id, body));
        showToast(getString('updatingAgencySuccessMsg', language));
      }
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  return (
    <div>
      <Header title={getString('agencyDefaults', language)} />
      <AnalysisMethodPreference
        resultPreferences={agency?.result_preferences}
        operationOrAgencyId={agency.id}
      >
        <Stack>
          <Center>
            <Switch
              size="lg"
              onLabel={getString('on', language)}
              offLabel={getString('off', language)}
              checked={agency?.self_sampling}
              onChange={(event) => submitSelfSampling(event, 'self_sampling')}
            />
          </Center>
          {getString('selfSampling', language)}
        </Stack>
        <Stack>
          <Center>
            <Switch
              size="lg"
              onLabel={getString('on', language)}
              offLabel={getString('off', language)}
              checked={agency?.self_scanning}
              onChange={(event) => submitSelfSampling(event, 'self_scanning')}
            />
          </Center>
          {getString('selfScanning', language)}
        </Stack>
        <SuperAdminAccess>
          <Stack>
            <Center>
              <Switch
                size="lg"
                onLabel={getString('on', language)}
                offLabel={getString('off', language)}
                checked={agency?.allow_multiple_plans}
                onChange={submitMultiplePlans}
              />
            </Center>
            {getString('allowMultiplePlans', language)}
          </Stack>
        </SuperAdminAccess>
        <Space w="xs" />
        <Stack>
          <Center>
            <Select
              data={recSetLists || []}
              value={
                recSetLists?.find(
                  (recSet) => recSet.value === String(agency?.recommendation_set_id),
                )?.value
              }
              onChange={submitRecommendationSet}
            />
          </Center>
          {getString('displayedRecommendationSet', language)}
        </Stack>
        <Space w="xs" />
      </AnalysisMethodPreference>
      <Space h="xl" />
    </div>
  );
};
