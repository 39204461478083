import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Group, Menu, Tooltip } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { Header, AdminAccess } from 'common';
import useOperationFieldGeometries from 'util/hooks/useOperationFieldGeometries';
import { getOperation, getOperationUsers } from 'store/operation/thunks';
import { useTranslation, getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';

import { getSamplingTimings } from 'store/samplingTimings/thunks';
import FieldsTableContainer from './OrderFields/Table';
import OperationWithNoFields from './OperationWithNoFields/OperationWithNoFields';
import styles from './Container.module.css';
import { getPatternSamplers } from 'store/user/thunks';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';

const FullProductReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const { operationId } = useParams<{
    operationId: string;
  }>();
  const searchRef = useRef<HTMLInputElement>(null);
  const numericId = Number(operationId);
  const { operation, samplers, hasFailedSamplers, isAdmin } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericId],
    samplers: state.user.patternSamplers,
    hasFailedSamplers: state.user.hasFailed,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  const { allFieldGeometries, isFetchingList, willFetchList, hasFetchedList, hasFailedList } =
    useOperationFieldGeometries(operation, true, false);

  const filteredFields = useMemo(() => {
    return (operation.fields || []).map((field) => {
      const fieldGeometry = allFieldGeometries[field.id];
      return fieldGeometry ? fieldGeometry.features[0].properties : field;
    });
  }, [operation, allFieldGeometries]);

  const fetchOperation = useCallback(
    () => dispatch(getOperation(numericId)),
    [dispatch, numericId],
  );

  useEffect(() => {
    if (!operation) {
      fetchOperation();
    }
  }, [fetchOperation, operation]);

  useEffect(() => {
    dispatch(getOperationUsers(numericId));
    dispatch(getSamplingTimings());
  }, []);

  useEffect(() => {
    if (!samplers && !hasFailedSamplers && isAdmin) {
      dispatch(getPatternSamplers());
    }
  }, [samplers, hasFailedSamplers]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const missingOperationInfo = !operation.billing_user_id;

  const getAccountBadges = () => {
    if (missingOperationInfo) {
      return (
        <Tooltip
          label={getString('noBillingUserMsg', language)}
          position="bottom"
          style={(theme) => ({
            backgroundColor: theme.colors.blue[0],
            cursor: 'pointer',
          })}
          onClick={() => navigate(`/operations/${operationId}/edit`)}
        >
          <Badge size="lg" circle color="darkRed">
            !
          </Badge>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className={styles.Wrapper}>
      <Header title={`${useTranslation('orders')} - ${operation.name}`}>
        <Group gap="xs">
          {operation.plan_acres_since_lock_in !== null && (
            <span className={styles.AcreCommit}>
              <b>{getString('acreCommit', language)}:</b>{' '}
              {Math.round(operation.plan_acres_since_lock_in)} / {operation.locked_in_acres}
            </span>
          )}
          <Button
            variant="outline"
            className={styles.Button}
            data-test-id="map-field-boundaries-button"
            onClick={() => navigate(`/field-boundary/${operationId}/new`)}
          >
            {useTranslation('addEditBoundaries')}
          </Button>
          <Button
            variant="outline"
            className={styles.Button}
            data-test-id="upload-boundaries-button"
            onClick={() => navigate(`/operations/${operationId}/ingest_history`)}
          >
            {useTranslation('uploadBoundaries')}
          </Button>
          <Menu shadow="md" trigger="hover">
            <Menu.Target>
              <Button variant="outline" data-test-id="manage-account">
                {useTranslation('manageAccount')}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                data-test-id="edit-account"
                className={missingOperationInfo ? styles.RedButton : ''}
                onClick={() => navigate(`/operations/${operationId}/edit`)}
              >
                {getString('editAccount', language)}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <AdminAccess>{getAccountBadges()}</AdminAccess>
        </Group>
      </Header>
      {operation.fields?.length === 0 ? (
        <OperationWithNoFields operationId={operationId} />
      ) : (
        <FieldsTableContainer
          operation={operation}
          fields={filteredFields}
          fieldGeometries={allFieldGeometries}
          isFetching={[...isFetchingList, ...willFetchList]}
          hasFetchedList={[...hasFetchedList, ...hasFailedList]}
        />
      )}
    </div>
  );
};

export default FullProductReport;
