import React, { useState } from 'react';
import { Button, Checkbox, Group, Flex, Modal, Stack, Text, Title, Table } from '@mantine/core';
import { FiDelete } from 'react-icons/fi';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { EOCollectionFeatureType, EOCollectionFieldFeatureType } from 'store/eoCollections/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { isPlanCancelled, sortPlansByDate } from 'util/samplePlan';
import { formatDateMonthDayYear } from 'util/date';
import { disableCollection } from 'store/eoCollections/thunks';
import showToast, { type ToastType } from 'actions/toastActions';

import CollectionMap from './CollectionMap';

import styles from './Container.module.css';
import { RED } from 'util/mapImageryColors';
import { FaCheck } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';

interface FieldCollectionsProps {
  field: EOCollectionFieldFeatureType;
  selectedFieldIds: number[];
  toggleField: (id: number) => void;
  /** Show the grower name below the field name in expanded sub-panel */
  shouldShowGrowerSubheading: boolean;
}

const FieldCollections = ({
  field,
  selectedFieldIds,
  toggleField,
  shouldShowGrowerSubheading,
}: FieldCollectionsProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const {
    acreage,
    acreage_unit,
    farm_name,
    id: fieldId,
    name,
    pending_eo_collections,
    sampling_plans,
  } = field.features[0].properties;
  const [selectedCollection, setSelectedCollection] = useState<number | null>(
    pending_eo_collections[0]?.properties.id || null,
  );
  const [collectionToDelete, setCollectionToDelete] = useState<number | null>(null);

  const setToastMessage = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  const handleDisable = async () => {
    try {
      if (collectionToDelete) {
        setToastMessage(getString('deletingCollection', language));
        await dispatch(disableCollection(collectionToDelete));
        setToastMessage(getString('collectionDeleted', language));
        setCollectionToDelete(null);
      }
    } catch (error) {
      setToastMessage(getString('requestFailed', language), 'error');
    }
  };

  const lastPlan = sortPlansByDate(sampling_plans || []).filter((p) => !isPlanCancelled(p))[0];
  const totalOverlap = pending_eo_collections.reduce(
    (total, collection) => total + Math.round(collection.properties.overlap_percentage),
    0,
  );

  if (!(lastPlan && pending_eo_collections.length)) {
    return null;
  }

  const renderCollection = (collection: EOCollectionFeatureType) => {
    const {
      created_at,
      id,
      name: collectionName,
      overlap_percentage,
      scanning_time_minutes,
      adcp_file_exists,
      emi_file_exists,
      assigned_to_scan,
    } = collection.properties;

    const scannedBy = assigned_to_scan
      ? `${assigned_to_scan.first_name} ${assigned_to_scan.last_name}`
      : getString('unavailable', language);

    return (
      <Table.Tr
        className={classNames(styles.CollectionRow, {
          [styles.CollectionRowSelected]: id === selectedCollection,
        })}
        key={`collection-${id}`}
        onClick={() => setSelectedCollection(id)}
      >
        <Table.Td>
          <Group gap={0}>
            <FiDelete
              className={styles.DisableIcon}
              color="red"
              onClick={(event) => {
                event.stopPropagation();
                setCollectionToDelete(id);
              }}
            />
            <Text fw={FONT_WEIGHT_BOLD} size="sm">
              {collectionName}
            </Text>
          </Group>
        </Table.Td>
        <Table.Td>{formatDateMonthDayYear(created_at, language)}</Table.Td>
        <Table.Td>{scannedBy}</Table.Td>
        <Table.Td>
          {`${Math.round(scanning_time_minutes)} ${getString('minutesAbbreviated', language)}`}
        </Table.Td>
        <Table.Td>{`${Math.round(overlap_percentage)}%`}</Table.Td>
        <Table.Td c={adcp_file_exists ? 'green' : 'red.8'}>
          {adcp_file_exists ? <FaCheck aria-label="yes" /> : <MdClear aria-label="no" />}
        </Table.Td>
        <Table.Td c={emi_file_exists ? 'green' : 'red.8'}>
          {emi_file_exists ? <FaCheck aria-label="yes" /> : <MdClear aria-label="no" />}
        </Table.Td>
      </Table.Tr>
    );
  };

  const closeModal = () => setCollectionToDelete(null);

  return (
    <>
      <Flex className={styles.Row} justify="space-between">
        <Flex align="center" w="25%">
          <Checkbox
            className={styles.Checkbox}
            checked={selectedFieldIds.includes(fieldId)}
            color="rgba(17, 48, 99, 1)"
            onChange={() => toggleField(fieldId)}
          />
          <div>
            <Text fw={FONT_WEIGHT_BOLD} size="sm">
              {`${farm_name ? `${farm_name}:` : ''} ${name}`}
            </Text>
            {shouldShowGrowerSubheading && (
              <Text c="dimmed" size="xs">
                {field.features[0].properties.operation.name}
              </Text>
            )}
          </div>
        </Flex>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="25%">
          {lastPlan.name}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="35%">
          {`${Math.round(acreage)} ${acreage_unit}`}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} size="sm" w="15%">
          {`${getString('totalScanCoverage', language)}: ${totalOverlap}%`}
        </Text>
      </Flex>
      <Flex className={styles.Collection} justify="space-between">
        <Stack w="60%">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{getString('collectionName', language)}</Table.Th>
                <Table.Th>{getString('collectionDate', language)}</Table.Th>
                <Table.Th>{getString('scannedBy', language)}</Table.Th>
                <Table.Th>{getString('scanningTime', language)}</Table.Th>
                <Table.Th>{getString('fieldCoverage', language)}</Table.Th>
                <Table.Th>ADCP</Table.Th>
                <Table.Th>EMI</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{pending_eo_collections.map(renderCollection)}</Table.Tbody>
          </Table>
        </Stack>
        <CollectionMap field={field} selectedCollection={selectedCollection} />
      </Flex>
      {collectionToDelete && (
        <Modal
          opened={Boolean(collectionToDelete)}
          onClose={closeModal}
          centered
          withCloseButton={false}
          size="lg"
          ta="center"
        >
          <Stack p="2rem">
            <Title
              order={1}
              fw={FONT_WEIGHT_BOLD}
            >{`${getString('deleteCollection', language)}?`}</Title>
            <Text>{getString('deleteCollectionConfirm', language)}</Text>
            <Flex justify="flex-end">
              <Button bg={RED} onClick={closeModal} variant="filled">
                {getString('cancel', language)}
              </Button>
              <Button ml="1rem" onClick={handleDisable}>
                {getString('confirm', language)}
              </Button>
            </Flex>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default FieldCollections;
