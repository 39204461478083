export const RECEIVE_FEATURE_FLAG = 'RECEIVE_FEATURE_FLAG';
export const RECEIVE_FEATURE_FLAGS = 'RECEIVE_FEATURE_FLAGS';
export const REQUEST_FEATURE_FLAGS = 'REQUEST_FEATURE_FLAGS';
export const REQUEST_FEATURE_FLAGS_ERROR = 'REQUEST_FEATURE_FLAGS_ERROR';

export type FeatureFlagType = {
  id?: number;
  description?: string;
  enabled_for_all?: boolean;
  id_whitelist: number[];
  name: string;
};

export interface FeatureFlagsState {
  errorMessage: string | null;
  flags: FeatureFlagType[];
  hasFetched: boolean;
  isFetching: boolean;
}

interface RequestFeatureFlagsAction {
  type: typeof REQUEST_FEATURE_FLAGS;
}

interface ReceiveFeatureFlagAction {
  type: typeof RECEIVE_FEATURE_FLAG;
  payload: {
    [key: string]: any;
  };
}

interface ReceiveFeatureFlagsAction {
  type: typeof RECEIVE_FEATURE_FLAGS;
  payload: {
    [key: string]: any;
  };
}

interface FeatureFlagsRequestError {
  type: typeof REQUEST_FEATURE_FLAGS_ERROR;
  payload: {
    message: string;
  };
}

export type FeatureFlagsActionType =
  | ReceiveFeatureFlagAction
  | RequestFeatureFlagsAction
  | ReceiveFeatureFlagsAction
  | FeatureFlagsRequestError;
