import {
  handleJsonResponse,
  requestDownloadFile,
  requestGet,
  requestPostFile,
  SERVICE_URL,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { DNAConcentrationOptionType } from './types';

export const requestGetDNAConcentration = async (
  plateBarcode: string,
  quadrant: number,
  options: DNAConcentrationOptionType,
) => {
  const queryParams = queryStringify({
    plate_barcode: plateBarcode,
    plate_quadrant: quadrant,
    ...options,
  });
  return handleJsonResponse(await requestGet(`${SERVICE_URL}/dna_concentrations/?${queryParams}`));
};

export const requestPostDNAConcentration = async (
  barcode: string,
  file: File,
  quadrant: number,
) => {
  const queryParams = queryStringify({
    plate_barcode: barcode,
    plate_quadrant: quadrant,
    dna: true,
  });
  return handleJsonResponse(
    await requestPostFile(`${SERVICE_URL}/dna_concentration/?${queryParams}`, {
      file,
    }),
  );
};

export const requestDownloadDNAConcentration = (
  plateBarcode: string,
  quadrant: number,
  fileName: string,
  options: DNAConcentrationOptionType,
) => {
  const queryParams = queryStringify({
    plate_barcode: plateBarcode,
    plate_quadrant: quadrant,
    export: 'true',
    ...options,
  });
  return requestDownloadFile(`${SERVICE_URL}/dna_concentrations/?${queryParams}`, fileName);
};
