import React from 'react';
import { Skeleton, Table, TableTrProps } from '@mantine/core';

type LoadingRowsPropsType = TableTrProps & {
  colSpan: number;
  rowSpan: number;
};

export const LoadingRows = ({ colSpan, rowSpan, ...props }: LoadingRowsPropsType) => {
  return (
    <>
      {Array.from(Array(rowSpan), (_, i) => (
        <Table.Tr key={i} {...props}>
          {Array.from(Array(colSpan), (num) => (
            <Table.Th key={num}>
              <Skeleton height={8} radius="xl" />
            </Table.Th>
          ))}
        </Table.Tr>
      ))}
    </>
  );
};
