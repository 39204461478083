import React, { useEffect, useState } from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Container, Header, Pagination } from 'common';
import { LabShipmentFormType } from 'store/labShipments/types';
import { requestGetShippablePlates } from 'store/plates/requests';
import showToast from 'actions/toastActions';

import { ShippingPlatesList } from './common/PlatesList';
import styles from './common/PlateList.module.css';

type AddShippingPlatePropsType = {
  shipment: LabShipmentFormType;
  updateShipment: (attributeName: string, newValue: string[]) => void;
};

export const AddShippingPlate = ({ shipment, updateShipment }: AddShippingPlatePropsType) => {
  const language = useBroswerLanguage();
  const [plates, setPlates] = useState(shipment.plates || []);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchPlates();
  }, [shipment.group_id, page]);

  const fetchPlates = async () => {
    try {
      const response = await requestGetShippablePlates(page, shipment.group_id);
      if (response.plates) {
        setPlates(response.plates);
        setTotalPages(Math.ceil(response.total / response.per_page));
      }
    } catch (e) {
      showToast(e.message);
    }
  };

  return (
    <Container vertical>
      <Header
        title={
          <div className={styles.Header}>
            <div>{`${getString('genomicPlates', language)}: `}</div>
            <div className={styles.Count}>{shipment.plates?.length || 0}</div>
          </div>
        }
        underline="none"
        size="H2"
      />
      <ShippingPlatesList plates={plates} shipment={shipment} updateShipment={updateShipment} />
      {totalPages > 1 && (
        <div className={styles.Pagination}>
          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </div>
      )}
    </Container>
  );
};
