import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Space } from '@mantine/core';
import { Header } from 'common';
import { User } from 'store/user/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { submitUserDetails } from 'store/user/thunks';
import { RootState } from 'store';
import sortByName from 'util/sortByName';
import {
  getRoleListDisplayName,
  userIsAgencyAdmin,
  userIsAgent,
  userFullName,
} from 'util/userRoles';
import CreateAgentModal from './CreateAgentModal';
import UserTableRow from './UserTableRow';
import styles from './ManageUsers.module.css';
import { Agency } from 'store/agencies/types';
import { AGENCY } from 'constants/agency';

type PropTypes = {
  agency: Agency;
};

const ManageAgencyAgents = ({ agency }: PropTypes) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [showCreateAgentModal, toggleShowCreateAgentModal] = useState(false);
  const { currentUser, agencyUsers, isFetching, storeRoles } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    agencyUsers: state.agencies.byId[agency.id]?.allUsers || [],
    isFetching: state.agencies.isFetching,
    storeRoles: state.roles.roles,
  }));

  const primaryUsers = agencyUsers.filter((user) => user.agency_primary);
  const nonPrimaryUsers = agencyUsers
    .filter((user) => !user.agency_primary)
    .sort((a, b) => {
      return userFullName(a).localeCompare(userFullName(b), 'en', {
        sensitivity: 'base',
      });
    });

  const [sortedUsers, setSortedUsers] = useState<User[]>(
    [...primaryUsers, ...nonPrimaryUsers].filter(
      (u) => userIsAgent(u.roles) || userIsAgencyAdmin(u.roles),
    ),
  );

  const roles = sortByName(
    getRoleListDisplayName(
      (storeRoles || []).filter((role) => userIsAgent([role]) || userIsAgencyAdmin([role])),
    ),
  );

  const onChange = (user: User) => async (role_name: string) => {
    const control = roles.find((role) => role.value === role_name);
    if (control?.id) {
      const requestBody = { ...user, role_id: control.id };
      setSortedUsers(
        sortedUsers.map((temp) => {
          if (temp.id === user.id) {
            return { ...temp, roles: [control] };
          }
          return temp;
        }),
      );
      dispatch(submitUserDetails(requestBody, user.id, language, false, 'updatedUserRole'));
    }
  };

  return (
    <div>
      <Header title={`${getString('manageAgents', language)} - ${agency.name}`}>
        {showCreateAgentModal && (
          <CreateAgentModal
            agency={agency}
            open={showCreateAgentModal}
            hideModal={() => toggleShowCreateAgentModal(false)}
          />
        )}
        <Button
          disabled={isFetching}
          onClick={() => toggleShowCreateAgentModal(true)}
          data-test-id="create-new-agent"
        >
          {getString('createNewAgent', language)}
        </Button>
      </Header>
      {currentUser && sortedUsers.length ? (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{getString('name', language)}</Table.Th>
              {agency.hierarchy === AGENCY && (
                <Table.Th>{getString('location', language)}</Table.Th>
              )}
              <Table.Th>{getString('email', language)}</Table.Th>
              <Table.Th>{getString('role', language)}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {sortedUsers.map((user) => (
              <UserTableRow
                key={user.id}
                onChange={onChange}
                user={user}
                roles={roles}
                currentUserId={currentUser.id}
                agency={agency}
              />
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <div className={styles.NoAgentsExist}>**{getString('addAgents', language)}**</div>
      )}
      <Space h="xl" />
    </div>
  );
};

export default ManageAgencyAgents;
