import React, { useEffect, useState } from 'react';
import { Header, Button, Spinner } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getFlowTrackerSteps, validateFarmPlan } from 'util/salesFlow';
import { OperationDetailsType } from 'store/salesFlow/types';
import { useDispatch, useSelector } from 'react-redux';
import showToast from 'actions/toastActions';
import { defaultCornInfo, defaultSoybeanInfo } from 'constants/salesFlow';
import { getSalesFlowMeta, postOperationCropInfo } from 'store/salesFlow/thunks';
import { RootState } from 'store';
import { CORN, SOYBEANS } from 'constants/variables';
import { CROP_PROTECTION } from 'constants/results';

import FlowTracker from './FlowTracker';
import Overview from './Overview/Container';
import Styles from './Container.module.css';
import Benefits from './Benefits/Container';
import FarmPlan from './FarmPlan/Container';
import Report from './Report/Container';
import Offer from './Offer/Container';

const RiskEstimateContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const flowTrackerSteps = getFlowTrackerSteps(language);
  const [operationDetails, setOperationDetails] = useState<OperationDetailsType>({
    isOperationCreated: false,
    operation_id: null,
    operationName: '',
    ctfips: null,
    corn: defaultCornInfo,
    soybeans: defaultSoybeanInfo,
  });
  const [selectedStep, setSelectedStep] = useState(flowTrackerSteps[0]);

  const { salesFlowMeta, operationCropInfo, isLoading } = useSelector((state: RootState) => ({
    salesFlowMeta: state.salesFlow.meta,
    isLoading: state.salesFlow.isFetching,
    operationCropInfo: operationDetails.operation_id
      ? state.salesFlow.byOperationId[operationDetails.operation_id]
      : null,
  }));

  useEffect(() => {
    dispatch(getSalesFlowMeta());
  }, []);

  const showErrorMessage = (textStr: string) => showToast(textStr, 'error', 5000);

  const validateAndSetOperationCropDetails = (setNextStep: Function) => {
    const isNotValidStr = validateFarmPlan(operationDetails, language);
    if (isNotValidStr) {
      return showErrorMessage(isNotValidStr);
    }
    if (operationDetails.operation_id && operationDetails.ctfips) {
      dispatch(
        postOperationCropInfo(
          operationDetails.operation_id,
          {
            operation_id: operationDetails.operation_id,
            ctfips: operationDetails.ctfips,
            crop_plans: [CORN, SOYBEANS].map((crop) => ({
              ...operationDetails[crop],
              acreage: Number(operationDetails[crop].acreage),
              target_yield: Number(operationDetails[crop].target_yield),
              price_per_bushel: Number(operationDetails[crop].price_per_bushel),
            })),
          },
          setNextStep,
          () => showErrorMessage(getString('postOperationCropInfoErrorMsg', language)),
        ),
      );
    }
  };

  const goToNextStep = async () => {
    const nextStep = flowTrackerSteps[selectedStep.index + 1];
    if (selectedStep.index === 2) {
      validateAndSetOperationCropDetails(() => setSelectedStep(nextStep));
    } else {
      setSelectedStep(nextStep);
    }
  };

  const slideDisplay = (() => {
    switch (selectedStep.index) {
      case 1:
        return <Benefits />;
      case 2:
        return (
          <FarmPlan operationDetails={operationDetails} setOperationDetails={setOperationDetails} />
        );
      case 3:
        if (operationDetails.operation_id) {
          return <Report operationId={operationDetails.operation_id} />;
        }
        return null;
      case 4:
        if (operationDetails.operation_id) {
          return <Offer operationId={operationDetails.operation_id} />;
        }
        return null;
      default:
        return <Overview />;
    }
  })();

  const headerTitle = operationCropInfo
    ? `- ${operationCropInfo.crop_plans[0].operation.name}`
    : '';

  const displayInsideButtonGroup = selectedStep.index < 3;
  const displayLastButton = selectedStep.index < flowTrackerSteps.length - 1;

  if (!salesFlowMeta) {
    return <Spinner className={Styles.Spinner} />;
  }

  return (
    <div>
      <Header title={`Pattern ${getString('riskEstimate', language)} ${headerTitle}`} />
      <FlowTracker
        selectedStep={selectedStep}
        setSelectedStep={setSelectedStep}
        flowTrackerSteps={flowTrackerSteps}
      />
      <div className={Styles.Wrapper}>{slideDisplay}</div>
      <div className={Styles.ButtonGroup}>
        <div className={displayInsideButtonGroup ? undefined : Styles.NotVisible}>
          <Button
            onClick={() =>
              window.open(`/results/field/541/3485/3325/${CROP_PROTECTION}?crop=${CORN}`, '_blank')
            }
          >
            {getString('viewSampleReport', language)}
          </Button>
        </div>
        <div className={Styles.NextStepWrapper}>
          {isLoading && <Spinner className={Styles.SmallSpinner} />}
          <Button
            className={displayLastButton ? undefined : Styles.NotVisible}
            primary
            disabled={isLoading}
            onClick={goToNextStep}
          >
            {selectedStep.bottomButtonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RiskEstimateContainer;
