import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { OperationDetailsType } from 'store/salesFlow/types';
import { RoundedCornIcon, RoundedSoybeansIcon } from 'common';
import { getString } from 'strings/translation';
import { SelectType } from 'util/generalTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import OperationInfo from './OperationInfo';
import Styles from './Container.module.css';
import CropInfo from './CropInfo';
import SlideStyles from '../Shared.module.css';

interface FarmPlanContainerProps {
  operationDetails: OperationDetailsType;
  setOperationDetails: Function;
}

const FarmPlanContainer = ({ operationDetails, setOperationDetails }: FarmPlanContainerProps) => {
  const language = useBroswerLanguage();

  const { operationCropInfo } = useSelector((state: RootState) => ({
    operationCropInfo: operationDetails.operation_id
      ? state.salesFlow.byOperationId[operationDetails.operation_id]
      : null,
  }));

  const updateOperationDetails = (
    crop: string,
    key: string,
    value: SelectType | string | boolean,
  ) => {
    setOperationDetails((old: OperationDetailsType) => ({
      ...old,
      [crop]: {
        ...old[crop],
        [key]: value,
      },
    }));
  };

  const getOperationCountyName = () => {
    if (operationDetails.operation_id === operationCropInfo?.crop_plans[0].operation.id) {
      return `${getString('operation', language)}: ${
        operationCropInfo.crop_plans[0].operation.name
      }, ${getString('county', language)}: ${
        operationCropInfo.crop_plans[0].county.county
      }, ${operationCropInfo.crop_plans[0].county.state}`;
    }
    return '';
  };

  return (
    <div>
      <div className={SlideStyles.Header}>{getString('startedWhichOperation', language)} </div>
      <div className={Styles.ExistingOperation}>
        <b>{getOperationCountyName()}</b>
      </div>
      <OperationInfo
        operationDetails={operationDetails}
        setOperationDetails={setOperationDetails}
      />
      <div className={Styles.ThickBottomBorder} />
      <div className={SlideStyles.Header}>
        <b>{getString('estimateYourRisk', language)}</b>
      </div>
      <CropInfo
        operationDetails={operationDetails}
        updateOperationDetails={updateOperationDetails}
        icon={<RoundedCornIcon className={Styles.IconDisplay} />}
        crop="corn"
        cropText={getString('cornPlan', language)}
      />
      <CropInfo
        operationDetails={operationDetails}
        updateOperationDetails={updateOperationDetails}
        icon={<RoundedSoybeansIcon className={Styles.IconDisplay} />}
        crop="soybeans"
        cropText={getString('soybeansPlan', language)}
      />
    </div>
  );
};

export default FarmPlanContainer;
