import React from 'react';

import { Button, Table } from '@mantine/core';
import { capitalizeEveryWord } from 'util/stringUtils';
import { User } from 'store/user/types';
import { userFullName } from 'util/userRoles';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import styles from './UsersTable.module.css';

interface UsersTableProps {
  currentUserId: number;
  removeUser: (arg0: string) => void;
  users: User[];
}

const UsersTable = ({ currentUserId, removeUser, users }: UsersTableProps) => {
  const language = useBroswerLanguage();
  const sortedUsers = [...users].sort((a, b) => {
    return userFullName(a).localeCompare(userFullName(b), 'en', {
      sensitivity: 'base',
    });
  });

  return (
    <Table>
      <Table.Thead className={styles.TableHeader}>
        <Table.Tr>
          <Table.Th>{getString('name', language)}</Table.Th>
          <Table.Th>{getString('email', language)}</Table.Th>
          <Table.Th>{getString('role', language)}</Table.Th>
          <Table.Th className={styles.ShrinkedColumn} />
        </Table.Tr>
      </Table.Thead>
      {sortedUsers.map((user) => (
        <Table.Tr key={user.id}>
          <Table.Td>{userFullName(user)}</Table.Td>
          <Table.Td>{user.email}</Table.Td>
          <Table.Td>
            {user.roles.length
              ? capitalizeEveryWord(user.roles[0].name.replace('_', ' '))
              : NOT_APPLICABLE}
          </Table.Td>
          <Table.Td className={styles.ShrinkedColumn}>
            <Button
              data-test-id="remove-access"
              color="darkRed"
              onClick={() => removeUser(user.email)}
            >
              {currentUserId === user.id
                ? getString('leaveOperation', language)
                : getString('removeAccess', language)}
            </Button>
          </Table.Td>
        </Table.Tr>
      ))}
    </Table>
  );
};

export default UsersTable;
