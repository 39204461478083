import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Container.module.css';

type ContainerProps = {
  className?: string;
  dataTestId?: string;
  vertical?: Boolean;
  children?: ReactNode;
};

export const Container: FunctionComponent<ContainerProps> = ({
  children,
  className,
  vertical,
  dataTestId,
}) => (
  <div
    className={classNames(
      styles.Container,
      {
        [styles.Vertical]: vertical,
      },
      className,
    )}
    data-test-id={dataTestId}
  >
    {children}
  </div>
);
