import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FilesTable, FileUploadButton } from 'common';
import showToast from 'actions/toastActions';
import { BatchType } from 'store/batches/types';
import { getBatchFiles, uploadBatchFile } from 'store/batches/thunks';

type BatchResultsPropsType = {
  batch: BatchType;
};

export const BatchResults = ({ batch }: BatchResultsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatchFiles(batch.id));
  }, [dispatch]);

  const handleUpload = async (file: File) => {
    try {
      await dispatch(uploadBatchFile(batch.id, file));
    } catch (error) {
      showToast(getString('uploadFailedMsg', language), 'error');
    }
  };

  return (
    <FilesTable title={getString('results', language)} files={batch.uploaded_files}>
      <FileUploadButton
        buttonLabel={getString('uploadResults', language)}
        onUpload={handleUpload}
      />
    </FilesTable>
  );
};
