import { FieldPropertiesType } from 'store/fields/types';
import { SamplePlanType } from 'store/samplePlans/types';

export type OrderHistorySamplePlanType = {
  id: SamplePlanType['id'];
  name: SamplePlanType['name'];
  created_at: SamplePlanType['created_at'];
  sampled_at: SamplePlanType['sampled_at'];
  crop_year: SamplePlanType['crop_year'];
  status: SamplePlanType['sampling_status'];
  price_summary: SamplePlanType['price_summary'];
  plan_type: SamplePlanType['plan_type'];
};

export type OrderHistoryFieldType = {
  id: FieldPropertiesType['id'];
  name: FieldPropertiesType['name'];
  farm_name: FieldPropertiesType['farm_name'];
  calculated_area_acres: number;
  sampling_plans: OrderHistorySamplePlanType[];
};

export type OrderHistoryType = {
  id: number;
  name: string;
  fields: OrderHistoryFieldType[];
};

export type OrderHistoryStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  paginated: {
    items: OrderHistoryType[];
    page: number;
  };
};

export const REQUEST_ORDER_HISTORY = 'REQUEST_ORDER_HISTORY';
export const RECEIVE_ORDER_HISTORY = 'RECEIVE_ORDER_HISTORY';
export const ORDER_HISTORY_REQUEST_ERROR = 'ORDER_HISTORY_REQUEST_ERROR';

type RequestOrderHistoryType = {
  type: typeof REQUEST_ORDER_HISTORY;
};

type ReceiveOrderHistoryType = {
  type: typeof RECEIVE_ORDER_HISTORY;
  payload: {
    page: number;
    items: OrderHistoryType[];
  };
};

type OrderHistoryRequestErrorType = {
  type: typeof ORDER_HISTORY_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type OrderHistoryActionsType =
  | RequestOrderHistoryType
  | ReceiveOrderHistoryType
  | OrderHistoryRequestErrorType;
