import React from 'react';
import { ComboboxOptionProps, Select } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Agency, ContractType } from 'store/agencies/types';
import { formatDateMonthDayYear } from 'util/date';

type ParentContractPropsType = {
  agency: Agency;
  contract: Partial<ContractType>;
  setContractForm: (contract: Partial<ContractType>) => void;
};

export const ParentContractSelect = ({
  contract,
  agency,
  setContractForm,
}: ParentContractPropsType) => {
  const language = useBroswerLanguage();

  const parentContracts = agency.parent_pricing_contracts;

  const parentContractList = [
    {
      contract: null,
      value: 'none',
      label: getString('new', language),
    },
    ...(parentContracts || []).map((c) => ({
      contract: c,
      value: String(c.id),
      label: `${getString(c.contract_type, language)} - ${formatDateMonthDayYear(c.expired_at, language)}`,
    })),
  ];

  const loadContractFormByExisting = (label: string | null, option: ComboboxOptionProps) => {
    const existingContract = parentContractList.find(
      (o) => o.value === option?.value || o.label === label,
    )?.contract;
    if (existingContract) {
      setContractForm({
        ...existingContract,
        is_not_editable: true,
      });
    }
  };

  return (
    <Select
      label={getString('newOrParentContract', language)}
      onChange={loadContractFormByExisting}
      value={contract.parent_contract_id ? String(contract.parent_contract_id) : 'none'}
      data={parentContractList}
      disabled={Boolean(contract.agency_id && contract.agency_id !== agency.id && contract)}
    />
  );
};
