import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ColumnSorter, IconInput, Spinner, Table, TableCol, TableRow } from 'common';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { getAllRecommendationSets } from 'store/recommendationSets/thunks';
import { RootState } from 'store';

import { useNavigate } from 'react-router-dom';
import styles from './Container.module.css';

const RecommendationsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchRef = useRef<HTMLInputElement>(null);

  const { recommendationSets, hasFailed, hasFetched, isFetching } = useSelector(
    (state: RootState) => ({
      recommendationSets: state.recommendationSets.allSets,
      hasFailed: state.recommendationSets.hasFailed,
      hasFetched: state.recommendationSets.hasFetched,
      isFetching: state.recommendationSets.isFetching,
    }),
  );

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getAllRecommendationSets());
  }, []);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const [sorting, setSorting] = useState({
    attribute: 'created_at',
    order: 'desc',
  });

  const sortSetList = useCallback(
    (
      setsList: RecommendationSetType[],
      sortingValue: {
        attribute: keyof RecommendationSetType;
        order: 'asc' | 'desc';
      },
    ): RecommendationSetType[] =>
      setsList.sort(
        sorting.order === 'asc'
          ? (a, b) =>
              // @ts-ignore
              a[sortingValue.attribute]?.localeCompare(b[sortingValue.attribute])
          : (a, b) =>
              // @ts-ignore
              b[sortingValue.attribute]?.localeCompare(a[sortingValue.attribute]),
      ),
    [sorting.order],
  );

  const filterSetList = (setsList: RecommendationSetType[], searchName: string) =>
    setsList.filter((s) => s.name.toLowerCase().includes(searchName.toLowerCase()));

  const sortedSets = useMemo(() => {
    if (search === '') {
      // @ts-ignore
      return sortSetList([...recommendationSets], sorting);
    }
    // @ts-ignore
    return sortSetList(filterSetList([...recommendationSets], search), sorting);
  }, [recommendationSets, search, sortSetList, sorting]);

  if (isFetching) {
    return <Spinner fill />;
  }

  return (
    <div>
      <div className={styles.Toolbar}>
        <h2>Manage Recommendation Sets </h2>
        <div>
          <Button primary onClick={() => navigate('/product/recommendation_sets/create')}>
            New Set
          </Button>
        </div>
        <div className={styles.Search}>
          <IconInput
            icon="SearchIcon"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            ref={searchRef}
          />
        </div>
      </div>
      <Table className={styles.Table}>
        <TableRow header className={styles.TableHeader}>
          <TableCol>
            <ColumnSorter
              className={styles.RecSetName}
              sorting={sorting}
              setSorting={setSorting}
              attribute="name"
            >
              Set Name
            </ColumnSorter>
          </TableCol>
          <TableCol>Visible</TableCol>
          <TableCol>Agency</TableCol>
        </TableRow>
        {hasFetched &&
          !hasFailed &&
          sortedSets.map((row: RecommendationSetType) => (
            <TableRow
              key={row.id}
              onClick={() => navigate(`/product/recommendation_sets/${row.id}`)}
            >
              <TableCol>{row.name}</TableCol>
              <TableCol>{String(row.show_in_ui)}</TableCol>
              <TableCol>{row?.agency?.name}</TableCol>
            </TableRow>
          ))}
      </Table>
    </div>
  );
};

export default RecommendationsContainer;
