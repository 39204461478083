import React, { useMemo } from 'react';
import { Box, Button, SimpleGrid, Text } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType } from 'store/analytics/types';
import { getRiskColorFill } from 'util/chartUtils';
import { getFieldRisk, getPlanAnalytic } from 'util/results';
import { SamplingPlanType } from 'store/fields/types';
import { NO_DATA, NOT_ANALYZED, NOT_DETECTED } from 'constants/fieldRisks';
import styles from './AnalyticBar.module.css';

type PanelPropsType = {
  activeAnalytic: AnalyticType;
  samplingPlan: SamplingPlanType;
  onClick?: () => void;
  displayRiskLevels?: string[];
  textSize?: string;
};

const AnalyticBar = ({
  activeAnalytic,
  samplingPlan,
  onClick,
  displayRiskLevels,
  textSize,
}: PanelPropsType) => {
  const language = useBroswerLanguage();
  const planAnalytic = getPlanAnalytic(samplingPlan, activeAnalytic.category, activeAnalytic.id);
  const risk = useMemo(
    () => getFieldRisk(planAnalytic, samplingPlan, activeAnalytic),
    [activeAnalytic, planAnalytic, samplingPlan],
  );

  const riskColorFill = (() =>
    planAnalytic && (displayRiskLevels?.includes(planAnalytic.risk_level) || !displayRiskLevels)
      ? getRiskColorFill(planAnalytic.risk_level)
      : undefined)();

  return (
    <Box
      className={styles.AnalyticHeader}
      style={{
        backgroundColor: riskColorFill,
      }}
    >
      <SimpleGrid cols={2} className={styles.AnalyticHeaderText}>
        <Box className={styles.Center}>
          <Text size={textSize || 'md'} fw={700}>
            {activeAnalytic.name}
          </Text>
        </Box>
        <Box className={styles.Center}>
          <Text size={textSize || 'md'}>
            <b>{getString('risk', language)}:</b> {getString(risk, language)}
          </Text>
        </Box>
      </SimpleGrid>
      {onClick && ![NO_DATA, NOT_DETECTED, NOT_ANALYZED].includes(risk) && (
        <Button
          variant={riskColorFill ? 'white' : 'outline'}
          className={styles.HideForPrint}
          onClick={onClick}
        >
          {getString('viewDetails', language)}
        </Button>
      )}
    </Box>
  );
};

export default AnalyticBar;
