import { AppThunk } from 'store';
import showToast from 'actions/toastActions';
import {
  requestGetAllPlates,
  requestCreatePlate,
  requestUpdatePlate,
  requestGetPlateWithDetails,
  requestDeletePlate,
  requestGenerateQPCRSampleSheet,
  requestDownloadHomogenizationDetails,
  requestDeleteWell,
  requestCreateWell,
  requestGetHomogenRelatedPlates,
  requestGenerateBarcodeSheet,
  requestPutMarkPlateArrived,
} from './requests';
import {
  platesRequestError,
  receiveHomogenRelatedPlates,
  receivePlate,
  receivePlates,
  receivePlateWell,
  requestPlates,
  requestPlatesInfo,
} from './actions';
import { PlateType, StorePlateData, WellType } from './types';

export const getPlates =
  (
    page: number,
    barcode?: string,
    plateType?: string[],
    analysisType?: string[],
    status?: string[],
    labId?: string[],
    sortBy?: string,
    order?: string,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const results = await requestGetAllPlates({
        page,
        barcode,
        plateType,
        analysisType,
        status,
        labId,
        sortBy,
        order,
      });
      dispatch(receivePlates({ ...results }));
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const createPlate =
  (body: Partial<PlateType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const plate = await requestCreatePlate(body);
      dispatch(receivePlate(plate));
    } catch (e) {
      await dispatch(platesRequestError({ message: e.message }));
      throw e;
    }
  };

export const getPlate =
  (barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const plate = await requestGetPlateWithDetails(barcode);
      dispatch(receivePlate(plate));
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const getHomogenizationRelatedPlates =
  (barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlatesInfo());
    try {
      const plates = await requestGetHomogenRelatedPlates(barcode);
      dispatch(receiveHomogenRelatedPlates(barcode, plates));
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const updatePlate =
  (
    barcode: string,
    body: Partial<PlateType> | PlateType['meta_data'] | Partial<StorePlateData>,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const plate = await requestUpdatePlate(barcode, body);
      dispatch(receivePlate(plate));
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const deletePlate =
  (barcode: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const plate = await requestDeletePlate(barcode);
      dispatch(receivePlate(plate));
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const downloadPlateDetails =
  (barcode: string): AppThunk =>
  async () => {
    try {
      await requestDownloadHomogenizationDetails(barcode);
    } catch (e) {
      showToast(e.message);
    }
  };

export const generateQPCRSampleSheet =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestGenerateQPCRSampleSheet(id);
      if (!response.sample_sheet_url) {
        throw Error(`Sample Sheet URL is not present`);
      }
      return response;
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const generateBarcodeSheet =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestGenerateBarcodeSheet(id);
      if (!response.barcode_sheet_url) {
        throw Error(`Sample Sheet URL is not present`);
      }
      return response;
    } catch (e) {
      dispatch(platesRequestError({ message: e.message }));
    }
  };

export const addWellToPlate =
  (plateBarcode: string, attributes: Partial<WellType>): AppThunk =>
  async (dispatch) => {
    try {
      if (attributes.id !== 0 && attributes.id !== undefined) {
        await requestDeleteWell(attributes.id);
      }

      // TODO: Add support on API so all requests have the same data structure.
      const newAttributes = {
        ...attributes,
        notes: attributes.lab_notes,
        replicate_type: attributes?.replica_type,
      };

      const response = await requestCreateWell(plateBarcode, newAttributes);
      dispatch(receivePlateWell(plateBarcode, response));
    } catch (err) {
      throw err;
    }
  };

export const clearPlateWell =
  (plateBarcode: string, wellId: number): AppThunk =>
  async (dispatch) => {
    try {
      await requestDeleteWell(wellId);
      return dispatch(getPlate(plateBarcode));
    } catch (err) {
      throw err;
    }
  };

export const markPlateArrived =
  (barcode: string, isArrived: boolean = true): AppThunk =>
  async (dispatch) => {
    dispatch(requestPlates());
    try {
      const plate = await requestPutMarkPlateArrived(barcode, isArrived);
      dispatch(receivePlate(plate));
    } catch (e) {
      showToast(e.message, 'error');
      return dispatch(platesRequestError({ message: e.message }));
    }
  };
