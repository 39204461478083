import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Button, Input, Selector } from 'common';
import { getControlTypeOptions } from 'util/controls';
import { createControl } from 'store/controls/thunks';

import styles from './ControlCreation.module.css';

const ControlCreation = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [typeIndex, setTypeIndex] = useState(0);
  const [zymoDilution, setZymoDilution] = useState('');
  const [soilDilution, setSoilDilution] = useState('');
  const { isFetching } = useSelector((state: RootState) => ({
    isFetching: state.controls.isFetching,
  }));

  const controlTypeOptions = getControlTypeOptions(language);

  const createInputHandler =
    (inputChangeHandler: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      inputChangeHandler(event.target.value);
    };

  const isFormValid = (() => {
    if (!name) {
      return false;
    }

    const isDilutionValid = (dilution: string) => Number(dilution) > 0;

    if (typeIndex === 0) {
      return zymoDilution && isDilutionValid(zymoDilution);
    }

    if (typeIndex === 1) {
      return soilDilution && isDilutionValid(soilDilution);
    }

    return isDilutionValid(zymoDilution) && isDilutionValid(soilDilution);
  })();

  const clearInputs = () => {
    setName('');
    setDescription('');
    setTypeIndex(0);
    setZymoDilution('');
    setSoilDilution('');
  };

  const handleCreate = async () => {
    const control = {
      barcode: name,
      notes: description,
      dilution: {
        zymo: Number(zymoDilution) || null,
        soil: Number(soilDilution) || null,
      },
    };
    await dispatch(createControl(control));
    clearInputs();
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.InputArea}>
        <div className={styles.InputRow}>
          <span className={styles.BigColumn}>{getString('name', language)}</span>
          <span className={styles.BigColumn}>{getString('description', language)}</span>
          <span className={styles.SmallColumn}>{getString('type', language)}</span>
          <span className={styles.SmallColumn}>{getString('zymoDilution', language)}</span>
          <span className={styles.SmallColumn}>{getString('soilDilution', language)}</span>
        </div>
        <div className={styles.InputRow}>
          <Input
            className={styles.BigColumn}
            onChange={createInputHandler(setName)}
            placeholder={getString('enterName', language)}
            type="Text"
            value={name}
          />
          <Input
            className={styles.BigColumn}
            onChange={createInputHandler(setDescription)}
            placeholder={getString('enterDescription', language)}
            type="Text"
            value={description}
          />
          <Selector
            activeIndex={typeIndex}
            className={styles.SmallColumn}
            onChange={setTypeIndex}
            options={controlTypeOptions}
          />
          <Input
            className={styles.SmallColumn}
            onChange={createInputHandler(setZymoDilution)}
            placeholder={getString('dilution', language)}
            type="Text"
            value={zymoDilution}
          />
          <Input
            className={styles.SmallColumn}
            onChange={createInputHandler(setSoilDilution)}
            placeholder={getString('dilution', language)}
            type="Text"
            value={soilDilution}
          />
        </div>
      </div>
      <Button
        className={styles.Submit}
        onClick={handleCreate}
        disabled={isFetching || !isFormValid}
        primary
      >
        {getString('createControl', language)}
      </Button>
    </div>
  );
};

export default ControlCreation;
