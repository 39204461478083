import React, { ReactNode } from 'react';
import { Divider, DividerProps, Group, GroupProps, Stack, Title, TitleProps } from '@mantine/core';

type HeaderPropsType = Omit<TitleProps, 'title'> & {
  title: ReactNode | string;
  divider?: DividerProps['size'];
  children?: ReactNode;
  childrenProps?: GroupProps;
};

export const Header = ({
  title,
  divider = 'md',
  order = 2,
  children,
  childrenProps,
  ...props
}: HeaderPropsType) => {
  return (
    <Stack>
      <Group gap={0} justify="space-between">
        <Title {...props} order={order} textWrap="nowrap">
          {title}
        </Title>
        <Group {...childrenProps} wrap="nowrap">
          {children}
        </Group>
      </Group>
      <Divider size={divider} />
    </Stack>
  );
};
