import React, { useEffect, useState } from 'react';
import { Box, Center, Loader, NavLink, Stack, Text, Title } from '@mantine/core';
import { isProduction } from 'util/request';
import { WEB_FRONTEND } from 'constants/deployment';

import { getDiff } from './requests';

import styles from './Container.module.css';

type DiffElement = {
  pr: string;
  title: string;
};

interface ReleaseDashboardPayload {
  demeter: {
    merged_unbuilt: DiffElement[];
    on_production: DiffElement;
    on_stage: DiffElement[];
  };
  [WEB_FRONTEND]: {
    merged_unbuilt: DiffElement[];
    on_production: DiffElement;
    on_stage: DiffElement[];
  };
}

const ReleaseDashboard = () => {
  const [diff, setDiff] = useState<ReleaseDashboardPayload | null>();

  useEffect(() => {
    const fetchAndStoreDiff = async () => {
      const response: ReleaseDashboardPayload = await getDiff();
      setDiff(response);
    };
    if (!isProduction) {
      fetchAndStoreDiff();
    }
  }, []);

  if (isProduction) {
    return (
      <Center>
        <Text>Stage Only</Text>
      </Center>
    );
  }

  if (!diff) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const renderElement = (element: DiffElement) => (
    <Box>
      <Title order={4}>{element.title}</Title>
      <NavLink href={element.pr} label={element.pr} />
    </Box>
  );

  return (
    <Stack>
      <Title fw={700} order={1}>
        Release Dashboard
      </Title>
      <Title fw={700} order={2}>
        Web
      </Title>
      <Stack className={styles.Summary}>
        <Title fw={700} order={3}>
          On Production
        </Title>
        {renderElement(diff[WEB_FRONTEND].on_production)}
        <Title fw={700} order={3}>
          On Stage
        </Title>
        {diff[WEB_FRONTEND].on_stage.length ? (
          diff[WEB_FRONTEND].on_stage.map(renderElement)
        ) : (
          <Text>None</Text>
        )}
        <Title fw={700} order={3}>
          Merged Unbuilt
        </Title>
        {diff[WEB_FRONTEND].merged_unbuilt.length ? (
          diff[WEB_FRONTEND].merged_unbuilt.map(renderElement)
        ) : (
          <Text>None</Text>
        )}
      </Stack>
      <Title fw={700} order={2}>
        Demeter
      </Title>
      <Stack className={styles.Summary}>
        <Title fw={700} order={3}>
          On Production
        </Title>
        {renderElement(diff.demeter.on_production)}
        <Title fw={700} order={3}>
          On Stage
        </Title>
        {diff.demeter.on_stage.length ? (
          diff.demeter.on_stage.map(renderElement)
        ) : (
          <Text>None</Text>
        )}
        <Title fw={700} order={3}>
          Merged Unbuilt
        </Title>
        {diff.demeter.merged_unbuilt.length ? (
          diff.demeter.merged_unbuilt.map(renderElement)
        ) : (
          <Text>None</Text>
        )}
      </Stack>
    </Stack>
  );
};

export default ReleaseDashboard;
