import React from 'react';
import { FiChevronRight, FiCheck } from 'react-icons/fi';
import classNames from 'classnames';
import { SalesFlowStepType } from 'store/salesFlow/types';
import Styles from './FlowTracker.module.css';

interface FlowTrackerProps {
  selectedStep: SalesFlowStepType;
  setSelectedStep: (val: SalesFlowStepType) => void;
  flowTrackerSteps: SalesFlowStepType[];
}

const FlowTracker = ({ selectedStep, setSelectedStep, flowTrackerSteps }: FlowTrackerProps) => {
  const getTitleStyle = (stepIndex: number) => {
    if (selectedStep.index > stepIndex) {
      return Styles.FlowItemSpanSelected;
    }
    if (selectedStep.index === stepIndex) {
      return classNames(Styles.FlowItemSpanSelected, Styles.MarginRight);
    }

    return Styles.MarginRight;
  };

  return (
    <div className={Styles.Wrapper}>
      {flowTrackerSteps.map((step) => (
        <div
          className={Styles.FlowItem}
          key={step.index}
          onClick={() => step.index < selectedStep.index && setSelectedStep(step)}
        >
          <span className={getTitleStyle(step.index)}>{step.title}</span>{' '}
          {selectedStep.index > step.index && <FiCheck className={Styles.MarginRight} />}
          {step.index < flowTrackerSteps.length - 1 && <FiChevronRight />}
        </div>
      ))}
    </div>
  );
};

export default FlowTracker;
