import React from 'react';
import { TableCol, TableRow } from 'common';
import { MdPlayCircleOutline } from 'react-icons/md';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import classNames from 'classnames';
import { CropReportType } from 'store/salesFlow/types';
import { displayPriceOrBar } from 'util/salesFlow';
import AnalyticsRows from './AnalyticsRows';
import Styles from './ReportTable.module.css';

interface CropRowProps {
  cropInfo: CropReportType;
  index: number;
  openIndex: number | null;
  setOpenIndex: (index: number | null) => void;
}

const CropRow = ({ cropInfo, index, openIndex, setOpenIndex }: CropRowProps) => {
  const language = useBroswerLanguage();

  return (
    <>
      <TableRow borderless>
        <TableCol size="x2">
          <div className={Styles.ColumnLeft}>
            {getString(cropInfo.crop, language)} ({cropInfo.acreage}ac){' '}
            <MdPlayCircleOutline
              className={openIndex === index ? Styles.RotatedIcon : undefined}
              size="1.2rem"
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            />
          </div>
        </TableCol>
        <TableCol className={Styles.Column}>
          {displayPriceOrBar(cropInfo.summary.boost_yield, '/ ac')}
        </TableCol>
        <TableCol className={Styles.Column}>
          {displayPriceOrBar(cropInfo.summary.optimize_spend, '/ ac')}
        </TableCol>
        <TableCol className={classNames(Styles.Column, Styles.ColumnEnd)}>
          {displayPriceOrBar(cropInfo.summary.total_return, '/ ac')}
        </TableCol>
      </TableRow>
      {openIndex === index && <AnalyticsRows cropInfo={cropInfo} />}
    </>
  );
};

export default CropRow;
