import {
  DNAConcentrationsType,
  DNA_CONCENTRATIONS_REQUEST_ERROR,
  RECEIVE_DNA_CONCENTRATIONS,
  REQUEST_DNA_CONCENTRATIONS,
  UPDATE_DNA_CONCENTRATIONS,
} from './types';

export const requestDNAConcentrations = () => ({
  type: REQUEST_DNA_CONCENTRATIONS,
});

export const receiveDNAConcentrations = (
  plateBarcode: string,
  concentrations: DNAConcentrationsType[],
) => ({
  type: RECEIVE_DNA_CONCENTRATIONS,
  payload: {
    plateBarcode,
    concentrations,
  },
});

export const updateDNAConcentrations = (concentration: DNAConcentrationsType[]) => ({
  type: UPDATE_DNA_CONCENTRATIONS,
  payload: { concentration },
});

export const dnaConcentrationsRequestError = (payload: { message: string }) => ({
  type: DNA_CONCENTRATIONS_REQUEST_ERROR,
  payload,
});
