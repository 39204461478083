import React, { useCallback, useEffect, useState } from 'react';
import { Label } from 'common';
import { getString } from 'strings/translation';
import useBrowserLanguage from 'util/hooks/useLanguage';
import { OperationType } from 'store/operation/types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import showToast, { type ToastType } from 'actions/toastActions';
import {
  postCNHICallback,
  postFVCallback,
  postJDCallback,
  requestCNHIConnect,
  requestFVConnect,
  requestJDConnect,
  postAgLeaderCallback,
  requestAgLeaderConnect,
} from 'store/operation/requests';
import { getLocalStorage, setLocalStorage } from 'util/localStorage';
import { JD_CONNECT_CODE, JD_LOGOUT_URL } from 'constants/auth';
import {
  accountDisplayNames,
  CNHI,
  FIELDVIEW,
  JOHN_DEERE,
  AG_LEADER,
} from 'constants/connectAccounts';
import { getConnectedAccountsInfo, setActiveOperation } from 'store/operation/thunks';
import ConnectedAccountsTable from './AccountsTable';
import styles from './Container.module.css';

interface ConnectedAccountsProps {
  operationId: number;
  operation: OperationType;
  connectAccount?: string;
}

const Container = ({ operationId, operation, connectAccount }: ConnectedAccountsProps) => {
  const language = useBrowserLanguage();
  const [loading, setLoading] = useState<string | null>(null);
  const [logoutJD, setLogoutJD] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setToastMessage = useCallback(
    (message: string, type?: ToastType, time: number = 3000) => {
      showToast(message, type, time);
    },
    [dispatch],
  );

  const handleError = useCallback(
    (message) => {
      navigate('/connected-accounts');
      setToastMessage(message, 'error', 5000);
    },
    [navigate, setToastMessage],
  );

  const postJDCode = useCallback(
    async (code: string, operation_id: number) => {
      try {
        setLoading(JOHN_DEERE);
        await postJDCallback({ code, operation_id });
        const { url } = await requestJDConnect({ operation_id });
        if (url) {
          window.open(url, '_self');
        } else {
          handleError(getString('serverErrorMsg', language));
        }
      } catch (err) {
        handleError(getString('serverErrorMsg', language));
      }
    },
    [handleError, language],
  );

  const postCodeOnly = useCallback(
    async (accountType: string, code: string) => {
      // Just send the code to the backend so it can use it
      try {
        setLoading(accountType);
        if (accountType === JOHN_DEERE) {
          await postJDCallback({ code });
        } else if (accountType === FIELDVIEW) {
          await postFVCallback({ code, operation_id: operationId });
        } else if (accountType === CNHI) {
          await postCNHICallback({
            response_url: window.location.href,
            operation_id: operationId,
          });
        } else if (accountType === AG_LEADER) {
          await postAgLeaderCallback({ code, operation_id: operationId });
        }
        dispatch(getConnectedAccountsInfo(operationId));
        setToastMessage(getString('patternIsSyncing', language), 'success', 10000);
        navigate('/connected-accounts');
      } catch (err) {
        handleError(getString('serverErrorMsg', language));
      }
    },
    [setToastMessage, handleError, navigate, dispatch, operationId, language],
  );

  const handleAuthentication = useCallback(
    async (code, id) => {
      setLocalStorage(JD_CONNECT_CODE, code);
      const numericId = Number(id);
      dispatch(setActiveOperation(numericId));
      await postJDCode(code, numericId);
    },
    [dispatch, postJDCode],
  );

  useEffect(() => {
    if (connectAccount) {
      const jdConnectCode = getLocalStorage(JD_CONNECT_CODE);
      // For return from auth urls, get data in header
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const id = params.get('state');
      const error = params.get('error');
      if (connectAccount === JOHN_DEERE && code && id && jdConnectCode !== code) {
        // Coming back from authentication url
        handleAuthentication(code, id);
      } else if (code && (!id || connectAccount !== JOHN_DEERE)) {
        // Coming back from authorization url
        postCodeOnly(connectAccount, code);
      } else if (id && error) {
        handleError(
          `${getString('pleaseRefreshUnableToConnect', language)} ${
            accountDisplayNames[connectAccount]
          }.`,
        );
      }
    }
  }, [
    dispatch,
    postJDCode,
    postCodeOnly,
    handleAuthentication,
    handleError,
    connectAccount,
    language,
  ]);

  const beginConnect = async (
    accountType: string,
    requestConnect: (body: { operation_id?: number }) => Promise<any>,
  ) => {
    try {
      // Get the URL for authentication, go to it
      setLoading(accountType);
      if (accountType === JOHN_DEERE) {
        setLogoutJD(true);
      }
      const { url } = await requestConnect({ operation_id: operationId });
      if (url) {
        window.open(url, '_self');
      } else {
        setToastMessage(
          `${getString('pleaseRefreshUnableToConnect', language)} ${
            accountDisplayNames[accountType]
          }.`,
          'error',
          5000,
        );
      }
    } catch (err) {
      handleError(
        `${getString('pleaseRefreshUnableToConnect', language)} ${
          accountDisplayNames[accountType]
        }.`,
      );
    }
  };

  const beginJDConnect = () => beginConnect(JOHN_DEERE, requestJDConnect);

  const beginFVConnect = () => beginConnect(FIELDVIEW, requestFVConnect);

  const beginCNHIConnect = () => beginConnect(CNHI, requestCNHIConnect);

  const beginAgLeaderConnect = () => beginConnect(AG_LEADER, requestAgLeaderConnect);

  return (
    <div className={styles.Container}>
      <div className={styles.CenterLabel}>
        <Label
          className={styles.Label}
          label={getString('connectOutsideAccountsImport', language)}
        />
      </div>
      {logoutJD && <iframe hidden title="JD Logout" src={JD_LOGOUT_URL} />}
      <div className={styles.TableWrapper}>
        <ConnectedAccountsTable
          beginJDConnect={beginJDConnect}
          beginFVConnect={beginFVConnect}
          beginCNHIConnect={beginCNHIConnect}
          beginAgLeaderConnect={beginAgLeaderConnect}
          operation={operation}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Container;
