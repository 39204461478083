import React, { useState } from 'react';
import { Group, Table, Tooltip } from '@mantine/core';
import { AlertType, SamplePlanTrackingType } from 'store/samplePlans/types';
import { displayDivide, endEllipsis, getCombinedString } from 'util/stringUtils';
import classNames from 'classnames';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSamplePlan, putSamplePlan } from 'store/samplePlans/requests';
import showToast, { type ToastType } from 'actions/toastActions';
import { getFieldGeometry } from 'store/fields/thunks';
import { RootState } from 'store';
import styles from './Container.module.css';
import TrackingInnerRow from './TrackingInnerRow';
import TrackingActionButton from './TrackingActionButton';
import CancelModal from '../Orders/FieldList/FullProductReport/common/CancelModal';
import AssignSampler from '../Orders/FieldList/FullProductReport/OrderButtons/AssignSampler';
import MarkReady from '../Orders/FieldList/FullProductReport/OrderButtons/MarkReady';
import { formatDateMonthDayYear } from '../../util/date';
import AlertsModal from './AlertsModal';
import { getOperation, getOperationUsers } from 'store/operation/thunks';
import { acToHectares, getAcreageUnitFromLang } from 'util/units';
import NotesPopover from './NotesPopover';
import { getProductList } from 'util/product';
import { simpleProductTypes } from 'constants/products';
import { generateStatusBubble } from 'common/Status';

type TrackingRowPropsType = {
  samplePlan: SamplePlanTrackingType;
  toggleInitSearch: (val: boolean) => void;
};

const TrackingRow = ({ samplePlan, toggleInitSearch }: TrackingRowPropsType) => {
  const language = useBroswerLanguage();

  const [displayExpanded, toggleDisplayExpanded] = useState(false);
  const [showCancelModal, toggleShowCancelModal] = useState(false);
  const [showMarkReadyModal, toggleMarkReadyModal] = useState(false);
  const [showMarkNotReadyModal, toggleMarkNotReadyModal] = useState(false);
  const [showAssignSamplerModal, toggleAssignSamplerModal] = useState(false);
  const [alertsModalData, toggleAlertsModalData] = useState({
    show: false,
    snooze: false,
  });
  const [assignSamplerSamplePlan, setAssignSamplerSamplePlan] =
    useState<SamplePlanTrackingType | null>(null);
  const dispatch = useDispatch();

  const { fieldGeometry, operation, operationUsers } = useSelector((state: RootState) => ({
    fieldGeometry: state.fieldGeometry.geometries[samplePlan.field_id],
    operation: state.operations.operationsById[samplePlan.operation_id],
    operationUsers: state.operations.operationsById[samplePlan.operation_id]?.operationUsers,
  }));

  const setToastMessage = (message: string, type?: ToastType, time?: number) => {
    showToast(message, type, time);
  };

  const cancelSamples = async () => {
    try {
      await deleteSamplePlan(samplePlan.id);
      setToastMessage(getString('samplePlanCancelledSuccess', language));
      toggleShowCancelModal(false);
      toggleInitSearch(true);
    } catch (e) {
      toggleShowCancelModal(false);
      setToastMessage(getString('cancelSamplePlanFailureMsg', language), 'error', 7000);
    }
  };

  const markNotReady = async () => {
    try {
      await putSamplePlan({ ready_to_sample: false }, samplePlan.id);
      setToastMessage(getString('markNotReadySuccess', language));
      toggleMarkNotReadyModal(false);
      toggleInitSearch(true);
    } catch (e) {
      toggleMarkNotReadyModal(false);
      setToastMessage(getString('markNotReadyFail', language), 'error', 7000);
    }
  };

  const openAssignSampler = async () => {
    if (!fieldGeometry) {
      dispatch(getFieldGeometry(samplePlan.field_id));
    }
    if (!operation || !operationUsers) {
      dispatch(getOperation(samplePlan.operation_id));
      dispatch(getOperationUsers(samplePlan.operation_id));
    }
    toggleAssignSamplerModal(true);
    setAssignSamplerSamplePlan(samplePlan);
  };

  const closeAssignSampler = () => {
    toggleAssignSamplerModal(false);
    toggleInitSearch(true);
  };

  const getProTillStatusText = () => {
    let emiText = 'N/A';
    let adcpText = 'N/A';
    if (samplePlan.is_pro || samplePlan.is_till_rx) {
      emiText = samplePlan.emi_collection_confirmed_at
        ? getString('yes', language)
        : getString('no', language);
    }
    if (samplePlan.is_till_rx) {
      adcpText = samplePlan.adcp_collection_confirmed_at
        ? getString('yes', language)
        : getString('no', language);
    }
    return `${emiText} / ${adcpText}`;
  };

  const getDateOrNone = (displayDate: string | null) => {
    return displayDate
      ? formatDateMonthDayYear(displayDate, language)
      : getString('none', language);
  };

  const getEstimatedScanningDate = () => {
    if (!samplePlan.is_pro && !samplePlan.is_till_rx) {
      return 'N/A';
    }
    return getDateOrNone(samplePlan.estimated_scanning_date);
  };

  const getEstimatedSamplingDate = () => {
    if (!samplePlan.sample_total_active_count) {
      return 'N/A';
    }
    return getDateOrNone(samplePlan.estimated_sampling_date);
  };

  const alerts = (samplePlan.alerts || [])
    .map((alert: AlertType) => getString(alert.name, language))
    .join('; ');

  const productsOnPlan = simpleProductTypes
    .filter((product) => samplePlan[`${product}_ordered_count`])
    .map((product) => `${product}_panel`);

  return (
    <>
      <CancelModal
        cancelTitle={getString('cancelSamplePlan', language)}
        cancel={cancelSamples}
        isFetching={false}
        hideModal={() => toggleShowCancelModal(false)}
        opened={showCancelModal}
      />
      {showMarkReadyModal && (
        <MarkReady
          samplePlanId={samplePlan.id}
          fieldId={samplePlan.field_id}
          onClose={() => {
            toggleInitSearch(true);
            toggleMarkReadyModal(false);
          }}
          opened={showMarkReadyModal}
        />
      )}
      <CancelModal
        cancelTitle={getString('markNotReady', language)}
        cancel={markNotReady}
        cancelText={getString('sureMarkNotReady', language)}
        isFetching={false}
        hideModal={() => {
          toggleMarkNotReadyModal(false);
        }}
        opened={showMarkNotReadyModal}
      />
      {showAssignSamplerModal && assignSamplerSamplePlan && operation && operationUsers && (
        <AssignSampler
          samplePlan={assignSamplerSamplePlan}
          fieldId={samplePlan.field_id}
          onClose={closeAssignSampler}
          getFieldsWithUpdates={() => {}}
          billing_user_id={samplePlan.billing_user_id}
          opened={showAssignSamplerModal}
          operationId={samplePlan.operation_id}
        />
      )}
      {alertsModalData.show && (
        <AlertsModal
          toggleModal={toggleAlertsModalData}
          snooze={alertsModalData.snooze}
          alerts={samplePlan.alerts}
          onClose={toggleInitSearch}
        />
      )}
      <Table.Tr
        className={classNames(styles.RowTrack, displayExpanded && styles.RowOpen)}
        onClick={() => toggleDisplayExpanded(!displayExpanded)}
      >
        <Table.Td>{samplePlan.operation_name}</Table.Td>
        <Table.Td>
          <div className={styles.VerticalAlign}>
            <span className={styles.SpanInDivWrap}>
              {samplePlan.field_name} (
              {acToHectares(samplePlan.calculated_area_acres, language).toFixed(0)}{' '}
              {getAcreageUnitFromLang(language)})
            </span>
            <span className={classNames(styles.SpanInDivSmall, styles.SmallFont)}>
              {samplePlan.field_farm_name}
            </span>
          </div>
        </Table.Td>
        <Table.Td>
          <Group gap={0}>
            {getProductList(productsOnPlan, samplePlan.is_pro, samplePlan.is_till_rx, false).map(
              (product) => generateStatusBubble(product, false),
            )}
          </Group>
        </Table.Td>
        <Table.Td>{samplePlan.agency_name}</Table.Td>
        <Table.Td>
          <div className={styles.VerticalAlign}>
            <span className={styles.SpanInDiv}>{getDateOrNone(samplePlan.created_at)}</span>
            <span className={styles.SpanInDiv}>{getDateOrNone(samplePlan.updated_at)}</span>
            <span className={styles.SpanInDiv}>{getEstimatedSamplingDate()}</span>
            <span className={styles.SpanInDiv}>{getEstimatedScanningDate()}</span>
          </div>
        </Table.Td>
        <Table.Td>
          <span className={styles.SpanInDiv}>{getString(samplePlan.status, language)}</span>
        </Table.Td>
        <Table.Td maw="10rem">
          <NotesPopover samplePlan={samplePlan} notes_type="dispatch_notes" />
        </Table.Td>
        <Table.Td maw="10rem">
          <NotesPopover samplePlan={samplePlan} notes_type="notes" />
        </Table.Td>
        <Table.Td>
          <div className={styles.VerticalAlign}>
            <Tooltip
              label={`${getString('sample', language)}: ${getCombinedString(samplePlan.assigned_to_first_name, samplePlan.assigned_to_last_name, language)}`}
              position="top"
              color="blue"
            >
              <span className={styles.SpanInDiv}>
                {samplePlan.assigned_to_first_name
                  ? endEllipsis(
                      `${samplePlan.assigned_to_last_name}, ${samplePlan.assigned_to_first_name}`,
                      10,
                    )
                  : getString('none', language)}
              </span>
            </Tooltip>
            <Tooltip
              label={`${getString('scan', language)}: ${getCombinedString(samplePlan.assigned_to_scan_first_name, samplePlan.assigned_to_scan_last_name, language)}`}
              position="top"
              color="blue"
            >
              <span className={styles.SpanInDiv}>
                {samplePlan.assigned_to_scan_first_name
                  ? endEllipsis(
                      `${samplePlan.assigned_to_scan_last_name}, ${samplePlan.assigned_to_scan_first_name}`,
                      10,
                    )
                  : getString('none', language)}
              </span>
            </Tooltip>
            <span className={styles.SpanInDiv}>
              {samplePlan.ready_to_sample
                ? getString('ready', language)
                : getString('notReady', language)}
            </span>
          </div>
        </Table.Td>
        <Table.Td className={classNames(styles.AlertCol, alerts.length && styles.AlertColRed)}>
          {alerts}
        </Table.Td>
        <Table.Td>{samplePlan.days_marked_ready}</Table.Td>
        <Table.Td>
          <div className={styles.VerticalAlign}>
            <span className={styles.SpanInDiv}>
              {displayDivide(samplePlan.sample_sampled_count, samplePlan.sample_total_active_count)}
            </span>
            <span className={styles.SpanInDiv}>
              {displayDivide(
                samplePlan.sample_received_count,
                samplePlan.sample_total_active_count,
              )}
            </span>
            <span className={styles.SpanInDiv}>
              {displayDivide(
                samplePlan.sample_processed_count,
                samplePlan.sample_total_active_count,
              )}
            </span>
            <span className={styles.SpanInDiv}>{getProTillStatusText()}</span>
          </div>
        </Table.Td>
        <Table.Td>
          <TrackingActionButton
            samplePlan={samplePlan}
            toggleShowCancelModal={toggleShowCancelModal}
            toggleMarkReadyModal={toggleMarkReadyModal}
            toggleMarkNotReadyModal={toggleMarkNotReadyModal}
            openAssignSampler={openAssignSampler}
            toggleAlertsModalData={toggleAlertsModalData}
          />
        </Table.Td>
      </Table.Tr>
      {displayExpanded && <TrackingInnerRow samplePlan={samplePlan} />}
    </>
  );
};

export default TrackingRow;
