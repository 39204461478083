import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox } from 'common';
import { UserRole } from 'store/user/types';
import styles from './Welcome.module.css';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import UserAgreementLinks from 'apps/Operations/Common/UserAgreementLinks';

interface Props {
  showResetPassword: () => void;
  role: UserRole;
}

const Welcome = ({ showResetPassword, role }: Props) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);

  const acceptTerms = () => {
    return (
      <div className={styles.TermsContainer}>
        <span className={styles.AgreementText} onClick={() => setAccepted(!accepted)}>
          <>
            {[UserRole.Agent, UserRole.AgencyAdmin].includes(role)
              ? getString('haveReadAndAgreeToPatternAgIncludingTerms', language)
              : getString('haveReadAndAgreeToPatternAg', language)}
            &nbsp;
          </>
        </span>
        <div className={styles.AgreementLinksWrapper}>
          <UserAgreementLinks role={role} />

          <p className={styles.CheckboxTextWrapper}>
            <p className={styles.CheckboxText}>({getString('checkBoxToContinue', language)})</p>:
          </p>

          <Checkbox checked={accepted} onChange={() => setAccepted(!accepted)} />
        </div>
      </div>
    );
  };

  const handleAccept = () => {
    showResetPassword();
  };

  const handleLogout = () => {
    navigate('/auth/login');
  };
  return (
    <>
      <div className={styles.Footer}>
        <Button disabled={!accepted} className={styles.AcceptButton} primary onClick={handleAccept}>
          {getString('createPassword', language)}
        </Button>
        <Button className={styles.LogoutButton} danger onClick={handleLogout}>
          {getString('noLogMeOut', language)}
        </Button>
      </div>
      {acceptTerms()}
    </>
  );
};

export default Welcome;
