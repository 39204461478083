import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './Icon.module.css';

type IconProps = {
  className?: string;
  dataTestId?: string;
};

export const DropdownArrowIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className)}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0" maskUnits="userSpaceOnUse">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m4.5 8.25 7.5 7.5 7.5-7.5h-15z"
          className="background"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="24" height="24" className="foreground" />
      </g>
    </svg>
  );
};

export const SearchIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className)}
      data-test-id={dataTestId}
      viewBox="0 0 21 21"
    >
      <g stroke="none" strokeWidth="1" className="foreground" fillRule="evenodd">
        <g
          transform="translate(-1038.000000, -213.000000)"
          className="foreground"
          fillRule="nonzero"
        >
          <g transform="translate(1038.000000, 213.000000)">
            <path d="M20.1729711,19.0921665 L15.4305575,14.349753 C15.3176429,14.2368384 15.2047283,14.2368384 15.0918137,14.2368384 L14.583698,14.2368384 C15.8257586,12.8254058 16.5597036,10.9623147 16.5597036,8.9298518 C16.5597036,4.46972477 12.9464361,0.8 8.4298518,0.8 C3.91326747,0.8 0.3,4.41326747 0.3,8.9298518 C0.3,13.4464361 3.91326747,17.0597036 8.4298518,17.0597036 C10.4623147,17.0597036 12.3254058,16.3257586 13.7368384,15.083698 L13.7368384,15.5918137 C13.7368384,15.7047283 13.7932957,15.8176429 13.849753,15.9305575 L18.5921665,20.6729711 C18.7615385,20.842343 19.043825,20.842343 19.2696542,20.6729711 L20.1729711,19.7696542 C20.342343,19.6002823 20.342343,19.3179958 20.1729711,19.0921665 Z M8.4863091,15.1966126 C5.04241355,15.1966126 2.27600565,12.4302047 2.27600565,8.9863091 C2.27600565,5.54241355 5.04241355,2.77600565 8.4863091,2.77600565 C11.9302047,2.77600565 14.6966126,5.54241355 14.6966126,8.9863091 C14.6966126,12.4302047 11.9302047,15.1966126 8.4863091,15.1966126 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const LegendIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="layers_24px">
        <path
          id="icon/maps/layers_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 16.465L19.36 10.735L21 9.46497L12 2.46497L3 9.46497L4.63 10.735L12 16.465ZM11.99 19.005L4.62 13.275L3 14.535L12 21.535L21 14.535L19.37 13.265L11.99 19.005ZM17.74 9.46497L12 4.99497L6.26 9.46497L12 13.935L17.74 9.46497Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const EditIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="edit_24px">
        <path
          id="icon/image/edit_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM2.99878 17.25L14.0588 6.19L17.8088 9.94L6.74878 21H2.99878V17.25Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const CloseIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="cancel_24px">
        <path
          id="icon/navigation/cancel_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 10.59L15.59 7L17 8.41L13.41 12L17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const UploadIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="cloud_upload_24px">
        <path
          id="icon/file/cloud_upload_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM10.55 13H8L12 9L16 13H13.45V16H10.55V13Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const ExpandIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="expand_more_24px">
        <path
          id="icon/navigation/expand_more_24px"
          d="M16.59 8.29498L12 12.875L7.41 8.29498L6 9.70498L12 15.705L18 9.70498L16.59 8.29498Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export const HelpIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      viewBox="0 0 24 24"
    >
      <g id="help_outline_24px">
        <path
          id="icon/action/help_outline_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 16V18H11V16H13ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM8 10C8 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 11.2829 15.21 11.9733 14.4408 12.6455C13.711 13.2833 13 13.9046 13 15H11C11 13.1787 11.9421 12.4566 12.7704 11.8217C13.4202 11.3236 14 10.8792 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10H8Z"
          fill="black"
          fillOpacity="0.54"
        />
      </g>
    </svg>
  );
};

export const RoundedCornIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.5H30C35.2467 0.5 39.5 4.75329 39.5 10V30C39.5 35.2467 35.2467 39.5 30 39.5H10C4.75329 39.5 0.5 35.2467 0.5 30V10C0.5 4.75329 4.75329 0.5 10 0.5Z"
        fill="white"
        stroke="#113063"
      />
      <g id="rounded_corner_40">
        <path
          d="M21.3065 12.6028L21.9763 13.2723C22.2197 13.5156 22.6158 13.5155 22.8591 13.2724L23.2273 12.9043C23.4705 12.6612 23.4706 12.2652 23.2271 12.0218L22.7101 11.505L22.5574 11.3523C22.314 11.1091 21.9179 11.1092 21.6747 11.3523L21.3065 11.7204C21.0633 11.9635 21.0631 12.3595 21.3065 12.6028Z"
          fill="#113063"
        />
        <path
          d="M19.7987 14.1106L20.4685 14.7801C20.7119 15.0234 21.108 15.0233 21.3513 14.7802L21.7195 14.4121C21.9627 14.169 21.9628 13.773 21.7193 13.5296L21.0496 12.8601C20.8062 12.6169 20.4101 12.617 20.1668 12.8601L19.7986 13.2282C19.5555 13.4713 19.5553 13.8672 19.7987 14.1106Z"
          fill="#113063"
        />
        <path
          d="M20.211 15.9199C20.4546 15.6764 20.4547 15.2804 20.2113 15.0371L19.5415 14.3675C19.3938 14.2199 19.1902 14.1624 18.998 14.1939C18.8597 14.7059 18.6922 15.2482 18.4883 15.8155L18.9604 16.2875C19.2038 16.5309 19.5997 16.531 19.8432 16.2876L20.211 15.9199Z"
          fill="#113063"
        />
        <path
          d="M18.703 17.4277C18.9463 17.1846 18.9464 16.7886 18.7029 16.5452L18.3489 16.1914C18.1385 16.7445 17.894 17.319 17.6094 17.9098C17.8438 18.0293 18.1387 17.9917 18.3348 17.7957L18.703 17.4277Z"
          fill="#113063"
        />
        <path
          d="M17.3688 18.3945C17.235 18.656 17.0931 18.9202 16.9434 19.1867L17.1946 18.9355C17.3419 18.7883 17.3993 18.5855 17.3688 18.3945Z"
          fill="#113063"
        />
        <path
          d="M25.0368 14.7139C25.28 14.4707 25.2804 14.075 25.0368 13.8315L24.4292 13.2242L24.3672 13.1621C24.1236 12.9186 23.7275 12.9187 23.4842 13.1619L23.1161 13.5299C22.8728 13.773 22.8727 14.169 23.1162 14.4125L23.7859 15.0819C24.0295 15.3254 24.4254 15.325 24.6687 15.0819L25.0368 14.7139Z"
          fill="#113063"
        />
        <path
          d="M23.529 16.2217C23.7723 15.9785 23.7726 15.5828 23.529 15.3393L22.8594 14.6699C22.6158 14.4264 22.2197 14.4265 21.9764 14.6697L21.6082 15.0378C21.365 15.2809 21.3649 15.6769 21.6085 15.9203L22.2782 16.5897C22.5217 16.8332 22.9177 16.8328 23.1609 16.5897L23.529 16.2217Z"
          fill="#113063"
        />
        <path
          d="M22.0201 17.7288C22.2636 17.4853 22.264 17.0896 22.0204 16.8461L21.3507 16.1767C21.1071 15.9332 20.711 15.9333 20.4674 16.1768L20.0996 16.5445C19.856 16.788 19.8562 17.1836 20.0998 17.4271L20.7694 18.0965C21.013 18.34 21.4086 18.34 21.6522 18.0965L22.0201 17.7288Z"
          fill="#113063"
        />
        <path
          d="M20.5125 19.2363C20.7558 18.9932 20.7561 18.5974 20.5125 18.3539L19.8429 17.6846C19.5993 17.4411 19.2032 17.4412 18.9599 17.6844L18.5917 18.0524C18.3482 18.2958 18.3484 18.6916 18.5919 18.935L19.2616 19.6044C19.5052 19.8479 19.9009 19.8477 20.1444 19.6044L20.5125 19.2363Z"
          fill="#113063"
        />
        <path
          d="M19.0035 20.7445C19.2473 20.5008 19.2474 20.1052 19.0038 19.8618L18.3341 19.1924C18.0905 18.9489 17.6947 18.9488 17.4508 19.1925L17.083 19.5602C16.8394 19.8037 16.8396 20.1993 17.0832 20.4428L17.7528 21.1122C17.9964 21.3557 18.392 21.3557 18.6356 21.1122L19.0035 20.7445Z"
          fill="#113063"
        />
        <path
          d="M17.4956 22.2516C17.7389 22.0084 17.7392 21.6127 17.4956 21.3692L16.826 20.6998C16.6255 20.4994 16.3215 20.4644 16.0845 20.5944C15.8697 20.9202 15.6435 21.2486 15.402 21.5788C15.4005 21.5807 15.3992 21.5826 15.3975 21.5847C15.4139 21.7184 15.4725 21.8479 15.575 21.9502L16.2447 22.6196C16.4883 22.8631 16.8842 22.8627 17.1276 22.6194L17.4956 22.2516Z"
          fill="#113063"
        />
        <path
          d="M15.9878 23.7595C16.231 23.5164 16.2315 23.1205 15.9879 22.877L15.3182 22.2077C15.2436 22.133 15.1543 22.0815 15.0598 22.0527C14.7209 22.5247 14.3918 22.9956 14.0723 23.4633L14.7368 24.1276C14.9804 24.3711 15.3761 24.371 15.6196 24.1275L15.9878 23.7595Z"
          fill="#113063"
        />
        <path
          d="M14.4791 25.2672C14.7223 25.024 14.7227 24.6283 14.4791 24.3848L13.8636 23.7695C13.5199 24.2778 13.1879 24.7812 12.8682 25.2753L13.2282 25.6352C13.4718 25.8787 13.8677 25.8783 14.111 25.6352L14.4791 25.2672Z"
          fill="#113063"
        />
        <path
          d="M26.8473 16.5224C27.0905 16.2793 27.0905 15.8833 26.8473 15.6401L26.7852 15.578L26.1777 14.9706C25.9341 14.7271 25.5381 14.7274 25.2948 14.9705L24.9267 15.3386C24.6834 15.5817 24.6832 15.9776 24.9268 16.2211L25.5965 16.8904C25.8397 17.1336 26.236 17.1336 26.4792 16.8904L26.8473 16.5224Z"
          fill="#113063"
        />
        <path
          d="M25.3385 18.0302C25.5818 17.7871 25.5818 17.391 25.3385 17.1478L24.6688 16.4784C24.4253 16.2349 24.0293 16.2352 23.786 16.4783L23.4178 16.8464C23.1746 17.0895 23.1744 17.4854 23.418 17.7289L24.0877 18.3983C24.3309 18.6414 24.7271 18.6414 24.9703 18.3983L25.3385 18.0302Z"
          fill="#113063"
        />
        <path
          d="M23.8305 19.5383C24.0741 19.2948 24.0741 18.8987 23.8309 18.6556L23.1612 17.9862C22.9177 17.7427 22.5216 17.743 22.278 17.9865L21.9102 18.3541C21.6666 18.5976 21.6667 18.9931 21.9103 19.2366L22.58 19.906C22.8232 20.1491 23.2191 20.1495 23.4627 19.906L23.8305 19.5383Z"
          fill="#113063"
        />
        <path
          d="M22.322 21.0458C22.5653 20.8027 22.5653 20.4066 22.322 20.1634L21.6524 19.4941C21.4088 19.2506 21.0128 19.2508 20.7695 19.4939L20.4014 19.862C20.1579 20.1054 20.1579 20.501 20.4015 20.7445L21.0712 21.4139C21.3144 21.657 21.7104 21.6573 21.9539 21.4139L22.322 21.0458Z"
          fill="#113063"
        />
        <path
          d="M20.8139 22.554C21.0578 22.3103 21.0575 21.9144 20.8143 21.6713L20.1446 21.0019C19.9011 20.7584 19.5053 20.7584 19.2614 21.0022L18.8936 21.3699C18.65 21.6134 18.6501 22.0089 18.8937 22.2524L19.5634 22.9217C19.8066 23.1649 20.2025 23.1652 20.4461 22.9217L20.8139 22.554Z"
          fill="#113063"
        />
        <path
          d="M18.6227 24.5989C18.7069 24.5595 18.7914 24.5209 18.8753 24.4836C18.8971 24.4668 18.9183 24.4493 18.9383 24.4293L19.3063 24.0614C19.5496 23.8183 19.5496 23.4223 19.3063 23.1791L18.6366 22.5097C18.3931 22.2662 17.997 22.2664 17.7538 22.5096L17.3858 22.8774C17.1424 23.1208 17.1421 23.5166 17.3857 23.7601L18.0554 24.4294C18.209 24.5831 18.424 24.6395 18.6227 24.5989Z"
          fill="#113063"
        />
        <path
          d="M17.9475 24.929C17.9179 24.8397 17.8683 24.7565 17.7977 24.6858L17.128 24.0165C16.8844 23.773 16.4883 23.7733 16.2451 24.0165L15.877 24.3844C15.6334 24.6279 15.6334 25.0235 15.877 25.267L16.4033 25.793C16.9232 25.4808 17.4389 25.1902 17.9475 24.929Z"
          fill="#113063"
        />
        <path
          d="M16.0839 25.988L15.62 25.5243C15.3765 25.2808 14.9804 25.2811 14.7372 25.5242L14.369 25.8923C14.1258 26.1354 14.1255 26.5313 14.3692 26.7748L14.5612 26.9667C15.07 26.6267 15.5787 26.2982 16.0839 25.988Z"
          fill="#113063"
        />
        <path
          d="M28.5045 17.2972L27.9872 16.7802C27.7438 16.5368 27.3476 16.5368 27.1043 16.78L26.7361 17.148C26.4929 17.3912 26.4929 17.7873 26.7363 18.0307L27.4059 18.7C27.6494 18.9433 28.0456 18.9433 28.2888 18.7002L28.657 18.3322C28.9002 18.089 28.9002 17.693 28.6567 17.4496L28.5045 17.2972Z"
          fill="#113063"
        />
        <path
          d="M22.9676 11.2485L23.4846 11.7653L23.6036 11.8843C23.8313 12.1119 24.2145 12.0988 24.458 11.8553L24.8259 11.4876C25.0694 11.2443 25.0826 10.8612 24.8548 10.6335L24.3711 10.15L24.2189 9.99783C23.9909 9.76996 23.6073 9.78283 23.3639 10.0262L22.996 10.3939C22.7525 10.6374 22.7397 11.0206 22.9676 11.2485Z"
          fill="#113063"
        />
        <path
          d="M26.5448 13.2065C26.7883 12.9631 26.8015 12.58 26.5738 12.3525L25.9994 11.7782L25.9375 11.7164C25.7098 11.4887 25.3262 11.5016 25.0827 11.745L24.7148 12.1127C24.4713 12.3562 24.4585 12.7395 24.6862 12.9671L25.2938 13.5745L25.3225 13.6032C25.5501 13.8308 25.9333 13.8176 26.1768 13.5741L26.5448 13.2065Z"
          fill="#113063"
        />
        <path
          d="M26.4346 13.8315C26.1911 14.075 26.1781 14.4582 26.4059 14.6858L26.4344 14.7143L27.0419 15.3217C27.2698 15.5494 27.653 15.5364 27.8966 15.2929L28.2645 14.9252C28.508 14.6819 28.5211 14.2987 28.2933 14.0709L28.2312 14.0089L27.6572 13.4351C27.4294 13.2074 27.046 13.2204 26.8025 13.4638L26.4346 13.8315Z"
          fill="#113063"
        />
        <path
          d="M29.8606 15.6373L29.377 15.1539C29.1492 14.9262 28.7657 14.9391 28.5222 15.1825L28.1543 15.5502C27.9107 15.7937 27.8979 16.177 28.1257 16.4046L28.2446 16.5236L28.7618 17.0406C28.9895 17.2681 29.3728 17.2553 29.6164 17.0118L29.9843 16.6441C30.2278 16.4007 30.2408 16.0174 30.0132 15.7898L29.8606 15.6373Z"
          fill="#113063"
        />
        <path
          d="M24.628 9.89326L25.1118 10.3768L25.231 10.4959C25.4429 10.7078 25.8134 10.6822 26.0567 10.4389L26.4248 10.0709C26.6682 9.82763 26.6939 9.45713 26.482 9.24532L26.0315 8.79494L25.8791 8.64257C25.6672 8.43076 25.2965 8.45632 25.053 8.69963L24.6849 9.06763C24.4416 9.31094 24.4162 9.68144 24.628 9.89326Z"
          fill="#113063"
        />
        <path
          d="M28.0536 11.6986C28.297 11.4553 28.323 11.0851 28.1108 10.873L27.57 10.3324L27.508 10.2705C27.2961 10.0587 26.9254 10.0842 26.682 10.3276L26.3138 10.6956C26.0705 10.9387 26.045 11.3092 26.257 11.5211L26.8314 12.0953L26.8596 12.1236C27.0718 12.3356 27.4421 12.3098 27.6854 12.0666L28.0536 11.6986Z"
          fill="#113063"
        />
        <path
          d="M27.9428 12.3236C27.6995 12.5667 27.6739 12.9371 27.8858 13.1489L27.9144 13.1776L28.4884 13.7513C28.7005 13.9634 29.0712 13.9379 29.3145 13.6947L29.6826 13.3267C29.926 13.0834 29.9516 12.7128 29.7395 12.5007L29.6775 12.4388L29.1369 11.8984C28.925 11.6866 28.5544 11.7122 28.311 11.9556L27.9428 12.3236Z"
          fill="#113063"
        />
        <path
          d="M31.2161 13.9765L30.7659 13.5265C30.5539 13.3145 30.1832 13.3401 29.9398 13.5835L29.5716 13.9515C29.3283 14.1946 29.3028 14.5651 29.5149 14.7771L29.6339 14.8961L30.1176 15.3796C30.3295 15.5914 30.7 15.5658 30.9433 15.3226L31.3114 14.9546C31.5549 14.7113 31.5806 14.3408 31.3686 14.129L31.2161 13.9765Z"
          fill="#113063"
        />
        <path
          d="M26.2885 8.53805L26.739 8.98843L26.8578 9.10724C27.054 9.30336 27.3915 9.28568 27.6097 9.06755L27.9482 8.72918C28.1663 8.51111 28.184 8.1738 27.9878 7.97768L27.571 7.56105L27.4184 7.40855C27.2224 7.21255 26.885 7.23036 26.6668 7.44842L26.3283 7.7868C26.1102 8.0048 26.0924 8.34205 26.2885 8.53805Z"
          fill="#113063"
        />
        <path
          d="M29.4859 10.266C29.7041 10.0479 29.7222 9.71103 29.5258 9.51466L29.0186 9.0076L28.9566 8.94566C28.7605 8.74966 28.4231 8.76747 28.205 8.98553L27.8665 9.32391C27.6483 9.54203 27.6305 9.87922 27.8266 10.0753L28.3673 10.6158L28.3958 10.6443C28.5922 10.8407 28.9292 10.8225 29.1475 10.6045L29.4859 10.266Z"
          fill="#113063"
        />
        <path
          d="M29.4056 10.8621C29.1874 11.0802 29.1695 11.4173 29.3656 11.6133L29.3942 11.6419L29.9348 12.1823C30.1311 12.3786 30.4684 12.3606 30.6866 12.1425L31.0251 11.8041C31.2432 11.5861 31.2611 11.2489 31.0648 11.0527L31.0028 10.9908L30.4956 10.4837C30.2995 10.2877 29.9622 10.3056 29.7441 10.5236L29.4056 10.8621Z"
          fill="#113063"
        />
        <path
          d="M32.4505 12.4375L32.0338 12.021C31.8376 11.8248 31.5003 11.8426 31.2821 12.0607L30.9436 12.3991C30.7254 12.6172 30.7076 12.9545 30.9038 13.1506L31.0229 13.2696L31.4731 13.7196C31.6695 13.916 32.0068 13.898 32.2249 13.6799L32.5634 13.3415C32.7816 13.1235 32.7995 12.7863 32.6031 12.59L32.4505 12.4375Z"
          fill="#113063"
        />
        <path
          d="M27.8278 7.304L28.2446 7.72063L28.3635 7.83957C28.5442 8.02007 28.8627 7.99507 29.0737 7.78419L29.4038 7.45413C29.615 7.24307 29.6399 6.92475 29.4593 6.74419L28.9235 6.20863C28.743 6.02813 28.4242 6.05282 28.2131 6.26388L27.8829 6.59394C27.672 6.80482 27.6472 7.1235 27.8278 7.304Z"
          fill="#113063"
        />
        <path
          d="M30.8521 8.90135C31.0632 8.69028 31.0882 8.37191 30.9077 8.19153L30.3718 7.65585C30.1912 7.47535 29.8726 7.50016 29.6615 7.71116L29.3313 8.04122C29.1203 8.2521 29.0954 8.57072 29.276 8.75122L29.7832 9.25828L29.8118 9.28691C29.9923 9.46735 30.3109 9.44228 30.5219 9.23141L30.8521 8.90135Z"
          fill="#113063"
        />
        <path
          d="M30.7796 9.48862C30.5687 9.6995 30.5436 10.018 30.7242 10.1985L30.7525 10.2269L31.2598 10.7339C31.4403 10.9144 31.7592 10.8896 31.9702 10.6787L32.3003 10.3487C32.5115 10.1376 32.5362 9.81906 32.3556 9.6385L31.8199 9.10306C31.6393 8.92256 31.3208 8.9475 31.1097 9.1585L30.7796 9.48862Z"
          fill="#113063"
        />
        <path
          d="M33.2672 10.5503C33.0867 10.3699 32.7681 10.3947 32.557 10.6058L32.2268 10.9358C32.0159 11.1467 31.9909 11.4653 32.1714 11.6457L32.2903 11.7646L32.707 12.1812C32.8876 12.3617 33.2064 12.3369 33.4174 12.1261L33.7476 11.796C33.9587 11.5849 33.9834 11.2664 33.8028 11.0858L33.2672 10.5503Z"
          fill="#113063"
        />
        <path
          d="M29.4826 6.14466L29.9641 6.62597C30.1191 6.78091 30.3807 6.77179 30.547 6.60554L30.8248 6.32779C30.9911 6.16154 31.0002 5.9001 30.8452 5.74516L30.3637 5.26385C30.2087 5.10885 29.9472 5.11797 29.7808 5.28429L29.503 5.56204C29.3367 5.72829 29.3276 5.98972 29.4826 6.14466Z"
          fill="#113063"
        />
        <path
          d="M32.1254 7.62743C32.2917 7.46118 32.3011 7.20005 32.146 7.04499L31.6644 6.56355C31.5095 6.40868 31.248 6.4178 31.0816 6.58405L30.8038 6.8618C30.6375 7.02805 30.6284 7.28949 30.7833 7.44437L31.265 7.9258C31.4201 8.08087 31.6813 8.07143 31.8476 7.90518L32.1254 7.62743Z"
          fill="#113063"
        />
        <path
          d="M32.5666 9.22645C32.7216 9.38139 32.983 9.37214 33.1493 9.20595L33.4271 8.9282C33.5934 8.76195 33.6027 8.50064 33.4477 8.34564L32.9662 7.86433C32.8111 7.70933 32.5499 7.7187 32.3835 7.88495L32.1057 8.1627C31.9394 8.32895 31.93 8.59014 32.0851 8.74514L32.5666 9.22645Z"
          fill="#113063"
        />
        <path
          d="M33.6842 9.18564L33.4064 9.46339C33.2401 9.62964 33.2308 9.89095 33.3861 10.0461L33.8673 10.5271C34.0223 10.6821 34.2837 10.6728 34.45 10.5066L34.7278 10.2289C34.8941 10.0626 34.9034 9.80132 34.7483 9.64632L34.2671 9.16533C34.1119 9.01014 33.8505 9.01939 33.6842 9.18564Z"
          fill="#113063"
        />
        <path
          d="M31.0604 5.32887L31.4567 5.72505C31.5702 5.83843 31.793 5.80693 31.9436 5.65637L32.2058 5.3943C32.3563 5.2438 32.3878 5.02112 32.2743 4.90774L31.878 4.51155C31.7647 4.3983 31.5417 4.42949 31.3912 4.57999L31.129 4.84205C30.9783 4.99262 30.9471 5.21562 31.0604 5.32887Z"
          fill="#113063"
        />
        <path
          d="M32.1317 6.39919L32.5283 6.79563C32.6413 6.90869 32.8642 6.87719 33.0148 6.72663L33.277 6.46456C33.4276 6.31406 33.459 6.09138 33.3459 5.97831L32.9494 5.58187C32.8359 5.4685 32.6132 5.49994 32.4626 5.65044L32.2004 5.9125C32.0498 6.06306 32.0183 6.28581 32.1317 6.39919Z"
          fill="#113063"
        />
        <path
          d="M33.2028 7.47033L33.5994 7.86677C33.7125 7.97983 33.9352 7.94821 34.0858 7.79765L34.348 7.53558C34.4986 7.38508 34.5302 7.16252 34.4171 7.04946L34.0205 6.65302C33.9072 6.53977 33.6845 6.57133 33.534 6.72183L33.2718 6.9839C33.1212 7.1344 33.0895 7.35702 33.2028 7.47033Z"
          fill="#113063"
        />
        <path
          d="M34.6053 7.79315L34.3431 8.05521C34.1925 8.20577 34.1609 8.4284 34.274 8.54146L34.6704 8.93777C34.7837 9.05102 35.0066 9.01952 35.1572 8.86902L35.4194 8.60696C35.5699 8.45646 35.6014 8.23377 35.4881 8.12052L35.0916 7.72421C34.9785 7.61109 34.7559 7.64265 34.6053 7.79315Z"
          fill="#113063"
        />
        <path
          d="M32.5792 4.55754L32.8937 4.87198C32.9702 4.94842 33.1041 4.93904 33.1924 4.85086L33.3786 4.66467C33.4667 4.57667 33.4761 4.44267 33.3997 4.36623L33.0851 4.05179C33.0084 3.97517 32.8745 3.98473 32.7865 4.07273L32.6002 4.25892C32.512 4.34711 32.5025 4.48086 32.5792 4.55754Z"
          fill="#113063"
        />
        <path
          d="M33.4289 5.40622L33.7433 5.72047C33.82 5.79709 33.9539 5.78778 34.0422 5.69959L34.2284 5.51341C34.3165 5.42541 34.3259 5.29141 34.2492 5.21478L33.9349 4.90053C33.8581 4.82378 33.7241 4.83334 33.6361 4.92134L33.4499 5.10753C33.3616 5.19572 33.3521 5.32947 33.4289 5.40622Z"
          fill="#113063"
        />
        <path
          d="M34.2784 6.25574L34.5928 6.56999C34.6692 6.64636 34.8034 6.63743 34.8918 6.54918L35.078 6.36299C35.1661 6.27499 35.1751 6.14061 35.0987 6.06424L34.7843 5.74999C34.7079 5.67361 34.5736 5.6828 34.4856 5.7708L34.2993 5.95699C34.211 6.04524 34.2019 6.17936 34.2784 6.25574Z"
          fill="#113063"
        />
        <path
          d="M35.1282 7.10555L35.4425 7.41967C35.5192 7.4963 35.653 7.48686 35.7412 7.39867L35.9275 7.21248C36.0156 7.12448 36.0251 6.99061 35.9484 6.91398L35.6342 6.59986C35.5574 6.52311 35.4232 6.53242 35.3352 6.62042L35.1489 6.80661C35.0607 6.8948 35.0514 7.0288 35.1282 7.10555Z"
          fill="#113063"
        />
        <path
          d="M27.1483 19.8399C27.3915 19.5968 27.3915 19.2007 27.148 18.9573L26.4785 18.288C26.235 18.0447 25.8388 18.0447 25.5955 18.2878L25.2273 18.6558C24.9841 18.899 24.9841 19.2951 25.2276 19.5384L25.8971 20.2077C26.1406 20.4511 26.5368 20.4511 26.7801 20.208L27.1483 19.8399Z"
          fill="#113063"
        />
        <path
          d="M25.6403 21.348C25.8838 21.1045 25.8838 20.7085 25.6404 20.4651L24.9708 19.7958C24.7274 19.5524 24.3311 19.5524 24.0875 19.7959L23.7196 20.1636C23.4761 20.4071 23.4764 20.8029 23.7198 21.0462L24.3894 21.7155C24.6329 21.9589 25.0288 21.9592 25.2723 21.7158L25.6403 21.348Z"
          fill="#113063"
        />
        <path
          d="M21.9541 22.8105C21.7106 22.5671 21.3146 22.567 21.0708 22.8106L20.7029 23.1783C20.5188 23.3623 20.4743 23.6333 20.5683 23.8597C21.3205 23.6498 22.0399 23.5512 22.714 23.5952C22.6887 23.5544 22.6592 23.5154 22.6236 23.4798L21.9541 22.8105Z"
          fill="#113063"
        />
        <path
          d="M12.9709 26.7743C13.2144 26.5308 13.2144 26.1355 12.9709 25.892L12.6667 25.5879C12.3301 26.1128 12.0082 26.6261 11.7012 27.1236L11.7199 27.1424C11.9635 27.3859 12.3592 27.3858 12.6027 27.1424L12.9709 26.7743Z"
          fill="#113063"
        />
        <path
          d="M13.9729 27.3644C14.0664 27.3005 14.1602 27.2369 14.2536 27.1738L14.112 27.0323C13.8685 26.7888 13.4728 26.7887 13.2292 27.0322L12.861 27.4002C12.6517 27.6095 12.6227 27.9315 12.7737 28.1723C13.1584 27.9174 13.5535 27.6507 13.9729 27.3644Z"
          fill="#113063"
        />
        <path
          d="M11.5041 27.4453C11.2098 27.9258 10.93 28.3897 10.666 28.8321C10.8215 28.8293 10.9763 28.7688 11.095 28.6502L11.4628 28.2826C11.6918 28.0536 11.7056 27.6899 11.5041 27.4453Z"
          fill="#113063"
        />
        <path
          d="M11.7202 28.5398L11.3523 28.9075C11.2718 28.988 11.2181 29.0853 11.1914 29.1879C11.602 28.9339 12.0035 28.6775 12.4125 28.41C12.1839 28.3098 11.9073 28.3527 11.7202 28.5398Z"
          fill="#113063"
        />
        <path
          d="M19.1592 10.4377C19.0712 12.2474 18.4769 16.6005 15.0352 21.3105C12.5906 24.6562 10.6061 28.0203 9.25634 30.328C8.63903 30.6751 7.96384 31.042 7.19309 31.4473L6.15997 30.4146C6.14484 30.3588 6.13003 30.3035 6.11622 30.2466C5.57684 27.9777 6.47572 25.4442 8.7164 22.92C9.86484 21.6261 10.9667 20.437 12.0324 19.2865C12.0324 19.2865 17.6547 13.6208 18.8121 7.64258C18.812 7.64252 19.1658 8.57477 19.1592 10.4377Z"
          fill="#113063"
        />
        <path
          d="M4 32.4766C4.28238 32.7589 7.24212 35.7175 7.5245 35.9998C7.77656 35.7478 9.0625 34.4623 9.31437 34.2106C9.032 33.9284 6.07225 30.9698 5.78988 30.6875C5.538 30.9392 4.25206 32.2247 4 32.4766Z"
          fill="#113063"
        />
        <path
          d="M22.5815 24.7373L22.5605 24.7535C22.702 24.9123 22.8134 25.0532 22.8868 25.1512C22.7913 25.0137 22.6907 24.8754 22.5815 24.7373Z"
          fill="#113063"
        />
        <path
          d="M22.211 22.5531L22.8806 23.2224C23.1241 23.4658 23.5201 23.4661 23.7635 23.2227L24.1317 22.8546C24.375 22.6115 24.375 22.2154 24.1315 21.9721L23.4619 21.3027C23.2185 21.0593 22.8222 21.0593 22.579 21.3024L22.2108 21.6705C21.9674 21.9139 21.9675 22.3097 22.211 22.5531Z"
          fill="#113063"
        />
        <path
          d="M21.1959 24.1953C19.1012 24.6188 16.6715 26.0726 14.2288 27.7397C11.9883 29.2689 10.4305 30.2522 7.5293 31.7838L9.66573 33.9194C9.9438 33.9899 10.2174 34.0237 10.4882 34.01L10.4938 34.0098L10.4995 34.0099C13.2547 34.0099 15.7034 31.9313 17.8637 30.0978C18.521 29.5398 19.1419 29.0129 19.758 28.5502C21.4008 27.3156 22.7022 26.4656 23.0294 26.2546C22.8859 25.9814 22.7162 25.703 22.5205 25.4211C22.2924 25.0867 21.7865 24.5079 21.1959 24.1953Z"
          fill="#113063"
        />
        <path
          d="M26.111 28.265C26.2746 27.1885 26.0468 26.1011 24.9627 25.0726C24.1008 24.2547 23.0478 23.9832 21.8682 24.0939C22.1365 24.3027 22.4857 24.6522 22.4857 24.6522C22.7671 24.9495 22.9832 25.2892 22.9832 25.2892C24.6793 27.8285 24.2247 30.1568 23.8427 31.252C24.2503 30.9901 24.6156 30.703 24.9251 30.3936C25.5992 29.7198 25.9897 29.0627 26.111 28.265Z"
          fill="#113063"
        />
      </g>
      <defs>
        <clipPath id="clip0_5825_84317">
          <rect width="32" height="32" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RoundedSoybeansIcon: FunctionComponent<IconProps> = ({ className, dataTestId }) => {
  return (
    <svg
      className={classNames(styles.Icon, className, 'background')}
      data-test-id={dataTestId}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 10.8984C0.5 5.65173 4.7533 1.39844 10 1.39844H31.5417C36.7884 1.39844 41.0417 5.65173 41.0417 10.8984V31.1025C41.0417 36.3492 36.7884 40.6025 31.5417 40.6025H9.99999C4.75328 40.6025 0.5 36.3492 0.5 31.1025V10.8984Z"
        fill="white"
        stroke="#113063"
      />
      <path
        d="M35.9485 4.9193C35.3768 4.97399 34.2624 6.55656 33.1701 6.87359C32.077 7.19062 29.9389 6.87359 27.9347 9.44234C26.5008 11.2813 26.414 14.0131 24.877 15.3743C23.4936 16.5986 21.9448 16.5357 19.9067 18.3189C17.8688 20.1019 17.7641 22.4496 16.4836 23.5281C14.5046 25.1945 12.6009 25.0746 9.95545 26.8331C7.14718 28.6989 7.1438 32.4878 6.22728 34.2228C5.46289 35.6718 3.98113 35.9547 4.17093 36.3925C4.36098 36.8305 10.508 37.4462 13.9601 36.8065C17.9428 36.0682 18.994 33.4577 21.4066 31.7885C23.8197 30.1192 26.138 29.9652 28.373 27.5502C31.1665 24.5318 31.1127 22.4852 32.243 20.9999C33.4389 19.4289 35.7543 17.5966 36.918 14.4501C38.197 10.9914 36.52 4.86513 35.9485 4.9193ZM15.6706 33.9703C13.4149 35.389 10.6954 35.1437 9.59662 33.4193C8.49653 31.6958 9.43253 29.1477 11.6883 27.7277C13.9435 26.3081 16.6632 26.554 17.7628 28.2771C18.8619 30.0016 17.9248 32.5497 15.6706 33.9703ZM26.9491 25.9669C25.1158 28.0101 22.3224 28.5088 20.7092 27.08C19.0963 25.6514 19.2747 22.8371 21.1078 20.7938C22.9408 18.7507 25.7343 18.2521 27.3474 19.6809C28.9606 21.1093 28.7819 23.9238 26.9491 25.9669ZM34.5376 15.2263C33.2649 17.3521 30.9149 18.2619 29.2885 17.2592C27.6621 16.2568 27.3752 13.7206 28.6488 11.595C29.922 9.46968 32.2718 8.55935 33.8982 9.56203C35.5248 10.5645 35.8107 13.1007 34.5376 15.2263Z"
        fill="#113063"
      />
    </svg>
  );
};
