import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  CloseButton,
  Combobox,
  ComboboxChevron,
  Group,
  Paper,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { FiSearch } from 'react-icons/fi';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  AGRONOMIC_PRODUCTS,
  SEED,
  SEEDS,
  SEED_TREATMENT,
  TRAITS,
  TREATMENT,
} from 'constants/cropPlan';
import { AgronomicProductType, CropPlanType, SeedType } from 'store/cropPlans/types';
import { requestGetProductSearch, requestGetSeedSearch } from 'store/cropPlans/requests';
import { DEBOUNCE } from 'util/request';
import { getPestGroups } from 'store/cropPlans/thunks';
import showToast, { type ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_STANDARD } from 'constants/mantine';
import { sortInputsByCoverage } from 'util/cropPlans';
import { CatalogType } from 'store/catalogs/types';

import styles from './CropPlanChart.module.css';
import InputModal from '../ManageCatalog/Detail/Sections/ProductList/Common/InputModal';
import { OperationCatalog } from './OperationCatalog';

interface AddInputProps {
  cropPlan: CropPlanType;
  catalog: CatalogType | null;
  selectedChartInfo: { label: string; value: string | number };
  operationId: number;
}

const AddInput = ({ cropPlan, catalog, selectedChartInfo, operationId }: AddInputProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { pestGroups } = useSelector((state: RootState) => ({
    pestGroups: state.cropPlanning.pestGroups,
  }));

  const [products, setProducts] = useState<AgronomicProductType[]>([]);
  const [seeds, setSeeds] = useState<SeedType[]>([]);
  const [selectedItem, setSelectedItem] = useState<
    Partial<SeedType> | Partial<AgronomicProductType> | null
  >({});
  const [itemName, setItemName] = useState('');
  const [isSaving, toggleIsSaving] = useState(false);
  const [showModal, toggleShowModal] = useState(false);

  const showMessage = (message: string, type: ToastType) => showToast(message, type);

  const isSeed = [SEED, TRAITS].includes(selectedChartInfo.label);

  const debounceFetchProducts = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: AgronomicProductType[] = await requestGetProductSearch(name, cropPlan.crop);
        setProducts(response);
      } catch (error) {
        showMessage('Failed to fetch agronomic products.', 'error');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );
  const debounceFetchSeeds = useDebouncedCallback(
    async (name: string) => {
      try {
        toggleIsSaving(true);
        const response: SeedType[] = await requestGetSeedSearch(name, cropPlan.crop);
        setSeeds(response);
      } catch (error) {
        showMessage('Failed to fetch seeds.', 'error');
      } finally {
        toggleIsSaving(false);
      }
    },
    DEBOUNCE,
    { trailing: true },
  );

  useEffect(() => {
    const populatePestGroups = async () => {
      try {
        dispatch(getPestGroups());
      } catch (e) {
        showMessage('Failed to fetch pest groupings.', 'error');
      }
    };
    if (!pestGroups) {
      populatePestGroups();
    }
  }, [pestGroups]);

  const handleSelect = (value: string) => {
    const optionId = parseInt(value, 10);
    if (optionId === 0) {
      setSelectedItem({ crop: cropPlan.crop });
      toggleShowModal(true);
    } else {
      const item = isSeed
        ? seeds.find((p) => p.id === optionId)
        : products.find((p) => p.id === optionId);
      if (item) {
        setItemName(isSeed ? (item as SeedType).hybrid : (item as AgronomicProductType).name);
        setSelectedItem(item);
        toggleShowModal(true);
      }
    }
  };

  const options = (() => {
    const data = isSeed
      ? seeds.map((seed) => ({
          id: seed.id,
          label: seed.hybrid,
          fw: seed.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          coverage_ratings: seed.coverage_ratings,
        }))
      : products.map((product) => ({
          id: product.id,
          label: product.name,
          fw: product.coverage_ratings.length ? FONT_WEIGHT_BOLD : FONT_WEIGHT_STANDARD,
          coverage_ratings: product.coverage_ratings,
        }));
    const sorted = sortInputsByCoverage(data);
    return [
      <Combobox.Option value="0" key="0" fw="bold">
        {getString('createNew', language)}
      </Combobox.Option>,
    ].concat(
      sorted.map((element) => (
        <Combobox.Option value={String(element.id)} key={element.label}>
          {element.label}
        </Combobox.Option>
      )),
    );
  })();

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setItemName(name);
    if (name && !isSaving) {
      if (isSeed) {
        debounceFetchSeeds(name);
      } else {
        debounceFetchProducts(name);
      }
    }
  };

  const reset = () => {
    setSelectedItem(null);
    setItemName('');
    toggleShowModal(false);
  };

  const getDefaultCategory = () => {
    // we use 'treatment' in category labels to match naming of column groupings but 'seed_treatment' is
    // required for input categorization
    if (selectedChartInfo.label === TREATMENT) {
      return SEED_TREATMENT;
    }
    return selectedChartInfo.label;
  };

  const RightSelect = () => {
    if (itemName) {
      return <CloseButton onClick={reset} style={{ display: selectedItem ? undefined : 'none' }} />;
    }
    return <ComboboxChevron />;
  };

  return (
    <>
      <Paper className={styles.AddInput}>
        <Text fw={600} size="sm" w="100%">
          {getString(isSeed ? 'addSeedChart' : 'addProductChart', language)}
        </Text>
        <Group>
          <Combobox onOptionSubmit={handleSelect} store={combobox}>
            <Combobox.Target>
              <TextInput
                w="13rem"
                leftSection={<FiSearch />}
                rightSection={<RightSelect />}
                onChange={handleTextChange}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={itemName}
                label={getString(isSeed ? 'addHybrid' : 'addProduct', language)}
              />
            </Combobox.Target>

            <Combobox.Dropdown hidden={!options.length} onClick={() => combobox.closeDropdown()}>
              {options}
            </Combobox.Dropdown>
          </Combobox>
          <Text mt="lg">{`- ${getString('or', language)} -`}</Text>
          <OperationCatalog
            catalog={catalog || undefined}
            operationId={operationId}
            label={getString('selectProductCatalog', language)}
            createNew
          />
        </Group>
      </Paper>
      {showModal && (
        <InputModal
          closeModal={reset}
          defaultCategory={getDefaultCategory()}
          isTemporary
          modalOpened
          catalog={catalog}
          type={isSeed ? SEEDS : AGRONOMIC_PRODUCTS}
          data={selectedItem || {}}
          operationId={operationId}
        />
      )}
    </>
  );
};

export default AddInput;
