import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Pagination, Spinner, Table, TableCol, TableRow } from 'common';
import { getLabShipments } from 'store/labShipments/thunks';

import styles from './ShipmentTable.module.css';
import LabShipmentTableRow from './ShipmentTableRow';

const LabShipmentTable = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const { shipments, totalPages, isFetching, hasFetched } = useSelector((state: RootState) => ({
    shipments: state.labShipments.paginated.items,
    totalPages: state.labShipments.paginated.total,
    isFetching: state.labShipments.isFetching,
    hasFetched: state.labShipments.hasFetched,
  }));

  useEffect(() => {
    handleGetPage();
  }, [page]);

  const handleGetPage = async () => dispatch(getLabShipments(page));

  return (
    <div className={styles.Wrapper}>
      <Table>
        <TableRow header>
          <TableCol size="x2">{getString('labShipmentId', language)}</TableCol>
          <TableCol>{getString('from', language)}</TableCol>
          <TableCol>{getString('to', language)}</TableCol>
          <TableCol>{getString('regulatedMaterial', language)}</TableCol>
          <TableCol>{getString('shippedLabels', language)}</TableCol>
          <TableCol>{getString('deliveredLabels', language)}</TableCol>
          <TableCol>{getString('nutrientSamples', language)}</TableCol>
          <TableCol>{getString('genomicPlates', language)}</TableCol>
          <TableCol>{getString('genomicSamples', language)}</TableCol>
          <TableCol>{getString('createdAt', language)}</TableCol>
          <TableCol>{getString('actions', language)}</TableCol>
        </TableRow>
        {isFetching ? (
          <TableRow className={styles.ExpandingColumn}>
            <TableCol size="x4">
              <Spinner fill />
            </TableCol>
          </TableRow>
        ) : (
          <>
            {shipments.map((shipment) => (
              <LabShipmentTableRow
                key={shipment.group_id}
                shipment={shipment}
                reloadPage={handleGetPage}
              />
            ))}
          </>
        )}
        {hasFetched && !shipments?.length && (
          <TableRow>
            <TableCol size="x4">{getString('noShipmentsFoundMsg', language)}</TableCol>
          </TableRow>
        )}
        <TableRow className={styles.PaginationRow}>
          <TableCol size="x4">
            <Pagination page={page} setPage={setPage} totalPages={totalPages} />
          </TableCol>
        </TableRow>
      </Table>
    </div>
  );
};

export default LabShipmentTable;
