import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Center, Loader, Box } from '@mantine/core';
import Header from './Components/Header';
import useOperation from 'util/hooks/useOperation';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import {
  getDefaultAnalysis,
  getTargetNumberOfZones,
  isGridsOption,
  isProScanPoint,
  operationAllowsMultiplePlans,
  setupCompletedAnalysis,
  setupExistingAnalysis,
} from 'util/samplePlan';

import ZoneAnalysisMap from './Components/ZoneAnalysisMap';
import Form from './Components/Forms/Form';
import Styles from './Container.module.css';
import {
  CUSTOM_POINTS,
  UPLOAD_ZONES,
  DEM_ZONES,
  GRID_POINTS,
  GRID_ZONES,
  SAMPLING_ZONES,
  SSURGO_ZONES,
} from 'constants/samplePlanning';
import AnalysisForm from './Components/Forms/AnalysisForm';
import useSampleTiming from 'util/hooks/useSampleTiming';
import { defaultPlanDate, sortByCreatedAt } from 'util/date';
import { AgronomicZonesType, SAMPLE_STATUSES } from 'store/samplePlans/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { GEOMETRY_COLLECTION, MULTIPOLYGON, POINT, POLYGON } from 'constants/mapbox';
import {
  acresToFeetSideLength,
  clipZonesToLayer,
  filterPointsWithinBoundaries,
  filterZonesWithinBoundaries,
  mergeZonesUnderThreshold,
  processZoneFeatureCollection,
} from 'util/geospatial';
import { haToAcres } from 'util/units';
import {
  Feature,
  FeatureCollection,
  GeometryCollection,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import turfBbox from '@turf/bbox';
import transformRotate from '@turf/transform-rotate';
import squareGrid from '@turf/square-grid';
import pointGrid from '@turf/point-grid';
import buffer from '@turf/buffer';
import { getAgronomicZones } from 'store/samplePlans/requests';
import { feature, featureCollection } from '@turf/helpers';
import { TURF_ILLEGAL_ARGUMENT_EXCEPTION } from 'constants/errorTypes';
import { getString } from 'strings/translation';
import showToast, { type ToastType } from 'actions/toastActions';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYSIS_TYPES, ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';

import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import Confirmation from './Components/Confirmation/Confirmation';
import { ZoneAnalysisStateType } from 'store/zoneAnalysisV2/reducer';
import { requestOperationUsers } from 'store/user/requests';
import { getExternalNutrientLabs } from 'store/zoneAnalysisV2/thunks';
import { canGetExternalNutrientLabs } from 'util/zoneAnalysis';

type ParamsType = {
  fieldId: string;
  operationId: string;
  planId: string;
};

const ZoneAnalysisV2Container = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const drawRef = useRef<any>(null);
  const { currentTiming, timings } = useSampleTiming();

  const params = useParams<ParamsType>();
  const { planId } = params;
  const numericPlanId = Number(planId);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [isPageLoading, toggleIsPageLoading] = useState<boolean>(true);
  const [savedState, setSavedState] = useState<ZoneAnalysisStateType['plan'] | null>(null);

  const { operation } = useOperation();
  const fieldGeometry = useFieldGeometry();

  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
  }));

  const displayToast = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  const analysis = useSelector((state: RootState) => state.zoneAnalysisV2.plan);

  const isProOrTillRx = analysis.isProScan || analysis.isTillRx;

  const updatePlanState = useCallback(
    (metaKeyValue: ZoneAnalysisKeyType) => dispatch(updateZoneAnalysis(metaKeyValue)),
    [],
  );

  useEffect(() => {
    if (currentTiming && fieldGeometry && operation && isPageLoading) {
      (async function populatePlan() {
        await setupInitialAnalysis();
        toggleIsPageLoading(false);
      })();
    }
  }, [currentTiming, fieldGeometry, operation, isPageLoading]);

  useEffect(() => {
    if (canGetExternalNutrientLabs(currentUser)) {
      dispatch(getExternalNutrientLabs());
    }
  }, [currentUser]);

  const resetPreviewScanGrid = (density: number) => {
    const { geometry, properties } = fieldGeometry.features[0];
    const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
    const units = 'feet';

    const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));
    const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });

    updatePlanState({
      zoneGeomType: POINT,
      previewZones: processZoneFeatureCollection(gridPoints, POINT) as FeatureCollection<Point>,
    });
  };

  const resetPreviewGrid = (
    creationType: typeof GRID_ZONES | typeof GRID_POINTS,
    density: number,
  ) => {
    const { geometry, properties } = fieldGeometry.features[0];
    const cellWidth = acresToFeetSideLength(haToAcres(density, properties.acreage_unit));
    const units = 'feet';

    const bufferedBbox = turfBbox(buffer(geometry, cellWidth * 2, { units }));
    if (creationType === GRID_POINTS) {
      updatePlanState({ zoneGeomType: POINT });
      const gridPoints = pointGrid(bufferedBbox, cellWidth, { units });

      return processZoneFeatureCollection(gridPoints, POINT) as FeatureCollection<Point>;
    }

    updatePlanState({ zoneGeomType: POLYGON });
    const gridPolys = squareGrid(bufferedBbox, cellWidth, { units });
    return processZoneFeatureCollection(gridPolys, POLYGON) as FeatureCollection<Polygon>;
  };

  const createAgronomicZones = async (numZones: number, zoneType: AgronomicZonesType) => {
    try {
      const zoneGeoms: FeatureCollection = await getAgronomicZones(
        fieldGeometry.features[0].properties.id,
        numZones,
        zoneType,
      );
      const validGeomsOnly = zoneGeoms.features.reduce((list, feat) => {
        if ([POLYGON, MULTIPOLYGON].includes(feat.geometry?.type)) {
          return list.concat(feat as Feature<Polygon>);
        }
        if (feat.geometry?.type === GEOMETRY_COLLECTION) {
          const polyGeometry = (feat.geometry as GeometryCollection).geometries
            .filter((geo) => [POLYGON, MULTIPOLYGON].includes(geo.type))
            .map((geo) => feature(geo) as Feature<Polygon>);
          return list.concat(polyGeometry);
        }
        return list;
      }, [] as Feature<Polygon>[]);

      if (!validGeomsOnly.length) {
        throw new Error('Invalid geometries received.');
      }

      const mergedGeoms = await mergeZonesUnderThreshold(
        clipZonesToLayer(featureCollection(validGeomsOnly), fieldGeometry),
        numZones,
        fieldGeometry,
      );

      return processZoneFeatureCollection(
        mergedGeoms as FeatureCollection<Polygon>,
        POLYGON,
      ) as FeatureCollection<Polygon>;
    } catch (error) {
      updatePlanState({ enableButtonSpinner: false });
      toggleIsPageLoading(false);
      // Check for issues with trying to merge and clip zones with turf
      if (error?.name === TURF_ILLEGAL_ARGUMENT_EXCEPTION) {
        displayToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
      } else {
        displayToast(getString('unableToLoadZonesMsg', language), 'error', 7000);
      }
    }
    return null;
  };

  const createFinalizedGrid = async (creationType: typeof GRID_ZONES | typeof GRID_POINTS) => {
    try {
      if (analysis.previewZones) {
        if (creationType === GRID_POINTS) {
          const filteredPoints = filterPointsWithinBoundaries(
            analysis.previewZones,
            fieldGeometry,
          ) as FeatureCollection<Point>;
          return processZoneFeatureCollection(filteredPoints, POINT) as FeatureCollection<Point>;
        }

        const intersectingZones = filterZonesWithinBoundaries(
          analysis.previewZones as FeatureCollection<MultiPolygon | Polygon>,
          fieldGeometry,
        ) as FeatureCollection<Polygon>;
        const clippedZones = clipZonesToLayer(
          intersectingZones,
          fieldGeometry,
        ) as FeatureCollection<MultiPolygon | Polygon>;

        const targetNumZones = getTargetNumberOfZones(
          fieldGeometry.features[0].properties.acreage,
          analysis.density,
        );
        const mergedGeoms = await mergeZonesUnderThreshold(
          clippedZones,
          targetNumZones,
          fieldGeometry,
        );
        return processZoneFeatureCollection(mergedGeoms, POLYGON) as FeatureCollection<Polygon>;
      }
      return null;
    } catch (err) {
      displayToast(getString('errorWithFieldGeometryMsg', language), 'error', 7000);
      return null;
    }
  };

  const prepareCreatingZones = (creationOption: string) => {
    const targetZones = getTargetNumberOfZones(
      fieldGeometry.features[0].properties.acreage,
      analysis.density,
    );
    if (creationOption === SSURGO_ZONES || creationOption === DEM_ZONES) {
      updatePlanState({ enableButtonSpinner: true });
      return createAgronomicZones(targetZones, creationOption);
    }
    if (creationOption === GRID_POINTS || creationOption === GRID_ZONES) {
      updatePlanState({
        zoneGeomType: creationOption === GRID_ZONES ? POLYGON : POINT,
      });
      return createFinalizedGrid(creationOption);
    }
    if (creationOption === UPLOAD_ZONES) {
      updatePlanState({ enableButtonSpinner: true });
      uploadRef.current?.click();
    }
    if (creationOption === CUSTOM_POINTS) {
      updatePlanState({ zoneGeomType: POINT });
    }
    return null;
  };

  const createZones = async (setUserZones: boolean = false) => {
    const newZones = await prepareCreatingZones(analysis.creationOption);
    if (setUserZones) {
      updatePlanState({
        zones: newZones,
        enableButtonSpinner: false,
      });
    }
    updatePlanState({ previewZones: null });
  };

  const prepareResetZones = (creationOption: string) => {
    updatePlanState({ zoneGeomType: POLYGON });
    if (creationOption === GRID_ZONES || creationOption === GRID_POINTS) {
      updatePlanState({
        zoneGeomType: creationOption === GRID_ZONES ? POLYGON : POINT,
      });
      return resetPreviewGrid(creationOption, analysis.density);
    }
    if (creationOption === CUSTOM_POINTS) {
      updatePlanState({ zoneGeomType: POINT });
    }
    return null;
  };

  const filterZonesIfPro = (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    resetUserZones: boolean,
  ) => {
    if (
      isProOrTillRx &&
      analysis.zones &&
      creationType === ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION
    ) {
      return featureCollection(
        analysis.zones.features.filter((zone) => !isProScanPoint(zone, isProOrTillRx)),
      );
    }
    return resetUserZones ? null : analysis.zones;
  };

  const resetZones = async (
    creationType:
      | typeof ANALYSIS_TYPES.CREATION_OPTION
      | typeof ANALYSIS_TYPES.PRO_POINT_CREATION_OPTION,
    creationOption: string,
    resetUserZones: boolean = false,
  ) => {
    const previews = await prepareResetZones(creationOption);
    updatePlanState({
      previewZones:
        isGridsOption(creationOption) && previews
          ? transformRotate(previews, analysis.gridAngle)
          : previews,
      zones: filterZonesIfPro(creationType, resetUserZones),
    });
  };

  const setGridZonesAsDefault = async () => {
    // Default creation Option is Grid 10ac
    const previews = await prepareResetZones(analysis.creationOption);
    updatePlanState({
      existingPlan: null,
      name: defaultPlanDate(),
      disableMapTools: true,
      enableButtonSpinner: false,
      zonesLocked: false,
      previewZones: previews
        ? transformRotate(previews, analysis.gridAngle)
        : analysis.previewZones,
    });
  };

  const setupInitialAnalysis = async () => {
    if (fieldGeometry && operation && currentTiming) {
      const { sampling_plans } = fieldGeometry.features[0].properties;
      const existingPlan = sortByCreatedAt(sampling_plans).find(
        (plan) =>
          ![SAMPLE_STATUSES.CANCELLED, SAMPLE_STATUSES.COMPLETED].includes(plan.sampling_status),
      );
      const completedPlan = sortByCreatedAt(sampling_plans).find(
        (plan) => plan.sampling_status === SAMPLE_STATUSES.COMPLETED,
      );
      try {
        const operationUsers = await requestOperationUsers(
          fieldGeometry.features[0].properties.operation_id,
        );
        if (existingPlan) {
          const newAnalysis = await setupExistingAnalysis(
            operation,
            operationUsers.users,
            fieldGeometry,
            existingPlan,
            currentTiming,
            timings,
          );
          updatePlanState(newAnalysis);
        } else if (completedPlan) {
          const newAnalysis = await setupCompletedAnalysis(
            operation,
            operationUsers.users,
            fieldGeometry,
            completedPlan,
            currentTiming,
          );
          updatePlanState(newAnalysis);
        } else {
          const newAnalysis = getDefaultAnalysis(
            operation,
            operationUsers.users,
            currentTiming,
            fieldGeometry,
          );
          updatePlanState(newAnalysis);
          await setGridZonesAsDefault();
        }
      } catch (err) {
        setGridZonesAsDefault();
      }
    }
  };

  if (isPageLoading) {
    return (
      <Center h="90vh">
        <Loader />
      </Center>
    );
  }

  return (
    <Box className={Styles.Wrapper}>
      <Header drawRef={drawRef} field={fieldGeometry} setSavedState={setSavedState} />
      <Confirmation
        opened={analysis.isConfirmationVisible}
        deleteExisting={operationAllowsMultiplePlans(operation)}
        field={fieldGeometry}
        planId={numericPlanId}
        savedState={savedState}
      />
      <Box className={Styles.MenuWrapper}>
        <Box className={Styles.FormField}>
          <Form
            createZones={createZones}
            resetZones={resetZones}
            resetPreviewScanGrid={resetPreviewScanGrid}
            createFinalizedGrid={createFinalizedGrid}
            uploadRef={uploadRef}
            drawRef={drawRef}
          />
        </Box>
        <Box data-test-id="orders-page-map-container" className={Styles.MapField}>
          {analysis.accordionLocation === SAMPLING_ZONES ? (
            <ZoneAnalysisMap
              field={fieldGeometry}
              mapContainerRef={mapContainerRef}
              mapRef={mapRef}
              drawRef={drawRef}
            />
          ) : (
            <AnalysisForm fieldGeometry={fieldGeometry} operation={operation} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ZoneAnalysisV2Container;
