import { Feature, MultiPolygon } from 'geojson';
import {
  SERVICE_URL,
  requestGet,
  requestPost,
  handleJsonResponse,
  requestDelete,
} from 'util/request';

export const requestAllTerritories = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/commercial_territories/`));

export const requestTerritory = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/commercial_territory/${id}`));

export const requestPostCommercialTerritory = async (body: Feature<MultiPolygon | null>) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/commercial_territory/`, {
      body,
    }),
  );

export const requestDeleteCommercialTerritory = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/commercial_territory/${id}`));
