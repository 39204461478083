import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import {
  getAnalyticFromPlan,
  getDisplaySamplesForAnalytic,
  getFieldRisk,
  getOmQuantities,
  getPlanAnalytic,
  getPlanRiskLevel,
  getResultRecommendation,
  getTotalSamplesAtRisk,
} from './results';
import { allCrops, COTTON, SOYBEANS, SUGAR_BEETS_CROP, WHEAT } from 'constants/variables';
import CornPng from 'images/icons/crops/corn.png';
import SoybeansPng from 'images/icons/crops/soybeans.png';
import SugarBeetsPng from 'images/icons/crops/sugar-beets.png';
import CottonPng from 'images/icons/crops/cotton.png';
import WheatPng from 'images/icons/crops/wheat.png';
import { AnalyticType } from 'store/analytics/types';
import { getQuantityFromPercentInLevel, getRiskColorFill } from './chartUtils';
import { WHITE } from './mapImageryColors';
import {
  ANAEROBIC_POTENTIAL_ID,
  BCSR,
  COMPACTION,
  DIVERSITY_ID,
  OM_ID,
  REFUND_THRESHOLD,
  ROOTWORM_ID,
} from 'constants/results';
import {
  AWAITING_RESULTS,
  LOW_RISK,
  lowModHighRiskLevels,
  NO_DATA,
  NOT_ANALYZED,
} from 'constants/fieldRisks';
import { RecommendationType } from 'store/recommendations/types';
import { sortAnalyticsById, sortFarmThenFieldName } from './sortByName';
import styles from '../apps/Results/OverviewSummaryV2/AnalysisTable/AnalyticChart.module.css';
import { getCropOrDefault } from './cropPlans';
import { OperationFieldType } from 'store/operation/types';
import { getAnalyticValueCompaction } from 'util/proMaps';

const getBoxStyle = (analyticId: number, risk: string, isOverviewNd: boolean) => {
  if (isOverviewNd && risk === LOW_RISK) {
    return styles.EmptyBox;
  }
  if (risk === AWAITING_RESULTS) {
    return styles.AwaitingResultsBox;
  }
  if (risk === NOT_ANALYZED && [OM_ID, DIVERSITY_ID, ANAEROBIC_POTENTIAL_ID].includes(analyticId)) {
    return styles.AwaitingResultsBox;
  }
  if (risk === NOT_ANALYZED) {
    return styles.NotAnalyzedBox;
  }
  if (risk === NO_DATA) {
    return styles.NoDataBox;
  }
  return styles.HasDataBox;
};

export const getOverviewResultsConfig = (
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType | undefined | null,
) => {
  const analyticValue =
    activeAnalytic.category === COMPACTION
      ? getAnalyticValueCompaction(samplingPlan, activeAnalytic)
      : getAnalyticFromPlan(samplingPlan, activeAnalytic);
  const displayConfig = activeAnalytic.display_config;
  const overviewBarValue = getQuantityFromPercentInLevel(analyticValue);
  const isNDAndZero = displayConfig?.is_overview_nd && analyticValue?.quantity === 0;
  const isNDWithValue =
    displayConfig?.is_overview_nd && !!analyticValue && analyticValue?.quantity > 0;
  const overviewBackgroundColor = analyticValue
    ? getRiskColorFill(analyticValue.risk_level, isNDWithValue)
    : WHITE;
  const risk = getPlanRiskLevel(analyticValue);
  const overviewBoxStyle = getBoxStyle(activeAnalytic.id, risk, !!displayConfig?.is_overview_nd);
  const { low, moderate, high } = analyticValue?.risk_summary || {};
  const isOnlyLowRisk = low && !moderate && !high;
  const isBCSR = activeAnalytic.category === BCSR;
  const overviewBoxShowValueOnly = isOnlyLowRisk || isBCSR;
  const overviewShowLevels = Boolean(
    !overviewBoxShowValueOnly && analyticValue && lowModHighRiskLevels.includes(risk),
  );
  return {
    ...displayConfig,
    analyticValue,
    risk,
    overviewBarValue,
    overviewBackgroundColor,
    isNDAndZero,
    isNDWithValue,
    overviewBoxStyle,
    overviewBoxShowValueOnly,
    overviewShowLevels,
  };
};

const getFieldAcresAtRisk = (
  fieldTotalAcres: number,
  samples: MapboxSample[],
  activeAnalytic: AnalyticType,
) => {
  const samplesAtRisk = getTotalSamplesAtRisk(samples, activeAnalytic);
  const totalSamplesWithAnalytic =
    samples.filter((sample) => {
      const value = sample.properties.analytics[activeAnalytic.category]?.[activeAnalytic.id];
      return value !== undefined && value !== null;
    }).length || 1;
  return (samplesAtRisk / totalSamplesWithAnalytic) * fieldTotalAcres;
};

export const getFieldResultsConfig = (
  activeAnalytic: AnalyticType,
  samplingPlan: SamplingPlanType,
  samples: MapboxSample[],
  recommendations: RecommendationType[],
  field: FieldType,
) => {
  const displaySamples = getDisplaySamplesForAnalytic(samples, activeAnalytic);
  const planAnalytic = getPlanAnalytic(samplingPlan, activeAnalytic.category, activeAnalytic.id);
  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);
  const { acreage: fieldTotalAcres } = field.features[0].properties;
  const recommendation = getResultRecommendation(
    recommendations,
    activeAnalytic.id,
    planAnalytic?.risk_level,
  ) as RecommendationType;

  const fieldAtRiskAcres = getFieldAcresAtRisk(fieldTotalAcres, displaySamples, activeAnalytic);
  const fieldOmQuantities = getOmQuantities(displaySamples);
  const hasInsufficientData =
    displaySamples.filter((sample) => {
      const value = sample.properties.analytics[activeAnalytic.category]?.[activeAnalytic.id];
      return value === undefined || value === null;
    }).length /
      (displaySamples.length || 1) >
      REFUND_THRESHOLD && activeAnalytic.id !== ROOTWORM_ID;
  // exclude rootworm from insufficient data check

  return {
    planAnalytic,
    risk,
    recommendation,
    fieldAtRiskAcres,
    fieldTotalAcres,
    fieldOmQuantities,
    hasInsufficientData,
  };
};

export const getCropImage = (crop?: string) => {
  if (crop === SOYBEANS) {
    return SoybeansPng;
  }
  if (crop === SUGAR_BEETS_CROP) {
    return SugarBeetsPng;
  }
  if (crop === COTTON) {
    return CottonPng;
  }
  if (crop === WHEAT) {
    return WheatPng;
  }
  return CornPng;
};

export const getOtherCrops = (crop: string) => {
  return allCrops.filter((c) => c !== crop);
};

const maxDisplayLen = 17;
const nameIconYieldLen = 4;
export const getAnalyticLength = (subCategoryAnalytics: { [key: string]: AnalyticType[] }) => {
  const maxLength = Object.values(subCategoryAnalytics).reduce((max, arr) => {
    return Math.max(max, arr.length);
  }, 0);
  return (maxLength > maxDisplayLen ? maxLength : maxDisplayLen) + nameIconYieldLen;
};

export const filterAnalyticsPerTabByCrop = (
  analyticsPerTab: AnalyticType[],
  planAnalytics: SamplingPlanType['analytics'],
  analyticIdsShown: number[],
) => {
  const spreadAnalytics = Object.values(planAnalytics).reduce(
    (acc, categoryGroup) => ({ ...acc, ...categoryGroup }),
    {},
  );
  return sortAnalyticsById(
    analyticIdsShown.reduce((acc: AnalyticType[], analyticId) => {
      const analytic = analyticsPerTab.find((a) => a.id === analyticId);
      return analytic && spreadAnalytics?.[analytic.id]?.quantity !== null
        ? [...acc, analytic]
        : acc;
    }, []),
    analyticIdsShown,
  );
};

export const getFieldAnalyticsByCrop = (
  filteredFields: FieldType[],
  subCategoryAnalytics: { [crop: string]: AnalyticType[] },
  operationFields: OperationFieldType[],
) =>
  Object.keys(subCategoryAnalytics).reduce((acc, crop) => {
    return {
      ...acc,
      [crop]: sortFarmThenFieldName(operationFields)
        .map((field) =>
          filteredFields.find((filtField) => field.id === filtField.features[0].properties.id),
        )
        .filter((field) => field && getCropOrDefault(field) === crop) as FieldType[],
    };
  }, {});
