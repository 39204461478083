import React from 'react';

import { Button } from 'common';

import styles from './Modal.module.css';

interface ModalPropsType {
  cancelUpload: () => void;
  fileName: string;
  hideModal: () => void;
  modalStep: string | null;
  operationName: string;
  percentComplete: number;
}

const Modal = ({
  cancelUpload,
  fileName,
  hideModal,
  modalStep,
  operationName,
  percentComplete,
}: ModalPropsType) => {
  switch (modalStep) {
    case 'pending':
      return (
        <>
          <div className={styles.Modal}>
            <h1>Upload Progress</h1>
            {`You are currently uploading the following file to ${operationName}:`}
            <span className={styles.FileName}>{fileName}</span>
            <div className={styles.StatusBarWrapper}>
              <div className={styles.StatusBar} style={{ width: `${percentComplete}%` }} />
            </div>
            {`${percentComplete}% complete`}
            <i className={styles.Warning}>
              Do not navigate away from this page until upload is complete
            </i>
            <Button onClick={cancelUpload} primary>
              Cancel
            </Button>
          </div>
          <div className={styles.Overlay} />
        </>
      );
    case 'failure':
      return (
        <>
          <div className={styles.Modal}>
            <h1>Upload Failed</h1>
            {`The upload to ${operationName} failed. Please try again.`}
            <Button className={styles.Close} onClick={hideModal} primary>
              Close
            </Button>
          </div>
          <div className={styles.Overlay} />
        </>
      );
    case 'invalid':
      return (
        <>
          <div className={styles.Modal}>
            <h1>Invalid Filename</h1>
            The uploaded file must be in a .zip or .gz format, and should contain only the following
            characters: a-z, A-Z, 0-9, -, _. Please re-name and upload again.
            <Button className={styles.Close} onClick={hideModal} primary>
              Close
            </Button>
          </div>
          <div className={styles.Overlay} />
        </>
      );
    default:
      return null;
  }
};

export default Modal;
