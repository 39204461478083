import {
  ControlsActionType,
  CONTROL_REQUEST_ERROR,
  ControlType,
  RECEIVE_CONTROL,
  RECEIVE_CONTROLS,
  REQUEST_CONTROLS,
} from './types';

export const requestControls = (): ControlsActionType => ({
  type: REQUEST_CONTROLS,
});

export const receiveControls = (controls: ControlType[]): ControlsActionType => {
  return {
    type: RECEIVE_CONTROLS,
    payload: {
      controls,
    },
  };
};

export const receiveControl = (control: ControlType): ControlsActionType => {
  return {
    type: RECEIVE_CONTROL,
    payload: control,
  };
};

export const controlRequestError = (payload: { message: string }): ControlsActionType => ({
  type: CONTROL_REQUEST_ERROR,
  payload,
});
