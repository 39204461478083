import { GeoJSON } from 'geojson';
import { RiskSampleType, SampleType as SampleOriginalType } from '../samples/types';
import { SamplePlanType as SamplePlanOriginType } from '../samplePlans/types';
import { PrescriptionType } from '../prescriptions/types';
import { CountyType } from '../salesFlow/types';
import { CropPlanType } from 'store/cropPlans/types';

export const REFRESH_FIELDS = 'REFRESH_FIELDS';
export const DELETE_FIELD = 'DELETE_FIELD';
export const SET_FIELDS_WILL_FETCH = 'SET_FIELDS_WILL_FETCH';
export const FIELD_GEOMETRY_ERROR = 'FIELD_GEOMETRY_ERROR';
export const RECEIVE_FIELD_GEOMETRY = 'RECEIVE_FIELD_GEOMETRY';
export const REQUEST_FIELD_GEOMETRY = 'REQUEST_FIELD_GEOMETRY';
export const RECEIVE_FIELD_GEOMETRIES = 'RECEIVE_FIELD_GEOMETRIES';
export const FIELD_GEOMETRIES_ERROR = 'FIELD_GEOMETRIES_ERROR';
export const REQUEST_FIELD_GEOMETRIES = 'REQUEST_FIELD_GEOMETRIES';
export const RECEIVE_SAMPLE_PLAN = 'RECEIVE_SAMPLE_PLAN';
export const RECEIVE_PRESCRIPTION = 'RECEIVE_PRESCRIPTION';
export const CLEAR_FIELD_GEOMETRIES = 'CLEAR_FIELD_GEOMETRIES';

export const REQUEST_CROP_PLAN_OPTIMIZE = 'REQUEST_CROP_PLAN_OPTIMIZE';
export const RECEIVE_CROP_PLAN_OPTIMIZE = 'RECEIVE_CROP_PLAN_OPTIMIZE';
export const CROP_PLAN_OPTIMIZE_ERROR = 'CROP_PLAN_OPTIMIZE_ERROR';

export type GeometryType = {
  [id: number]: FieldType;
};

export type ClassificationAbudances18s16s = {
  count: number;
  internal_taxid: number;
  lineage_json: {
    class: string;
    family: string;
    genus: string;
    kingdom: string;
    order: string;
    phylum: string;
    root: string;
    species: string;
    superkingdom: string;
  };
  proportion_all: number;
  total_reads: number;
};

export type SampleType = SampleOriginalType;

export type MapboxSample = GeoJSON.Feature<GeoJSON.Geometry, SampleType>;

export type RiskMapboxSample = GeoJSON.Feature<GeoJSON.Geometry, RiskSampleType>;

export type SamplingPlanType = SamplePlanOriginType;

export type FieldPropertiesType = {
  active: boolean;
  operation_id: number;
  samples: SampleType[];
  sampling_plans: SamplingPlanType[];
  county?: CountyType;
  id: number;
  acreage: number;
  acreage_unit: string;
  country_code: string | null;
  crop_plans: CropPlanType[];
  name: string;
  farm_name: string;
  state: string;
  sampling_status: 'never sampled' | 'sampling' | 'shipping' | 'processing' | 'completed';
  prescriptions: PrescriptionType[];
  crop_years: number[];
};

export type FieldType = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon, FieldPropertiesType>;

type DeleteFieldActionType = {
  type: typeof DELETE_FIELD;
  payload: {
    fieldId: number;
  };
};

type FieldGeometryErrorType = {
  type: typeof FIELD_GEOMETRY_ERROR;
  payload: {
    fieldId: number;
  };
};

type SetFieldsWillFetch = {
  type: typeof SET_FIELDS_WILL_FETCH;
  payload: {
    fieldIds: number[];
  };
};

type ReceiveFieldGeometryType = {
  type: typeof RECEIVE_FIELD_GEOMETRY;
  payload: {
    fieldId: number;
    geometry: FieldType;
  };
};

type ReceiveFieldGeometriesType = {
  type: typeof RECEIVE_FIELD_GEOMETRIES;
  payload: {
    fieldIds: Array<number>;
    geometries: Array<FieldType>;
  };
};

type FieldGeometriesErrorType = {
  type: typeof FIELD_GEOMETRIES_ERROR;
  payload: {
    fieldIds: Array<number>;
  };
};

type ReceiveSamplePlanType = {
  type: typeof RECEIVE_SAMPLE_PLAN;
  payload: {
    fieldId: number;
    samplePlan: SamplingPlanType;
  };
};

type ReceivePrescriptionType = {
  type: typeof RECEIVE_PRESCRIPTION;
  payload: {
    fieldId: number;
    prescription: PrescriptionType;
  };
};

type RequestFieldGeometryType = {
  type: typeof REQUEST_FIELD_GEOMETRY;
  payload: {
    fieldId: number;
  };
};

type RequestFieldGeometriesType = {
  type: typeof REQUEST_FIELD_GEOMETRIES;
  payload: {
    fieldIds: Array<number>;
  };
};

type ClearFieldGeometriesType = {
  type: typeof CLEAR_FIELD_GEOMETRIES;
};

type RequestCropPlanOptimizeActionType = {
  type: typeof REQUEST_CROP_PLAN_OPTIMIZE;
  payload: {
    cropPlanIds: number[];
  };
};

type ReceiveCropPlanOptimizeActionType = {
  type: typeof RECEIVE_CROP_PLAN_OPTIMIZE;
  payload: {
    cropPlans: CropPlanType[];
  };
};

type CropPlanOptimizeErrorActionType = {
  type: typeof CROP_PLAN_OPTIMIZE_ERROR;
  payload: {
    message: string;
  };
};

type RefreshFieldsActionType = {
  type: typeof REFRESH_FIELDS;
};

export type FieldActionsType =
  | DeleteFieldActionType
  | FieldGeometryErrorType
  | ReceiveFieldGeometryType
  | ReceiveFieldGeometriesType
  | FieldGeometriesErrorType
  | ReceiveSamplePlanType
  | ReceivePrescriptionType
  | RequestFieldGeometryType
  | RequestFieldGeometriesType
  | SetFieldsWillFetch
  | ClearFieldGeometriesType
  | RequestCropPlanOptimizeActionType
  | ReceiveCropPlanOptimizeActionType
  | CropPlanOptimizeErrorActionType
  | RefreshFieldsActionType;

export type CluType = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>;
