import {
  RECEIVE_SAMPLE_PRICES,
  REQUEST_SAMPLE_PRICES,
  SAMPLE_PRICE_REQUEST_ERROR,
  SamplePricesActionType,
  SamplePricesStateType,
} from './types';

export const initialState: SamplePricesStateType = {
  hasFailed: false,
  isFetching: false,
  samplePrices: [],
};

export const SamplePricesReducer = (state = initialState, action: SamplePricesActionType) => {
  switch (action.type) {
    case RECEIVE_SAMPLE_PRICES:
      return {
        ...state,
        isFetching: false,
        hasFailed: false,
        samplePrices: action.payload.samplePrices,
      };
    case REQUEST_SAMPLE_PRICES:
      return {
        ...state,
        isFetching: true,
      };
    case SAMPLE_PRICE_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
