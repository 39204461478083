import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Input, Label, Selector, Toggle } from 'common';
import { getSampleTypeOptions, getReplicaTypeOptions } from 'util/sample';
import { WellType } from 'store/plates/types';
import { formatIntString } from 'util/stringUtils';

import RnDFieldSet from './RnDAttributesFieldSet';
import styles from '../Container.module.css';

type SharedFormInputsPropsType = {
  well: WellType;
  onValueChange: (
    attributeKey: keyof WellType,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => void;
  errorMessage?: string | null;
  setErrorMessage?: (message: string) => void;
  rndToggle?: boolean;
  sampleType?: boolean;
  replicaType?: boolean;
  rndAttributes?: boolean;
};

export const SharedFormInputs = ({
  well,
  onValueChange,
  errorMessage,
  setErrorMessage,
  rndToggle,
  sampleType,
  replicaType,
  rndAttributes,
}: SharedFormInputsPropsType) => {
  const language = useBroswerLanguage();
  const sampleTypeOptions = getSampleTypeOptions(language);
  const replicaTypeOptions = getReplicaTypeOptions(language);

  const selectReplica = (idx: number) => {
    if (errorMessage === getString('sampleAlreadyInWellMsg', language)) {
      setErrorMessage && setErrorMessage('');
    }
    onValueChange('replica_type', replicaTypeOptions[idx].value);
  };
  const selectSampleType = (idx: number) => onValueChange('type', sampleTypeOptions[idx].value);
  return (
    <>
      {rndToggle && (
        <Label className={styles.InputLabel} label={getString('rndSample', language)}>
          <Toggle
            dataTestId="mark-sample-rnd"
            className={styles.Toggle}
            checked={Boolean(well.rnd)}
            onChange={(e: React.ChangeEvent<HTMLFormElement>) => {
              onValueChange('rnd', e.target.checked);
            }}
          />
        </Label>
      )}
      {sampleType && (
        <Label className={styles.InputLabel} label={getString('sampleType', language)}>
          <Selector
            className={styles.InputSelector}
            placeholder={getString('none', language)}
            activeIndex={sampleTypeOptions.findIndex((opt) => opt.value === well.type)}
            onChange={selectSampleType}
            options={sampleTypeOptions}
          />
        </Label>
      )}
      {replicaType && (
        <Label className={styles.InputLabel} label={getString('replicaType', language)}>
          <Selector
            className={styles.InputSelector}
            placeholder={getString('none', language)}
            activeIndex={replicaTypeOptions.findIndex(
              (opt) => opt.value === well.replica_type || opt.value === well.sample?.replica_type,
            )}
            onChange={selectReplica}
            options={replicaTypeOptions}
          />
        </Label>
      )}
      {rndAttributes && (
        <RnDFieldSet
          onValueChange={onValueChange}
          rndAttributes={well.rnd_meta || well.sample?.rnd_meta || {}}
        />
      )}
      <Label label={getString('sampleMass', language)}>
        <Input
          value={String(well.sample_weight || well.sample?.sample_weight || '')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onValueChange('sample_weight', parseFloat(formatIntString(e.target.value)))
          }
          name="sample_weight"
        />
      </Label>
      <Label label={getString('notes', language)}>
        <Input
          value={well.notes || well.sample?.lab_notes || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onValueChange('notes', e.target.value)
          }
          name="notes"
        />
      </Label>
    </>
  );
};
