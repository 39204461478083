export const TILLAGE_LAYER_TEST_RESULTS = 'testResults';
export const TILLAGE_LAYER_RX = 'rx';

export const TILL_RX_PROPERTIES_KEY = 'till_to_inch';
export const TILL_TEST_RESULTS_PROPERTIES_KEY = 'psi';

export const tillagePolygonLayerIds = {
  /** Aka "Compaction" */
  testResults: TILLAGE_LAYER_TEST_RESULTS,
  rx: TILLAGE_LAYER_RX,
} as const;

export const DCP_POINTS_LAYER_ID = 'dcpPoints';
