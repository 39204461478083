import React from 'react';

import { Button, Container } from 'common';

import styles from './Pagination.module.css';

export const Pagination = ({ page, totalPages, setPage }) => {
  const BLANK = -1;

  const pages = Array.from(Array(totalPages), (x, i) => i + 1)
    .map((i) => {
      // first pages always shown
      if (i <= 3 || i >= totalPages - 3) {
        return i;
      }
      // 3 pages before and 3 pages after the current
      if (i >= page - 3 && i <= page + 3) {
        return i;
      }

      return BLANK;
    })
    .reduce((acc, i) => {
      // skip consecutive blanks
      if (acc[acc.length - 1] === BLANK && i === BLANK) {
        return acc;
      }
      acc.push(i);
      return acc;
    }, []);

  return (
    <Container className={styles.Pagination}>
      {pages.map((i, idx) => {
        if (i === BLANK) {
          return <span key={`blank${idx}`}>…</span>;
        }
        return (
          <Button key={i} onClick={() => setPage(i)} disabled={i === page} primary={i === page}>
            {String(i)}
          </Button>
        );
      })}
    </Container>
  );
};
