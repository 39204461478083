import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Table, Text } from '@mantine/core';
import { LoadingRows, Pagination } from 'common';
import { RootState } from 'store';
import { getSamplingHistory } from 'store/samplingHistory/thunks';

import { SamplingHistoryRow } from './SamplingHistoryRow';

type SamplingHistoryTablePropsType = {
  operationId?: number | null;
  year: number;
};

export const SamplingHistoryTable = ({ operationId, year }: SamplingHistoryTablePropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { samplingDates, totalPages, isFetching } = useSelector((state: RootState) => ({
    samplingDates: state.samplingHistory.paginated.items,
    totalPages: state.samplingHistory.paginated.total,
    isFetching: state.samplingHistory.isFetching,
  }));

  useEffect(() => {
    dispatch(getSamplingHistory(page, year, operationId));
  }, [page, year, operationId]);

  return (
    <Table>
      <Table.Tbody>
        <Table.Tr>
          <Table.Th w="20rem" miw="11rem">
            <Text inherit>{getString('date', language)}</Text>
          </Table.Th>
          <Table.Th w="15%" miw="5.5rem" ta="right">
            <Text inherit>{getString('samplePlans', language)}</Text>
          </Table.Th>
          <Table.Th w="10%" miw="4.5rem" ta="right">
            <Text inherit>{getString('acres', language)}</Text>
          </Table.Th>
          <Table.Th w="10%" miw="4.5rem" ta="right">
            <Text inherit>{getString('samples', language)}</Text>
          </Table.Th>
          <Table.Th w="20%" ta="right">
            <Text inherit>{getString('shippedDate', language)}</Text>
          </Table.Th>
          <Table.Th w="10%" miw="4.5rem" ta="right">
            <Text inherit>{getString('arrived', language)}</Text>
          </Table.Th>
          <Table.Th w="3rem" maw="3rem" miw="3rem" />
        </Table.Tr>
        {isFetching && <LoadingRows colSpan={7} rowSpan={10} />}
        {!isFetching &&
          samplingDates.map((date) => (
            <SamplingHistoryRow sampledDate={date} key={date.sampled_date} />
          ))}
        {!isFetching && samplingDates.length === 0 && (
          <Table.Tr>
            <Table.Td colSpan={7} ta="center">
              <Text inherit>{getString('noSamplingHistoryMsg', language)}</Text>
            </Table.Td>
          </Table.Tr>
        )}
        {totalPages > 1 && (
          <Table.Tr>
            <Table.Td colSpan={7} ta="center">
              <Pagination page={page} setPage={setPage} totalPages={totalPages} />
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};
