import {
  NAPT_CONTROLS_REQUEST_ERROR,
  NaptControlsActionType,
  NaptControlsStateType,
  RECEIVE_NAPT_CONTROLS,
  REQUEST_NAPT_CONTROLS,
} from './types';

export const initialState: NaptControlsStateType = {
  hasFetched: false,
  isFetching: false,
  hasFailed: false,
  naptControls: [],
};

export const naptControlsReducer = (state = initialState, action: NaptControlsActionType) => {
  switch (action.type) {
    case REQUEST_NAPT_CONTROLS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
        hasFailed: false,
      };
    case RECEIVE_NAPT_CONTROLS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        naptControls: action.payload.controls,
      };
    case NAPT_CONTROLS_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};
