import { handleJsonResponse, requestGet, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';

export const requestGetSamplingHistory = async (
  page: number = 1,
  year?: number,
  operationId?: number | null,
) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/sampling_history/?${queryStringify({
        page,
        year,
        operation_id: operationId || undefined,
      })}`,
    ),
  );
