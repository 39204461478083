import {
  PlateActionsType,
  PLATES_REQUEST_ERROR,
  PlateType,
  RECEIVE_HOMOGEN_RELATED_PLATES,
  RECEIVE_PLATE,
  RECEIVE_PLATES,
  RECEIVE_PLATE_WELL,
  REQUEST_PLATES,
  REQUEST_PLATES_INFO,
  WellType,
} from './types';

export const requestPlates = (): PlateActionsType => ({
  type: REQUEST_PLATES,
});

export const requestPlatesInfo = (): PlateActionsType => ({
  type: REQUEST_PLATES_INFO,
});

export const receivePlates = (payload: {
  page: number;
  total: number;
  per_page: number;
  plates: PlateType[];
  fetchRequestNumber: number;
}): PlateActionsType => {
  return {
    type: RECEIVE_PLATES,
    payload,
  };
};

export const receivePlate = (plate: PlateType): PlateActionsType => {
  return {
    type: RECEIVE_PLATE,
    payload: plate,
  };
};

export const receivePlateWell = (barcode: string, well: WellType): PlateActionsType => {
  return {
    type: RECEIVE_PLATE_WELL,
    payload: {
      plateBarcode: barcode,
      well,
    },
  };
};

export const receiveHomogenRelatedPlates = (
  barcode: string,
  homogenRelated: PlateType[],
): PlateActionsType => {
  return {
    type: RECEIVE_HOMOGEN_RELATED_PLATES,
    payload: {
      plateBarcode: barcode,
      homogenRelated,
    },
  };
};

export const platesRequestError = (payload: { message: string }): PlateActionsType => ({
  type: PLATES_REQUEST_ERROR,
  payload,
});
