import React from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { UserRole } from 'store/user/types';

interface Props {
  role: UserRole;
}

const UserAgreementLinks = ({ role }: Props) => {
  const language = useBroswerLanguage();
  return (
    <div>
      {[UserRole.Agent, UserRole.AgencyAdmin].includes(role) && (
        <>
          <a
            href="https://www.pattern.ag/agent-terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {getString('termsAndConditions', language)}
          </a>
          &nbsp;|&nbsp;
        </>
      )}
      <a
        href="https://www.pattern.ag/end-user-license-agreement"
        target="_blank"
        rel="noopener noreferrer"
      >
        {getString('endUserLicenseAgreement', language)}
      </a>
      &nbsp;|&nbsp;
      <a href="https://www.pattern.ag/privacy-policy" target="_blank" rel="noopener noreferrer">
        {getString('privacyPolicy', language)}
      </a>
    </div>
  );
};

export default UserAgreementLinks;
