export const ENGLISH = 'en';
export const PORTUGUESE = 'pt';

export const ENGLISH_CODES = [
  'en',
  'en-US',
  'en-EG',
  'en-AU',
  'en-GB',
  'en-CA',
  'en-NZ',
  'en-IE',
  'en-ZA',
  'en-JM',
  'en-BZ',
  'en-TT',
];
export const PORTUGUESE_CODES = ['pt-BR', 'pt', 'pt-PT'];

export const HA = 'ha';
export const AC = 'ac';

export const LANGUAGE_OPTIONS = [
  {
    id: 1,
    label: ENGLISH,
    value: ENGLISH,
  },
  {
    id: 1,
    label: PORTUGUESE,
    value: PORTUGUESE,
  },
];
