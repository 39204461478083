export const COMPLETED = 'completed';
export const IN_PROGRESS = 'in_progress';
export const NOT_STARTED = 'not_started';

// translation keys
export const progressDisplayNames = {
  [COMPLETED as string]: 'completed',
  [IN_PROGRESS as string]: 'inProgress',
  [NOT_STARTED as string]: 'notStarted',
};

export const ORDERED = 'ordered';
export const SAMPLED = 'sampled';
export const ARRIVED = 'arrived';

export const PLANNED = 'planned';

export const CONTRACT_METRIC_STATUSES = [PLANNED, SAMPLED, COMPLETED];
