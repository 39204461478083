const API_LAYER_TOKEN = 'koKSnPyigGz9an1U8y5zy91fY8QyUFcs';

type ApiLayerResponse = {
  base: string;
  date: string;
  rates: {
    BRL: string;
  };
  sucess: boolean;
  timestamp: number;
};

export const getConversion = async () => {
  const headers = new Headers();
  headers.append('apikey', API_LAYER_TOKEN);
  const requestOptions = {
    method: 'GET',
    headers,
  };
  const response = await fetch(
    'https://api.apilayer.com/exchangerates_data/latest?symbols=BRL&base=USD',
    requestOptions,
  );
  const processed = (await response.json()) as ApiLayerResponse;
  return Number(processed.rates.BRL);
};
