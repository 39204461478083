import React, { useState } from 'react';
import { Button, Header, Spinner } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { ContactInfoType, User } from 'store/user/types';
import { useDispatch } from 'react-redux';
import showToast from 'actions/toastActions';
import { isNumber } from 'util/numUtils';
import { cleanPhoneNumber } from 'util/validators';
import { updateUserDetails } from 'store/user/requests';
import { receiveSingleUser } from 'store/user/actions';
import styles from './ContactInfo.module.css';
import ContactInfoInputs from './ContactInfoInputs';

interface Props {
  initialUser: User;
}

const ContactInfo = ({ initialUser }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const initialValues = {
    first_name: initialUser?.first_name || '',
    last_name: initialUser?.last_name || '',
    email: initialUser?.email || '',
    business_name: initialUser?.business_name || '',
    cell: initialUser?.cell || '',
  };
  const [contactInfo, setContactInfo] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitContact = async () => {
    try {
      const { first_name, last_name, business_name, cell } = contactInfo;

      const firstName = first_name.trim();
      const lastName = last_name.trim();

      const cleanCell = cleanPhoneNumber(cell);
      if (cleanCell && cleanCell.length < 10) {
        showToast(getString('invalidCellPhoneMsg', language), 'error', 5000);
        return;
      }
      if (!firstName || !lastName) {
        showToast(getString('fillOutRequiredFields', language), 'error', 5000);
        return;
      }
      setIsSubmitting(true);
      const submittedJson: Partial<ContactInfoType> = {
        first_name: firstName,
        last_name: lastName,
        business_name: business_name?.trim() || '',
        cell: cleanCell,
      };
      if (!cleanCell) {
        delete submittedJson.cell;
      }
      if (!business_name) {
        delete submittedJson.business_name;
      }
      const response = await updateUserDetails(submittedJson, initialUser.id);
      dispatch(receiveSingleUser(response));
      showToast(getString('contactInfoUpdated', language));
    } catch (err) {
      showToast(getString('updateUserInfoFailMsg', language), 'error', 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateContactInfo = (key: string, value: string) => {
    setContactInfo({ ...contactInfo, [key]: value });
  };

  const updateContactCell = (value: string) => {
    if (isNumber(Number(value)) && value.length < 13) {
      setContactInfo({ ...contactInfo, cell: value });
    }
  };

  return (
    <div className={styles.Wrapper}>
      <Header underline="thin" size="H2" title={getString('contactInfo', language)}>
        <Button onClick={() => setContactInfo(initialValues)}>
          {getString('cancel', language)}
        </Button>
        <Button primary disabled={isSubmitting} onClick={handleSubmitContact}>
          {isSubmitting ? (
            <Spinner className={styles.Spinner} />
          ) : (
            getString('saveContactInfo', language)
          )}
        </Button>
      </Header>
      <ContactInfoInputs
        user={contactInfo}
        updateContactInfo={updateContactInfo}
        updateContactCell={updateContactCell}
        emailDisabled
      />
    </div>
  );
};

export default ContactInfo;
