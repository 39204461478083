import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { TableRow, TableCol, Spinner } from 'common';

import { FieldType } from 'store/fields/types';
import { getPlanName } from 'util/samplePlan';

import Field from 'common/Field';
import Status from 'common/Status';
import { SamplePlanType } from 'store/samplePlans/types';
import { getLatestCropPlan } from 'util/cropPlans';
import { CORN } from 'constants/variables';
import PlanStatusCell from '../OrderFields/TableCells/PlanStatus';
import OrderPlanButton from './PlanButton';
import { CROP_PROTECTION } from 'constants/results';

import styles from '../OrderFields/TableRow.module.css';

type FieldsTableRowPropsType = {
  field: FieldType;
  hasFetched: Boolean;
  samplePlan: SamplePlanType;
  children?: ReactNode;
  showFieldThumbnail?: boolean;
  className?: string;
};

const FieldsTableRow = ({
  field,
  hasFetched,
  samplePlan,
  children,
  showFieldThumbnail,
  className,
}: FieldsTableRowPropsType) => {
  const fieldProps = field.features[0].properties;
  const cropPlan = getLatestCropPlan(field);

  if (!hasFetched) {
    return (
      <TableRow className={className}>
        <TableCol>
          <Spinner className={styles.Spinner} />
        </TableCol>
      </TableRow>
    );
  }

  if (!samplePlan) {
    return (
      <TableRow className={className}>
        <TableCol>{showFieldThumbnail && <Field field={field} router="results" />}</TableCol>
        <TableCol className={styles.SamplingPlanCell}>N/A</TableCol>
        <TableCol size="x4">
          <OrderPlanButton field={field} primary className={styles.PrimaryAction} />
        </TableCol>
        {children}
      </TableRow>
    );
  }

  return (
    <TableRow className={className}>
      <TableCol>{showFieldThumbnail && <Field field={field} router="results" />}</TableCol>
      <TableCol className={styles.SamplingPlanCell}>
        <Link
          to={`/results/field/${fieldProps.operation_id}/${samplePlan.field_id}/${samplePlan.id}/${CROP_PROTECTION}?crop=${cropPlan.crop || CORN}`}
        >
          {getPlanName(samplePlan)}
        </Link>
        <Status samplingPlan={samplePlan} />
      </TableCol>
      <TableCol dataTestId="plan-status" size="x4">
        <PlanStatusCell samplingPlan={samplePlan} />
      </TableCol>
      {children}
    </TableRow>
  );
};

export default FieldsTableRow;
