import {
  CropGroupingType,
  RECEIVE_OPERATION_CROP_INFO,
  RECEIVE_SALES_FLOW_META,
  REQUEST_OPERATION_CROP_INFO,
  REQUEST_SALES_FLOW_META,
  SALES_FLOW_REQUEST_ERROR,
  SalesFlowActionType,
  SalesFlowMetaType,
  SalesFlowStateType,
} from './types';

export const initialState: SalesFlowStateType = {
  meta: null,
  byOperationId: {},
  hasFailed: false,
  hasFetched: false,
  isFetching: true,
};

const groupingsToList = (grouping: CropGroupingType) =>
  Object.keys(grouping).map((key, index) => {
    return {
      id: index,
      displayName: grouping[key],
      value: key,
    };
  });

const cropGroupingsToList = (cropGroupings: SalesFlowMetaType['corn']) =>
  Object.keys(cropGroupings).reduce((acc, key) => {
    if (key !== 'groupings') {
      return {
        ...acc,
        [key]: groupingsToList(cropGroupings[key]),
      };
    }
    return {
      ...acc,
      groupings: cropGroupings[key],
    };
  }, {});

export const SalesFlowReducer = (state = initialState, action: SalesFlowActionType) => {
  switch (action?.type) {
    case REQUEST_SALES_FLOW_META:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SALES_FLOW_META:
      return {
        ...state,
        meta: {
          corn: cropGroupingsToList(action.payload.corn),
          soybeans: cropGroupingsToList(action.payload.soybeans),
        },
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_OPERATION_CROP_INFO:
      return {
        ...state,
        operationFetching: action.payload.operationId,
      };
    case RECEIVE_OPERATION_CROP_INFO:
      return {
        ...state,
        byOperationId: {
          ...state.byOperationId,
          [action.payload.operationId]: action.payload.cropInfo,
        },
      };
    case SALES_FLOW_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
