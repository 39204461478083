import {
  RECEIVE_SAMPLES,
  REQUEST_SAMPLES,
  SamplesActionType,
  SampleState,
  SAMPLE_REQUEST_ERROR,
} from './types';

export const initialState: SampleState = {
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
  samples: {},
};

export const SampleReducer = (state = initialState, action: SamplesActionType) => {
  switch (action.type) {
    case RECEIVE_SAMPLES:
      return {
        ...state,
        hasFetchedList: state.hasFetchedList.concat(action.payload.id),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
        samples: {
          ...state.samples,
          [action.payload.id]: action.payload.samples,
        },
      };
    case REQUEST_SAMPLES:
      return {
        ...state,
        isFetchingList: state.isFetchingList.concat(action.payload.id),
      };
    case SAMPLE_REQUEST_ERROR:
      return {
        ...state,
        hasFailedList: state.hasFailedList.concat(action.payload.id),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
      };
    default:
      return state;
  }
};
