import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Group, Stack, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Header } from 'common';
import { creationPlateTypes, QPCR, QPCR_384, QPCR_96, HOMOGENIZATION } from 'constants/plates';
import { createPlate } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { cleanupPlateForm, isPlateFormValid } from 'util/plates';

import { PlateTypeSelector } from './PlateForms/FormInputs/PlateTypeSelector';
import { NewBarcodeInput } from './PlateForms/FormInputs/NewBarcodeInput';
import { PlateFormContainer } from './PlateForms/Container';
import styles from './Container.module.css';

const PlatesCreate = () => {
  const language = useBroswerLanguage();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { plateType } = useParams<{ plateType: string }>();
  const [plate, setPlate] = useState<Partial<PlateType>>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Preserve location state for source barcode
    if (plateType === QPCR) {
      navigate(`/lab/plates/create/${QPCR_384}`, {
        state: location.state,
      });
    } else if (!plateType || !creationPlateTypes.includes(plateType)) {
      navigate(`/lab/plates/create/${HOMOGENIZATION}`, {
        state: location.state,
      });
    }
  }, [plateType]);

  useEffect(() => {
    if (plate && plateType) {
      setIsFormValid(isPlateFormValid(plate, plateType));
    }
  }, [plate, plateType]);

  const onSubmit = async () => {
    if (isFormValid && plate.plate_type) {
      try {
        const submitType = [QPCR_384, QPCR_96].includes(plate.plate_type) ? QPCR : plate.plate_type;
        const cleanup = cleanupPlateForm(plate);
        await dispatch(
          createPlate({
            ...cleanup,
            plate_type: submitType,
          }),
        );
        navigate(`/lab/plates/details/${plate.barcode}`);
      } catch (e) {
        setErrorMessage(e.message);
      }
    }
  };

  const handleOnValueChange = (
    attributeName: string,
    newValue: string | string[] | { [key: string]: any },
    clearForm?: boolean,
  ) => {
    setPlate(
      (plateForm) =>
        (clearForm
          ? { barcode: plateForm.barcode, [attributeName]: newValue }
          : { ...plateForm, [attributeName]: newValue }) as PlateType,
    );
  };

  return (
    <Stack>
      <Header title={getString('newPlate', language)} />
      <Stack gap="2rem">
        <Group wrap="nowrap">
          <PlateTypeSelector
            onValueChange={handleOnValueChange}
            plateType={plateType}
            miw="12.5rem"
            maw="20rem"
            w="40%"
          />
          <NewBarcodeInput
            plate={plate}
            onValueChange={handleOnValueChange}
            miw="12.5rem"
            maw="20rem"
            w="40%"
          />
        </Group>
        <PlateFormContainer plate={plate} onValueChange={handleOnValueChange} />
        <Stack>
          <Text>{`(*) ${getString('requiredField', language)}`}</Text>
          <Group>
            <Button
              data-test-id="create-plate-button"
              disabled={!isFormValid}
              onClick={() => onSubmit()}
            >
              {getString('createPlate', language)}
            </Button>
          </Group>
          {errorMessage && <Text className={styles.Error}>{errorMessage}</Text>}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PlatesCreate;
