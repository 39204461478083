import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  userIsSuperAdmin,
  userIsAdmin,
  userIsAgent,
  userIsAgencyAdmin,
  userIsOperator,
} from 'store/user/selectors';
import { RootState } from 'store';

type RoleBasedAccessPropsType = {
  children: ReactNode;
  roleSelector: (state: RootState) => boolean;
};
const RoleBasedAccess = ({ children, roleSelector }: RoleBasedAccessPropsType) => {
  const { hasAccess } = useSelector((state: RootState) => ({
    hasAccess: roleSelector(state),
  }));
  return hasAccess ? <>{children}</> : null;
};

export default RoleBasedAccess;

type AccessPropsType = {
  children: ReactNode;
};
export const AdminAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) => userIsSuperAdmin(state) || userIsAdmin(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};

export const SuperAdminAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) => userIsSuperAdmin(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};

export const AgencyAdminAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) =>
    userIsSuperAdmin(state) || userIsAdmin(state) || userIsAgencyAdmin(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};

export const AdminAgentAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) =>
    userIsSuperAdmin(state) || userIsAdmin(state) || userIsAgencyAdmin(state) || userIsAgent(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};

export const OperationManagerAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) =>
    userIsSuperAdmin(state) ||
    userIsAdmin(state) ||
    userIsAgencyAdmin(state) ||
    userIsAgent(state) ||
    userIsOperator(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};

export const AgentOnlyAccess = ({ children }: AccessPropsType) => {
  const selector = (state: RootState) => userIsAgencyAdmin(state) || userIsAgent(state);
  return <RoleBasedAccess roleSelector={selector}>{children}</RoleBasedAccess>;
};
