import React from 'react';
import { Group } from '@mantine/core';
import { PlateType } from 'store/plates/types';

import { QuadrantBarcodeInput } from './BarcodeInput';
import { IndexSetSelector } from './IndexSetSelector';
import { TargetSelector } from './TargetSelector';

type QuadrantInputPropsType = {
  plate: Partial<PlateType>;
  quadrant?: number;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  indexSet?: boolean;
  target?: boolean;
};

export const QuadrantInput = ({
  plate,
  quadrant = 1,
  onValueChange,
  indexSet,
  target,
}: QuadrantInputPropsType) => {
  return (
    <Group wrap="nowrap">
      <QuadrantBarcodeInput
        plate={plate}
        onValueChange={onValueChange}
        quadrant={quadrant}
        miw="12.5rem"
        maw="20rem"
        w="40%"
      />
      {indexSet && (
        <IndexSetSelector
          plate={plate}
          onValueChange={onValueChange}
          quadrant={quadrant}
          miw="12.5rem"
          maw="20rem"
          w="40%"
        />
      )}
      {target && (
        <TargetSelector
          plate={plate}
          onValueChange={onValueChange}
          quadrant={quadrant}
          miw="12.5rem"
          maw="20rem"
          w="40%"
        />
      )}
    </Group>
  );
};
