import { AppThunk } from 'store';
import { getSamplingPrices } from './requests';
import { requestSamplePrices, receiveSamplePrices, samplePricesRequestError } from './actions';

const getSamplePrices = (): AppThunk => async (dispatch) => {
  dispatch(requestSamplePrices());
  try {
    const response = await getSamplingPrices();
    dispatch(receiveSamplePrices({ samplePrices: response }));
  } catch (e) {
    dispatch(samplePricesRequestError({ message: e.message }));
  }
};

export default getSamplePrices;
