import { handleJsonResponse, requestGet, SERVICE_URL, requestPostFile } from 'util/request';

export const requestGetIndexSets = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/index_sets/`));

export const requestGetIndexSetsNames = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/index_sets_names/`));

export const requestPostIndexSet = async (file: File) =>
  handleJsonResponse(await requestPostFile(`${SERVICE_URL}/index_set/`, { file }));
