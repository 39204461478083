import React from 'react';

import { Dialog } from 'common';
import { AdjustmentsType } from 'store/payments/types';
import { capitalizeEveryWord } from 'util/stringUtils';

import AdjustmentCreation from './AdjustmentCreation';
import styles from './AdjustmentDialog.module.css';

type AdjustmentDialogPropsType = {
  userId: number;
  firstName: string;
  lastName: string;
  onSubmit: (data: AdjustmentsType) => void;
  onClose: () => void;
};

const AdjustmentDialog = ({
  userId,
  firstName,
  lastName,
  onSubmit,
  onClose,
}: AdjustmentDialogPropsType) => {
  return (
    <Dialog className={styles.AdjustmentDialog} onClose={onClose}>
      <div className={styles.InternalContainer}>
        <AdjustmentCreation
          userId={userId}
          userName={capitalizeEveryWord(`${firstName} ${lastName}`)}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </div>
    </Dialog>
  );
};

export default AdjustmentDialog;
