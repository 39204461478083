import {
  CommercialTerritoryType,
  RECEIVE_ALL_TERRITORIES,
  RECEIVE_SINGLE_TERRITORY,
  REQUEST_TERRITORIES,
  TERRITORIES_REQUEST_ERROR,
  TerritoryActionType,
} from './types';

export const territoryRequestError = (id?: number): TerritoryActionType => {
  return {
    type: TERRITORIES_REQUEST_ERROR,
    payload: {
      id,
    },
  };
};

export const requestTerritories = (id?: number): TerritoryActionType => {
  return {
    type: REQUEST_TERRITORIES,
    payload: {
      id,
    },
  };
};

export const receiveAllTerritories = (
  territories: CommercialTerritoryType[],
): TerritoryActionType => ({
  type: RECEIVE_ALL_TERRITORIES,
  payload: {
    territories,
  },
});

export const receiveSingleTerritory = (
  id: number,
  territory: CommercialTerritoryType,
): TerritoryActionType => ({
  type: RECEIVE_SINGLE_TERRITORY,
  payload: { id, territory },
});
