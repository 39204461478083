import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { generateWellsIndex } from 'util/plates';
import { PlateType, WellType } from 'store/plates/types';
import createEmptyWell from 'constants/emptyWell';

import { WellDialogContainer as WellDialog } from '../Dialog/Container';
import { WellGrid as Grid } from './Grid';

type GridContainerPropsType = {
  plate: PlateType;
  quadrant?: number;
};

export const GridContainer = ({ plate, quadrant }: GridContainerPropsType) => {
  const [selectedWell, setSelectedWell] = useState<WellType>();
  const [showDialog, setShowDialog] = useState(false);
  const { wells } = useSelector((state: RootState) => ({
    wells: state.plates.byBarcode[plate.barcode]?.wells,
  }));

  const wellLookupMap = generateWellsIndex(wells, quadrant);

  const handleSelectWell = (gridCell: { row: string; column: string }) => {
    setSelectedWell(
      wellLookupMap?.[gridCell.row]?.[gridCell.column] ||
        createEmptyWell(gridCell.row, gridCell.column, plate.barcode, quadrant),
    );
    setShowDialog(true);
  };

  return (
    <div>
      <Grid wells={wellLookupMap} plate={plate} onWellClick={handleSelectWell} />
      {showDialog && selectedWell && (
        <WellDialog well={selectedWell} plate={plate} onClose={() => setShowDialog(false)} />
      )}
    </div>
  );
};
