import React, { useState, useRef, useCallback } from 'react';
import { AnalyticType, AnalyticImageType } from 'store/analytics/types';
import {
  analyticUploadImage,
  uploadAnalyticImageToGCP,
  markAnalyticImageAsUploaded,
} from 'store/analytics/requests';
import { Text, Input, Button, Group } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import AnalyticImages from './AnalyticImagesTable';

type AnalyticImageryPropsType = {
  analytic: AnalyticType;
  setAttribute: (attributeName: string, attributeValue: any) => void;
  onError: (errorMessage: string) => void;
  onSuccess: (message: string) => void;
  isSaving: boolean;
};

const AnalyticImagery = ({
  analytic,
  setAttribute,
  onError,
  onSuccess,
  isSaving,
}: AnalyticImageryPropsType) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const language = useBroswerLanguage();
  const [analyticImage, setAnalyticImage] = useState<Partial<AnalyticImageType>>({
    url: '',
    source: '',
    alt_text: '',
  });
  const [isUploading, toggleIsUploading] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);
  const [request, setRequest] = useState<XMLHttpRequest>();

  const uploadImageFile = useCallback(
    async (file: File) => {
      try {
        const analyticImageResponse = await analyticUploadImage(
          analytic,
          analyticImage as AnalyticImageType,
          file,
        );
        if (analyticImageResponse.upload_url && analyticImageResponse.id) {
          const handleCompleteUpload = async () => {
            try {
              const updatedAnalyticImage = await markAnalyticImageAsUploaded(analyticImageResponse);
              onSuccess('Image Uploaded');
              toggleIsUploading(false);
              setAnalyticImage({
                url: '',
                source: '',
                alt_text: '',
              });
              setPercentComplete(0);
              setAttribute('images', [...analytic.images, updatedAnalyticImage]);
            } catch (e) {
              onError('Error Marking Images as Uploaded.');
            }
          };
          const xhr = uploadAnalyticImageToGCP({
            analyticImage: analyticImageResponse,
            file,
            onCompleted: handleCompleteUpload,
            onError: () => onError('Error uploading image to server'),
            onProgressUpdate: (percent: number) => setPercentComplete(percent),
          });
          setRequest(xhr);
        }
      } catch (e) {
        onError(`Image Upload Error ${e.message}`);
        return null;
      }
    },
    [onError, onSuccess, setPercentComplete, analytic, analyticImage, setAttribute],
  );

  const handleImageUpload = useCallback(() => {
    const input = inputRef.current;
    const file = input?.files && input?.files[0];
    if (file) {
      uploadImageFile(file);
    }
  }, [uploadImageFile, inputRef]);

  const handleCancelUpload = useCallback(() => {
    if (request) {
      request.abort();
    }
  }, [request]);

  const createHandleUpdateAnalyticImage =
    (attr: keyof AnalyticImageType) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setAnalyticImage({
        ...analyticImage,
        [attr]: e.currentTarget.value,
      });
    };

  const handleTableDataUpdate = (analyticImages: AnalyticImageType[]) => {
    setAttribute('images', analyticImages);
    onSuccess('Image updated');
  };

  return (
    <>
      <Text size="lg" fw={500}>
        {getString('uploadAnalyticImagery', language)}
      </Text>
      <Group align="flex-end">
        <Input.Wrapper label={getString('imageLabel', language)}>
          <Input
            disabled={isSaving}
            onChange={createHandleUpdateAnalyticImage('alt_text')}
            value={analyticImage.alt_text}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('source', language)}>
          <Input
            disabled={isSaving}
            onChange={createHandleUpdateAnalyticImage('source')}
            value={analyticImage.source}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('image', language)}>
          <input disabled={isSaving} type="file" ref={inputRef} />
        </Input.Wrapper>
        {isUploading && (
          <Button onClick={handleCancelUpload}>{`Cancel (${percentComplete}% completed)`}</Button>
        )}
        {!isUploading && (
          <Button loading={isSaving} onClick={handleImageUpload}>
            {getString('upload', language)}
          </Button>
        )}
      </Group>

      <AnalyticImages
        images={analytic?.images}
        onUpdate={handleTableDataUpdate}
        onError={onError}
      />
    </>
  );
};

export default AnalyticImagery;
