import { getSamplePlans, requestSamplePlanTrackingPage } from 'store/samplePlans/requests';
import {
  samplePlanRequestError,
  requestSamplePlan,
  receiveFieldPlans,
  receiveSamplePlansTracking,
  requestSamplePlansTracking,
  samplePlanTrackingRequestError,
} from 'store/samplePlans/actions';
import { AppThunk } from 'store';
import { polygon } from '@turf/helpers';

export const getFieldSamplePlans =
  (fieldId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestSamplePlan(fieldId));
    try {
      const response = await getSamplePlans(fieldId);
      return dispatch(receiveFieldPlans(fieldId, response.sampling_plans));
    } catch (e) {
      return dispatch(samplePlanRequestError(fieldId));
    }
  };

export const getSamplePlansTrackingPage =
  (
    page: number = 1,
    showAllAccounts: boolean,
    operation_id?: number,
    agency_id?: number,
    assigned_to_id?: number,
    statuses?: string[],
    assignment?: string,
    readiness?: string,
    alerts?: string[],
    pro_selection?: string,
    completeness?: string[],
    is_sampler_only?: boolean,
    countryIndex?: string,
    currentMapBounds?: number[][] | null | undefined,
    sortingCol?: { col: string | undefined; asc: boolean },
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestSamplePlansTracking());
      const response = await requestSamplePlanTrackingPage(
        page,
        showAllAccounts,
        operation_id,
        agency_id,
        assigned_to_id,
        statuses ? JSON.stringify(statuses) : undefined,
        assignment || undefined,
        readiness || undefined,
        alerts ? JSON.stringify(alerts) : undefined,
        pro_selection || undefined,
        completeness ? JSON.stringify(completeness) : undefined,
        is_sampler_only || false,
        countryIndex,
        currentMapBounds ? JSON.stringify(polygon([currentMapBounds]).geometry) : undefined,
        sortingCol?.col ? sortingCol.col : undefined,
        sortingCol?.col ? sortingCol.asc : undefined,
      );
      dispatch(receiveSamplePlansTracking(response, page));
    } catch (error) {
      dispatch(samplePlanTrackingRequestError());
    }
  };
