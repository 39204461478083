import React from 'react';
import cornCollage from 'images/sales/cornCollage.png';
import soybeanCollage from 'images/sales/soybeanCollage.png';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import SlideStyles from '../../Shared.module.css';
import Styles from './BoostYield.module.css';

const BoostYield = () => {
  const language = useBroswerLanguage();

  return (
    <div>
      <div
        className={SlideStyles.Header}
        dangerouslySetInnerHTML={{
          __html: getString('patternReportAnalyzes', language),
        }}
      />
      <div className={SlideStyles.Images}>
        <img alt={getString('overview', language)} className={Styles.Image} src={cornCollage} />
        <div className={Styles.DottedLine} />
        <img alt={getString('overview', language)} className={Styles.Image} src={soybeanCollage} />
      </div>
    </div>
  );
};

export default BoostYield;
