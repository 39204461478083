import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Header.module.css';

type HeaderPropsType = {
  title: ReactNode | string;
  size?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
  underline?: 'thin' | 'thick' | 'none';
  className?: string;
  children?: ReactNode;
};

export const Header = ({
  className,
  title,
  size = 'H1',
  underline = 'thick',
  children,
}: HeaderPropsType) => (
  <div
    className={classNames(styles.Wrapper, className, {
      [styles.Thick]: underline === 'thick',
      [styles.Thin]: underline === 'thin',
      [styles.None]: underline === 'none',
    })}
  >
    <div data-test-id="field-name" className={classNames(styles.Title, styles[size])}>
      {title}
    </div>
    <div className={styles.Toolbar}>{children}</div>
  </div>
);
