import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.css';

type CheckboxPropsType = {
  className?: string;
  checked: boolean;
  onChange: () => void;
  dark?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  disabledIsClickable?: boolean;
};

export const Checkbox = ({
  className,
  checked,
  onChange,
  dark,
  disabled,
  dataTestId,
  disabledIsClickable,
}: CheckboxPropsType) => {
  const unclickable = disabled && !disabledIsClickable;
  return (
    <span className={classNames(styles.Checkbox, className)}>
      <label
        className={classNames(styles.CheckboxContent, {
          [styles.Disabled]: disabled,
          [styles.Unclickable]: unclickable,
        })}
      >
        <input
          type="checkbox"
          data-test-id={dataTestId}
          checked={checked}
          onChange={onChange}
          disabled={unclickable}
        />
        <span
          className={classNames(styles.CheckboxButton, dark ? styles.Dark : styles.Light)}
          data-test-id={dataTestId}
        />
      </label>
    </span>
  );
};
