import React, { useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { User } from 'store/user/types';
import { Agency } from 'store/agencies/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import AddBillingResponsibilityDialog from './AddBillingResponsibilityDialog';
import RemoveBillingResponsibilityDialog from './RemoveBillingResponsibilityDialog';
import BillingUserDetails from './BillingUserDetails';
import styles from './Container.module.css';

interface Props {
  billingUserId: number;
  users: User[];
  agencies: Agency[];
}
const BillingDetails = ({ billingUserId, users, agencies }: Props) => {
  const language = useBroswerLanguage();
  const [showAddBillingDialog, setShowAddBillingDialog] = useState(false);
  const [showRemoveBillingDialog, setShowRemoveBillingDialog] = useState(false);
  const [billingParty, setBillingParty] = useState<Partial<Agency>>();

  useEffect(() => {
    if (billingUserId) {
      const agency = agencies.find((agenc) => agenc.primary_user.id === billingUserId);
      const non_agency_user = users.find((user) => user.id === billingUserId);
      setBillingParty(agency?.primary_user ? agency : { primary_user: non_agency_user });
    }
  }, [billingUserId, users, agencies]);

  return (
    <>
      <div className={styles.TitleContainer}>
        <h2 className={styles.Title}>{getString('billingResponsibility', language)}</h2>
        {billingUserId && (
          <div>
            <Button
              color="darkRed"
              data-test-id="remove-billing-responsibility"
              onClick={() => setShowRemoveBillingDialog(true)}
            >
              {getString('removeBillingResponsibility', language)}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.Divider} />

      {billingUserId ? (
        <>
          {billingParty?.primary_user && !billingParty.primary_user.eula_accepted && (
            <div style={{ marginBottom: '3rem' }}>{getString('assignedBillingMsg', language)}</div>
          )}
          {billingParty?.primary_user && (
            <BillingUserDetails user={billingParty.primary_user} agencyName={billingParty?.name} />
          )}
        </>
      ) : (
        <>
          <div className={styles.Text}>{getString('noUserWithBillingResMsg', language)}</div>
          <Button
            data-test-id="add-billing-responsibility"
            style={{ marginTop: '2.5rem' }}
            onClick={() => setShowAddBillingDialog(true)}
          >
            {getString('addingBillingResponsibility', language)}
          </Button>
        </>
      )}
      {showRemoveBillingDialog && (
        <RemoveBillingResponsibilityDialog onClose={() => setShowRemoveBillingDialog(false)} />
      )}
      {showAddBillingDialog && (
        <AddBillingResponsibilityDialog
          onClose={() => setShowAddBillingDialog(false)}
          users={users}
          agencies={agencies}
          open={showAddBillingDialog}
        />
      )}
    </>
  );
};

export default BillingDetails;
