import { AppThunk } from 'store';
import {
  indexSetRequestError,
  receiveIndexSets,
  receiveIndexSetsNames,
  requestIndexSets,
} from './actions';
import { requestGetIndexSets, requestGetIndexSetsNames } from './requests';
import { IndexSetType, RECEIVE_INDEX_SET } from './types';

export const getIndexSets = (): AppThunk => async (dispatch) => {
  dispatch(requestIndexSets());
  try {
    const indexSets = await requestGetIndexSets();
    dispatch(receiveIndexSets(indexSets));
  } catch (e) {
    dispatch(indexSetRequestError({ message: e.message }));
  }
};

export const getIndexSetsNames = (): AppThunk => async (dispatch) => {
  dispatch(requestIndexSets());
  try {
    const indexSets = await requestGetIndexSetsNames();
    dispatch(receiveIndexSetsNames(indexSets));
  } catch (e) {
    dispatch(indexSetRequestError({ message: e.message }));
  }
};

export const addNewIndexSet = (indexSet: IndexSetType[]) => ({
  type: RECEIVE_INDEX_SET,
  payload: indexSet,
});
