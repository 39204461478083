import { groupBy } from 'util/collectionUtils';
import {
  IndexSetsActionType,
  IndexSetsStateType,
  INDEX_SET_REQUEST_ERROR,
  RECEIVE_INDEX_SET,
  RECEIVE_INDEX_SETS,
  RECEIVE_INDEX_SETS_NAMES,
  REQUEST_INDEX_SETS,
} from './types';

const initialState: IndexSetsStateType = {
  isFetching: false,
  hasFetched: false,
  hasFetchedNames: false,
  hasFailed: false,
  sets: [],
  setsByName: {},
  names: [],
};

const setParser = (set: any) => {
  return {
    ...set,
    forwardPrimer: set.f_prm,
    forwardIndex: set.f_index,
    reversePrimer: set.r_prm,
    reverseIndex: set.r_index,
  };
};

const indexSetReducer = (state = initialState, action: IndexSetsActionType) => {
  switch (action.type) {
    case REQUEST_INDEX_SETS:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        hasFailed: false,
      };
    case RECEIVE_INDEX_SETS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        // TODO: Remove weird _ insertion in key name
        // @ts-ignore
        sets: action.payload.index_Sets.map(setParser),
        // @ts-ignore
        setsByName: groupBy(action.payload.index_Sets.map(setParser), 'name'),
      };
    case RECEIVE_INDEX_SET:
      return {
        ...state,
        sets: [...state.sets, action.payload].map(setParser),
        setsByName: {
          ...state.setsByName,
          [action.payload.name]: [action.payload].map(setParser),
        },
      };
    case RECEIVE_INDEX_SETS_NAMES:
      return {
        ...state,
        hasFetchedNames: true,
        isFetching: false,
        names: action.payload.sort((a, b) => a.localeCompare(b, undefined, { numeric: true })),
      };
    case INDEX_SET_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};

export default indexSetReducer;
