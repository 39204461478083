import { handleJsonResponse, requestGet, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';

export const requestGetOrderHistory = async (year?: number, operationName?: string | null) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/order_history/?${queryStringify({
        year: year,
        name: operationName || undefined,
      })}`,
    ),
  );
