import {
  Button,
  Center,
  Group,
  Image,
  Loader,
  NumberInput,
  Paper,
  Popover,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { Header, TabbedController } from 'common';
import {
  ANALYTIC_DISPLAY_CATEGORIES,
  COMPACTION,
  CROP_PROTECTION,
  NUTRIENTS,
  RX,
} from 'constants/results';
import GeoJSON from 'geojson';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { SampleType } from 'store/fields/types';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import getSamplesForField from 'store/samples/thunks';
import { getString } from 'strings/translation';
import { getAnalyticsForCategory } from 'util/chartUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  analyticPreferenceList,
  getAnalysisViewOptions,
  getCategoryFromParams,
  getSubCategoryAnalyticsV2,
} from 'util/results';
import {
  filterSamplesWithPlan,
  getFirstSampleReceivedDate,
  getLastSampleProcessedDate,
} from 'util/sample';
import { getPlanName, getSortedPlansWithResults, mergePlanAnalytics } from 'util/samplePlan';

import { CORN } from 'constants/variables';
import { AnalyticType } from 'store/analytics/types';
import { putPostCropTargetYield } from 'store/cropPlans/thunks';
import { getSamplePlanRecommendations } from 'store/recommendations/thunks';
import { getLatestCropPlan } from 'util/cropPlans';
import {
  filterAnalyticsPerTabByCrop,
  getCropImage,
  getOtherCrops,
} from 'util/overviewResultsDisplay';
import { FiveNinetyContent } from '../590/FiveNinetyContent';
import { FieldSummaryRouteParams } from '../common/types';
import styles from './Container.module.css';
import MapbookList from './MapbookList';
import PrescriptionList from './Prescriptions/PrescriptionList';
import { useAvailableInferenceLayers } from 'util/hooks/compactionMap';
import { getTillageLayerExists } from 'util/proMaps';
import TillageTeaserMessage from './Compaction/TillageTeaserMessage';
import TillMapperOrderStatus from './Compaction/TillMapperOrderStatus';
import { BR } from 'constants/countries';

type Props = {
  tmpShouldDoCompactionStuff?: boolean;
};

const FieldSummaryContainer = ({ tmpShouldDoCompactionStuff }: Props) => {
  const { operationId, fieldId, analysis, planId } = useParams<FieldSummaryRouteParams>();
  const { search } = useLocation();
  const cropFromUrl = new URLSearchParams(search).get('crop') || CORN;
  const navigate = useNavigate();
  const numericOperationId = Number(operationId);
  const numericFieldId = Number(fieldId);
  const numericPlanId = Number(planId);
  const browserLang = useBroswerLanguage();
  const language = new URLSearchParams(search).get('language') || browserLang;
  const dispatch = useDispatch();

  const {
    analytics,
    analyticCategories,
    operation,
    fieldGeometry,
    samples,
    hasFetchedSamples,
    hasFetchedFieldGeometry,
    recommendations,
    analyticSortOrder,
    user,
  } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericOperationId],
    fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
    analytics: state.analytics.analytics,
    analyticCategories: state.analytics.analyticCategories,
    samples: state.samples.samples[numericFieldId],
    hasFetchedSamples: state.samples.hasFetchedList.includes(numericFieldId),
    hasFetchedFieldGeometry: state.fieldGeometry.hasFetchedList.includes(numericFieldId),
    recommendations: state.recommendations.byPlanId[numericPlanId],
    analyticSortOrder: state.analytics.analyticSortOrder,
    user: state.user,
  }));

  const preferredAnalytics = analyticPreferenceList(operation, analytics);
  const cropPlan = getLatestCropPlan(fieldGeometry);

  const [targetYield, setTargetYield] = useState<number | string>('');

  useEffect(() => {
    if (numericPlanId) {
      dispatch(getSamplePlanRecommendations(numericPlanId));
    }
  }, [numericPlanId]);

  useEffect(() => {
    if (cropPlan) {
      setTargetYield(cropPlan.target_yield_per_acre);
    }
  }, [cropPlan]);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
      dispatch(getFieldGeometry(numericFieldId));
    } else {
      dispatch(setActiveOperation(operation.id));
    }
  }, [operation, numericFieldId]);

  const sortedPlans = fieldGeometry?.features[0].properties
    ? getSortedPlansWithResults(fieldGeometry.features[0].properties, analyticCategories)
    : [];

  const selectedPlan = sortedPlans.find((plan) => plan.id === Number(planId));
  const secondaryPlan = sortedPlans.find(
    (plan) => plan.crop_year === selectedPlan?.crop_year && plan.id !== selectedPlan?.id,
  );

  const samplePlanOptions = sortedPlans.map((plan, idx) => ({
    id: idx,
    displayName: getPlanName(plan),
    label: getPlanName(plan),
    value: String(plan.id),
  }));

  const showAnotherPlan = (optionVal: string) =>
    navigate(
      `/results/field/${operationId}/${fieldId}/${optionVal}/${analysis}?crop=${cropFromUrl}&language=${language}`,
    );

  useEffect(() => {
    if (samplePlanOptions.length && !selectedPlan) {
      showAnotherPlan(samplePlanOptions[0].value);
    }
  }, [samplePlanOptions, selectedPlan, showAnotherPlan]);

  useEffect(() => {
    dispatch(getSamplesForField(numericFieldId, analytics));
  }, [numericFieldId, analytics]);

  const availableInferenceLayers = useAvailableInferenceLayers(selectedPlan?.id);
  const category = getCategoryFromParams(analysis);

  const mergedAnalytics = mergePlanAnalytics(selectedPlan, secondaryPlan);

  const analyticsInField = selectedPlan
    ? Object.keys(mergedAnalytics).flatMap((cat) => Object.keys(mergedAnalytics[cat]))
    : [];

  const isRx = analysis === RX;

  const { analyticsPerTab: analyticsToShow } = useMemo(
    () =>
      getSubCategoryAnalyticsV2(
        preferredAnalytics,
        [fieldGeometry],
        analyticSortOrder?.[analysis || CROP_PROTECTION] || {},
      ),
    [category, analysis, analyticCategories, preferredAnalytics, fieldGeometry, analyticSortOrder],
  );

  const analyticsPerTab = analyticsToShow.filter((analytic: AnalyticType) =>
    analyticsInField.includes(String(analytic.id)),
  );

  const fieldHasResults = getAnalyticsForCategory(
    mergedAnalytics,
    analyticsPerTab.map((analytic) => Number(analytic.id)),
  ).length;

  const handlePrint = () => {
    // wait for state change to propagate
    setTimeout(() => {
      window.print();
    }, 600);

    window.onafterprint = () => {
      window.onafterprint = null;
    };
  };

  const setCropTypeOnPlan = (crop: string, target?: string | number) => {
    dispatch(
      putPostCropTargetYield(language, numericFieldId, crop, cropPlan?.id, target, () =>
        navigate(
          `/results/field/${operation.id}/${fieldId}/${planId}/${analysis}?crop=${crop}&language=${language}`,
        ),
      ),
    );
  };

  const hasNoResults = (selectedPlan && !fieldHasResults) || !Number(planId);
  const isLoading = !hasFetchedFieldGeometry || !selectedPlan || !hasFetchedSamples;

  const maplistResults = (() => {
    if (isLoading) {
      return (
        <Center h={200}>
          <Loader />
        </Center>
      );
    }

    const filteredAnalytics = filterAnalyticsPerTabByCrop(
      analyticsPerTab,
      mergedAnalytics,
      analyticSortOrder[analysis || CROP_PROTECTION][cropFromUrl],
    );

    if (hasNoResults || !filteredAnalytics.length) {
      return (
        <Center h={200} data-test-id="noResultsExistForField">
          <Text size="xl" fw={600}>
            {getString('noResultsExistForField', language)}
          </Text>
        </Center>
      );
    }

    const analyticsInSelected = getAnalyticsForCategory(
      selectedPlan?.analytics,
      analyticsPerTab.map((analytic) => Number(analytic.id)),
    );

    const planWithResults = analyticsInSelected.length ? selectedPlan : secondaryPlan;

    if (!planWithResults) {
      return null;
    }

    const filteredSamplesWithPlan = filterSamplesWithPlan(
      samples,
      planWithResults,
    ) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[];

    if (isRx) {
      return (
        <PrescriptionList
          analytics={filteredAnalytics}
          fieldGeometry={fieldGeometry}
          samplingPlan={planWithResults}
        />
      );
    }

    const shouldShow590 = selectedPlan?.is_590_analysis && analysis === NUTRIENTS;

    return (
      <MapbookList
        fiveNinetyContent={
          shouldShow590 ? (
            <FiveNinetyContent
              samples={filteredSamplesWithPlan}
              metadataProps={{
                operationId: operation.id,
                operationName: operation.name,
                fieldName: fieldGeometry.features[0].properties.name,
                currentUserFullName: `${user.currentUser?.first_name} ${user.currentUser?.last_name}`,
                samplingPlanId: selectedPlan.id,
                receivedDateStr: getFirstSampleReceivedDate(samples),
                completedDateStr: getLastSampleProcessedDate(samples),
                billingAddress: user.currentUser?.billing_addresses[0],
                agencyName: user.currentUser?.agencies[0]?.name,
              }}
            />
          ) : null
        }
        samplingPlan={planWithResults}
        filteredSamples={filteredSamplesWithPlan}
        fieldGeometry={fieldGeometry}
        analyticsPerTab={filteredAnalytics}
        recommendations={recommendations}
        language={language}
        isCompaction={analysis === COMPACTION}
      />
    );
  })();

  const isBrazil = fieldGeometry?.features[0].properties.country_code === BR;

  const analysisViewOptions = getAnalysisViewOptions(
    language,
    ANALYTIC_DISPLAY_CATEGORIES,
    false,
    isBrazil,
  );

  const getInnerContent = () => {
    if (analysis === 'compaction' && selectedPlan && tmpShouldDoCompactionStuff) {
      const planHasTillage = selectedPlan.is_till_rx;
      const tillageLayerExists = getTillageLayerExists(availableInferenceLayers);

      if (!tillageLayerExists && planHasTillage) {
        return <TillMapperOrderStatus />;
      }

      if (!tillageLayerExists && !planHasTillage) {
        return <TillageTeaserMessage language={language} callToAction="order" />;
      }

      if (tillageLayerExists && !planHasTillage) {
        return (
          <TillageTeaserMessage
            language={language}
            callToAction="activate"
            activateModalProps={{
              acres: fieldGeometry?.features[0].properties.acreage,
            }}
          />
        );
      }
    }

    return (
      <Group className={styles.HideForPrint}>
        <Text fw={700}>{getString('plannedCrop', language)}:</Text>
        <Popover width="auto" trapFocus position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Paper shadow="sm" withBorder className={styles.CropImage}>
              <Center h="3rem">
                <Image h="2rem" w="auto" src={getCropImage(cropPlan?.crop)} />
              </Center>
            </Paper>
          </Popover.Target>
          <Popover.Dropdown>
            <Group gap="sm">
              {getOtherCrops(cropPlan?.crop).map((crop) => (
                <Image
                  key={crop}
                  h="2rem"
                  w="auto"
                  src={getCropImage(crop)}
                  className={styles.CropImgBox}
                  onClick={() => setCropTypeOnPlan(crop)}
                />
              ))}
            </Group>
          </Popover.Dropdown>
        </Popover>
        <Text fw={700}>{getString('targetYield', language)}:</Text>
        <NumberInput
          hideControls
          value={targetYield}
          onBlur={() => setCropTypeOnPlan(cropPlan?.crop || CORN, targetYield)}
          onChange={setTargetYield}
          size="xs"
        />
      </Group>
    );
  };

  return (
    <Stack>
      <Group>
        <Button
          size="compact-sm"
          variant="outline"
          onClick={() => {
            navigate(`/results/operation/${operation.id}/${analysis}`);
          }}
          className={styles.HideForPrint}
        >
          {getString('backToResults', language)}
        </Button>
      </Group>
      <Stack>
        <Header title={`${operation?.name} | ${fieldGeometry?.features[0].properties.name}`}>
          {samplePlanOptions.length > 0 && (
            <Select
              value={planId}
              onChange={(val) => val && showAnotherPlan(val)}
              data={samplePlanOptions}
            />
          )}
          <Button variant="outline" className={styles.HideForPrint} onClick={handlePrint}>
            {getString('print', language)}
          </Button>
        </Header>
        <TabbedController
          className={styles.TabbedController}
          options={analysisViewOptions}
          onChange={(idx: number) => {
            const newAnalysis = analysisViewOptions[idx].value;
            navigate(
              `/results/field/${operationId}/${fieldId}/${planId}/${newAnalysis}?crop=${cropFromUrl}&language=${language}`,
            );
          }}
          activeIndex={analysisViewOptions.findIndex((s) => s.value === analysis)}
        />
        {getInnerContent()}
      </Stack>
      {maplistResults}
    </Stack>
  );
};

export default FieldSummaryContainer;
