import React from 'react';
import { Group, SegmentedControl, Stack, Text } from '@mantine/core';
import {
  AgronomicProductType,
  PestGroupPayloadType,
  SeedType,
  CombinedAnalyticType,
} from 'store/cropPlans/types';
import { CatalogType } from 'store/catalogs/types';
import { NO_COVERAGE } from 'constants/cropPlan';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getLowModHigh } from 'util/cropPlans';

interface ProductProtectionPropsType {
  analytics: { [id: number]: CombinedAnalyticType };
  pestGroups: PestGroupPayloadType;
  product: AgronomicProductType;
  catalog: Partial<CatalogType> | null;
  setInputRating: (
    input: SeedType | AgronomicProductType,
    analyticId: number,
    coverage: number,
  ) => void;
  isTemporary?: boolean;
}

const ProductProtection = ({
  analytics,
  pestGroups,
  product,
  catalog,
  setInputRating,
  isTemporary,
}: ProductProtectionPropsType) => {
  const language = useBroswerLanguage();

  const analyticIds = Array.from(
    new Set(
      pestGroups.corn.below_ground.concat(
        pestGroups.corn.foliar,
        pestGroups.soybeans.below_ground,
        pestGroups.soybeans.foliar,
      ),
    ),
  );

  return (
    <Group>
      {analyticIds.map((analyticId) => {
        const analytic = Object.values(analytics).find((a) => a.id === analyticId);
        if (!analytic) {
          return null;
        }
        const coverageRatings = catalog?.products?.[product.id]?.ratings || [];
        const coverage = isTemporary
          ? analytic.coverage_rating
          : coverageRatings.find((rating) => rating.analytic_id === analyticId)?.coverage_rating;

        const lowModHigh = getLowModHigh(language);
        return (
          <Stack
            align="center"
            justify="flex-start"
            key={analyticId}
            w="5rem"
            h="13rem"
            style={{ textAlign: 'center' }}
          >
            <SegmentedControl
              orientation="vertical"
              data={lowModHigh}
              onChange={(val) => setInputRating(product, analytic.id, Number(val))}
              value={String(coverage || NO_COVERAGE)}
            />
            <Text size="sm" fw={600}>
              {analytic.name}
            </Text>
          </Stack>
        );
      })}
    </Group>
  );
};

export default ProductProtection;
