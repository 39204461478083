import { SegmentedControl } from '@mantine/core';
import { mapDisplayTypes } from 'constants/results';
import React from 'react';
import { getString } from 'strings/translation';

type MapDisplayType = keyof typeof mapDisplayTypes;

type Props = {
  language: string;
  onChange: (value: string) => void;
  value: MapDisplayType;
  className?: string;
};

export const MapDisplayTypeSelector = ({ value, language, onChange, className }: Props) => {
  return (
    <SegmentedControl
      className={className}
      value={value}
      data={[
        { label: getString('proResults', language), value: mapDisplayTypes.pro },
        { label: getString('highContrast', language), value: mapDisplayTypes.highContrast },
        { label: getString('points', language), value: mapDisplayTypes.points },
      ]}
      onChange={onChange}
    />
  );
};
