import { AppThunk } from 'store';

import { requestStateGeometry, receiveStateGeometry, stateGeometryError } from './actions';

import { fetchStateGeometry } from './requests';

export const getStateGeometry =
  (stateId: string, countryId: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStateGeometry(stateId));
    try {
      const response = await fetchStateGeometry(stateId, countryId);
      dispatch(receiveStateGeometry(stateId, countryId, response));
    } catch (e) {
      dispatch(stateGeometryError(stateId));
    }
  };
