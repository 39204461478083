export const printSampleBarcode = (sampleBarcode: string, qrCodeUrl: string) => `
    <html>
        <head>
            <title>Print QR Code</title>
            <style>
            @media print {
                @page {
                    size: 2in 1.25in;
                    margin: 0;
                }
                body {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-width: 2in;
                    max-height: 1.25in;
                }
                .Wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 100%;
                }
                .Qr {
                    max-width: 15mm;
                    max-height: 15mm;
                }
                .Barcode {
                    font-size: 5pt;
                    font-weight: 700;
                    font-family: sans-serif;
                    max-width: 20mm;
                }
            }
            </style>
        </head>
        <body>
            <div class="Wrapper">
                <div>
                    <img src="${qrCodeUrl}" alt="QR Code" class="Qr" />
                </div>
                <div>
                    <p class="Barcode">${sampleBarcode}</p>
                </div>
            </div>
            <script>
                window.onload = function() {
                    window.print();
                    window.onafterprint = function() {
                    window.close();
                    }
                }
            </script>
        </body>
    </html>
`;
