import React, { useState } from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { requestPutOperation } from 'store/operation/requests';
import { Agency } from 'store/agencies/types';
import { OperationType } from 'store/operation/types';

import { Select } from '@mantine/core';

interface ContractPreferencePropsType {
  operation: OperationType;
  agencies: Agency[];
}
const ContractPreference = ({ operation, agencies }: ContractPreferencePropsType) => {
  const language = useBroswerLanguage();
  const [agencyId, setAgencyId] = useState(operation.default_bill_to_agency_id);

  const handleSelect = async (value: string | null) => {
    const selectedId = value ? parseInt(value, 10) : null;
    if (selectedId !== operation.default_bill_to_agency_id) {
      setAgencyId(selectedId);
      requestPutOperation(operation.id, {
        default_bill_to_agency_id: selectedId,
      });
    }
  };

  const options = agencies.map((agency) => ({
    label: agency.name,
    value: String(agency.id),
  }));

  return (
    <Select
      data={options}
      onChange={handleSelect}
      value={agencyId ? String(agencyId) : null}
      placeholder={getString('none', language)}
      maw="20rem"
    />
  );
};

export default ContractPreference;
