import React from 'react';
import { Dialog } from 'common';
import { PlateType, WellType } from 'store/plates/types';

import { WellDetails } from './Details';
import { CreateWellContainer as WellCreation } from './Create/Container';
import styles from './Container.module.css';

type WellDialogContainerPropsType = {
  well: WellType;
  plate: PlateType;
  onClose: () => void;
};

export const WellDialogContainer = ({ well, plate, onClose }: WellDialogContainerPropsType) => {
  return (
    <Dialog className={styles.WellDialog} onClose={onClose}>
      <div className={styles.InternalContainer}>
        {well?.sample_uuid ? (
          <WellDetails well={well} onClose={onClose} />
        ) : (
          <WellCreation plate={plate} startingWell={well} onClose={onClose} />
        )}
      </div>
    </Dialog>
  );
};
