import React, { useState } from 'react';
import { Group, Text, Input, Button, Stack, Center, Loader } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { KEYS } from 'constants/keyCodes';
import styles from './EmailForm.module.css';
import { emailRegExp } from 'util/validators';
import { type ToastType } from 'actions/toastActions';

interface Props {
  getUserPrelogin: (email: string) => void;
  setToastMessage: (message: string, type?: ToastType, timeout?: number) => void;
}

const EmailForm = ({ getUserPrelogin, setToastMessage }: Props) => {
  const language = useBroswerLanguage();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const submitIfEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      handleClickNext();
    }
  };

  const handleClickNext = async () => {
    setLoading(true);
    if (!emailRegExp.test(email)) {
      setToastMessage(getString('enterValidEmail', language), 'error');
    } else {
      await getUserPrelogin(email);
    }
    setLoading(false);
  };

  return (
    <Stack>
      <Center>
        <Text size="lg">{getString('enterEmailToSignIn', language)}</Text>
      </Center>
      <Group>
        <Input
          className={styles.InputEmail}
          data-test-id="email"
          onChange={(e) => setEmail(e.target.value)}
          onKeyUp={submitIfEnter}
          type="email"
          placeholder={`${getString('email', language)}...`}
          value={email}
        />
        <Button data-test-id="next-button" onClick={handleClickNext} disabled={loading || !email}>
          {loading ? <Loader size={15} /> : getString('next', language)}
        </Button>
      </Group>
    </Stack>
  );
};

export default EmailForm;
