import { JobType, JOB_STATUSES, JOB_TYPES } from 'store/jobs/types';
import { getString } from 'strings/translation';

const atLeastOneComplete = (jobs: JobType[]) =>
  jobs.some((job) => job.status === JOB_STATUSES.COMPLETE);

const atLeastOneQueued = (jobs: JobType[]) =>
  jobs.some((job) => job.status === JOB_STATUSES.QUEUED);

export const getUploadStatus = (jobs: JobType[]) => {
  const uploadJob = jobs.find((job) => job.type === JOB_TYPES.UPLOADED_FILE);

  if (!uploadJob || uploadJob.status === JOB_STATUSES.ERROR) {
    return JOB_STATUSES.ERROR;
  }

  if (uploadJob.status === JOB_STATUSES.CANCELLED) {
    return JOB_STATUSES.CANCELLED;
  }

  const shapefileJobs = jobs.filter((job) => job.type === JOB_TYPES.SHAPEFILE);

  const layerJobs = jobs.filter((job) => job.type === JOB_TYPES.LAYERS);

  if (!layerJobs.length) {
    // if any jobs are processing, we are still awaiting shapefile results
    if (atLeastOneQueued(shapefileJobs) || uploadJob.status === JOB_STATUSES.QUEUED) {
      return JOB_STATUSES.QUEUED;
    }

    // if all jobs are terminated and at least one is complete we can proceed to ingest
    if (atLeastOneComplete(shapefileJobs) || uploadJob.status === JOB_STATUSES.COMPLETE) {
      return JOB_STATUSES.READY_FOR_INGEST;
    }
    // otherwise, all jobs have failed
    return JOB_STATUSES.ERROR;
  }

  if (atLeastOneComplete(layerJobs)) {
    return JOB_STATUSES.COMPLETE;
  }

  return JOB_STATUSES.ERROR;
};

export const getUploadStatusDisplayName = (job: JobType, language: string) => {
  if (job.status === JOB_STATUSES.ERROR) {
    return getString('failed', language);
  }

  if (job.status === JOB_STATUSES.QUEUED) {
    return getString('processingData', language);
  }

  return getString(job.status, language);
};
