export const JOB_TYPES = {
  SHAPEFILE: 'shapefile',
  LAYERS: 'layers',
  UPLOADED_FILE: 'uploaded_file',
};

export const JOB_STATUSES = {
  CANCELLED: 'cancelled',
  COMPLETE: 'complete',
  ERROR: 'error',
  QUEUED: 'queued',
  READY_FOR_INGEST: 'ready for ingest',
};

export type JobType = {
  id: number;
  created_at: string;
  operation_id: number;
  name: string;
  run_id: number;
  status: string;
  type: 'shapefile' | 'layers' | 'uploaded_file';
};

export const REQUEST_JOBS = 'REQUEST_JOBS';
export const RECEIVE_JOBS = 'RECEIVE_JOBS';
export const RECEIVE_JOB = 'RECEIVE_JOB';
export const JOB_REQUEST_ERROR = 'JOB_REQUEST_ERROR';

type RequestJobsType = {
  type: typeof REQUEST_JOBS;
};

type ReceiveJobsType = {
  type: typeof RECEIVE_JOBS;
  payload: {
    operation_id: number;
    jobs: JobType[];
  };
};

type ReceiveJobType = {
  type: typeof RECEIVE_JOB;
  payload: {
    operation_id: number;
    job: JobType;
  };
};

type JobRequestError = {
  type: typeof JOB_REQUEST_ERROR;
  payload: {
    operation_id: number;
  };
};

export type JobActionType = RequestJobsType | ReceiveJobsType | ReceiveJobType | JobRequestError;
