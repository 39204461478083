import {
  RECEIVE_ALL_TERRITORIES,
  REQUEST_TERRITORIES,
  TERRITORIES_REQUEST_ERROR,
  TerritoryActionType,
  TerritoryStateType,
} from './types';

export const initialState: TerritoryStateType = {
  myTerritory: null,
  allTerritories: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const TerritoryReducer = (state = initialState, action: TerritoryActionType) => {
  switch (action?.type) {
    case REQUEST_TERRITORIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ALL_TERRITORIES:
      return {
        ...state,
        allTerritories: action.payload.territories,
        hasFetched: true,
        isFetching: false,
      };
    case TERRITORIES_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
