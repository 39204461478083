import React from 'react';
import { Group, Stack } from '@mantine/core';
import { PlateType } from 'store/plates/types';

import { SourceBarcodeInput } from '../FormInputs/SourceBarcodeInput';

type NormalizedDNAFormPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const NormalizedDNAForm = ({ plate, onValueChange }: NormalizedDNAFormPropsType) => (
  <Stack>
    <Group>
      <SourceBarcodeInput
        plate={plate}
        onValueChange={onValueChange}
        miw="12.5rem"
        maw="20rem"
        w="40%"
      />
    </Group>
  </Stack>
);
