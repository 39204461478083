import {
  CurrencyStateType,
  RECEIVE_CONVERSION,
  REQUEST_CONVERSION,
  CONVERSION_REQUEST_ERROR,
  ConversionActionTypes,
} from './types';

export const initialState: CurrencyStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  realsToDollars: 1,
};

export const CurrencyReducer = (state = initialState, action: ConversionActionTypes) => {
  switch (action.type) {
    case RECEIVE_CONVERSION:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        realsToDollars: action.payload,
      };
    case REQUEST_CONVERSION:
      return {
        ...state,
        hasFailed: false,
        hasFetched: false,
        isFetching: true,
      };
    case CONVERSION_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
