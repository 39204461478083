import React, { ReactNode, useState } from 'react';
import { Button } from '@mantine/core';

import { FileUploadDialog } from './UploadDialog';

type FileUploadButtonPropTypes = {
  minDimension?: number;
  maxFileSize?: number;
  buttonLabel: string;
  dialogLabel?: string;
  onUpload: (file: File, notes?: string) => void;
  onAbort?: () => void;
  accept?: string;
  message?: string;
  danger?: boolean;
  primary?: boolean;
  children?: ReactNode;
};

export const FileUploadButton = ({
  minDimension,
  maxFileSize,
  buttonLabel,
  dialogLabel,
  onUpload,
  onAbort,
  danger,
  primary,
  accept,
  message,
  children,
}: FileUploadButtonPropTypes) => {
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const variantType = () => {
    if (danger) {
      return 'danger';
    }
    if (!primary) {
      return 'outline';
    }
    return 'filled';
  };

  return (
    <>
      {showUploadDialog && (
        <FileUploadDialog
          open={showUploadDialog}
          minDimension={minDimension}
          maxFileSize={maxFileSize}
          dialogLabel={dialogLabel}
          onUpload={onUpload}
          onClose={() => setShowUploadDialog(false)}
          onAbort={onAbort}
          accept={accept}
          message={message}
        >
          {children}
        </FileUploadDialog>
      )}
      <Button
        variant={variantType()}
        onClick={() => {
          setShowUploadDialog(true);
        }}
      >
        {buttonLabel}
      </Button>
    </>
  );
};
