import { AppThunk } from 'store';
import {
  dnaConcentrationsRequestError,
  receiveDNAConcentrations,
  requestDNAConcentrations,
} from './actions';
import {
  requestDownloadDNAConcentration,
  requestGetDNAConcentration,
  requestPostDNAConcentration,
} from './requests';
import { DNAConcentrationOptionType } from './types';

export const getDNAConcentration =
  (barcode: string, quadrant: number, options: DNAConcentrationOptionType): AppThunk =>
  async (dispatch) => {
    dispatch(requestDNAConcentrations());
    try {
      const { dna_concentrations: dnaConcentrations } = await requestGetDNAConcentration(
        barcode,
        quadrant,
        options,
      );
      dispatch(receiveDNAConcentrations(barcode, dnaConcentrations));
    } catch (e) {
      dispatch(dnaConcentrationsRequestError({ message: e.message }));
    }
  };

export const updateDNAConcentration =
  (plateBarcode: string, file: File, plateQuadrant: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestDNAConcentrations());
    try {
      await requestPostDNAConcentration(plateBarcode, file, plateQuadrant);
      dispatch(
        getDNAConcentration(plateBarcode, plateQuadrant, {
          dna: true,
        }),
      );
    } catch (e) {
      dispatch(dnaConcentrationsRequestError({ message: e.message }));
    }
  };

export const downloadDNAConcentration =
  (
    plateBarcode: string,
    plateQuadrant: number,
    type: string,
    options: DNAConcentrationOptionType,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const fileName = `${plateBarcode}-${type}.csv`;
      await requestDownloadDNAConcentration(plateBarcode, plateQuadrant, fileName, options);
    } catch (e) {
      dispatch(dnaConcentrationsRequestError({ message: e.message }));
    }
  };
