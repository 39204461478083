import React from 'react';

import { Table, TableCol, TableRow } from 'common';
import { AdjustmentsSimpleType } from 'store/payments/types';

import styles from './PaymentTables.module.css';
import AdjustmentsRow from './AdjustmentsRow';

type AdjustmentsTablePropsType = {
  adjustments: AdjustmentsSimpleType[];
};

const AdjustmentsTable = ({ adjustments }: AdjustmentsTablePropsType) => {
  return (
    <Table>
      <TableRow header className={styles.TableHeader}>
        <TableCol className={styles.BigColumn} shrink>
          Description
        </TableCol>
        <TableCol>Approved By</TableCol>
        <TableCol>Transaction Type</TableCol>
        <TableCol>Amount</TableCol>
      </TableRow>
      {adjustments.map((adjustment) => (
        <AdjustmentsRow
          key={adjustment.id}
          description={adjustment.description}
          approvedBy={adjustment.created_by}
          transactionType={adjustment.transaction_type}
          amount={adjustment.amount}
        />
      ))}
    </Table>
  );
};

export default AdjustmentsTable;
