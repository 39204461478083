import React, { useState } from 'react';
import { Accordion } from 'common';
import { Input, SimpleGrid, Stack, Text } from '@mantine/core';
import { FieldType } from 'store/fields/types';
import {
  getPrescriptionAnalytic,
  getPrescriptionSummaryValues,
  getSampleValueForPrescriptionAnalytic,
  getZoneDollarsPerAcre,
  sortZones,
} from 'util/prescription';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { InputType } from 'store/inputs/types';
import { PrescriptionType, PrescriptionZoneType } from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import { BASE_SATURATION, BPH_SIKORA_ID, CEC_ID, PH_ID, SOIL_ATTRIBUTES } from 'constants/results';
import { getPlanRiskColor } from 'util/results';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import styles from './ZoneDetail.module.css';

type ZoneDetailProps = {
  field: FieldType;
  inputs: InputType[];
  prescription: PrescriptionType;
  samples: SampleFeatureType[];
  zones: PrescriptionZoneType[];
  updatePrescriptionZones: (zoneId: number, mapValue: string) => void;
  setSelectedZone: (zoneId: number) => void;
  saveZoneAmount: (zoneId: number, amount: number) => void;
};

const ZoneDetail = ({
  field,
  inputs,
  prescription,
  samples,
  zones,
  updatePrescriptionZones,
  setSelectedZone,
  saveZoneAmount,
}: ZoneDetailProps) => {
  const language = useBroswerLanguage();
  const { acreage_unit } = field.features[0].properties;
  const [showZoneDetail, setShowZoneDetail] = useState(false);

  const summary = () => {
    const { fieldCost, fieldRate, input } = getPrescriptionSummaryValues(
      prescription,
      inputs,
      field,
      language,
    );
    return `${zones.length} ${getString('zones', language)}, ${getString(
      'fieldAvg',
      language,
    )}: ${fieldRate} ${input} @ ${fieldCost}`;
  };

  const analyticId = getPrescriptionAnalytic(prescription);

  const getAnalyticHeaders = () => {
    if (analyticId === PH_ID) {
      return (
        <>
          <Text size="sm">pH</Text>
          <Text size="sm">BpH</Text>
        </>
      );
    }
    if (analyticId === BASE_SATURATION) {
      return (
        <>
          <Text size="sm">{getString('baseSaturation', language)}</Text>
          <Text size="sm">CTC</Text>
        </>
      );
    }
    return (
      <>
        <Text size="sm">{getString('soilTest', language)}</Text>
        <Text size="sm">
          {`${prescription.input.nutrient.toUpperCase()} ${getString('applied', language)}`}
        </Text>
      </>
    );
  };

  const getSecondaryValue = (amount: number, sample?: SampleFeatureType) => {
    if (analyticId === PH_ID) {
      return (
        <div>
          {sample?.properties.analytics[SOIL_ATTRIBUTES]?.[BPH_SIKORA_ID]?.quantity ||
            NOT_APPLICABLE}
        </div>
      );
    }
    if (analyticId === BASE_SATURATION) {
      return (
        <div>
          {sample?.properties.analytics[SOIL_ATTRIBUTES]?.[CEC_ID]?.quantity || NOT_APPLICABLE}
        </div>
      );
    }
    return <div>{Math.round(amount * (prescription.input.concentration || 1))}</div>;
  };

  return (
    <>
      <Accordion
        key="prescription-zone-details"
        open={showZoneDetail}
        title={getString('zoneDetails', language)}
        toggleOpen={() => setShowZoneDetail(!showZoneDetail)}
      >
        <Stack gap="xs" className={styles.Table}>
          <SimpleGrid cols={5} className={styles.TableHeader}>
            <Text size="sm">{getString('zone', language)}</Text>
            {getAnalyticHeaders()}
            <Text size="sm">{prescription.input.name}</Text>
            <Text size="sm">$ / {acreage_unit}</Text>
          </SimpleGrid>
          {sortZones(zones).map((zone, index) => {
            const { amount, id, sample_uuid } = zone.properties;
            const sample = samples?.find((s) => s.properties.sample_uuid === sample_uuid);
            const analyticValue = getSampleValueForPrescriptionAnalytic(prescription, sample);

            const fillColor = getPlanRiskColor(analyticValue?.risk_level);

            return (
              <SimpleGrid cols={5} className={styles.TableRow} key={`table-zone-${id}`}>
                <div>{index + 1}</div>
                <div style={{ backgroundColor: fillColor }}>
                  {analyticValue?.quantity || NOT_APPLICABLE}
                </div>
                {getSecondaryValue(amount, sample)}
                <div
                  onBlur={() => saveZoneAmount(zone.properties.id, amount)}
                  onClick={() => setSelectedZone(zone.properties.id)}
                >
                  <Input
                    data-test-id="map-zone-input"
                    onChange={(e) =>
                      sample && updatePrescriptionZones(zone?.properties.id, e.target.value)
                    }
                    value={String(amount)}
                    type="number"
                  />
                </div>
                <div>
                  {getZoneDollarsPerAcre(
                    prescription,
                    zone,
                    field.features[0].properties.acreage_unit,
                  ).toFixed(2)}
                </div>
              </SimpleGrid>
            );
          })}
        </Stack>
      </Accordion>
      {!showZoneDetail && (
        <div data-test-id="zone-details" className={styles.SummaryText}>
          {summary()}
        </div>
      )}
    </>
  );
};

export default ZoneDetail;
