import {
  handleJsonResponse,
  requestGet,
  SERVICE_URL,
  requestPost,
  requestPut,
  handleFileAttachment,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { BatchUploadedFileType } from './types';

export const requestGetBatches = async (filter: {
  page: number;
  type?: string[];
  status?: string[];
  id?: string;
}) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/batches/?${queryStringify({
        page: filter.page,
        type: filter.type?.length ? filter.type.toString() : undefined,
        status: filter.status?.length ? filter.status.toString() : undefined,
        id: filter.id || undefined,
      })}`,
    ),
  );

export const requestGetBatch = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/batch/${id}`));

export const requestCreateBatchShotgun = async (body: {
  plates_barcodes: Array<string>;
  type: string;
  barcode_lanes?: { [lane: number]: string[] };
}) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/batch/`, {
      body,
    }),
  );

export const requestUpdateBatch = async (id: number, body: any) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/batch/${id}`, {
      body,
    }),
  );

export const requestMarkBatchSent = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/mark_batch_sent/${id}`));

export const requestSampleSheet = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/batch_sample_sheet/${id}`));

export const requestBatchSampleSheet = async (id: number) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/generate_shotgun_sample_sheet/${id}`));

export const requestGenerateSampleSheetV2 = async (batch_ids: number[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/generate_shotgun_sample_sheet_v2/`, { body: { batch_ids } }),
  );

export const requestGetBatchSamples = async (batchId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/batch_samples_data/${batchId}`));

export const requestPostBatchFileUpload = async (batchId: number, fileName: string) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/batch_file_upload/`, {
      body: {
        batch_id: batchId,
        file_name: fileName,
      },
    }),
  );

export const requestPutBatchUploadedFile = async (
  uploadedId: number,
  body: Partial<BatchUploadedFileType>,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/batch_uploaded_file/${uploadedId}`, {
      body,
    }),
  );

export const requestGetBatchUploadedFile = async (batchFileId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/batch_uploaded_files/${batchFileId}`));

export const requestPutAddPlateToBatch = async (
  batchId: number,
  plateBarcode: string,
  lane?: number | null,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/add_plate_to_batch/${batchId}`, {
      body: { new_barcode: plateBarcode, lane },
    }),
  );

export const requestDownloadRunSheet = async (batchId: number, format?: string) =>
  handleFileAttachment(
    await requestGet(`${SERVICE_URL}/batch_run_sheet/${batchId}?${queryStringify({ format })}`),
    `batch_${batchId}_run_sheet.csv`,
  );
