import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Header, Spinner, Table, TableCol, TableRow } from 'common';
import { getAllNaptControls } from 'store/naptControls/thunks';
import { naptElements } from 'constants/controls';

import { CreateNaptControlButton } from './Create/CreateNaptControl';

const NaptOverview = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { isFetching, hasFetched, hasFailed, naptControls } = useSelector((state: RootState) => ({
    isFetching: state.naptControls.isFetching,
    hasFetched: state.naptControls.hasFetched,
    hasFailed: state.naptControls.hasFailed,
    naptControls: state.naptControls.naptControls,
  }));

  useEffect(() => {
    if (!isFetching && !hasFetched && !hasFailed) {
      dispatch(getAllNaptControls());
    }
  }, [dispatch, isFetching, hasFetched, hasFailed]);

  return (
    <div>
      <Header title={<div>{getString('naptControls', language)}</div>}>
        <CreateNaptControlButton />
      </Header>
      <Table>
        <TableRow header>
          <TableCol size="x3">{getString('controlCode', language)}</TableCol>
          {naptElements.map((elem, idx) => (
            <TableCol key={idx}>{elem}</TableCol>
          ))}
        </TableRow>
        {isFetching && (
          <TableRow>
            <TableCol size="x4">
              <Spinner fill />
            </TableCol>
          </TableRow>
        )}
        {hasFetched &&
          naptControls.map((control) => (
            <TableRow key={control.id}>
              <TableCol size="x3">{control.control_code}</TableCol>
              {naptElements.map((elem, idx) => (
                <TableCol key={idx}>{control[elem.toLowerCase()]}</TableCol>
              ))}
            </TableRow>
          ))}
      </Table>
    </div>
  );
};

export default NaptOverview;
