export type DNAConcentrationsType = {
  concentration: number;
  id: number;
  column: string;
  plate: number;
  plate_barcode: string;
  plate_quadrant: number;
  row: string;
  value: number;
};

export type PlateDNAConcentrationType = {
  [key: number]: DNAConcentrationLookupType;
};

export type DNAConcentrationLookupType = {
  [key: string]: {
    [key: string]: DNAConcentrationsType;
  };
};

export type DNAConcentrationOptionType = {
  target_plate_dilution?: string;
  total_dna_multiplier?: string;
  total_pcr_multiplier?: string;
  dna?: boolean;
  normalized_dna?: boolean;
  total_dna?: boolean;
  total_pcr_dna?: boolean;
};

export type DNAConcentrationsStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  error: string;
  setsByPlateBarcode: {
    [key: string]: PlateDNAConcentrationType;
  };
};

export const RECEIVE_DNA_CONCENTRATIONS = 'RECEIVE_DNA_CONCENTRATIONS';
export const REQUEST_DNA_CONCENTRATIONS = 'REQUEST_DNA_CONCENTRATIONS';
export const UPDATE_DNA_CONCENTRATIONS = 'UPDATE_DNA_CONCENTRATIONS';
export const DNA_CONCENTRATIONS_REQUEST_ERROR = 'DNA_CONCENTRATIONS_REQUEST_ERROR';

type RequestDNAConcentrationsActionType = {
  type: typeof REQUEST_DNA_CONCENTRATIONS;
  payload: {
    [key: string]: any;
  };
};

type ReceiveDNAConcentrationsActionType = {
  type: typeof RECEIVE_DNA_CONCENTRATIONS;
  payload: {
    plateBarcode: string;
    concentrations: DNAConcentrationsType[];
  };
};

type UpdateDNAConcentrationsActionType = {
  type: typeof UPDATE_DNA_CONCENTRATIONS;
  payload: {
    file: Blob;
  };
};

type dnaConcentrationsRequestErrorType = {
  type: typeof DNA_CONCENTRATIONS_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type DNAConcentrationsActionType =
  | RequestDNAConcentrationsActionType
  | ReceiveDNAConcentrationsActionType
  | UpdateDNAConcentrationsActionType
  | dnaConcentrationsRequestErrorType;
