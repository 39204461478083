import React from 'react';
import { getString } from 'strings/translation';
import { Stack, Text, List, ThemeIcon, Group } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { ND, NO_DATA, NOT_DETECTED, riskLevels, riskToDisplay } from 'constants/fieldRisks';
import { FiChevronRight } from 'react-icons/fi';

import styles from './RiskLegendV2.module.css';

interface PropTypes {
  clickCropSideBar: (crop: string) => void;
  highlighted: string;
  crops: string[];
}

const RiskLegend = ({ clickCropSideBar, highlighted, crops }: PropTypes) => {
  const language = useBroswerLanguage();

  return (
    <Stack className={styles.Legend}>
      <Text size="sm" fw={500} td="underline">
        {getString('legend', language)}
      </Text>
      <List spacing={0} size="xs" center>
        {riskLevels.map((risk) => {
          if (risk === NO_DATA) {
            return null;
          }
          const displayName =
            getString(riskToDisplay[risk], language) || getString('noData', language);
          return (
            <List.Item
              key={risk}
              className={styles.Risk}
              icon={
                <ThemeIcon size={13} className={styles[riskToDisplay[risk]]}>
                  <Text size="0.4rem" color="blue">
                    {risk === NOT_DETECTED ? ND : ''}
                  </Text>
                </ThemeIcon>
              }
            >
              {displayName}
            </List.Item>
          );
        })}
      </List>
      {crops.map((crop) => (
        <Group key={crop} gap={0}>
          <Text
            key={crop}
            size="md"
            fw={highlighted === crop ? 500 : 0}
            c={highlighted !== crop ? 'dimmed' : undefined}
            onClick={() => clickCropSideBar(crop)}
            className={styles.Crop}
          >
            {getString(crop, language)}
          </Text>
          {highlighted === crop ? <FiChevronRight /> : ''}
        </Group>
      ))}
    </Stack>
  );
};

export default RiskLegend;
