import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Input, OperationSearchBar, Button, Spinner } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { CountyType, OperationDetailsType } from 'store/salesFlow/types';
import { CountySearch } from 'common/CountySearch';
import showToast, { type ToastType } from 'actions/toastActions';
import { postOperation } from 'store/operation/requests';
import { requestExistingOperationCropPlans } from 'store/salesFlow/requests';
import { CORN, SOYBEANS } from 'constants/variables';
import Styles from './Container.module.css';
import SlideStyles from '../Shared.module.css';

interface OperationDetailsPropTypes {
  operationDetails: OperationDetailsType;
  setOperationDetails: Function;
}
const OperationInfo = ({ operationDetails, setOperationDetails }: OperationDetailsPropTypes) => {
  const [isSavingOperation, toggleSavingOperation] = useState(false);
  const language = useBroswerLanguage();

  const setToastMessage = (message: string, type?: ToastType, timeout?: boolean | number) =>
    showToast(message, type, timeout);

  const createFarm = async () => {
    try {
      toggleSavingOperation(true);
      const operation = await postOperation({
        name: operationDetails.operationName,
      });
      setToastMessage(getString('createOperationSuccessMsg', language));
      setOperationDetails({
        ...operationDetails,
        isOperationCreated: true,
        operation_id: operation.id,
      });
    } catch (err) {
      setToastMessage(getString('createOperationErrorMsg', language), 'error', 5000);
    } finally {
      toggleSavingOperation(false);
    }
  };

  const setOperation = async (operation) => {
    try {
      const existingPlans = await requestExistingOperationCropPlans(operation.id);
      existingPlans.forEach((plan) => {
        delete plan.operation;
        delete plan.county;
        delete plan.analysis;
        delete plan.summary;
      });
      const operationDetailsTemp = {
        ...operationDetails,
        operation_id: operation.id,
        operationName: '',
      };
      if (existingPlans.length) {
        operationDetailsTemp.corn = existingPlans.find((plan) => plan.crop === CORN);
        operationDetailsTemp.soybeans = existingPlans.find((plan) => plan.crop === SOYBEANS);
      }
      setOperationDetails(operationDetailsTemp);
    } catch (err) {
      // Only set the operation id
      setOperationDetails({
        ...operationDetails,
        operation_id: operation.id,
        operationName: '',
      });
    }
  };

  return (
    <>
      <div>
        <div className={Styles.OperationRow}>
          <OperationSearchBar
            className={Styles.OperationSelector}
            leftSection={<FiSearch />}
            placeholder={getString('searchOperation', language)}
            disabled={isSavingOperation || operationDetails.isOperationCreated}
            onSelect={setOperation}
            onDeselect={() =>
              setOperationDetails({
                ...operationDetails,
                operation_id: null,
              })
            }
          />
          <span className={Styles.MarginRight}>--- {getString('or', language)} ---</span>
          <Input
            className={SlideStyles.Input}
            disabled={isSavingOperation || operationDetails.isOperationCreated}
            placeholder={`${getString('operationName', language)}*`}
            onChange={(e) => {
              setOperationDetails({
                ...operationDetails,
                operationName: e.target.value,
                operation_id: null,
              });
            }}
            value={operationDetails.operationName}
          />
          <Button
            primary
            onClick={createFarm}
            disabled={
              !operationDetails.operationName ||
              isSavingOperation ||
              !!operationDetails.operation_id
            }
          >
            {getString('create', language)}
          </Button>
          {isSavingOperation && <Spinner className={Styles.SmallSpinner} />}
          {operationDetails.isOperationCreated && (
            <span className={Styles.Note}>
              <i>*{getString('canUpdateLater', language)}*</i>
            </span>
          )}
        </div>
        <div className={Styles.CountyRow}>
          <CountySearch
            className={Styles.OperationSelector}
            onDeselect={() =>
              setOperationDetails({
                ...operationDetails,
                ctfips: null,
              })
            }
            placeholder={getString('searchCounty', language)}
            onSelect={(county: CountyType) =>
              setOperationDetails({
                ...operationDetails,
                ctfips: county.ctfips,
              })
            }
          />
          <span className={Styles.Note}>
            <i>*{getString('countyFootNote', language)}*</i>
          </span>
        </div>
      </div>
    </>
  );
};

export default OperationInfo;
