import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Container, Header } from 'common';
import { PlateType } from 'store/plates/types';

import { GridContainer } from '../../Wells/Grid/Container';
import { DNAWellGrid } from '../DNAConcentration/DNAWellGrid';
import styles from './PlateWells.module.css';

type PlateQuadrantsPropsType = {
  plate: PlateType;
};

export const PlateQuadrants = ({ plate }: PlateQuadrantsPropsType) => {
  const language = useBroswerLanguage();

  const quadrantsBarcodesNumbers = [
    plate.quadrant1_plate_barcode,
    plate.quadrant2_plate_barcode,
    plate.quadrant3_plate_barcode,
    plate.quadrant4_plate_barcode,
  ]
    .filter((quadrant) => quadrant)
    .map((q, idx) => idx + 1);

  return (
    <Container vertical>
      <Header title={getString('plateDetails', language)} underline="none" size="H2" />
      {quadrantsBarcodesNumbers.map((quadrant) => (
        <Container vertical key={quadrant}>
          <Header
            title={`${getString('quadrant', language)} ${quadrant}`}
            underline="none"
            size="H5"
          />
          <div className={styles.Row}>
            <div>
              <Header title={getString('controls', language)} underline="none" size="H2" />
              <GridContainer plate={plate} quadrant={quadrant} />
            </div>
            <DNAWellGrid plateBarcode={plate.barcode} quadrant={quadrant} />
          </div>
        </Container>
      ))}
    </Container>
  );
};
