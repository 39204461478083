import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Table, Stack, Text } from '@mantine/core';
import { Agency } from 'store/agencies/types';
import formatPhoneNumber from 'util/formatPhone';
import { joinStrings } from 'util/stringUtils';
import { useNavigate } from 'react-router-dom';
import styles from './Container.module.css';

type AgencyRowPropsType = {
  agency: Agency;
};

const AgencyRow = ({ agency }: AgencyRowPropsType) => {
  const navigate = useNavigate();
  const [isDisplayExpanded, toggleDisplayExpanded] = useState(false);

  const openCloseChildrenRows = (e) => {
    e.stopPropagation();
    agency.child_agencies?.length && toggleDisplayExpanded(!isDisplayExpanded);
  };

  const goToAgency = (e) => {
    e.stopPropagation();
    navigate(`/agencies/${agency.id}`);
  };

  const chevronDisplay = () => {
    if (agency.child_agencies?.length) {
      return isDisplayExpanded ? <FiChevronUp /> : <FiChevronDown />;
    }
    return null;
  };

  return (
    <>
      <Table.Tr onClick={goToAgency}>
        <Table.Th className={styles.Clickable}>
          <Stack h={agency.parent_agency?.id ? 40 : 20} gap="xs" align="flex-start">
            <Text size="sm" fw={700}>
              {agency.name}
            </Text>
            {!!agency.parent_agency?.id && (
              <Text size="xs" fs="italic">
                {agency.parent_agency.name}
              </Text>
            )}
          </Stack>
        </Table.Th>
        <Table.Th>{agency?.users?.length}</Table.Th>
        <Table.Th>
          {joinStrings([agency.primary_user.first_name, agency.primary_user.last_name], ' ')}
        </Table.Th>
        <Table.Th>{formatPhoneNumber(agency.primary_user.cell)}</Table.Th>
        <Table.Th>{agency.primary_user.email}</Table.Th>
        <Table.Th className={styles.Clickable} onClick={openCloseChildrenRows}>
          {chevronDisplay()}
        </Table.Th>
      </Table.Tr>
      {isDisplayExpanded &&
        agency.child_agencies?.map((chi) => (
          <Table.Tr
            style={(theme) => ({
              backgroundColor: theme.colors.grey[9],
            })}
            key={chi.id}
          >
            <Table.Td className={styles.Clickable}>
              <span onClick={() => navigate(`/agencies/${chi.id}`)}>{chi.name}</span>
            </Table.Td>
            <Table.Td>{chi.users?.length}</Table.Td>
            <Table.Td>
              {joinStrings([chi.primary_user?.first_name, chi.primary_user?.last_name], ' ')}
            </Table.Td>
            <Table.Td>{chi.primary_user?.cell}</Table.Td>
            <Table.Td>{chi.primary_user?.email}</Table.Td>
            <Table.Td />
          </Table.Tr>
        ))}
    </>
  );
};

export default AgencyRow;
