import React, { useState } from 'react';
import { Button, Input, Space } from '@mantine/core';
import { MERGE, MODES, SPLIT } from 'constants/mapbox';
import mapboxgl from 'mapbox-gl';
import { getString } from 'strings/translation';
import styles from './DrawingTools.module.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved,@typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// fixes a mapbox-gl issue, will not be needed soon

type DrawingToolsPropType = {
  showClus?: boolean;
  setShowClus?: (arg0: boolean) => void;
  addClusToEditor?: () => void;
  drawModeSetter: (
    arg0: string,
  ) => (((event: any) => void) & ((event: string) => void)) | undefined;
  handleDelete: () => void;
  startMergeFields?: () => void;
  startSplitFields?: () => void;
  mergeSplitType?: string | null;
  mergeSelectedPolygons?: number[];
  confirmMerge?: () => void;
  confirmSplit?: (newName?: string) => void;
  cancelSplitMerge?: () => void;
  mode: string;
  language: string;
  isNewFieldWithExisting?: boolean;
  drawRef?: any;
  isLoading?: boolean;
};

const DrawingTools = ({
  showClus,
  setShowClus,
  addClusToEditor,
  drawModeSetter,
  handleDelete,
  startMergeFields,
  startSplitFields,
  mergeSplitType,
  mergeSelectedPolygons,
  confirmMerge,
  confirmSplit,
  cancelSplitMerge,
  mode,
  language,
  isNewFieldWithExisting = false,
  drawRef,
  isLoading,
}: DrawingToolsPropType) => {
  const [spliteFieldName, setSpliteFieldName] = useState('');

  const mergeSplit = () => {
    if (mergeSplitType === MERGE && confirmMerge && cancelSplitMerge) {
      return (
        <>
          <Button
            disabled={
              (mergeSplitType === MERGE && (mergeSelectedPolygons || [])?.length < 2) || isLoading
            }
            data-test-id="merge-fields-confirm"
            onClick={confirmMerge}
          >
            {getString('confirmMerge', language)}
          </Button>
          <Button
            variant="white"
            data-test-id="merge-fields-cancel"
            onClick={cancelSplitMerge}
            disabled={isLoading}
          >
            {getString('cancelMerge', language)}
          </Button>
        </>
      );
    }
    if (mergeSplitType === SPLIT && startSplitFields && confirmSplit && cancelSplitMerge) {
      return (
        <>
          <Input
            data-test-id="split-fields-confirm"
            placeholder={getString('newFieldName', language)}
            style={{ width: '13rem' }}
            value={spliteFieldName}
            onChange={(e) => setSpliteFieldName(e.currentTarget.value)}
            disabled={isLoading}
          />
          <Space h="xs" />
          <Button
            data-test-id="split-fields-confirm"
            onClick={() => {
              confirmSplit(spliteFieldName);
              setSpliteFieldName('');
            }}
            style={{ width: '11rem' }}
            disabled={!spliteFieldName || !drawRef.current?.getAll()?.features?.length || isLoading}
          >
            {getString('confirmSplit', language)}
          </Button>
          <Button
            data-test-id="split-fields-cancel"
            onClick={() => {
              cancelSplitMerge();
              setSpliteFieldName('');
            }}
            disabled={isLoading}
            style={{ width: '11rem' }}
            variant="white"
          >
            {getString('cancelSplit', language)}
          </Button>
        </>
      );
    }
  };

  return (
    <div className={styles.Controls}>
      {mergeSplitType ? (
        mergeSplit()
      ) : (
        <>
          {addClusToEditor && setShowClus && (
            <>
              <Button
                data-test-id="select-clus"
                onClick={drawModeSetter(MODES.SELECT)}
                variant={showClus && mode === MODES.SELECT ? 'filled' : 'white'}
                disabled={isLoading}
              >
                {getString('selectClus', language)}
              </Button>
            </>
          )}
          <Button
            data-test-id="draw-polygon"
            onClick={drawModeSetter(MODES.DRAW_POLYGON)}
            variant={mode === MODES.DRAW_POLYGON && !mergeSplitType ? 'filled' : 'white'}
          >
            {getString('drawPolygon', language)}
          </Button>
          <Button
            data-test-id="draw-rectangle"
            onClick={drawModeSetter(MODES.DRAW_RECTANGLE)}
            variant={mode === MODES.DRAW_RECTANGLE ? 'filled' : 'white'}
            disabled={isLoading}
          >
            {getString('drawRectangle', language)}
          </Button>
          <Button color="darkRed" data-test-id="delete" onClick={handleDelete} disabled={isLoading}>
            {getString('delete', language)}
          </Button>
          {isNewFieldWithExisting && startMergeFields && (
            <Button
              data-test-id="merge-fields"
              onClick={startMergeFields}
              disabled={isLoading}
              variant={mergeSplitType === MERGE && mode === MODES.SELECT ? 'filled' : 'white'}
            >
              {getString('mergeFields', language)}
            </Button>
          )}
          {isNewFieldWithExisting && startSplitFields && (
            <Button
              data-test-id="split-fields"
              onClick={startSplitFields}
              variant={mergeSplitType === SPLIT ? 'filled' : 'white'}
              disabled={isLoading}
            >
              {getString('splitFields', language)}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default DrawingTools;
