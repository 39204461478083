import { useEffect } from 'react';
import { getOperation, getOperationUsers, setActiveOperation } from 'store/operation/thunks';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';

type ParamsType = {
  operationId: string;
};

const useOperation = () => {
  const params = useParams<ParamsType>();
  const operationId = Number(params.operationId);
  const dispatch = useDispatch();
  const { operation, operationUsers } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[operationId],
    operationUsers: state.operations.operationsById[operationId]?.operationUsers,
  }));

  useEffect(() => {
    dispatch(setActiveOperation(operationId));
    if (!operation) {
      dispatch(getOperation(operationId));
      dispatch(getOperationUsers(operationId));
    }
  }, [operation]);

  return { operation, operationUsers };
};

export default useOperation;
