import React from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { QUICKBOOKS_TERMS } from 'constants/results';
import { capitalizeEveryWord } from 'util/stringUtils';
import ActionDialog from '../common/Dialog/ActionDialog';

type SettlementControllerPropsType = {
  firstName: string;
  lastName: string;
  amount: number;
  onSubmit: (term?: { displayName: string; id: number }, isQB?: boolean) => void;
  onClose: () => void;
};

const SettlementController = ({
  firstName,
  lastName,
  amount,
  onSubmit,
  onClose,
}: SettlementControllerPropsType) => {
  const language = useBroswerLanguage();
  const name = `${firstName} ${lastName}`;
  const absAmount = Math.abs(amount);
  const payToWho =
    amount > 0
      ? `${getString(
          'resultInPatternPaymentMsg',
          language,
        )} ${capitalizeEveryWord(name)}: $${absAmount}.`
      : `${getString(
          'resultInPatternInvoiceMsg',
          language,
        )} ${capitalizeEveryWord(name)}: $${absAmount}.`;
  const description = `${getString('transactionApprovalMsg', language)} ${payToWho}`;

  const paymentType = (amountVal: number) => {
    if (!amountVal) {
      return null;
    }
    return amountVal > 0 ? 'payment' : 'invoice'; // mimic backend if
  };

  return (
    <ActionDialog
      actionTitle={getString('approveOpenTransactions', language)}
      name={name}
      type={paymentType(amount)}
      amount={amount}
      description={description}
      buttonName={getString('approveTransaction', language)}
      onSubmit={onSubmit}
      onClose={onClose}
      selectorOptions={QUICKBOOKS_TERMS}
      secondButtonName={`${getString('approveTransaction', language)} (No QB)`}
    />
  );
};

export default SettlementController;
