import { getString } from 'strings/translation';
import { CORN, SOYBEANS } from 'constants/variables';
import { OperationDetailsType } from 'store/salesFlow/types';
import { CROP_OUTPUT_RANGES, defaultCornInfo } from 'constants/salesFlow';

export const getFlowTrackerSteps = (language: string) => [
  {
    title: getString('overview', language),
    bottomButtonTitle: getString('viewBenefits', language),
    index: 0,
  },
  {
    title: getString('benefits', language),
    bottomButtonTitle: getString('createFarmPlan', language),
    index: 1,
  },
  {
    title: getString('myFarmPlan', language),
    bottomButtonTitle: getString('viewMyReport', language),
    index: 2,
  },
  {
    title: getString('myReport', language),
    bottomButtonTitle: getString('viewOffer', language),
    index: 3,
  },
  {
    title: `Pattern 360 ${getString('offer', language)}`,
    bottomButtonTitle: '',
    index: 4,
  },
];

export const validateFarmPlan = (operationDetails: OperationDetailsType, language: string) => {
  const cornOutputs = operationDetails.corn;
  const soybeanOutputs = operationDetails.soybeans;
  const cornRanges = CROP_OUTPUT_RANGES.corn;
  const soybeanRanges = CROP_OUTPUT_RANGES.soybeans;

  if (!operationDetails.operation_id) {
    return getString('missingOperationIdMsg', language);
  }
  if (!operationDetails.ctfips) {
    return getString('missingCountyMsg', language);
  }

  const keysToCheck = Object.keys(defaultCornInfo);
  if (
    [CORN, SOYBEANS].find((crop) =>
      Object.keys(operationDetails[crop]).some(
        (key) =>
          keysToCheck.includes(key) &&
          (operationDetails[crop][key] === '' || operationDetails[crop][key] === null),
      ),
    )
  ) {
    return getString('missingCropDetailsMsg', language);
  }

  if (
    !cornOutputs.target_yield ||
    !soybeanOutputs.target_yield ||
    !cornOutputs.price_per_bushel ||
    !soybeanOutputs.price_per_bushel
  ) {
    return getString('missingCropDetailsMsg', language);
  }
  if (
    cornRanges.target_yield[0] > cornOutputs.target_yield ||
    cornOutputs.target_yield > cornRanges.target_yield[1]
  ) {
    return getString('invalidCornYieldMsg', language);
  }
  if (
    cornRanges.price_per_bushel[0] > cornOutputs.price_per_bushel ||
    cornOutputs.price_per_bushel > cornRanges.price_per_bushel[1]
  ) {
    return getString('invalidCornPriceMsg', language);
  }
  if (
    soybeanRanges.target_yield[0] > soybeanOutputs.target_yield ||
    soybeanOutputs.target_yield > soybeanRanges.target_yield[1]
  ) {
    return getString('invalidSoybeanYieldMsg', language);
  }
  if (
    soybeanRanges.price_per_bushel[0] > soybeanOutputs.price_per_bushel ||
    soybeanOutputs.price_per_bushel > soybeanRanges.price_per_bushel[1]
  ) {
    return getString('invalidSoybeanPriceMsg', language);
  }
};

export const displayPriceOrBar = (value: number, endString: string) => {
  const rounded = Math.round(value);
  if (rounded === 0) {
    return '--';
  }
  if (rounded < 0) {
    return `($${Math.round(Math.abs(value)).toLocaleString()}) ${endString}`;
  }
  return `$${Math.round(value).toLocaleString()} ${endString}`;
};
