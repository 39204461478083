import React, { useState, useRef } from 'react';
import cls from 'classnames';
import { string, func } from 'prop-types';
import { Button, Container, Header, Textarea } from 'common';

import styles from './EditableText.module.css';

export const EditableText = ({ className, onChange, text, title }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef(null);
  return (
    <div className={cls(styles.Wrapper, className)}>
      {!isEditing && (
        <>
          <Header
            title={
              <div>
                <span>{title}</span>{' '}
                <Button className={styles.Button} onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
              </div>
            }
            underline="none"
            size="H2"
          />
          {text && (
            <span className={styles.Text}>
              {text.split('\n').map((t) => (
                <span key={t}>
                  {t}
                  <br />
                </span>
              ))}
            </span>
          )}
        </>
      )}
      {isEditing && (
        <>
          <Header
            title={
              <div>
                <span>{title}</span>{' '}
                <Container className={styles.Buttons}>
                  <Button
                    disabled={isSubmitting}
                    primary
                    className={styles.Button}
                    onClick={() => {
                      setIsSubmitting(true);
                      onChange(textareaRef.current.value)
                        .then(() => {
                          setIsEditing(false);
                          setIsSubmitting(false);
                        })
                        .catch((err) => {
                          setIsSubmitting(false);
                          throw err;
                        });
                    }}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className={styles.Button}
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Container>
              </div>
            }
            underline="none"
            size="H2"
          />
          <Textarea
            disabled={isSubmitting}
            className={styles.Input}
            ref={textareaRef}
            defaultValue={text}
          />
        </>
      )}
    </div>
  );
};

EditableText.propTypes = {
  className: string,
  onChange: func.isRequired,
  text: string,
  title: string,
};

EditableText.defaultProps = {
  className: '',
  text: '',
  title: '',
};
