import { SampleType } from 'store/samples/types';

export type SamplePlateSummaryType = {
  created_at: string;
  barcode: string;
  batch_id: number | null;
};

export type TotalCarbonResultsType = {
  total_carbon_pct: number;
  total_inorganic_carbon_pct: number;
  total_organic_carbon_pct: number;
};

export type BulkDensityResultsType = {
  total_dry_mass_105c_g: number;
  total_fine_dry_mass_105c_g: number;
  total_coarse_dry_mass_105c_g: number;
};

export type LabSampleType = SampleType & {
  tags: number[];
  bph?: number;
  ph?: number;
  plate_quadrant?: number;
  notes?: string;
  enabled?: boolean;
  plates_summary?: {
    [plate_type: string]: SamplePlateSummaryType[];
  };
  products_eta?: {
    [product: string]: string | null;
  };
  total_filtered_reads?: number;
  // Well sample attributes
  tag_names?: string[];
  rnd_notes?: string;
  rnd_meta?: { [key: string]: any };
  lab_notes?: string;
  sample_type_repr: string;
  arrived_at_lab?: string;
  blended_at_lab?: string;
  county?: CountyType;
  field?: {
    farm_name: string;
    name: string;
  };
  operation?: {
    id: number;
    name: string;
  };
  // Reducer generated attributes
  operation_name?: string;
  operation_id?: number;
  field_name?: string;
  farm_name?: string;
  // TODO: Keep until further lab app refactor
  status?: SampleType['status'];
  barcode: string;

  m3_rerun_status?: string;
  ph_rerun_status?: string;
  nitrate_rerun_status?: string;
  aa_rerun_status?: string;
  om_rerun_status?: string;
  phosphate_rerun_status?: string;

  is_carbon_analysis?: boolean;
  carbon_processed?: boolean;
  total_carbon_processed?: boolean;
  bulk_density_processed?: boolean;

  total_carbon?: TotalCarbonResultsType;
  bulk_density?: BulkDensityResultsType;

  type: string;
};

export type CountyType = {
  county: string;
  ctfips: string;
  restricted: boolean;
  state: string;
};

export type LabSampleStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  errorMessage: string;
  foundSample?: LabSampleType;
  list: LabSampleType[];
  byBarcode: {
    [key: string]: LabSampleType[];
  };
  byFieldId: {
    [field_id: string]: LabSampleType[];
  };
};

export const REQUEST_FIELD_LAB_SAMPLES = 'REQUEST_FIELD_LAB_SAMPLES';
export const REQUEST_LAB_SAMPLES = 'REQUEST_LAB_SAMPLES';
export const RECEIVE_FIELD_LAB_SAMPLES = 'RECEIVE_FIELD_LAB_SAMPLES';
export const RECEIVE_LAB_SAMPLES = 'RECEIVE_LAB_SAMPLES';
export const RECEIVE_LAB_SAMPLE = 'RECEIVE_LAB_SAMPLE';
export const LAB_SAMPLES_REQUEST_ERROR = 'LAB_SAMPLES_REQUEST_ERROR';

type RequestFieldLabSamplesActionType = {
  type: typeof REQUEST_FIELD_LAB_SAMPLES;
  payload: {
    id: number;
  };
};

type RequestLabSamplesActionType = {
  type: typeof REQUEST_LAB_SAMPLES;
};

type ReceiveFieldLabSamplesActionType = {
  type: typeof RECEIVE_FIELD_LAB_SAMPLES;
  payload: {
    id: number;
    samples: LabSampleType[];
  };
};

type ReceiveLabSamplesActionType = {
  type: typeof RECEIVE_LAB_SAMPLES;
  payload: LabSampleType[];
};

type ReceiveLabSampleActionType = {
  type: typeof RECEIVE_LAB_SAMPLE;
  payload: LabSampleType;
};

type ReceiveErrorLabSamplesType = {
  type: typeof LAB_SAMPLES_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type LabSamplesActionsType =
  | RequestFieldLabSamplesActionType
  | RequestLabSamplesActionType
  | ReceiveFieldLabSamplesActionType
  | ReceiveLabSamplesActionType
  | ReceiveLabSampleActionType
  | ReceiveErrorLabSamplesType;
