import { ACTIVE_USER_ID } from 'constants/auth';
import { getLocalStorage } from 'util/localStorage';
import {
  RECEIVE_ALERT_NAMES,
  RECEIVE_COUNTRIES,
  RECEIVE_LABS,
  RECEIVE_OPERATION_ASSOCIATED_USERS,
  RECEIVE_PATTERN_SAMPLERS,
  RECEIVE_SINGLE_USER,
  RECEIVE_USER_SET,
  REQUEST_USERS,
  SET_USER_FETCHING,
  UserActionType,
  USERS_REQUEST_ERROR,
  UserStateType,
} from './types';

export const initialState: UserStateType = {
  currentUser: null,
  allUsers: [],
  byId: {},
  countries: [],
  lab_locations: [],
  alertNames: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
  summary: {
    users: [],
    page: 1,
    total: 0,
    per_page: 0,
  },
};

export const UserReducer = (state = initialState, action: UserActionType): UserStateType => {
  switch (action?.type) {
    case REQUEST_USERS:
      return {
        ...state,
        isFetching: true,
        isFetchingList: action.payload?.id
          ? state.isFetchingList.concat(Number(action.payload.id))
          : state.isFetchingList,
      };
    case RECEIVE_USER_SET:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        summary: action.payload.summary,
      };
    case RECEIVE_OPERATION_ASSOCIATED_USERS:
      return {
        ...state,
        allUsers: action.payload.users,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries,
      };
    case RECEIVE_SINGLE_USER:
      return {
        ...state,
        currentUser:
          action.payload?.id === Number(getLocalStorage(ACTIVE_USER_ID))
            ? action.payload
            : state.currentUser,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        hasFetched: true,
        isFetching: false,
        hasFetchedList: action.payload?.id
          ? state.hasFetchedList.concat(Number(action.payload.id))
          : state.hasFetchedList,
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
      };
    case RECEIVE_PATTERN_SAMPLERS:
      return {
        ...state,
        patternSamplers: action.payload.users,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_LABS:
      return {
        ...state,
        lab_locations: action.payload.labs,
      };
    case RECEIVE_ALERT_NAMES:
      return {
        ...state,
        alertNames: Object.keys(action.payload.alertNames).map((key) => ({
          displayName: action.payload.alertNames[key],
          label: action.payload.alertNames[key],
          id: key,
          value: key,
        })),
      };
    case SET_USER_FETCHING:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    case USERS_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
        hasFailedList: action.payload?.id
          ? state.hasFailedList.concat(Number(action.payload.id))
          : state.hasFailedList,
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.id),
      };
    default:
      return state;
  }
};
