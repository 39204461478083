export type RecommendationType = {
  sampling_plan_id?: number; // form submissions only
  recommendation_set_id?: number;
  id: number;
  risk_level: 'high' | 'moderate' | 'low';
  analytic_id: number;
  created_by_id: number;
  recommendation: string;
  default: boolean;
  pattern_default: boolean;
  show_in_ui: boolean;
  updated_at: null | string;
  created_at: string;
};

export type RecommendationsStateType = {
  recommendations: RecommendationType[];
  byPlanId: {
    [id: number]: RecommendationType[];
  };
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const REQUEST_RECOMMENDATIONS = 'REQUEST_RECOMMENDATIONS';
export const RECEIVE_RECOMMENDATIONS = 'RECEIVE_RECOMMENDATIONS';
export const RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS = 'RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS';
export const RECEIVE_DEFAULT_RECOMMENDATIONS = 'RECEIVE_DEFAULT_RECOMMENDATIONS';
export const RECOMMENDATIONS_REQUEST_ERROR = 'RECOMMENDATIONS_REQUEST_ERROR';

type RequestRecommendationsType = {
  type: typeof REQUEST_RECOMMENDATIONS;
};

type ReceiveRecommendationsType = {
  type: typeof RECEIVE_RECOMMENDATIONS;
  payload: {
    recommendations: RecommendationType[];
  };
};

type ReceiveSamplePlanRecommendationsType = {
  type: typeof RECEIVE_SAMPLE_PLAN_RECOMMENDATIONS;
  payload: {
    planId: number;
    recommendations: RecommendationType[];
  };
};

type ReceiveDefaultRecommendationType = {
  type: typeof RECEIVE_DEFAULT_RECOMMENDATIONS;
};

type RecommendationsRequestError = {
  type: typeof RECOMMENDATIONS_REQUEST_ERROR;
};

export type RecommendationsActionsType =
  | RequestRecommendationsType
  | ReceiveRecommendationsType
  | ReceiveSamplePlanRecommendationsType
  | ReceiveDefaultRecommendationType
  | RecommendationsRequestError;
