import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Stack,
  Grid,
  Text,
  Image,
  Paper,
  Center,
  Popover,
  Group,
  NumberInput,
  Tooltip,
} from '@mantine/core';
import { FiAlertTriangle } from 'react-icons/fi';

import { OperationFieldType } from 'store/operation/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getCropImage, getOtherCrops } from 'util/overviewResultsDisplay';
import { CORN, SOYBEANS } from 'constants/variables';
import { getCropPlanRowValues, getLatestCropPlan } from 'util/cropPlans';
import { CatalogType } from 'store/catalogs/types';
import { OPEN_RISKS, OUTSTANDING_RISK_COLOR, SEED, TRAITS } from 'constants/cropPlan';
import { putPostCropTargetYield } from 'store/cropPlans/thunks';
import { RISK_FILL_COLORS } from 'util/mapImageryColors';
import { FieldType } from 'store/fields/types';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getString } from 'strings/translation';

import Styles from './FieldRow.module.css';
import CropPlanChart from './CropPlanChart';
import classNames from 'classnames';

type FieldRowPropsType = {
  field: OperationFieldType;
  fieldGeometry: FieldType | undefined;
  cropType: string;
  hasFailedGeometry: boolean;
  isFetchingGeometry: boolean;
  willFetch: boolean;
  defaultCatalog: CatalogType | null;
};

const FieldRow = ({
  field,
  fieldGeometry,
  hasFailedGeometry,
  isFetchingGeometry,
  willFetch,
  cropType,
  defaultCatalog,
}: FieldRowPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const cropPlan = getLatestCropPlan(fieldGeometry);
  const hasOpenRisks = cropPlan?.outstanding_risks.outstanding_risks_show_in_ui !== undefined;
  const [targetYield, setTargetYield] = useState<number | string>('');
  const [openChart, toggleOpenChart] = useState<boolean>(false);
  const rowValues = getCropPlanRowValues(fieldGeometry);
  const [selectedChartInfo, setSelecteChartInfo] = useState<{
    label: string;
    value: string | number;
  } | null>(null);

  useEffect(() => {
    if (cropPlan) {
      setTargetYield(cropPlan.target_yield_per_acre);
    }
  }, [cropPlan]);

  const setCropTypeOnPlan = async (crop: string, target?: string | number) => {
    dispatch(putPostCropTargetYield(language, field.id, crop, cropPlan?.id, target));
  };

  const addRemoveRecommendedInput = (inputSelected: { label: string; value: string | number }) => {
    if (inputSelected.label === selectedChartInfo?.label) {
      setSelecteChartInfo(null);
    } else {
      setSelecteChartInfo(inputSelected);
      toggleOpenChart(true);
    }
  };

  useEffect(() => {
    if (openChart && selectedChartInfo === null) {
      setSelecteChartInfo(rowValues[0]);
    } else if (!openChart && selectedChartInfo !== null) {
      setSelecteChartInfo(null);
    }
  }, [openChart, selectedChartInfo, rowValues]);

  const getOpenRiskColor = (value: number | string, index: number) => {
    if (hasOpenRisks && index === rowValues.length - 1) {
      if (value > 0) {
        return OUTSTANDING_RISK_COLOR;
      }
      return RISK_FILL_COLORS.LOW_RISK;
    }
    return '';
  };

  if (!fieldGeometry && !isFetchingGeometry && !hasFailedGeometry && !willFetch) {
    return <></>;
  }

  const isEditable = cropPlan && [CORN, SOYBEANS].includes(cropPlan.crop);

  const getDisplay = (rowValue: { label: string; value: string | number }) => {
    if (cropPlan?.outstanding_risks?.outstanding_risks_show_in_ui) {
      // show warning if crop plan has outstanding risks and category is unprotected
      if (rowValue.value === NOT_APPLICABLE && rowValue.label !== OPEN_RISKS) {
        return <FiAlertTriangle color="red" />;
      }
    }
    return (
      <Text truncate="end" size="sm">
        {rowValue.value}
      </Text>
    );
  };
  const getTooltip = (rowValue: { label: string; value: string | number }) => {
    if (cropPlan?.outstanding_risks?.outstanding_risks_show_in_ui) {
      // show warning if crop plan has outstanding risks and category is unprotected
      if (rowValue.value === NOT_APPLICABLE && rowValue.label !== OPEN_RISKS) {
        if ([SEED, TRAITS].includes(rowValue.label)) {
          return getString('seedMissing', language);
        }
        return getString(`${rowValue.label}Missing`, language);
      }
    }
    return rowValue.value;
  };

  return (
    <>
      <Grid gutter="xs" columns={10}>
        <Grid.Col span={2}>
          <Stack
            gap={0}
            className={Styles.Hover}
            justify="center"
            h="3rem"
            onClick={() => toggleOpenChart(!openChart)}
          >
            <Text size="xs" fw={700}>
              {field.farm_name ? `${field.farm_name}: ${field.name}` : field.name}
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={1}>
          <Popover width="auto" trapFocus position="bottom" withArrow shadow="md">
            <Popover.Target>
              <Paper shadow="sm" withBorder className={Styles.CropSelect}>
                <Center h="3rem">
                  <Image h="2rem" w="auto" src={getCropImage(cropPlan?.crop)} />
                </Center>
              </Paper>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap="sm">
                {getOtherCrops(cropType).map((crop) => (
                  <Image
                    key={crop}
                    h="2rem"
                    w="auto"
                    src={getCropImage(crop)}
                    className={Styles.CropImgBox}
                    onClick={() => setCropTypeOnPlan(crop)}
                  />
                ))}
              </Group>
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col span={1}>
          <Paper shadow="sm" className={Styles.CropSelect}>
            <NumberInput
              hideControls
              value={targetYield}
              onBlur={() => setCropTypeOnPlan(cropType || CORN, targetYield)}
              onChange={setTargetYield}
              size="xs"
              classNames={{
                input: Styles.CropInput,
              }}
            />
          </Paper>
        </Grid.Col>
        {rowValues.map((rowVal, index) => (
          <Grid.Col
            span={1}
            key={rowVal.label}
            onClick={() =>
              index !== rowValues.length - 1 &&
              isEditable &&
              cropPlan.crop &&
              addRemoveRecommendedInput(rowVal)
            }
          >
            <Tooltip label={getTooltip(rowVal)} bg="blue" disabled={index === rowValues.length - 1}>
              <Paper
                bg={getOpenRiskColor(rowVal.value, index)}
                shadow={selectedChartInfo?.label === rowVal.label ? 'xl' : 'md'}
                withBorder={selectedChartInfo?.label === rowVal.label}
                className={classNames(
                  Styles.RowValue,
                  selectedChartInfo?.label === rowVal.label ? Styles.Border : undefined,
                )}
                onClick={() =>
                  index === rowValues.length - 1 && isEditable && toggleOpenChart(!openChart)
                }
              >
                {getDisplay(rowVal)}
              </Paper>
            </Tooltip>
          </Grid.Col>
        ))}
      </Grid>
      {fieldGeometry && openChart && cropPlan && (
        <CropPlanChart
          cropType={cropType}
          defaultCatalog={defaultCatalog}
          fieldGeometry={fieldGeometry}
          selectedChartInfo={selectedChartInfo}
          cropPlan={cropPlan}
        />
      )}
    </>
  );
};

export default FieldRow;
