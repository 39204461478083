import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Table, TableCol, TableRow } from 'common';
import { AnalyticType } from 'store/analytics/types';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { getHeatMapForAnalytic, sortHeatMap } from 'util/heatMap';
import { findRecommendationForAnalyticRisk } from 'util/recommendations';
import { getPlanRiskColor } from 'util/results';
import { acToHectares } from 'util/units';
import styles from './HeatList.module.css';

type HeatMapProps = {
  heatMap: HeatMapAnalyticType[];
  analytic: AnalyticType;
  recSet: RecommendationSetType;
};

export const HeatList = ({ heatMap, analytic, recSet }: HeatMapProps) => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  const highRiskList = getHeatMapForAnalytic(heatMap, analytic.id, HIGH_RISK);
  const moderateRiskList = getHeatMapForAnalytic(heatMap, analytic.id, MODERATE_RISK);
  const lowRiskList = getHeatMapForAnalytic(heatMap, analytic.id, LOW_RISK);

  const generateRow = (heatList: HeatMapAnalyticType[]) =>
    sortHeatMap(heatList).map((heat) => (
      <TableRow key={heat.id}>
        <TableCol>{heat.operation_name}</TableCol>
        <TableCol>{heat.farm_name}</TableCol>
        <TableCol>{heat.field_name}</TableCol>
        <TableCol>{Math.round(acToHectares(heat.calculated_area_acres, language))}</TableCol>
        <TableCol>{getString(heat.risk_level, language)}</TableCol>
        <TableCol>
          <Button
            onClick={() =>
              navigate(
                `/results/analytic/${heat.operation_id}/${heat.field_id}/${heat.id}/${analytic.id}`,
              )
            }
          >
            {getString('viewResults', language)}
          </Button>
        </TableCol>
      </TableRow>
    ));

  const generateRecBubble = (risk: string, title: string) => {
    const rec = findRecommendationForAnalyticRisk(recSet?.recommendations || [], analytic.id, risk);
    return (
      <div className={styles.RecBubble} style={{ background: getPlanRiskColor(risk) }}>
        <div>
          <b>{getString(title, language)}</b>
        </div>
        {rec && <div>{rec.recommendation}</div>}
      </div>
    );
  };

  const generateRiskBlock = (risk: string, label: string, heatList: HeatMapAnalyticType[]) => {
    return (
      Boolean(heatList.length) && (
        <>
          {generateRecBubble(risk, label)}
          {generateRow(heatList)}
        </>
      )
    );
  };

  return (
    <div>
      <Table>
        <TableRow header className={styles.StickyHeader}>
          <TableCol>{getString('account', language)}</TableCol>
          <TableCol>{getString('farm', language)}</TableCol>
          <TableCol>{getString('field', language)}</TableCol>
          <TableCol>{getString('totalAcres', language)}</TableCol>
          <TableCol>{getString('fieldRisk', language)}</TableCol>
          <TableCol />
        </TableRow>
        {generateRiskBlock(HIGH_RISK, 'highRiskFields', highRiskList)}
        {generateRiskBlock(MODERATE_RISK, 'moderateRiskFields', moderateRiskList)}
        {generateRiskBlock(LOW_RISK, 'lowRiskFields', lowRiskList)}
      </Table>
    </div>
  );
};
