import React from 'react';
import { Switch } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type Props = {
  isChecked: boolean;
  onChange: (value: boolean) => void;
};

export const DcpPointsToggle = ({ isChecked, onChange }: Props) => {
  const language = useBroswerLanguage();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.checked);
  };

  return (
    <Switch
      checked={isChecked}
      label={getString('showDcpPoints', language)}
      onChange={handleChange}
    />
  );
};
