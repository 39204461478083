import {
  FieldEventType,
  FieldEventActionType,
  REQUEST_FIELD_EVENTS,
  RECEIVE_FIELD_EVENT,
  RECEIVE_FIELD_EVENTS,
  FIELD_EVENT_REQUEST_ERROR,
} from './types';

export const requestFieldEvents = {
  type: REQUEST_FIELD_EVENTS,
};

export const receiveFieldEvent = (
  event: FieldEventType,
  oldFieldId: number,
): FieldEventActionType => ({
  type: RECEIVE_FIELD_EVENT,
  payload: { event, oldFieldId },
});

export const receiveFieldEvents = (id: number, events: FieldEventType[]): FieldEventActionType => ({
  type: RECEIVE_FIELD_EVENTS,
  payload: { id, events },
});

export const fieldEventRequestError = { type: FIELD_EVENT_REQUEST_ERROR };
