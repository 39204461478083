import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';

import { AdjustmentsType } from './types';

export const getAllOpenTransactions = (
  search: string,
  page: number = 1,
  selectedRole: string | undefined,
) =>
  requestGet(
    `${SERVICE_URL}/open_transactions/?page=${page}${
      search.length > 0 ? `&search=${search}` : ''
    }&role=${selectedRole}`,
  ).then(handleJsonResponse);

export const getAllApprovedPayments = (
  search: string,
  page: number = 1,
  selectedRole: string | undefined,
) =>
  requestGet(
    `${SERVICE_URL}/payments/?page=${page}${
      search.length > 0 ? `&search=${search}` : ''
    }&role=${selectedRole}`,
  ).then(handleJsonResponse);

export const getOpenTransaction = async (id: number, planIds: number[]) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/open_transaction/${id}`, {
      body: {
        plan_ids: planIds,
      },
    }),
  );

export const getApprovedPayment = (uuid: string) =>
  requestGet(`${SERVICE_URL}/payment/${uuid}`).then(handleJsonResponse);

export const postPaymentToApprove = (
  user_id: number,
  is_qb: boolean,
  included_plans: number[],
  payment_term?: string,
) =>
  requestPost(`${SERVICE_URL}/payment/`, {
    body: {
      user_id,
      is_qb,
      included_plans,
      payment_term,
    },
  }).then(handleJsonResponse);

export const postQuickbooksToken = async (realm_id: string, auth_token: string) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/quickbooks/authorization/${realm_id}/${auth_token}`),
  );

export const postLoginToQuickbooks = async () =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/quickbooks/login`));

export const postAdjustment = (body: AdjustmentsType) =>
  requestPost(`${SERVICE_URL}/adjustment/`, { body }).then(handleJsonResponse);

export const putPaymentAsSettled = (uuid: string) =>
  requestPut(`${SERVICE_URL}/settle_payment/${uuid}`).then(handleJsonResponse);
