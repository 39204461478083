import React from 'react';
import { Button } from 'common';
import { useNavigate } from 'react-router-dom';

import styles from './IconButton.module.css';

interface Props {
  icon: React.ReactNode;
  text: string;
  to: string;
}
const IconButton = ({ icon, text, to }: Props) => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(to)} className={styles.Wrapper}>
      <div className={styles.Container}>
        <div className={styles.Icon}>{icon}</div>
        <div className={styles.Text}>{text}</div>
      </div>
    </Button>
  );
};

export default IconButton;
