import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Button, Header, Input, Label, Toggle } from 'common';
import { createFeatureFlag, editFeatureFlag, getAllFeatureFlags } from 'store/featureFlags/thunks';
import { FeatureFlagType } from 'store/featureFlags/types';
import showToast from 'actions/toastActions';

import styles from './Container.module.css';

const FeatureFlagContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [idList, setIdList] = useState<number[]>([]);
  const [enabledForAll, setEnabledForAll] = useState(false);

  const featureFlags = useSelector((state: any) => state.featureFlags);

  const { flags, hasFetched, isFetching } = featureFlags;

  useEffect(() => {
    if (!hasFetched && !flags.length) {
      dispatch(getAllFeatureFlags());
    }
  }, [dispatch, flags.length, hasFetched]);

  useEffect(() => {
    if (hasFetched) {
      const matchingFlag = flags.find((flag: FeatureFlagType) => flag.id === Number(id));
      if (matchingFlag) {
        setDescription(matchingFlag.description);
        setEnabledForAll(matchingFlag.enabled_for_all);
        setIdList(matchingFlag.id_whitelist);
        setName(matchingFlag.name);
      }
    }
  }, [flags, hasFetched, id]);

  const isNew = location.pathname.includes('/create');

  const createInputHandler =
    (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setter(event.target.value);

  const handleToggleChange = () => setEnabledForAll(!enabledForAll);

  const goBackToList = () => navigate('/product/feature_flags');

  const submit = async () => {
    const requestBody = {
      name,
      description,
      id_whitelist: idList,
      enabled_for_all: enabledForAll,
    };
    try {
      await dispatch(
        isNew ? createFeatureFlag(requestBody) : editFeatureFlag(Number(id), requestBody),
      );
      showToast(`Successfully ${isNew ? 'created' : 'edited'} feature flag: ${name}`);
      goBackToList();
    } catch (error) {
      showToast('Unable to submit. Try again later.');
    }
  };

  return (
    <>
      <Header title={isNew ? 'New Feature Flag' : 'Feature Flag Management'}>
        <span>
          <Button onClick={goBackToList}>Back to List</Button>
          <Button className={styles.Submit} disabled={isFetching} onClick={submit} primary>
            Submit
          </Button>
        </span>
      </Header>
      <div className={styles.Form}>
        <Label label="Name">
          <Input autoComplete="off" onChange={createInputHandler(setName)} value={name} />
        </Label>
        <Label label="Description">
          <Input
            autoComplete="off"
            onChange={createInputHandler(setDescription)}
            value={description}
          />
        </Label>
        <Label label="Enabled for All?">
          <Toggle checked={enabledForAll} onChange={handleToggleChange} />
        </Label>
      </div>
    </>
  );
};

export default FeatureFlagContainer;
