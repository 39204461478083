import {
  handleJsonResponse,
  requestGet,
  SERVICE_URL,
  requestPut,
  requestPost,
  requestDelete,
  GCPFileUpload,
} from 'util/request';
import { TaxonomyInterface, PaginatedResponseInterface } from 'util/generalTypes';
import { AnalyticType, AnalyticImageType } from './types';

export const requestEnabledAnalytics = (slim = true) =>
  requestGet(`${SERVICE_URL}/analytics/?slim=${slim}`).then(handleJsonResponse);

export const requestAllAnalytics = (slim = true) =>
  requestGet(`${SERVICE_URL}/analytics/?all=${true}&slim=${slim}`).then(handleJsonResponse);

export const requestGetSingleAnalytic = (id: number) =>
  requestGet(`${SERVICE_URL}/analytic/${id}`).then(handleJsonResponse);

export const requestAddTaxonomyToAnalytics = (
  analyticId: number,
  internal_taxids: number[],
): Promise<{ taxonomies: TaxonomyInterface[] }> =>
  requestPut(`${SERVICE_URL}/analytic_taxonomy/${analyticId}`, {
    body: {
      internal_taxids,
    },
  }).then(handleJsonResponse);

export const getTaxonomy = (
  taxId: string,
  taxRank: string,
): Promise<PaginatedResponseInterface<TaxonomyInterface>> =>
  requestGet(`${SERVICE_URL}/taxonomies/${taxId}?rank=${taxRank}`).then(handleJsonResponse);

export const requestUpdateAnalytic = (id: number, body: Partial<AnalyticType>) =>
  requestPut(`${SERVICE_URL}/analytic/${id}`, {
    body,
  }).then(handleJsonResponse);

export const analyticUploadImage = (
  analytic: AnalyticType,
  analyticImage: AnalyticImageType,
  file: File,
) =>
  requestPost(`${SERVICE_URL}/analytic_upload_image/`, {
    body: {
      analytic_id: analytic.id,
      file_name: file.name,
      source: analyticImage.source,
      alt_text: analyticImage.alt_text,
    },
  }).then(handleJsonResponse);

export const uploadAnalyticImageToGCP = ({
  analyticImage,
  file,
  onProgressUpdate,
  onCompleted,
  onError,
}: {
  analyticImage: AnalyticImageType;
  file: File;
  onProgressUpdate: (percent: number) => void;
  onCompleted: () => void;
  onError: () => void;
}) => {
  const fileNameArray = file.name.split('.');
  const ext = fileNameArray[fileNameArray.length - 1];
  const contentType = `image/${ext}`;
  const progressHandler = (event: ProgressEvent) => {
    const percent = ((event.loaded * 100) / event.total).toFixed(1);
    onProgressUpdate(Number(percent));
  };

  const xhr = GCPFileUpload({
    uploadUrl: analyticImage.upload_url,
    file,
    onProgressUpdate: progressHandler,
    onCompleted,
    onError,
    onAbort: onError,
    headerValue: contentType,
  });
  return xhr;
};

export const markAnalyticImageAsUploaded = (analyticImage: AnalyticImageType) =>
  requestPut(`${SERVICE_URL}/analytic_upload_image/${analyticImage.id}`, {
    body: {
      uploaded: true,
    },
  }).then(handleJsonResponse);

export const updateAnalyticImage = (analyticImage: AnalyticImageType) =>
  requestPut(`${SERVICE_URL}/analytic_upload_image/${analyticImage.id}`, {
    body: analyticImage,
  }).then(handleJsonResponse);

export const deleteAnalyticImage = (analyticImage: AnalyticImageType) =>
  requestDelete(`${SERVICE_URL}/analytic_upload_image/${analyticImage.id}`).then(
    handleJsonResponse,
  );

export const putAnalyticCrop = async (
  analytic_ids: { [analyticId: number]: number },
  crop: string,
  display_category: string,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/analytic_results_order/`, {
      body: {
        analytic_ids,
        crop,
        display_category,
      },
    }),
  );

export const requestGetAnalyticCropConfig = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/analytic_results_order/`));
