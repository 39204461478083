import {
  Map,
  AnySourceData,
  AnyLayer,
  CustomSourceInterface,
  CustomLayerInterface,
  MapMouseEvent,
  EventData,
  MapboxGeoJSONFeature,
  GeoJSONSource,
} from 'mapbox-gl';

export const removeMapLayer = (mapRef: any, layerId: string) => {
  if (mapRef.current?.getLayer(layerId)) {
    mapRef.current?.removeLayer(layerId);
    mapRef.current?.removeSource(layerId);
  }
};

/**
 * Add a mapbox source and child layers to a map if the source and layers don't already exist.
 * @param options See docs below
 */
export const addSourceAndLayers = (options: {
  map: Map;
  /** Must be unique within each map */
  sourceId: string;
  sourceOptions: Exclude<
    AnySourceData,
    CustomSourceInterface<HTMLImageElement | ImageData | ImageBitmap>
  >;
  layers: Array<
    Exclude<AnyLayer, CustomLayerInterface> & {
      /**
       * The ID of an existing layer to insert the new layer before, resulting in the new layer
       * appearing visually beneath the existing layer.
       *
       * See [beforeId docs](https://docs.mapbox.com/mapbox-gl-js/api/map/#map#addlayer).
       */
      beforeId?: string;
      onClick?: (
        evt: MapMouseEvent & {
          features?: MapboxGeoJSONFeature[];
        } & EventData,
      ) => void;
    }
  >;
}) => {
  const { map, sourceId, sourceOptions, layers } = options;

  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, sourceOptions);
  }

  layers.forEach((layer) => {
    if (!map.getLayer(layer.id)) {
      map.addLayer(
        {
          ...layer,
          source: sourceId,
        } as AnyLayer,
        layer.beforeId,
      );

      if (layer.onClick) {
        map.on('click', layer.id, layer.onClick);
      }
    }
  });
};

export const toggleLayerVisibility = (map: Map, layerId: string, visible: boolean) => {
  if (map.getLayer(layerId)) {
    map.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none');
  }
};

export const setSourceData = (
  map: Map,
  id: string,
  data: string | GeoJSON.FeatureCollection | GeoJSON.Feature,
): void => {
  const source = map.getSource(id) as GeoJSONSource | undefined;

  if (source) {
    source.setData(data);
  } else {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    const newSource = map.getSource(id) as GeoJSONSource | undefined;

    newSource?.setData(data);
  }
};

export default removeMapLayer;
