import { SelectType } from 'util/generalTypes';

export const REQUEST_SALES_FLOW_META = 'REQUEST_SALES_FLOW_META';
export const RECEIVE_SALES_FLOW_META = 'RECEIVE_SALES_FLOW_META';
export const SALES_FLOW_REQUEST_ERROR = 'SALES_FLOW_REQUEST_ERROR';

export const REQUEST_OPERATION_CROP_INFO = 'REQUEST_OPERATION_CROP_INFO';
export const RECEIVE_OPERATION_CROP_INFO = 'RECEIVE_OPERATION_CROP_INFO';

export interface MetaType {
  corn: {
    [key: string]: SelectType[];
  };
  soybeans: {
    [key: string]: SelectType[];
  };
}

export interface OperationCropReportType {
  summary: {
    total_acres: number;
    average_boost_yield: number;
    average_optimize_spend: number;
    average_total_return: number;
    average_acre_revenue: number;
    base_crop_protection: number;
    crop_row_total_return: number;
  };
  crop_plans: CropReportType[];
}

export type SalesFlowStateType = {
  meta: MetaType | null;
  byOperationId: {
    [key: number]: OperationCropReportType;
  };
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export interface SalesFlowStepType {
  title: string;
  index: number;
  bottomButtonTitle: string;
}

export interface CropInfoType {
  acreage: number | undefined;
  target_yield: number | undefined;
  price_per_bushel: number | undefined;
  traits: string | null;
  seed_treatment: string | null;
  in_furrow: string | null;
  foliar_fungicide: boolean;
  seed_disease_risk: string | null;
  crop: string;
}

export interface OperationDetailsType {
  isOperationCreated: boolean;
  operation_id: number | null;
  operationName?: string;
  ctfips: string | null;
  corn: CropInfoType;
  soybeans: CropInfoType;
}

export interface OperationDetailsPostType {
  operation_id: number;
  ctfips: string;
  crop_plans: CropInfoType[];
}

export interface CountyType {
  gid: number;
  state: string;
  county: string;
  stfips: string;
  ctfips: string;
  restricted: boolean;
}

export interface SalesFlowMetaType {
  corn: {
    groupings: string[];
    in_furrow: SelectType[];
    seed_disease_risks: SelectType[];
    seed_treatment: SelectType[];
    traits: SelectType[];
  };
  soybeans: {
    groupings: string[];
    in_furrow: SelectType[];
    seed_disease_risks: SelectType[];
    seed_treatment: SelectType[];
    traits: SelectType[];
  };
}

export interface CropGroupingType {
  [key: string]: string;
}

export interface CropSummaryType {
  boost_yield: number;
  optimize_spend: number;
  total_return: number;
}

export interface CropReportType {
  id: number;
  analysis: CropAnalysisType[];
  county: CountyType;
  operation: {
    id: number;
    name: string;
  };
  ctfips: string;
  acreage: number;
  active: boolean;
  crop: string;
  foliar_fungicide: boolean;
  in_furrow: string;
  operation_id: number;
  price_per_bushel: number;
  seed_disease_risk: string;
  seed_treatment: string;
  target_yield: number;
  traits: string;
  base_revenue: number;
  summary: CropSummaryType;
}

export interface AnaylticMiniType {
  id: number;
  name: string;
  category: string;
  description: string;
  environment_and_weather_notes: string;
  potential_yield_impact_notes: string;
}

export interface CropAnalysisType {
  id: number;
  acreage: number;
  additional_input_spend: number;
  crop: string;
  estimated_yield_impact: number;
  grouping_id: number;
  grouping_name: string;
  high_risk_acres: number;
  low_risk_acres: number;
  operation_crop_plan_id: number;
  over_protection_cost_per_acre: number;
  input_savings: number;
  protection_level: string;
  total_return_per_acre: number;
  yield_loss_per_acre: number;
  optimize_spend: number;
  analysisTotalReturn: number;
  yield_at_risk: number;
  boost_yield: number;
  yield_loss: number;
  associated_analytics: AnaylticMiniType[];
}

export interface OperationCropsSummaryType {
  average_boost_yield: number;
  average_optimize_spend: number;
  average_total_return: number;
  average_acre_revenue: number;
  base_crop_protection: number;
  total_acres: number;
}

interface RecieveSalesFlowMetaAction {
  type: typeof RECEIVE_SALES_FLOW_META;
  payload: SalesFlowMetaType;
}

interface RequestSalesFlowMetaAction {
  type: typeof REQUEST_SALES_FLOW_META;
}

interface SalesFlowRequestError {
  type: typeof SALES_FLOW_REQUEST_ERROR;
}

interface RequestOperationCropInfo {
  type: typeof REQUEST_OPERATION_CROP_INFO;
  payload: {
    operationId: number;
  };
}

interface ReceiveOperationCropInfo {
  type: typeof RECEIVE_OPERATION_CROP_INFO;
  payload: {
    operationId: number;
    cropInfo: {
      crop_plans: CropReportType[];
      summary: OperationCropsSummaryType;
    };
  };
}

export type SalesFlowActionType =
  | RecieveSalesFlowMetaAction
  | RequestSalesFlowMetaAction
  | SalesFlowRequestError
  | RequestOperationCropInfo
  | ReceiveOperationCropInfo;
