import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Center, Button, Input, Tooltip, Text } from '@mantine/core';
import { getLocalStorage } from 'util/localStorage';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BillingPreference, CreateFarmAccountRequestType } from 'store/user/types';
import { requestCreateFarmAccount } from 'store/operation/requests';
import { USER_AGENCY_ID } from 'store/agencies/types';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styles from './RegisterUser.module.css';

const RegisterUser = () => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const isAdminOrSuper = useSelector(
    (state: RootState) => userIsSuperAdmin(state) || userIsAdmin(state),
  );

  const [error, setError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [operationDetails, setOperationDetails] = useState({
    name: '',
    billing_preference: BillingPreference.Self,
  });

  const handleSubmitUser = async () => {
    setError('');
    const { billing_preference, name } = operationDetails;

    const data: CreateFarmAccountRequestType = {
      name: name.trim(),
      billing_preference,
    };

    if (!getLocalStorage(USER_AGENCY_ID) || isAdminOrSuper) {
      data.billing_preference = BillingPreference.Skip;
    }

    if (!data.name) {
      setError(getString('fillOutRequiredFields', language));
      setSubmitting(false);
      return;
    }

    try {
      setSubmitting(false);
      const { id } = await requestCreateFarmAccount(data);
      navigate(`/orders/${id}`);
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
    }
  };

  return (
    <Center maw="100vw" h="60vh">
      <Stack>
        <Input.Wrapper
          label={
            <Tooltip label={getString('whatsTheNameOfAccountTooltip', language)}>
              <Text>{getString('whatsTheNameOfAccount', language)}</Text>
            </Tooltip>
          }
        >
          <Input
            className={styles.InputWidth}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOperationDetails((old) => ({ ...old, name: e.target.value }))
            }
            value={operationDetails.name}
            data-test-id="account-name"
          />
        </Input.Wrapper>
        {error && (
          <Text size="sm" fs="italic" className={styles.Error}>
            *{error}
          </Text>
        )}
        <Button
          disabled={isSubmitting}
          data-test-id="create-account-button"
          onClick={handleSubmitUser}
          className={styles.SubmitButton}
        >
          {getString('createAccount', language)}
        </Button>
      </Stack>
    </Center>
  );
};

export default RegisterUser;
