import {
  RECEIVE_ATTRIBUTE,
  RECEIVE_ATTRIBUTES,
  REQUEST_ATTRIBUTES,
  ATTRIBUTES_REQUEST_ERROR,
  AttributeType,
} from './types';

export const requestAttributes = { type: REQUEST_ATTRIBUTES };

export const receiveAttribute = (attribute: AttributeType) => ({
  type: RECEIVE_ATTRIBUTE,
  payload: { attribute },
});

export const receiveAttributes = (attributes: AttributeType[]) => ({
  type: RECEIVE_ATTRIBUTES,
  payload: { attributes },
});

export const attributeRequestError = (message: string) => ({
  type: ATTRIBUTES_REQUEST_ERROR,
  payload: { message },
});
