import { Dispatch } from 'react';
import {
  refreshToken as refreshJwtToken,
  requestLogin,
  requestLogout,
} from 'requesters/authenticationRequester';
import { setLocalStorage } from 'util/localStorage';
import { ACCESS_TOKEN, ACTIVE_USER_ID, REFRESH_TOKEN } from 'constants/auth';
import { AppThunk } from 'store';
import { getUser } from 'store/user/thunks';
import { getRealsToDollars } from 'store/currencyConversion/thunks';
import { requestVerifyUserPasswordless } from 'store/user/requests';
import { LOGIN } from 'constants/firebase';

const AuthStore = {
  isLoggedIn: false,

  setLoggedIn(bool: boolean) {
    AuthStore.isLoggedIn = bool;
  },
  async refresh(errorCall: Function) {
    try {
      const response = await refreshJwtToken();
      setLocalStorage(ACCESS_TOKEN, response.access_token);
    } catch (err) {
      await AuthStore.logout(() => errorCall());
    }
  },
  async login(
    data: { email: string; password: string },
    dispatch: Dispatch<AppThunk>,
    handleError: Function,
    firebase?: any,
  ) {
    try {
      const response = await requestLogin(data);
      const { access_token: accessToken, id, refresh_token: refreshToken } = response;
      setLocalStorage(ACCESS_TOKEN, accessToken);
      setLocalStorage(ACTIVE_USER_ID, id);
      setLocalStorage(REFRESH_TOKEN, refreshToken);
      const user = await getUser(id);
      await dispatch(user);
      // re-enable with rest of PROD-2950
      // if (user.user_locale === 'pt') {
      //   dispatch(getRealsToDollars())
      // }
      getRealsToDollars();
      firebase?.logEvent(LOGIN, { method: 'password' });
      const params = new URLSearchParams(window.location.search);
      const referrer = params.get('referrer');
      AuthStore.isLoggedIn = true;
      return referrer || '/';
    } catch (err) {
      handleError();
    }
  },
  async loginWithToken(
    data: { email: string; code: string },
    dispatch: Dispatch<AppThunk>,
    handleError: Function,
    firebase?: any,
  ) {
    try {
      const response = await requestVerifyUserPasswordless(data);
      const { access_token: accessToken, id, refresh_token: refreshToken } = response;
      setLocalStorage(ACCESS_TOKEN, accessToken);
      setLocalStorage(ACTIVE_USER_ID, id);
      setLocalStorage(REFRESH_TOKEN, refreshToken);
      const user = await getUser(id);
      await dispatch(user);
      getRealsToDollars();
      firebase?.logEvent(LOGIN, { method: 'email' });
      const params = new URLSearchParams(window.location.search);
      const referrer = params.get('referrer');
      AuthStore.isLoggedIn = true;
      return referrer || '/';
    } catch (err) {
      handleError();
    }
  },
  async logout(callback?: Function) {
    try {
      await requestLogout();
    } catch (err) {
      // Finish clearing localstorage either way
    }
    localStorage.clear();
    AuthStore.isLoggedIn = false;
    if (callback) {
      callback();
    }
    window.location.href = '/auth/login';
  },
};

export default AuthStore;
