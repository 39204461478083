import { handleJsonResponse, requestGet, requestPost, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { OperationDetailsPostType } from './types';

export const requestExistingOperationCropPlans = async (operationId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/operation_crop_plans/${operationId}`));

export const requestCountySearch = async (county?: string) =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/county_search/?${queryStringify({ county })}`),
  );

export const requestSalesFlowData = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/operation_crop_plans_static_data/`));

export const requestPostOperationCropInfo = async (body: OperationDetailsPostType) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/operation_crop_plans/`, { body }));
