import React, { ReactNode } from 'react';
import { Container, Header } from 'common';
import { PlateUploadedFileType } from 'store/plates/types';
import { BatchUploadedFileType } from 'store/batches/types';
import { FilesTableContent } from './Content';

type UploadFileType = PlateUploadedFileType | BatchUploadedFileType;

type FilesTablePropsType = {
  files: UploadFileType[] | undefined;
  title: string;
  children?: ReactNode;
};

export const FilesTable = ({ files, title, children }: FilesTablePropsType) => {
  return (
    <Container vertical>
      <Header title={title} underline="none" size="H2">
        {children}
      </Header>
      <FilesTableContent files={files} />
    </Container>
  );
};
