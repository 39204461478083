import { RISK_FILL_COLORS } from 'util/mapImageryColors';

export const CLU_LATITUDE_BUFFER = 1.6;
export const CLU_LONGITUTE_BUFFER = 1;
export const CLU_ZOOM_THRESHOLD = 11.5;
export const CLU_ZOOM_BUFFER = 0.9;

type LayerType = {
  id: string;
  type: string;
  source: any;
  paint: any;
  filter: any[];
};

export const cluLayers: LayerType[] = [
  {
    id: 'clus-line-unselected',
    type: 'line',
    source: {},
    paint: { 'line-color': RISK_FILL_COLORS.MODERATE_RISK },
    filter: ['all'],
  },
  {
    id: 'clus-fill-unselected',
    type: 'fill',
    source: {},
    paint: { 'fill-color': RISK_FILL_COLORS.MODERATE_RISK, 'fill-opacity': 0.1 },
    filter: ['all'],
  },
  {
    id: 'clus-fill-selected',
    type: 'fill',
    source: {},
    paint: { 'fill-color': RISK_FILL_COLORS.MODERATE_RISK, 'fill-opacity': 1 },
    filter: ['all'],
  },
];
