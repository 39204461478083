export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const ACTIVE_OPERATION_ID = 'ACTIVE_OPERATION_ID';
export const ACTIVE_HEATMAP_AGENCY_ID = 'ACTIVE_HEATMAP_AGENCY_ID';
export const ACTIVE_USER_ID = 'ACTIVE_USER_ID';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const JD_CONNECT_CODE = 'JD_CONNECT_CODE';
export const JD_LOGOUT_URL = 'https://signin.johndeere.com/login/signout';
export const SEARCHED_USER_OR_AGENCY = 'SEARCHED_USER_OR_AGENCY';
export const USER_TRANSACTION_ID = 'USER_TRANSACTION_ID';
export const ADMIN = 'admin';
export const SAMPLER = 'sampler';
export const OPERATOR = 'operator';
export const AGENCY_ADMIN = 'agency_admin';
export const SUPER_ADMIN = 'superadmin';

// Check token for authenticity at this interval
export const REFRESH_INTERVAL = 58 * 60 * 1000;

export enum LOADING_FETCHES {
  NOT_STARTED = 'not_started',
  LOADING = 'loading',
  FINISHED = 'finished',
}

export const PAYMENT_FILTER_OPTIONS = [AGENCY_ADMIN, SAMPLER, OPERATOR, ADMIN, SUPER_ADMIN];

export const PASSWORD = 'password';
export const EMAIL = 'email';
export const CELL = 'cell';
