import React from 'react';
import { Stack } from '@mantine/core';
import { FaChartLine } from 'react-icons/fa';
import { FaMagnifyingGlassLocation } from 'react-icons/fa6';
import { TbReportMoney } from 'react-icons/tb';
import { getString } from 'strings/translation';
import { currencyFormatFromLanguage, getAcreageUnitFromLang } from 'util/units';

import styles from './Compaction.module.css';
import Pitch from './Pitch';
import { APPROX_TILLAGE_COSTS } from 'constants/results';

type Props = {
  language: string;
};

const Pitches = ({ language }: Props) => {
  const acresUnits = getAcreageUnitFromLang(language);
  const lowApproxCost = currencyFormatFromLanguage(APPROX_TILLAGE_COSTS.low, language, 0);
  const highApproxCost = currencyFormatFromLanguage(APPROX_TILLAGE_COSTS.high, language, 0);
  const costRangeText = `${lowApproxCost}-${highApproxCost}`;

  return (
    <Stack component="ul" className={styles.PitchList} gap="xs" align="flex-start" mx="auto">
      <Pitch
        icon={<FaChartLine />}
        title={getString('boostYield', language)}
        description={getString('boostYieldPitch', language)}
      />
      <Pitch
        icon={<FaMagnifyingGlassLocation />}
        title={getString('understandCompaction', language)}
        description={getString('understandCompactionPitch', language)}
      />
      <Pitch
        icon={<TbReportMoney />}
        title={getString('saveCostsByTilling', language)}
        description={`${getString('tillageCosts', language)} ${costRangeText} / ${acresUnits}`}
      />
    </Stack>
  );
};

export default Pitches;
