import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Spinner, Header } from 'common';
import { RootState } from 'store';
import showToast from 'actions/toastActions';
import { getBatch } from 'store/batches/thunks';
import { AMPLICON, GENOMIC } from 'constants/analysis';

import { ActionsDropdown } from './Actions/ActionsDropdown';
import { SourcePlates } from './Sections/BatchPlates/SourcePlates';
import { BatchResults } from './Sections/RelatedDocuments/BatchResults';
import { RunPlanned } from './Sections/RunPlanned/RunPlanned';
import { SampleSheet } from './Sections/SampleSheet/SampleSheet';
import styles from './Container.module.css';

const BatchDetailsContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { batch, isFetching } = useSelector((state: RootState) => ({
    batch: id && state.batches.byId[id],
    isFetching: state.batches.isFetching,
  }));

  useEffect(() => {
    if (id) {
      try {
        dispatch(getBatch(Number(id)));
      } catch (e) {
        showToast(e.message, 'error');
      }
    }
  }, [dispatch, id]);

  return isFetching || !batch ? (
    <div className={styles.Wrapper}>
      <Spinner />
    </div>
  ) : (
    <div className={styles.Wrapper}>
      <Header
        title={
          <div className={styles.Header}>
            <div className={styles.Name}>{`B${batch.id}`}</div>
            <div className={styles.Info}>
              <div className={styles.Type}>{getString('batch', language)}</div>
            </div>
          </div>
        }
      >
        {[AMPLICON, GENOMIC].includes(batch.type) && <SampleSheet batch={batch} />}
        <ActionsDropdown batch={batch} />
      </Header>
      <div className={styles.Content}>
        <div className={styles.Row}>
          <div className={styles.Column}>
            {batch.plates.length > 0 && <SourcePlates batch={batch} />}
          </div>
          <div className={styles.Column}>
            <RunPlanned batch={batch} />
            <BatchResults batch={batch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDetailsContainer;
