import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';
import { Table, Tooltip, Modal, Text, Group, Button, Space } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { Agency } from 'store/agencies/types';
import styles from './ManageAgencyHierarchy.module.css';
import { joinStrings } from 'util/stringUtils';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { updateAgencyDetails } from 'store/agencies/requests';
import { useDispatch } from 'react-redux';
import { getAgency } from 'store/agencies/thunks';
import { SuperAdminAccess } from 'common';
import showToast from 'actions/toastActions';

type PropTypes = {
  agency: Agency;
  parentAgency: Agency;
};

const ManageAgencyHierarchyRow = ({ agency, parentAgency }: PropTypes) => {
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [isDisplayExpanded, toggleDisplayExpanded] = useState(false);
  const [removeAgencyModalOpened, toggleRemoveAgencyModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeAgencyLocation = async () => {
    try {
      setLoading(true);
      await updateAgencyDetails(agency.id, { parent_agency_id: null });
      dispatch(getAgency(parentAgency.id));
      toggleRemoveAgencyModalOpened(false);
      showToast(getString('agencyUpdatedSuccessMsg', language));
    } catch (error) {
      showToast(getString('updatingAgencyErrorMsg', language), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Table.Tbody key={agency.id}>
      <Modal
        opened={removeAgencyModalOpened}
        onClose={() => toggleRemoveAgencyModalOpened(false)}
        title={`${getString('remove', language)} ${agency.name} ${getString('from', language)} ${parentAgency.name}?`}
        centered
        size="lg"
      >
        <Text>{getString('removeAgencyLocationWarning', language)}</Text>
        <Space h="xl" />
        <Group justify="flex-end">
          <Button color="darkRed" onClick={removeAgencyLocation} disabled={loading}>
            {getString('remove', language)}
          </Button>
        </Group>
      </Modal>
      <Table.Tr key={agency.id} className={styles.PaddingLeft}>
        <Table.Td className={styles.AgencyName}>
          <span onClick={() => navigate(`/agencies/${agency.id}`)}>{agency.name}</span>
        </Table.Td>
        <Table.Td>
          {joinStrings([agency.primary_user?.first_name, agency.primary_user.last_name], ' ')}
        </Table.Td>
        <Table.Td>{agency.primary_user?.email}</Table.Td>
        <Table.Td>{agency.users?.length || 0}</Table.Td>
        <Table.Td>{agency.child_agencies?.length || 0}</Table.Td>
        <SuperAdminAccess>
          <Table.Td>
            <Tooltip
              color="blue"
              label={getString('removeAgencyLocation', language)}
              onClick={() => toggleRemoveAgencyModalOpened(true)}
            >
              <span>
                <FiX color="red" />
              </span>
            </Tooltip>
          </Table.Td>
        </SuperAdminAccess>
        <Table.Td>
          {agency.users?.length > 1 && (
            <div
              className={styles.ChevronDiv}
              onClick={() => toggleDisplayExpanded(!isDisplayExpanded)}
            >
              {isDisplayExpanded ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          )}
        </Table.Td>
      </Table.Tr>
      {isDisplayExpanded &&
        agency.users
          ?.filter((user) => user.id !== agency.primary_user?.id)
          .map((chi) => (
            <Table.Tr
              style={(theme) => ({
                backgroundColor: theme.colors.grey[9],
              })}
              key={chi.id}
            >
              <Table.Td className={styles.AgencyName} />
              <Table.Td>{joinStrings([chi.first_name, chi.last_name], ' ')}</Table.Td>
              <Table.Td>{chi.email}</Table.Td>
              <Table.Td />
              <Table.Td />
              <Table.Td />
              <SuperAdminAccess>
                <Table.Td />
              </SuperAdminAccess>
            </Table.Tr>
          ))}
    </Table.Tbody>
  );
};

export default ManageAgencyHierarchyRow;
