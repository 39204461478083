import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Checkbox, Group, Table } from '@mantine/core';
import { CollapsibleRow } from 'common';
import { getTotalHistoryAcres, getTotalHistorySamples, isFieldArrived } from 'util/samplingHistory';
import { SamplingDateType } from 'store/samplingHistory/types';
import { formatCalendarMonthDayYear } from 'util/date';
import { SamplingHistoryExpanded } from './SamplingHistoryExpanded';

type SamplingHistoryRowPropsType = {
  sampledDate: SamplingDateType;
};

export const SamplingHistoryRow = ({ sampledDate }: SamplingHistoryRowPropsType) => {
  const language = useBroswerLanguage();
  return (
    <CollapsibleRow collapseComponent={<SamplingHistoryExpanded sampledDate={sampledDate} />}>
      <Table.Td>{formatCalendarMonthDayYear(sampledDate.sampled_date)}</Table.Td>
      <Table.Td ta="right">{sampledDate.sampled_fields.length}</Table.Td>
      <Table.Td ta="right">{getTotalHistoryAcres(sampledDate.sampled_fields)}</Table.Td>
      <Table.Td ta="right">{getTotalHistorySamples(sampledDate.sampled_fields)}</Table.Td>
      <Table.Td ta="right">{getString('viewDetails', language)}</Table.Td>
      <Table.Td ta="right">
        <Group justify="flex-end">
          <Checkbox
            variant="outline"
            readOnly
            checked={isFieldArrived(...sampledDate.sampled_fields)}
          />
        </Group>
      </Table.Td>
    </CollapsibleRow>
  );
};
