import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { TableRow, TableCol, Header, Spinner, Button, SuperAdminAccess } from 'common';
import { RootState } from 'store';
import { getApprovedPaymentByUuid, markPaymentAsSettled } from 'store/payments/thunks';
import { capitalize, capitalizeEveryWord } from 'util/stringUtils';
import { formatCalendarMonthDayYear } from 'util/date';
import { NOT_APPLICABLE } from 'constants/defaultValues';

import ActivityDetailTable from '../common/Tables/ActivityDetailTable';
import AdjustmentsTable from '../common/Tables/AdjustmentsTable';
import UserInfo from './UserInfo';
import SummaryTable from './SummaryTable';
import styles from './Container.module.css';
import SettlementController from './SettlementController';

const PaymentApprovedDetailsContainer = () => {
  const { paymentUuid } = useParams<{ paymentUuid: string }>();
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  const fetchApprovedPayments = useCallback(
    () => paymentUuid && dispatch(getApprovedPaymentByUuid(paymentUuid)),
    [dispatch, paymentUuid],
  );

  const { payment, hasFetched, hasFailed } = useSelector((state: RootState) => ({
    payment: paymentUuid ? state.payments.approved.byId[paymentUuid] : null,
    hasFetched: state.payments.hasFetched,
    hasFailed: state.payments.hasFailed,
  }));

  useEffect(() => {
    if (!payment && !hasFailed) {
      fetchApprovedPayments();
    }
  }, [fetchApprovedPayments, hasFailed, payment]);

  const [showMarkSettled, setShowMarkSettled] = useState(false);

  const handleMarkSettled = useCallback(() => {
    if (paymentUuid) {
      dispatch(markPaymentAsSettled(paymentUuid));
    }
    navigate(`/payments/approved`);
  }, [dispatch, navigate, paymentUuid]);

  const name = payment ? `${payment.id} - ${capitalize(payment.status)}` : NOT_APPLICABLE;
  const title = (
    <div>
      <Link to="/payments/approved" className={styles.OpenPaymentsLink}>
        {getString('payment', language)} - {getString('approved', language)}
      </Link>
      <span>{`${getString('payments', language)} ${name}`}</span>
    </div>
  );

  return !hasFetched ? (
    <div className={styles.Wrapper}>
      <Spinner fill />
    </div>
  ) : (
    <div className={styles.Wrapper}>
      <Header title={title} />
      {payment && (
        <div className={styles.InvoicesWrapper}>
          <div className={styles.HeaderWithButton}>
            <h2>{getString('status', language)}</h2>
            {payment.status !== 'settled' && (
              <SuperAdminAccess>
                <Button onClick={() => setShowMarkSettled(true)} primary>
                  {getString('markSettled', language)}
                </Button>
              </SuperAdminAccess>
            )}
          </div>
          <TableRow>
            <TableCol className={styles.PaymentInfo}>
              {`${getString('approved', language)}: `}
              <span>
                {`${capitalizeEveryWord(
                  payment.approved_by_user_name,
                )} (${formatCalendarMonthDayYear(payment.created_at)})`}
              </span>
            </TableCol>
            <TableCol className={styles.PaymentInfo}>
              {`${getString('settled', language)}: `}
              <span>
                {payment.settled_at && payment.settled_by_user_name
                  ? `${capitalizeEveryWord(
                      payment.settled_by_user_name,
                    )} (${formatCalendarMonthDayYear(payment.settled_at)})`
                  : NOT_APPLICABLE}
              </span>
            </TableCol>
          </TableRow>
          <h2>{getString('payorPayee', language)}</h2>
          <UserInfo payment={payment} />
          <h2>{getString('summary', language)}</h2>
          <SummaryTable payment={payment} />
          {Object.keys(payment.transactions.transactions_by_sample_plan).length > 0 && (
            <>
              <h2>{getString('lineItemDetail', language)}</h2>
              <ActivityDetailTable
                transactions={payment.transactions.transactions_by_sample_plan}
                toggleModTransactions={() => {}}
              />
            </>
          )}
          {payment.transactions.adjustments.length > 0 && (
            <>
              <h2>{getString('adjustments', language)}</h2>
              <AdjustmentsTable adjustments={payment.transactions.adjustments} />
            </>
          )}
          {showMarkSettled && (
            <SettlementController
              firstName={payment.user.first_name}
              lastName={payment.user.last_name}
              type={payment.payment_type}
              amount={payment.amount}
              onSubmit={handleMarkSettled}
              onClose={() => setShowMarkSettled(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentApprovedDetailsContainer;
