import React from 'react';
import { Input, Label } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import styles from './ContactInfo.module.css';

interface Props {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    business_name: string | null;
    cell?: string | null;
  };
  updateContactInfo: (key: string, value: string) => void;
  updateContactCell: (value: string) => void;
  emailDisabled?: boolean;
}

const ContactInfo = ({ user, updateContactInfo, updateContactCell, emailDisabled }: Props) => {
  const language = useBroswerLanguage();

  return (
    <>
      <div className={styles.WrapperRow}>
        <Label label={`${getString('firstName', language)}*`} className={styles.ThreeWideInput}>
          <Input
            dataTestId="first-name"
            className={styles.ThreeWideInput}
            onChange={(e) => updateContactInfo('first_name', e.target.value)}
            value={user?.first_name}
          />
        </Label>
        <Label label={`${getString('lastName', language)}*`} className={styles.ThreeWideInput}>
          <Input
            dataTestId="last-name"
            className={styles.ThreeWideInput}
            onChange={(e) => updateContactInfo('last_name', e.target.value)}
            value={user?.last_name}
          />
        </Label>
      </div>
      <div className={styles.WrapperRow}>
        <Label label={`${getString('email', language)}*`} className={styles.WiderInput}>
          <Input
            dataTestId="email"
            className={styles.WiderInput}
            onChange={(e) => updateContactInfo('email', e.target.value)}
            value={user?.email}
            disabled={emailDisabled}
          />
        </Label>
        <Label label={getString('businessName', language)} className={styles.ThreeWideInput}>
          <Input
            dataTestId="business-name"
            className={styles.ThreeWideInput}
            onChange={(e) => updateContactInfo('business_name', e.target.value)}
            value={user?.business_name || ''}
          />
        </Label>
        <Label label={getString('cellPhone', language)} className={styles.ThreeWideInput}>
          <Input
            dataTestId="cell-phone"
            className={styles.ThreeWideInput}
            onChange={(e) => updateContactCell(e.target.value)}
            value={user?.cell || ''}
          />
        </Label>
      </div>
    </>
  );
};

export default ContactInfo;
