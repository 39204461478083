import React from 'react';
import { AnalyticImageType } from 'store/analytics/types';

import styles from './AnalyticImages.module.css';

type AnalyticImageryPropsType = {
  images?: AnalyticImageType[];
};

const AnalyticImagery = ({ images }: AnalyticImageryPropsType) => {
  if (!images?.length) {
    return null;
  }

  return (
    <div className={styles.Wrapper}>
      {images.map((image) => (
        <div key={image.id} className={styles.ImageContainer}>
          <div className={styles.Image}>
            <img src={image.url} alt={image.alt_text} />
          </div>
          <span className={styles.ImgTitle}>{image.alt_text}</span>
          <span className={styles.Source}>
            {'Source: '}
            <a href={image.source} rel="noreferrer">
              Link
            </a>
          </span>
        </div>
      ))}
    </div>
  );
};

export default AnalyticImagery;
