import React, { useEffect } from 'react';
import { Select, SelectProps } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { getPlateAnalysisOptions } from 'util/plates';
import { PlateType } from 'store/plates/types';

type AnalysisSelectorPropTypes = SelectProps & {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
  analysis?: string[];
};

export const AnalysisSelector = ({
  plate,
  onValueChange,
  analysis,
  ...props
}: AnalysisSelectorPropTypes) => {
  const language = useBroswerLanguage();
  const analysisOptions = getPlateAnalysisOptions(language, analysis);

  const handleOnChange = (value: string | null) => {
    if (value) {
      onValueChange('analysis_type', value);
    }
  };

  useEffect(() => {
    if (plate.analysis_type === undefined) {
      handleOnChange(analysisOptions[0].value);
    }
  }, [plate]);

  return (
    <Select
      onChange={handleOnChange}
      data={analysisOptions}
      value={plate.analysis_type}
      label={getString('analysis', language)}
      {...props}
    />
  );
};
