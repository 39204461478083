import {
  HEAT_MAP_REQUEST_ERROR,
  HeatMapActionsType,
  HeatMapType,
  RECEIVE_HEAT_MAP,
  REQUEST_HEAT_MAP,
  RECEIVE_AGENCY_HEAT_MAP,
} from './types';

export const requestHeatMap = (): HeatMapActionsType => ({ type: REQUEST_HEAT_MAP });

export const receiveHeatMap = (heatMap: HeatMapType[]): HeatMapActionsType => ({
  type: RECEIVE_HEAT_MAP,
  payload: { heatMap },
});

export const receiveAgencyHeatMap = (
  agencyId: number,
  heatMap: HeatMapType[],
): HeatMapActionsType => ({ type: RECEIVE_AGENCY_HEAT_MAP, payload: { agencyId, heatMap } });

export const heatMapRequestError = (): HeatMapActionsType => ({ type: HEAT_MAP_REQUEST_ERROR });
