import React, { useEffect, useState } from 'react';
import { ActionIcon, Group, Loader, Stack } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FiDelete } from 'react-icons/fi';
import { PlateSearchBar } from 'common';
import { generateWellsIndex } from 'util/plates';
import { PlateType } from 'store/plates/types';
import { requestGetPlate } from 'store/plates/requests';
import showToast from 'actions/toastActions';
import { mergeablePlateTypes } from 'constants/plates';
import { WellGrid } from '../Details/Wells/Grid/Grid';
import { PlateHeader } from '../Details/Header/PlateTitle';

type MergeGridPropsType = {
  barcode: string | undefined;
  setBarcode: (value: string | undefined) => void;
  onMerge?: () => Promise<PlateType | null>;
  onRemove?: VoidFunction;
  secondaryBarcodes?: string[];
  alreadySelected?: string[];
  label?: string;
  error?: boolean;
};

export const MergeGrid = ({
  barcode,
  setBarcode,
  onMerge,
  onRemove,
  secondaryBarcodes,
  alreadySelected,
  label,
  error,
}: MergeGridPropsType) => {
  const language = useBroswerLanguage();

  const [plate, setPlate] = useState<PlateType>();
  const [isLoading, setIsLoading] = useState(false);
  const [initial, toggleInitial] = useState(true);

  useEffect(() => {
    if (initial && barcode) {
      handleGetPlate(barcode);
    }
    toggleInitial(false);
  }, [initial, barcode]);

  const handleSelect = async (value: string) => {
    const preventSelect = alreadySelected?.length && alreadySelected.includes(value);
    if (!preventSelect) {
      handleGetPlate(value);
    }
  };

  const handleGetPlate = async (value: string) => {
    setIsLoading(true);
    try {
      setBarcode(value);
      const response = await requestGetPlate(value);
      setPlate(response);
    } catch (e) {
      showToast(getString('', language), 'error');
    }
    setIsLoading(false);
  };

  const handleDeselect = () => {
    setBarcode(undefined);
    setPlate(undefined);
  };

  const handleRemove = () => {
    onRemove && onRemove();
  };

  const barcodesHaveChanged = secondaryBarcodes?.length
    ? JSON.stringify(secondaryBarcodes)
    : undefined;
  useEffect(() => {
    handleMerge();
  }, [barcode, barcodesHaveChanged]);

  const handleMerge = async () => {
    if (barcode && secondaryBarcodes) {
      if (secondaryBarcodes.length && onMerge) {
        const response = await onMerge();
        if (response) {
          setPlate(response);
        }
      } else {
        handleGetPlate(barcode);
      }
    }
  };

  const wellLookupMap = generateWellsIndex(plate?.wells);

  return (
    <Stack align="flex-end">
      <Group justify="flex-end" align="flex-end" w="32rem">
        {onRemove && (
          <ActionIcon onClick={handleRemove} variant="transparent" mb="0.3rem">
            <FiDelete color="red" cursor="pointer" size={35} />
          </ActionIcon>
        )}
        <PlateSearchBar
          label={label}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          defaultValue={barcode}
          placeholder={getString('scanBarcodeMsg', language)}
          plateTypes={mergeablePlateTypes}
          all
          forceSelect
          w="15rem"
          error={error}
        />
      </Group>
      {plate && (
        <Stack gap="0">
          <PlateHeader plate={plate} ml="md" />
          <WellGrid wells={wellLookupMap} plate={plate} disableHighlight />
        </Stack>
      )}
      {isLoading && <Loader />}
    </Stack>
  );
};
