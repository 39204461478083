import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';

import { Header, Spinner, TabbedController, SuperAdminAccess } from 'common';
import { user as selectUser } from 'store/user/selectors';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getUser, submitUserDetails } from 'store/user/thunks';

import ContactInfo from './ContactInfo';
import PasswordChange from './PasswordChange';
import BillingInfo from './BillingInfo';
import UserAgreements from './UserAgreements';
import Scanning from './Scanning';

import styles from './Container.module.css';

type paramsType = {
  userId: string;
};

const SettingsContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const userId = Number(useParams<paramsType>().userId);

  const { user, lab_locations } = useSelector((state: RootState) => ({
    user: userId ? state.user.byId[userId] : selectUser(state),
    lab_locations: state.user.lab_locations,
  }));

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [userId]);

  const changeDefaultShipping = async (idx: number) => {
    if (user?.id) {
      dispatch(
        submitUserDetails(
          { default_shipping_lab: lab_locations[idx] },
          user.id,
          language,
          true,
          'contactInfoUpdated',
        ),
      );
    }
  };

  if (!user?.id || !lab_locations) {
    return <Spinner fill />;
  }

  return (
    <div className={styles.Wrapper}>
      <Header title={getString('profileSettings', language)} />
      <SuperAdminAccess>
        <Flex justify="space-between" w="50%">
          <Stack>
            <b>{getString('defaultShippingLocation', language)}</b>
            <TabbedController
              activeIndex={lab_locations.findIndex((lab) => lab === user.default_shipping_lab)}
              options={lab_locations.map((lab) => ({
                displayName: lab.replace('_', ' ').toUpperCase(),
                value: lab,
              }))}
              onChange={changeDefaultShipping}
            />
          </Stack>
          <SuperAdminAccess>
            <Stack>
              <b>{getString('canScanProPlans', language)}</b>
              <Scanning user={user} />
            </Stack>
          </SuperAdminAccess>
        </Flex>
      </SuperAdminAccess>
      <ContactInfo initialUser={user} />
      <BillingInfo userId={user.id} initialBillingAddress={user.billing_addresses[0]} />
      {!userId && <PasswordChange email={user.email} />}
      <UserAgreements role={user.roles[0].name} />
    </div>
  );
};

export default SettingsContainer;
