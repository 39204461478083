import React from 'react';
import {
  DISCOUNT,
  DISCOUNT_PANELS,
  DISCOUNT_PRESSURE_PANEL,
  SAMPLING_FEE_KEY,
} from 'constants/products';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { pricePerAcre, getDensity, getPriceDisplay } from 'util/samplePlan';
import { getAcreageUnitFromLang } from 'util/units';
import { PriceSummaryType } from 'store/samplePlans/types';
import styles from './Confirmation.module.css';
import { PER_ACRE } from 'constants/variables';
import { Grid } from '@mantine/core';

type AnalysisRowProps = {
  product: string;
  acreage: number;
  priceSummary: PriceSummaryType;
};

const AnalysisRow = ({ product, acreage, priceSummary }: AnalysisRowProps) => {
  const language = useBroswerLanguage();

  const getDisplayName = (prod: string) => {
    if (prod === SAMPLING_FEE_KEY) {
      return `${getString('sampling', language)} (${getDensity(
        priceSummary[prod].num_zones,
        acreage,
      )} ${getAcreageUnitFromLang(language)})`;
    }
    if (prod.includes(DISCOUNT)) {
      if ([DISCOUNT_PRESSURE_PANEL, DISCOUNT_PANELS].includes(prod)) {
        return priceSummary[prod].name;
      }
      return `${priceSummary[prod].name} (${getDensity(
        priceSummary[prod].num_zones,
        acreage,
      )} ${getAcreageUnitFromLang(language)})`;
    }
    return getString(prod, language);
  };

  const cost_per_zone = priceSummary[product].total / priceSummary[product].num_zones;

  const isPricePerZone = priceSummary[product]?.price_type !== PER_ACRE;
  return (
    <Grid
      columns={36}
      className={product.includes(DISCOUNT) ? styles.HightlightDiscount : undefined}
      key={product}
    >
      <Grid.Col span={3} />
      <Grid.Col span={9}>{getDisplayName(product)}</Grid.Col>
      <Grid.Col span={6}>{isPricePerZone ? priceSummary[product].num_zones : ''}</Grid.Col>
      <Grid.Col span={6}>{isPricePerZone ? getPriceDisplay(product, cost_per_zone) : ''}</Grid.Col>
      <Grid.Col span={6}>{getPriceDisplay(product, priceSummary[product].total)}</Grid.Col>
      <Grid.Col span={6}>
        {getPriceDisplay(product, pricePerAcre(priceSummary[product].total, acreage))}
      </Grid.Col>
    </Grid>
  );
};

export default AnalysisRow;
