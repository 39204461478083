import { createSelector } from 'reselect';

import { OperationType } from 'store/operation/types';
import { RootState } from 'store';

export const activeOperationId = (state: RootState) => state.operations.activeOperationId || -1;
export const operationIsFetching = (state: RootState) => state.operations.isFetching;
const operationSummary = (state: RootState) => state.operations.summary.items;
export const operationMap = (state: RootState) => state.operations.operationsById;

export const activeOperation = createSelector(
  [operationSummary, operationMap, activeOperationId],
  (allOperations, operationsById, operationId) =>
    operationsById[operationId] ||
    allOperations.find((operation: OperationType) => operation.id === operationId),
);
