import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Button, Input } from 'common';

import { createAttribute } from 'store/attributes/thunks';

import styles from './AttributeCreation.module.css';

const AttributeCreation = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { isFetching } = useSelector((state: RootState) => ({
    isFetching: state.attributes.isFetching,
  }));

  const createInputHandler =
    (inputChangeHandler: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      inputChangeHandler(event.target.value);
    };

  const clearInputs = () => {
    setName('');
    setDescription('');
  };

  const handleCreate = async () => {
    const attribute = {
      name,
      description,
    };
    await dispatch(createAttribute(attribute));
    clearInputs();
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.InputArea}>
        <div className={styles.InputRow}>
          <span className={styles.SmallColumn}>{getString('name', language)}</span>
          <span className={styles.BigColumn}>{getString('description', language)}</span>
        </div>
        <div className={styles.InputRow}>
          <Input
            className={styles.SmallColumn}
            onChange={createInputHandler(setName)}
            placeholder={getString('enterName', language)}
            type="Text"
            value={name}
          />
          <Input
            className={styles.BigColumn}
            onChange={createInputHandler(setDescription)}
            placeholder={getString('enterDescription', language)}
            type="Text"
            value={description}
          />
        </div>
      </div>
      <Button
        className={styles.Submit}
        onClick={handleCreate}
        disabled={isFetching || !name}
        primary
      >
        {getString('createAttribute', language)}
      </Button>
    </div>
  );
};

export default AttributeCreation;
