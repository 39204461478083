import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/auth';
import { getLocalStorage } from './localStorage';
import { UnauthorizedError, InvalidRequestError } from './errors';

type OptionsType = { [key: string]: any };

export const getAuthedHeaders = (url: string) => {
  const authHeaderKey = url.includes('refresh') ? REFRESH_TOKEN : ACCESS_TOKEN;
  return {
    Authorization: `Bearer ${getLocalStorage(authHeaderKey)}`,
    'Content-Type': 'application/json',
  };
};

const handleResponse = (response: Response) => {
  if (!response.ok) {
    return response.json().then((responseJson) => {
      const { msg, message } = responseJson;
      if (response.status === 401) {
        throw new UnauthorizedError('Unauthorized error from the server');
      }
      if (response.status === 400) {
        if (typeof msg === 'string' || typeof message === 'string') {
          throw new InvalidRequestError(msg || message);
        }
        if (typeof msg === 'object' && msg !== null) {
          if (Array.isArray(msg)) {
            throw new Error(msg.join('. \n'));
          }
          throw new Error(
            Object.keys(msg)
              .reduce((acc: string[], key) => acc.concat(`${key}: ${msg[key]}`), [])
              .join('. \n'),
          );
        }
        throw new InvalidRequestError(msg);
      }
      throw new Error(msg || 'Error response from server');
    });
  }

  return response;
};

export const handleJsonResponse = async (response: Response) => {
  const handledResponse = await handleResponse(response);
  return handledResponse.json();
};

export const handleHelloSignResponse = (response: Response) => {
  return Promise.resolve()
    .then(() => {
      if (!response.ok) {
        return response.json().then((responseJson) => {
          const { message, msg } = responseJson;
          if (response.status === 400) {
            throw new InvalidRequestError(message || msg);
          }
          if (response.status === 401) {
            throw new InvalidRequestError(msg);
          }
          throw new Error(message || 'Error response from server');
        });
      }

      return response;
    })
    .then((r) => r.json());
};

export const handleBlobResponse = async (response: Response) => {
  const handledResponse = await handleResponse(response);
  return handledResponse.blob();
};

const extractFileName = (response: Response) => {
  const fileName = response.headers
    .get('Content-Disposition')
    ?.split(';')
    .map((param) => param.trim().split('='))
    .filter((param) => param[0] === 'filename');
  if (fileName?.length) {
    return fileName[0][1].replace(/"/g, '');
  }
  return null;
};

/**
 * Get an `href`-friendly data URI string from a JSON object
 *
 * @param json any JSON object
 * @returns data URI string
 */
export const getDataUriStringFromJson = (json: Record<string, any>) => {
  return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
};

/**
 * Hacky (yet widely accepted) way to download a file using a temporary `<a>` element.
 *
 * @param href URL of the file, or a data URI string with the file's contents
 * @param filename name of the output file
 */
export const downloadViaAnchorElem = (href: string, filename: string) => {
  const anchorElem = document.createElement('a');

  anchorElem.setAttribute('href', href);
  anchorElem.setAttribute('download', filename);
  document.body.appendChild(anchorElem); // required for firefox
  anchorElem.click();
  anchorElem.remove();
};

const downloadAttachment = async (response: Response, name?: string) => {
  const objectUrl = URL.createObjectURL(await response.blob());
  const filename = name || extractFileName(response) || 'unnamed';

  downloadViaAnchorElem(objectUrl, filename);
};

export const handleFileAttachment = async (response: Response, name?: string) => {
  const handledResponse = await handleResponse(response);
  return downloadAttachment(handledResponse, name);
};

/**
 * Generic fetcher for JSON data.
 *
 * @param url url to fetch
 * @returns Promise with the JSON data from the response
 */
export const getJsonFromFetch = async <ResponseShape extends Record<string, any>>(
  url: string,
): Promise<ResponseShape | undefined> => {
  const response = await fetch(url);
  const contents: ResponseShape = await response.json();

  return contents;
};

const makeAuthedRequest = (url: string, options: OptionsType) =>
  fetch(url, {
    headers: getAuthedHeaders(url),
    ...options,
  });

const productionHosts = [
  'pattern.app',
  'www.pattern.app',
  'blueprint.pattern.app',
  'www.blueprint.pattern.app',
  'lavoro.pattern.app',
  'www.lavoro.pattern.app',
  'redstar.pattern.app',
  'www.redstar.pattern.app',
];

const isLocal = window.location.href.includes('localhost');
export const isProduction = productionHosts.includes(window.location.host);

export const SERVICE_URL = isLocal ? 'https://stage.pattern.app/api' : '/api';

export const QR_CODE_URL = 'https://api.qrserver.com/v1';

export const requestGet = (url: string, options: OptionsType = {}) =>
  makeAuthedRequest(url, options);

export const requestPost = (url: string, options: OptionsType = {}) =>
  makeAuthedRequest(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(options.body),
  });

export const requestPut = (url: string, options: OptionsType = {}) =>
  makeAuthedRequest(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(options.body),
  });

export const requestDelete = (url: string, options: OptionsType = {}) =>
  makeAuthedRequest(url, {
    ...options,
    method: 'DELETE',
    body: JSON.stringify(options.body),
  });

const makeRequestWithoutHeaders = (url: string, options: OptionsType) => fetch(url, { ...options });

export const requestPostFile = (url: string, options: OptionsType = {}) => {
  const formData = new FormData();
  formData.append('file', options.file);
  const headers = getAuthedHeaders(url);
  // @ts-ignore
  delete headers['Content-Type'];
  return makeRequestWithoutHeaders(url, {
    ...options,
    method: 'POST',
    body: formData,
    headers,
  });
};

export const requestGetFile = (url: string, options: OptionsType = {}) =>
  makeAuthedRequest(url, options);

const downloadBlob = (blob: Blob, filename: string) => {
  const objectUrl = URL.createObjectURL(blob);
  downloadViaAnchorElem(objectUrl, filename);
};

export const requestDownloadFile = async (url: string, filename: string) =>
  downloadBlob(await handleBlobResponse(await requestGetFile(url)), filename);

export const GCPFileUpload = ({
  method = 'PUT',
  uploadUrl,
  file,
  onProgressUpdate,
  onCompleted,
  onError,
  onAbort,
  header = 'Content-Type',
  headerValue = 'application/zip',
}: {
  method?: 'PUT' | 'POST';
  uploadUrl: string;
  file: File | FormData;
  onProgressUpdate?: (event: ProgressEvent) => void;
  onCompleted: () => void;
  onError: (err?: any) => void;
  onAbort: (err?: any) => void;
  header?: string;
  headerValue?: string;
}) => {
  const xhr = new XMLHttpRequest();
  if (onProgressUpdate) {
    xhr.upload.addEventListener('progress', onProgressUpdate);
  }
  xhr.addEventListener('load', onCompleted);
  xhr.addEventListener('error', onError);
  xhr.addEventListener('abort', onAbort);

  xhr.open(method, uploadUrl);
  xhr.setRequestHeader(header, headerValue);
  xhr.send(file);
  return xhr;
};

export const uploadToGCP = (
  uploadInfo: { file_name: string; upload_url: string; content_type: string },
  file: File,
) =>
  new Promise((resolve, reject) => {
    const onCompleted = () => resolve(undefined);
    const onError = (err: any) => reject(err);

    return GCPFileUpload({
      uploadUrl: uploadInfo.upload_url,
      file,
      onCompleted,
      onError,
      onAbort: onError,
      header: 'Content-Type',
      headerValue: uploadInfo.content_type,
    });
  });

export const DEBOUNCE = 300;
