import React, { useState, useEffect } from 'react';

import { AnalyticType } from 'store/analytics/types';
import SortableTable from 'common/SortedTable';
import { Group, Text, Input, Button, Stack, Space } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

type ReferencesAndResourcesPropsType = {
  loadingError: string;
  analytic: AnalyticType;
  setAttribute: (attributeName: string, attributeValue: any) => void;
};

const ReferencesAndResources = ({
  loadingError,
  analytic,
  setAttribute,
}: ReferencesAndResourcesPropsType) => {
  const language = useBroswerLanguage();
  const [description, setDescription] = useState('');
  const [source, setSource] = useState('');
  const [referenceLink, setReferenceLink] = useState('');
  const [referencesAndResources, setReferences] = useState(analytic?.references_resources || []);

  useEffect(() => {
    setReferences(analytic.references_resources || []);
  }, [analytic]);

  const cleanForm = () => {
    setDescription('');
    setSource('');
    setReferenceLink('');
  };

  const handleAddResource = () => {
    setAttribute('references_resources', [
      ...referencesAndResources,
      {
        description,
        source,
        reference_link: referenceLink,
      },
    ]);
    cleanForm();
  };

  const handleDeleteResource = (desc: string) => {
    const newReferences = referencesAndResources.filter((r) => r.description !== desc);
    setAttribute('references_resources', newReferences);
  };

  return (
    <Stack gap="md">
      <Text size="lg" fw={500}>
        {getString('addReferencesOrResources', language)}
      </Text>
      <Group align="flex-end">
        <Input.Wrapper label={getString('description', language)}>
          <Input
            disabled={!!loadingError || !analytic.id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
            value={description}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('source', language)}>
          <Input
            disabled={!!loadingError || !analytic.id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSource(e.target.value)}
            value={source}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('referenceLink', language)}>
          <Input
            disabled={!!loadingError || !analytic.id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferenceLink(e.target.value)}
            value={referenceLink}
          />
        </Input.Wrapper>
        <Button
          variant="outline"
          disabled={!!loadingError}
          loading={!analytic.id}
          onClick={handleAddResource}
        >
          {getString('add', language)}
        </Button>
      </Group>
      <Space h="md" />
      <SortableTable
        columns={[
          {
            name: getString('description', language),
            attribute: 'description',
            sortable: true,
          },
          {
            name: getString('source', language),
            attribute: 'source',
            sortable: true,
          },
          {
            name: getString('referenceLink', language),
            attribute: 'reference_link',
            sortable: false,
            cellFormatter: (refLink) => (
              <a href={refLink} target="_blank" rel="noopener noreferrer">
                {refLink}
              </a>
            ),
          },
          {
            name: getString('actions', language),
            attribute: 'description',
            sortable: false,
            cellFormatter: (desc) => (
              <Button color="darkRed" onClick={() => handleDeleteResource(desc)}>
                {getString('delete', language)}
              </Button>
            ),
          },
        ]}
        data={referencesAndResources}
        title={getString('referencesAndResources', language)}
        initialSortBy="description"
      />
    </Stack>
  );
};

export default ReferencesAndResources;
