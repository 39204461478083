export interface CalculatorValueInterface {
  key: string;
  initialValue: string;
}
export interface CalculatorValuesInterface {
  TARGET_DILUTION: CalculatorValueInterface;
  TOTAL_DNA_MULTIPLIER: CalculatorValueInterface;
  TOTAL_PCR_MULTIPLIER: CalculatorValueInterface;
}

export const DNA_CONCENTRATION = 'dna';
export const NORMALIZED_DNA = 'normalized_dna';
export const TOTAL_DNA = 'total_dna';
export const TOTAL_PCR_DNA = 'total_pcr_dna';

export const CALCULATOR_VALUES: CalculatorValuesInterface = {
  TARGET_DILUTION: {
    key: 'target_dilution',
    initialValue: '5',
  },
  TOTAL_DNA_MULTIPLIER: {
    key: 'total_dna_multiplier',
    initialValue: '80',
  },
  TOTAL_PCR_MULTIPLIER: {
    key: 'total_pcr_multiplier',
    initialValue: '5',
  },
};

export const dnaConDisplayNames = {
  [DNA_CONCENTRATION as string]: 'dnaConcentration',
  [NORMALIZED_DNA as string]: 'normalizedDna',
  [TOTAL_DNA as string]: 'totalDna',
  [TOTAL_PCR_DNA as string]: 'totalPcrDna',
};

export const dnaConOptionsList = [DNA_CONCENTRATION, NORMALIZED_DNA, TOTAL_DNA, TOTAL_PCR_DNA];
