import {
  LAB_SHIPMENT_REQUEST_ERROR,
  LabShipmentActionType,
  LabShipmentStateType,
  RECEIVE_LAB_SHIPMENT,
  RECEIVE_LAB_SHIPMENTS,
  REQUEST_LAB_SHIPMENT,
} from './types';

const initialState: LabShipmentStateType = {
  paginated: {
    page: 0,
    per_page: 0,
    total: 0,
    items: [],
  },
  byId: {},
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const LabShipmentReducer = (
  state = initialState,
  action: LabShipmentActionType,
): LabShipmentStateType => {
  switch (action.type) {
    case REQUEST_LAB_SHIPMENT:
      return {
        ...state,
        hasFailed: false,
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_LAB_SHIPMENT:
      return {
        ...state,
        byId: {
          [action.payload.id]: action.payload.shipment,
        },
        hasFailed: false,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_LAB_SHIPMENTS:
      return {
        ...state,
        paginated: {
          ...state.paginated,
          items: action.payload.items,
          page: action.payload.page || 1,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
        hasFailed: false,
        hasFetched: true,
        isFetching: false,
      };
    case LAB_SHIPMENT_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        hasFetched: false,
        isFetching: false,
      };
    default:
      return state;
  }
};
