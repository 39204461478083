const ENGLISH_STRINGS = {
  'crop-protection': 'Crop Protection',
  'dna copies': 'DNA Copies',
  'pagi-2024': 'PAGI',
  'partially received': 'Partially Received',
  'partially sampled': 'Partially Sampled',
  'partially shipped': 'Partially Shipped',
  'sugar-beets': 'Sugar Beets',
  'waiting to sample': 'Waiting to Sample',
  aaAnalysis: 'AA Analysis',
  aaRerun: 'AA Rerun',
  abort: 'Abort',
  aboveGroundOnly: 'Above ground only',
  acAtRisk: 'Ac. at Risk',
  accepted: 'Accepted',
  account: 'Account',
  accountName: 'Account Name',
  acre: 'Acre',
  acreCommit: 'Acre Commit',
  acres: 'Acres',
  acresPerZone: 'Acres per zone',
  action: 'Action',
  actions: 'Actions',
  activateTillMapper: 'Activate TillMapper for this field',
  active: 'Active',
  activeSamplingPlans: 'Active Sampling Plans (Acres)',
  activityDetails: 'Activity Details',
  adcp_needed_not_complete: 'ADCP Needed, Not Complete',
  add: 'Add',
  addAddress: 'Add Address',
  addAdjustment: 'Add Adjustment',
  addAdjustments: 'Add Adjustments',
  addAdjustmentsFor: 'Add Adjustment for',
  addAgency: 'Add Agency',
  addAgents: 'Add Agents',
  addAnAgency: 'Please add an agency.',
  addAnalytic: 'Add Analytic',
  addBatch: 'Add Batch',
  addBillingResponsibility: 'Add Billing Responsibility',
  addClus: 'Add CLUs',
  addControl: 'Add Control',
  added: 'Added',
  addEditBoundaries: 'Add / Edit Boundaries',
  addExternalLab: 'Add external lab',
  addFieldInstructions: 'Provide a field name and click "save" to continue.',
  addHybrid: 'Add Hybrid',
  addingBillingResponsibility: 'Adding Billing Responsibility',
  additionalInputSpend: 'Additional Input Spend',
  addLabel: 'Add Label',
  addLocation: 'Add Location',
  addNewImage: 'Add New Image',
  addPlate: 'Add Plate',
  addPlateSuccess: 'Plate Added Successfully!',
  addProduct: 'Add Product',
  addProductChart:
    'Select a product that will provide an adequate level of protection. Adding products to your Product Catalog will display their protection levels here.',
  addProducts: 'Add Products',
  addProductsAbove: 'Add Products Above',
  addReferencesOrResources: 'Add References or Resources',
  addressCreatedMsg: 'Address updated successfully!',
  addSample: 'Add Sample',
  addSeedChart:
    'Select a hybrid or variety that will provide an adequate level of protection. Adding hybrids to your Product Catalog will display their protection levels here.',
  addSeeds: 'Add Seeds',
  addTargetYieldMsg: 'Please add a target yield to continue.',
  adjustmentAmount: 'Adjustment Amount',
  adjustments: 'Adjustments',
  admin: 'Admin',
  affectAllLabelsMsg: 'This will affect all labels in this group.',
  agencies: 'Agencies',
  agency_admin: 'Agency Admin',
  agency: 'Agency',
  agencyConnectionAdded: 'Agency connection added successfully!',
  agencyConnectionRemoved: 'Agency connection removed successfully!',
  agencyContractWarningMsg: 'Billing agency contract is expired: orders will be invoiced at MSRP',
  agencyCost: 'Agency Cost',
  agencyDefaults: 'Agency Defaults',
  agencyName: 'Agency Name',
  agencyPrimary: 'Agency Primary',
  agencyUpdatedSuccessMsg: 'Agency updated successfully!',
  agent: 'Agent',
  agents: 'Agents',
  agentsMustBeAssignedToExisting:
    'Agents must be assigned to an existing Agency. Search for the Agency below. If you want to create a new Agency, you will need to assign the role of Agency Admin to this user',
  alertDoubleSamplePrinting:
    'A label for this sample has already been printed! Please make sure that you are working with the correct sample. There should not be a reason to print a second label unless the first one got damaged or did not print correctly! Are you sure you want to print this label again?',
  alertDoubleSamplePrintingTitle: 'Label Already Printed',
  alerts: 'Alerts',
  alertsAndNotReady: 'Alerts + Not Ready',
  alertsAndReady: 'Alerts + Ready',
  all_assignments: 'All Assignments',
  all_plans: 'All Plans',
  all_pro_or_till_rx: 'All Pro or TillMapper',
  all_readiness: 'All Readiness',
  all_statuses: 'All Statuses',
  all: 'All',
  allAccounts: 'All Accounts',
  allAnalyses: 'All Analyses',
  allExtractions: 'All Extractions',
  allowedValues: 'Allowed Values',
  allowMultiplePlans: 'Allow Multiple Open Plans Per Field',
  allPipelines: 'All Pipelines',
  allResults: 'All Results',
  allRiskLevels: 'All Risk Levels',
  allStatuses: 'All Statuses',
  allTypes: 'All Types',
  allZones: 'All Zones',
  ammoniumAcetate: 'Ammonium Acetate',
  ammoniumAcetateAnalysis: 'Ammonium Acetate Analysis',
  amount: 'Amount',
  amplicon: 'Amplicon',
  analysis: 'Analysis',
  analysis590Description:
    'This will include additional analysis in support of 590 Manure Management plans',
  analysis590Required:
    'Warning: 590 Analysis Required - Process through dry / grind to support downstream analysis',
  analysis590Title: '590 Manure Management Analysis',
  analysisAndSampling: 'Analysis & Sampling',
  analysisOptions: 'Analysis Options',
  analysisOptionsMsg: 'Analysis options updated successfully!',
  analysisPackage: 'Analysis Package',
  analytic: 'Analytic',
  analyticAttributes: 'Analytic Attributes',
  analyticDetails: 'Analytic Details',
  analyticImagery: 'Analytic Imagery',
  analyticName: 'Analytic Name',
  analyticOrderUpdated: 'Analytic order updated!',
  analyticRiskMap: 'Analytic Risk Map',
  analytics: 'Analytics',
  analyticTaxonomies: 'Analytic Taxonomies',
  applied: 'Applied',
  applyRecommendedInputs:
    "Apply recommended inputs based on Pattern 360 report on 'at risk' fields",
  approved: 'Approved',
  approvedBy: 'Approved By',
  approveOpenTransactions: 'Approve Open Transactions',
  approveTransaction: 'Approve Transaction',
  areYouSure: 'Are you sure',
  arrived: 'Arrived',
  arrivedLab: 'Arrived Lab',
  assign: 'Assign',
  assigned: 'Assigned',
  assignAllPlans: 'Assign all open sample plans on this account to this sampler',
  assigned_not_accepted: 'Assigned, Not Accepted',
  assignedBillingMsg:
    'The user assigned billing responsibility has not signed their agreement with Pattern. They can access and sign the agreement by logging in to their account at www.pattern.app. You will be able to create sample plans for this account, but will not be able collect samples in field until the agreement has been signed, or an alternative user is added for billing responsibility.',
  assignment_accepted: 'Assignment Accepted',
  assignment_declined: 'Assignment Declined',
  assignment_not_accepted: 'Assignment Not Accepted',
  assignSamplePlan: 'Assign Sample Plan',
  assignSampler: 'Assign Sampler',
  assignSamplerError: 'There was an error assigning the sampler. Please refresh and try again.',
  assignSamplerNoBilling: 'Please assign a billing responsibility before the field can be sampled.',
  assignSamplerPar: 'Select the Sampler you would like to assign to this sample plan',
  assignSamplersSuccessMsg: 'Samplers/Scanners assigned successfully!',
  assignTo: 'Assign to ',
  assignUserToAgency: 'Assign User to Agency',
  authFailed: 'Authentication Failed',
  autoSubmit: 'Auto-Submit',
  average: 'Average',
  averageAppliedToZone: 'Field average applied to zone',
  averageCompaction: 'Average Compaction',
  averageCompactionTooltip: 'Full Field Average PSI, 0-12"',
  avg: 'Avg',
  avgRate: 'Avg Rate',
  awaitingResults: 'Awaiting Results',
  awaitingWellInpuMsg: 'Awaiting Input For Well',
  backToAllRx: 'Back to all Rx',
  backToAnalytics: 'Back to Analytics',
  backToFieldSummary: 'Back To Field Summary',
  backToList: 'Back To List',
  backToResults: 'Back to Results',
  backToTop: 'Back to top',
  backup: 'Backup',
  balance: 'Balance',
  barcode: 'Barcode',
  barcodes: 'Barcodes',
  basePackage: 'Base Package',
  baseSaturation: 'Base Saturation',
  baseTreatment: 'Base Treatment',
  batch: 'Batch',
  batched: 'Batched',
  batches: 'Batches',
  batchOrder: 'Batch Order',
  batchPoolStatus: 'Batch/Pool Status',
  batchSummary: 'Batch Summary',
  bcsr: 'BCSR',
  bdCoarseDryMass: 'BD Coarse Dry Mass',
  bdFineDryMass: 'BD Fine Dry Mass',
  bdTotalDryMass: 'BD Total Dry Mass',
  belowForMappedFields: ' below for any of your mapped fields:',
  benchmark: 'Benchmark',
  beneficialBioMarkers: 'Beneficial Biomarkers (higher is better)',
  benefits: 'Benefits',
  billingAddress: 'Billing Address',
  billingAddressMissingMsg: 'Please fill out all billing address fields.',
  billingAddressRequired: 'Billing address required.',
  billingAgency: 'Billing Agency',
  billingInfoUpdated: 'Billing information updated!',
  billingResponsibility: 'Billing Responsibility',
  bioactive: 'Biofertility',
  biological: 'Biological',
  biological_analysis: 'Biological Analysis',
  biologicalSubsample: 'Biological Subsample',
  blendedLab: 'Blended Lab',
  boostYield: 'Boost yield',
  boostYieldPitch: 'Compaction above 250-300 PSI can reduce yield by 10-20%+',
  boundariesAndData: 'Boundaries and Data',
  bray: 'Bray',
  brazilPhosphorus: 'Brazil - Phosphorus',
  brazilPotassium: 'Brazil - Potassium',
  brazilSaturationMethod: 'Brazil - Saturation Method',
  bulkAssign: 'Bulk Assign',
  bulkDelete: 'Bulk Delete',
  bulkDeleteRxMsg: 'This will remove all selected prescriptions. Press confirm to continue.',
  bulkExport: 'Bulk Export',
  bulkExportRxMsg: 'This will export all selected prescriptions. Press confirm to continue.',
  businessName: 'Business Name',
  by: 'by',
  calciumCarbonateEquivalent: 'Calcium Carbonate Equivalent',
  cancel: 'Cancel',
  cancelAll: 'Cancel All',
  cancelAllAnalysis: 'Cancel All Analysis',
  cancelEntireSample: 'Cancel Entire Sample',
  cancelIngest: 'Cancel Ingest',
  cancelLabel: 'Cancel Label',
  cancelled: 'Cancelled',
  cancelMerge: 'Cancel Merge',
  cancelNitrate: 'Cancel Nitrate',
  cancelNutrient: 'Cancel Nutrient',
  cancelOrder: 'Cancel Order',
  cancelPerformance: 'Cancel Performance',
  cancelPressure: 'Cancel Pressure',
  cancelSample: 'Cancel Sample',
  cancelSamplePlan: 'Cancel Sample Plan',
  cancelSamplePlanFailureMsg: 'Failed to cancel sampling plan. Try again later.',
  cancelSamplePlanMsg: 'Are you sure you want to cancel this order?',
  cancelSamplingPlanMsg:
    'By cancelling this sample plan it will no longer be viewable on this operation.',
  cancelSplit: 'Cancel Split',
  canScanProPlans: '360 Pro Scanning Enabled',
  canUpdateLater: 'You can update this later',
  carbonDetails: 'Carbon Details',
  catalog: 'Catalog',
  catalogDetails: 'Catalog Details',
  category: 'Category',
  cationRatios: 'Cation Ratios',
  cellPhone: 'Cell Phone',
  changePassword: 'Change Password',
  checkBoxToContinue: 'check the box to continue',
  checkingForExistingPlan: 'Checking for an existing sampling plan',
  chemistryBackup: 'Chemistry Backup',
  chemistryResults: 'Chemistry Results',
  childrenPlates: 'Children Plates',
  choose: 'Choose',
  chooseAnalysisMsg: 'Choose the analysis you want ordered',
  chooseNutrientPanels: 'Choose your nutrient panels',
  city: 'City',
  clear: 'Clear',
  clickToAssignPlans: 'Click To Assign Plans',
  clickToExpand: 'Click To Expand',
  cluInstructions1:
    'Select CLU(s) to begin mapping a boundary. Clicking "Reload CLUs" will refresh the boundaries after moving the map.',
  cluInstructions2: 'Click "Add CLUs" to edit selected boundaries.',
  cluInstructions3: 'Zoom in to load CLUs.',
  cnhi: 'CNHI',
  codeIncorrectMsg: 'This code is incorrect or has expired. Please resend and try again.',
  codeSentFailMsg: 'There was an error sending the code. Please refresh and try again.',
  codeSentSuccessMsg: 'Code sent successfully!',
  coldOrRoomTemp: 'Cold or Room Temp',
  collectionDate: 'Collection Date',
  collectionDeleted: 'Collection deleted.',
  collectionName: 'Collection Name',
  collectionsToReview: 'collections to review',
  comments: 'Comments',
  commercialTerritory: 'Commercial Territory',
  commission_tillage_map_generation: 'Commission Tillage Map Generation',
  commission: 'Commission',
  committed_samples: 'Committed Samples',
  committedSamples: 'Committed Samples',
  compaction: 'Compaction',
  compactionAnalysis: 'Compaction Analysis',
  compactionScore: 'Compaction Score',
  complete_bio: 'Complete Bio',
  complete: 'Complete',
  completeBioMsg:
    "In addition to the Pressure Panel, map your soil's disease risk and biofertility opportunity, to optimize input spend",
  completed: 'Completed',
  completedResults: 'Completed Results',
  completedSamples: 'Completed Samples',
  completeIngest: 'Complete Ingest',
  completeness: 'Completeness',
  confirm: 'Confirm',
  confirmAnalysis: 'Confirm Analysis',
  confirmCollectionMsg1:
    'This step will link the selected sample plans to the scan data listed underneath them.',
  confirmCollectionMsg2:
    'Please ensure that all of the necessary scan data has been uploaded for each sample plan before confirming and wait to confirm a sample plan if it has not been completely scanned.',
  confirmFieldAssignment: 'Confirm Field Assignment',
  confirmFieldRemoval: 'Confirm Field Removal',
  confirmMerge: 'Confirm Merge',
  confirmOrder: 'Confirm Order',
  confirmRemoveFieldMsg: 'Are you sure you want to remove this field?',
  confirmSplit: 'Confirm Split',
  connect: 'Connect',
  connecting: 'Connecting',
  connectOutsideAccounts: 'Connect Outside Accounts',
  connectOutsideAccountsImport:
    'Connect to outside accounts to easily import field boundaries and data layers into Pattern Ag',
  connectYourDataFrom: 'Connect your data from',
  contactInfo: 'Contact Information',
  contactInfoUpdated: 'Contact information updated!',
  contactYourAgronomist:
    'Contact your agronomist to learn more, or order TillMapper directly below!',
  containsRegulatedSoilMsg: 'Contains Regulated Soil (Please include DSCA number)',
  contractDetails: 'Contract Details',
  contractEndedSuccessMsg: 'Contract Ended Successfully!',
  contractPricing: 'Contract Pricing',
  contractStatus: 'Contract Status',
  contractType: 'Contract Type',
  contractUpdatedErrorMsg:
    'There was an error updating the contract. Please refresh and try again.',
  contractUpdatedSuccessMsg: 'Contract Updated Successfully!',
  controlCode: 'Control Code',
  controls: 'Controls',
  controlType: 'Control Type',
  conventionalTillage: 'Conventional Tillage',
  copyFromCorn: 'Copy from Corn',
  copyPlate: 'Copy Plate',
  corn: 'Corn',
  cornAndSoybeanPathogens: 'Corn & Soybean Pathogens',
  cornFoliar: 'Corn Foliar',
  cornPathogens: 'Corn Pathogens',
  cornPlan: 'Corn Plan',
  cost: 'Cost',
  cotton: 'Cotton',
  couldNotAddProduct: 'Could not add product. Please refresh and try again.',
  couldNotDownloadLayer: 'Could not download layer',
  couldNotMergeSelectedError: 'Could not merge selected zones.',
  countries: 'Countries',
  country: 'Country',
  county: 'County',
  countyFootNote: 'Used to estimate the risks for your operation',
  create: 'Create',
  createAccount: 'Create Account',
  createAndEditRx: 'Create and Edit Rx',
  createAttribute: 'Create Attribute',
  createBatch: 'Create Batch',
  createCatalogMsg: 'Enter a name for this Product Catalog and click save',
  createCatalogPlaceholderMsg: 'Example: Inputs for Smith Farms...',
  createContract: 'Create Contract',
  createControl: 'Create Control',
  created: 'Created',
  createdAt: 'Created At',
  createEstimate: 'Create Estimate',
  createFarmAccount: 'Create a new farm account',
  createFarmPlan: 'Create Farm Plan',
  createIndexSet: 'Create Index Set',
  createLabShipment: 'Create Lab Shipment',
  createNaptControl: 'Create NAPT Control',
  createNew: 'Create New',
  createNewAgency: 'Create New Agency',
  createNewAgent: 'Create New Agent',
  createNewContract: 'Create New Contract',
  createNewLabel: 'Create New Shipping Label',
  createNewProductCatalog: 'Create New Product Catalog',
  createNutrientRx: 'Create Nutrient Rx',
  createNutrientScript: 'Create Nutrient Script',
  createOperation: 'Create Operation',
  createOperationErrorMsg:
    'There was an error creating the operation. Please refresh and try again.',
  createOperationSuccessMsg: 'Operation Created Successfully!',
  createOrAddAgencyUnder: 'Create or add an agency under',
  createPassword: 'Agree and Create Password',
  createPlate: 'Create Plate',
  createProtocol: 'Create Protocol',
  createSample: 'Create Sample',
  createSampleSheet: 'Create Sample Sheet',
  createShippingAddress: 'Create Shipping Address',
  createShippingLabels: 'Create Shipping labels',
  createUser: 'Create User',
  createYourPlan:
    '<b>Create your plan:</b> The Pattern 360 will help <b>recommend the inputs</b> that will boost yield and optimize spend.',
  createZonesInOrderToBegin: 'Create zones in order to begin selecting analysis!',
  createZonesUsing: 'Create zones using',
  crop: 'Crop',
  cropProtectionCosts: 'Crop Protection Costs',
  crops: 'Crops',
  csvColumnsRequiredMsg: 'File must contain the following columns',
  csvDownloadMsg:
    'This can take a few minutes. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  csvGenerateErrorMsg: 'Error generating CSV. Please try again.',
  current: 'Current',
  custom_points: 'Custom Points',
  cycleTreshold: 'Cycle Threshold',
  dashboard: 'Dashboard',
  dataConfirmationErrorMsg:
    'We were unable to assign the scan to the field - try again or contact EarthOptics/Pattern tech support',
  dataConfirmationSuccess: 'Scan data was assigned to field(s)',
  dataReview: 'Data Review',
  date: 'Date',
  dateSigned: 'Date Signed',
  days_marked_ready: 'Days Marked Ready',
  dcpReading: 'DCP reading',
  dealer: 'Dealer',
  dealerPortal: 'Dealer Portal',
  declined: 'Declined',
  default: 'Default',
  defaultShippingLocation: 'Default Shipping Location',
  delete: 'Delete',
  deleteAgencyOperationConnection: 'Delete agency connection for',
  deleteCollection: 'Delete Collection',
  deleteCollectionConfirm: 'Are you sure? This collection cannot be recovered once deleted.',
  deleteLabelsGroupMsg: 'Are you sure you want to delete this group of labels?',
  deleteLabelsMsg: 'Are you sure you want to delete this label?',
  deletePDFErrorMsg: 'Error deleting PDF. Please refresh and try again.',
  deletePDFMsg: 'Are you sure you want to delete this PDF?',
  deletePDFSuccessMsg: 'PDF deleted successfully!',
  deleteTerritory: 'Delete Territory',
  deleteTerritoryMsg: 'Are you sure you want to delete this territory?',
  deleteYourDataMsg:
    'For support or to request the deletion of your account and/or data, please send an email to support@pattern.ag.',
  deleteZonesSuccess: 'Successfully deleted zones!',
  deletingCollection: 'Deleting collection...',
  deliverCompletePar:
    'This operation has sample plans with results that have not been yet marked as delivered to the customer. If you have completed delivery of those results, you can mark this operation complete now.',
  delivered: 'Delivered',
  deliveredLabels: 'Delivered Labels',
  deliveryComplete: 'Delivery Complete for',
  deliveryDate: 'Delivery Date',
  demoAccount: 'Demo Account',
  density: 'Density',
  depth: 'Depth',
  description: 'Description',
  detected: 'Detected',
  dilution: 'Dilution',
  dilutionConcentration: 'Dillution Concentration',
  disable: 'Disable',
  discard: 'Discard',
  discarded: 'Discarded',
  discardPlate: 'Discard Plate',
  discardPlateWarningMsg:
    'Warning: If you discard this plate, it cannot be recovered. Are you sure you want to do this?',
  discount_panels: 'Discount Panels',
  discount_pressure_panel: 'Stine - Pressure Panel',
  discount_sampling_fee: 'Discount Sampling Fee',
  discount: 'Discount',
  dismissAlerts: 'Dismiss Alerts',
  dispatchNotes: 'Dispatch Notes',
  displayCategory: 'Display Category',
  displayedRecommendationSet: 'Displayed Recommendation Set',
  displayOrder: 'Display Order',
  dlBarcodeFile: 'DL Barcode File',
  dlFIARunSheet: 'DL Run Sheet -- FIA',
  dlICPRunSheet: 'DL Run Sheet -- ICP',
  dlMiSeqIndexSets: 'DL MiSeq Index Sets',
  dlNovaSeqIndexSets: 'DL NovaSeq Index Sets',
  dlRunSheet: 'DL Run Sheet',
  dlSampleSheet: 'DL Sample Sheet',
  dnaConcentration: 'DNA Concentration',
  dnaConcentrationData: 'DNA Concentration Data',
  dnaExtr: 'DNA Extr',
  dnaExtraction: 'DNA Extraction',
  donorPlate: 'Donor Plate',
  doNotBill: 'Do Not Bill',
  doNotEnd: 'Do Not End',
  doNotNavigateMsg: 'Do not navigate away from this page until upload is complete.',
  download: 'Download',
  downloadCsvResults: 'Download CSV Results',
  downloadDetails: 'Download Details',
  downloadOptions: 'Download Options',
  downloadResults: 'Download Results',
  downloadRunSheet: 'Download Run Sheet',
  downloadScript: 'Download Script',
  downloadShpfile: 'Download Shapefile',
  drawBoundariesOnMap: 'Draw boundaries on a map',
  drawPolygon: 'Draw Polygon',
  drawRectangle: 'Draw Rectangle',
  dry: 'Dry',
  dscaInShipment:
    "Shipments of regulated soil must also include the following packing slip, which contains Pattern Ag's DSCA number",
  earliestSample: 'Earliest Sample',
  earnings: 'Earnings',
  edit: 'Edit',
  editAccount: 'Edit Account',
  editBatch: 'Edit Batch',
  editContract: 'Edit Contract',
  editDiscountProgram: 'Edit Discount Program',
  editFieldBoundaries: 'Edit Field Boundaries',
  editFieldBoundary: 'Edit Field Boundary',
  editNotes: 'Edit Notes',
  editOperation: 'Edit Operation',
  editPoints: 'Edit Points',
  editQpcrCycles: 'Edit qPCR Cycles',
  editSamplePlan: 'Edit Sample Plan',
  editZones: 'Edit Zones',
  effectOfEnvironment: 'Effect Of Environment',
  eggs: 'Eggs',
  ele: 'Ele',
  email: 'Email',
  emailAddress: 'Email address',
  emailAlreadyExistsMsg:
    'That email already has an account with Pattern Ag. Use a different email, or contact support to have that user moved to this Agency.',
  emailCodeTo: 'Email code to',
  emiAdcp: 'EMI / ADCP',
  emi_needed_not_complete: 'EMI Needed, Not Complete',
  empty: 'Empty',
  endContract: 'End Contract',
  endOfResults: 'End of Results',
  endUserLicenseAgreement: 'End user license agreement',
  english: 'English',
  enterAccountName: 'Enter account name',
  enterAgencyName: 'Enter an agency name.',
  enterAnEmail: 'Enter an email',
  enterBarcodeMsg: 'Enter barcode or UUID',
  enterCodeSentTo: 'Enter the 6-digit code sent to',
  enterCommaSeparatedValues: 'Enter Comma-Separated Values',
  enterDescription: 'Enter Description',
  enterEmailToSignIn: 'Enter your email to sign in',
  enterName: 'Enter Name',
  enterPasswordFor: 'Enter password for',
  enterValidEmail: 'Enter a valid email address.',
  enterValidPhone: 'Enter a valid phone number.',
  environmentalConsiderations: 'Environmental Considerations',
  eo_dispatch: 'EO Dispatch',
  errorApprovingTransaction: 'An error occurred when trying to approve transaction.',
  errorBillingInfo: 'Error while updating billing information.',
  errorCancellingPlan: 'Error cancelling sampling plan. Please try again later.',
  errorContactInfo: 'Error while updating contact information.',
  errorCreatingShippingLabel: 'Error while creating shipping label.',
  errorDefaultShippingMsg:
    'There was an error setting the default shipping location. Please try again.',
  errorDeletingPrescription: 'An error occurred while deleting the prescription. Please Refresh.',
  errorDownloadingShpfile: 'Error downloading shapefile. Try again later.',
  errorLoadingExistingPlanMsg: 'There was an issue loading the existing plan. Please refresh.',
  errorLoggedInMsg: 'Your token expired. Please log in again.',
  errorLoggingIn: 'Error getting login url. There may be a backend issue.',
  errorLoggingInMsg: 'There was an issue logging in. Please try again.',
  errorMarkingOperationDiscount: 'Error Marking Operation as part of discount program. Try again.',
  errorMarkingPlanDelivered: 'Error Marking Sampling Plans as Delivered. Try again.',
  errorNewPassConf: 'The New Password Confirmation does not match the New Password.',
  errorNewPassRules: 'The New Password provided does not match the password rules below.',
  errorPasswordChange: 'Password change failed.',
  errorSavingFieldMsg: 'Error saving field. Please Refresh.',
  errorSavingOperationMsg: 'Error saving operation. Please Refresh',
  errorSettingBillingResMsg: 'Error setting billing responsibility. Please try again.',
  errorWithFieldGeometryMsg:
    'Please check your field boundaries. There may be unnecessary intersections or holes.',
  estAcresAtRisk: 'Est. Acres at Risk',
  estBenefit: 'Est. Benefit',
  estimateBasedOnFarmPlan:
    "This is an estimate based on your farm plan and Pattern Ag's predicted risk for 2023 in your county.",
  estimatedCompletionDate: 'Estimated Completion Date',
  estimatedReturnAcross: 'Estimated return across your operation',
  estimateYourRisk: 'To estimate your risk, tell us about your crop plan:',
  estReturn: 'Est. Return',
  estYieldAtRisk: 'Est. Yield at Risk',
  estYieldImpact: 'Est. Yield Impact',
  eta: 'ETA',
  exc: 'Exc',
  excludeContractSignature: 'Exclude Contract Signature?',
  excludeFromAnalysis: 'Exclude from analysis',
  existingPassNoReuse: 'Existing password cannot be reused.',
  expectedYield: 'Expected yield',
  expirationDate: 'Expiration Date',
  expired: 'Expired',
  expiredOn: 'Expired On',
  export: 'Export',
  exportCsv: 'Export CSV',
  exportData: 'Export Data',
  exportInitiatedMsg: 'Your export was initiated!',
  exportProCsv: 'Export Pro CSV',
  exportProShapefile: 'Export Pro Shapefile',
  exportSelected: 'Export Selected',
  exportShapefile: 'Export Shapefile',
  externalLab: 'External Lab',
  extractionMethod: 'Extraction Method',
  failed: 'Failed',
  failedDeleteLabelsMsg: 'Failed to delete labels. Please refresh and try again.',
  failedGetRegulatedSoilMsg: 'Unable to get regulated soil PDF.',
  failedToDownloadRegSoilMsg: 'Failed to download regulated soil PDF.',
  failedToFetchMsg: 'Failed to fetch meta data. Please refresh.',
  failedToFetchThresholdsMsg: 'Failed to fetch thresholds. Try again later.',
  failedToLoadClusMsg: 'Failed to load CLUs',
  failure: 'Failure',
  farm: 'Farm',
  farmField: 'Farm: Field',
  farmName: 'Farm Name',
  featureFlags: 'Feature Flags',
  fertility: 'Fertility',
  field_view: 'Field View',
  field: 'Field',
  fieldAlreadyExists: 'A field with this name has already been created for your operation.',
  fieldAndSamplePlanDetails: 'Field & Sample Plan Details',
  fieldAvg: 'Field Avg',
  fieldComparison: 'Field Comparison',
  fieldCost: 'Field Cost',
  fieldCoverage: 'Field Coverage',
  fieldDeletedMsg: 'Field deleted successfully!',
  fieldName: 'Field name',
  fieldRate: 'Field Rate',
  fieldResultsOverview: 'Field Results - Overview',
  fieldRisk: 'Field Risk',
  fields: 'Fields',
  fieldSavedMsg: 'Field Saved successfully!',
  fieldsLower: 'field(s)',
  fieldsMergedSuccessMsg: 'Fields merged successfully!',
  fieldSplitSuccessMsg: 'Field split successfully!',
  fieldsWithErrors: 'Fields with an * are required.',
  filename: 'Filename',
  fileSizeTooLarge: 'File size must be less than 2 MB.',
  fillOutRequiredFields: 'Please fill out all required fields before submitting.',
  filteredResults: 'Filtered Results',
  firstName: 'First Name',
  firstSampleArrived: 'First Sample Arrived',
  foliar: 'Foliar',
  foliarFungicide: 'Foliar Fungicide',
  foliarMissing: 'Foliar protection options have not been added to crop plan. Click to review',
  foliarProducts: 'Foliar Products',
  forgotPassword: 'Forgot password?',
  formula: 'Formula',
  forwardIndexSequence: 'Forward Index Sequence',
  forwardPrimer: 'Forward Primer',
  fourOFourMsg: 'It appears the page you are looking for is empty. Try one of the links above...',
  freezer: 'Freezer',
  from: 'From',
  full: 'Full',
  fullyAssigned: 'Fully Assigned',
  fungicide: 'Fungicide',
  fungicideInsecticide: 'Fungicide & Insecticide',
  fungicideNematicide: 'Fungicide + Nematicide',
  generateCsvResults: 'Generate CSV Results',
  generateMapbookMsg:
    'Generating mapbook... This will take a few minutes. The download will appear in the dropdown.',
  generateMapbookResults: 'Generate Mapbook Results',
  generatePoints: 'Generate Points',
  generateProCsvResults: 'Generate Pro CSV Results',
  generateProShapefileResults: 'Generate Pro Shapefile Results',
  generateRxFrom: 'Generate Rx from',
  generateShapefileResults: 'Generate Shapefile Results',
  generateZones: 'Generate Zones',
  generating: 'Generating',
  generatingProPrescription: 'Generating Rx from Pro Results',
  generic: 'Generic',
  genomic: 'Genomic',
  genomicPlates: 'Genomic Plates',
  genomicProcessing: 'Genomic Processing',
  genomicSamples: 'Genomic Samples',
  getStartedCreateAccount: 'To get started, create your farming account',
  goBack: 'Go back',
  gold: 'Gold',
  good: 'Good',
  gossWilt: "Goss's Wilt",
  goToPlan: 'Go To Plan',
  grayLeafSpot: 'Gray Leaf Spot',
  grid_points: 'Grid Points',
  grid_zones: 'Grid Zones',
  grower: 'Grower',
  harmfulBiomarkers: 'Harmful Biomarkers (lower is better)',
  haveReadAndAgreeToPatternAg:
    'By clicking “Agree,” I acknowledge that I have read and understand the PatternAg, Inc. End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the PatternAg, Inc. End User License Agreement:',
  haveReadAndAgreeToPatternAgIncludingTerms:
    'By clicking “Agree,” I acknowledge that I have read and understand the PatternAg, Inc. Terms and Conditions, End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the PatternAg, Inc. End User License Agreement:',
  height: 'Height',
  hideDetails: 'Hide Details',
  high: 'High',
  highContrast: 'High Contrast',
  highRisk: 'High Risk',
  highRiskAcres: 'High Risk Acres',
  highRiskAreas: 'High Risk Areas',
  highRiskFields: 'High Risk Fields',
  highRiskRwFieldTooltip:
    'Detection of 6+ eggs indicates high risk for root feeding. Likely ranges from 0.5-2.5 node injury average without protection.',
  history: 'History',
  homogen: 'Homogen',
  homogenBarcode: 'Homogen Barcode',
  homogenization: 'Homogenization',
  homogenizationComplete: 'Homogen Complete',
  howWouldyouLikeToSignIn: 'How would you like to sign in?',
  hybrid: 'Hybrid',
  imageLabel: 'Image Label',
  importData: 'Import Data',
  in_furrow: 'In Furrow',
  in_furrowMissing:
    'In-furrow protection options have not been added to crop plan. Click to review',
  incentive: 'Incentive',
  include: 'Include',
  incomplete: 'Incomplete',
  incorrectImageDimensions: 'Image dimensions must be at least 1x1.',
  incorrectPassword: 'The Old Password you entered is incorrect.',
  indexSet: 'Index Set',
  indexSets: 'Index Sets',
  indicatePlateStorageMsg: 'Please indicate where and how the plate will be stored',
  inFurrow: 'In furrow',
  inFurrowProducts: 'In-Furrow Products',
  ingested: 'Ingested',
  initiatedExportFailMsg: 'Failed to initiate export. Please refresh and try again.',
  inPayment: 'In Payment',
  inProgress: 'In Progress',
  input: 'Input',
  inputSavings: 'Input Savings',
  insecticide: 'Insecticide',
  inShipment: 'In Shipment',
  insufficient_data_performance: 'Insufficient Data (Performance)',
  insufficient_data: 'Insufficient Data',
  insufficient_layers_tillage: 'Insufficient Tillage Layers',
  insufficientDataFound: 'Insufficient Data Found',
  insufficientDataWarning:
    'More than 25% of your field has insufficient data and you will be receiving a refund. Zones marked N/A do not have sufficient data.',
  interLabShipping: 'Inter-Lab Shipping',
  invalidCellPhoneMsg: 'Please enter a valid cell phone number.',
  invalidCornPriceMsg: 'Please make sure your corn price is between $2 and $10.',
  invalidCornYieldMsg: 'Please make sure your corn yield is between 100 and 600.',
  invalidFile: 'Invalid File Uploaded',
  invalidSoybeanPriceMsg: 'Please make sure your soybean price is between $5 and $20.',
  invalidSoybeanYieldMsg: 'Please make sure your soybean yield is between 20 and 100.',
  invalidZipcode: 'Invalid zip code',
  invalidZoneComboError: 'Invalid zone combination.',
  invite: 'Invite',
  inviteAgentCreateAcctMsg:
    'Invite an agent to create an account and work with your agency customers',
  inviteByEmail: 'Invite users by email',
  inviteNewUser: 'Invite New User',
  inviteSampler: 'Invite Sampler',
  inviteUserMsg:
    'The user you are inviting does not have an account with Pattern yet. Please provide some more information about them to complete the invite',
  invoice: 'Invoice',
  is_field_nd: 'Is Field ND',
  is_field_result_binary: 'Is Field Result Binary',
  is_overview_nd: 'Is Overview ND',
  is_sample_nd: 'Is Sample ND',
  isExistingAgency: 'Is this an existing agency?',
  isolates: 'Isolates',
  isThisPartOfExisting: 'Is this part of an existing agency?',
  isuLimeTo6: 'ISU Lime to 6.0',
  isuLimeTo65: 'ISU Lime to 6.5',
  isuLimeTo69: 'ISU Lime to 6.9',
  isuPhosphorus: 'ISU Phosphorus',
  isuPotassium: 'ISU Potassium',
  john_deere: 'John Deere',
  kclAnalysis: 'KCL Analysis',
  kclRerun: 'KCL Rerun',
  lab: 'Lab',
  labApp: 'Lab App',
  label: 'Label',
  labelCount: 'Label Count',
  labelDeletedSuccessMsg: 'Labels successfully deleted!',
  labelDownloads: 'Label Downloads',
  labels: 'Labels',
  labId: 'Lab ID',
  labShipmentId: 'Lab Shipment ID',
  lane: 'Lane',
  language: 'Language',
  lastName: 'Last Name',
  lastSync: 'Last Sync',
  lastUpdated: 'Last Updated',
  latitude: 'Latitude',
  leaveOperation: 'Leave Operation',
  legend: 'Legend',
  length: 'Length',
  letsSetYourPassword: 'Let’s set your password',
  level: 'Level',
  levels: 'Levels',
  levelsIndicateHighRisk: 'levels indicate higher risk',
  levelsIndicateModerateRisk: 'levels indicate moderate risk',
  levelsIndicateYieldLoss: 'levels indicate risk for yield loss',
  lime: 'Lime',
  lineItemDetail: 'Line Item Detail',
  list: 'List',
  listPrice: 'List Price',
  loading: 'Loading',
  location: 'Location',
  locations: 'Locations',
  locked: 'Locked',
  lockedInAcres: 'Locked In Acres',
  lockInAcresNow: 'Lock in acres now to ensure timely sampling',
  lockInOfferErrorMsg: 'There was an error locking in your offer. Please try again.',
  lockInOfferToday: 'Lock In Offer Today',
  lockPoints: 'Lock Points',
  lockZones: 'Lock Zones',
  login: 'Log In',
  loginToQuickbooks: 'Login to Quickbooks',
  logout: 'Logout',
  longitude: 'Longitude',
  lossOnIgnition: 'Loss On Ignition',
  low: 'Low',
  lowRisk: 'Low Risk',
  lowRiskAcres: 'Low Risk Acres',
  lowRiskAreas: 'Low Risk Areas',
  lowRiskFields: 'Low Risk Fields',
  lowRiskRwFieldTooltip:
    'Detection of less than 0.2 eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  lowRiskRwZoneTooltip:
    'No detection of eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  m3Analysis: 'M3 Analysis',
  m3Rerun: 'M3 Rerun',
  macronutrients: 'Macronutrients',
  manage: 'Manage',
  manageAccount: 'Manage Account',
  manageAgencies: 'Manage Agencies',
  manageAgency: 'Manage Agency',
  manageAgencyLocations: 'Manage Agency Locations',
  manageAgents: 'Manage Agents',
  manageAnalytics: 'Manage Analytics',
  manageCatalogs: 'Manage Catalogs',
  manageCommercialUsers: 'Manage Commercial Users',
  manageControls: 'Manage Controls',
  manageFields: 'Manage Fields',
  manageLocations: 'Manage Locations',
  manageOrders: 'Manage Orders',
  manageParentAgency: 'Manage Parent Agency',
  manageUsers: 'Manage Users',
  manual: 'Manual',
  manualRecord: 'Manual Record',
  manufacturer: 'Manufacturer',
  mapbookConfiguration: 'Mapbook Configuration',
  mapbookDownloads: 'Mapbook Downloads',
  mapbookGenerationFailedMsg: 'Mapbook generation failed. Please try again.',
  mapFields: 'Map Fields',
  mapFieldsToOrder: 'Map fields to place an order',
  mapSearchPlaceholder: 'Search city, county etc',
  mapYourPressure:
    '<b>Map your pressure:</b> Pattern measures your soil biology to create <b>sub-field maps of pest and disease pressure.</b>',
  markArrived: 'Mark Arrived',
  markAsDelivered: 'Mark as Delivered',
  markBatchConfirmMsg: 'Are you sure you want to mark this batch as sent?',
  markBatchSent: 'Mark Batch Sent',
  markBatchWarningMsg:
    'Please note this batch will no longer be editable and the sample sheets will be locked!',
  marked_not_ready: 'Marked Not Ready',
  marked_ready_days: 'Marked Ready (Days)',
  marked_ready: 'Marked Ready',
  markedReadyAssignedMsg: 'When marked ready, a sampler will be assigned',
  markedReadyFailureMsg: 'There was an error marking the plan ready. Please refresh and try again.',
  markedReadySuccessMsg: 'The plan was successfully marked ready.',
  markNotArrived: 'Mark Not Arrived',
  markNotReady: 'Mark Not Ready',
  markNotReadyFail:
    'There was an error marking the plan as not ready. Please refresh and try again.',
  markNotReadySuccess: 'The plan was successfully marked as not ready.',
  markPlanDelivered: 'Mark Plans Delivered',
  markReady: 'Mark Ready',
  markReadyMsg: 'Clicking this button will mark this field as Ready to Sample',
  markRecorded: 'Mark Recorded',
  markSettled: 'Mark Settled',
  markSettledMsg:
    'By marking this payment as settled, you are aknowledging that this payment has been entered into Pattern Ag’s accounting records, and has been handled according to the details outlined below:',
  markSterilized: 'Mark Sterilized',
  mass: 'Mass',
  max: 'Max',
  maxAcres: 'Max Acres',
  maxAcresForEnrollment: 'Max Acres for enrollment',
  maxDepth: 'Max Depth',
  maximumRate: 'Maximum rate',
  maxPsi: 'Max PSI',
  mehlich: 'Mehlich',
  merge: 'Merge',
  mergeFields: 'Merge Fields',
  mergePlates: 'Merge Plates',
  mergeZones: 'Merge Zones',
  mergeZonesSuccess: 'Successfully merged zones!',
  method: 'Method',
  micronutrients: 'Micronutrients',
  minDepth: 'Min Depth',
  minimalNumberPointsMsg: 'Please add more points in order to submit the plan.',
  minimalProPointsMsg:
    'Pro requires a minimum of 4 samples per field - either remove Pro from your order or increase sample count.',
  minimumRate: 'Minimum rate',
  minutesAbbreviated: 'min',
  missingCountyMsg: 'Please select a county.',
  missingCropDetailsMsg: 'Please fill out all crop information.',
  missingInfoMsg: 'Please fill out all information before saving.',
  missingOperationIdMsg: 'Please create or choose an existing operation.',
  missingSelectionOverlapMsg: 'Missing overlap between field and selection.',
  missingUrlMsg: 'Missing upload_url',
  mod: 'Mod',
  moderate: 'Moderate',
  moderateRisk: 'Moderate Risk',
  moderateRiskFields: 'Moderate Risk Fields',
  moderateRiskRwFieldTooltip:
    'Detection from 0.2-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  moderateRiskRwZoneTooltip:
    'Detection of 0-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  modifiedMehlich: 'Modified Mehlich',
  modRisk: 'Mod Risk',
  moisture: 'Moisture',
  msrp: 'MSRP',
  myAccounts: 'My Accounts',
  myAgencies: 'My Agencies',
  myAlerts: 'My Alerts',
  myFarmPlan: 'My Farm Plan',
  myReport: 'My Report',
  name: 'Name',
  naptControls: 'NAPT Controls',
  nematicide: 'Nematicide',
  netPayment: 'Net Payment',
  neutralization: 'Neutralization',
  new: 'New',
  newAgencyName: 'New Agency Name',
  newAnalytic: 'New Analytic',
  newBatch: 'New Batch',
  newFieldName: 'New Field Name',
  newOrParentContract: 'New or Parent Contract?',
  newPassConf: 'New Password Confirmation',
  newPassConfMissing: 'New password confirmation is missing.',
  newPassMissing: 'New password is missing.',
  newPassword: 'New Password',
  newPlate: 'New Plate',
  newPlateBarcode: 'New Plate Barcode',
  newRiskEstimate: 'New Risk Estimate',
  newTerritory: 'New Territory',
  next: 'Next',
  nextStep: 'Next Step',
  nitrate_panel: 'Nitrate Panel',
  nitrate: 'Nitrate',
  nitrateAnalysis: 'Nitrate Analysis',
  nitrateAnalysisMsg: 'Add nitrate testing to each zone where nutrient analysis will be completed',
  nitratePanel: 'Nitrate Panel',
  no: 'No',
  noAgenciesExist: 'No agencies exist',
  noAnalysis: 'No analysis',
  noBatchesFound: 'No Batches Found',
  noBillingUserMsg: 'Please add a Billing Responsibility before placing orders',
  noChemistryResultsMsg: 'No chemistry results for this sample uuid',
  noContractsExistMsg: 'No contracts exist for this agency.',
  noData: 'No Data',
  noDataForFieldAnalytic: 'There is no data for this field/analytic.',
  noDataToReview: 'No Data To Review',
  noDealer: 'No dealer',
  noDiscount: 'No Discount',
  noFileChosen: 'No File Chosen',
  noFilesListMsg: 'No files to display.',
  noIndexSetsFound: 'No Index Sets Found',
  noInstructionsAvailable: 'No instructions available.',
  noLabelsListMsg: 'No labels found',
  noLogMeOut: 'No, log me out',
  non_pro_till_rx: 'Non-Pro/TillMapper',
  none: 'None',
  noNitrate: 'No Nitrate',
  nonPrimary: 'Non-Primary',
  noOrderHistoryMsg: 'No order history found for current year',
  noPlatesFoundMsg: 'No plates found',
  noPlatesListMsg: 'No plates to display.',
  noProductsToDisplay: 'No Products To Display',
  noResultsExistForField: 'No results exist for this field.',
  noResultsExistForOperation: 'No results exist for this operation.',
  noResultsForCategory: 'No results for this category.',
  noRiskCancel: 'No risk, cancel anytime before sampling',
  normalizedDna: 'Normalized DNA',
  normDna: 'Norm DNA',
  northCornLeafBlight: 'North Corn Leaf Blight',
  noSamplesInBatch: 'No samples in batch.',
  noSamplingHistoryMsg: 'No sampling history found for current year',
  noSeedToDisplay: 'No Seed To Display',
  noShipmentsFoundMsg: 'No Shipments Found',
  not_analyzed: 'Not Analyzed',
  not_detected: 'Not Detected',
  not_ready: 'Not Ready',
  notAnalyzed: 'Not Analyzed',
  notAssignedNotReady: 'Not Assigned + Not Ready',
  notDetected: 'Not Detected',
  notes: 'Notes',
  notOrdered: 'Not Ordered',
  notReady: 'Not Ready',
  notSentToNetsuiteMsg: 'Operation sampling plans will not be billed in NetSuite.',
  notStarted: 'Not Started',
  notSyncedYet: 'Not Synced yet',
  notTerritoryOwner:
    'Your assigned territory was not created by you. Press clear to reset the map if you would like to draw your own.',
  noUnits: 'no units',
  noUserFoundMsg: 'No user found with that email.',
  noUserWithBillingResMsg:
    'There is no user with billing responsibility for this account. You will be able to create sample plans for this account, but will not be able collect samples in field until a user has been added here.',
  noWeNeedToCreate: 'No, we need to create a new agency for them.',
  noZonesSelected: 'No zones selected for analysis',
  numberOfLabels: 'Number of labels',
  nutrient_analysis: 'Nutrient Analysis',
  nutrient_panel_description_plus_nitrate:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter, plus Nitrate testing',
  nutrient_panel_description:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter',
  nutrient_panel_list_high_msg: 'Pattern 360 list price is too high.',
  nutrient_panel_list_low_msg: 'Pattern 360 list price is too low.',
  nutrient_panel: 'Nutrient Panel',
  nutrient: 'Nutrient',
  nutrientDetails: 'Nutrient Details',
  nutrientPanel: 'Nutrient Panel',
  nutrientPoints: 'Nutrient Points',
  nutrientProcessing: 'Nutrient Processing',
  nutrientRx: 'Nutrient Rx',
  nutrients: 'Nutrients',
  nutrientSamples: 'Nutrient Samples',
  of: 'of',
  off: 'Off',
  offer: 'Offer',
  oldPassMissing: 'Old password is missing.',
  oldPassword: 'Old Password',
  olsen: 'Olsen',
  omAnalysis: 'OM Analysis',
  omniRevvityDescription: 'omni lysis and magbead with TBD method for chelating metal ions',
  omniRevvityPbWashDescription: 'omni lysis and magbead with PB wash',
  omRerun: 'OM Rerun',
  on: 'On',
  only: 'Only',
  open: 'Open',
  openPlate: 'Open Plate',
  openRisks: 'Open Risks',
  openTransactions: 'Open Transactions',
  operation: 'Operation',
  operationCountryMsg: 'This is the country where the operation is located.',
  operationDetails: 'Operation Details',
  operationName: 'Operation Name',
  operationNoDiscountsMsg: 'This operation has no discount programs.',
  operations: 'Operations',
  operationSavedMsg: 'Operation Saved successfully!',
  operationSummary: 'Operation Summary',
  operator: 'Operator',
  operatorEmail: 'Operator Email',
  operatorName: 'Operator Name',
  optimal: 'Optimal',
  optimizedPlansErrorMsg: 'Error optimizing plans. Please refresh and try again.',
  optimizedPlansSuccessMsg: 'Plans optimized successfully!',
  optimizeInputs: "Optimize inputs based on Pattern 360 report on 'at risk' fields",
  optimizePlans: 'Optimize Plans',
  optimizeSpend: 'Optimize spend',
  optimizeTillage: 'Optimize tillage decisions and understand sub-field compaction with TillMapper',
  optimizingPlans: 'Optimizing Plans',
  optional: 'optional',
  options: 'Options',
  optOutText:
    'You have successfully opted out of receiving notifications from Pattern Ag via text message. Please allow 24 hours for changes to take effect.',
  or: 'Or',
  order: 'Order',
  orderAdditionalSamplePlan: 'Add Additional Sample Plan',
  ordered: 'Ordered',
  orderHistory: 'Order History',
  orders: 'Orders',
  orderSample: 'Order Sample',
  orderSamplePlan: 'Order Sample Plan',
  orderSamplingPlan: 'Order Sampling Plan',
  orderTillMapperNow: 'Order TillMapper Now',
  orYouCan: 'Or you can',
  other: 'Other',
  outputFormula: 'Output format',
  overrideInputsMsg: 'All input selections will be overridden on all crop plans!',
  overview: 'Overview',
  overviewOnly: 'Overview Only',
  owner: 'Owner',
  panel: 'Panel',
  panelsOrdered: 'Panels Ordered',
  parcelDimensions: 'Parcel Dimensions',
  parent_agency: 'Parent Agency',
  parentAgency: 'Parent Agency',
  parentPlates: 'Parent Plates',
  partial: 'Partial',
  partially_sampled_long_duration: 'Partially Sampled (Long Duration)',
  partiallyFilled: 'Partially Filled',
  partiallyShipped: 'Partially Shipped',
  partialResults: 'Partial Results',
  partner: 'Partner',
  password: 'Password',
  passwordReqMsg: 'Must contain 8 characters, including an uppercase letter and a number',
  passwordRules: 'Note: Must contain an uppercase letter, lowercase letter, and a number',
  pastFileUpload: 'Past file upload',
  pattern_360: 'Pattern 360',
  pattern360Msg:
    'Combine Complete Bio with our comprehensive macro and micronutrient analysis to identify nutrient deficiencies and balance your soil (not available for regulated soil at this time)',
  patternAgDSCANumber: 'Pattern Ag DSCA Number',
  patternAgPays: 'Pattern Ag Pays',
  patternAgPersonalizedReport:
    'Your Pattern 360 report will provide personalized analysis and recommendations for your farm.',
  patternIsSyncing: 'Pattern Ag is syncing with your account. (This can take a few minutes)',
  patternReportAnalyzes:
    'The Pattern 360 report analyzes your soil to <b>predict next year’s risk</b> from damaging pests & diseases.',
  payment_due: 'Payment Due',
  payment: 'Payment',
  payments: 'Payments',
  paymentTerms: 'Payment Terms',
  payorPayee: 'Payor / Payee',
  paysPatternAg: 'Pays Pattern Ag',
  pcrDnaMultiplier: 'PCR DNA Multiplier',
  PDFCropSelectMsg: 'If no crops selected, only the upcoming crop will be shown for each field.',
  PDFFieldsDisabledMsg: 'Fields will be disabled if results do not exist yet.',
  pdfRegenerationInitiatedErrorMsg: 'Error initiating PDF regeneration. Please try again.',
  pdfRegenerationInitiatedMsg: 'Mapbook PDF regeneration initiated!',
  pending: 'Pending',
  pendingDelivery: 'Pending Delivery',
  per: 'Per',
  percentCompacted: '% Compacted',
  percentCompactedTooltip: '% > 300 PSI (0"-12")',
  percentOfFieldsAtModerateOrHighRisk: 'Percent of Fields at Moderate or High Risk',
  percentTargetApply: 'Percent of target to apply',
  performAnalysisSingleDensity: 'Perform all analysis at a single density',
  performance_panel_description:
    "Map your soil's disease risk, and biofertility opportunity, to optimize input spend",
  performance_panel_list_high_msg: 'Complete Bio list price is too high.',
  performance_panel_list_low_msg: 'Complete Bio list price is too low.',
  performance_panel: 'Performance Panel',
  performance: 'Performance',
  performancePanel: 'Performance Panel',
  perfPanel: 'Perf Panel',
  perSampleIndicates: 'per sample indicates',
  phAnalysis: 'pH Analysis',
  phone: 'Phone',
  phosphate: 'Phosphate',
  phosphateAnalysis: 'Phosphate Analysis',
  phosphateRerun: 'Phosphate Rerun',
  phosphorus: 'Phosphorus',
  phRerun: 'pH Rerun',
  pipeline: 'Pipeline',
  planConfMsg:
    "By clicking 'Confirm Order' you are agreeing to purchase the services outlined above for this operation. Payment will be due 2 weeks after results are made available.",
  planDetails: 'Plan Details',
  planMarkedDeliveredSuccess: 'Plans marked as delivered successfully!',
  planName: 'Plan Name',
  planNames: 'Plan Name(s)',
  plannedCrop: 'Planned Crop',
  plannedSamples: 'Planned Samples',
  planning: 'Planning',
  planStatus: 'Plan Status',
  planSummary: 'Plan Summary',
  planZonesErrorDiffMsg: 'You may have uploaded a different field.',
  planZonesErrorNoZipMsg: 'You must upload a zipped file containing a ".shp" file.',
  planZonesErrorNoZonesMsg: 'This upload does not contain zones.',
  planZonesSuccessMsg: 'Zones have successfully been updated!',
  plate: 'Plate',
  plateAddedToBatch: 'Plate Added To Batch',
  plateArrived: 'Plate Arrived',
  plateBarcode: 'Plate Barcode',
  plateBarcodeId: 'Plate Barcode / ID',
  plated: 'Plated',
  plateDetails: 'Plate Details',
  plateHasBatchErrorMsg:
    'Plate already exists in another batch. Adding the plate to this batch will remove it from the previous batch',
  plateNotAddedToBatch: 'Plate Not Added To Batch',
  plates: 'Plates',
  plateType: 'Plate Type',
  platinum: 'Platinum',
  pleaseRefreshUnableToConnect: 'Please Refresh and try again. We were unable to connect you to',
  pointResults: 'Point Results',
  points: 'Points',
  poolBarcode: 'Pool Barcode',
  portuguese: 'Portuguese',
  postOperationCropInfoErrorMsg:
    'There was an error updating the crop information. Please refresh and try again.',
  potassium: 'Potassium',
  predicted_ready: 'Predicted Ready',
  predictYourRisk:
    '<b>Predict your risks:</b> Pest & disease maps <b>predicts your risk of yield loss</b> next season.',
  prep: 'Prep',
  preparedBy: 'Prepared by',
  preplant: 'Pre-plant',
  prescriptionCreatedMsg: 'Prescription created!',
  prescriptionDownloadFailMsg: 'Failed to download prescription. Please refresh and try again.',
  prescriptionDownloadSuccessMsg: 'Prescription downloaded successfully!',
  prescriptions: 'Prescriptions',
  prescriptionSaved: 'Prescription saved',
  pressure_nutrient_panels: 'Pressure Panel + Nutrient',
  pressure_panel_description_rkn:
    'Predict and plan for Root Knot Nematode, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  pressure_panel_description:
    'Predict and plan for Rootworm, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  pressure_panel_list_high_msg: 'Pressure Panel list price is too high.',
  pressure_panel_list_low_msg: 'Pressure Panel list price is too low.',
  pressure_panel_only: 'Pressure Panel',
  pressure_panel: 'Pressure Panel',
  pressure: 'Pressure',
  pressureAndPerformancePanelsZones: 'Pressure & Performance Panels Zones',
  pressureNutrientPanel: 'Pressure Panel + Nutrient Panel',
  pressureNutrientPanelsDescription:
    'Our Pressure Panel plus our comprehensive macro and micronutrient analysis',
  pressurePanel: 'Pressure Panel',
  preTransit: 'Pre Transit',
  price: 'Price',
  priceList: 'Price List',
  pricePerTon: 'Price per ton',
  primary: 'Primary',
  primaryAgent: 'Primary Agent',
  primaryCounty: 'Primary County',
  print: 'Print',
  printAll: 'Print All',
  printHeader: 'Your Soil Is Talking, It’s Time To Listen',
  printLabel: 'Print Label',
  printOptions: 'Print Options',
  printQRCode: 'Print QR Code',
  printSplitDensityBarcode: 'Print Split Density Barcode',
  printSummary: 'Print Summary',
  privacyPolicy: 'Privacy Policy',
  pro_map_generation: 'Pro Map Generation',
  pro_only: 'Pro Only',
  pro_sensor_scan: 'Pro Sensor Scan',
  pro: 'Pro',
  pro10Res: 'Pro (10x10 meter resolution)',
  process: 'Process',
  processed: 'Processed',
  processing: 'Processing',
  processingData: 'Processing Data',
  processingSummary: 'Processing Summary',
  product: 'Product',
  productAdmin: 'Product Admin',
  productOrdered: 'Product Ordered',
  profileSettings: 'Profile Settings',
  programToEnrollMsg: 'Which program do you want to enroll?',
  proResults: 'Pro Results',
  protection: 'Protection',
  provideNotesMsg: 'Provide notes for the uploaded document',
  puppeteerRunningMsg: 'Generating Mapbook Results (Not all fields are ready)',
  purchase: 'Purchases',
  purchases: 'Purchases',
  qpcrAnalysis: 'qPCR Analysis',
  qpcrCycles: 'qPCR Cycles',
  quadrant: 'Quadrant',
  rack: 'Rack',
  range: 'Range',
  ranges: 'Ranges',
  ranging: 'ranging',
  rating: 'Rating',
  rdAttributes: 'R&D Attribiutes',
  rdProtocols: 'R&D Protocols',
  readiness: 'Readiness',
  reads: 'Reads',
  readThresholdUpdateFailureMsg: 'Read Threshold Update Failed',
  readThresholdUpdateSuccessMsg: 'Read Threshold Update Successful',
  ready: 'Ready',
  readyToSample: 'Ready To Sample',
  readyToScan: 'Ready to Scan',
  reasonForAdjustments: 'What is the reason for the adjustment?',
  reassign: 'Reassign',
  recalculate: 'Recalculate',
  received: 'Received',
  receivingDataEntry: 'Receiving & Data Entry',
  recenter: 'Recenter',
  recommendation: 'Recommendation',
  recommendationDefaults: 'Recommendation Defaults',
  recommendationSets: 'Recommendation Sets',
  recordedBy: 'Recorded By',
  reducedNoTill: 'Reduced / No-Till',
  referenceLink: 'Reference Link',
  referencesAndResources: 'References and Resources',
  regenerateMapbookPDFsMsg: 'Regenerate Mapbook PDFs (Outdated resuslts showing)',
  region: 'Region',
  regulated: 'Regulated',
  regulatedMaterial: 'Regulated Material',
  regulatedMaterialMsg:
    'If shipping regulated material, ensure that ONLY regulated materials are in the shipment',
  regulatedSoil: 'Regulated Soil',
  regulatedSoilMsg:
    'If this sample plan contains regulated soil, please review the required protocols and ensure that you are completing each step prior to shipment',
  relatedAgencies: 'Related Agencies',
  relatedDocuments: 'Related Documents',
  relatedRsms: 'Related RSMs',
  relativeNeutralization: 'Relative Neutralization',
  reloadClus: 'Reload CLUs',
  remove: 'Remove',
  removeAccess: 'Remove Access',
  removeAgencyConnectionMsg:
    'This will remove the agency access from this operation. However, any agents directly connected to this operation will still have access.',
  removeAgencyLocation: 'Remove Agency/Location?',
  removeAgencyLocationWarning: 'This will remove the agency/location connection. Are you sure?',
  removeAnalyticFromView: 'Remove this analytic from view?',
  removeBillingPartyMsg:
    'Are you sure you want to remove this user from billing responsibility for this account? Without a user responsible for billing on the account, you will not be able to collect samples in the field.',
  removeBillingResponsibility: 'Remove Billing Responsibility',
  removedFromPlan: 'Removed from plan',
  removeFieldMsg:
    'Removing this field from your operation will mean the field and its associated data is no longer available to you. This cannot be undone.',
  removeSample: 'Remove Sample',
  removeSearchBounds: 'Remove search bounds',
  removeSelected: 'Remove Selected',
  replicaType: 'Replica Type',
  report: 'Report',
  requestFailed: 'Request failed. Please try again later.',
  requiredField: 'Required Field',
  rerun: 'Rerun',
  reselectDensity: 'Reselect Density',
  resendCode: 'Resend code',
  resetMap: 'Reset Map',
  resetPoints: 'Reset Points',
  residue: 'Residue',
  resistanceOptions: 'Resistance Options',
  restricted_panel_description:
    'Nutrient analysis is not available for restricted soil at this time',
  restrictedGeography: 'Restricted Geography',
  restrictedGeographyFromMsg: `Sample is from`,
  restrictedGeogrpahyProtocolsMsg: `Follow proper handling protocols`,
  resultInPatternInvoiceMsg: 'This will result in Pattern Ag invoicing',
  resultInPatternPaymentMsg: 'This will result in Pattern Ag making payment to',
  results: 'Results',
  resultsDownloadFailure: 'Error downloading results. Try again later.',
  resultsDownloadSuccess: 'Results download has been completed successfully.',
  resultsUploaded: 'Results Uploaded',
  return: 'Return',
  returned: 'Returned',
  revenue: 'Revenue',
  reverseIndexSequence: 'Reverse Index Sequence',
  reversePrimer: 'Reverse Primer',
  rhizosphere: 'Rhizosphere',
  risk: 'Risk',
  riskEstimate: 'Risk Estimate',
  riskMap: 'Risk Map',
  rndAttributes: 'R&D Attributes',
  rndProtocols: 'R&D Protocols',
  rndSample: 'R&D Sample',
  role: 'Role',
  rootBall: 'Root Ball',
  rootTissue: 'Root Tissue',
  rootworm: 'Rootworm',
  rotate: 'Rotate',
  runPlanned: 'Run Planned',
  runSheetDownloadFailed: 'Failed to download run sheet. Try again later.',
  rwSingle: 'RW Single',
  rwStack: 'RW Stack',
  rx: 'Rx',
  rxDetails: 'Rx Details',
  rxName: 'Rx Name',
  sample: 'Sample',
  sampleAlreadyExistsMsg:
    "That barcode is associated with an existing sample. If this is meant to be a replicate sample, please go to 'Add Sample'. If you intend to create a new sample in the system, please choose a different barcode.",
  sampleAlreadyInWellMsg: 'Sample already exists in another well, please use a replicate',
  sampleBarcode: 'Sample Barcode',
  sampleCancelledFailureMsg: 'Cancelling the sample failed',
  sampleCancelledSuccessMsg: 'Sample has been cancelled successfully',
  sampled_not_shipped: 'Sampled, not shipped',
  sampled: 'Sampled',
  sampleDate: 'Sample Date',
  sampledBy: 'Sampled By',
  sampleExecution: 'Sample Execution',
  sampleMass: 'Sample Mass',
  sampleNotFound: 'Sample Not Found',
  sampleNotFoundMsg: 'No sample instance matching this barcode was found',
  sampleOnly: 'Sample Only',
  samplePlan: 'Sample Plan',
  samplePlanCancelledSuccess: 'Sampling plan cancelled!',
  samplePlanDetails: 'Sample Plan Details',
  samplePlanReadThreshold: 'Sample Plan Read Threshold',
  samplePlans: 'Sample Plans',
  samplePlansAwaiting: 'Sample Plans Awaiting Shipment',
  samplePlanStatus: 'Sample Plan Status',
  samplePlanTracking: 'Sample Plan Tracking',
  samplePlusScan: 'Sample + Scan',
  samplePricing: 'Sample Pricing',
  sampler: 'Sampler',
  samplerInstructions: 'Sampler Instructions',
  samplerNotes: 'Sampler Notes',
  samplerOnly: 'Sampler Only',
  sampleRisk: 'Sample Risk',
  samples: 'Samples',
  samples_not_complete: 'Samples Not Complete',
  sampleSheet: 'Sample Sheet',
  samplesInPool: 'Samples in Pool',
  samplesTaken: 'Samples Taken',
  sampleStatus: 'Sample Status',
  sampleSummary: 'Sample Summary',
  sampleTiming: 'Sample timing',
  sampleType: 'Sample Type',
  sampling_at_risk: 'Sampling at Risk',
  sampling_overdue: 'Sampling Overdue',
  sampling: 'Sampling',
  samplingAt: 'Sampling At',
  samplingHistory: 'Sampling History',
  samplingPlan: 'Sampling Plan',
  samplingTiming: 'Sampling Timing',
  samplingPlanUpdatedFailureMsg: 'Sampling plan update failed. Please refresh and try again.',
  samplingPlanUpdatedMsg: 'Sampling plan updated successfully!',
  samplingZones: 'Sampling Zones',
  save: 'Save',
  saveBillingInfo: 'Save Billing Info',
  saveChanges: 'Save Changes',
  saveContactInfo: 'Save Contact Info',
  saveCostsByTilling: 'Save costs by tilling only where you need to',
  saved: 'Saved',
  scan: 'Scan',
  scanBarcodeMsg: 'Scan Plate Barcode',
  scanDataConfirmed: 'Scan data confirmed',
  scanDataReceived: 'Scan data have been received',
  scanHistory: 'Scan History',
  scanned: 'Scanned',
  scannedBy: 'Scanned By',
  scanner: 'Scanner',
  scanNewBarcodeMsg: 'Scan New Plate Barcode',
  scanningAt: 'Scanning At',
  scanningTime: 'Scanning Time',
  scanOnly: 'Scan Only',
  scanTimeframe: 'Once scans are confirmed, results should be available within an hour',
  scn: 'SCN',
  score: 'Score',
  sds: 'SDS',
  sdsFungicide: 'SDS + Fungicide',
  sdsFungicideNematicide: 'SDS + Fungicide + Nematicide',
  sdsNematicide: 'SDS + Nematicide',
  sdsuLime: 'SDSU Lime',
  sdsuPhosphorusBray: 'SDSU/MN Phosphorus (Bray)',
  sdsuPhosphorusOlsen: 'SDSU/MN Phosphorus (OLSEN)',
  sdsuPotassium: 'SDSU/MN Potassium',
  searchAccount: 'Search for An Account',
  searchAccountOrViewAll: 'Search for an Account or View All',
  searchAgency: 'Search Agency',
  searchAgencyName: 'Search Agency Name',
  searchAnalytic: 'Search Analytic',
  searchByAccount: 'Search By Account',
  searchByBarcode: 'Search By Barcode',
  searchByUser: 'Search by user',
  searchByUserAgency: 'Search by user/agency',
  searchCounty: 'Search County',
  searchCountyError: 'An error occurred while searching for the specified county. Please Refresh.',
  searchCurrentBounds: 'Search current bounds',
  searching: 'Searching',
  searchOperation: 'Search Operation',
  searchOperationOrAgency: 'Search Operation or Agency',
  searchPayments: 'Search Payments',
  searchSampleMsg: 'Search for a sample by barcode or UUID',
  searchSampler: 'Search sampler',
  searchSamplerScanner: 'Search sampler/scanner',
  searchUsers: 'Search Users',
  season: 'Season',
  secondaryAnalytic: 'Secondary Analytic',
  secondaryAnalyticMsg:
    'This analytic will be displayed if the current analytic does not have results.',
  seed: 'Seed',
  seedCatalog: 'Seed Catalog',
  seedMissing: 'Hybrid options have not been added to crop plan. Click to review',
  seedResistance: 'Seed Resistance',
  seedRisks: 'Seed Risks',
  seedTreatment: 'Seed Treatment',
  seedTreatments: 'Seed Treatments',
  seeHowItWorks: 'See How It Works',
  select: 'Select',
  selectAgencyMsg: 'Select An Agency To View',
  selectAgencyReassignMsg: 'Select the Agency they should belong to or create a new one',
  selectAgencyToBeginMsg: 'To Start, Select An Agency From the Dropdown Above',
  selectAlertsToDismiss: 'Select Alerts to Dismiss',
  selectAlertsToSnooze: 'Select Alerts to Snooze',
  selectAll: 'Select All',
  selectClus: 'Select CLUs',
  selectDiscountProgram: 'Select a Discount Program',
  selectExternalLab: 'Select an external lab',
  selectFieldsToMerge: 'Select field boundaries to merge that have not had previous sample plans.',
  selectFieldsToSplit: 'Select field boundaries to split that have not had previous sample plans.',
  selectFileToUploadMsg: 'Please select a file to upload',
  selectOne: 'Select One',
  selectProductCatalog: 'Select Product Catalog',
  selectZoneDensity: 'Select Zone Density',
  selectZones: 'Select Zones',
  selectZonesToMerge: 'Select zones to merge.',
  selfSampling: 'Self Sampling',
  selfScanning: 'Self Scanning',
  seller: 'Seller',
  sendCodeTo: 'Send code to',
  sendRequestInstructions: 'Send Reset Instructions',
  sendTo: 'Send To',
  sequencedData: 'Sequenced Data',
  serverErrorMsg: 'An error occurred with the server, please refresh and try again.',
  setPassword: 'Set Password',
  settings: 'Settings',
  settled: 'Settled',
  setupASamplePlan:
    'To set up a sample plan, you need to map field boundaries. You can do this a number of ways:',
  setupZonesInstructionsMsg:
    'Set up zones for Bio analysis, then generate points for Nutrient analysis and Pro scanning',
  shapefile: 'Shapefile',
  shapefileDownloadMsg:
    'This can take 20 minutes or more. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  shapefileDownloadSuccess: 'Shapefile download has been completed successfully',
  shelf: 'Shelf',
  shipmentCreatedMsg: 'Shipment Created Successfully',
  shipmentUpdatedMsg: 'Shipment Updated Successfully',
  shipped: 'Shipped',
  shippedDate: 'Shipped Date',
  shippedLabels: 'Shipped Labels',
  shipping: 'Shipping',
  shippingFrom: 'Shipping From',
  shippingLabel: 'Shipping Label',
  shippingLabels: 'Shipping Labels',
  shippingLabelsInProgress: 'Shipping Labels in Progress',
  shippingRegulatedSoil: 'Shipping Regulated Soil',
  shippingTo: 'Shipping To',
  shotgun: 'Shotgun',
  shotgunAnalysis: 'Shotgun Analysis',
  shotgunQpcr: 'Shotgun + qPCR',
  show: 'Show',
  showDcpPoints: 'Show DCP points',
  showing: 'Showing',
  showInUI: 'Show in UI',
  showMe: 'Show Me',
  showPastPlans: 'Show Past Sample Plans',
  sidedress: 'Sidedress',
  signed: 'Signed',
  signedAt: 'Signed At',
  signIn: 'Sign In',
  signInDifferentWay: 'Sign in a different way',
  signInWithPassword: 'Sign in with password',
  sikora: 'Sikora',
  silver: 'Silver',
  sla_at_risk: 'SLA at Risk',
  sla_overdue: 'SLA Overdue',
  snoozeAlerts: 'Snooze Alerts',
  soil: 'Soil',
  soilAnalysis: 'Soil Analysis',
  soilCollection: 'Soil Collection',
  soilDilution: 'Soil Dilution',
  soilProcessing: 'Soil Processing',
  soilScan: 'Soil Scan',
  soilTest: 'Soil Test',
  source: 'Source',
  sourcePlateBarcode: 'Source Plate Barcode',
  sourcePlates: 'Source Plates',
  sourcePools: 'Source Pools',
  soybeanFoliar: 'Soybean Foliar',
  soybeanPathogens: 'Soybean Pathogens',
  soybeanPlan: 'Soybean Plan',
  soybeans: 'Soybeans',
  soybeansPlan: 'Soybeans Plan',
  split: 'Split',
  splitDensity: 'Split Density',
  splitDensityBarcode: 'Split Density Barcode',
  splitDensityDesc:
    'Split density allows you to run biological analysis at a lower density than nutrient analysis. Both Pressure Panel and Performance Panel will be included for any selected biological zones.',
  splitDensityForBio: 'Split density for biological + nutrient analysis',
  splitDensityGroup: 'Split Density Group',
  splitEveryZoneError: 'Could not split every zone.',
  splitFields: 'Split Fields',
  splitZones: 'Split Zones',
  splitZonesSuccess: 'Successfully split zones!',
  ssurgo: 'Soil Type (SSURGO)',
  ssurgoOmDisclaimer:
    'In the event a zone is missing a value for OM, a weighted average from the SSURGO soil survery will be used.',
  startedWhichOperation: 'To get started, which operation are we working with?',
  starter: 'Starter',
  state: 'State',
  stateCounty: 'State & County',
  status: 'Status',
  sterilized: 'Sterilized',
  storage: 'Storage',
  storePlate: 'Store Plate',
  street: 'Street',
  submit: 'Submit',
  submitting: 'Submitting',
  success: 'Success',
  successCreatedShippingLabelBatch:
    'Successfully created shipping label batch. Your download will be ready in a few minutes.',
  successfullyCreatedInvoice: 'Successfully created invoice in Quickbooks.',
  successfullyCreatedNoQB: 'Successfully created invoice without sending it to Quickbooks.',
  successfullyRemovedPrescription: 'Successfully removed prescription',
  sugar_beets: 'Sugar Beets',
  sugarBeetPestsDiseases: 'Sugar Beet Pests & Disease',
  sugarBeets: 'Sugar Beets',
  summary: 'Summary',
  summaryOfSamplePlanMsg:
    'This is a summary of the sample plan as ordered. The final invoice will reflect any potential adjustments.',
  summarySheet: 'Summary Sheet',
  superadmin: 'Super Admin',
  support: 'Support',
  supportUnderTwoAcres: 'Our system does not support fields under 2.1 acres. Please resize.',
  sureCancelPlan: 'Are you sure you want to cancel this plan?',
  sureMarkNotReady: 'Are you sure you want to mark the plan "Not Ready"?',
  surface: 'Surface',
  switchUserToAgent: 'Switch User to Agent',
  tags: 'Tags',
  target: 'Target',
  targetBaseSaturation: 'Target Base Saturation',
  targetDilution: 'Target Dilution',
  targetPhosphorus: 'Target Phosphorus',
  targetPotassium: 'Target Potassium',
  targetYield: 'Target Yield',
  tarSpot: 'Tar Spot',
  tbd: 'TBD',
  technical: 'Technical',
  temp: 'Temp',
  temperature: 'Temperature',
  termsAndConditions: 'Terms and Conditions',
  territory: 'Territory',
  territoryCreated: 'Commercial Territory Saved!',
  territoryDeletedMsg: 'Territory Deleted Successfully!',
  territoryName: 'Territory Name',
  territorySavingErrMsg: 'There was an error saving the territory. Please refresh and try again.',
  territoryUsers: 'Territory Users',
  testResults: 'Test Results',
  thanksForJoiningPatter: 'Thanks for joining Pattern!',
  thatEmailHasAcctMsg:
    'That email already has an account with Pattern Ag. Use a different email, or contact support to have that user moved to this Agency.',
  theTypicalMidwestFarm:
    'The typical <b>Midwest</b> farm working with Pattern can <b>boost profits by</b>',
  thisFieldIsRequired: 'This field is required.',
  till_rx_only: 'TillMapper Only',
  tillage_map_generation: 'Tillage Map Generation',
  tillage_measurement: 'Tillage Measurement',
  tillage: 'Tillage',
  tillageCosts: 'Tillage costs',
  tillMapper: 'TillMapper',
  tillMapperActivated: 'TillMapper activated successfully',
  tillMapperAvailable: 'This field has TillMapper available - activate it below for',
  tillMapperAvailableForField: 'TillMapper is available for this field!',
  tillMapperDensityMsg: 'TillMapper density must be greater than 8 and less than 10.5.',
  tillMapperIsLoading: 'TillMapper is loading - check back soon for your custom results',
  tillMapperOrdered: 'An order for TillMapper has been placed for this field',
  tillMapperPoints: 'TillMapper Points',
  timing: 'Timing',
  to: 'To',
  toBeSampledBy: 'To be sampled by',
  toBeScannedBy: 'To be scanned by',
  todaysDate: "Today's date",
  tooHighMsg: 'Value is too high',
  tooLowMsg: 'Value is too low',
  tooManyLabelsMsg: 'Please generate fewer than 80 labels at a time.',
  toOrderFirstPlan: 'To order your first sample plan, click ',
  total: 'Total',
  totalAcres: 'Total Acres',
  totalApplied: 'Total Applied',
  totalCarbon: 'Total Carbon',
  totalCompletedSamples: 'Total Completed Samples',
  totalDna: 'Total DNA',
  totalDnaMultiplier: 'Total DNA Multiplier',
  totalInorganicCarbon: 'Total Inorganic Carbon',
  totalOrganicCarbon: 'Total Organic Carbon',
  totalPcrDna: 'Total PCR DNA',
  totalPlannedSamples: 'Total Planned Samples',
  totalReads: 'Total Reads',
  totalReturn: 'Total Return',
  totalSamplesTaken: 'Total Samples Taken',
  totalScanCoverage: 'Total Scan Coverage',
  trackPackage: 'Track Package',
  traits: 'Traits',
  transactionApprovalMsg:
    'By approving these transaction, they will be sent to accounting for processing.',
  transit: 'Transit',
  treatment: 'Treatment',
  treatmentMissing: 'Seed treatment options have not been added to crop plan. Click to review',
  triStateLimeTo6: 'Tri State Lime to 6.0',
  triStateLimeTo65: 'Tri State Lime to 6.5',
  triStateLimeTo68: 'Tri State Lime to 6.8',
  triStateOhLimeTo6: 'Tri State (OH) Lime to 6.0',
  triStateOhLimeTo65: 'Tri State (OH) Lime to 6.5',
  triStateOhLimeTo68: 'Tri State (OH) Lime to 6.8',
  triStatePhosphorus: 'Tri State Phosphorus',
  triStatePotassium: 'Tri State Potassium',
  turnOnTillMapper:
    "Turn on TillMapper today - view your compaction maps and generate custom tillage Rx's",
  twoWaysToUpload: 'Two ways to upload field boundaries and data',
  type: 'Type',
  unableGenerateZoneGroupings: 'Unable to generate zone groupings. Please refresh.',
  unableToConnectAgency: 'Unable to connect agency. Please refresh and try again.',
  unableToDisconnectAgency: 'Unable to disconnect agency. Please refresh and try again.',
  unableToDisplayZoneGroupingError: 'Unable to display zone groupings.',
  unableToLoadZonesMsg: 'Unable to load zones. Try again later.',
  unableToSavePlanMsg: 'Unable to save plan. Please refresh and try again.',
  unavailable: 'Unavailable',
  unassigned_all: 'Unassigned - All',
  unassigned_eo: 'Unassigned - EO',
  unassigned_pattern: 'Unassigned - Pattern',
  unassigned_ready_to_sample: 'Unassigned, Ready to Sample',
  unassigned: 'Unassigned',
  unassignedPlans: 'Unassigned Plans',
  uncheckAll: 'Uncheck All',
  understandCompaction: 'Understand compaction on a sub-field level',
  understandCompactionPitch: "Generate 10m maps and custom Rx's",
  unit: 'Unit',
  units: 'Units',
  unknown: 'Unknown',
  update: 'Update',
  updateAddressFailMsg: 'Failed to update address. Please refresh and try again.',
  updateConcentrationDataMsg:
    'There is already concentration data associated with this plate. If you upload a new file it will overwrite the concentration data associated with each well. Are you sure you want to continue?',
  updated: 'Updated',
  updateData: 'Update Data',
  updatedUserRole: 'Updated User Role!',
  updateFieldCropErrorMsg: 'Error updating field crop plan. Please refresh and try again.',
  updateFieldCropSuccessMsg: 'Field crop plan updated successfully!',
  updatePrescriptionZoneErrorMsg: 'Error updating prescription zone. Please refresh and try again.',
  updatePrescriptionErrorMsg: 'Error updating prescription. Please refresh and try again.',
  updatePrescriptionZoneSuccessMsg: 'Prescription zone updated successfully!',
  updateUserInfoFailMsg: 'Failed to update user info. Please refresh and try again.',
  updatingAgencyErrorMsg: 'There was an error updating the agency. Please refresh and try again.',
  updatingAgencySuccessMsg: 'The agency was successfully updated!',
  updatingAlertError: 'There was an error updating the alert',
  updatingUserRoleErrorMsg:
    'There was an error updating the user role. Please refresh and try again.',
  upload_zones: 'Upload Zones',
  upload: 'Upload',
  uploadAbortedMsg: 'File upload was aborted.',
  uploadAnalyticImagery: 'Upload Analytic Imagery',
  uploadBoundaries: 'Upload Boundaries',
  uploadBulkPlate: 'Upload Bulk Plate',
  uploadCsv: 'Upload CSV File',
  uploadDocument: 'Upload Document',
  uploadedResults: 'Uploaded Results',
  uploadErrorMsg: 'There was an error uploading file.',
  uploadFailedMsg: 'Upload Failed.',
  uploading: 'Uploading',
  uploadLogo: 'Upload Logo',
  uploadPoints: 'Upload Points',
  uploadProgress: 'Upload Progress',
  uploadResults: 'Upload Results',
  uploadSamples: 'Upload Samples',
  uploadSamplesCsvMsg: 'Upload Sample Wells from CSV File',
  uploadShapefiles: 'Upload Shapefiles',
  uploadShapefilesData: 'Upload shapefiles or planting / harvest data',
  uploadShapefilesOfYourFields:
    'Upload shapefiles of your fields here - make sure you include all files (.shp, .shx, .dbf, etc/) and group those files in a single zip file',
  uploadSuccessMsg: 'Upload Successful!',
  uploadZipFile: 'Upload Zip File',
  uploadZipTip: 'The uploaded file must be in a .zip or .gz format.',
  uploadZones: 'Upload Zones',
  upsLabels: 'UPS Labels',
  user: 'User',
  userAgreements: 'User Agreements',
  userCreatedMsg: 'User created successfully!',
  userLocaleUpdated: 'User locale updated!',
  userRoleUpdated: 'User role updated!',
  users: 'Users',
  userScanningUpdated: 'User scanning capability updated!',
  userWillBeAssignedTip:
    "Note: The user being created will be assigned as the 'Primary Agent' for this Agency which means their contact information will be used for billing purposes.",
  value: 'Value',
  viewAdditionalResults: 'View Additional Results',
  viewAllAccounts: 'View All Accounts',
  viewBenefits: 'View Benefits',
  viewDemo: 'View Demo',
  viewDemoAccount: 'View Demo Account',
  viewDetails: 'View Details',
  viewEdit: 'View/Edit',
  viewEditLabel: 'View/Edit Label',
  viewMyAccounts: 'View My Accounts',
  viewMyReport: 'View My Report',
  viewOffer: 'View Offer',
  viewOrderSummary: 'View Order Summary',
  viewResults: 'View Results',
  viewSampleReport: 'View Sample Report',
  viewSampleStatus: 'View Sample Status',
  viewScanCoverage: 'View Scan Coverage',
  waiting: 'Waiting',
  waitingToSample: 'Waiting to Sample',
  waitingToScan: 'Waiting to Scan',
  webApp: 'Web App',
  weighedAndGround: 'Weighed & Ground',
  weight: 'Weight',
  welcomeBack: 'Welcome back',
  well: 'Well',
  wells: 'Wells',
  wet: 'Wet',
  whatsTheNameOfAccount: "What's the name of the farming account?",
  whatsTheNameOfAccountTooltip: 'This could be a farm name, or an individual.',
  wheat: 'Wheat',
  whereSendInvoiceMsg: 'Let us know where we should send any outstanding invoices for this account',
  width: 'Width',
  willReceiveLinkMsg: 'You will receive an email with a link to reset your password',
  with: 'With',
  wontSample: "Won't Sample",
  worms: 'Worms',
  yes: 'Yes',
  yield: 'Yield',
  yieldImpact: 'Yield Impact',
  youHaveNoApprovedMsg:
    "You have no 'Approved' payments. To view 'Open' payments, click the 'Open' selector above.",
  yourPasswordChanged: 'Your password has been updated!',
  zipcode: 'Zipcode',
  zone: 'Zone',
  zoneDetails: 'Zone Details',
  zones: 'Zones',
  zonesAtRisk: 'zones at risk',
  zymo: 'Zymo',
  zymoDilution: 'Zymo Dilution',
  zymoRevvityDescription: 'zymo lysis and magbead with TBD method for chelating metal ions',
  zymoSilicaDescription: 'zymo lysis and silica filter isolation',
  zymoSoil: 'Zymo Soil',
};

export default ENGLISH_STRINGS;
