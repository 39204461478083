import {
  CropReportType,
  OperationCropsSummaryType,
  RECEIVE_OPERATION_CROP_INFO,
  RECEIVE_SALES_FLOW_META,
  REQUEST_OPERATION_CROP_INFO,
  REQUEST_SALES_FLOW_META,
  SALES_FLOW_REQUEST_ERROR,
  SalesFlowActionType,
  SalesFlowMetaType,
} from './types';

export const salesFlowRequestError = (): SalesFlowActionType => ({
  type: SALES_FLOW_REQUEST_ERROR,
});

export const requestSalesFlowMeta = (): SalesFlowActionType => {
  return {
    type: REQUEST_SALES_FLOW_META,
  };
};

export const receiveSalesFlowMeta = (meta: SalesFlowMetaType): SalesFlowActionType => ({
  type: RECEIVE_SALES_FLOW_META,
  payload: meta,
});

export const requestOperationInfo = (operationId: number): SalesFlowActionType => ({
  type: REQUEST_OPERATION_CROP_INFO,
  payload: { operationId },
});

export const receiveOperationCropInfo = (
  operationId: number,
  cropInfo: {
    crop_plans: CropReportType[];
    summary: OperationCropsSummaryType;
  },
): SalesFlowActionType => ({
  type: RECEIVE_OPERATION_CROP_INFO,
  payload: { operationId, cropInfo },
});
