import {
  CatalogActionsType,
  CatalogType,
  CATALOG_REQUEST_ERROR,
  RECEIVE_CATALOG,
  RECEIVE_CATALOGS,
  REQUEST_CATALOGS,
} from './types';

export type CatalogStateType = {
  byId: {
    [id: number]: CatalogType;
  };
  all: CatalogType[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const initialState: CatalogStateType = {
  byId: {},
  all: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const CatalogReducer = (
  state = initialState,
  action: CatalogActionsType,
): CatalogStateType => {
  switch (action.type) {
    case REQUEST_CATALOGS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CATALOGS:
      return {
        ...state,
        all: action.payload.catalogs,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_CATALOG:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.catalog.id]: action.payload.catalog,
        },
        hasFetched: true,
        isFetching: false,
      };
    case CATALOG_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
