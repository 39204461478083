import React, { useCallback, useState } from 'react';
import { Button, Container, Label, Selector, Spinner } from 'common';
import { capitalizeEveryWord } from 'util/stringUtils';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import styles from './ActionForm.module.css';

type ActionFormPropsType = {
  actionTitle: string;
  name: string | null;
  type: string | null;
  amount: number | null;
  description: string;
  buttonName: string;
  onSubmit: (option?: { displayName: string; id: number }, isQb?: boolean) => void;
  onClose: () => void;
  selectorOptions?: { displayName: string; id: number }[];
  secondButtonName?: string;
};

const ActionForm = ({
  actionTitle,
  name,
  type,
  amount,
  description,
  buttonName,
  onSubmit,
  onClose,
  selectorOptions,
  secondButtonName,
}: ActionFormPropsType) => {
  const language = useBroswerLanguage();
  const [activeIndex, setActiveIndex] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    async (isQb: boolean) => {
      setLoading(true);
      const term = selectorOptions?.find((single, index) => activeIndex === index);
      await onSubmit(term, isQb);
      setLoading(false);
      onClose();
    },
    [onSubmit, onClose, selectorOptions, activeIndex],
  );

  return (
    <div className={styles.Wrapper}>
      <Container className={styles.InputColumn} vertical>
        <h1>{capitalizeEveryWord(actionTitle)}</h1>
        <div className={styles.Body}>
          <p>{description}</p>
          <div className={styles.PaymentInfo}>
            <div className={styles.FormColumn}>
              <div>{getString('type', language)}:</div>
              <div className={styles.FieldInput}>
                {type ? getString(type, language) : NOT_APPLICABLE}
              </div>
            </div>
            <div className={styles.FormColumn}>
              <div>{getString('user', language)}:</div>
              <div className={styles.FieldInput}>
                {name ? capitalizeEveryWord(name) : NOT_APPLICABLE}
              </div>
            </div>
            <div className={styles.FormColumn}>
              <div>{getString('amount', language)}:</div>
              <div className={styles.FieldInput}>
                {amount ? `$${Math.abs(amount)}` : NOT_APPLICABLE}
              </div>
            </div>
            {selectorOptions && (
              <div className={styles.FormColumn}>
                <Label label={getString('paymentTerms', language)}>
                  <Selector
                    activeIndex={activeIndex}
                    className={styles.Selector}
                    onChange={(idx) => setActiveIndex(idx)}
                    options={selectorOptions}
                  />
                </Label>
              </div>
            )}
          </div>
        </div>
        <div className={styles.SubmitWrapper}>
          <Button
            onClick={() => submit(true)}
            primary
            disabled={(selectorOptions && activeIndex === undefined) || loading}
          >
            {capitalizeEveryWord(buttonName)}
          </Button>
          {secondButtonName && (
            <Button
              className={styles.MarginLeft}
              onClick={() => submit(false)}
              disabled={(selectorOptions && activeIndex === undefined) || loading}
            >
              {capitalizeEveryWord(secondButtonName)}
            </Button>
          )}
          {loading && <Spinner className={styles.SmallSpinner} />}
        </div>
      </Container>
    </div>
  );
};

export default ActionForm;
