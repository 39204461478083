import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getCatalog } from 'store/catalogs/thunks';
import { OperationType } from 'store/operation/types';
import { OperationCatalog } from 'apps/Planning/List/OperationCatalog';

type DefaultCatalogProps = {
  operation: OperationType;
};
const DefaultCatalog = ({ operation }: DefaultCatalogProps) => {
  const dispatch = useDispatch();

  const { catalog } = useSelector((state: RootState) => ({
    catalog: operation.catalog_id ? state.catalogs.byId[operation.catalog_id] : undefined,
  }));

  useEffect(() => {
    if (operation.catalog_id) {
      dispatch(getCatalog(operation.catalog_id));
    }
  }, [dispatch, operation.catalog_id]);

  return <OperationCatalog catalog={catalog} operationId={operation.id} maw="20rem" />;
};

export default DefaultCatalog;
