import {
  COLLECTION_REQUEST_ERROR,
  EOCollectionActionType,
  EOCollectionsResponseType,
  RECEIVE_COLLECTIONS,
  REQUEST_COLLECTIONS,
} from './types';

export const requestEOCollections: EOCollectionActionType = {
  type: REQUEST_COLLECTIONS,
};

export const receiveEOCollections = (
  payload: EOCollectionsResponseType,
): EOCollectionActionType => ({
  type: RECEIVE_COLLECTIONS,
  payload,
});

export const collectionRequestError: EOCollectionActionType = {
  type: COLLECTION_REQUEST_ERROR,
};
