import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Stack } from '@mantine/core';
import { AdminAgentAccess, Header, OperationSearchBar, YearSelect } from 'common';
import { RootState } from 'store';
import { OperationType } from 'store/operation/types';
import { OrderHistoryTable } from './OrderHistoryTable';

export const OrderHistoryContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();
  const { year } = useParams<{ year: string }>();
  const [search, setSearch] = useState<string>('');

  const numericYear = Number(year);

  useEffect(() => {
    if (!year) {
      const currentYear = new Date().getFullYear();
      navigate(`/order-history/${currentYear}`);
    }
  }, [year]);

  const setYear = (nYear: number) => {
    navigate(`/order-history/${nYear}`);
  };

  const { currentCropYear } = useSelector((state: RootState) => ({
    currentCropYear: state.timings.currentCropYear,
  }));

  return (
    <Stack>
      <Header title={getString('orderHistory', language)}>
        <AdminAgentAccess>
          <OperationSearchBar
            leftSection={<FiSearch />}
            placeholder={getString('enterAccountName', language)}
            onSelect={(operation: OperationType) => setSearch(operation.name)}
            onDeselect={() => setSearch('')}
          />
        </AdminAgentAccess>
        <YearSelect
          year={numericYear}
          setYear={setYear}
          currentYear={currentCropYear}
          yearsBack={4}
        />
      </Header>
      <OrderHistoryTable year={numericYear} operationName={search} />
    </Stack>
  );
};
