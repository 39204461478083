import {
  RECEIVE_ORDER_HISTORY,
  REQUEST_ORDER_HISTORY,
  OrderHistoryActionsType,
  OrderHistoryStateType,
  ORDER_HISTORY_REQUEST_ERROR,
} from './types';

const initialState: OrderHistoryStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: true,
  paginated: {
    items: [],
    page: 1,
  },
};

export const OrderHistoryReducer = (state = initialState, action: OrderHistoryActionsType) => {
  switch (action.type) {
    case REQUEST_ORDER_HISTORY:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
      };
    case RECEIVE_ORDER_HISTORY:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        paginated: {
          items: action.payload.items,
          page: action.payload.page || 1,
        },
      };
    case ORDER_HISTORY_REQUEST_ERROR:
      return {
        ...state,
        hasFetched: false,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
