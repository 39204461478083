import { useEffect, useState } from 'react';
import { getApps, getApp, initializeApp } from 'firebase/app';
import {
  getAnalytics,
  Analytics,
  logEvent as firebaseLogEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { firebaseConfig, PAGE_VIEW } from 'constants/firebase';
import { isProduction } from 'util/request';
import { User } from 'store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type FirebaseUser = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  business_name: string | null;
  is_active: boolean;
  roles: string;
  agencies: string;
  agency_primary: boolean;
  user_locale: string;
};

const useFirebase = () => {
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<Analytics>();
  const [lastPage, setLastPage] = useState<string>();
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>();

  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
  }));

  useEffect(() => {
    const initializeFirebase = async () => {
      // Only record analytics on production website
      if (isProduction) {
        const app = getApps().length ? getApp() : initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        setFirebaseAnalytics(analytics);
      }
    };

    initializeFirebase();
  }, []);

  useEffect(() => {
    if (currentUser) {
      updateUserData(currentUser);
    }
  }, [firebaseAnalytics, currentUser]);

  const logEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
    if (firebaseAnalytics) {
      firebaseLogEvent(firebaseAnalytics, eventName, eventParams);
    }
  };

  const pageView = (pageLocation: string, pagePath: string, pageTitle: string) => {
    if (pagePath !== lastPage) {
      logEvent(PAGE_VIEW, {
        page_location: pageLocation,
        page_path: pagePath,
        page_title: pageTitle,
      });
      setLastPage(pagePath);
    }
  };

  const updateUserData = (user: User) => {
    const {
      id,
      first_name,
      last_name,
      email,
      business_name,
      is_active,
      agency_primary,
      user_locale,
    } = user;
    const userData = {
      id,
      first_name,
      last_name,
      email,
      business_name,
      is_active,
      roles: user.roles?.map((r) => r.name).toString(),
      agencies: user.agencies?.map((a) => a.id).toString(),
      agency_primary,
      user_locale,
    };

    if (JSON.stringify(userData) !== JSON.stringify(firebaseUser)) {
      if (firebaseAnalytics) {
        setUserId(firebaseAnalytics, String(user.id));
        setUserProperties(firebaseAnalytics, userData);
      }
      setFirebaseUser(userData);
    }
  };

  return {
    logEvent,
    pageView,
    updateUserData,
  };
};

export default useFirebase;
