import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import Styles from './Container.module.css';
import HeaderLogo from 'images/horizontalLogo.svg';

const TextOptOut = () => {
  const language = useBroswerLanguage();

  return (
    <div className={Styles.Wrapper}>
      <div>
        <img alt="Pattern Ag" className={Styles.Logo} src={HeaderLogo} />
        <div data-test-id="text-opt-out" className={Styles.OptOutText}>
          {getString('optOutText', language)}
        </div>
      </div>
    </div>
  );
};

export default TextOptOut;
