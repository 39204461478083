import { ZoneAnalysisStateType } from './reducer';

export const UPDATE_ZONE_ANALYSIS_V2 = 'UPDATE_ZONE_ANALYSI_V2';
export const CLEAR_ZONE_ANALYSIS_V2 = 'CLEAR_ZONE_ANALYSIS_V2';
export const REQUEST_EXTERNAL_NUTRIENT_LABS = 'REQUEST_EXTERNAL_NUTRIENT_LABS';
export const RECEIVE_EXTERNAL_NUTRIENT_LABS = 'RECEIVE_EXTERNAL_NUTRIENT_LABS';

export type ZoneAnalysisKeyType = { [key: string]: any };

export const ANALYSIS_TYPES = {
  CREATION_OPTION: 'creationOption',
  PRO_POINT_CREATION_OPTION: 'proPointCreationOption',
};

type updateSamplePlanType = {
  type: typeof UPDATE_ZONE_ANALYSIS_V2;
  payload: {
    metaKeyValues: ZoneAnalysisKeyType;
  };
};

type clearSamplePlanType = {
  type: typeof CLEAR_ZONE_ANALYSIS_V2;
};

export type RequestExternalNutrientLabs = {
  type: typeof REQUEST_EXTERNAL_NUTRIENT_LABS;
};

export type ReceiveExternalNutrientLabs = {
  type: typeof RECEIVE_EXTERNAL_NUTRIENT_LABS;
  payload: ZoneAnalysisStateType['availableExternalLabs'];
};

export type ZoneAnalysisActionsType =
  | updateSamplePlanType
  | clearSamplePlanType
  | RequestExternalNutrientLabs
  | ReceiveExternalNutrientLabs;
