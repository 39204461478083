import React from 'react';
import { Button, Center, Group, Loader, Modal, Space, Stack, Text } from '@mantine/core';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

interface CancelModalProps {
  cancel: () => void;
  hideModal: () => void;
  isFetching: boolean;
  cancelTitle: string;
  cancelText?: string;
  opened: boolean;
  isDisabled?: boolean;
}

const CancelModal = ({
  cancel,
  hideModal,
  isFetching,
  cancelTitle,
  cancelText,
  opened,
  isDisabled,
}: CancelModalProps) => {
  const language = useBroswerLanguage();

  if (isFetching) {
    return (
      <Modal onClose={hideModal} opened>
        <Center>
          <Loader />
        </Center>
        <Space h="xl" />
      </Modal>
    );
  }

  return (
    <Modal onClose={hideModal} opened={opened} centered title={cancelTitle}>
      <Stack>
        <Text>{cancelText || getString('cancelSamplePlanMsg', language)}</Text>
        <Group justify="flex-end">
          {isDisabled && <Loader size="sm" />}
          <Button
            data-test-id="cancel-sample-plan-no"
            onClick={hideModal}
            variant="outline"
            disabled={isDisabled}
          >
            {getString('no', language)}
          </Button>
          <Button data-test-id="cancel-sample-plan-yes" onClick={cancel} disabled={isDisabled}>
            {getString('yes', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default CancelModal;
