import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import { Group, Stack, Title } from '@mantine/core';
import { Header } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import SampleSearchBar from '../common/SampleSearchBar';
import DetailsController from './DetailsController';
import styles from './Container.module.css';
import { getAllAnalytics } from 'store/analytics/thunks';

const BarcodeContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { barcode } = useParams<{ barcode: string; sample_uuid?: string }>();
  const [focus, setFocus] = useState(false);

  const { allAnalytics } = useSelector((state: RootState) => ({
    allAnalytics: state.analytics.allAnalytics,
  }));

  useEffect(() => {
    if (!allAnalytics.length) {
      dispatch(getAllAnalytics());
    }
  }, [allAnalytics]);

  return (
    <Stack gap="0">
      <Header
        title={
          <Group gap="xs">
            <Title order={3}>{`${getString('sampleBarcode', language)}: `}</Title>
            <Title order={3} data-test-id="sample-barcode">
              {barcode}
            </Title>
          </Group>
        }
      >
        <SampleSearchBar
          className={styles.SampleSearchInput}
          autofocus={focus}
          onSearch={() => setFocus(false)}
        />
      </Header>
      <Stack>
        <DetailsController barcode={barcode} searchBarFocus={() => setFocus(true)} />
      </Stack>
    </Stack>
  );
};

export default BarcodeContainer;
