import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { activeOperationId } from 'selectors/operationSelectors';
import { getOperation } from 'store/operation/thunks';
import { requestDeleteBillingResponsibility } from 'store/operation/requests';
import { getOperationAllAgencies } from 'store/agencies/thunks';
import styles from './Container.module.css';

interface Props {
  onClose: () => void;
}

const RemoveBillingResponsibilityDialog = ({ onClose }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const operationId = useSelector(activeOperationId);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleContinue = async () => {
    if (operationId) {
      setIsSubmitted(true);
      try {
        await requestDeleteBillingResponsibility(operationId);
        dispatch(getOperation(operationId));
        dispatch(getOperationAllAgencies(operationId));
        onClose();
      } catch (e) {
        setIsSubmitted(false);
      }
    }
  };
  return (
    <Dialog>
      <div className={styles.Dialog}>
        <h1 style={{ textAlign: 'center' }}>
          {getString('removeBillingResponsibility', language)}
        </h1>
        <p>{getString('removeBillingPartyMsg', language)}</p>
        <div className={styles.Center} style={{ marginTop: '3rem' }}>
          <Button
            type="button"
            primary
            style={{ marginRight: '1rem' }}
            onClick={handleContinue}
            disabled={isSubmitted}
          >
            {getString('yes', language)}
          </Button>
          <Button type="button" onClick={onClose}>
            {getString('cancel', language)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RemoveBillingResponsibilityDialog;
