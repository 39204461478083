import { CropPlanType } from 'store/cropPlans/types';
import { PrescriptionType } from 'store/prescriptions/types';
import {
  FieldType,
  FieldActionsType,
  REQUEST_FIELD_GEOMETRY,
  RECEIVE_FIELD_GEOMETRY,
  RECEIVE_FIELD_GEOMETRIES,
  REQUEST_FIELD_GEOMETRIES,
  RECEIVE_SAMPLE_PLAN,
  FIELD_GEOMETRY_ERROR,
  SET_FIELDS_WILL_FETCH,
  DELETE_FIELD,
  SamplingPlanType,
  FIELD_GEOMETRIES_ERROR,
  RECEIVE_PRESCRIPTION,
  CLEAR_FIELD_GEOMETRIES,
  REQUEST_CROP_PLAN_OPTIMIZE,
  RECEIVE_CROP_PLAN_OPTIMIZE,
  CROP_PLAN_OPTIMIZE_ERROR,
  REFRESH_FIELDS,
} from './types';

export const requestFieldGeometry = (fieldId: number): FieldActionsType => ({
  type: REQUEST_FIELD_GEOMETRY,
  payload: { fieldId },
});

export const requestFieldGeometries = (fieldIds: Array<number>): FieldActionsType => ({
  type: REQUEST_FIELD_GEOMETRIES,
  payload: { fieldIds },
});

export const requestSetFieldsWillFetch = (fieldIds: number[]): FieldActionsType => ({
  type: SET_FIELDS_WILL_FETCH,
  payload: { fieldIds },
});

export const receiveFieldGeometry = (fieldId: number, geometry: FieldType): FieldActionsType => ({
  type: RECEIVE_FIELD_GEOMETRY,
  payload: { fieldId, geometry },
});

export const receiveFieldGeometries = (
  fieldIds: Array<number>,
  geometries: Array<FieldType>,
): FieldActionsType => ({
  type: RECEIVE_FIELD_GEOMETRIES,
  payload: { fieldIds, geometries },
});

export const fieldGeometriesError = (fieldIds: Array<number>): FieldActionsType => ({
  type: FIELD_GEOMETRIES_ERROR,
  payload: { fieldIds },
});

export const receiveSamplePlan = (
  fieldId: number,
  samplePlan: SamplingPlanType,
): FieldActionsType => ({
  type: RECEIVE_SAMPLE_PLAN,
  payload: { fieldId, samplePlan },
});

export const receivePrescription = (
  fieldId: number,
  prescription: PrescriptionType,
): FieldActionsType => ({
  type: RECEIVE_PRESCRIPTION,
  payload: { fieldId, prescription },
});

export const fieldGeometryError = (fieldId: number): FieldActionsType => ({
  type: FIELD_GEOMETRY_ERROR,
  payload: { fieldId },
});

export const removeFieldAction = (fieldId: number): FieldActionsType => ({
  type: DELETE_FIELD,
  payload: { fieldId },
});

export const clearFieldGeometries = (): FieldActionsType => ({
  type: CLEAR_FIELD_GEOMETRIES,
});

export const requestCropPlanOptimize = (cropPlanIds: number[]): FieldActionsType => ({
  type: REQUEST_CROP_PLAN_OPTIMIZE,
  payload: {
    cropPlanIds,
  },
});

export const receiveCropPlanOptimize = (cropPlans: CropPlanType[]): FieldActionsType => ({
  type: RECEIVE_CROP_PLAN_OPTIMIZE,
  payload: {
    cropPlans,
  },
});

export const cropPlanOptimizeError = (payload: { message: string }): FieldActionsType => ({
  type: CROP_PLAN_OPTIMIZE_ERROR,
  payload,
});

export const refreshFields = (): FieldActionsType => ({
  type: REFRESH_FIELDS,
});
