export const RECEIVE_SHIPPING_ADDRESSES = 'RECEIVE_SHIPPING_ADDRESSES';
export const REQUEST_SHIPPING_ADDRESSES = 'REQUEST_SHIPPING_ADDRESSES';
export const SHIPPING_REQUEST_ERROR = 'SHIPPING_REQUEST_ERROR';
export const REQUEST_SHIPPING_BATCHES = 'REQUEST_SHIPPING_BATCHES';
export const RECEIVE_SHIPPING_BATCHES = 'RECEIVE_SHIPPING_BATCHES';

export interface ParcelDimensionType {
  parcelWidth: number | string;
  parcelHeight: number | string;
  parcelLength: number | string;
  parcelWeight: number | string;
  distance_unit?: string;
  mass_unit?: string;
}

export interface ShippingLabel {
  id: number;
  created_at: string;
  created_by_id: number;
  sampling_plans: number[];
  eta: string | null;
  shipping_address_id: number;
  status: string;
  tracking_number: string;
  tracking_url_provider: string;
  updated_at: string | null;
  parcel: ParcelDimensionType;
  group_id: string;
  last_updated: string | null;
  location: string | null;
  is_regulated: boolean | null;

  shippo_label_url: string;
  shippo_id: string;
}

export interface LabAddressType {
  id: number;
  name: string;
  address: string;
  lab_location: string;
}

export interface ShippingAddress {
  id: number;
  shippo_address_id: number;
  street: string;
  city: string;
  state: string;
  zip: string;
  valid_address: boolean;
}

export interface ShippingLabelBatch {
  id: number;
  lab_name: string;
  created_at: string;
  shipping_address_id: number;
  ship_to_lab: string;
  tracking_number: string | null;
  tracking_url_provider: string | null;
  created_by_id: number;
  status: string;
  shippo_batch_id: string;
  shippo_label_url: string | null;
  shippo_id: string;
}

export interface JobType {
  id: number;
  complete: number;
  error: string | null;
  updated_at: string | null;
  created_at: string;
  status: string;
  created_by_id: number;
  shippo_batch_id: string;
}

export interface ShippingLabelBatches {
  ready: ShippingLabelBatch[];
  pending: JobType[];
  failed: JobType[];
}

export type PartialShippingAddress = Partial<ShippingAddress>;

export type ShippingStateType = {
  shippingAddresses: ShippingAddress[];
  labAddresses: LabAddressType[];
  ready: ShippingLabelBatch[];
  pending: JobType[];
  failed: JobType[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

interface RequestShippingAddressesAction {
  type: typeof REQUEST_SHIPPING_ADDRESSES;
}

interface RecieveShippingAddressesAction {
  type: typeof RECEIVE_SHIPPING_ADDRESSES;
  payload: {
    lab_addresses: LabAddressType[];
    shipping_addresses: ShippingAddress[];
  };
}

interface RequestShippingBatchesAction {
  type: typeof REQUEST_SHIPPING_BATCHES;
}

interface RecieveShippingBatchesAction {
  type: typeof RECEIVE_SHIPPING_BATCHES;
  payload: ShippingLabelBatches;
}

interface ShippingRequestError {
  type: typeof SHIPPING_REQUEST_ERROR;
  payload: {
    groupId?: number;
  };
}

export type ShippingActionType =
  | RecieveShippingAddressesAction
  | RequestShippingAddressesAction
  | ShippingRequestError
  | RequestShippingBatchesAction
  | RecieveShippingBatchesAction;
