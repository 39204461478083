import {
  RECEIVE_TIMINGS,
  REQUEST_TIMINGS,
  TimingActionsType,
  TimingsType,
  TimingsResponseType,
  TIMINGS_REQUEST_ERROR,
} from './types';

export type TimingStateType = {
  currentCropYear: number | null;
  currentTiming: string | null;
  timings: TimingsType[];
  isFetching: boolean;
  hasFetched: boolean;
  hasFailed: boolean;
};

export const initialState: TimingStateType = {
  currentCropYear: null,
  currentTiming: null,
  timings: [],
  isFetching: false,
  hasFetched: false,
  hasFailed: false,
};

const defineIdsForList = (timings: TimingsResponseType[]) =>
  timings.map((timing, index) => ({
    id: index,
    value: timing.value,
    displayName: timing.display_name,
  }));

export const TimingReducer = (state = initialState, action: TimingActionsType) => {
  switch (action.type) {
    case RECEIVE_TIMINGS:
      return {
        ...state,
        currentCropYear: action.payload.current_crop_year,
        currentTiming: action.payload.current_timing,
        timings: defineIdsForList(action.payload.sampling_timings),
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_TIMINGS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
      };
    case TIMINGS_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};
