import React, { useEffect, useState } from 'react';
import { MultiSelector } from 'common';

type FilterDropdownPropsType = {
  filter: string[];
  options: { id: number; displayName: string; value: string | null }[];
  onChangeFilter: (value: string[]) => void;
  className?: string;
};

export const FilterDropdown = ({
  filter,
  options,
  onChangeFilter,
  className,
}: FilterDropdownPropsType) => {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    const indices = options.reduce((list, option, idx) => {
      if (option.value && filter.includes(option.value)) {
        return list.concat(idx);
      }
      return list;
    }, [] as number[]);
    if (!indices.length && options[0]?.value === null) {
      indices.push(0);
    }
    setActiveIndices(indices);
  }, [filter, options]);

  const handleSelect = (index: number) => {
    const optionValue = options[index].value;
    onChangeFilter(optionValue === null ? [] : filter.concat(optionValue));
  };

  const handleDeselect = (index: number) => {
    onChangeFilter(filter.filter((v: string) => v !== options[index].value));
  };

  return (
    <MultiSelector
      className={className}
      activeIndices={activeIndices}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      options={options}
      hideOverflow
    />
  );
};
