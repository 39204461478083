import React from 'react';
import { ComboboxOptionProps, Select } from '@mantine/core';
import { getYearOptions } from 'util/date';

type YearSelectPropsType = {
  year?: number;
  setYear: (year: number) => void;
  currentYear?: number | null;
  yearsBack?: number;
};

export const YearSelect = ({ year, setYear, currentYear, yearsBack }: YearSelectPropsType) => {
  const current = currentYear || new Date().getFullYear();
  const cropYearOptions = getYearOptions(current, yearsBack);

  const handleSelectYear = (label: string | null, option: ComboboxOptionProps) => {
    const selectedYear = parseInt(option.value, 10);
    setYear(selectedYear);
  };

  return (
    <Select
      w="6rem"
      data={cropYearOptions}
      value={year ? String(year) : undefined}
      defaultValue={String(current)}
      onChange={handleSelectYear}
      allowDeselect={false}
    />
  );
};
