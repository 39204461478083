import React from 'react';
import { Group, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RequiredAnalysis } from 'common';
import { LabSampleType } from 'store/labSamples/types';

type SampleAnalysisPropsType = {
  sample: LabSampleType;
  shouldShow590: boolean;
};

export const SampleAnalysis = ({ sample, shouldShow590 }: SampleAnalysisPropsType) => {
  const language = useBroswerLanguage();
  return (
    <Group align="center" gap="xl">
      <Text fw={700} w="4.2rem">
        {getString('analysis', language)}
      </Text>
      <RequiredAnalysis sample={sample} shouldShow590={shouldShow590} />
    </Group>
  );
};
