import React from 'react';

import { Table, TableCol, TableRow } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { ApprovedPaymentsType } from 'store/payments/types';
import { capitalize, capitalizeEveryWord } from 'util/stringUtils';
import { formatCalendarMonthDayYear } from 'util/date';
import { NOT_APPLICABLE } from 'constants/defaultValues';

import styles from '../common/Tables/PaymentTables.module.css';

type SummaryTablePropsType = {
  payment: ApprovedPaymentsType;
};

const SummaryTable = ({ payment }: SummaryTablePropsType) => {
  const language = useBroswerLanguage();
  return (
    <Table>
      <TableRow header className={styles.TableHeader}>
        <TableCol>{getString('user', language)}</TableCol>
        <TableCol>{getString('type', language)}</TableCol>
        <TableCol>{getString('amount', language)}</TableCol>
        <TableCol>{getString('approvedBy', language)}</TableCol>
        <TableCol>{getString('recordedBy', language)}</TableCol>
        <TableCol>{getString('updated', language)}</TableCol>
        <TableCol>{getString('status', language)}</TableCol>
      </TableRow>
      <TableRow>
        <TableCol className={styles.TransactionTitleColumn}>
          {capitalizeEveryWord(`${payment.user.first_name} ${payment.user.last_name}`)}
        </TableCol>
        <TableCol>
          {payment.payment_type ? capitalize(payment.payment_type) : NOT_APPLICABLE}
        </TableCol>
        <TableCol>{payment.amount ? `$${Math.abs(payment.amount)}` : NOT_APPLICABLE}</TableCol>
        <TableCol>{capitalizeEveryWord(payment.approved_by_user_name)}</TableCol>
        <TableCol>
          {payment.settled_by_user_name
            ? capitalizeEveryWord(payment.settled_by_user_name)
            : NOT_APPLICABLE}
        </TableCol>
        <TableCol>
          {payment.settled_at
            ? formatCalendarMonthDayYear(payment.settled_at)
            : formatCalendarMonthDayYear(payment.created_at)}
        </TableCol>
        <TableCol>{payment.status ? capitalize(payment.status) : NOT_APPLICABLE}</TableCol>
      </TableRow>
    </Table>
  );
};

export default SummaryTable;
