import React from 'react';
import { Button } from '@mantine/core';
import { OperationType } from 'store/operation/types';
import { capitalizeEveryWord } from 'util/stringUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import styles from './Container.module.css';

interface DiscountProgramProps {
  operation: OperationType;
  toggleDiscountModalOpen: (arg: boolean) => void;
}

const DiscountProgram = ({ operation, toggleDiscountModalOpen }: DiscountProgramProps) => {
  const language = useBroswerLanguage();
  return (
    <div>
      <div className={styles.TitleContainer}>
        <h2 className={styles.Title}>Discount Program</h2>
        <div>
          <Button variant="outline" onClick={() => toggleDiscountModalOpen(true)}>
            {getString('editDiscountProgram', language)}?
          </Button>
        </div>
      </div>
      <div className={styles.Divider} />
      <div className={styles.Section}>
        {operation.discount_name ? (
          <>
            <b>{getString('discount', language)}: </b>
            <span>{capitalizeEveryWord(operation.discount_name.replace('-', ' '))}, </span>
            <b>{getString('maxAcres', language)}: </b>
            <span>{operation.max_acres}</span>
          </>
        ) : (
          <b>{getString('operationNoDiscountsMsg', language)}</b>
        )}
      </div>
    </div>
  );
};

export default DiscountProgram;
