export const US = 'us';
export const BR = 'br';

export const COUNTRIES = [US, BR];

export const COUNTRY_OPTIONS = [
  {
    displayName: 'US',
    label: 'US',
    value: US,
    id: 1,
  },
  {
    displayName: 'BR',
    label: 'BR',
    value: BR,
    id: 2,
  },
];
