import React, { useState } from 'react';
import { Button, Menu } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { BatchType } from 'store/batches/types';
import { requestDownloadRunSheet } from 'store/batches/requests';
import { AMMONIUM_ACETATE, GENOMIC, NITRATE, M3, OM, PH, PHOSPHATE } from 'constants/analysis';

import { MarkBatch } from './ActionForms/MarkBatch';
import { runSheetFormat } from 'constants/batches';

type ActionsDropdownPropsType = {
  batch: BatchType;
};

export const ActionsDropdown = ({ batch }: ActionsDropdownPropsType) => {
  const language = useBroswerLanguage();
  const [showMarkBatch, setShowMarkBatch] = useState(false);

  const handleDownloadNovaSeqSampleSheet = async () => {
    if (batch && batch.sample_sheet_url) {
      window.open(batch.sample_sheet_url);
    }
  };

  const handleDownloadMiSeqSampleSheet = async () => {
    if (batch && batch.miseq_sample_sheet_url) {
      window.open(batch.miseq_sample_sheet_url);
    }
  };

  const handleDownloadRunSheet = async (format?: string) => {
    try {
      return await requestDownloadRunSheet(batch.id, format);
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  return (
    <>
      {showMarkBatch && <MarkBatch batch={batch} onClose={() => setShowMarkBatch(false)} />}
      <Menu shadow="md">
        <Menu.Target>
          <Button variant="outline">{getString('actions', language)}</Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={() => setShowMarkBatch(true)}
            disabled={Boolean(batch.sent_for_processing_at)}
          >
            {getString('markBatchSent', language)}
          </Menu.Item>
          {batch.type === GENOMIC && (
            <>
              <Menu.Item
                onClick={handleDownloadNovaSeqSampleSheet}
                disabled={!batch.sample_sheet_url}
              >
                {getString('dlNovaSeqIndexSets', language)}
              </Menu.Item>
              <Menu.Item
                onClick={handleDownloadMiSeqSampleSheet}
                disabled={!batch.miseq_sample_sheet_url}
              >
                {getString('dlMiSeqIndexSets', language)}
              </Menu.Item>
            </>
          )}
          {[M3, PH, NITRATE, AMMONIUM_ACETATE, OM].includes(batch.type) && (
            <Menu.Item onClick={() => handleDownloadRunSheet()}>
              {getString('dlRunSheet', language)}
            </Menu.Item>
          )}
          {[PHOSPHATE].includes(batch.type) && (
            <>
              <Menu.Item onClick={() => handleDownloadRunSheet(runSheetFormat.ICP)}>
                {getString('dlICPRunSheet', language)}
              </Menu.Item>
              <Menu.Item onClick={() => handleDownloadRunSheet(runSheetFormat.FIA)}>
                {getString('dlFIARunSheet', language)}
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
