import React from 'react';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';
import prescriptionStyles from './Prescription.module.css';
import PrescriptionThumbnail from './PrescriptionThumbnail';
import PrescriptionSummary from './PrescriptionSummary';

interface PrescriptionListPropsType {
  fieldGeometry: FieldType;
  prescription: PrescriptionType;
  cancelPrescription: (script: PrescriptionType) => void;
  checked?: boolean;
  onChecked?: () => void;
}

const PrescriptionMapbook = ({
  prescription,
  fieldGeometry,
  cancelPrescription,
  checked,
  onChecked,
}: PrescriptionListPropsType) => {
  return (
    <div className={prescriptionStyles.PrescriptionWrapper} key={prescription.id}>
      <PrescriptionSummary
        field={fieldGeometry}
        prescription={prescription}
        cancelPrescription={cancelPrescription}
        checked={checked}
        onChecked={onChecked}
      />
      <PrescriptionThumbnail field={fieldGeometry} prescription={prescription} />
    </div>
  );
};

export default PrescriptionMapbook;
