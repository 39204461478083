import React, { FunctionComponent, MouseEventHandler, useEffect, ReactNode } from 'react';
import classNames from 'classnames';

import { Button } from './Button';

import styles from './Dialog.module.css';
import { ClickOutsideWrapper } from './ClickOutsideWrapper';

type DialogProps = {
  className?: string;
  onClose?: MouseEventHandler;
  'data-test-id'?: string;
  title?: ReactNode;
  children: ReactNode;
};

export const Dialog: FunctionComponent<DialogProps> = ({
  children,
  className,
  onClose,
  'data-test-id': testId,
  title,
}) => {
  useEffect(() => {
    document.body.classList.add(styles.noScroll);
    return () => document.body.classList.remove(styles.noScroll);
  });

  const content = (
    <div className={styles.Wrapper} data-test-id={testId}>
      {(title || onClose) && (
        <div className={styles.Toolbar}>
          <div>{title}</div>
          {onClose && (
            <Button className={styles.Close} onClick={onClose}>
              X
            </Button>
          )}
        </div>
      )}
      <div className={styles.InnerWrapper}>{children}</div>
    </div>
  );

  return (
    <div className={classNames(styles.Overlay, className)}>
      {onClose ? (
        <ClickOutsideWrapper onClickOutside={onClose}>{content}</ClickOutsideWrapper>
      ) : (
        content
      )}
    </div>
  );
};
