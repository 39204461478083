import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { FileUploadDialog } from 'common';
import { SERVICE_URL, getAuthedHeaders, GCPFileUpload } from 'util/request';
import { PlateType } from 'store/plates/types';
import { getPlate } from 'store/plates/thunks';

import styles from './BulkUpload.module.css';

type BulkUploadPropsType = {
  plate: PlateType;
  onClose: () => void;
  open: boolean;
};

export const BulkUpload = ({ plate, onClose, open }: BulkUploadPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [percentComplete, setPercentComplete] = useState(0);
  const [request, setRequest] = useState<XMLHttpRequest>();

  const onComplete = () => {
    dispatch(getPlate(plate.barcode));
  };

  const uploadToGCP = async (file: File) => {
    // fetch does not support onProgress, so using XHR
    const onCompleted = onComplete;
    const onError = () => new Error(getString('uploadErrorMsg', language));
    const onAbort = () => new Error(getString('uploadAbortedMsg', language));
    const onProgressUpdate = (event: ProgressEvent) => {
      const percent = ((event.loaded * 100) / event.total).toFixed(1);
      setPercentComplete(Number(percent));
    };

    const formData = new FormData();
    formData.append('file', file, file.name);

    const xhr = GCPFileUpload({
      method: 'POST',
      uploadUrl: `${SERVICE_URL}/wells_bulk_upload/?plate_barcode=${plate.barcode}`,
      file: formData,
      onProgressUpdate,
      onCompleted,
      onError,
      onAbort,
      header: 'Authorization',
      headerValue: getAuthedHeaders('').Authorization,
    });
    setRequest(xhr);
    return xhr;
  };

  const cancelUpload = useCallback(() => {
    if (request) {
      request.abort();
    }
  }, [request]);

  return (
    <FileUploadDialog
      open={open}
      dialogLabel={getString('uploadSamples', language)}
      onUpload={uploadToGCP}
      onClose={onClose}
      onAbort={cancelUpload}
      message={getString('uploadSamplesCsvMsg', language)}
    >
      <div className={styles.DialogContent}>
        <div className={styles.ProgressBarBackground}>
          {percentComplete > 0 && (
            <div className={styles.ProgressBar} style={{ width: `${percentComplete}%` }}>
              {`${percentComplete}%`}
            </div>
          )}
        </div>
        <div className={styles.Warning}>
          {percentComplete > 0 && getString('doNotNavigateMsg', language)}
        </div>
      </div>
    </FileUploadDialog>
  );
};
