export type AttributeType = {
  id?: number;
  name: string;
  enabled?: boolean;
  description?: string;
  allowed_values?: string[];
};

export type AttributesStateType = {
  hasFetched: boolean;
  isFetching: boolean;
  attributes: AttributeType[];
};

export const RECEIVE_ATTRIBUTE = 'RECEIVE_ATTRIBUTE';
export const RECEIVE_ATTRIBUTES = 'RECEIVE_ATTRIBUTES';
export const REQUEST_ATTRIBUTES = 'REQUEST_ATTRIBUTES';
export const ATTRIBUTES_REQUEST_ERROR = 'ATTRIBUTES_REQUEST_ERROR';

type RequestAttributesActionType = {
  type: typeof REQUEST_ATTRIBUTES;
};

type ReceiveAttributeActionType = {
  type: typeof RECEIVE_ATTRIBUTE;
  payload: {
    [key: string]: any;
  };
};

type ReceiveAttributesActionType = {
  type: typeof RECEIVE_ATTRIBUTES;
  payload: {
    [key: string]: any;
  };
};

type AttributesRequestErrorType = {
  type: typeof ATTRIBUTES_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type AttributesActionType =
  | RequestAttributesActionType
  | ReceiveAttributeActionType
  | ReceiveAttributesActionType
  | AttributesRequestErrorType;
