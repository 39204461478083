import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'normalize.css';

import { isProduction } from 'util/request';

import configureStore from './store';
import AppV2 from './App';

Sentry.init({
  dsn: 'https://d089779fc2330ebe14a5c3c62482353d@o4505682002509824.ingest.sentry.io/4506033305812992',
  enabled: isProduction,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <AppV2 />
    </BrowserRouter>
  </Provider>,
);
