import { LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';
import { getString } from 'strings/translation';

export const getRootwormRiskText = (riskLevel: string, isField: boolean, language: string) => {
  if (riskLevel === LOW_RISK) {
    if (isField) {
      return getString('lowRiskRwFieldTooltip', language);
    }
    return getString('lowRiskRwZoneTooltip', language);
  }
  if (riskLevel === MODERATE_RISK) {
    if (isField) {
      return getString('moderateRiskRwFieldTooltip', language);
    }
    return getString('moderateRiskRwZoneTooltip', language);
  }
  return getString('highRiskRwFieldTooltip', language);
};

export default { getRootwormRiskText };
