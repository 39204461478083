import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';

export const requestDeleteAttribute = (id: number) =>
  requestDelete(`${SERVICE_URL}/rnd_attribute/${id}`).then(handleJsonResponse);

export const requestGetAttributes = () =>
  requestGet(`${SERVICE_URL}/rnd_attributes/`).then(handleJsonResponse);

export const requestPutAttribute = (id: number, body: any) =>
  requestPut(`${SERVICE_URL}/rnd_attribute/${id}`, {
    body,
  }).then(handleJsonResponse);

export const requestPostAttribute = (body: any) =>
  requestPost(`${SERVICE_URL}/rnd_attribute/`, {
    body,
  }).then(handleJsonResponse);
