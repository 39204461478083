import { CORN, SOYBEANS } from './variables';

export const PATTERN_SAMPLE_COST = 12;

export const SALES_FLOW_STATUSES = {
  OPEN: 'open',
  EXPIRED: 'expired',
  LOCKED: 'locked',
};

export const defaultCornInfo = {
  acreage: 1000,
  target_yield: 220,
  price_per_bushel: 5.0,
  traits: 'stacked_rw',
  seed_treatment: 'standard',
  in_furrow: 'no_infurrow',
  foliar_fungicide: false,
  seed_disease_risk: '7',
  crop: CORN,
};

export const defaultSoybeanInfo = {
  acreage: 1000,
  target_yield: 65,
  price_per_bushel: 14.0,
  traits: 'no_trait',
  seed_treatment: 'standard',
  in_furrow: 'no_infurrow',
  foliar_fungicide: false,
  seed_disease_risk: '0',
  crop: SOYBEANS,
};

export const CROP_OUTPUT_RANGES = {
  corn: {
    target_yield: [100, 600],
    price_per_bushel: [2, 10],
  },
  soybeans: {
    target_yield: [20, 100],
    price_per_bushel: [5, 25],
  },
};
