import React, { FunctionComponent, PropsWithChildren } from 'react';
import cls from 'classnames';

import styles from './SortedTableColumnSorter.module.css';

interface sortingInterface {
  attribute: string;
  order: 'desc' | 'asc';
}

type ColumnSorterPropsType = {
  sorting: sortingInterface;
  setSorting: (sorting: sortingInterface) => void;
  attribute: string;
};

const ColumnSorter: FunctionComponent<PropsWithChildren<ColumnSorterPropsType>> = ({
  sorting,
  setSorting,
  attribute,
  children,
}) => {
  const isActive = attribute === sorting.attribute;
  return (
    <div className={styles.SortedColumn}>
      <span>{children}</span>
      <div
        onClick={() => {
          if (isActive) {
            setSorting({
              attribute,
              order: sorting.order === 'desc' ? 'asc' : 'desc',
            });
          } else {
            setSorting({
              attribute,
              order: 'desc',
            });
          }
        }}
        className={cls(styles.SortingArrow, {
          [styles.Active]: isActive,
        })}
      >
        {isActive && (sorting.order === 'desc' ? <span>&darr;</span> : <span>&uarr;</span>)}
        {!isActive && <span>&darr;</span>}
      </div>
    </div>
  );
};

export default ColumnSorter;
