import { useEffect, useState } from 'react';

const useMapLoaded = (mapRef: React.MutableRefObject<mapboxgl.Map | null>) => {
  const [mapHasLoaded, setMapHasLoaded] = useState(false);

  useEffect(() => {
    const map = mapRef.current;

    if (!map) {
      return;
    }

    map.on('load', () => {
      setMapHasLoaded(true);
    });
  }, [mapRef, setMapHasLoaded]);

  return mapHasLoaded;
};

export default useMapLoaded;
