import { PriceRange } from 'store/samplePrices/types';
import { OperationType } from 'store/operation/types';
import { Agency } from 'store/agencies/types';
import { getString } from 'strings/translation';
import { getHostName } from './auth';
import { PATTERN, regexPattern, regexPatternAg } from 'constants/agency';

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

export const capitalizeEveryWord = (s: string): string =>
  s
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');

/**
 * Transforms middleEllipsis(ReallyLongWord, 8) => Real...Word
 * @param name
 * @param maxLength
 */
export const middleEllipsis = (name: string, maxLength: number) => {
  if (name.length <= maxLength) {
    return name;
  }
  const fragmentLength = Math.floor(maxLength / 2 - 1);
  return `${name.slice(0, fragmentLength)}...${name.slice(name.length - fragmentLength)}`;
};

export const endEllipsis = (s: string, maxLength: number) => {
  if (s.length <= maxLength) {
    return s;
  }
  return `${s.slice(0, maxLength)}...`;
};

export const numsToInvoiceDollars = (n: number): string => {
  const dollars = new Intl.NumberFormat('en-US').format(Math.abs(n));
  const formattedAmount = n >= 0 ? `+$${dollars}` : `-$${dollars}`;
  return formattedAmount;
};

export const joinStrings = (stringArray: (string | undefined)[], joinType: string) => {
  const stringsExist = stringArray.filter((single) => single);
  return stringsExist.join(joinType);
};

const encoderForArrayFormat = () => (key: string) => (result: string[], value: string) =>
  value === undefined
    ? result
    : [...result, [encodeURIComponent(key), '=', encodeURIComponent(value)].join('')];

export const queryStringify = (queryObject: { [key: string]: any }) =>
  Object.keys(queryObject)
    .filter((key) => queryObject[key] !== '')
    .map((key) => {
      const value = queryObject[key];

      if (value === undefined) {
        return '';
      }

      if (Array.isArray(value)) {
        return value.reduce(encoderForArrayFormat()(key), []).join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter((x) => x.length > 0)
    .join('&');

export const formatFloatString = (floatString: string) => floatString.replace(/[^0-9.]/g, '');

export const formatIntString = (floatString: string) => floatString.replace(/[^0-9]/g, '');

export const cleanFileName = (fileName: string) =>
  fileName.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, '');

export const convertSpacesToUnderscores = (s: string) => s.replace(/ /g, '_');

export const samplePricesWithRanges = (samplePricing: PriceRange[]) =>
  samplePricing
    .sort((a, b) => (a.max_density > b.max_density ? 1 : -1))
    .reduce((display: string[], range: PriceRange, indx, priceRangeaArr) => {
      return [
        ...display,
        `(${priceRangeaArr[indx - 1] ? priceRangeaArr[indx - 1].max_density : 0} - ${
          range.max_density
        }) = $${range.price.toFixed(2)}`,
      ];
    }, [])
    .join(', ');

export const displayDivide = (numerator: string | number, denominator: string | number) => {
  return !denominator || denominator === '0' ? 'N/A' : `${numerator} / ${denominator}`;
};

export const getOperationAgencyDisplayName = (opOrAg: OperationType | Agency, language: string) => {
  if ('billing_user' in opOrAg) {
    return `${getString('operation', language)}: ${opOrAg.name}`;
  }
  // @ts-ignore
  return `${getString(opOrAg.hierarchy, language)}: ${opOrAg.name}`;
};

// Take in an array and sort it by a given order
export const sortByStringArr = (arr: string[], order: string[]) =>
  arr.sort((a, b) => {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);

    return indexA - indexB;
  });

const translationReplace = (regex: RegExp, translation: string, hostName: string) =>
  translation?.replace(regex, capitalizeEveryWord(hostName));

const replaceWhitelabel = (translation: string, hostName: string) => {
  if (hostName === PATTERN) {
    return translation;
  }
  // Hit on 'Pattern Ag' and 'Pattern' (ex: Pattern 360)
  const result = translationReplace(regexPatternAg, translation, hostName);
  return translationReplace(regexPattern, result, hostName);
};

export const getWhiteLabelTranslation = (translation: string) =>
  replaceWhitelabel(translation, getHostName());

export const getCombinedString = (
  str1: string | null | undefined,
  str2: string | null | undefined,
  language: string,
) => (str1 ? `${str1} ${str2}` : getString('none', language));
