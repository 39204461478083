import React from 'react';

import { Modal, Button, Group } from '@mantine/core';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type DoubleSamplePrintingModalProps = {
  handlePrint: () => {};
  onClose: () => void;
};

export const DoubleSamplePrintingModal = ({
  handlePrint,
  onClose,
}: DoubleSamplePrintingModalProps) => {
  const language = useBroswerLanguage();

  const handleConfirmedPrint = () => {
    handlePrint();
    onClose();
  };

  const handleCancelledPrint = () => {
    onClose();
  };

  return (
    <Modal
      opened
      onClose={onClose}
      title={getString('alertDoubleSamplePrintingTitle', language)}
      centered
    >
      <p>{getString('alertDoubleSamplePrinting', language)}</p>

      <Group justify="center">
        <Button onClick={handleConfirmedPrint}>{getString('yes', language)}</Button>
        <Button onClick={handleCancelledPrint}>{getString('no', language)}</Button>
      </Group>
    </Modal>
  );
};
