import React from 'react';
import classNames from 'classnames';
import { SamplingPlanType } from 'store/fields/types';
import { productAbbrNames } from 'constants/products';
import { COMPLETED } from 'constants/samplePlanning';
import { isProductComplete, getProductList } from 'util/product';
import styles from './Status.module.css';

type StatusPropsType = {
  samplingPlan: SamplingPlanType;
};

export const generateStatusBubble = (product: string, isComplete?: boolean) => (
  <div
    className={classNames(styles.Bubble, {
      [styles.FilledBubble]: isComplete,
    })}
    key={product}
  >
    <span className={styles.BubbleText}>{productAbbrNames[product]}</span>
  </div>
);

const Status = ({ samplingPlan }: StatusPropsType) => {
  return (
    <div className={styles.Wrapper}>
      {getProductList(
        samplingPlan.products,
        samplingPlan.is_pro,
        samplingPlan.is_till_rx,
        samplingPlan.is_590_analysis,
      ).map((product) =>
        generateStatusBubble(
          product,
          samplingPlan?.sampling_status === COMPLETED || isProductComplete(samplingPlan, product),
        ),
      )}
    </div>
  );
};

export default Status;
