export const REGULATED_SOIL_PDF = 'regulated-soil';

export const REGULATED_PACKING_SLIP_PDF = 'regulated-packing-slip';

export const DEFAULT_PARCEL_DIMENSIONS = {
  parcelLength: '12',
  parcelWidth: '12',
  parcelHeight: '8',
  parcelWeight: '50',
};

export const MAX_LABELS_CREATE = 80;

export const US_EMERYVILLE = 'us_emeryville';
export const US_EMERYVILLE_REGULATED = 'us_emeryville_regulated';
export const US_MEMPHIS = 'us_memphis';
export const BR_CAMPINAS = 'br_campinas';

export const labDisplayNames = {
  [US_EMERYVILLE]: 'Emeryville',
  [US_EMERYVILLE_REGULATED]: 'Emeryville (Regulated)',
  [US_MEMPHIS]: 'Memphis',
  [BR_CAMPINAS]: 'Campinas',
};

export const UNKNOWN = 'UNKNOWN';
export const PRE_TRANSIT = 'PRE_TRANSIT';
export const TRANSIT = 'TRANSIT';
export const DELIVERED = 'DELIVERED';
export const RETURNED = 'RETURNED';
export const FAILURE = 'FAILURE';
export const CANCELLED = 'CANCELLED';

export const trackingStatusDisplayNames = {
  [UNKNOWN]: 'unknown',
  [PRE_TRANSIT]: 'preTransit',
  [TRANSIT]: 'transit',
  [DELIVERED]: 'delivered',
  [RETURNED]: 'returned',
  [FAILURE]: 'failure',
  [CANCELLED]: 'cancelled',
};

export const PENDING = 'pending';
export const COMPLETED = 'completed';
export const FAILED = 'failed';

export const JOB_COMPLETENESS = {
  [PENDING]: 0,
  [COMPLETED]: 1,
  [FAILED]: -1,
};
