import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'common/Components/Mantine/Typeahead';
import { Modal, Stack, Text, Button, Space, SegmentedControl, Checkbox, Box } from '@mantine/core';
import showToast from 'actions/toastActions';
import { useTranslation, getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { capitalizeEveryWord } from 'util/stringUtils';
import sortByName from 'util/sortByName';
import { RootState } from 'store';
import { getPatternSamplers } from 'store/user/thunks';
import { requestPutBulkAssignPlans } from 'store/samplePlans/requests';
import { SamplePlanTrackingType, SamplePlanType } from 'store/samplePlans/types';
import { FiSearch } from 'react-icons/fi';
import { SAMPLE, SAMPLE_SCAN, SCAN } from 'constants/samplePlanning';
import { getBulkAssignTabs, getSamplingPlanBulkBuckets } from 'util/samplePlanTracking';

type BulkAssignSamplerPropsType = {
  samplePlans: SamplePlanTrackingType[];
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type OptionType = {
  name: string;
  label: string;
  id: number | null;
  value: number | null;
  can_scan: boolean;
};

export const BulkAssignSampler = ({
  samplePlans,
  open,
  onClose,
  onSubmit,
}: BulkAssignSamplerPropsType) => {
  const typeaheadRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [selectedView, setSelectedView] = useState(SAMPLE);
  const [userSelected, setUserSelected] = useState<OptionType | undefined>(undefined);
  const [scannerSelected, setScannerSelected] = useState<OptionType | undefined>(undefined);
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const [viewUser, setViewUser] = useState<string>('');
  const [viewScanner, setViewScanner] = useState<string>('');
  const [completedViews, setCompletedViews] = useState<string[]>([]);
  const [eoSampleDispatch, toggleEoSampleDispatch] = useState(false);
  const [eoScanDispatch, toggleEoScanDispatch] = useState(false);

  const { samplers } = useSelector((state: RootState) => ({
    samplers: state.user.patternSamplers,
  }));

  const assignmentPlans = getSamplingPlanBulkBuckets(samplePlans);

  useEffect(() => {
    if (!samplers) {
      dispatch(getPatternSamplers());
    }
  }, [samplers]);

  const getDefaultBulkAssignTab = () => {
    if (assignmentPlans[SAMPLE_SCAN].length) {
      return SAMPLE_SCAN;
    }
    if (assignmentPlans[SCAN].length) {
      return SCAN;
    }
    return SAMPLE;
  };

  useEffect(() => {
    setSelectedView(getDefaultBulkAssignTab());
  }, [samplePlans]);

  const plans = assignmentPlans[selectedView];

  const options = samplers
    ? [
        {
          id: -1,
          label: getString('none', language),
          name: getString('none', language),
          value: -1,
          can_scan: true,
        },
        ...sortByName(
          samplers.map((user) => {
            const displayName = capitalizeEveryWord(`${user.first_name} ${user.last_name}`);
            return {
              id: user.id,
              label: displayName,
              name: displayName,
              value: user.id,
              can_scan: user.can_scan,
            };
          }),
        ),
      ]
    : [];

  const submit = async () => {
    toggleIsSubmitting(true);
    const body = {
      user_id: userSelected?.id || null,
      scanner_id: scannerSelected?.id || null,
      plan_ids: plans.map((plan: SamplePlanType | SamplePlanTrackingType) => plan.id),
      is_eo_sample_dispatch: eoSampleDispatch,
      is_eo_scan_dispatch: eoScanDispatch,
    };

    try {
      await requestPutBulkAssignPlans(body);
      showToast(getString('assignSamplersSuccessMsg', language));
      const tempCompleted = [...completedViews, selectedView];
      setCompletedViews(tempCompleted);
      const isOnlySampleOrScan =
        !assignmentPlans[SAMPLE].length || !assignmentPlans[SAMPLE_SCAN].length;
      if (tempCompleted.length === 2 || (isOnlySampleOrScan && tempCompleted.length)) {
        // Only close if both views have been completed or if there is only one view
        onSubmit();
        onClose();
      }
      updateSampleView(selectedView === SAMPLE ? SAMPLE_SCAN : SAMPLE);
    } catch (error) {
      showToast(getString('assignSamplerError', language), 'error', 7000);
    } finally {
      toggleIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (typeaheadRef.current) {
      typeaheadRef.current.focus();
    }
  }, [typeaheadRef]);

  const handleUserSelection = (val: number | string) => {
    const sampler = options.find((option) => option.id === Number(val));
    setViewUser(sampler?.label || '');
    setUserSelected(sampler);
  };

  const handleScannerSelection = (val: number | string) => {
    const sampler = options.find((option) => option.id === Number(val));
    setViewScanner(sampler?.label || '');
    setScannerSelected(sampler);
  };

  const removeFilter = () => {
    setUserSelected(undefined);
    setViewUser('');
  };

  const removeScannerFilter = () => {
    setScannerSelected(undefined);
    setViewScanner('');
  };

  const handleTextChangeUser = (value: string) => {
    setViewUser(value);
  };

  const handleTextChangeScanner = (value: string) => {
    setViewScanner(value);
  };

  const updateSampleView = (val: string) => {
    removeFilter();
    removeScannerFilter();
    toggleEoScanDispatch(false);
    toggleEoSampleDispatch(false);
    setSelectedView(val);
  };

  const updateSampleEO = (val: boolean) => {
    toggleEoSampleDispatch(val);
    if (val) {
      removeFilter();
    }
  };

  const updateScannerEO = (val: boolean) => {
    toggleEoScanDispatch(val);
    if (val) {
      removeScannerFilter();
    }
  };

  return (
    <Modal opened={open} onClose={onClose} size="lg" centered>
      <Stack justify="center" align="center" gap="lg">
        <Text size="xl" fw={700}>
          {useTranslation('assignSampler')}
        </Text>
        <SegmentedControl
          value={selectedView}
          data={getBulkAssignTabs(assignmentPlans, language)}
          onChange={(val) => updateSampleView(val)}
        />
        <Stack gap="xs">
          <Text>
            <b>{getString('samplePlans', language)}:</b> {plans.length}
          </Text>
          <Text>
            <b>{getString('acres', language)}:</b>{' '}
            {plans
              .reduce((total_acres, plan) => total_acres + plan.calculated_area_acres, 0)
              .toFixed(2)}
          </Text>
          <Text>
            <b>{getString('samples', language)}:</b>{' '}
            {plans.reduce(
              (total_samples, plan) => total_samples + plan.sample_total_active_count,
              0,
            )}
          </Text>
        </Stack>
        <Box
          display="grid"
          w="90%"
          style={{
            gridTemplateColumns: 'auto 1fr auto',
            gap: 'var(--mantine-spacing-lg)',
            alignItems: 'center',
          }}
        >
          {selectedView !== SCAN && (
            <>
              <Text>{getString('soilCollection', language)}</Text>
              <Typeahead
                onTextChange={handleTextChangeUser}
                onSelect={handleUserSelection}
                onDeselect={removeFilter}
                data={options}
                data-test-id="assign-sampler-search-bar"
                placeholder={getString('searchSampler', language)}
                leftSection={<FiSearch />}
                value={viewUser}
              />
              <Checkbox
                label="EO dispatch"
                checked={eoSampleDispatch}
                onChange={(event) => updateSampleEO(event.currentTarget.checked)}
              />
            </>
          )}
          {[SAMPLE_SCAN, SCAN].includes(selectedView) && (
            <>
              <Text>{getString('soilScan', language)}</Text>
              <Typeahead
                onTextChange={handleTextChangeScanner}
                onSelect={handleScannerSelection}
                onDeselect={removeScannerFilter}
                data={options.filter((option) => option.can_scan)}
                data-test-id="assign-scanner-search-bar"
                placeholder={getString('searchSampler', language)}
                leftSection={<FiSearch />}
                value={viewScanner}
              />
              <Checkbox
                label="EO dispatch"
                checked={eoScanDispatch}
                onChange={(event) => updateScannerEO(event.currentTarget.checked)}
              />
            </>
          )}
        </Box>
        <Button onClick={submit} disabled={isSubmitting}>
          {getString('inviteSampler', language)}
        </Button>
      </Stack>
      <Space h="md" />
    </Modal>
  );
};
