import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BIOACTIVITY_PANEL_FLAG_ID, FULL_PRODUCT_REPORT_FLAG_ID } from 'constants/featureFlags';
import { FeatureFlagType } from 'store/featureFlags/types';
import { editFeatureFlag } from 'store/featureFlags/thunks';
import { Center, Group, Input, Loader, Switch, Tooltip } from '@mantine/core';
import { RootState } from 'store';
import { updateOperation } from 'store/operation/thunks';
import { userIsSuperAdmin as userIsSuperAdminSelector } from 'store/user/selectors';

type FeatureFlagsPropsType = {
  operationId: number;
};

const FeatureFlags = ({ operationId }: FeatureFlagsPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { isSuperAdmin, operation, featureFlags } = useSelector((state: RootState) => ({
    isSuperAdmin: userIsSuperAdminSelector(state),
    operation: state.operations.operationsById[operationId],
    featureFlags: state.featureFlags.flags,
  }));

  const findFlag = (featureFlagId: number) => featureFlags.find((f) => f.id === featureFlagId);
  const flagIsActive = (featureFlag: FeatureFlagType | undefined) =>
    featureFlag?.id_whitelist.some((id) => id === operationId);
  const bioactivityFlag = findFlag(BIOACTIVITY_PANEL_FLAG_ID);
  const fullProductReportFlag = findFlag(FULL_PRODUCT_REPORT_FLAG_ID);
  const isSoilHealthActive = flagIsActive(bioactivityFlag);
  const isFullProductReportActive = flagIsActive(fullProductReportFlag);

  const toggleFeatureFlag = (featureFlag: FeatureFlagType | undefined) => {
    if (featureFlag) {
      const { id_whitelist: oldWhiteList } = featureFlag;
      const newWhitelist = flagIsActive(featureFlag)
        ? oldWhiteList.filter((id) => id !== operationId)
        : oldWhiteList.concat(operationId);

      if (featureFlag.id) {
        dispatch(
          editFeatureFlag(featureFlag.id, {
            ...featureFlag,
            id_whitelist: newWhitelist,
          }),
        );
      }
    }
  };

  if (!operation || !featureFlags) {
    return (
      <Center h="10rem">
        <Loader />
      </Center>
    );
  }
  return (
    <Center>
      <Group justify="space-between" align="center" w="50%">
        <Input.Wrapper label={getString('nutrients', language)}>
          <Switch
            size="lg"
            checked={isFullProductReportActive}
            onChange={() => toggleFeatureFlag(fullProductReportFlag)}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('bioactive', language)}>
          <Switch
            size="lg"
            checked={isSoilHealthActive}
            onChange={() => toggleFeatureFlag(bioactivityFlag)}
          />
        </Input.Wrapper>
        <Input.Wrapper label={getString('excludeFromAnalysis', language)}>
          <Switch
            data-test-id="exclude-from-analysis"
            size="lg"
            checked={operation.excluded_from_analysis}
            onChange={() => {
              dispatch(
                updateOperation({
                  id: operationId,
                  excluded_from_analysis: !operation.excluded_from_analysis,
                }),
              );
            }}
          />
        </Input.Wrapper>
        {isSuperAdmin && (
          <Tooltip label={getString('notSentToNetsuiteMsg', language)} color="blue">
            <Input.Wrapper label={getString('doNotBill', language)}>
              <Switch
                size="lg"
                checked={operation.is_not_billable}
                onChange={() =>
                  dispatch(
                    updateOperation({
                      id: operationId,
                      is_not_billable: !operation.is_not_billable,
                    }),
                  )
                }
              />
            </Input.Wrapper>
          </Tooltip>
        )}
      </Group>
    </Center>
  );
};

export default FeatureFlags;
