import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Table } from '@mantine/core';
import { Header } from 'common/Components/Mantine/Header';
import { LoadingRows, Spinner } from 'common';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { CatalogType } from 'store/catalogs/types';
import { getString } from 'strings/translation';
import { getAllCatalogs } from 'store/catalogs/thunks';

const CatalogListContainer = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { catalogs, hasFailed, hasFetched, isFetching } = useSelector((state: RootState) => ({
    catalogs: state.catalogs.all,
    hasFailed: state.catalogs.hasFailed,
    hasFetched: state.catalogs.hasFetched,
    isFetching: state.catalogs.isFetching,
  }));

  useEffect(() => {
    dispatch(getAllCatalogs());
  }, []);

  if (isFetching) {
    return <Spinner fill />;
  }

  return (
    <Stack>
      <Header title={getString('manageCatalogs', language)}>
        <Button onClick={() => navigate('/catalog/create')}>{getString('create', language)}</Button>
      </Header>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{getString('name', language)}</Table.Th>
            <Table.Th>{getString('user', language)}</Table.Th>
            <Table.Th />
            <Table.Th>{getString('active', language)}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {!hasFetched || hasFailed ? (
            <LoadingRows rowSpan={4} colSpan={2} />
          ) : (
            catalogs.map((row: CatalogType) => (
              <Table.Tr key={row.id} onClick={() => navigate(`/catalog/${row.id}`)}>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row?.created_by_name || 'No User'}</Table.Td>
                <Table.Td />
                <Table.Td>{getString(row.show_in_ui ? 'yes' : 'no', language)}</Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export default CatalogListContainer;
