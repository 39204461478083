import React, { useCallback, useEffect, useMemo, useState } from 'react';
import center from '@turf/center';
import {
  polygon,
  Position,
  Properties,
  FeatureCollection,
  Geometry,
  Polygon,
  multiPolygon,
} from '@turf/helpers';
import 'mapbox-gl/dist/mapbox-gl.css';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import { v4 as uuid } from 'uuid';
import showToast, { type ToastType } from 'actions/toastActions';
import { Spinner } from 'common';
import { Button } from '@mantine/core';
import { WHITE } from 'util/mapImageryColors';
import { BRAZIL_VIEWPORT, US_MIDWEST_VIEWPORT } from 'constants/mapViewport';
import useMapboxGl from 'common/MapHooks';
import { FieldType } from 'store/fields/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { User } from 'store/user/types';

import DrawingTools from './DrawingTools';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from './FieldBoundaryEditor.module.css';
import { Feature, GeoJsonProperties, MultiPolygon } from 'geojson';
import { MERGE, MODES, SPLIT } from 'constants/mapbox';
import { CLU_ZOOM_THRESHOLD, cluLayers } from 'constants/clus';
import { requestMergeFields, requestSplitFields } from 'store/fields/requests';
import { useDispatch } from 'react-redux';
import { clearFieldGeometries } from 'store/fields/actions';
import { OperationType } from 'store/operation/types';
import { requestSingleOperation } from 'store/operation/requests';
import { receiveSingleOperation } from 'store/operation/actions';
import centroid from '@turf/centroid';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import intersect from '@turf/intersect';
import difference from '@turf/difference';

interface ViewPortType {
  latitude: number;
  longitude: number;
  zoom: number;
}

type FieldEditorPropsType = {
  cluPolygons: Feature<MultiPolygon, GeoJsonProperties>[] | null;
  selectedClusPolygons: FeatureCollection<Geometry, Properties>;
  selectedClus: number[];
  setSelectedClus: React.Dispatch<React.SetStateAction<number[]>>;
  reloadClus: () => void;
  loadingClus: boolean;
  fieldGeometry: FieldType;
  allFieldGeometries: { [fieldId: number]: FieldType };
  drawRef: any;
  fieldIds: number[];
  operation: OperationType;
  isNewField: boolean;
  mapContainerRef: any;
  mode: string;
  setMode: (arg0: string) => void;
  setViewport: (viewport: ViewPortType) => void;
  viewport: ViewPortType;
  mapRef: any;
  user: User | null;
  mergeSplitType: string | null;
  setMergeSplitType: (arg0: string | null) => void;
};

const FieldBoundaryEditor = ({
  reloadClus,
  loadingClus,
  cluPolygons,
  selectedClusPolygons,
  selectedClus,
  setSelectedClus,
  drawRef,
  fieldGeometry,
  allFieldGeometries,
  fieldIds,
  operation,
  isNewField,
  mapContainerRef,
  mode,
  setMode,
  setViewport,
  viewport,
  mapRef,
  user,
  mergeSplitType,
  setMergeSplitType,
}: FieldEditorPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [initialViewport, setInitialViewport] = useState(
    user?.user_locale === 'en' ? US_MIDWEST_VIEWPORT : BRAZIL_VIEWPORT,
  );
  const [mergeSelectedPolygons, setMergeSelectedPolygons] = useState<number[]>([]);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [showClus, setShowClus] = useState(isNewField);
  const [isLoading, toggleLoading] = useState(false);

  const updateCluFilter = useCallback(
    (id: number) => {
      setSelectedClus((prev: number[]) => {
        if (prev.includes(id)) {
          return prev.filter((item) => item !== id);
        }
        return [...prev, id];
      });
    },
    [selectedClus, setSelectedClus],
  );

  const setToastMessage = (message: string, type?: ToastType, timeout?: boolean | number) =>
    showToast(message, type, timeout);

  const updateMergeFilter = useCallback(
    (fieldId) => {
      setMergeSelectedPolygons((prev) => {
        if (prev.includes(fieldId)) {
          return prev.filter((item) => item !== fieldId);
        }
        return [...prev, fieldId];
      });
    },
    [setMergeSelectedPolygons],
  );

  useMapboxGl(mapContainerRef, mapRef, drawRef, viewport, setViewport, setMode, true, true);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('load', () => {
        setMapHasLoaded(true);
      });
      // allow users to select clus when active
      mapRef.current.on('click', 'clus-fill-unselected', (event) => {
        const features = mapRef.current.queryRenderedFeatures(event.point, {
          layers: ['clus-fill-unselected'],
        });
        if (features.length) {
          const clickedFeature = features[0];
          updateCluFilter(clickedFeature.id);
        }
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    if (mode === MODES.DIRECT) {
      setShowClus(false);
    }
  }, [mode]);

  const addBoundaryToMap = useCallback(
    (boundary) => {
      const source = { type: 'geojson', data: boundary };

      const bbox = turfBbox(fieldGeometry);
      const bboxPoly = turfBboxPolygon(bbox);
      const [longitude, latitude] = center(bboxPoly).geometry.coordinates;

      mapRef.current.addLayer({
        id: 'fieldOutline',
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 1 },
      });
      mapRef.current.fitBounds(bbox, { duration: 0, padding: 30 });
      const zoom = mapRef.current.getZoom();
      setInitialViewport({
        latitude,
        longitude,
        zoom,
      });
      setViewport({
        latitude,
        longitude,
        zoom,
      });
    },
    [fieldGeometry, mapRef, setViewport],
  );

  useEffect(() => {
    if (mapHasLoaded && fieldGeometry?.features[0].geometry) {
      addBoundaryToMap(fieldGeometry);
    }
  }, [addBoundaryToMap, fieldGeometry, mapHasLoaded]);

  const addClusToEditor = useCallback(() => {
    if (drawRef.current && selectedClusPolygons.features?.length) {
      const featureIds = selectedClusPolygons.features.map((feature) =>
        feature.geometry.coordinates
          .map((geom) =>
            drawRef.current.add(
              polygon(geom, {
                id: uuid(),
                renderType: 'Polygon',
              }),
            ),
          )
          .flat(),
      );
      const ids = featureIds.flat();
      drawModeSetter(MODES.SELECT, ids)();
    }
  }, [drawRef, selectedClusPolygons]);

  const addFieldToEditor = useCallback(
    (geoJson = fieldGeometry) => {
      const { geometry } = geoJson.features[0];
      geometry.coordinates.forEach((geom: Position[][]) =>
        drawRef.current.add(
          polygon(geom, {
            id: uuid(),
            renderType: 'Polygon',
          }),
        ),
      );
    },
    [drawRef, fieldGeometry],
  );

  useEffect(() => {
    if (drawRef.current && fieldGeometry?.features[0].geometry) {
      if (!drawRef.current.getAll().features.length) {
        addFieldToEditor(fieldGeometry);
      }
    }
  }, [addFieldToEditor, drawRef, fieldGeometry]);

  useEffect(() => {
    // whenever we select a clu, update the filter we use to highlight it
    if (mapRef.current.getLayer('clus-fill-selected')) {
      mapRef.current.setFilter('clus-fill-selected', ['in', 'id', ...selectedClus]);
    }
  }, [selectedClus]);

  const addClusToMap = useCallback(
    (geoArray) => {
      const featCollection = {
        type: 'FeatureCollection',
        features: geoArray,
      };

      const source = { type: 'geojson', data: featCollection };

      if (mode === MODES.SELECT && showClus) {
        cluLayers.forEach((layer) => {
          if (!mapRef.current.getLayer(layer.id)) {
            // add a filter to the selected fill layer based on the selected clus
            if (layer.id === 'clus-fill-selected') {
              layer.filter = ['in', 'id', ...selectedClus];
            }
            layer.source = source;
            mapRef.current.addLayer(layer);
          }
        });
      }
    },
    [mode, cluPolygons, showClus],
  );

  const highlightSplitMergeSelected = useCallback(
    (feature) => {
      const newLayerId = `split-merge-${feature.properties.id}`;
      if (mapRef.current.getLayer(newLayerId)) {
        mapRef.current.removeLayer(newLayerId);
        mapRef.current.removeSource(newLayerId);
      } else {
        const featCollection = {
          type: 'FeatureCollection',
          features: [feature],
        };
        const source = { type: 'geojson', data: featCollection };
        mapRef.current.addLayer({
          id: newLayerId,
          type: 'fill',
          source,
          paint: { 'fill-color': 'orange', 'fill-opacity': 0.6 },
        });
      }
    },
    [mapRef],
  );

  const mergeSplitSelect = useCallback(
    (event) => {
      const features = mapRef.current.queryRenderedFeatures(event.point, {
        layers: [`operation=${operation?.name}`],
      });
      if (features.length) {
        const clickedFeature = features[0];
        updateMergeFilter(clickedFeature.properties.id);
        highlightSplitMergeSelected(clickedFeature);
      }
    },
    [mapRef, updateMergeFilter, highlightSplitMergeSelected],
  );

  const turnOnOffSelectMergeSplit = useCallback(
    (onOff: boolean) => {
      if (onOff) {
        mapRef.current.on('click', `operation=${operation?.name}`, mergeSplitSelect);
      } else {
        mapRef.current.off('click', `operation=${operation?.name}`, mergeSplitSelect);
      }
    },
    [mapRef, mergeSplitSelect],
  );

  const clearHighlightedSelected = () => {
    setMergeSplitType(null);
    mergeSelectedPolygons.forEach((id) => {
      if (mapRef.current.getLayer(`split-merge-${id}`)) {
        mapRef.current.removeLayer(`split-merge-${id}`);
        mapRef.current.removeSource(`split-merge-${id}`);
      }
    });
    setMergeSelectedPolygons([]);
  };

  const removeClusFromMap = useCallback(() => {
    cluLayers.forEach((layer) => {
      if (mapRef.current.getLayer(layer.id)) {
        mapRef.current.removeLayer(layer.id);
        mapRef.current.removeSource(layer.id);
      }
    });
  }, [cluPolygons, showClus]);

  const addFieldToMap = useCallback(
    (geoArray) => {
      if (!mapRef.current.getLayer(`operation=${operation?.name}`)) {
        const featCollection = {
          type: 'FeatureCollection',
          features: geoArray,
        };
        const source = { type: 'geojson', data: featCollection };
        mapRef.current.addLayer({
          id: `operation=${operation?.name}`,
          type: 'fill',
          source,
          paint: { 'fill-color': 'yellow', 'fill-opacity': 0.4 },
        });

        const bbox = turfBbox(featCollection);
        const bboxPoly = turfBboxPolygon(bbox);
        const [longitude, latitude] = center(bboxPoly).geometry.coordinates;
        mapRef.current.fitBounds(bbox, { duration: 0, padding: 150 });
        const zoom = mapRef.current.getZoom();
        setInitialViewport({
          latitude,
          longitude,
          zoom,
        });
        setViewport({
          latitude,
          longitude,
          zoom,
        });
      }
    },
    [operation?.name, setViewport, mapRef],
  );

  useEffect(() => {
    if (mapHasLoaded) {
      // if cluPolygons change, remove old clus and add new ones
      removeClusFromMap();
      addClusToMap(cluPolygons);
    }
  }, [addClusToMap, cluPolygons, showClus]);

  useEffect(() => {
    if (mapHasLoaded && isNewField && fieldIds && Object.keys(allFieldGeometries).length) {
      const featuresArray = Object.values(allFieldGeometries)
        .filter(
          (geom) => geom.features[0].geometry && fieldIds.includes(geom.features[0].properties.id),
        )
        .map((geom) => {
          return geom.features[0];
        });
      if (featuresArray.length > 0) {
        addFieldToMap(featuresArray);
      }
    }
  }, [mapRef, allFieldGeometries, fieldIds, isNewField, addFieldToMap, mapHasLoaded]);

  const drawModeSetter = (drawType: string, ids?: string[]) => () => {
    setSelectedClus([]);
    setMode(drawType);
    if (drawType === MODES.SELECT && !showClus) {
      setShowClus(true);
    } else {
      setShowClus(false);
    }
    turnOnOffSelectMergeSplit(false);
    clearHighlightedSelected();
    const featureIds = ids || [];
    drawRef.current.changeMode(drawType, { featureIds: featureIds });
  };

  const handleDelete = () => {
    const selectedIds = drawRef.current.getSelectedIds();
    drawRef.current.delete(selectedIds);
  };

  const startMergeFields = () => {
    if (mergeSplitType !== MERGE) {
      setShowClus(false);
      setMergeSplitType(MERGE);
      turnOnOffSelectMergeSplit(true);
      drawRef.current.changeMode(MODES.SELECT);
      drawRef.current.deleteAll();
    }
  };

  const startSplitFields = () => {
    if (mergeSplitType !== SPLIT) {
      drawRef.current.deleteAll();
      turnOnOffSelectMergeSplit(false);
      setShowClus(false);
      setMergeSplitType(SPLIT);
      setMode(MODES.DRAW_POLYGON);
      drawRef.current.changeMode(MODES.DRAW_POLYGON);
    }
  };

  const updateOperation = async () => {
    try {
      const newOperation = await requestSingleOperation(operation.id);
      dispatch(receiveSingleOperation(newOperation));
      dispatch(clearFieldGeometries());
    } catch (err) {
      setToastMessage(err.message, 'error', 5000);
    }
  };

  const confirmMerge = async () => {
    try {
      toggleLoading(true);
      await requestMergeFields(mergeSelectedPolygons[0], mergeSelectedPolygons.slice(1));
      setToastMessage(getString('fieldsMergedSuccessMsg', language));
      await updateOperation();
    } catch (e) {
      setToastMessage(e.message, 'error', 5000);
    } finally {
      toggleLoading(false);
      turnOnOffSelectMergeSplit(false);
      clearHighlightedSelected();
      mapRef.current.removeLayer(`operation=${operation?.name}`);
      mapRef.current.removeSource(`operation=${operation?.name}`);
    }
  };

  const confirmSplit = async (newFieldName?: string) => {
    try {
      toggleLoading(true);
      const operationFields = Object.keys(allFieldGeometries)
        .filter(
          (fieldId) =>
            fieldIds.includes(Number(fieldId)) &&
            allFieldGeometries[fieldId].features?.[0].geometry,
        )
        .map((fieldId) => {
          const feat = allFieldGeometries[fieldId].features[0];
          return multiPolygon(feat.geometry.coordinates, feat.properties);
        });

      const selectedPoly: FeatureCollection<Polygon> = drawRef.current.getAll();
      const selectedPolyCentroid = centroid(selectedPoly);
      const selectedField = operationFields.find((single) =>
        booleanPointInPolygon(selectedPolyCentroid, single),
      );
      if (selectedField) {
        const intersectionArea = intersect(selectedField, selectedPoly.features[0]);
        const differanceArea = difference(selectedField, selectedPoly.features[0]);

        if (intersectionArea && differanceArea) {
          intersectionArea.properties = {
            name: newFieldName,
            farm_name: selectedField.properties.farm_name,
          };
          differanceArea.properties = selectedField.properties;
          await requestSplitFields(selectedField.properties.id, [intersectionArea, differanceArea]);
          await updateOperation();
          drawRef.current.deleteAll();
          mapRef.current.removeLayer(`operation=${operation?.name}`);
          mapRef.current.removeSource(`operation=${operation?.name}`);
          setMergeSplitType(null);
          setMode(MODES.SELECT);
          drawRef.current.changeMode(MODES.SELECT);
          setToastMessage(getString('fieldSplitSuccessMsg', language));
        } else {
          setToastMessage(getString('missingSelectionOverlapMsg', language), 'error', 5000);
        }
      } else {
        setToastMessage(getString('missingSelectionOverlapMsg', language), 'error', 5000);
      }
    } catch (e) {
      setToastMessage(e.message, 'error', 5000);
    } finally {
      toggleLoading(false);
    }
  };

  const cancelSplitMerge = () => {
    setMergeSplitType(null);
    turnOnOffSelectMergeSplit(false);
    setMergeSelectedPolygons([]);
    clearHighlightedSelected();
    drawRef.current.deleteAll();
    drawRef.current.changeMode(MODES.SELECT);
  };

  const recenterMap = () => {
    if (mapRef.current) {
      setViewport(initialViewport);
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  const reloadClusButton = (
    <>
      {mode === MODES.SELECT && showClus && (
        <div className={styles.CluButtonsContainer}>
          {loadingClus ? (
            <Spinner className={styles.ReloadSpinner} />
          ) : (
            <>
              <Button
                disabled={mode !== MODES.SELECT || !selectedClus?.length}
                data-test-id="add-clus"
                onClick={addClusToEditor}
                variant="white"
              >
                {getString('addClus', language)}
              </Button>
              <Button
                disabled={mode !== MODES.SELECT || viewport.zoom < CLU_ZOOM_THRESHOLD}
                data-test-id="reload-clus"
                className={styles.ReloadClus}
                onClick={reloadClus}
                variant="white"
              >
                {getString('reloadClus', language)}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );

  const cluInstructions = useMemo(() => {
    const message = (() => {
      if (mode === MODES.SELECT && mergeSplitType === MERGE) {
        return 'selectFieldsToMerge';
      }
      if (mode === MODES.DRAW_POLYGON && mergeSplitType === SPLIT) {
        return 'selectFieldsToSplit';
      }
      if (mode === MODES.SELECT && viewport.zoom < CLU_ZOOM_THRESHOLD) {
        return 'cluInstructions3';
      }
      if (mode === MODES.SELECT && selectedClus?.length) {
        return 'cluInstructions2';
      }
      if (mode === MODES.SELECT && drawRef.current?.getAll().features.length) {
        return 'addFieldInstructions';
      }
      if (mode === MODES.SELECT) {
        return 'cluInstructions1';
      }
      return '';
    })();
    return (
      <>
        {Boolean(message.length) && (
          <div className={styles.InstructionsContainer}>
            <p className={styles.InstructionText}>{getString(message, language)}</p>
          </div>
        )}
      </>
    );
  }, [mode, selectedClus, selectedClusPolygons, viewport, mergeSplitType]);

  return (
    <div className={styles.Map}>
      <div data-test-id="field-editor-container" ref={mapContainerRef} className={styles.Map} />
      <DrawingTools
        showClus={showClus}
        setShowClus={setShowClus}
        addClusToEditor={addClusToEditor}
        drawModeSetter={drawModeSetter}
        handleDelete={handleDelete}
        startMergeFields={startMergeFields}
        startSplitFields={startSplitFields}
        mode={mode}
        mergeSplitType={mergeSplitType}
        mergeSelectedPolygons={mergeSelectedPolygons}
        confirmMerge={confirmMerge}
        confirmSplit={confirmSplit}
        cancelSplitMerge={cancelSplitMerge}
        language={language}
        isNewFieldWithExisting={Boolean(isNewField && fieldIds.length)}
        drawRef={drawRef}
        isLoading={isLoading}
      />
      {reloadClusButton}
      <div className={styles.Recenter}>
        <Button variant="white" data-test-id="recenter" onClick={recenterMap}>
          {getString('recenter', language)}
        </Button>
      </div>
      {cluInstructions}
    </div>
  );
};

export default FieldBoundaryEditor;
