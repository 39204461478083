import React from 'react';
import { Link } from 'react-router-dom';
import { Group, Stack, Text, Title } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { findPrimarySample, sampleIsPrimary } from 'util/sample';
import { formatCalendarMonthDayYear } from 'util/date';
import { LabSampleType } from 'store/labSamples/types';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { CROP_PROTECTION } from 'constants/results';
import { labDisplayNames } from 'constants/shipping';
import { getLatestCropPlan } from 'util/cropPlans';
import { CORN } from 'constants/variables';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styles from './Container.module.css';

type InfoPropsType = {
  samples: LabSampleType[];
};

export const InfoContainer = ({ samples }: InfoPropsType) => {
  const language = useBroswerLanguage();

  const { fieldGeometry } = useSelector((state: RootState) => ({
    fieldGeometry: state.fieldGeometry.geometries[samples[0].field_id],
  }));
  const sample = findPrimarySample(samples) || samples[0];
  const nonPrimarySamples = samples.filter((s) => !sampleIsPrimary(s));
  const nonPrimaryUuids = nonPrimarySamples.length
    ? nonPrimarySamples.map((s) => s.sample_uuid).join(',\n')
    : null;

  const generateInfoCell = (
    label: string,
    value: string | number | null,
    link?: string,
    flex: number = 1,
  ) => (
    <Stack gap="0" flex={flex} justify="flex-start" align="flex-start">
      <Text fw={700}>{`${label}: `}</Text>
      {link ? (
        <Link className={styles.Link} to={link}>
          {value}
        </Link>
      ) : (
        <Text>{value || NOT_APPLICABLE}</Text>
      )}
    </Stack>
  );

  const operationString =
    sample.operation_name && sample.operation_id
      ? `${sample.operation_name} (ID ${sample.operation_id})`
      : null;
  const operationLink = sample.operation_id
    ? `/results/operation/${sample.operation_id}/${CROP_PROTECTION}`
    : undefined;

  const fieldString = sample.field_name ? `${sample.field_name} (ID ${sample.field_id})` : null;
  const cropPlan = getLatestCropPlan(fieldGeometry);
  const fieldLink =
    sample.field_id && sample.operation_id && sample.sampling_plan_id && cropPlan?.crop
      ? `/results/field/${sample.operation_id}/${sample.field_id}/${sample.sampling_plan_id}/${CROP_PROTECTION}?crop=${cropPlan.crop || CORN}`
      : undefined;

  const countyString = sample.county ? `${sample.county.county}, ${sample.county.state}` : null;

  const createdAt = sample.created_at ? formatCalendarMonthDayYear(sample.created_at) : null;
  const sampledAt = sample.sampled_at ? formatCalendarMonthDayYear(sample.sampled_at) : null;
  const shippedAt = sample.shipped_at ? formatCalendarMonthDayYear(sample.shipped_at) : null;
  const arrivedAt = sample.arrived_at ? formatCalendarMonthDayYear(sample.arrived_at) : null;
  const arrivedAtLocation = sample.arrived_at_lab ? labDisplayNames[sample.arrived_at_lab] : null;
  const blendedAtLocation = sample.blended_at_lab ? labDisplayNames[sample.blended_at_lab] : null;

  // TODO: Sampled by user link
  const sampledByString =
    sample.sampled_by_id && sample.sampled_by_name
      ? `${sample.sampled_by_name} (ID: ${sample.sampled_by_id})`
      : null;

  // TODO: Shipping label string and link
  const shippingLabel = null;
  const shippingLabelLink = undefined;
  // TODO: RSM string and link
  const relatedRsms = null;
  // TODO: Related agencies and links
  const relatedAgencies = null;

  return (
    <Stack>
      <Title order={2} fw={700}>
        {getString('sampleSummary', language)}
      </Title>
      <Stack gap="md">
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(`${getString('sample', language)} ID`, sample.id)}
          {generateInfoCell(`${getString('primary', language)} UUID`, sample.sample_uuid)}
          {generateInfoCell(getString('type', language), sample.sample_type_repr)}
          {generateInfoCell(getString('operation', language), operationString, operationLink)}
          {generateInfoCell(getString('field', language), fieldString, fieldLink)}
          {generateInfoCell(getString('stateCounty', language), countyString)}
        </Group>
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(getString('created', language), createdAt)}
          {generateInfoCell(getString('sampled', language), sampledAt)}
          {generateInfoCell(getString('sampledBy', language), sampledByString, undefined, 2)}
          {generateInfoCell(getString('shipped', language), shippedAt)}
          {generateInfoCell(getString('shippingLabel', language), shippingLabel, shippingLabelLink)}
        </Group>
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(getString('arrived', language), arrivedAt)}
          {generateInfoCell(getString('arrivedLab', language), arrivedAtLocation)}
          {generateInfoCell(getString('blendedLab', language), blendedAtLocation, undefined, 4)}
        </Group>
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(getString('relatedRsms', language), relatedRsms)}
        </Group>
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(
            `${getString('relatedAgencies', language)} (${getString('agents', language)})`,
            relatedAgencies,
          )}
        </Group>
        <Group gap="xs" align="flex-start" flex={1}>
          {generateInfoCell(`${getString('nonPrimary', language)} UUIDs`, nonPrimaryUuids)}
        </Group>
      </Stack>
    </Stack>
  );
};
