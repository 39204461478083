import { AppThunk } from 'store';
import { getSamples } from './requests';
import { requestSamples, receiveSamples, sampleRequestError } from './actions';
import { AnalyticType } from 'store/analytics/types';
import { updateSampleAnalyticResults } from 'util/sample';

const getSamplesForField =
  (fieldId: number, analytics: AnalyticType[]): AppThunk =>
  async (dispatch) => {
    dispatch(requestSamples(fieldId));
    try {
      const response = await getSamples(fieldId);
      const modifiedSamples = updateSampleAnalyticResults(response.features, analytics);
      dispatch(receiveSamples({ id: fieldId, samples: modifiedSamples }));
    } catch (e) {
      dispatch(sampleRequestError({ id: fieldId, message: e.message }));
    }
  };

export default getSamplesForField;
