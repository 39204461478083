import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Loader, Button, Space, Group, Stack } from '@mantine/core';
import { KEYS } from 'constants/keyCodes';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { PasswordlessUserEmailType } from 'store/user/types';
import { getString } from 'strings/translation';
import { useAuth } from 'Auth';
import styles from './EmailForm.module.css';

interface PasswordFormProps {
  emailCell: PasswordlessUserEmailType;
  clearLoginType: () => void;
}

const PasswordForm = ({ emailCell, clearLoginType }: PasswordFormProps) => {
  const language = useBroswerLanguage();
  const auth = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleError = () => {
    auth.setLoadingAuth(false);
    setErrorMessage(getString('authFailed', language));
    setLoading(false);
  };

  const submit = () => {
    setLoading(true);
    auth.login({ email: emailCell.email, password }, handleError);
  };

  const submitIfEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      submit();
    }
  };

  return (
    <Stack>
      <Input.Wrapper
        label={`${getString('enterPasswordFor', language)} ${emailCell.email}`}
        error={errorMessage}
      >
        <Input
          className={styles.InputEmail}
          data-test-id="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={submitIfEnter}
          type="password"
          placeholder={`${getString('password', language)}...`}
          value={password}
        />
      </Input.Wrapper>
      <Link className={styles.ForgotLink} to="/auth/forgot">
        {getString('forgotPassword', language)}
      </Link>
      <Group>
        <Button variant="outline" onClick={clearLoginType}>
          {getString('goBack', language)}
        </Button>
        <Space w="xl" />
        <Button disabled={loading} onClick={submit} data-test-id="signin-button">
          {loading ? <Loader size={15} /> : getString('signIn', language)}
        </Button>
      </Group>
    </Stack>
  );
};

export default PasswordForm;
