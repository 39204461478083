export const PH = 'ph';
export const BPH = 'bph';
export const P = 'p';
export const K = 'k';
export const CA = 'ca';
export const MG = 'mg';
export const B = 'b';
export const CU = 'cu';
export const FE = 'fe';
export const MN = 'mn';
export const S = 's';
export const ZN = 'zn';
export const N = 'n';
export const OM_MASS_1 = 'om_mass_1';
export const OM_MASS_2 = 'om_mass_2';
export const OM_MEASURED = 'om_measured';

export const m3ChemList = [P, K, CA, MG, B, CU, FE, MN, ZN, PH, BPH];
export const phChemList = [PH, BPH];
export const kclChemList = [N, BPH];
export const aaChemList = [P, K, CA, MG, B, CU, FE, MN, ZN, S, PH, BPH];
export const omChemList = [OM_MASS_1, OM_MASS_2, OM_MEASURED];

export const tableChemList = [N, P, K, CA, MG, B, CU, FE, MN, ZN, S, PH, BPH, OM_MEASURED];

export const chemistryDisplayNames = {
  [PH as string]: 'pH',
  [BPH as string]: 'BpH',
  [P as string]: 'P',
  [K as string]: 'K',
  [CA as string]: 'Ca',
  [MG as string]: 'Mg',
  [B as string]: 'B',
  [CU as string]: 'Cu',
  [FE as string]: 'Fe',
  [MN as string]: 'Mn',
  [S as string]: 'S',
  [ZN as string]: 'Zn',
  [N as string]: 'N',
  [OM_MEASURED as string]: 'OM',
};
