import { shape, string } from 'prop-types';

export const OPERATION = 'operation';

const operationType = shape({
  name: string,
});

export const discountPrograms = [
  { label: 'Stine', value: 'stine', id: 0 },
  { label: 'Stine 2024', value: 'stine-2024', id: 1 },
  { label: 'PAGI 2024', value: 'pagi-2024', id: 2 },
];

export default operationType;
