import { useEffect } from 'react';
import { getOperationUsers } from 'store/operation/thunks';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getOperationAllAgencies } from 'store/agencies/thunks';

type ParamsType = {
  operationId: string;
};

const useOperationUsers = () => {
  const params = useParams<ParamsType>();
  const operationId = Number(params.operationId);
  const dispatch = useDispatch();
  const { operationUsers, operationAgencies } = useSelector((state: RootState) => ({
    operationAgencies: state.agencies.allAgencies,
    operationUsers: state.operations.operationsById[operationId]?.operationUsers,
  }));

  useEffect(() => {
    dispatch(getOperationUsers(operationId));
    dispatch(getOperationAllAgencies(operationId));
  }, []);

  return { operationUsers, operationAgencies };
};

export default useOperationUsers;
