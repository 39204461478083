import { ENGLISH, PORTUGUESE } from 'constants/languages';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getWhiteLabelTranslation } from 'util/stringUtils';

import ENGLISH_STRINGS from './en';
import PORTUGUESE_STRINGS from './pt';

export const getString = (key: string, language: string) => {
  let translation = ENGLISH_STRINGS?.[key as keyof typeof ENGLISH_STRINGS] || key;
  if (language !== ENGLISH) {
    translation = PORTUGUESE_STRINGS?.[key as keyof typeof PORTUGUESE_STRINGS] || key;
  }
  return getWhiteLabelTranslation(translation);
};

export const useTranslation = (key: string) => {
  const language = useBroswerLanguage();

  return getString(key, language);
};

export const listTranslate = (
  list: { id: number; value: string; displayName: string }[],
  lang: string,
) =>
  list.map((item) => ({
    ...item,
    displayName: getString(item.value, lang),
  }));

export const getLanguages = (language: string) => [
  {
    value: ENGLISH,
    displayName: getString('english', language),
    label: getString('english', language),
    id: 0,
  },
  {
    value: PORTUGUESE,
    displayName: getString('portuguese', language),
    label: getString('portuguese', language),
    id: 1,
  },
];
