export const ROOTWORM_PRESSURE_PANEL = 'rootworm_pressure_panel';
export const DECISION_DASHBOARD = 'decision_dashboard';
export const SCN_PRESSURE_PANEL = 'scn_pressure_panel';

export const NUTRIENT_PANEL = 'nutrient_panel';
export const NUTRIENT_PRO = 'nutrient_pro';
export const PRESSURE_PANEL = 'pressure_panel';
export const PERFORMANCE_PANEL = 'performance_panel';
export const NITRATE_PANEL = 'nitrate_panel';

export const PRESSURE_NUTRIENT = 'pressure_nutrient';
export const PRESSURE_PANEL_ONLY = 'pressure_panel_only';
export const PRESSURE_NUTRIENT_PANELS = 'pressure_nutrient_panels';
export const COMPLETE_BIO = 'complete_bio';
export const PATTERN_360 = 'pattern_360';
export const PATTERN_360_PRO = 'pattern_360_pro';
export const TILL_MAPPER = 'till_mapper';
export const ANALYSIS_590 = 'analysis_590';
export const NONE = 'none';

export const REFUND_PERFORMANCE = 'refund_performance';
export const REFUND_PRESSURE_PANEL = 'refund_pressure_panel';
export const REFUND_PERFORMANCE_PANEL = 'refund_performance_panel';
export const REFUND_NUTRIENT_PANEL = 'refund_nutrient_panel';
export const REFUND_NITRATE_PANEL = 'refund_nitrate_panel';
export const REFUND_SAMPLING_FEE = 'refund_sampling_fee';

export const PRO_SENSOR_SCAN = 'pro_sensor_scan';
export const PRO_MAP_GENERATION = 'pro_map_generation';
export const COMMISSION_PRO_SENSOR_SCAN = 'commission_pro_sensor_scan';
export const COMMISSION_PRO_MAP_GENERATION = 'commission_pro_map_generation';
export const TILLAGE_MEASUREMENT = 'tillage_measurement';
export const TILLAGE_MAP_GENERATION = 'tillage_map_generation';
export const COMMISSION_TILLAGE_MAP_GENERATION = 'commission_tillage_map_generation';

export const SAMPLING_FEE_KEY = 'sampling_fee';
export const COMMISSION_INCENTIVE = 'commission_incentive';
export const COMMISSION_PRESSURE_PANEL = 'commission_pressure_panel';
export const COMMISSION_PERFORMANCE_PANEL = 'commission_performance_panel';
export const DISCOUNT_PRESSURE_PANEL = 'discount_pressure_panel';
export const DISCOUNT_SAMPLING_FEE = 'discount_sampling_fee';
export const DISCOUNT_PANELS = 'discount_panels';
export const DISCOUNT = 'discount';
export const INCENTIVE = 'incentive';
export const SELLER = 'seller';
export const PRO = 'pro';
export const CONTRACT_TYPES = [SELLER, PRO];

export const BLUEPRINT_SILVER = 'blueprint_silver';
export const BLUEPRINT_GOLD = 'blueprint_gold';
export const BLUEPRINT_PLATINUM = 'blueprint_plat';

export const contractTypeDisplayNames = {
  [DISCOUNT as string]: DISCOUNT,
  [INCENTIVE as string]: INCENTIVE,
  [SELLER as string]: SELLER,
  [PRO as string]: PRO,
  [BLUEPRINT_SILVER as string]: 'silver',
  [BLUEPRINT_GOLD as string]: 'gold',
  [BLUEPRINT_PLATINUM as string]: 'platinum',
};

export const LIST = 'list';
export const AGENCY = 'agency';
export const MSRP = 'msrp';
export const agencyProductTypes = [AGENCY, MSRP, LIST];
export const productPriceTypes = [AGENCY, MSRP];
export const productList = [PRESSURE_PANEL, PERFORMANCE_PANEL, NUTRIENT_PANEL, NITRATE_PANEL];
export const incorporatedProducts = [PRESSURE_PANEL, COMPLETE_BIO, PATTERN_360, NITRATE_PANEL];
export const discountProductList = [
  DISCOUNT_PRESSURE_PANEL,
  DISCOUNT_SAMPLING_FEE,
  DISCOUNT_PANELS,
];

export const contractEditableProductsMap = {
  [MSRP]: [PRESSURE_PANEL, COMPLETE_BIO, NUTRIENT_PANEL, NITRATE_PANEL],
  [AGENCY]: [PRESSURE_PANEL, COMPLETE_BIO, NUTRIENT_PANEL, NITRATE_PANEL],
  [LIST]: [PRESSURE_PANEL, COMPLETE_BIO, PATTERN_360, NITRATE_PANEL],
};

export const splitDensityProducts = [NUTRIENT_PANEL, PRESSURE_PANEL, PERFORMANCE_PANEL];

export const SAMPLES_PAID = 'samples_paid';
export const productDisplayNames = {
  [NUTRIENT_PANEL as string]: 'Pro Nutrient Panel',
  [PRESSURE_PANEL as string]: 'Pressure Panel',
  [PERFORMANCE_PANEL as string]: 'Performance Panel',
  [NITRATE_PANEL as string]: 'Nitrate Panel',
  [COMPLETE_BIO as string]: 'Complete Bio',
  [PATTERN_360 as string]: 'Pattern 360',
  [REFUND_PERFORMANCE as string]: 'Refund - Sample Quality',
  [SAMPLING_FEE_KEY as string]: 'Sampling Fee',
  [DISCOUNT_PRESSURE_PANEL as string]: 'Stine - Pressure Panel',
  [DISCOUNT_SAMPLING_FEE as string]: 'Discount - Sampling',
  [DISCOUNT_PANELS as string]: 'Discount - Panels',
  [SAMPLES_PAID as string]: 'Samples Paid Already',
  [COMMISSION_INCENTIVE as string]: 'Commission Incentive',
  [COMMISSION_PRESSURE_PANEL as string]: 'Commission Pressure Panel',
  [COMMISSION_PERFORMANCE_PANEL as string]: 'Commission Pressure Panel',
  [REFUND_PERFORMANCE_PANEL as string]: 'Refund - Performance Panel',
  [REFUND_NUTRIENT_PANEL as string]: 'Refund - Nutrient Panel',
  [REFUND_PRESSURE_PANEL as string]: 'Refund - Pressure Panel',
  [REFUND_NITRATE_PANEL as string]: 'Refund - Nitrate Panel',
  [REFUND_SAMPLING_FEE as string]: 'Refund - Sampling Fee',
  [PRO_SENSOR_SCAN as string]: 'Pro Sensor Scan',
  [PRO_MAP_GENERATION as string]: 'Pro Map Generation',
  [COMMISSION_PRO_SENSOR_SCAN as string]: 'Commission Pro Sensor Scan',
  [COMMISSION_PRO_MAP_GENERATION as string]: 'Commission Pro Map Generation',
  [TILLAGE_MEASUREMENT as string]: 'Tillage Measurement',
  [TILLAGE_MAP_GENERATION as string]: 'Tillage Map Generation',
  [COMMISSION_TILLAGE_MAP_GENERATION as string]: 'Commission Tillage Map Generation',
  // Legacy products
  [DECISION_DASHBOARD as string]: 'Decision Dashboard',
  [ROOTWORM_PRESSURE_PANEL as string]: 'Rootworm Pressure Panel',
  [SCN_PRESSURE_PANEL as string]: 'SCN Pressure Panel',
};

export const productShortNames = {
  [NUTRIENT_PANEL as string]: 'nutrient',
  [PRESSURE_PANEL as string]: 'pressure',
  [PERFORMANCE_PANEL as string]: 'performance',
  [NITRATE_PANEL as string]: 'nitrate',
  // Legacy products
  [DECISION_DASHBOARD as string]: 'dashboard',
  [ROOTWORM_PRESSURE_PANEL as string]: 'rootworm',
  [SCN_PRESSURE_PANEL as string]: 'scn',
};

export const productAbbrNames = {
  [NUTRIENT_PANEL as string]: 'PN',
  [NUTRIENT_PRO as string]: 'Nutrient Pro',
  [PRESSURE_PANEL as string]: 'PR',
  [PERFORMANCE_PANEL as string]: 'PE',
  [NITRATE_PANEL as string]: 'NI',
  [COMPLETE_BIO as string]: 'CB',
  [PATTERN_360 as string]: '360',
  [PATTERN_360_PRO as string]: '360 Pro',
  [TILL_MAPPER as string]: 'TillMapper',
  [ANALYSIS_590 as string]: '590',
  // Legacy products
  [DECISION_DASHBOARD as string]: 'DD',
  [ROOTWORM_PRESSURE_PANEL as string]: 'CRW',
  [SCN_PRESSURE_PANEL as string]: 'SCN',
};

export const SPRING = 'spring';
export const SUMMER = 'summer';
export const FALL = 'fall';
export const timingSeasons = [SPRING, SUMMER, FALL];

export const legacyProducts = [DECISION_DASHBOARD, ROOTWORM_PRESSURE_PANEL, SCN_PRESSURE_PANEL];

export const PRESSURE = 'pressure';
export const NUTRIENT = 'nutrient';
export const PERFORMANCE = 'performance';
export const NITRATE = 'nitrate';
export const simpleProductTypes = [PRESSURE, NUTRIENT, PERFORMANCE, NITRATE];

export const CONTRACT_METRIC_PRODUCTS = [
  PRESSURE_PANEL_ONLY,
  PRESSURE_NUTRIENT_PANELS,
  COMPLETE_BIO,
  PATTERN_360,
  NUTRIENT_PANEL,
];

export const CONTRACT_10_AC = 10;
export const CONTRACT_20_AC = 20;
export const contractDensities = [CONTRACT_10_AC, CONTRACT_20_AC];

export const CURRENT_PRODUCTS = {
  [PRESSURE_PANEL_ONLY]: {
    panels: [PRESSURE_PANEL],
  },
  [COMPLETE_BIO]: {
    panels: [PRESSURE_PANEL, PERFORMANCE_PANEL],
  },
  [PATTERN_360]: {
    panels: [PRESSURE_PANEL, PERFORMANCE_PANEL, NUTRIENT_PANEL],
  },
  [NONE]: {
    panels: [],
  },
};
