import { RoleType } from 'store/roles/types';
import { Agency } from 'store/agencies/types';

export const RECEIVE_USER_SET = 'RECEIVE_USER_SET';
export const RECEIVE_SINGLE_USER = 'RECEIVE_SINGLE_USER';
export const RECEIVE_OPERATION_ASSOCIATED_USERS = 'RECEIVE_OPERATION_ASSOCIATED_USERS';
export const RECEIVE_PATTERN_SAMPLERS = 'RECEIVE_PATTERN_SAMPLERS';
export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_ALERT_NAMES = 'RECEIVE_ALERT_NAMES';
export const USERS_REQUEST_ERROR = 'USERS_REQUEST_ERROR';

export const SET_USER_FETCHING = 'SET_USER_FETCHING';

export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';

export const RECEIVE_LABS = 'RECEIVE_LABS';

export enum UserRole {
  Admin = 'admin',
  Superadmin = 'superadmin',
  Sampler = 'sampler',
  Operator = 'operator',
  Lab = 'lab',
  Agent = 'agent',
  AgencyAdmin = 'agency_admin',
}

export enum BillingPreference {
  Self = 'self',
  Customer = 'user',
  Skip = 'skip',
}

export interface FarmAccountInfo {
  name: string;
  billing_preference: BillingPreference;
}

export interface ContactInfoType {
  id?: number;
  first_name: string;
  last_name: string;
  business_name?: string | null;
  role_id?: number;
  notifications_preference?: string;
  cell?: string;
}

export interface UserSummary {
  users: User[];
  page: number;
  total: number;
  per_page: number;
}

export interface BillingAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
  id?: number;
  country_code?: string;
}

export interface UserInfo {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  skip_signature: boolean;
  business_name: string | null;
  role_id?: number;
  notifications_preference?: string;
  cell?: string;
  billing_address: BillingAddress;
  agency_name?: string;
  agency_id?: number;
  user_locale?: 'en' | 'pt';
}

export interface RegisterAgentForm {
  first_name: string;
  last_name: string;
  email: string;
  role_id?: number;
  notifications_preference?: string;
  cell?: string;
  agency_id?: number;
}

export interface RegisterUserForOperationType {
  first_name: string;
  last_name: string;
  email: string;
  cell?: string;
  role_id?: number;
}

export interface User {
  default_shipping_lab: string | null;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  email_verified: boolean;
  business_name: string | null;
  notifications_preference: string;
  cell: string | null;
  billing_addresses: BillingAddress[];
  created_at: string;
  updated_at: string;
  eula_accepted: boolean;
  is_active: boolean;
  roles: RoleType[];
  agencies: Agency[];
  agency_primary: boolean;
  user_locale: string;
  lab_location?: string;
  can_scan: boolean;
}

export type CountryType = {
  id: number;
  name: string;
  iso: string;
  fid: number;
};

export type UserStateType = {
  currentUser: User | null;
  allUsers: User[];
  byId: {
    [id: number]: User;
  };
  countries: CountryType[];
  alertNames: { displayName: string; label: string; id: string; value: string }[];
  lab_locations: string[];
  patternSamplers?: User[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  hasFailedList: number[];
  hasFetchedList: number[];
  isFetchingList: number[];
  summary: UserSummary;
};

export type PasswordlessUserEmailType = {
  email: string;
  cell?: string | null;
};

export interface TokenDTO {
  access_token: string;
  id: number;
  refresh_token: string;
}

export interface UserAgencySelectorType {
  user_id: number | null;
  agency_id: number | null;
  agency_primary_id: number | null;
  displayName: string;
  id: string;
}

export interface UserSelectorType {
  user_id: number;
  displayName: string;
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface CreateFarmAccountRequestType extends Partial<Omit<FarmAccountInfo, 'user'>> {
  user?: Partial<UserInfo>;
}

interface RequestUsersAction {
  type: typeof REQUEST_USERS;
  payload: {
    id?: number;
  };
}

interface ReceiveCountriesAction {
  type: typeof RECEIVE_COUNTRIES;
  payload: {
    countries: CountryType[];
  };
}

interface ReceiveAlertNamesAction {
  type: typeof RECEIVE_ALERT_NAMES;
  payload: {
    alertNames: { [key: string]: string };
  };
}

interface ReceiveLabsAction {
  type: typeof RECEIVE_LABS;
  payload: {
    labs: string[];
  };
}

interface RecieveUserSetAction {
  type: typeof RECEIVE_USER_SET;
  payload: {
    summary: UserSummary;
  };
}

interface RecieveSingleUserAction {
  type: typeof RECEIVE_SINGLE_USER;
  payload: User;
}

interface RecieveOperationUsersAction {
  type: typeof RECEIVE_OPERATION_ASSOCIATED_USERS;
  payload: {
    users: User[];
  };
}

interface RecievePatternSamplersAction {
  type: typeof RECEIVE_PATTERN_SAMPLERS;
  payload: {
    users: User[];
  };
}

interface UsersRequestError {
  type: typeof USERS_REQUEST_ERROR;
  payload: {
    id?: number;
  };
}

interface UserIsFetchingAction {
  type: typeof SET_USER_FETCHING;
  payload: {
    isFetching: boolean;
  };
}

export type UserActionType =
  | RequestUsersAction
  | RecieveSingleUserAction
  | RecieveOperationUsersAction
  | RecievePatternSamplersAction
  | UsersRequestError
  | RecieveUserSetAction
  | ReceiveCountriesAction
  | ReceiveLabsAction
  | ReceiveAlertNamesAction
  | UserIsFetchingAction;
