import {
  RECEIVE_ATTRIBUTE,
  RECEIVE_ATTRIBUTES,
  REQUEST_ATTRIBUTES,
  ATTRIBUTES_REQUEST_ERROR,
  AttributesStateType,
  AttributesActionType,
  AttributeType,
} from './types';

export const initialState: AttributesStateType = {
  hasFetched: false,
  isFetching: false,
  attributes: [],
};

const addOrReplace = (
  attributes: AttributeType[],
  newAttribute: AttributeType,
): AttributeType[] => {
  const duplicatesRemoved = attributes.filter((attribute) => attribute.id !== newAttribute.id);
  return duplicatesRemoved.concat(newAttribute);
};

const attributesReducer = (state = initialState, action: AttributesActionType) => {
  switch (action.type) {
    case RECEIVE_ATTRIBUTES:
      return {
        ...state,
        errorMessage: null,
        hasFetched: true,
        isFetching: false,
        attributes: action.payload.attributes.filter((a: AttributeType) => a.enabled),
      };
    case RECEIVE_ATTRIBUTE:
      return {
        ...state,
        errorMessage: null,
        hasFetched: true,
        isFetching: false,
        attributes: addOrReplace(state.attributes, action.payload.attribute),
      };
    case REQUEST_ATTRIBUTES:
      return {
        ...state,
        errorMessage: null,
        hasFetched: false,
        isFetching: true,
      };
    case ATTRIBUTES_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default attributesReducer;
