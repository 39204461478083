import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Button, Group, Stack, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { formatBatchForSubmission } from 'util/batches';
import { updateBatch } from 'store/batches/thunks';
import { BatchFormType, BatchType } from 'store/batches/types';
import { BatchFormContainer } from 'apps/Lab/Batches/Create/BatchForms/Container';

type EditBatchFormPropsType = {
  batch: BatchType;
  onClose: () => void;
};

export const EditBatchForm = ({ batch, onClose }: EditBatchFormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [batchForm, setBatchForm] = useState<BatchFormType>({
    id: 0,
    type: '',
    plates: [],
  });

  const { isFetching, errorMessage } = useSelector((state: RootState) => {
    return state.batches;
  });

  useEffect(() => {
    setBatchForm({
      id: batch.id,
      type: batch.type,
      plates: batch.plates
        .sort((a, b) => (a.batch_order > b.batch_order ? 1 : -1))
        .map((p) => ({ barcode: p.barcode, lanes: p.lanes })),
    });
  }, [batch]);

  const onSubmit = () => {
    if (batchForm.id) {
      try {
        dispatch(updateBatch(batchForm.id, formatBatchForSubmission(batchForm)));
        onClose();
      } catch (e) {
        showToast(e.message);
      }
    }
  };

  const handleOnValueChange = (
    attributeKey: string,
    newValue: string | string[] | { [key: string]: any },
  ) => {
    setBatchForm((form) => ({ ...form, [attributeKey]: newValue }) as BatchFormType);
  };

  return (
    <Stack pl="xl">
      <Group>
        <Group>
          <Button disabled={isFetching} onClick={onSubmit}>
            {getString('editBatch', language)}
          </Button>
        </Group>
        {errorMessage && <Text c="red.5">{errorMessage}</Text>}
      </Group>
      <BatchFormContainer batch={batchForm} onValueChange={handleOnValueChange} />
    </Stack>
  );
};
