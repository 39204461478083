import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mantine/core';
import center from '@turf/center';
import turfBbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import useMapboxGl from 'common/MapHooks';
import { PRESCRIPTION_COLORS, WHITE } from 'util/mapImageryColors';
import { addProPrescriptionToMap, formatZonesWithOpacity } from 'util/prescription';
import { FieldType } from 'store/fields/types';
import { PrescriptionType } from 'store/prescriptions/types';
import getZonesForPrescription from 'store/prescriptions/thunks';
import { RootState } from 'store';
import { LegendWrap } from 'apps/Results/common/MapLegend/LegendWrap';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import Popup from 'common/Maps/Popup';
import { AMOUNT_KEY, FILL_PERCENT_KEY } from 'constants/prescription';

import styles from './Prescription.module.css';
import { FIELD_OUTLINE } from 'constants/mapbox';
import { getPopupContent } from '../PopupContent';
import { PopupState } from 'common/Maps/types';

interface PrescriptionThumbnailPropsType {
  field: FieldType;
  prescription: PrescriptionType;
}

const PrescriptionThumbnail = ({ field, prescription }: PrescriptionThumbnailPropsType) => {
  const language = useBroswerLanguage();

  const { zones } = useSelector((state: RootState) => ({
    zones: state.prescriptionZones.zones[prescription.id],
  }));
  const dispatch = useDispatch();

  const [centerLongitude, centerLatitude] = useMemo(
    () => center(field).geometry?.coordinates as number[],
    [field],
  );
  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });

  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    if (!prescription.geojson_uri) {
      dispatch(getZonesForPrescription(prescription.id, field.features[0].properties.acreage_unit));
    }
  }, [dispatch, prescription, field]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (!map.getLayer(FIELD_OUTLINE)) {
        map.addLayer({
          id: FIELD_OUTLINE,
          type: 'line',
          source,
          paint: { 'line-color': WHITE, 'line-width': 2 },
        });

        map.fitBounds(bbox, {
          duration: 0,
          padding: 30,
        });
        const zoom = map.getZoom();
        setViewport((prevViewport) => ({
          ...prevViewport,
          centerLatitude,
          centerLongitude,
          zoom,
        }));
      }
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    const mapId = `prescriptions-${prescription.field_id}-${prescription.id}`;
    if (mapHasLoaded && map) {
      if (prescription.geojson_uri) {
        addProPrescriptionToMap(
          prescription,
          map,
          mapId,
          setPopupInfo,
          getPopupContent(field, language),
        );
      } else if (zones) {
        const formattedZones = formatZonesWithOpacity(zones);
        const newFeatureCollection = featureCollection(formattedZones);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;
        if (formattedZones.length) {
          map.addLayer({
            id: mapId,
            type: 'fill',
            source,
            paint: {
              'fill-color': {
                property: FILL_PERCENT_KEY,
                stops: PRESCRIPTION_COLORS,
              },
              'fill-outline-color': WHITE,
            },
          });
          map.addLayer({
            id: `${mapId}-quantity`,
            type: 'symbol',
            source,
            layout: {
              'text-field': ['get', AMOUNT_KEY],
              'text-justify': 'center',
              'text-size': 12,
            },
            paint: {
              'text-color': WHITE,
            },
          });
        }
      }
    }
  }, [mapRef, mapHasLoaded, prescription, zones]);

  return (
    <Box className={styles.Map}>
      <Box ref={mapContainerRef} className={styles.MapWrapper}>
        <LegendWrap
          colorStops={[
            PRESCRIPTION_COLORS[0][1] as string,
            PRESCRIPTION_COLORS[PRESCRIPTION_COLORS.length - 1][1] as string,
          ]}
          marks={[prescription.amount_range[1], prescription.amount_range[0]]}
          showPlus={false}
          title={getString('amount', language)}
        />
        {popupInfo && mapRef.current && (
          <Popup
            {...popupInfo}
            map={mapRef.current}
            anchor="bottom"
            onClose={() => setPopupInfo(null)}
          >
            {popupInfo.content}
          </Popup>
        )}
      </Box>
    </Box>
  );
};

export default PrescriptionThumbnail;
