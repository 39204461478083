import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button, Group, Stack, Checkbox, Text, Modal, Grid } from '@mantine/core';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FieldType } from 'store/fields/types';
import { getString } from 'strings/translation';
import { capitalizeEveryWord, getWhiteLabelTranslation } from 'util/stringUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { DISCOUNT } from 'constants/products';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { clearZoneAnalysisState, updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { deleteSamplePlan, postSamplePlan } from 'store/samplePlans/requests';
import { useNavigate } from 'react-router-dom';
import { getFieldGeometry } from 'store/fields/thunks';
import { formatToNumericDate } from 'util/date';
import { getPriceSummary, sortPriceSummaryKeys } from 'util/product';
import { PATTERN_AG } from 'constants/agency';
import styles from './Confirmation.module.css';
import AnalysisRow from './AnalysisRow';
import PlanTotal from './PlanTotal';
import {
  getAnalysisOverviewFromSummary,
  getDefaultAnalysis,
  getPriceDisplay,
  getScannerOptions,
} from 'util/samplePlan';
import { PLAN_SUMMARY_SAMPLING_KEYS } from 'constants/samplePlanning';

interface ConfirmationProps {
  deleteExisting?: boolean;
  field: FieldType;
  isViewOnly?: boolean;
  planId?: number;
  opened: boolean;
  savedState: ZoneAnalysisKeyType['plan'] | null;
}

const Confirmation = ({
  deleteExisting,
  field,
  isViewOnly,
  planId,
  opened,
  savedState,
}: ConfirmationProps) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, acreage, id, operation_id, acreage_unit } = field.features[0].properties;
  const [showAnalysis, toggleShowAnalysis] = useState(false);
  const [showSampling, toggleShowSampling] = useState(false);

  const { analysis, timings, operation } = useSelector((state: RootState) => ({
    analysis: state.zoneAnalysisV2.plan,
    timings: state.timings.timings,
    operation: state.operations.operationsById[operation_id],
  }));

  const {
    tempPlan,
    sampleTimingIndex,
    samplerIndex,
    priceSummary,
    readyToSample,
    isOrderButtonDisabled,
    agencyId,
    scannerIndex,
  } = analysis;

  const updatePlanState = useCallback(
    (metaKeyValue: ZoneAnalysisKeyType) => dispatch(updateZoneAnalysis(metaKeyValue)),
    [],
  );

  const scannerOptions = getScannerOptions(operation);
  const { operationUsers } = operation;

  const operationHasDiscount = !!operation.discount_name && !!operation.max_acres;
  const sampleTiming = timings[sampleTimingIndex]?.displayName || '';
  const isProOrTillRx = analysis.isProScan || analysis.isTillRx;
  const isSampleable = !!analysis.products.length;

  const getSamplerName = () => {
    if (samplerIndex === -1) {
      return getWhiteLabelTranslation(capitalizeEveryWord(PATTERN_AG));
    }
    if (operationUsers?.length) {
      return getWhiteLabelTranslation(
        `${operationUsers[samplerIndex].first_name} ${operationUsers[samplerIndex].last_name}`,
      );
    }
    return '';
  };

  const getScannerName = () => {
    if (scannerIndex === -1) {
      return getWhiteLabelTranslation(capitalizeEveryWord(PATTERN_AG));
    }
    if (scannerOptions?.length) {
      return getWhiteLabelTranslation(`${scannerOptions[scannerIndex]?.label}`);
    }
    return '';
  };

  const confirm = async () => {
    updatePlanState({ isOrderButtonDisabled: false });
    if (tempPlan) {
      // Cancel existing plan and create new one (Only if status = created)
      await postSamplePlan({
        ...tempPlan,
        temporary: false,
        ready_to_sample: readyToSample,
        agency_id: agencyId,
      });
      // for operations allowing multiple plans, we need to manually delete the open plan being edited
      if (planId && deleteExisting) {
        await deleteSamplePlan(planId);
      }
    }
    dispatch(getFieldGeometry(id));
    updatePlanState(getDefaultAnalysis());
    navigate(`/orders/${operation_id}`);
  };

  const cancel = async () => {
    if (isViewOnly) {
      updatePlanState({
        isOrderButtonDisabled: false,
        isConfirmationVisible: false,
      });
    } else {
      updatePlanState({
        ...savedState,
      });
    }
    if (isViewOnly) {
      dispatch(clearZoneAnalysisState());
    }
  };

  const operationHasDiscountButUsedAll =
    operationHasDiscount && !Object.keys(priceSummary).find((str) => str.includes(DISCOUNT));
  const newPriceSummary = getPriceSummary(
    priceSummary,
    language,
    analysis.analysisMode,
    analysis.isProScan || tempPlan?.is_pro,
  );

  const getOverviewRow = (
    summary: { total: number; num_zones: number },
    chevronBool: boolean,
    isAnalysis: boolean = false,
  ) => {
    const updateBoolType = isAnalysis ? toggleShowAnalysis : toggleShowSampling;

    if (!summary.total) {
      return;
    }
    return (
      <Grid columns={36}>
        <Grid.Col span={1} />
        <Grid.Col span={9}>
          <Button
            variant="transparent"
            rightSection={chevronBool ? <FiChevronDown /> : <FiChevronUp />}
            onClick={() => updateBoolType(!chevronBool)}
          >
            <Text fw={500} fs="italic">
              {getString(isAnalysis ? 'analysis' : 'sampling', language)}
            </Text>
          </Button>
        </Grid.Col>
        <Grid.Col span={2} />
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {summary.num_zones}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total / summary.num_zones)}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total)}
          </Text>
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fs="italic">
            {getPriceDisplay('', summary.total / acreage)}
          </Text>
        </Grid.Col>
      </Grid>
    );
  };

  const lastUpdated = tempPlan?.updated_at || tempPlan?.created_at;

  const sortedPriceSummaryKeys = sortPriceSummaryKeys(newPriceSummary);

  const priceSummarySplit = sortedPriceSummaryKeys.reduce(
    (acc, key) =>
      PLAN_SUMMARY_SAMPLING_KEYS.includes(key)
        ? { ...acc, sampling: { ...acc.sampling, [key]: newPriceSummary[key] } }
        : { ...acc, analysis: { ...acc.analysis, [key]: newPriceSummary[key] } },
    { sampling: {}, analysis: {} },
  );

  const analysisOverview = getAnalysisOverviewFromSummary(priceSummarySplit.analysis);
  const samplingOverview = getAnalysisOverviewFromSummary(priceSummarySplit.sampling);

  return (
    <Modal opened={opened} withCloseButton onClose={cancel} size="50rem" centered>
      <Stack className={styles.Dialog} gap="xs">
        <Stack align="center" gap="xs">
          <Text size="xl" fw={700}>
            {`${
              isViewOnly
                ? getString('samplePlanDetails', language)
                : getString('orderSamplePlan', language)
            }: ${name}`}
          </Text>
          {isViewOnly && (
            <>
              <Text>
                {getString('lastUpdated', language)}:{' '}
                {lastUpdated && formatToNumericDate(lastUpdated)}
              </Text>
              <Text>
                {getString('acres', language)}: {acreage.toFixed(2)}
              </Text>
            </>
          )}
        </Stack>
        <Text size="lg" fw={600}>
          {getString('analysisPackage', language)}
        </Text>
        <Stack data-test-id="package-info" className={styles.PackageInfo} gap="0.1rem">
          <>
            <Grid columns={36}>
              <Grid.Col span={12}>
                <Text fw={700}>{getString('listPrice', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>{getString('zones', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>$ / {getString('zone', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>{getString('total', language)}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text fw={700}>
                  {getString('per', language)} {acreage_unit}
                </Text>
              </Grid.Col>
            </Grid>
            {getOverviewRow(analysisOverview, showAnalysis, true)}
            {showAnalysis &&
              Object.keys(priceSummarySplit.analysis).map((product) => (
                <AnalysisRow
                  key={product}
                  product={product}
                  acreage={acreage}
                  priceSummary={newPriceSummary}
                />
              ))}
            {getOverviewRow(samplingOverview, showSampling, false)}
            {showSampling &&
              Object.keys(priceSummarySplit.sampling).map((product) => (
                <AnalysisRow
                  key={product}
                  product={product}
                  acreage={acreage}
                  priceSummary={newPriceSummary}
                />
              ))}
            {operationHasDiscountButUsedAll && (
              <div className={classNames(styles.FormRow, styles.HightlightDiscount)}>
                <div>{getString('noDiscount', language)}</div>
              </div>
            )}
            <PlanTotal priceSummary={newPriceSummary} acreage={acreage} />
          </>
        </Stack>
        <Text size="lg" fw={600}>
          {getString('sampleExecution', language)}
        </Text>
        <Stack align="center" gap="xs">
          {isSampleable && (
            <Group className={styles.Width90} justify="space-between">
              <Text>{getString(isViewOnly ? 'toBeSampledBy' : 'sampledBy', language)}</Text>
              <Text fw={700}>{getSamplerName()}</Text>
            </Group>
          )}
          {isProOrTillRx && (
            <Group className={styles.Width90} justify="space-between">
              <Text>{getString(isViewOnly ? 'toBeScannedBy' : 'scannedBy', language)}</Text>
              <Text fw={700}>{getScannerName()}</Text>
            </Group>
          )}
          <Group className={styles.Width90} justify="space-between">
            <Text>{getString('samplingTiming', language)}</Text>
            <Text fw={700}>{sampleTiming}</Text>
          </Group>
        </Stack>
        {isViewOnly ? (
          <Text size="sm" fs="italic">
            *{getString('summaryOfSamplePlanMsg', language)}
          </Text>
        ) : (
          <Stack align="center">
            <Group className={styles.Width90} justify="flex-start">
              {getString('planConfMsg', language)}
              <Checkbox
                data-test-id="ready-to-sample"
                checked={readyToSample}
                onChange={() =>
                  updatePlanState({
                    readyToSample: !readyToSample,
                  })
                }
                description={`${getString('markedReadyAssignedMsg', language)} ${sampleTiming}`}
                label={
                  <Text size="sm">
                    <b>{getString('readyToSample', language)}</b>
                  </Text>
                }
              />
            </Group>
            <Group justify="center">
              <Button
                data-test-id="confirm-order"
                onClick={confirm}
                disabled={!isOrderButtonDisabled}
                loading={!isOrderButtonDisabled}
              >
                {getString('confirmOrder', language)}
              </Button>
            </Group>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default Confirmation;
