import { AppThunk } from 'store';
import {
  receiveSamplingHistory,
  requestSamplingHistory,
  samplingHistoryRequestError,
} from './actions';
import { requestGetSamplingHistory } from './requests';

export const getSamplingHistory =
  (page: number = 1, year?: number, operationId?: number | null): AppThunk =>
  async (dispatch) => {
    dispatch(requestSamplingHistory());
    try {
      const response = await requestGetSamplingHistory(page, year, operationId);
      dispatch(receiveSamplingHistory(response));
    } catch (e) {
      dispatch(samplingHistoryRequestError({ message: e.message }));
    }
  };
