import React from 'react';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { BatchType } from 'store/batches/types';
import { SampleSheetForm } from './SampleSheetForm';

type SampleSheetPropsType = {
  batch: BatchType;
};

export const SampleSheet = ({ batch }: SampleSheetPropsType) => {
  const language = useBroswerLanguage();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="70%"
        padding="xl"
        title={getString('sampleSheet', language)}
      >
        <SampleSheetForm batch={batch} />
      </Modal>
      <Button variant="outline" onClick={open}>
        {getString('createSampleSheet', language)}
      </Button>
    </>
  );
};
