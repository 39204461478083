import React from 'react';
import { Stack, Group, Button, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

import { ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { useDispatch } from 'react-redux';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { FiArrowRight } from 'react-icons/fi';
import { SAMPLING_ZONES } from 'constants/samplePlanning';

const AnalysisAndSamplingPanel = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const updatePlanState = (metaKeyValue: ZoneAnalysisKeyType) =>
    dispatch(updateZoneAnalysis(metaKeyValue));

  return (
    <Stack>
      <Group justify="flex-end">
        <Text>{getString('chooseAnalysisMsg', language)}:</Text>
      </Group>
      <Group justify="flex-end">
        <Button
          onClick={() => updatePlanState({ accordionLocation: SAMPLING_ZONES })}
          rightSection={<FiArrowRight />}
        >
          {getString('nextStep', language)}: {getString('selectZones', language)}
        </Button>
      </Group>
    </Stack>
  );
};

export default AnalysisAndSamplingPanel;
