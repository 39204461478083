import React from 'react';

import { Box, Flex, Text } from '@mantine/core';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';

import { getString } from 'strings/translation';
import { FieldType } from 'store/fields/types';

import styles from './Container.module.css';
import { getUnitLbsAc } from 'util/units';

// currying function so prescription map function doesn't need to be aware of field/language
// in broader scope
export const getPopupContent = (field: FieldType, language: string) => {
  return function PopupContent(amount: number) {
    return (
      <Box className={styles.Popup}>
        <Flex align="center">
          <Text fw={FONT_WEIGHT_BOLD}>{getString('amount', language)}:</Text>
          <Text>{`${amount} ${getUnitLbsAc(field.features[0].properties.acreage_unit)}`}</Text>
        </Flex>
      </Box>
    );
  };
};
