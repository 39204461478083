import React, { FunctionComponent, forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Radio.module.css';

type RadioProps = {
  className?: string;
  onChange?: Function;
  value?: string;
  label?: string;
  checked?: boolean;
  name?: string;
  dataTestId?: string;
  disabled?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio: FunctionComponent<RadioProps> = forwardRef<HTMLInputElement, RadioProps>(
  ({ className, onChange, value, label, checked, name, dataTestId, ...props }, ref) => {
    return (
      <label className={classNames(styles.RadioOption, className)}>
        {label ? <span className={styles.RadioLabel}>{label}</span> : <>&nbsp;</>}
        <input
          ref={ref}
          type="radio"
          value={value}
          name={name}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <span className={styles.RadioButton} data-test-id={dataTestId} />
      </label>
    );
  },
);

Radio.displayName = 'Radio';
