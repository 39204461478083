import React, { useMemo } from 'react';
import { JobType, JOB_STATUSES, JOB_TYPES } from 'store/jobs/types';
import { EVENT_TYPES, FieldEventType } from 'store/fieldEvents/types';

type FileSummaryPropsType = {
  runData: {
    runFieldEvents: FieldEventType[];
    runJobs: JobType[];
  };
};

const FileSummary = ({ runData }: FileSummaryPropsType) => {
  const uploadJob = useMemo(
    () => runData.runJobs.find((job) => job.type === JOB_TYPES.UPLOADED_FILE),
    [runData.runJobs],
  );
  const numFailedJobs = useMemo(
    () => runData.runJobs.filter((job) => job.status === JOB_STATUSES.ERROR).length,
    [runData.runJobs],
  );

  const numHarvestEvents = useMemo(
    () => runData.runFieldEvents.filter((event) => event.event_type === EVENT_TYPES.HARVEST).length,
    [runData.runFieldEvents],
  );
  const numPlantingEvents = useMemo(
    () =>
      runData.runFieldEvents.filter((event) => event.event_type === EVENT_TYPES.PLANTING).length,
    [runData.runFieldEvents],
  );
  const numShapefileEvents = useMemo(
    () =>
      runData.runFieldEvents.filter((event) => event.event_type === EVENT_TYPES.SHAPEFILES).length,
    [runData.runFieldEvents],
  );
  const numApplicationEvents = useMemo(
    () =>
      runData.runFieldEvents.filter((event) => event.event_type === EVENT_TYPES.APPLICATION).length,
    [runData.runFieldEvents],
  );
  return (
    <div>
      <h2>Summary</h2>
      <span>
        {`There were ${runData.runFieldEvents.length} valid files / file groups and ${numFailedJobs} invalid files / file groups
        extracted from`}
        <b>{` ${uploadJob?.name}:`}</b>
      </span>
      <ul>
        <li>{`Field Boundaries: ${numShapefileEvents}`}</li>
        <li>{`Planting Files: ${numPlantingEvents}`}</li>
        <li>{`Application Files: ${numApplicationEvents}`}</li>
        <li>{`Harvest Files: ${numHarvestEvents}`}</li>
        <li>{`Unknown / Invalid Files: ${numFailedJobs}`}</li>
      </ul>
      <span>
        We mapped data columns and assigned the files new or existing fields based on spatial
        overlap. Please confirm of correct the data mapping and field assignment before completing
        ingest.
      </span>
    </div>
  );
};

export default FileSummary;
