import {
  AnalyticsActionType,
  AnalyticType,
  RECEIVE_ANALYTIC_CATEGORIES,
  RECEIVE_ANALYTICS,
  RECEIVE_SINGLE_ANALYTIC,
  ReceiveAnalyticsAction,
  ReceiveAnalyticCategoriesAction,
  REQUEST_ANALYTICS,
  REQUEST_ANALYTICS_ERROR,
  RECEIVE_ALL_ANALYTICS,
  RECEIVE_ANALYTIC_SORT_ORDER,
  ReceiveAnalyticSortOrderAction,
} from './types';

export const receiveAnalytics = (
  payload: ReceiveAnalyticsAction['payload'],
): AnalyticsActionType => ({
  type: RECEIVE_ANALYTICS,
  payload,
});

export const receiveAllAnalytics = (
  payload: ReceiveAnalyticsAction['payload'],
): AnalyticsActionType => ({
  type: RECEIVE_ALL_ANALYTICS,
  payload,
});

export const receiveAnalyticCategories = (
  payload: ReceiveAnalyticCategoriesAction['payload'],
): AnalyticsActionType => ({
  type: RECEIVE_ANALYTIC_CATEGORIES,
  payload,
});

export const receiveAnalyticSortOrder = (
  payload: ReceiveAnalyticSortOrderAction['payload'],
): AnalyticsActionType => ({
  type: RECEIVE_ANALYTIC_SORT_ORDER,
  payload,
});

export const receiveSingleAnalytic = (analytic: AnalyticType): AnalyticsActionType => ({
  type: RECEIVE_SINGLE_ANALYTIC,
  payload: {
    analytic,
  },
});

export const requestAnalytics = { type: REQUEST_ANALYTICS };

export const analyticsRequestError = { type: REQUEST_ANALYTICS_ERROR };
