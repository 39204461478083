import React from 'react';
import { Text, SegmentedControl, Group } from '@mantine/core';
import { getString } from 'strings/translation';
import { DataReviewGroupViewType } from 'store/eoCollections/types';

type Props = {
  currentValue: DataReviewGroupViewType;
  language: string;
  onChange: (value: DataReviewGroupViewType) => void;
};

const GroupDataSwitcher = ({ currentValue, language, onChange }: Props) => {
  return (
    <Group>
      <Text size="sm" fw={500}>
        Group by:
      </Text>
      <SegmentedControl
        value={currentValue}
        onChange={(val) => onChange(val as DataReviewGroupViewType)}
        data={[
          { value: 'grower', label: getString('grower', language) },
          { value: 'dealer', label: getString('dealer', language) },
        ]}
      />
    </Group>
  );
};

export default GroupDataSwitcher;
