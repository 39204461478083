import { Feature, FeatureCollection, LineString, MultiPolygon } from 'geojson';
import { SamplePlanType } from 'store/samplePlans/types';

export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS';
export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS';
export const COLLECTION_REQUEST_ERROR = 'COLLECTION_REQUEST_ERROR';

export type EOCollectionType = {
  adcp_file_exists: boolean;
  created_at: string;
  emi_file_exists: boolean;
  field_id: number;
  id: number;
  name: string;
  overlap_percentage: number;
  sampling_plan_id: number;
  sampling_plan_name: string;
  scanning_time_minutes: number;
  assigned_to_scan: null | {
    first_name: string;
    last_name: string;
  };
};

export type EOInferenceLayerType = {
  geojson_uri: string;
  id: number;
  layer_name: string;
  layer_type: string;
  layer_param: string;
  max_value: number | null;
  min_value: number | null;
};

export type EOTargetMeasurement = Pick<
  EOInferenceLayerType,
  'id' | 'layer_name' | 'layer_type' | 'geojson_uri'
>;

export type EOTargetMeasurementResponse = {
  target_measurements: EOTargetMeasurement[];
};

export type EOCollectionFeatureType = Feature<LineString, EOCollectionType>;
export type EOCollectionFieldType = {
  acreage: number;
  acreage_unit: string;
  farm_name: string;
  id: number;
  name: string;
  operation: {
    id: number;
    name: string;
  };
  agency: {
    id: number;
    name: string;
  } | null;
  pending_eo_collections: EOCollectionFeatureType[];
  sampling_plans: SamplePlanType[];
};
export type EOCollectionFieldFeatureType = FeatureCollection<MultiPolygon, EOCollectionFieldType>;

export type EOCollectionStateType = {
  fieldCollections: EOCollectionFieldFeatureType[];
  hasFetched: boolean;
  isFetching: boolean;
  hasFailed: boolean;
};

export type DataReviewGroupViewType = 'dealer' | 'grower';

export type DataReviewCollectionGroup = {
  fields: EOCollectionFieldFeatureType[];
  name: string;
  /** Grower(s) if grouped by dealer, and vice versa */
  secondaryNames: string[];
};

export type DataReviewGroupedCollections = {
  [operationOrAgencyId: number]: DataReviewCollectionGroup;
};

type ReceiveEOCollectionsActionType = {
  type: typeof RECEIVE_COLLECTIONS;
  payload: {
    fields: EOCollectionFieldFeatureType[];
  };
};

type RequestEOCollectionsActionType = {
  type: typeof REQUEST_COLLECTIONS;
};

type EOCollectionErrorActionType = {
  type: typeof COLLECTION_REQUEST_ERROR;
};

export type EOCollectionActionType =
  | ReceiveEOCollectionsActionType
  | RequestEOCollectionsActionType
  | EOCollectionErrorActionType;

export type EOCollectionsResponseType = {
  fields: EOCollectionFieldFeatureType[];
};

export type CollectionConfirmationPayloadType = {
  assignments: {
    [samplingPlanId: number]: number[];
  };
};

export type CollectionsResponseType = {
  fields: [];
  first: number;
  last: number;
  page: number;
  per_page: number;
  next_num: number | null;
};
