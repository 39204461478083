import React from 'react';
import { Stack, Text, Timeline } from '@mantine/core';
import { BsCartCheckFill } from 'react-icons/bs';
import { IoScan } from 'react-icons/io5';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import { FaCheck } from 'react-icons/fa';
import styles from './Compaction.module.css';

const getTranslatedText = (language: string) => ({
  title: getString('tillMapperIsLoading', language),
  ordered: getString('ordered', language),
  scanned: getString('scanned', language),
  scanDataConfirmed: getString('scanDataConfirmed', language),
  orderedDescrip: getString('tillMapperOrdered', language),
  scannedDescrip: getString('scanDataReceived', language),
  scanConfirmedDescrip: getString('scanTimeframe', language),
});

const TillMapperOrderStatus = () => {
  const language = useBroswerLanguage();
  const text = getTranslatedText(language);

  return (
    <Stack ta="center" py="xl" gap="xl" align="center">
      <Text component="h1" fw="bold" fz="h3">
        {text.title}
      </Text>
      <Timeline bulletSize={36} active={1} className={styles.Timeline}>
        <Timeline.Item title={text.ordered} fz="lg" bullet={<BsCartCheckFill />}>
          <Text c="gray.7" size="sm">
            {text.orderedDescrip}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={text.scanned} fz="lg" bullet={<IoScan />}>
          <Text c="gray.7" size="sm">
            {text.scannedDescrip}
          </Text>
        </Timeline.Item>
        <Timeline.Item title={text.scanDataConfirmed} fz="lg" bullet={<FaCheck />}>
          <Text c="gray.7" size="sm">
            {text.scanConfirmedDescrip}
          </Text>
        </Timeline.Item>
      </Timeline>
    </Stack>
  );
};

export default TillMapperOrderStatus;
