import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { IconInput } from 'common';
import { KEYS } from 'constants/keyCodes';

import { searchSample } from 'store/labSamples/thunks';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

type SampleSearchBarPropTypes = {
  className?: string;
  autofocus?: boolean;
  onSearch?: () => void;
};

const SampleSearchBar = ({ className, autofocus, onSearch }: SampleSearchBarPropTypes) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [searchBarcode, setSearchBarcode] = useState('');
  const { isFetching, byBarcode } = useSelector((state: RootState) => ({
    isFetching: state.labSamples.isFetching,
    byBarcode: state.labSamples.byBarcode,
  }));

  useEffect(() => {
    if (!isFetching && inputRef.current && autofocus) {
      inputRef.current.focus();
    }
  }, [isFetching, inputRef, autofocus]);

  useEffect(() => {
    const barCodeKeys = Object.keys(byBarcode);
    if (byBarcode[searchBarcode]?.length) {
      setInputValue('');
      navigate(`/lab/samples/${searchBarcode}`);
    } else if (barCodeKeys.length && byBarcode[barCodeKeys[0]][0].sample_uuid === searchBarcode) {
      setInputValue('');
      navigate(`/lab/samples/${barCodeKeys[0]}`);
    }
  }, [byBarcode, searchBarcode, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOnKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER && inputValue.length) {
      setSearchBarcode(inputValue);
      // NOTE: await d o e s work here
      const response: any = await dispatch(searchSample(inputValue));
      if (response.length && response[0].barcode !== inputValue) {
        // NOTE: Earth Optics qc code handling
        setSearchBarcode(response[0].barcode);
      }
      onSearch && onSearch();
    }
  };

  return (
    <IconInput
      ref={inputRef}
      disabled={isFetching}
      className={className}
      placeholder={`${getString('enterBarcodeMsg', language)}...`}
      icon="SearchIcon"
      value={inputValue}
      onChange={handleInputChange}
      onKeyUp={handleOnKeyUp}
    />
  );
};

export default SampleSearchBar;
