import React, { ReactNode } from 'react';
import { Container, Header } from 'common';
import { PlateType } from 'store/plates/types';
import { PlatesListContent } from './PlatesListContent';
import { BatchType } from 'store/batches/types';

type PlatesListPropsType = {
  plates?: PlateType[];
  title: string;
  batchOrder?: boolean;
  children?: ReactNode;
  batch?: BatchType;
};

export const PlatesList = ({ plates, title, batchOrder, children, batch }: PlatesListPropsType) => {
  return (
    <Container vertical>
      <Header title={title} underline="none" size="H2">
        {children}
      </Header>
      <PlatesListContent plates={plates} batchOrder={batchOrder} batch={batch} />
    </Container>
  );
};
