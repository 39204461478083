import React, { useState } from 'react';
import { TableRow, TableCol, CloseIcon, EditIcon } from 'common';
import { samplePricesWithRanges } from 'util/stringUtils';
import { SamplePrice } from 'store/samplePrices/types';
import { useDispatch } from 'react-redux';
import { deleteSamplePrice } from 'store/samplePrices/requests';
import getSamplePrices from 'store/samplePrices/thunks';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import styles from './Container.module.css';
import CancelModal from '../Orders/FieldList/FullProductReport/common/CancelModal';
import { type ToastType } from 'actions/toastActions';

type SamplePricingRowPropsType = {
  userPrice: SamplePrice;
  setToastMessage: (message: string, type?: ToastType, time?: number) => void;
  editUserPrice: (user_id: number) => void;
};

const SamplePricingRow = ({
  userPrice,
  setToastMessage,
  editUserPrice,
}: SamplePricingRowPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const deleteUserSamplePrice = async () => {
    try {
      setIsFetching(true);
      await deleteSamplePrice(userPrice.user.id);
      dispatch(getSamplePrices());
      setIsFetching(false);
    } catch (err) {
      setToastMessage('Error deleting user.', 'error');
      setIsFetching(false);
      toggleDeleteModal(false);
    }
  };

  return (
    <>
      <TableRow className={styles.AgencyHover}>
        <TableCol size="x2">{`${userPrice.user.first_name} ${userPrice.user.last_name}`}</TableCol>
        <TableCol size="x2">{userPrice.user.email}</TableCol>
        <TableCol size="x4">{samplePricesWithRanges(userPrice.pricing_ranges)}</TableCol>
        <TableCol size="x2" className={styles.EditCloseIcons}>
          <span onClick={() => toggleDeleteModal(true)} className={styles.MarginLeft}>
            <CloseIcon />
          </span>
          <span onClick={() => editUserPrice(userPrice.user.id)} className={styles.MarginLeft}>
            <EditIcon />
          </span>
        </TableCol>
      </TableRow>
      <CancelModal
        cancelTitle={getString('delete', language)}
        cancel={deleteUserSamplePrice}
        cancelText="Are you sure you want to delete this user's sample price?"
        isFetching={isFetching}
        hideModal={() => toggleDeleteModal(false)}
        opened={showDeleteModal}
      />
    </>
  );
};

export default SamplePricingRow;
