import { SingleAnalyticType } from 'store/analytics/types';
import { SamplePlanType } from 'store/samplePlans/types';

export type HeatMapType = {
  id: number;
  created_at: string;
  updated_at: string;
  status: string;
  crop_year: number;
  nutrient_opanel_analyze_om?: boolean;
  field_id: number;
  field_name: string;
  farm_name: string;
  field_centroid: { type: string; coordinates: number[] };
  calculated_area_acres: number;
  operation_id: number;
  operation_name: string;
  analytics: SamplePlanType['analytics'];
};

export type HeatMapAnalyticType = HeatMapType &
  SingleAnalyticType & {
    analytic_id: number;
  };

export type HeatMapStateType = {
  heatMap: HeatMapAnalyticType[];
  byAgencyId: {
    [agency_id: string]: HeatMapAnalyticType[];
  };
  isFetching: boolean;
  hasFetched: boolean;
  hasFailed: boolean;
};

export const REQUEST_HEAT_MAP = 'REQUEST_HEAT_MAP';
export const RECEIVE_HEAT_MAP = 'RECEIVE_HEAT_MAP';
export const RECEIVE_AGENCY_HEAT_MAP = 'RECEIVE_AGENCY_HEAT_MAP';
export const HEAT_MAP_REQUEST_ERROR = 'HEAT_MAP_REQUEST_ERROR';

type RequestHeatMapActionType = {
  type: typeof REQUEST_HEAT_MAP;
};

type ReceiveAgencyHeatMapActionType = {
  type: typeof RECEIVE_AGENCY_HEAT_MAP;
  payload: {
    agencyId: number;
    heatMap: HeatMapType[];
  };
};

type ReceiveHeatMapActionType = {
  type: typeof RECEIVE_HEAT_MAP;
  payload: {
    heatMap: HeatMapType[];
  };
};

type HeatMapRequestErrorType = {
  type: typeof HEAT_MAP_REQUEST_ERROR;
};

export type HeatMapActionsType =
  | RequestHeatMapActionType
  | ReceiveHeatMapActionType
  | ReceiveAgencyHeatMapActionType
  | HeatMapRequestErrorType;
