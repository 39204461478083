import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import turfArea from '@turf/area';
import {
  featureCollection,
  feature,
  FeatureCollection,
  Properties,
  MultiPolygon,
} from '@turf/helpers';
import { Header, Spinner } from 'common';
import { Button, Input, Group, Text, Space, Title } from '@mantine/core';
import { getOperation, setActiveOperation, updateField } from 'store/operation/thunks';
import { getFieldGeometry, updateFieldGeometry, getFieldGeometries } from 'store/fields/thunks';
import { MODES } from 'constants/mapbox';
import showToast from 'actions/toastActions';
import { BRAZIL_VIEWPORT, US_MIDWEST_VIEWPORT } from 'constants/mapViewport';
import { RootState } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { GeoJsonProperties, Geometry } from 'geojson';
import { postField } from 'store/operation/requests';
import { operationRequestError, receiveSingleField } from 'store/operation/actions';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getFieldArea, squareMetersToAcres } from 'util/geospatial';
import { ENGLISH } from 'constants/languages';
import styles from './Container.module.css';
import FieldBoundaryEditor from './FieldBoundaryEditor';
import { requestClusResults } from 'store/fields/requests';
import {
  CLU_LATITUDE_BUFFER,
  CLU_LONGITUTE_BUFFER,
  CLU_ZOOM_BUFFER,
  CLU_ZOOM_THRESHOLD,
} from 'constants/clus';

const FieldBoundaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const { fieldId, operationId } = useParams();
  const numericFieldId = Number(fieldId);
  const isNewField = fieldId === 'new';
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [fieldName, setFieldName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState(MODES.SELECT);
  const [viewport, setViewport] = useState(US_MIDWEST_VIEWPORT);
  const drawRef = useRef<any>(null);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const numericOperationId = Number(operationId);
  const [selectedClus, setSelectedClus] = useState<number[]>([]);
  const [clus, setClus] = useState<FeatureCollection<MultiPolygon, GeoJsonProperties> | null>(null);
  const [loadingClus, setLoadingClus] = useState<boolean>(false);
  const [mergeSplitType, setMergeSplitType] = useState<string | null>(null);

  const { operation, allFieldGeometries, fieldGeometry, hasFailedList, hasFetchedList, user } =
    useSelector((state: RootState) => ({
      operation: state.operations.operationsById[numericOperationId],
      allFieldGeometries: state.fieldGeometry.geometries,
      fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
      isFetchingList: state.fieldGeometry.isFetchingList,
      hasFetchedList: state.fieldGeometry.hasFetchedList,
      hasFailedList: state.fieldGeometry.hasFailedList,
      user: state.user.currentUser,
    }));

  const fetchClus = useCallback(async () => {
    if (
      !clus &&
      mode === MODES.SELECT &&
      viewport.zoom >= CLU_ZOOM_THRESHOLD &&
      !selectedClus.length &&
      !loadingClus
    ) {
      try {
        setLoadingClus(true);
        const { latitude, longitude } = viewport;
        const cluParams = [
          longitude - CLU_ZOOM_BUFFER / (CLU_LONGITUTE_BUFFER * viewport.zoom),
          longitude + CLU_ZOOM_BUFFER / (CLU_LONGITUTE_BUFFER * viewport.zoom),
          latitude - CLU_ZOOM_BUFFER / (CLU_LATITUDE_BUFFER * viewport.zoom),
          latitude + CLU_ZOOM_BUFFER / (CLU_LATITUDE_BUFFER * viewport.zoom),
        ];

        const response = await requestClusResults(
          cluParams[0],
          cluParams[1],
          cluParams[2],
          cluParams[3],
        );
        if (response) {
          setClus(response);
        }
      } catch (error) {
        showToast(getString('failedToLoadClusMsg', language), 'error');
      }
      setLoadingClus(false);
    }
  }, [viewport, mode, clus, viewport, loadingClus]);

  useEffect(() => {
    fetchClus();
  }, [fetchClus, numericFieldId, isNewField]);

  const reloadClus = () => {
    setClus(null);
    setSelectedClus([]);
    fetchClus();
  };

  useEffect(() => {
    if (!fieldGeometry && !isNewField) {
      dispatch(getFieldGeometry(numericFieldId));
    }
  }, [dispatch, fieldGeometry, numericFieldId, isNewField]);

  useEffect(() => {
    if (user) {
      const countryViewport = user.user_locale === ENGLISH ? US_MIDWEST_VIEWPORT : BRAZIL_VIEWPORT;
      setViewport(countryViewport);
    }
  }, [user]);

  useEffect(() => {
    if (fieldGeometry) {
      dispatch(setActiveOperation(fieldGeometry.features[0].properties.operation_id));
      const { properties } = fieldGeometry.features[0];
      setFieldName(properties.name);
    }
  }, [dispatch, fieldGeometry]);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
    }
  }, [dispatch, operation, numericOperationId]);

  useEffect(() => {
    if (operation && isNewField) {
      const newFieldIds = operation.fields.map((field) => field.id);
      const alreadyExistIds = Object.keys(allFieldGeometries).map((str) => parseInt(str, 10));
      const neededFieldIds = newFieldIds.filter((nfid) => !alreadyExistIds.includes(nfid));
      if (neededFieldIds.length) {
        dispatch(getFieldGeometries(neededFieldIds));
      }
    }
  }, [dispatch, operation, isNewField, allFieldGeometries]);

  // Fix loading flash, load page correctly
  useEffect(() => {
    const fieldIds = operation?.fields.map((field) => field.id);
    const fetchedOrFailedList = [...hasFetchedList, ...hasFailedList];
    if (isNewField && fieldIds?.every((id) => fetchedOrFailedList.includes(id))) {
      setIsLoadingPage(false);
    }
    if (!isNewField) {
      setIsLoadingPage(false);
    }
  }, [isNewField, numericFieldId, hasFetchedList, hasFailedList, operation]);

  useEffect(() => {
    if (mergeSplitType) {
      setFieldName('');
    }
  }, [mergeSplitType]);

  const cluPolygons = useMemo(() => {
    if (clus) {
      return clus.features.map((f, i) => {
        f.id = i;
        if (f.properties) {
          f.properties.id = i;
        }
        return f;
      });
    }
    return [];
  }, [clus]);

  const selectedCluPolygons = useMemo(() => {
    if (cluPolygons) {
      return featureCollection(cluPolygons.filter((f) => selectedClus.includes(f.properties?.id)));
    }
    return featureCollection([]);
  }, [selectedClus]);

  const submit = async () => {
    if (drawRef.current) {
      try {
        setIsSubmitting(true);
        const polygons: FeatureCollection<Geometry, GeoJsonProperties> | undefined =
          drawRef.current?.getAll();

        if (polygons?.features) {
          const acres = squareMetersToAcres(turfArea(polygons));
          if (acres < 2.1) {
            setIsSubmitting(false);
            return showToast(getString('supportUnderTwoAcres', language), 'error');
          }
          polygons.features[0].properties = {
            name: fieldName,
            operation_id: operation.id,
          };

          if (isNewField) {
            const response = await postField(polygons);
            dispatch(receiveSingleField(response));
            const { id } = response.features[0].properties;
            dispatch(updateFieldGeometry(polygons, id));
          } else {
            dispatch(
              updateField(featureCollection([feature(null, { name: fieldName })]), numericFieldId),
            );
            dispatch(updateFieldGeometry(polygons, numericFieldId));
          }
        }
        dispatch(getOperation(operation.id));
        navigate(`/orders/${operation.id}`);
      } catch (err) {
        dispatch(operationRequestError);
        setIsSubmitting(false);
        showToast(getString('fieldAlreadyExists', language), 'error');
      }
    }
  };

  const numPolygons = drawRef.current?.getAll().features.length || 0;

  if (isLoadingPage) {
    return <Spinner fill />;
  }

  const fieldNameInput = (
    <Input
      className={styles.FieldNameInput}
      onChange={(e) => setFieldName(e.currentTarget.value)}
      placeholder={`${getString('fieldName', language)}...`}
      value={fieldName}
      disabled={!!mergeSplitType}
    />
  );

  // using selectedCluPolygons here allows us to display the acreage before adding the clus to the editor
  const fieldArea = selectedCluPolygons.features.length
    ? getFieldArea(selectedCluPolygons as FeatureCollection<Geometry, Properties>, language)
    : getFieldArea(drawRef.current?.getAll(), language);
  const operationName = operation ? `${operation.name} - ` : '';
  const title = `${operationName}${
    isNewField ? getString('create', language) : getString('edit', language)
  } ${getString('field', language)}`;

  return (
    <>
      {isSubmitting && (
        <div className={styles.LoadingOverlay}>
          <Spinner className={styles.Spinner} fill />{' '}
        </div>
      )}
      <Title order={2} className={styles.TitleText}>
        {title}
      </Title>
      <Space h="md" />
      <Header className={styles.Header} title={fieldNameInput}>
        <Group justify="space-between">
          <Text fw={500}>{getString('acres', language)}:</Text> <Text>{fieldArea.toFixed(2)}</Text>
          <Button data-test-id="save" disabled={!fieldName || numPolygons < 1} onClick={submit}>
            {getString('save', language)}
          </Button>
          <Button data-test-id="cancel" color="darkRed" onClick={() => navigate(-1)}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </Header>
      <FieldBoundaryEditor
        drawRef={drawRef}
        reloadClus={reloadClus}
        loadingClus={loadingClus}
        cluPolygons={cluPolygons}
        selectedClusPolygons={selectedCluPolygons}
        selectedClus={selectedClus}
        setSelectedClus={setSelectedClus}
        fieldGeometry={fieldGeometry}
        allFieldGeometries={allFieldGeometries}
        isNewField={isNewField}
        mode={mode}
        setMode={setMode}
        setViewport={setViewport}
        viewport={viewport}
        fieldIds={operation?.fields.map((field) => field.id)}
        operation={operation}
        mapRef={mapRef}
        mapContainerRef={mapContainerRef}
        user={user}
        mergeSplitType={mergeSplitType}
        setMergeSplitType={setMergeSplitType}
      />
    </>
  );
};

export default FieldBoundaryContainer;
