import React, { useState, useEffect } from 'react';

import { Text, Input, Group, Select } from '@mantine/core';
import { Container, Table, TableCol, TableRow, Typeahead } from 'common';
import { TaxonomyInterface } from 'util/generalTypes';
import { taxonomicRanks } from 'constants/taxonomies';
import { getTaxonomy, requestAddTaxonomyToAnalytics } from 'store/analytics/requests';
import { NOT_APPLICABLE } from 'constants/defaultValues';

import styles from '../Container.module.css';
import { AnalyticType } from 'store/analytics/types';

const taxRanksList = Object.values(taxonomicRanks);

type TaxonomiesEditorPropsType = {
  analytic: AnalyticType;
  onError: (errorMessage: string) => void;
  onSuccess: (message: string) => void;
  setAttribute: (name: string, taxIds: TaxonomyInterface['taxid'][]) => void;
};

const TaxonomiesEditor = ({
  analytic,
  onError,
  onSuccess,
  setAttribute,
}: TaxonomiesEditorPropsType) => {
  const [isFetching, toggleFetching] = useState(false);
  const [searchResult, setSearchResult] =
    useState<Array<TaxonomyInterface & { displayName: string }>>();
  const [taxonomies, setTaxonomies] = useState(analytic?.taxonomies || []);
  const [newTaxIds, setNewTaxIds] = useState([] as TaxonomyInterface['taxid'][]);
  const [taxRank, setTaxRank] = useState(taxRanksList[7]);

  useEffect(() => {
    setAttribute('internal_taxids', newTaxIds);
  }, [newTaxIds, setAttribute]);

  const handleSearch = async (idx: number) => {
    const newTaxonomy = searchResult ? searchResult[idx] : ({} as TaxonomyInterface);

    if (analytic.id) {
      await requestAddTaxonomyToAnalytics(analytic.id, [newTaxonomy.internal_taxid]);
    } else {
      setNewTaxIds([newTaxonomy.internal_taxid, ...newTaxIds]);
    }
    // @ts-ignore
    setTaxonomies([newTaxonomy, ...taxonomies]);
    onSuccess(`Taxonomy ${newTaxonomy.name} with id ${newTaxonomy.internal_taxid} added`);
    setSearchResult(undefined);
  };

  const handleTaxonomySearch = async (value: string) => {
    try {
      setSearchResult(undefined);
      toggleFetching(true);
      const results = await getTaxonomy(value, taxRank);
      const formattedResults = results.items
        .filter((tx) => tx.internal_taxid)
        .map((tax) => ({
          ...tax,
          displayName: tax.name || (tax[taxRank as keyof TaxonomyInterface] as string) || '',
        }));
      setSearchResult(formattedResults);
      toggleFetching(false);
    } catch (e) {
      onError(`Error getting taxonomy ${(e as Error).message}`);
    }
  };

  return (
    <>
      <Text size="lg" fw={500}>
        Taxonomy IDs
      </Text>
      <Container>
        <Group align="flex-end">
          <Input.Wrapper label="Tax ID">
            <Select
              value={taxRank}
              data={taxRanksList.map((rank, idx) => ({
                id: idx,
                label: rank,
                value: rank,
              }))}
              onChange={(val) => {
                val && setTaxRank(val);
                setSearchResult(undefined);
              }}
            />
          </Input.Wrapper>
          <Typeahead
            options={searchResult || []}
            onSelect={handleSearch}
            placeholder={isFetching ? 'Searching...' : 'Type taxonomy name'}
            onNewOption={handleTaxonomySearch}
            icon="SearchIcon"
          />
        </Group>
        <Table className={styles.Table}>
          <TableRow className={styles.TableRow} header>
            <TableCol className={styles.TableCol}>Name</TableCol>
            <TableCol className={styles.TableCol}>Taxonomic Rank</TableCol>
            <TableCol className={styles.TableCol} size="x4">
              Internal Tax ID
            </TableCol>
            <TableCol shrink />
          </TableRow>
          {taxonomies &&
            taxonomies.map(({ name, internal_taxid, rank }) => (
              <TableRow key={internal_taxid} className={styles.TableRow}>
                <TableCol className={styles.TableCol}>{name || NOT_APPLICABLE}</TableCol>
                <TableCol className={styles.TableCol}>{rank}</TableCol>
                <TableCol className={styles.TableCol} size="x4">
                  {internal_taxid}
                </TableCol>
                {/* TODO add remove buttons */}
                <TableCol shrink />
              </TableRow>
            ))}
        </Table>
      </Container>
    </>
  );
};

export default TaxonomiesEditor;
