import { US } from './countries';
import { AC, HA } from './languages';
import {
  DISCOUNT_SAMPLING_FEE,
  PRO_MAP_GENERATION,
  PRO_SENSOR_SCAN,
  SAMPLING_FEE_KEY,
  TILLAGE_MAP_GENERATION,
  TILLAGE_MEASUREMENT,
} from './products';
import { ANALYSIS_590_STATES } from './states';

export const PLAN_TRACKING_FILTERS = 'PLAN_TRACKING_FILTERS';
export const FIELDS = 'fields';

export const LOCKED_IN_ACRES = 'locked_in_acres';

export const CREATED = 'created';
export const UNASSIGNED = 'unassigned';
export const UNASSIGNED_PATTERN = 'unassigned_pattern';
export const UNASSIGNED_EO = 'unassigned_eo';

export const WAITING_TO_SAMPLE = 'waiting to sample';
export const READY = 'ready';
export const SAMPLED = 'sampled';
export const RECEIVED = 'received';
export const PARTIAL = 'partial';
export const COMPLETED = 'completed';
export const CANCELLED = 'cancelled';
export const PARTIALLY_SAMPLED = 'partially sampled';
export const PARTIALLY_SHIPPED = 'partially shipped';
export const SHIPPED = 'shipped';
export const PARTIALLY_RECEIVED = 'partially received';

export const SAMPLE_PLAN_DETAILS = 'details';
export const SAMPLING_ZONES = 'zones';
export const ASSIGN_SAMPLE_PLAN = 'assignment';
export const CONFIRM_ANALYSIS = 'analysis';

export const SSURGO_ZONES = 'ssurgo';
export const DEM_ZONES = 'dem';
export const GRID_ZONES = 'grid_zones';
export const GRID_POINTS = 'grid_points';
export const UPLOAD_ZONES = 'upload_zones';
export const CUSTOM_POINTS = 'custom_points';

export const POINT_BASED = [GRID_POINTS, CUSTOM_POINTS];

export const OPERATIONS_TABLE_LIST = [WAITING_TO_SAMPLE, READY, SAMPLED, PARTIAL];

export const PLAN_SUMMARY_SAMPLING_KEYS = [
  SAMPLING_FEE_KEY,
  DISCOUNT_SAMPLING_FEE,
  PRO_SENSOR_SCAN,
  TILLAGE_MEASUREMENT,
];

export const PER_ACRE_SAMPLING_KEYS = [
  PRO_SENSOR_SCAN,
  TILLAGE_MEASUREMENT,
  PRO_MAP_GENERATION,
  TILLAGE_MAP_GENERATION,
];

export const PARTIAL_STATUSES = [
  CREATED,
  PARTIALLY_SAMPLED,
  PARTIALLY_SHIPPED,
  PARTIALLY_RECEIVED,
  PARTIAL,
];

export const SPLIT_DENSITY = 'split_density';
export const ZONE_BY_ZONE = 'zone_by_zone';

export const SAMPLER_INSTRUCTIONS = 'sampler_instructions';
export const POINT_RADIUS = 20;
export const ACRE_SIZE_AT_POINT_RADIUS = 0.32;

export const DISPLAY_POINT_RADIUS = 40;
export const FIELD_DISPLAY_POINT_RADIUS = 30;
export const ADDITIONAL = 'additional';

export const DEFAULT_PRO_BIO_DENSITY = 20;
export const DEFAULT_PRO_NUTRIENT_DENSITY = 5;

export const zoneCreationOptions = [
  {
    id: 2,
    displayName: 'Soil Type (SSURGO)',
    value: SSURGO_ZONES,
  },
  // {
  //   id: 3,
  //   displayName: 'Elevation Map',
  //   value: DEM_ZONES,
  // },
  {
    id: 4,
    displayName: 'Grid Zones',
    value: GRID_ZONES,
  },
  {
    id: 5,
    displayName: 'Grid Points',
    value: GRID_POINTS,
  },
  {
    id: 6,
    displayName: 'Custom Points',
    value: CUSTOM_POINTS,
  },
  {
    id: 1,
    displayName: 'Upload Zones',
    value: UPLOAD_ZONES,
  },
];

export const DEFAULT_GRID_SIZE = 10;

export const densityOptions = {
  [AC]: [
    {
      id: 2,
      displayName: 1,
      label: 1,
      value: '1',
    },
    {
      id: 3,
      displayName: 2.5,
      label: 2.5,
      value: '2.5',
    },
    {
      id: 4,
      displayName: 4.4,
      label: 4.4,
      value: '4.4',
    },
    {
      id: 5,
      displayName: 5,
      label: 5,
      value: '5',
    },
    {
      id: 1,
      displayName: DEFAULT_GRID_SIZE,
      label: DEFAULT_GRID_SIZE,
      value: String(DEFAULT_GRID_SIZE),
    },
    {
      id: 6,
      displayName: 20,
      label: 20,
      value: '20',
    },
  ],
  [HA]: [
    {
      id: 1,
      displayName: 1,
      label: 1,
      value: '1',
    },
    {
      id: 2,
      displayName: 2,
      label: 2,
      value: '2',
    },
    {
      id: 3,
      displayName: 3,
      label: 3,
      value: '3',
    },
    {
      id: 4,
      displayName: 4,
      label: 4,
      value: '4',
    },
    {
      id: 5,
      displayName: 5,
      label: 5,
      value: '5',
    },
    {
      id: 6,
      displayName: 10,
      label: 10,
      value: '10',
    },
  ],
};

export const readyToSampleOptions = [
  {
    id: 1,
    displayName: 'Yes',
    value: true,
  },
  {
    id: 2,
    displayName: 'No',
    value: false,
  },
];

export const splitDensities = [1 / 2, 1 / 3, 1 / 4, 1 / 8];

export const SPLIT_DENSITY_MAX = 30; // acres - max CB acreage

// No cancelled status
export const ALL_STATUSES = 'all_statuses';
export const samplePlanStatuses = [
  { displayName: ALL_STATUSES, id: 0, value: ALL_STATUSES },
  { displayName: CREATED, id: 1, value: CREATED },
  { displayName: PARTIALLY_SAMPLED, id: 2, value: PARTIALLY_SAMPLED },
  { displayName: SAMPLED, id: 3, value: SAMPLED },
  { displayName: PARTIALLY_SHIPPED, id: 4, value: PARTIALLY_SHIPPED },
  { displayName: SHIPPED, id: 5, value: SHIPPED },
  { displayName: PARTIALLY_RECEIVED, id: 6, value: PARTIALLY_RECEIVED },
  { displayName: RECEIVED, id: 7, value: RECEIVED },
  { displayName: PARTIAL, id: 8, value: PARTIAL },
  { displayName: COMPLETED, id: 9, value: COMPLETED },
];

export const ACCEPTED = 'accepted';
export const DECLINED = 'declined';
export const NOT_READY = 'not_ready';

export const ALL_ASSIGNMENTS = 'all_assignments';
export const ALL_READINESS = 'all_readiness';

export const CRW = 'crw';
export const RKN = 'rkn';

export const SAMPLE = 'sample';
export const SAMPLE_SCAN = 'sample_scan';
export const SCAN = 'scan';

export const SAMPLES_NOT_COMPLETE = 'samples_not_complete';
export const EMI_NEEDED_NOT_COMPLETE = 'emi_needed_not_complete';
export const ADCP_NEEDED_NOT_COMPLETE = 'adcp_needed_not_complete';

export const is590Capable = (country_code: string, state: string) =>
  country_code === US && ANALYSIS_590_STATES.includes(state);
