import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Group, TextInput } from '@mantine/core';
import { FiSearch } from 'react-icons/fi';
import { useDisclosure } from '@mantine/hooks';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Header } from 'common';
import { RootState } from 'store';
import { getAllAgencies, getMyAgencies } from 'store/agencies/thunks';
import { Agency } from 'store/agencies/types';
import sortByName from 'util/sortByName';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';
import CreateAgencyModal from 'common/Modals/CreateAgencyModal';
import { LOCATION } from 'constants/agency';
import AgenciesTable from './AgenciesTable';

const ManageAgenciesDashboardContainer = () => {
  const language = useBroswerLanguage();
  const searchRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState('');
  const dispatch = useDispatch();

  const [addAgencyDialongOpened, addAgencyHandlers] = useDisclosure(false);
  const [onlyMyAgencies, setOnlyMyagencies] = useState(false);

  useEffect(() => {
    dispatch(getAllAgencies());
    dispatch(getMyAgencies());
  }, []);

  const { allAgencies, myAgencies, isAdmin } = useSelector((state: RootState) => ({
    allAgencies: state.agencies.allAgencies,
    myAgencies: state.agencies.myAgencies,
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  const agencies = onlyMyAgencies ? myAgencies : allAgencies;

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const getFilteredAgencies = () => {
    const lowerCaseFilter = filter.toLowerCase();

    return agencies.filter(
      (agency) =>
        agency.hierarchy !== LOCATION && agency.name.toLowerCase().includes(lowerCaseFilter),
    );
  };

  const sortAgencyList = (agencyList: Agency[]) => sortByName(agencyList);

  const getAgencyList = () => {
    const filteredAgencies = getFilteredAgencies();
    return sortAgencyList(filteredAgencies);
  };

  return (
    <>
      <CreateAgencyModal
        open={addAgencyDialongOpened}
        onClose={() => addAgencyHandlers.close()}
        isParentAgency={false}
        agency={null}
        isCreateOnly
      />
      <Header title={getString('manageAgencies', language)}>
        <div>
          <Group>
            {isAdmin && (
              <Button onClick={() => addAgencyHandlers.open()} data-test-id="add-agency-button">
                {getString('addAgency', language)}
              </Button>
            )}
            <Button
              data-test-id="my-agencies-button"
              onClick={() => setOnlyMyagencies(!onlyMyAgencies)}
              variant={onlyMyAgencies ? undefined : 'outline'}
            >
              {getString('myAgencies', language)}
            </Button>
            <TextInput
              leftSectionPointerEvents="none"
              leftSection={<FiSearch />}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={getString('searchAgency', language)}
              data-test-id="agency-search-input"
            />
          </Group>
        </div>
      </Header>
      <AgenciesTable agencies={getAgencyList()} />
    </>
  );
};

export default ManageAgenciesDashboardContainer;
