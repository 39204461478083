import {
  CLEAR_ZONE_ANALYSIS_V2,
  ReceiveExternalNutrientLabs,
  RequestExternalNutrientLabs,
  RECEIVE_EXTERNAL_NUTRIENT_LABS,
  REQUEST_EXTERNAL_NUTRIENT_LABS,
  UPDATE_ZONE_ANALYSIS_V2,
  ZoneAnalysisActionsType,
  ZoneAnalysisKeyType,
} from './types';

export const updateZoneAnalysis = (
  metaKeyValues: ZoneAnalysisKeyType,
): ZoneAnalysisActionsType => ({
  type: UPDATE_ZONE_ANALYSIS_V2,
  payload: { metaKeyValues },
});

export const clearZoneAnalysisState = (): ZoneAnalysisActionsType => ({
  type: CLEAR_ZONE_ANALYSIS_V2,
});

export const requestExternalNutrientLabs = (): RequestExternalNutrientLabs => ({
  type: REQUEST_EXTERNAL_NUTRIENT_LABS,
});

export const receiveExternalNutrientLabs = (payload): ReceiveExternalNutrientLabs => ({
  type: RECEIVE_EXTERNAL_NUTRIENT_LABS,
  payload,
});
