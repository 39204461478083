import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Table, TableRow, TableCol } from 'common';
import { PlateType, WellType } from 'store/plates/types';
import createEmptyWell from 'constants/emptyWell';
import { generateWellsIndex, getWellRowColLists } from 'util/plates';
import { WellDialogContainer as WellDialog } from './Dialog/Container';

type WellTablePropsType = {
  plate: PlateType;
  quadrant?: number;
};

const WellTable = ({ plate, quadrant }: WellTablePropsType) => {
  const language = useBroswerLanguage();
  const [selectedWell, setSelectedWell] = useState<WellType>();
  const [showDialog, setShowDialog] = useState(false);

  const wellLookupMap = generateWellsIndex(plate.wells, quadrant);
  const { rows, columns } = getWellRowColLists(plate.plate_type);

  const handleAddNewWell = (row: string, column: string) => {
    setSelectedWell(createEmptyWell(row, column, plate.barcode, quadrant));
    setShowDialog(true);
  };

  const table = rows.flatMap((r) =>
    columns.map((c) => {
      const data = (wellLookupMap[r] && wellLookupMap[r][c]) || null;
      return (
        <TableRow dataTestId="row-1" key={`${r}${c}`}>
          <TableCol>
            {r}
            {c}
          </TableCol>
          {data && <TableCol>{data.operation_name}</TableCol>}
          {data && <TableCol>{data.field_name}</TableCol>}
          {data && (
            <TableCol>
              {data.sample_barcode && (
                <Link data-test-id="well-row-1" to={`/lab/samples/${data.sample_barcode}`}>
                  {data.sample_barcode}
                </Link>
              )}
            </TableCol>
          )}
          {data && (
            <TableCol>
              {data.sample_barcode && (
                <Link to={`/lab/samples/${data.sample_barcode}`}>{data.sample_uuid}</Link>
              )}
            </TableCol>
          )}
          {!data && (
            <TableCol size="x4">
              <Button dataTestId="add-sample" onClick={() => handleAddNewWell(r, c)}>
                {getString('addSample', language)}
              </Button>
            </TableCol>
          )}
        </TableRow>
      );
    }),
  );

  return (
    <>
      <Table>
        <TableRow header>
          <TableCol>{`${getString('well', language)} ID`}</TableCol>
          <TableCol>{getString('operation', language)}</TableCol>
          <TableCol>{getString('field', language)}</TableCol>
          <TableCol>{getString('sampleBarcode', language)}</TableCol>
          <TableCol>{`${getString('sample', language)} UUID`}</TableCol>
        </TableRow>
        {table}
      </Table>
      {showDialog && selectedWell && (
        <WellDialog well={selectedWell} plate={plate} onClose={() => setShowDialog(false)} />
      )}
    </>
  );
};

export default WellTable;
