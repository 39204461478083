import React from 'react';
import { useElementSize } from '@mantine/hooks';
import { Group } from '@mantine/core';
import { AnalyticType } from 'store/analytics/types';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import { getHeatMapForAnalytic, getStatsForHeatMap } from 'util/heatMap';
import { AnalyticButton } from './AnalyticButton';

type AnalyticsRowPropsType = {
  activeAnalytic: AnalyticType;
  setActiveAnalytic: (a: AnalyticType) => void;
  analytics: AnalyticType[];
  heatMap: HeatMapAnalyticType[];
};

export const AnalyticsRow = ({
  activeAnalytic,
  setActiveAnalytic,
  analytics,
  heatMap,
}: AnalyticsRowPropsType) => {
  const { ref, width } = useElementSize();
  const gapWidthRem = 1;
  const chartMaxWidth = 10;

  const getSquareSize = () => {
    if (width) {
      const totalWidthRem = width;
      const totalGapWidth = gapWidthRem * 16 * (analytics.length - 1);
      const totalChartWidth = totalWidthRem - totalGapWidth;
      const singleChartWidth = totalChartWidth / analytics.length;
      return Math.min(singleChartWidth, chartMaxWidth * 16);
    }
    return 0;
  };

  const squareWidthPx = getSquareSize();

  return (
    <Group align="flex-start" wrap="nowrap" gap={`${gapWidthRem}rem`} ref={ref}>
      {activeAnalytic &&
        analytics?.map((a) => {
          const analyticHeatMap = getHeatMapForAnalytic(heatMap, a.id);
          const { modHighRiskPercent } = getStatsForHeatMap(analyticHeatMap, a.id);
          return (
            <AnalyticButton
              key={a.id}
              name={a.name}
              percent={modHighRiskPercent}
              setAnalytic={() => setActiveAnalytic(a)}
              isSelected={a.id === activeAnalytic.id}
              pxWidth={squareWidthPx}
            />
          );
        })}
    </Group>
  );
};
