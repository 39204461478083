import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Combobox,
  ComboboxChevron,
  Loader,
  TextInput,
  TextInputProps,
  useCombobox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { getAllCatalogs } from 'store/catalogs/thunks';
import { getString } from 'strings/translation';
import { CatalogType } from 'store/catalogs/types';
import { CreateCatalogModal } from './CreateCatalogModal';
import { OperationCatalogModal } from './OperationCatalogModal';

type OperationCatalogPropsType = TextInputProps & {
  catalog: CatalogType | undefined;
  operationId: number;
  createNew?: boolean;
  className?: string;
};

export const OperationCatalog = ({
  catalog,
  operationId,
  createNew,
  className,
  ...props
}: OperationCatalogPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<CatalogType | undefined>(catalog);
  const [opened, { open, close }] = useDisclosure();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { catalogs, isFetching } = useSelector((state: RootState) => ({
    catalogs: state.catalogs.all,
    hasFailed: state.catalogs.hasFailed,
    hasFetched: state.catalogs.hasFetched,
    isFetching: state.catalogs.isFetching,
  }));

  useEffect(() => {
    dispatch(getAllCatalogs(operationId, true));
  }, [operationId]);

  useEffect(() => {
    if (catalog) {
      setSelected(catalog);
    }
  }, [catalog]);

  const handleSelect = (value: string) => {
    const optionId = parseInt(value, 10);
    if (optionId === 0) {
      open();
      setSelected(undefined);
    } else {
      const foundCatalog = catalogs.find((c) => c.id === optionId);
      if (foundCatalog?.id !== selected?.id) {
        open();
        setSelected(foundCatalog);
      }
    }
  };

  const handleTextChange = (event: any) => {
    setSelected(event.currentTarget.value);
    combobox.resetSelectedOption();
    combobox.openDropdown();
  };

  const handleClose = () => {
    close();
  };

  const availableCatalogs = catalogs.map((item) => (
    <Combobox.Option value={String(item.id)} key={item.id}>
      {item.name}
    </Combobox.Option>
  ));

  const options = createNew
    ? [
        <Combobox.Option value="0" key="0" fw="bold">
          {getString('createNew', language)}
        </Combobox.Option>,
      ].concat(availableCatalogs)
    : availableCatalogs;

  const RightSelect = () => {
    if (isFetching) {
      return <Loader size={20} />;
    }
    return <ComboboxChevron />;
  };

  return (
    <>
      <Combobox onOptionSubmit={handleSelect} withinPortal={false} store={combobox}>
        <Combobox.Target>
          <TextInput
            className={className}
            placeholder={getString('choose', language)}
            value={catalog?.name}
            onChange={handleTextChange}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
            rightSection={<RightSelect />}
            readOnly
            {...props}
          />
        </Combobox.Target>
        <Combobox.Dropdown onClick={() => combobox.closeDropdown()}>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {opened &&
        (selected ? (
          <OperationCatalogModal
            opened={opened}
            onClose={handleClose}
            catalog={selected}
            operationId={operationId}
          />
        ) : (
          <CreateCatalogModal opened={opened} onClose={handleClose} operationId={operationId} />
        ))}
    </>
  );
};
