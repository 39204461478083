import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Label, Input, Container } from 'common';
import { PlateType } from 'store/plates/types';
import { updatePlate } from 'store/plates/thunks';
import { STORAGE } from 'constants/plates';
import showToast from 'actions/toastActions';

type StorageFormPropsType = {
  plate: PlateType;
  onClose: () => void;
};

export const StorageForm = ({ plate, onClose }: StorageFormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const tempRef = useRef<HTMLInputElement>(null);
  const freezerRef = useRef<HTMLInputElement>(null);
  const rackRef = useRef<HTMLInputElement>(null);
  const shelfRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(
        updatePlate(plate.barcode, {
          status: STORAGE,
          freezer: freezerRef.current?.value,
          temp: tempRef.current?.value,
          rack: rackRef.current?.value,
          shelf: shelfRef.current?.value,
        }),
      );
      setIsSubmitting(false);
      onClose();
    } catch (e) {
      showToast(e.message, 'error');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2>{`${getString('indicatePlateStorageMsg', language)}:`}</h2>
      <Container vertical>
        <Label label={getString('temperature', language)}>
          <Input
            disabled={isSubmitting}
            ref={tempRef}
            placeholder={getString('coldOrRoomTemp', language)}
          />
        </Label>
        <Label label={getString('freezer', language)}>
          <Input
            disabled={isSubmitting}
            ref={freezerRef}
            placeholder={getString('freezer', language)}
          />
        </Label>
        <Label label={getString('rack', language)}>
          <Input
            disabled={isSubmitting}
            ref={rackRef}
            placeholder={`${getString('rack', language)} #`}
          />
        </Label>
        <Label label={getString('shelf', language)}>
          <Input
            disabled={isSubmitting}
            ref={shelfRef}
            placeholder={`${getString('shelf', language)} #`}
          />
        </Label>
        <Container>
          <Button primary disabled={isSubmitting} onClick={onSubmit}>
            {isSubmitting ? 'Storing...' : 'Store plate'}
          </Button>
          <Button disabled={isSubmitting} onClick={onClose}>
            Cancel
          </Button>
        </Container>
      </Container>
    </div>
  );
};
