import { arrayOf, shape, string } from 'prop-types';

export const LAST_NAME = 'last_name';
export const FIRST_NAME = 'first_name';

export const roleType = shape({
  description: string,
  name: string,
});

export const userType = shape({
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
  roles: arrayOf(roleType),
});
