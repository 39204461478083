import { SamplingHistorySummaryType } from 'store/samplingHistory/types';

export const getTotalHistoryAcres = (samplingHistoryList: SamplingHistorySummaryType[]) => {
  const totalArea = samplingHistoryList.reduce(
    (sum, planSummary) => sum + planSummary.sampled_area,
    0,
  );

  return Math.round(totalArea);
};

export const getTotalHistorySamples = (samplingHistoryList: SamplingHistorySummaryType[]) =>
  samplingHistoryList.reduce((sum, planSummary) => sum + planSummary.sample_count, 0);

export const isFieldArrived = (...samplingHistoryList: SamplingHistorySummaryType[]) =>
  samplingHistoryList.some((planSummary) => planSummary.arrived_at);

export const getHistoryByOperation = (samplingHistoryList: SamplingHistorySummaryType[]) => {
  const fieldsByOperation = Object.values(
    samplingHistoryList.reduce(
      (dict, field) => {
        if (!dict[field.operation_id]) {
          dict[field.operation_id] = {
            id: field.operation_id,
            name: field.operation_name,
            fields: [],
          };
        }

        dict[field.operation_id].fields.push(field);
        return dict;
      },
      {} as { [id: number]: { id: number; name: string; fields: SamplingHistorySummaryType[] } },
    ),
  );

  return fieldsByOperation;
};
