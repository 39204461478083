import {
  DNAConcentrationsActionType,
  DNAConcentrationsStateType,
  DNAConcentrationsType,
  DNA_CONCENTRATIONS_REQUEST_ERROR,
  PlateDNAConcentrationType,
  RECEIVE_DNA_CONCENTRATIONS,
  REQUEST_DNA_CONCENTRATIONS,
  UPDATE_DNA_CONCENTRATIONS,
} from './types';

const initialState: DNAConcentrationsStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  error: '',
  setsByPlateBarcode: {},
};

const parseDNAConcentrations = (
  state: PlateDNAConcentrationType,
  concentrations: DNAConcentrationsType[],
) => {
  return concentrations.reduce((_state, c) => {
    const quadrant = c.plate_quadrant || 0;
    const row = c.row.toLowerCase();
    const col = c.column.toLowerCase();
    const quadrantObject = _state[quadrant] || {};
    return {
      ..._state,
      [quadrant]: {
        ...quadrantObject,
        [row]: {
          ...(quadrantObject[row] || {}),
          [col]: {
            ...c,
            value: Number(c.concentration.toFixed(2)),
          },
        },
      },
    };
  }, state);
};

const DNAConcentrationsReducer = (state = initialState, action: DNAConcentrationsActionType) => {
  switch (action.type) {
    case REQUEST_DNA_CONCENTRATIONS:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        hasFailed: false,
      };
    case RECEIVE_DNA_CONCENTRATIONS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
        setsByPlateBarcode: {
          ...state.setsByPlateBarcode,
          [action.payload.plateBarcode]: parseDNAConcentrations(
            state.setsByPlateBarcode[action.payload.plateBarcode] || {},
            action.payload.concentrations,
          ),
        },
      };
    case UPDATE_DNA_CONCENTRATIONS:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        hasFailed: false,
      };
    case DNA_CONCENTRATIONS_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFetched: true,
        hasFailed: true,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export default DNAConcentrationsReducer;
