import { BR_CAMPINAS, US_EMERYVILLE, US_MEMPHIS } from './shipping';

export const ZYMO_SILICA = 'zymo_silica';
export const OMNI_REVVITY_PBWASH = 'omni_revvity_pbwash';
export const ZYMO_REVVITY = 'zymo_revvity';
export const OMNI_REVVITY = 'omni_revvity';

export const US_INCUMBANT = ZYMO_SILICA;
export const BR_INCUMBANT = OMNI_REVVITY_PBWASH;
export const US_CHEMAGIC = ZYMO_REVVITY;
export const BR_V2 = OMNI_REVVITY;

export const extractionMethods = [US_INCUMBANT, BR_INCUMBANT, US_CHEMAGIC, BR_V2];

export const extractionMethodDisplayNames = {
  [US_INCUMBANT as string]: 'US Incumbent',
  [BR_INCUMBANT as string]: 'BR Incumbent',
  [US_CHEMAGIC as string]: 'US Chemagic',
  [BR_V2 as string]: 'BR V2',
};

export const extractionMethodDescriptions = {
  [ZYMO_SILICA as string]: 'zymoSilicaDescription',
  [OMNI_REVVITY_PBWASH as string]: 'omniRevvityPbWashDescription',
  [ZYMO_REVVITY as string]: 'zymoRevvityDescription',
  [OMNI_REVVITY as string]: 'omniRevvityDescription',
};

export const defaultExtractionByLocation = {
  [US_EMERYVILLE as string]: US_INCUMBANT,
  [US_MEMPHIS as string]: US_INCUMBANT,
  [BR_CAMPINAS as string]: BR_INCUMBANT,
};
