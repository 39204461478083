import { MultiPolygon } from '@turf/helpers';

export const EVENT_TYPES = {
  APPLICATION: 'asapplied',
  HARVEST: 'harvest',
  PLANTING: 'planting',
  SHAPEFILES: 'shapefiles',
};

export type FieldEventType = {
  id: number;
  enabled: boolean;
  mbtiles: string;
  field_id: number;
  geom: MultiPolygon;
  event_type:
    | typeof EVENT_TYPES.HARVEST
    | typeof EVENT_TYPES.PLANTING
    | typeof EVENT_TYPES.SHAPEFILES;
  units: string;
  crop_name: string;
  products: string;
  total_area_reported_acres: number;
  shape_files_bucket: string;
  shape_file_name: string;
  notes: string;
  started_at: string;
  ended_at: string;
  updated_at: string;
  created_at: string;
  job_id?: number;
  user_reported_as_upcoming: boolean;
  user_reported_crop_name?: string;
  user_reported_actual_yield_bu_per_acre?: number;
  // @TODO: add rest of model as needed
};

export const FIELD_EVENT_REQUEST_ERROR = 'FIELD_EVENT_REQUEST_ERROR';
export const RECEIVE_FIELD_EVENT = 'RECEIVE_FIELD_EVENT';
export const RECEIVE_FIELD_EVENTS = 'RECEIVE_FIELD_EVENTS';
export const REQUEST_FIELD_EVENTS = 'REQUEST_FIELD_EVENTS';

type RequestFieldEventsType = {
  type: typeof REQUEST_FIELD_EVENTS;
};

type ReceiveFieldEventType = {
  type: typeof RECEIVE_FIELD_EVENT;
  payload: {
    event: FieldEventType;
    oldFieldId: number;
  };
};

type ReceiveFieldEventsType = {
  type: typeof RECEIVE_FIELD_EVENTS;
  payload: {
    id: number;
    events: FieldEventType[];
  };
};

type FieldEventRequestError = {
  type: typeof FIELD_EVENT_REQUEST_ERROR;
};

export type FieldEventActionType =
  | RequestFieldEventsType
  | ReceiveFieldEventType
  | ReceiveFieldEventsType
  | FieldEventRequestError;
