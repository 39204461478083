import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { SALES_FLOW_COLORS } from 'util/mapImageryColors';
import { toCommaDollars } from 'util/numUtils';
import Styles from './Container.module.css';

interface BarChartProps {
  barColor: string;
  chartData: { name: string; value: number }[];
  legendText: string;
  domainRange: number[];
}
const ChartBar = ({ barColor, chartData, legendText, domainRange }: BarChartProps) => {
  const CustomYAxisTick = ({ y, payload }: { y: number; payload: { value: string } }) => {
    const numValue = Number(payload.value);
    return (
      <g transform={`translate(${0},${y})`}>
        <foreignObject x={82} y={-18} textAnchor="start" width={150} height={40}>
          <span style={{ color: SALES_FLOW_COLORS.GREY }}>
            {numValue && numValue > 0 ? toCommaDollars(payload.value) : ''}
          </span>
        </foreignObject>
      </g>
    );
  };
  return (
    <div className={Styles.Chart}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 100, right: 100 }}
            tickLine={false}
            axisLine={false}
          />
          <YAxis tick={CustomYAxisTick} domain={domainRange} tickLine={false} />
          <Legend
            align="left"
            verticalAlign="top"
            height={35}
            content={() => (
              <span className={Styles.LegendText}>
                <i>{legendText}</i>
              </span>
            )}
            wrapperStyle={{ marginLeft: 55, marginTop: -10 }}
          />
          <Bar barSize={70} dataKey="value" fill={barColor} radius={[5, 5, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartBar;
