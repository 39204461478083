import React from 'react';
import { useDispatch } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { FileUploadButton } from 'common';
import { getIndexSets } from 'store/indexSets/thunks';
import { requestPostIndexSet } from 'store/indexSets/requests';
import { IndexSetFileColumns } from 'constants/fileUpload';

export const CreateIndexSetButton = () => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleUpload = async (file: File) => {
    try {
      await requestPostIndexSet(file);
      await dispatch(getIndexSets());
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  return (
    <FileUploadButton
      buttonLabel={getString('createIndexSet', language)}
      onUpload={handleUpload}
      accept=".csv,.xls"
      primary
    >
      <h3>{`${getString('csvColumnsRequiredMsg', language)}:`}</h3>
      <b>{IndexSetFileColumns.join(', ')}</b>
    </FileUploadButton>
  );
};
