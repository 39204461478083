import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Container, Button } from 'common';
import { clearPlateWell } from 'store/plates/thunks';
import { WellType } from 'store/plates/types';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';

type WellDetailsPropsType = {
  well: WellType;
  onClose: () => void;
};

export const WellDetails = ({ well, onClose }: WellDetailsPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const handleDeleteWell = async () => {
    try {
      await dispatch(clearPlateWell(well.plate_barcode, well.id));
      onClose();
    } catch (e) {
      showToast(e.message, 'error');
    }
  };

  const {
    field_name: fieldName,
    operation_name: operationName,
    sample_type: type,
    lab_notes: notes,
    sample_uuid: uuid,
    sample_barcode: barcode,
    sample_weight: sampleWeight,
    replica_type: replica,
    tag_names: tags,
  } = well;
  const sampleType = replica ? [type, replica].join(' - ') : type;

  return (
    <div>
      <h1>{`${getString('well', language)} ${well.row.toUpperCase()}${well.column}`}</h1>
      <p>
        <b>{`${getString('sampleType', language)}: `}</b>
        {sampleType}
      </p>
      <p>
        <b>{`${getString('sampleMass', language)}: `}</b>
        {sampleWeight}
      </p>
      <p>
        <b>{`${getString('tags', language)}: `}</b>
        {tags?.map((tagName) => <span key={tagName}>{tagName}</span>)}
      </p>
      <p>
        <b>{`${getString('notes', language)}: `}</b>
        {notes}
      </p>
      <p>
        <b>{`${getString('operation', language)}: `}</b>
        {operationName}
      </p>
      <p>
        <b>{`${getString('field', language)}: `}</b>
        {fieldName}
      </p>
      <p>
        <b>{`${getString('barcode', language)}: `}</b>
        <Link to={`/lab/samples/${barcode}`}>{barcode}</Link>
      </p>
      <p>
        <b>UUID: </b>
        <Link to={`/lab/samples/${barcode}`}>{uuid}</Link>
      </p>
      <Container>
        <Button danger onClick={handleDeleteWell}>
          {getString('removeSample', language)}
        </Button>
      </Container>
    </div>
  );
};

export default WellDetails;
