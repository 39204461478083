import React from 'react';
import { Link } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import SortableTable from 'common/SortedTable';
import { PlateType } from 'store/plates/types';

import styles from './PlateList/PlateList.module.css';

type AmpliconPlatesPropsType = {
  plate: PlateType;
};

export const AmpliconPlates = ({ plate }: AmpliconPlatesPropsType) => {
  const language = useBroswerLanguage();

  const getHomogenBarcode = (barcode: string | undefined) => {
    return (
      barcode &&
      plate.related_plates?.parents_plates?.find((p) => p.barcode === barcode)
        ?.homogenization_plate_barcode
    );
  };

  const tableData = [
    {
      quadrant: 1,
      barcode: plate.quadrant1_plate_barcode,
      homogenBarcode: getHomogenBarcode(plate.quadrant1_plate_barcode),
      indexSet: plate.quadrant1_index_set_name,
    },
    {
      quadrant: 2,
      barcode: plate.quadrant2_plate_barcode,
      homogenBarcode: getHomogenBarcode(plate.quadrant2_plate_barcode),
      indexSet: plate.quadrant2_index_set_name,
    },
    {
      quadrant: 3,
      barcode: plate.quadrant3_plate_barcode,
      homogenBarcode: getHomogenBarcode(plate.quadrant3_plate_barcode),
      indexSet: plate.quadrant3_index_set_name,
    },
    {
      quadrant: 4,
      barcode: plate.quadrant4_plate_barcode,
      homogenBarcode: getHomogenBarcode(plate.quadrant4_plate_barcode),
      indexSet: plate.quadrant4_index_set_name,
    },
  ].filter((row) => row.barcode);

  const tableColumns = [
    {
      name: getString('quadrant', language),
      attribute: 'quadrant',
      sortable: true,
    },
    {
      name: getString('barcode', language),
      attribute: 'barcode',
      sortable: false,
      cellFormatter: (barcode: string) => {
        return barcode ? (
          <Link className={styles.Link} to={`/lab/plates/details/${barcode}`}>
            {barcode}
          </Link>
        ) : (
          '-'
        );
      },
    },
    {
      name: getString('indexSet', language),
      attribute: 'indexSet',
      sortable: false,
    },
    {
      name: getString('homogenBarcode', language),
      attribute: 'homogenBarcode',
      sortable: false,
      cellFormatter: (homogenBarcode: string) => {
        return homogenBarcode ? (
          <Link className={styles.Link} to={`/lab/plates/details/${homogenBarcode}`}>
            {homogenBarcode}
          </Link>
        ) : (
          '-'
        );
      },
    },
  ];

  return (
    <SortableTable
      title={`${getString('sourcePlates', language)}${
        plate.amplicon ? ` - ${getString('amplicon', language)}: ${plate.amplicon}` : ''
      }`}
      data={tableData}
      columns={tableColumns}
      initialSortBy="quadrant"
    />
  );
};
