import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Input, Modal, Stack, Text, Title } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { CatalogType } from 'store/catalogs/types';
import { createCatalog } from 'store/catalogs/thunks';
import showToast from 'actions/toastActions';
import { updateOperation } from 'store/operation/thunks';
import { refreshFields } from 'store/fields/thunks';

type CreateCatalogModalPropsType = {
  onClose: () => void;
  opened: boolean;
  operationId?: number;
};

export const CreateCatalogModal = ({
  onClose,
  opened,
  operationId,
}: CreateCatalogModalPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [catalogForm, setCatalogForm] = useState<Partial<CatalogType>>({});

  const handleTextChange = (value: string) => {
    setCatalogForm((newCatalogForm) => ({ ...newCatalogForm, name: value }));
  };

  const handleSubmit = async () => {
    try {
      const response: any = await dispatch(createCatalog(catalogForm));
      if (operationId) {
        await dispatch(updateOperation({ id: operationId, catalog_id: response.id }));
        dispatch(refreshFields());
      }
    } catch (e) {
      showToast(getString('serverErrorMsg', language), 'error');
    }
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} centered size="xl">
      <Stack align="center" gap="lg" pb="md">
        <Title order={2} fw="bold">
          {getString('createNewProductCatalog', language)}
        </Title>
        <Text>{getString('createCatalogMsg', language)}</Text>
        <Input
          onChange={(e) => handleTextChange(e.target.value)}
          value={catalogForm?.name || ''}
          placeholder={getString('createCatalogPlaceholderMsg', language)}
          w="30rem"
        />
        <Group pt="lg">
          <Button onClick={onClose} color="red.8">
            {getString('cancel', language)}
          </Button>
          <Button onClick={handleSubmit} disabled={!catalogForm?.name?.length}>
            {getString('save', language)}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
