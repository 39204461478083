import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store';
import { OperationType } from 'store/operation/types';
import { getRecommendationSet } from 'store/recommendationSets/thunks';

const useOperationRecommendationSet = (operation: OperationType) => {
  const dispatch = useDispatch();

  const { setsById, hasFailed, isFetching } = useSelector((state: RootState) => ({
    setsById: state.recommendationSets.byId,
    hasFailed: state.recommendationSets.hasFailed,
    isFetching: state.recommendationSets.isFetching,
  }));

  const fetchRecommendationSet = useCallback(
    (id: number) => {
      dispatch(getRecommendationSet(id));
    },
    [dispatch],
  );

  const defaultSet = useMemo(() => {
    const rec_set_id = operation?.recommendation_set_id || operation?.agency_recommendation_set_id;
    if (rec_set_id && setsById[rec_set_id]) {
      return setsById[rec_set_id];
    }
    return null;
  }, [setsById, operation]);

  useEffect(() => {
    if (!isFetching && !hasFailed) {
      const rec_set_id =
        operation?.recommendation_set_id || operation?.agency_recommendation_set_id;
      if (rec_set_id && !setsById[rec_set_id]) {
        fetchRecommendationSet(rec_set_id);
      }
    }
  }, [fetchRecommendationSet, setsById, isFetching, hasFailed, operation]);

  return defaultSet;
};

export default useOperationRecommendationSet;
