import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { User } from 'store/user/types';
import { SimpleGrid, Stack, Text } from '@mantine/core';

interface Props {
  user: Partial<User>;
  agencyName?: string | null;
}

const BillingUserDetails = ({ user, agencyName }: Props) => {
  const language = useBroswerLanguage();

  return (
    <SimpleGrid cols={3}>
      <Stack>
        <Text fw={600}>{getString('agency', language)}</Text>
        <Text size="sm">{agencyName}</Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('firstName', language)}</Text>
        <Text size="sm">{user.first_name}</Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('lastName', language)}</Text>
        <Text size="sm">{user.last_name}</Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('email', language)}</Text>
        <Text truncate="end" size="sm">
          {user.email}
        </Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('cellPhone', language)}</Text>
        <Text size="sm">{user.cell}</Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('contractStatus', language)}</Text>
        <Text size="sm">{user.eula_accepted && getString('signed', language)}</Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('billingAddress', language)}</Text>
        <Text truncate="end" size="sm">
          {user.billing_addresses && user.billing_addresses[0]?.street}
        </Text>
      </Stack>
      <Stack>
        <Text fw={600}>
          {getString('city', language)} &amp; {getString('state', language)}
        </Text>
        <Text truncate="end" size="sm">
          {user.billing_addresses && user.billing_addresses[0]?.city}{' '}
          {user.billing_addresses && user.billing_addresses[0]?.state}
        </Text>
      </Stack>
      <Stack>
        <Text fw={600}>{getString('zipcode', language)}</Text>
        <Text truncate="end" size="sm">
          {user.billing_addresses && user.billing_addresses[0]?.zip}
        </Text>
      </Stack>
    </SimpleGrid>
  );
};

export default BillingUserDetails;
