export type IndexSetType = {
  id: number;
  name: string;
  row: string;
  column: string;
  i7_index_id: string;
  index: string;
  rc_index: string;
  i5_index_id: string;
  index2: string;
  rc_index2: string;
};

export type IndexSetsStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  hasFetchedNames: boolean;
  sets: IndexSetType[];
  setsByName: {
    [key: string]: IndexSetType[];
  };
  names: string[];
};

export const REQUEST_INDEX_SETS = 'REQUEST_INDEX_SETS';
export const RECEIVE_INDEX_SETS = 'RECEIVE_INDEX_SETS';
export const RECEIVE_INDEX_SET = 'RECEIVE_INDEX_SET';
export const RECEIVE_INDEX_SETS_NAMES = 'RECEIVE_INDEX_SETS_NAMES';
export const INDEX_SET_REQUEST_ERROR = 'INDEX_SET_REQUEST_ERROR';

type RequestIndexSetsActionType = {
  type: typeof REQUEST_INDEX_SETS;
};

type ReceiveIndexSetsActionType = {
  type: typeof RECEIVE_INDEX_SETS;
  payload: {
    indexSets: IndexSetType[];
  };
};

type ReceiveIndexSetActionType = {
  type: typeof RECEIVE_INDEX_SET;
  payload: IndexSetType;
};

type ReceiveIndexSetNamesActionType = {
  type: typeof RECEIVE_INDEX_SETS_NAMES;
  payload: string[];
};

type IndexSetRequestErrorType = {
  type: typeof INDEX_SET_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type IndexSetsActionType =
  | RequestIndexSetsActionType
  | ReceiveIndexSetsActionType
  | ReceiveIndexSetActionType
  | ReceiveIndexSetNamesActionType
  | IndexSetRequestErrorType;
