import React, { useState } from 'react';
import { RED } from 'util/mapImageryColors';
import { useDisclosure } from '@mantine/hooks';
import { Text, Button, Group, Popover, Stack, Textarea, ActionIcon, Tooltip } from '@mantine/core';
import { SamplePlanTrackingType } from 'store/samplePlans/types';
import { putSamplePlan } from 'store/samplePlans/requests';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import showToast from 'actions/toastActions';
import { FiMessageSquare } from 'react-icons/fi';
import { endEllipsis } from 'util/stringUtils';

type Props = {
  samplePlan: SamplePlanTrackingType;
  notes_type: string;
};

const NotesPopover = ({ samplePlan, notes_type }: Props) => {
  const language = useBroswerLanguage();
  const [notesOpened, { close: closeNotes, open: openNotes }] = useDisclosure(false);

  const [notes, setNotes] = useState(samplePlan[notes_type] || '');

  const saveDispatchNotes = async (e) => {
    try {
      e.stopPropagation();
      await putSamplePlan({ [notes_type]: notes }, samplePlan.id);
      showToast(getString('samplingPlanUpdatedMsg', language));
      closeNotes();
    } catch (err) {
      showToast(getString('samplingPlanUpdatedFailureMsg', language), 'error', 7000);
    }
  };

  const cancelDispatchNotes = (e) => {
    e.stopPropagation();
    setNotes(samplePlan.dispatch_notes || '');
    closeNotes();
  };

  const openCloseNotes = (e) => {
    e.stopPropagation();
    notesOpened ? closeNotes() : openNotes();
  };

  return (
    <Popover width={300} trapFocus position="bottom" withArrow opened={notesOpened}>
      <Popover.Target>
        {notes ? (
          <Tooltip label={notes} bg="blue" maw="20rem" multiline>
            <Text size="xs" onClick={openCloseNotes}>
              {endEllipsis(notes, 110)}
            </Text>
          </Tooltip>
        ) : (
          <ActionIcon
            variant="white"
            onClick={(e) => {
              e.stopPropagation();
              notesOpened ? closeNotes() : openNotes();
            }}
          >
            <FiMessageSquare />
          </ActionIcon>
        )}
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="xs">
          <Textarea
            label={getString('dispatchNotes', language)}
            size="xs"
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            onClick={(e) => e.stopPropagation()}
          />
          <Group justify="flex-end">
            <Button w="6rem" onClick={saveDispatchNotes}>
              {getString('save', language)}
            </Button>
            <Button w="6rem" bg={RED} onClick={cancelDispatchNotes}>
              {getString('cancel', language)}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default NotesPopover;
