import React from 'react';
import { PestGroupPayloadType, SeedType } from 'store/cropPlans/types';
import { CatalogType } from 'store/catalogs/types';
import { AnalyticType } from 'store/analytics/types';

import SeedRows from './Common/SeedRow';
import { Table } from '@mantine/core';
import { type ToastType } from 'actions/toastActions';

interface SeedCatalogPropsType {
  analytics: AnalyticType[];
  pestGroups: PestGroupPayloadType;
  catalog: Partial<CatalogType>;
  seeds: SeedType[];
  setToastMessage: (message: string, type?: ToastType, time?: number) => void;
}

const SeedCatalog = ({
  analytics,
  pestGroups,
  catalog,
  seeds,
  setToastMessage,
}: SeedCatalogPropsType) => {
  return (
    <Table stickyHeaderOffset={60}>
      <SeedRows
        seeds={seeds}
        analytics={analytics}
        pestGroups={pestGroups}
        catalog={catalog}
        setToastMessage={setToastMessage}
      />
    </Table>
  );
};

export default SeedCatalog;
