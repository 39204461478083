import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';

type ParamsType = {
  fieldId: string;
};

const useFieldGeometry = () => {
  const params = useParams<ParamsType>();
  const fieldId = Number(params.fieldId);
  const dispatch = useDispatch();
  const { field } = useSelector((state: RootState) => ({
    field: state.fieldGeometry.geometries[fieldId],
  }));

  useEffect(() => {
    if (!field) {
      dispatch(getFieldGeometry(fieldId));
    }
  }, [field, fieldId]);

  return field;
};

export default useFieldGeometry;
