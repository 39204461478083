import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';
import { FieldEventType } from './types';

export const getFieldEvents = (fieldId: number) =>
  requestGet(`${SERVICE_URL}/field_events/${fieldId}`).then(handleJsonResponse);

export const updateFieldEvent = (id: number, body: Partial<FieldEventType>) =>
  requestPut(`${SERVICE_URL}/field_event/${id}`, { body }).then(handleJsonResponse);

export const completeFieldEventIngest = (id: number) =>
  requestPost(`${SERVICE_URL}/field_event_complete_ingest/`, {
    body: {
      field_event_id: id,
    },
  }).then(handleJsonResponse);
