import React, { useState, useMemo } from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useSelector } from 'react-redux';
import { userIsAgencyAdmin, userIsAgent, checkRole, getCreateUserRoleOption } from 'util/userRoles';
import showToast from 'actions/toastActions';
import { isNumber } from 'util/numUtils';
import { selectRoles } from 'store/roles/actions';
import { TooltipWrapper, Label, HelpIcon, Button, Typeahead, Selector, Radio, Input } from 'common';
import { UserInfo, UserRole } from 'store/user/types';
import { registerFormValidate, zipCodeExp } from 'util/validators';
import { RootState } from 'store';
import { Agency } from 'store/agencies/types';
import styles from './RegistrationForm.module.css';
import BillingInfoInputs from '../../Settings/BillingInfoInputs';
import ContactInfoInputs from '../../Settings/ContactInfoInputs';

interface Props {
  errorMessage?: string;
  submit: (data: RegistrationUserInfo, setSubmitting: Function) => void;
}

interface LabelWithTooltipProps {
  label: string;
  tooltip: string;
}

interface RegistrationUserInfo extends Partial<UserInfo> {}

const RegistrationForm = ({ errorMessage, submit }: Props) => {
  const language = useBroswerLanguage();
  const storeRoles = useSelector(selectRoles);
  const [submitting, setSubmitting] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState<Agency | null>(null);
  const [hasExistingAgency, setHasExistingAgency] = useState('');
  const { allAgencies } = useSelector((state: RootState) => {
    return state.agencies;
  });

  const roles = useMemo(
    () => getCreateUserRoleOption(storeRoles, language),
    [storeRoles, language],
  );

  const typeaheadOptions = useMemo(
    () =>
      allAgencies.map((option) => ({
        id: option.id,
        displayName: option.name,
      })),
    [allAgencies],
  );

  const initialValues: UserInfo = {
    first_name: '',
    last_name: '',
    email: '',
    business_name: '',
    agency_name: '',
    skip_signature: false,
    role_id: roles.find((role) => role.name.includes(UserRole.Agent))?.id || undefined,
    cell: '',
    billing_address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  };
  const [userInfo, setUserInfo] = useState(initialValues);

  const handleTypeaheadSelect = (index: number) => {
    const option = allAgencies[index];
    setSelectedAgency(option);
  };

  const setAgency = (e) => {
    setHasExistingAgency(e.target.value);
    setSelectedAgency(null);
  };

  const LabelWithTooltip = ({ label, tooltip }: LabelWithTooltipProps) => {
    const content = <div className={styles.Tooltip}>{tooltip}</div>;
    return (
      <div className={styles.LabelWithTooltip}>
        <Label className={styles.TooltipLabel} label={label} />
        <TooltipWrapper className={styles.HelpIcon} right content={content}>
          <HelpIcon />
        </TooltipWrapper>
      </div>
    );
  };

  const submitRegistration = async () => {
    const error = registerFormValidate(
      userInfo,
      selectedAgency,
      hasExistingAgency,
      roles,
      language,
    );

    if (error) {
      return showToast(error, 'error', 5000);
    }
    const data: RegistrationUserInfo = {
      ...userInfo,
      email: userInfo.email.trim().toLowerCase(),
    };
    if (userInfo.billing_address) {
      const { street, city, zip, state } = userInfo.billing_address;
      if (!street && !city && !zip && !state) {
        delete data.billing_address;
      } else {
        if (data.billing_address?.street) {
          data.billing_address.street = data.billing_address.street.trim();
        }
        if (data.billing_address?.city) {
          data.billing_address.city = data.billing_address.city.trim();
        }
        if (data.billing_address?.state) {
          data.billing_address.state = data.billing_address.state.trim();
        }
      }
    }

    if (!data.cell) {
      delete data.cell;
    }

    if (!data.business_name) {
      delete data.business_name;
    } else {
      data.business_name = data.business_name.trim();
    }

    if (data.agency_name) {
      data.agency_name = data.agency_name.trim();
    } else if (selectedAgency && selectedAgency.id) {
      data.agency_id = selectedAgency.id;
    }
    await submit(data, setSubmitting);
  };

  const updateBillingAddress = (addresskey: string, addressValue: string) => {
    setUserInfo({
      ...userInfo,
      billing_address: { ...userInfo.billing_address, [addresskey]: addressValue },
    });
  };

  const updateZipcode = (value: string) => {
    if (zipCodeExp.test(value) && value.length < 10) {
      setUserInfo({ ...userInfo, billing_address: { ...userInfo.billing_address, zip: value } });
    }
  };

  const updateRole = (idx: number) => {
    setUserInfo({ ...userInfo, role_id: roles[idx].id });
  };

  const updateContactInfo = (key: string, value: string) => {
    setUserInfo({ ...userInfo, [key]: value });
  };

  const updateContactCell = (value: string) => {
    if (isNumber(Number(value)) && value.length < 13) {
      setUserInfo({ ...userInfo, cell: value });
    }
  };

  return (
    <div className={styles.Wrapper}>
      <Label label={`${getString('role', language)}*`}>
        <Selector
          dataTestId="role"
          className={styles.Selector}
          activeIndex={roles.findIndex((role) => role.id === userInfo.role_id)}
          onChange={updateRole}
          options={roles}
        />
      </Label>
      <ContactInfoInputs
        user={userInfo}
        updateContactInfo={updateContactInfo}
        updateContactCell={updateContactCell}
      />
      <BillingInfoInputs
        updateBillingAddress={updateBillingAddress}
        updateZipcode={updateZipcode}
        address={userInfo.billing_address}
      />
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <div className={styles.FormControlContainer}>
        <div className={styles.LabelAndRadio}>
          {checkRole(roles, Number(userInfo.role_id), userIsAgencyAdmin) && (
            <>
              <LabelWithTooltip
                label={`${getString('isThisPartOfExisting', language)}*`}
                tooltip={getString('whatsTheNameOfAccountTooltip', language)}
              />
              <div className={styles.RadioControl} role="group" aria-labelledby="radio-group">
                <Radio
                  data-test-id="agency-radio-button-yes"
                  value="yes"
                  checked={hasExistingAgency === 'yes'}
                  onChange={setAgency}
                />
                <div className={styles.RadioItem}>{getString('yes', language)}</div>
                <Radio value="no" checked={hasExistingAgency === 'no'} onChange={setAgency} />
                <div className={styles.RadioItem}>{getString('noWeNeedToCreate', language)}</div>
              </div>
            </>
          )}
        </div>
      </div>
      {checkRole(roles, Number(userInfo.role_id), userIsAgent) && (
        <h2 className={styles.RegisterAgentAbout}>
          {getString('agentsMustBeAssignedToExisting', language)}:
        </h2>
      )}
      {checkRole(roles, Number(userInfo.role_id), userIsAgencyAdmin) &&
        hasExistingAgency === 'no' && (
          <>
            <h2 className={styles.RegisterAgentAbout}>
              {getString('userWillBeAssignedTip', language)}
            </h2>
            <div className={styles.FormControlContainer}>
              <Label label={`${getString('agencyName', language)}*`}>
                <Input
                  className={styles.Input}
                  onChange={(e) => updateContactInfo('agency_name', e.target.value)}
                  value={userInfo?.agency_name || ''}
                />
              </Label>
            </div>
          </>
        )}
      {(checkRole(roles, Number(userInfo.role_id), userIsAgent) || hasExistingAgency === 'yes') && (
        <>
          <div className={styles.FormControlContainer}>
            <Typeahead
              className={styles.RegisterTypeAhead}
              dataTestId="agency-name-search"
              onNewOption={() => {}}
              onSelect={handleTypeaheadSelect}
              onDeselect={() => setSelectedAgency(null)}
              options={typeaheadOptions}
              placeholder={getString('searchAgencyName', language)}
              icon="SearchIcon"
            />
          </div>
        </>
      )}
      <div className={styles.Submit}>
        <Button
          onClick={submitRegistration}
          data-test-id="create-user-button"
          primary
          disabled={submitting}
          type="submit"
        >
          {getString('createUser', language)}
        </Button>
      </div>
    </div>
  );
};

export default RegistrationForm;
