import showToast from 'actions/toastActions';
import { AppThunk } from 'store';
import { requestExternalNutrientLabs, receiveExternalNutrientLabs } from './actions';
import { getAvailableExternalNutrientLabs } from './requests';

export const getExternalNutrientLabs = (): AppThunk => async (dispatch) => {
  dispatch(requestExternalNutrientLabs());

  try {
    const response = await getAvailableExternalNutrientLabs();
    return dispatch(receiveExternalNutrientLabs(response));
  } catch (e) {
    showToast(e.message, 'error');
  }
};
