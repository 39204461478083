import { WellType } from 'store/plates/types';
import { SOIL } from './samples';

export const ENTRY_TYPES = {
  BARCODE: 'addSample',
  CONTROL: 'addControl',
  NO_BARCODE: 'createSample',
};

export const SAMPLE_TYPES = {
  COMMERCIAL: 'Commercial',
  RND: 'R&D',
};

const EMPTY_WELL: WellType = {
  column: '',
  created_at: '',
  id: 0,
  plate_barcode: '',
  plate_quadrant: 0,
  plate: 0,
  row: '',
  sample_type: SAMPLE_TYPES.COMMERCIAL,
  sample: {
    barcode: undefined,
    created_at: '',
    enabled: false,
    id: 0,
    primary: true,
    replica_type: null,
    rnd: false,
    sample_uuid: '',
    status: undefined,
    tags: [],
    sample_type_repr: '',
  },
  tag_names: [],
  notes: '',
  sample_barcode: '',
  sample_uuid: '',
  replica_type: undefined,
  type: SOIL,
};

const createEmptyWell = (
  row: string,
  column: string,
  plate: string,
  quadrant?: number,
): WellType => {
  return {
    ...EMPTY_WELL,
    row,
    column,
    plate_barcode: plate,
    plate_quadrant: quadrant,
  };
};

export default createEmptyWell;
