import React, { Fragment } from 'react';
import { Table, Text } from '@mantine/core';
import { OrderHistoryType } from 'store/orderHistory/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import acToHectares, { getAcreageUnitFromLang } from 'util/units';
import { getOrderHistoryTotals } from 'util/orders';
import { numsToInvoiceDollars } from 'util/stringUtils';
import { productDisplayNames } from 'constants/products';
import { getPriceSummary, sortPriceSummaryKeys } from 'util/product';
import { sortFarmThenFieldName } from 'util/sortByName';
import { sortByCreatedAt } from 'util/date';

type OrderHistoryExpandedPropsType = {
  operation: OrderHistoryType;
};

export const OrderHistoryExpanded = ({ operation }: OrderHistoryExpandedPropsType) => {
  const language = useBroswerLanguage();

  return (
    <Table verticalSpacing="0" withRowBorders={false}>
      <Table.Tbody>
        {sortFarmThenFieldName(operation.fields).map((field) =>
          sortByCreatedAt(field.sampling_plans, true).map((plan) => {
            const newPriceSummary = getPriceSummary(plan.price_summary, language, plan.plan_type);
            const sortedPriceSummaryKeys = sortPriceSummaryKeys(newPriceSummary);
            const { total, collection, commission } = getOrderHistoryTotals(plan);
            return (
              <Fragment key={`${plan.id}_field`}>
                <Table.Tr style={{ border: 0 }}>
                  <Table.Td w="20%">
                    <Text inherit>
                      {field.farm_name ? `${field.farm_name}:` : ''} {field.name}
                    </Text>
                  </Table.Td>
                  <Table.Td w="15%" ta="right">
                    <Text
                      inherit
                    >{`${Math.round(acToHectares(field.calculated_area_acres, language))}${getAcreageUnitFromLang(language)}`}</Text>
                  </Table.Td>
                  <Table.Td w="20%" ta="right">
                    <Text inherit>{plan.name}</Text>
                  </Table.Td>
                  <Table.Td w="15%" ta="right">
                    <Text inherit>{numsToInvoiceDollars(collection)}</Text>
                  </Table.Td>
                  <Table.Td w="15%" ta="right">
                    <Text inherit>{numsToInvoiceDollars(commission)}</Text>
                  </Table.Td>
                  <Table.Td w="15%" ta="right">
                    <Text inherit>{numsToInvoiceDollars(total)}</Text>
                  </Table.Td>
                  <Table.Th w="3rem" maw="3rem" miw="3rem" />
                </Table.Tr>
                {sortedPriceSummaryKeys.map((product) => {
                  const summary = newPriceSummary[product];
                  return (
                    <Table.Tr key={`${plan.id}_${product}`} style={{ border: 0 }}>
                      <Table.Td fs="italic" ta="right">
                        <Text inherit>{productDisplayNames[product]}</Text>
                      </Table.Td>
                      <Table.Td ta="right">
                        <Text inherit>{summary.num_zones}</Text>
                      </Table.Td>
                    </Table.Tr>
                  );
                })}
                <Table.Tr h="xs" style={{ border: 0 }} />
              </Fragment>
            );
          }),
        )}
      </Table.Tbody>
    </Table>
  );
};
