import React from 'react';
import { PlateType } from 'store/plates/types';

import { EditNotes } from '../../Sections/Inputs/EditNotes';
import { ChildrenPlates } from '../../Sections/RelatedPlates/ChildrenPlates';
import { ParentPlates } from '../../Sections/RelatedPlates/ParentPlates';
import { DNAWellGrid } from '../../Sections/DNAConcentration/DNAWellGrid';
import { PlateDocumentsContainer } from '../../Sections/PlateDocuments/Container';
import styles from '../Container.module.css';

type DNAExtractionLayoutPropsType = {
  plate: PlateType;
};

export const DNAExtractionLayout = ({ plate }: DNAExtractionLayoutPropsType) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <ParentPlates plate={plate} />
          <DNAWellGrid plateBarcode={plate.barcode} allowImport />
        </div>
        <div className={styles.Column}>
          <ChildrenPlates plate={plate} />
          <EditNotes plate={plate} />
          <PlateDocumentsContainer plate={plate} />
        </div>
      </div>
    </div>
  );
};
