import {
  LAB_SHIPMENT_REQUEST_ERROR,
  LabShipment,
  LabShipmentActionType,
  RECEIVE_LAB_SHIPMENT,
  RECEIVE_LAB_SHIPMENTS,
  REQUEST_LAB_SHIPMENT,
} from './types';

export const labShipmentRequestError = (payload: { message: string }): LabShipmentActionType => {
  return {
    type: LAB_SHIPMENT_REQUEST_ERROR,
    payload,
  };
};

export const requestLabShipment = (): LabShipmentActionType => {
  return {
    type: REQUEST_LAB_SHIPMENT,
  };
};

export const receiveLabShipment = (shipment: LabShipment): LabShipmentActionType => ({
  type: RECEIVE_LAB_SHIPMENT,
  payload: {
    id: shipment.group_id,
    shipment,
  },
});

export const receiveLabShipments = (payload: {
  page: number;
  per_page: number;
  total: number;
  items: LabShipment[];
}): LabShipmentActionType => ({
  type: RECEIVE_LAB_SHIPMENTS,
  payload,
});
