import React, { useState } from 'react';
import { Table, TableCol, TableRow } from 'common';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import classNames from 'classnames';
import { CropReportType } from 'store/salesFlow/types';
import { displayPriceOrBar } from 'util/salesFlow';
import Styles from './ReportTable.module.css';
import CropRow from './CropRow';

interface ReportTableProps {
  operationCropInfo: CropReportType[];
  totalAcres: number;
  averageBoostYield: number;
  averageOptimizeSpend: number;
  averageTotalReturn: number;
}

const ReportTable = ({
  operationCropInfo,
  totalAcres,
  averageTotalReturn,
  averageOptimizeSpend,
  averageBoostYield,
}: ReportTableProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const language = useBroswerLanguage();

  return (
    <Table>
      <TableRow header>
        <TableCol size="x2" />
        <TableCol className={Styles.Column}>{getString('boostYield', language)}</TableCol>
        <TableCol className={Styles.Column}>{getString('optimizeSpend', language)}</TableCol>
        <TableCol className={classNames(Styles.Column, Styles.ColumnEnd)}>
          {getString('totalReturn', language)}
        </TableCol>
      </TableRow>
      {operationCropInfo.map((info, index) => (
        <CropRow
          cropInfo={info}
          key={info.id}
          index={index}
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
        />
      ))}
      <TableRow borderless>
        <TableCol size="x2">
          <div className={Styles.ColumnLeft}>
            <b>
              {getString('operationSummary', language)} ({totalAcres}ac){' '}
            </b>
          </div>
        </TableCol>
        <TableCol className={Styles.Column}>
          <b>{displayPriceOrBar(averageBoostYield, '/ ac')}</b>
        </TableCol>
        <TableCol className={Styles.Column}>
          <b>{displayPriceOrBar(averageOptimizeSpend, '/ ac')}</b>
        </TableCol>
        <TableCol className={classNames(Styles.Column, Styles.ColumnEnd)}>
          <b>{displayPriceOrBar(averageTotalReturn, '/ ac')}</b>
        </TableCol>
      </TableRow>
      {openIndex === null && (
        <div>
          <div className={Styles.TableWording}>
            {getString('estimateBasedOnFarmPlan', language)}
          </div>
          <div className={Styles.TableWording}>
            {getString('patternAgPersonalizedReport', language)}
          </div>
        </div>
      )}
    </Table>
  );
};

export default ReportTable;
