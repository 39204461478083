import React, { useEffect, useState } from 'react';
import { ComboboxOptionProps, Select, Stack } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Header } from 'common/Components/Mantine/Header';
import { Agency, ContractType } from 'store/agencies/types';
import { formatDateMonthDayYear } from 'util/date';

import { PriceList } from './PriceList';
import { ContractDetails } from './ContractDetails';
import { CreateContract } from './CreateContract/CreateContract';

type PricingContractContainerPropTypes = {
  agency: Agency;
};

const PricingContractContainer = ({ agency }: PricingContractContainerPropTypes) => {
  const language = useBroswerLanguage();

  const contractOptions =
    agency?.pricing_contracts
      ?.sort((a, b) => (a.id > b.id ? 1 : -1))
      .map((contract) => ({
        contract: contract,
        value: String(contract.id),
        label: formatDateMonthDayYear(contract.expired_at, language),
      })) || [];

  const [selectedContract, setSelectedContract] = useState<ContractType>();

  const handleSelectContract = (label: string | null, option: ComboboxOptionProps) => {
    const contract = contractOptions.find(
      (o) => o.value === option?.value || o.label === label,
    )?.contract;
    if (contract) {
      setSelectedContract(contract);
    }
  };

  useEffect(() => {
    if (
      contractOptions.length &&
      (!selectedContract || !contractOptions.find((o) => o.contract.id === selectedContract.id))
    ) {
      const current = contractOptions.find((o) => o.contract.is_current)?.contract;
      if (current) {
        setSelectedContract(current);
      }
    }
  }, [contractOptions, selectedContract]);

  return (
    <Stack>
      <Header title={`${getString('contractPricing', language)} - ${agency.name}`}>
        <CreateContract agency={agency} />
        {selectedContract && <CreateContract agency={agency} contract={selectedContract} />}
        <Select
          value={String(selectedContract?.id)}
          onChange={handleSelectContract}
          data={contractOptions}
        />
      </Header>
      {selectedContract && <ContractDetails contract={selectedContract} agency={agency} />}
      {selectedContract && <PriceList contract={selectedContract} />}
    </Stack>
  );
};

export default PricingContractContainer;
