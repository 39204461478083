import React from 'react';
import {
  CheckIcon,
  CloseButton,
  Combobox,
  ComboboxChevron,
  ComboboxOptionProps,
  Group,
  Pill,
  PillsInput,
  PillsInputProps,
  ScrollArea,
  Text,
  useCombobox,
} from '@mantine/core';

type MultiSelectPropsType = Omit<PillsInputProps, 'onChange'> & {
  onChange: (value: any[]) => void;
  value: any[];
  data: {
    id: number;
    label: string;
    value: any;
    disabled?: boolean;
  }[];
  all?: string;
  placeholder?: string;
  clearable?: boolean;
  className?: string;
};

export const MultiSelect = ({
  onChange,
  value,
  data,
  all,
  placeholder,
  clearable,
  className,
  ...props
}: MultiSelectPropsType) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleSelect = (label: string, option: ComboboxOptionProps) => {
    const matchingOption =
      dataWithAll.find((o) => option.key === o.id) || dataWithAll.find((o) => label === o.label);
    if (matchingOption) {
      if (all) {
        if (matchingOption.value === null) {
          handleDeselectAll();
        } else {
          if (value.includes(matchingOption.value)) {
            onChange(value.filter((v) => v !== matchingOption.value));
          } else {
            const newValue = [...value, matchingOption.value];
            data.every((d) => newValue.includes(d.value))
              ? handleDeselectAll()
              : onChange(newValue);
          }
        }
      } else {
        onChange(
          value.includes(matchingOption.value)
            ? value.filter((v) => v !== matchingOption.value)
            : [...value, matchingOption.value],
        );
      }
    }
  };

  const handleDeselect = (label: string, option: ComboboxOptionProps) => {
    combobox.closeDropdown();
    const matchingOption =
      data.find((o) => option.key === o.id) || data.find((o) => label === o.label);
    if (matchingOption) {
      onChange(value.filter((v) => v !== matchingOption.value));
    }
  };

  const handleDeselectAll = () => {
    combobox.closeDropdown();
    onChange([]);
  };

  const RightSelect = () => {
    if (clearable && values.length) {
      return (
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={handleDeselectAll}
        />
      );
    }
    return <ComboboxChevron />;
  };

  const selectAllOption = all
    ? [
        {
          id: 0,
          value: null,
          label: all,
        } as {
          id: number;
          label: string;
          value: any;
          disabled?: boolean;
        },
      ]
    : [];

  const dataWithAll = selectAllOption.concat(data || []);
  const optionElements = dataWithAll.map((o) => (
    <Combobox.Option value={o.label} key={o.id} disabled={o.disabled}>
      <Group gap="xs">
        {value.includes(o.value) ? (
          <CheckIcon color="var(--mantine-color-gray-6)" size={12} />
        ) : null}
        <Text inherit>{o.label}</Text>
      </Group>
    </Combobox.Option>
  ));

  const values = data
    .filter((o) => value.includes(o.value))
    .map((o) => (
      <Pill key={o.id} withRemoveButton onRemove={() => handleDeselect(o.label, o.value)}>
        {o.label}
      </Pill>
    ));

  return (
    <Combobox onOptionSubmit={handleSelect} withinPortal={false} store={combobox}>
      <Combobox.Target>
        <PillsInput
          className={className}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={<RightSelect />}
          disabled={!data.length}
          miw={props.miw || '12.1rem'}
          {...props}
        >
          <Pill.Group>
            {values.length ? (
              values
            ) : (
              <PillsInput.Field readOnly pointer placeholder={placeholder} value={all} />
            )}
          </Pill.Group>
        </PillsInput>
      </Combobox.Target>
      <Combobox.Dropdown hidden={!data.length}>
        <ScrollArea.Autosize mah={200} type="scroll">
          <Combobox.Options>{optionElements}</Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
};
