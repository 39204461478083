import React, { useState } from 'react';
import { Divider, Flex, Stack, Text } from '@mantine/core';
import classNames from 'classnames';

import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';

import FieldCollections from './FieldCollections';

import styles from './Container.module.css';
import { DataReviewCollectionGroup } from 'store/eoCollections/types';

interface DataReviewCollectionsProps {
  collections: DataReviewCollectionGroup;
  selectedFieldIds: number[];
  /** Show the grower name below the field name in expanded sub-panel */
  shouldShowGrowerSubheading: boolean;
  toggleField: (id: number) => void;
}

const CollectionsGroup = ({
  collections,
  selectedFieldIds,
  toggleField,
  shouldShowGrowerSubheading,
}: DataReviewCollectionsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const language = useBroswerLanguage();

  if (!collections.fields.length) {
    return null;
  }

  const totalAcreage = collections.fields.reduce(
    (total, field) => total + Math.round(field.features[0].properties.acreage),
    0,
  );
  const acreageUnit = collections.fields[0].features[0].properties.acreage_unit;
  const totalCollections = collections.fields.reduce(
    (total, field) => total + field.features[0].properties.pending_eo_collections.length,
    0,
  );

  const uniqueSecondaryNames = collections.secondaryNames.filter(
    (name, index, self) => self.indexOf(name) === index,
  );

  return (
    <>
      <Flex
        className={classNames(styles.Header, styles.Row)}
        justify="space-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Stack gap={0} w="25%">
          <Text fw={FONT_WEIGHT_BOLD}>{collections.name}</Text>
          <Text c="dimmed" size="sm">
            {uniqueSecondaryNames.join(', ')}
          </Text>
        </Stack>
        <Text fw={FONT_WEIGHT_BOLD} w="25%">
          {getString('planNames', language)}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} w="35%">
          {`${totalAcreage} ${acreageUnit}`}
        </Text>
        <Text fw={FONT_WEIGHT_BOLD} w="15%">
          {isExpanded
            ? getString('viewScanCoverage', language)
            : `${totalCollections} ${getString('collectionsToReview', language)}`}
        </Text>
      </Flex>
      <Divider size="sm" />
      {isExpanded
        ? collections.fields.map((field) => (
            <FieldCollections
              field={field}
              key={`field-${field.features[0].properties.id}`}
              selectedFieldIds={selectedFieldIds}
              toggleField={toggleField}
              shouldShowGrowerSubheading={shouldShowGrowerSubheading}
            />
          ))
        : null}
    </>
  );
};

export default CollectionsGroup;
