import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Group, Text } from '@mantine/core';
import classNames from 'classnames';
import {
  getAnalysisShortName,
  getRequiredAnalysisForSample,
  isAnalysisComplete,
} from 'util/analysis';
import { LabSampleType } from 'store/labSamples/types';

import styles from './RequiredAnalysis.module.css';

type RequiredAnalysisPropsType = {
  sample: LabSampleType;
  shouldShow590?: boolean;
};

export const RequiredAnalysis = ({ sample, shouldShow590 }: RequiredAnalysisPropsType) => {
  const language = useBroswerLanguage();

  const generateAnalysisList = () => {
    const requiredList = getRequiredAnalysisForSample(sample, true, true, shouldShow590);
    if (!requiredList.length) {
      return <Text>{getString('noAnalysis', language)}</Text>;
    }
    return requiredList.map((analysis) => {
      const isComplete = isAnalysisComplete(sample, analysis);
      return (
        <div
          className={classNames(
            styles.AnalysisBox,
            isComplete ? styles.Complete : styles.Incomplete,
          )}
          key={analysis}
        >
          {getAnalysisShortName(analysis, language)}
        </div>
      );
    });
  };

  return <Group gap="xs">{generateAnalysisList()}</Group>;
};
