import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Container, Button, TabbedController } from 'common';
import { getString } from 'strings/translation';
import createEmptyWell, { ENTRY_TYPES } from 'constants/emptyWell';
import { PlateType, WellType } from 'store/plates/types';
import { RootState } from 'store';
import {
  generateWellsIndex,
  getMaxWellsByPlateType,
  getNextEmptyWell,
  getPlateDisplayName,
} from 'util/plates';
import { addWellToPlate } from 'store/plates/thunks';
import { userIsAdmin, userIsSuperAdmin } from 'store/user/selectors';

import styles from './Container.module.css';
import { ExistingSampleForm } from './ExistingSampleForm';
import { ControlSampleForm } from './ControlSampleForm';
import { NewSampleForm } from './NewSampleForm';

type CreateWellContainerPropsType = {
  startingWell: WellType;
  plate: PlateType;
  onClose: () => void;
};

export const CreateWellContainer = ({
  startingWell,
  plate,
  onClose,
}: CreateWellContainerPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const barcodeInput = useRef<HTMLInputElement>(null);
  const [isFormValid, setIsFormValid] = useState(true);
  const [entryTypeIndex, setEntryTypeIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [well, setWell] = useState<WellType>(startingWell);
  const { isAdmin } = useSelector((state: RootState) => ({
    isAdmin: userIsAdmin(state) || userIsSuperAdmin(state),
  }));

  useEffect(() => {
    if (barcodeInput && barcodeInput.current) {
      barcodeInput.current.focus();
    }
  }, [entryTypeIndex]);

  const { maxRows, maxCols } = getMaxWellsByPlateType(plate.plate_type);

  const wellLookupMap = generateWellsIndex(plate.wells, startingWell.plate_quadrant);

  const resetForNextWell = () => {
    const { row, column } =
      getNextEmptyWell(wellLookupMap, well.row, well.column, maxRows, maxCols) || {};
    if (row && column) {
      setErrorMessage('');
      setWell(createEmptyWell(row, column, plate.barcode, startingWell.plate_quadrant));
    } else {
      onClose();
    }
  };

  const submitWell = async () => {
    try {
      await dispatch(addWellToPlate(plate.barcode, well));
      resetForNextWell();
      if (barcodeInput && barcodeInput.current) {
        barcodeInput.current.focus();
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleOnValueChange = (
    attributeName: string,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => {
    setWell(
      (wellForm) =>
        ({
          ...wellForm,
          [attributeName]: newValue,
        }) as WellType,
    );
  };

  const handleFormChange = (idx: number) => {
    setEntryTypeIndex(idx);
    // Only keep attributes which persist between forms
    setWell((wellForm) =>
      createEmptyWell(wellForm.row, wellForm.column, plate.barcode, wellForm.plate_quadrant),
    );
    setIsFormValid(true);
    setErrorMessage('');
  };

  const entryTypeSelectorOptions = [
    {
      displayName: getString(ENTRY_TYPES.BARCODE, language),
      id: 0,
    },
    {
      displayName: getString(ENTRY_TYPES.CONTROL, language),
      id: 1,
    },
    ...(isAdmin
      ? [
          {
            displayName: getString(ENTRY_TYPES.NO_BARCODE, language),
            id: 2,
          },
        ]
      : []),
  ];

  return (
    <div className={styles.Wrapper}>
      <Container className={styles.InputColumn} vertical>
        <h1 className={styles.Centered}>
          {`${getString('awaitingWellInpuMsg', language)}: ${well.row.toUpperCase()}${well.column}`}
        </h1>
        <h5>
          {`${getPlateDisplayName(plate.plate_type, language)}: ${plate.barcode} (${
            plate.homogenization_plate_id
          })`}
        </h5>
        <div className={styles.Centered}>
          <TabbedController
            activeIndex={entryTypeIndex}
            className={styles.FormTypeSelector}
            onChange={handleFormChange}
            options={entryTypeSelectorOptions}
          />
        </div>
        <Container vertical>
          <Container>
            <Button
              dataTestId="save-plate"
              disabled={!well.sample_barcode?.trim() || !isFormValid}
              primary
              onClick={submitWell}
            >
              {getString('save', language)}
            </Button>
            <Button dataTestId="cancel-plate" onClick={onClose}>
              {getString('cancel', language)}
            </Button>
          </Container>
        </Container>
        {entryTypeIndex === 0 && (
          <ExistingSampleForm
            plate={plate}
            well={well}
            onValueChange={handleOnValueChange}
            setIsFormValid={setIsFormValid}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            barcodeRef={barcodeInput}
            submitForm={submitWell}
          />
        )}
        {entryTypeIndex === 1 && (
          <ControlSampleForm plate={plate} well={well} onValueChange={handleOnValueChange} />
        )}
        {entryTypeIndex === 2 && (
          <NewSampleForm
            well={well}
            onValueChange={handleOnValueChange}
            setIsFormValid={setIsFormValid}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            barcodeRef={barcodeInput}
          />
        )}
      </Container>
    </div>
  );
};
