import React from 'react';

import { Table, TableCol, TableRow } from 'common';
import { SamplePlanTransactionsType } from 'store/payments/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { userIsAdmin, userIsSampler, userIsSuperAdmin } from 'store/user/selectors';
import classNames from 'classnames';
import styles from './PaymentTables.module.css';
import ActivityDetailRow from './ActivityDetailRow';

type ActivityDetailTablePropsType = {
  transactions: {
    [id: number]: SamplePlanTransactionsType;
  };
  toggleModTransactions: (planId: number) => void;
  isSelectPayment?: boolean;
};

const ActivityDetailTable = ({
  transactions,
  toggleModTransactions,
  isSelectPayment,
}: ActivityDetailTablePropsType) => {
  const language = useBroswerLanguage();
  const isSampler = useSelector((state: RootState) => userIsSampler(state));
  const isAdminOrSuper = useSelector(
    (state: RootState) => userIsSuperAdmin(state) || userIsAdmin(state),
  );

  return (
    <Table>
      <TableRow header className={classNames(styles.TableHeader, styles.RowPadding)}>
        <TableCol>{getString('operation', language)}</TableCol>
        <TableCol>{getString('field', language)}</TableCol>
        <TableCol>{getString('acres', language)}</TableCol>
        <TableCol>{getString('samplePlan', language)}</TableCol>
        <TableCol>{getString('samples', language)}</TableCol>
        <TableCol>{getString('purchases', language)}</TableCol>
        <TableCol>{getString('earnings', language)}</TableCol>
        <TableCol>{getString('netPayment', language)}</TableCol>
        {isSelectPayment && <TableCol>{getString('inPayment', language)}</TableCol>}
      </TableRow>
      {Object.keys(transactions).map((idString) => {
        const samplePlan = transactions[Number(idString)];

        return (
          samplePlan && (
            <ActivityDetailRow
              key={samplePlan.sample_plan_id}
              samplePlan={samplePlan}
              isSampler={isSampler}
              isAdminOrSuper={isAdminOrSuper}
              toggleModTransactions={toggleModTransactions}
              isSelectPayment={isSelectPayment}
            />
          )
        );
      })}
    </Table>
  );
};

export default ActivityDetailTable;
