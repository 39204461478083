import React, { useMemo } from 'react';
import { Table } from 'common';
import JohnDeereLogo from 'images/connectAccount/johnDeere.png';
import FieldViewLogo from 'images/connectAccount/fieldView.png';
import CNHILogo from 'images/connectAccount/CNHi.png';
import AgLeader from 'images/connectAccount/agLeader.png';
import { OperationType } from 'store/operation/types';
import { AG_LEADER, CNHI, FIELDVIEW, JOHN_DEERE } from 'constants/connectAccounts';
import AccountsTableRow from './AccountsTableRow';

type FieldsTablePropsType = {
  loading: string | null;
  beginJDConnect: () => void;
  beginFVConnect: () => void;
  beginCNHIConnect: () => void;
  beginAgLeaderConnect: () => void;
  operation: OperationType;
};

const ConnectedAccountsTable = ({
  loading,
  beginJDConnect,
  beginFVConnect,
  beginCNHIConnect,
  beginAgLeaderConnect,
  operation,
}: FieldsTablePropsType) => {
  const accountOptions = useMemo(
    () => [
      {
        id: 0,
        beginConnect: beginJDConnect,
        accountType: JOHN_DEERE,
        accountLogo: JohnDeereLogo,
        authorized: operation.connectedAccounts?.john_deere_authorized,
      },
      {
        id: 1,
        beginConnect: beginFVConnect,
        accountType: FIELDVIEW,
        accountLogo: FieldViewLogo,
        authorized: operation.connectedAccounts?.fieldview_authorized,
      },
      {
        id: 2,
        beginConnect: beginCNHIConnect,
        accountType: CNHI,
        accountLogo: CNHILogo,
        authorized: operation.connectedAccounts?.cnhi_authorized,
      },
      {
        id: 3,
        beginConnect: beginAgLeaderConnect,
        accountType: AG_LEADER,
        accountLogo: AgLeader,
        authorized: operation.connectedAccounts?.agleader_authorized,
      },
    ],
    [operation, beginJDConnect, beginFVConnect, beginCNHIConnect, beginAgLeaderConnect],
  );

  return (
    <Table>
      {accountOptions.map((account) => (
        <AccountsTableRow
          accountType={account.accountType}
          accountLogo={account.accountLogo}
          beginConnect={account.beginConnect}
          authorized={account.authorized}
          operation={operation}
          loading={loading}
          key={account.id}
        />
      ))}
    </Table>
  );
};

export default ConnectedAccountsTable;
