import { PlateType } from 'store/plates/types';
import { ShippingLabel } from 'store/shipping/types';

export type LabShipment = {
  group_id: string;
  group_created_at: string;
  ship_from_lab: string;
  ship_to_lab: string;
  is_regulated: boolean;
  nutrient_sample_count?: number | null;
  genomic_sample_count?: number;
  genomic_plate_count?: number;

  total_labels_count: number;
  shipped_count: number;
  delivered_count: number;

  shipping_labels: ShippingLabel[];
  plates: PlateType[];
};

export type LabShipmentFormType = {
  group_id?: string;

  is_regulated?: boolean;
  nutrient_sample_count?: number | null;

  shipping_labels?: ShippingLabel[];
  plates?: PlateType[];

  plate_barcodes?: string[];
  new_tracking_numbers?: string[];
};

export type LabShipmentStateType = {
  paginated: {
    page: number;
    per_page: number;
    total: number;
    items: LabShipment[];
  };
  byId: {
    [group_id: string]: LabShipment;
  };
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const REQUEST_LAB_SHIPMENT = 'REQUEST_LAB_SHIPMENT';
export const RECEIVE_LAB_SHIPMENTS = 'RECEIVE_LAB_SHIPMENTS';
export const RECEIVE_LAB_SHIPMENT = 'RECEIVE_LAB_SHIPMENT';
export const LAB_SHIPMENT_REQUEST_ERROR = 'SHIPPING_REQUEST_ERROR';

type RequestLabShipmentActionType = {
  type: typeof REQUEST_LAB_SHIPMENT;
};

type RecieveLabShipmentsActionType = {
  type: typeof RECEIVE_LAB_SHIPMENTS;
  payload: {
    page: number;
    per_page: number;
    total: number;
    items: LabShipment[];
  };
};

type RecieveLabShipmentActionType = {
  type: typeof RECEIVE_LAB_SHIPMENT;
  payload: {
    id: string;
    shipment: LabShipment;
  };
};

type LabShipmentRequestErrorType = {
  type: typeof LAB_SHIPMENT_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type LabShipmentActionType =
  | RequestLabShipmentActionType
  | RecieveLabShipmentActionType
  | RecieveLabShipmentsActionType
  | LabShipmentRequestErrorType;
