import { convertToHeatMapAnalyticsType } from 'util/heatMap';
import {
  HEAT_MAP_REQUEST_ERROR,
  HeatMapActionsType,
  HeatMapStateType,
  RECEIVE_HEAT_MAP,
  REQUEST_HEAT_MAP,
  RECEIVE_AGENCY_HEAT_MAP,
} from './types';

const initialState: HeatMapStateType = {
  heatMap: [],
  byAgencyId: {},
  isFetching: false,
  hasFetched: false,
  hasFailed: false,
};

export const heatMapReducer = (state = initialState, action: HeatMapActionsType) => {
  switch (action.type) {
    case REQUEST_HEAT_MAP:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
        hasFailed: false,
      };
    case RECEIVE_HEAT_MAP:
      return {
        ...state,
        heatMap: convertToHeatMapAnalyticsType(action.payload.heatMap),
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
      };
    case RECEIVE_AGENCY_HEAT_MAP:
      return {
        ...state,
        byAgencyId: {
          ...state.byAgencyId,
          [action.payload.agencyId]: convertToHeatMapAnalyticsType(action.payload.heatMap),
        },
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
      };
    case HEAT_MAP_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};
