import showToast from 'actions/toastActions';
import { AppThunk } from 'store';
import { getString } from 'strings/translation';
import { collectionRequestError, receiveEOCollections, requestEOCollections } from './actions';
import { deleteCollection, getCollections, requestConfirmCollections } from './requests';
import { CollectionConfirmationPayloadType } from './types';

const fetchPaginatedCollections = async (nextPage: number | null = 1, fields = []) => {
  if (!nextPage) {
    return { fields };
  }
  const collections = await getCollections(nextPage);
  if (!collections.fields?.length) {
    return { fields };
  }
  return fetchPaginatedCollections(collections.next_num, fields.concat(collections.fields));
};

export const fetchCollections = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestEOCollections);
    const collections = await fetchPaginatedCollections();
    dispatch(receiveEOCollections(collections));
  } catch (e) {
    dispatch(collectionRequestError);
  }
};

export const disableCollection =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestEOCollections);
      await deleteCollection(id);
      const collections = await fetchPaginatedCollections();
      dispatch(receiveEOCollections(collections));
    } catch (e) {
      dispatch(collectionRequestError);
    }
  };

export const confirmCollectionAssignment =
  (body: CollectionConfirmationPayloadType, language: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestEOCollections);
      await requestConfirmCollections(body);

      showToast(getString('dataConfirmationSuccess', language), 'success', undefined, {
        title: `${getString('success', language)}!`,
      });

      const collections = await fetchPaginatedCollections();
      dispatch(receiveEOCollections(collections));
    } catch (e) {
      showToast(e.message, 'error');
    }
  };
