import { AppThunk } from 'store';
import {
  receiveRecommendations,
  receiveSamplePlanRecommendations,
  recommendationsRequestError,
  requestRecommendations,
} from './actions';
import {
  requestDeleteRecommendation,
  requestUpdateRecommendation,
  requestPastUserRecommmendations,
  requestPutSamplePlanRecommendations,
  requestGetSamplingPlanRecommendations,
} from './requests';
import { RecommendationType } from './types';

export const getRecommmendations = (): AppThunk => async (dispatch) => {
  dispatch(requestRecommendations);
  try {
    const response = await requestPastUserRecommmendations();
    return dispatch(receiveRecommendations(response.recommendations));
  } catch (e) {
    return dispatch(recommendationsRequestError);
  }
};

export const editPlanRecommendation =
  (recommendationId: number, body: Partial<RecommendationType>): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendations());
    try {
      await requestUpdateRecommendation(body, recommendationId);
      dispatch(getRecommmendations);
    } catch (e) {
      throw dispatch(recommendationsRequestError);
    }
  };

export const hidePastRecommendation =
  (recommendationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendations());
    try {
      await requestDeleteRecommendation(recommendationId);
      dispatch(getRecommmendations);
    } catch (e) {
      throw dispatch(recommendationsRequestError);
    }
  };

export const getSamplePlanRecommendations =
  (planId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendations());
    try {
      const response = await requestGetSamplingPlanRecommendations(planId);
      dispatch(receiveSamplePlanRecommendations(planId, response.recommendations));
    } catch (e) {
      dispatch(recommendationsRequestError());
    }
  };

export const addRecommendationToSamplePlan =
  (recommendationId: number, planId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestRecommendations());
    try {
      await requestPutSamplePlanRecommendations(recommendationId, planId);
      dispatch(getRecommmendations);
      dispatch(getSamplePlanRecommendations(planId));
    } catch (e) {
      dispatch(recommendationsRequestError());
    }
  };
