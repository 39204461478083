import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { SortableTable, Spinner } from 'common';
import { PlateUploadedFileType } from 'store/plates/types';
import { getString } from 'strings/translation';
import { formatToDateHourFormat } from 'util/date';
import { BatchUploadedFileType } from 'store/batches/types';

import styles from './FilesTable.module.css';

type UploadFileType = PlateUploadedFileType | BatchUploadedFileType;

type FilesTableContentPropsType = {
  files?: UploadFileType[];
};

export const FilesTableContent = ({ files }: FilesTableContentPropsType) => {
  const language = useBroswerLanguage();

  const onDownload = (file: UploadFileType) => window.open(file.download_url);

  if (!files?.length) {
    return (
      <div className={styles.NoFiles}>
        {getString('noFilesListMsg', language)}
        {files === undefined && <Spinner className={styles.Spinner} />}
      </div>
    );
  }

  const tableColumns = [
    {
      name: getString('filename', language),
      attribute: 'file_name',
      sortable: true,
    },
    {
      name: getString('createdAt', language),
      attribute: 'created_at',
      sortable: true,
      cellFormatter: formatToDateHourFormat,
    },
  ];

  const showNotes = files.some(
    (f) => f.hasOwnProperty('notes') || f.hasOwnProperty('plate_barcode'),
  );
  if (showNotes) {
    const noteColumn = {
      name: getString('notes', language),
      attribute: 'notes',
      sortable: false,
    };
    tableColumns.splice(1, 0, noteColumn);
  }

  return (
    <SortableTable
      title=""
      data={files}
      columns={tableColumns}
      initialSortBy="created_at"
      onRowClick={onDownload}
    />
  );
};
