export const RECEIVE_ANALYTICS = 'RECEIVE_ANALYTICS';
export const RECEIVE_SINGLE_ANALYTIC = 'RECEIVE_SINGLE_ANALYTIC';
export const REQUEST_ANALYTICS = 'REQUEST_ANALYTICS';
export const RECEIVE_ALL_ANALYTICS = 'RECEIVE_ALL_ANALYTICS';
export const RECEIVE_ANALYTIC_CATEGORIES = 'RECEIVE_ANALYTIC_CATEGORIES';
export const RECEIVE_ANALYTIC_SORT_ORDER = 'RECEIVE_ANALYTIC_SORT_ORDER';
export const REQUEST_ANALYTICS_ERROR = 'REQUEST_ANALYTICS_ERROR';
export const RECEIVE_BENCHMARKS = 'RECEIVE_BENCHMARKS';

export type TaxonomyType = {
  clazz: string;
  family: string;
  genus: string;
  id: number;
  internal_taxid: number;
  kingdom: string;
  name: string;
  order: string;
  phylum: string;
  rank: string;
  root: string;
  source: string;
  species: string;
  superkingdom: string;
};

export type AnalyticsFieldType = {
  [category: string]: GroupAnalyticsType;
};

export type GroupAnalyticsType = {
  [analytic_id: number]: SingleAnalyticType;
};

export type AnalyticBucket = {
  category: string;
  analytics: AnalyticType[];
};

export type RiskRangesType = {
  low: number[][] | undefined;
  moderate: number[][] | undefined;
  high: number[][] | undefined;
};

export type DataRangeType = {
  low: number[];
  moderate: number[];
  high: number[];
};

export type SingleAnalyticType = {
  quantity: number;
  benchmark_set: string | null;
  region: string | null;
  unit: string;
  risk_level: 'high' | 'moderate' | 'low';
  name?: string;
  category?: string;
  id?: number;
  data_summary: DataRangeType | null;
  percent_compaction_above_300_psi?: number;
  max_depth_300_psi: number | null;
  min_depth_300_psi: number | null;
  risk_summary: {
    low: string;
    moderate: string;
    high: string;
    risk_ranges: RiskRangesType;
    risk_level_order:
      | 'binary'
      | 'binary_reverse'
      | 'low_moderate_high'
      | 'high_moderate_low'
      | 'low_moderate'
      | 'moderate_low'
      | 'moderate_low_moderate'
      | undefined;
  };
  compaction_stratification?: {
    average_compaction_0_3: {
      quantity: number;
      risk_level: string;
    };
    average_compaction_4_6: {
      quantity: number;
      risk_level: string;
    };
    average_compaction_7_9: {
      quantity: number;
      risk_level: string;
    };
    average_compaction_0_18: {
      quantity: number;
      risk_level: string;
    };
    average_compaction_10_12: {
      quantity: number;
      risk_level: string;
    };
    average_compaction_13_18: {
      quantity: number;
      risk_level: string;
    };
  };
};

export type CompactionSearchKey =
  | keyof NonNullable<SingleAnalyticType['compaction_stratification']>
  | 'percent_compaction_above_300_psi'
  | 'base';

export type AnalyticWithChartKeys = AnalyticType & {
  searchKey?: CompactionSearchKey;
  tooltipName?: string;
};

export type AnalyticImageType = {
  id?: number;
  url: string;
  alt_text?: string;
  source: string;
  upload_url: string;
};

export interface ReferencesResourcesInterface {
  description: string;
  source: string;
  reference_link: string;
}

export type AnalyticCropType = { id?: number; crop: string; sort_order?: number };

export type AnalyticDisplayJson = {
  is_field_nd: boolean;
  is_overview_nd: boolean;
  is_sample_nd: boolean;
  countries: string[];
  secondary_analytic_id: number | null;
};

export type AnalyticType = {
  name: string;
  untranslated_name?: string;
  id: number;
  category: string;
  created_at: string | null;
  updated_at: string | null;
  show_in_ui: boolean;
  description: string | null;
  benchmarks: number[];
  kegg_orthologs: any[];
  environment_and_weather_notes: string | null;
  potential_yield_impact_notes: string | null;
  taxonomies: TaxonomyType[];
  images: AnalyticImageType[];
  references_resources?: ReferencesResourcesInterface[];
  subCategory?: string;
  crops: AnalyticCropType[];
  display_config: Partial<AnalyticDisplayJson>;
};

export type AnalyticSortOrderType = {
  [category: string]: {
    [crop: string]: number[];
  };
};

export interface AnalyticsState {
  analytics: AnalyticType[];
  allAnalytics: AnalyticType[];
  analyticCategories: string[];
  byId: {
    [id: number]: AnalyticType;
  };
  midwestBenchmarksById: GroupAnalyticsType;
  analyticSortOrder: AnalyticSortOrderType;
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
}

interface RequestAnalyticsAction {
  type: typeof REQUEST_ANALYTICS;
}

export interface ReceiveAnalyticsAction {
  type: typeof RECEIVE_ANALYTICS;
  payload: {
    analytics: AnalyticType[];
  };
}

export interface ReceiveAllAnalyticsAction {
  type: typeof RECEIVE_ALL_ANALYTICS;
  payload: {
    analytics: AnalyticType[];
  };
}

export interface ReceiveAnalyticCategoriesAction {
  type: typeof RECEIVE_ANALYTIC_CATEGORIES;
  payload: {
    analytic_categories: string[];
  };
}

export interface ReceiveAnalyticSortOrderAction {
  type: typeof RECEIVE_ANALYTIC_SORT_ORDER;
  payload: {
    [category: string]: {
      [crop: string]: number[];
    };
  };
}

export interface ReceiveSingleAnalyticAction {
  type: typeof RECEIVE_SINGLE_ANALYTIC;
  payload: {
    analytic: AnalyticType;
  };
}

export interface ReceiveBenchmarksAction {
  type: typeof RECEIVE_BENCHMARKS;
  payload: {
    benchmarks: GroupAnalyticsType;
  };
}

interface AnalyticsRequestError {
  type: typeof REQUEST_ANALYTICS_ERROR;
  payload: {
    message: string;
  };
}

export type AnalyticsActionType =
  | ReceiveSingleAnalyticAction
  | ReceiveAnalyticCategoriesAction
  | ReceiveAnalyticsAction
  | ReceiveAllAnalyticsAction
  | RequestAnalyticsAction
  | ReceiveBenchmarksAction
  | AnalyticsRequestError
  | ReceiveAnalyticSortOrderAction;
