import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnalyticType } from 'store/analytics/types';
import { getAllAnalytics } from 'store/analytics/thunks';
import { RootState } from 'store';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Group, Loader, Text, Table, Stack, Button, Input, Tabs } from '@mantine/core';
import { getString } from 'strings/translation';
import { FiSearch } from 'react-icons/fi';
import AnalyticOrder from './AnalyticOrder';
import { ALL, SORT_ORDER } from 'constants/variables';

const AnalyticsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef<HTMLInputElement>(null);
  const language = useBroswerLanguage();
  const [analyticDepartment, setAnalyticDepartment] = useState<string | null>(ALL);

  const { allAnalytics, hasFailed, hasFetched, isFetching } = useSelector((state: RootState) => ({
    allAnalytics: state.analytics.allAnalytics,
    hasFailed: state.analytics.hasFailed,
    hasFetched: state.analytics.hasFetched,
    isFetching: state.analytics.isFetching,
  }));

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getAllAnalytics());
  }, []);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const filter = (analyticsList: AnalyticType[], searchName: string) =>
    analyticsList.filter(
      (d) =>
        d.untranslated_name && d.untranslated_name.toLowerCase().includes(searchName.toLowerCase()),
    );

  const sortedAnalytics = search === '' ? allAnalytics : filter([...allAnalytics], search);

  return (
    <Stack gap="lg">
      <Group justify="space-between">
        <Text size="xl" fw={600}>
          {getString('manageAnalytics', language)}
        </Text>
        <Group>
          {isFetching && <Loader size="sm" />}
          <Button onClick={() => navigate('/product/analytics/create')}>
            {getString('newAnalytic', language)}
          </Button>
          <Input
            leftSection={<FiSearch />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            ref={searchRef}
          />
        </Group>
      </Group>
      <Tabs
        color="blue"
        defaultValue={ALL}
        onChange={setAnalyticDepartment}
        value={analyticDepartment}
      >
        <Tabs.List>
          <Tabs.Tab value={ALL}>{getString('analytics', language)}</Tabs.Tab>
          <Tabs.Tab value={SORT_ORDER}>{getString('displayOrder', language)}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
      {analyticDepartment === SORT_ORDER && <AnalyticOrder />}
      {analyticDepartment === ALL && (
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w="20rem">{getString('name', language)}</Table.Th>
              <Table.Th w="20rem">{getString('category', language)}</Table.Th>
              <Table.Th w="20rem">{getString('showInUI', language)}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {hasFetched && !hasFailed && (
            <Table.Tbody>
              {sortedAnalytics.map((d: AnalyticType) => (
                <Table.Tr onClick={() => navigate(`/product/analytics/${d.id}`)} key={d.id}>
                  <Table.Td>{d.untranslated_name}</Table.Td>
                  <Table.Td>{d.category}</Table.Td>
                  <Table.Td>{String(d.show_in_ui)}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          )}
        </Table>
      )}
    </Stack>
  );
};

export default AnalyticsContainer;
