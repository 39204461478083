import {
  RECEIVE_CONVERSION,
  REQUEST_CONVERSION,
  CONVERSION_REQUEST_ERROR,
  ConversionActionTypes,
} from './types';

export const receiveConversion = (payload: number): ConversionActionTypes => ({
  type: RECEIVE_CONVERSION,
  payload,
});

export const requestConversion = { type: REQUEST_CONVERSION };

export const conversionRequestError = { type: CONVERSION_REQUEST_ERROR };
