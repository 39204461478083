import React, { useEffect, useState } from 'react';

import { Container, Header, Table, TableRow, TableCol } from 'common';

import ColumnSorter from './SortedTableColumnSorter';
import styles from './SortedTable.module.css';

type ArrayOfObjects = Array<{ [key: string]: any }>;
type OrderType = 'asc' | 'desc';
type ComparatorType = (a: any, b: any, sortingOrder: OrderType) => number;
type SortableTablePropsType = {
  columns: Array<{
    name: string;
    attribute: string;
    sortable: boolean;
    comparator?: ComparatorType;
    cellFormatter?: (value: any, dataRow?: any) => any;
  }>;
  data: ArrayOfObjects;
  title?: string;
  initialSortBy: string;
  onRowClick?: (value: any) => void;
};

const SortableTable = ({
  data = [],
  title,
  columns = [],
  initialSortBy,
  onRowClick,
}: SortableTablePropsType) => {
  const [sortedData, setSortedData] = useState(data);
  const [sorting, setSorting] = useState({
    attribute: initialSortBy,
    order: 'desc',
  } as { attribute: string; order: OrderType });
  const defaultComparator: ComparatorType = (a, b, sort) => {
    if (sort === 'asc') {
      return a > b ? -1 : 1;
    }
    return a > b ? 1 : -1;
  };

  useEffect(() => {
    const columnByAttribute = columns.find((c) => c.attribute === sorting.attribute) || null;
    const comparator =
      columnByAttribute && columnByAttribute.comparator
        ? columnByAttribute.comparator
        : defaultComparator;
    const comparatorWithOrder = (a: any, b: any) =>
      comparator(a[sorting.attribute], b[sorting.attribute], sorting.order);
    setSortedData([...data].sort(comparatorWithOrder));
  }, [sorting, data, columns]);

  return (
    <Container vertical dataTestId="source-plates-table">
      {title && <Header title={title} underline="none" size="H2" />}
      <Table>
        <TableRow header>
          {columns.map((column, colidx) => (
            <TableCol className={styles.TableCol} key={colidx}>
              {column.sortable ? (
                <ColumnSorter
                  sorting={sorting}
                  setSorting={setSorting}
                  attribute={column.attribute}
                >
                  {column.name}
                </ColumnSorter>
              ) : (
                column.name
              )}
            </TableCol>
          ))}
        </TableRow>
        {sortedData.map((dataRow, idx) => (
          <TableRow onClick={() => onRowClick && onRowClick(dataRow)} key={idx}>
            {columns.map(({ attribute, cellFormatter }, colIdx) => (
              <TableCol className={styles.TableCol} key={`${idx}_${colIdx}`}>
                {cellFormatter && (dataRow[attribute] || dataRow[attribute] === 0)
                  ? cellFormatter(dataRow[attribute], dataRow)
                  : dataRow[attribute]}
              </TableCol>
            ))}
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};

export default SortableTable;
