import { AppThunk } from 'store';
import showToast from 'actions/toastActions';
import { heatMapRequestError, receiveHeatMap, requestHeatMap } from './actions';
import { requestGetAnalyticsHeatmap } from './requests';

export const getHeatMapForUser =
  (
    agencyId?: number,
    state?: string,
    cropYear?: number,
    demoAccount?: number,
    all: boolean = true,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestHeatMap());
      const response = await requestGetAnalyticsHeatmap({
        all: demoAccount ? false : all,
        agencyId,
        state,
        cropYear,
        operationId: demoAccount,
      });
      dispatch(receiveHeatMap(response.sampling_plans));
    } catch (e) {
      showToast(e.message, 'error');
      dispatch(heatMapRequestError());
    }
  };
