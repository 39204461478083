import { useState } from 'react';
import { ENGLISH, ENGLISH_CODES, PORTUGUESE, PORTUGUESE_CODES } from 'constants/languages';

const useBroswerLanguage = () => {
  const [language, setLanguage] = useState<string | null>(null);

  if (language) {
    return language;
  }
  const onlyEnPt = navigator.languages.filter(
    (lang) => ENGLISH_CODES.includes(lang) || PORTUGUESE_CODES.includes(lang),
  );
  if (!onlyEnPt.length || ENGLISH_CODES.includes(onlyEnPt[0])) {
    setLanguage(ENGLISH);
    return ENGLISH;
  }
  setLanguage(PORTUGUESE);
  return PORTUGUESE;
};

export const getBrowserLanguage = (langugage: string | undefined) => {
  if (langugage && PORTUGUESE_CODES.includes(langugage)) {
    return PORTUGUESE;
  }
  return ENGLISH;
};

export default useBroswerLanguage;
