import React, { useState } from 'react';

import { TableRow, TableCol, Checkbox } from 'common';
import { capitalizeEveryWord, numsToInvoiceDollars } from 'util/stringUtils';
import { SamplePlanTransactionsType } from 'store/payments/types';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import classNames from 'classnames';
import { SAMPLING_FEE_KEY } from 'constants/products';
import styles from './PaymentTables.module.css';
import ActivityDetailRowExpanded from './ActivityDetailRowExpanded';

type ActivityDetailRowPropTypes = {
  isSampler: boolean;
  isAdminOrSuper: boolean;
  samplePlan: SamplePlanTransactionsType;
  toggleModTransactions: (planId: number) => void;
  isSelectPayment?: boolean;
};

const ActivityDetailRow = ({
  samplePlan,
  isSampler,
  isAdminOrSuper,
  toggleModTransactions,
  isSelectPayment,
}: ActivityDetailRowPropTypes) => {
  const [showDetails, toggleShowDetails] = useState(false);

  const getCommissions = () => {
    if (isSampler && samplePlan.sampling) {
      return numsToInvoiceDollars(samplePlan.sampling);
    }
    return !isSampler && samplePlan.commissions
      ? numsToInvoiceDollars(samplePlan.commissions)
      : numsToInvoiceDollars(0);
  };

  const getSamplesVsPaid = () => {
    if (samplePlan?.num_of_samples && isAdminOrSuper) {
      return `${samplePlan.price_summary[SAMPLING_FEE_KEY].num_zones} /
      ${samplePlan?.num_of_samples}`;
    }

    return samplePlan?.num_of_samples || NOT_APPLICABLE;
  };

  const isNewFinancialTransaction = samplePlan.price_summary.sampling_fee.num_zones > 0;

  return (
    <>
      <TableRow
        onClick={() => !isSampler && toggleShowDetails(!showDetails)}
        className={classNames(
          !isSampler && isNewFinancialTransaction && styles.HoverClick,
          styles.RowPadding,
        )}
      >
        <TableCol className={styles.TransactionTitleColumn}>
          {samplePlan?.operation ? capitalizeEveryWord(samplePlan.operation) : NOT_APPLICABLE}
        </TableCol>
        <TableCol className={styles.TransactionTitleColumn}>
          {samplePlan?.field_name ? capitalizeEveryWord(samplePlan.field_name) : NOT_APPLICABLE}
        </TableCol>
        <TableCol className={styles.TransactionTitleColumn}>
          {samplePlan?.plan_acres ? Math.round(samplePlan.plan_acres) : NOT_APPLICABLE}
        </TableCol>
        <TableCol>{samplePlan?.sample_plan_name || NOT_APPLICABLE}</TableCol>
        <TableCol>{getSamplesVsPaid()}</TableCol>
        <TableCol>
          {samplePlan?.collections
            ? numsToInvoiceDollars(samplePlan.collections * -1)
            : numsToInvoiceDollars(0)}
        </TableCol>
        <TableCol>{getCommissions()}</TableCol>
        <TableCol>
          {samplePlan?.balance ? numsToInvoiceDollars(samplePlan.balance) : numsToInvoiceDollars(0)}
        </TableCol>
        {isSelectPayment && (
          <TableCol>
            {isAdminOrSuper && (
              <Checkbox
                checked={samplePlan?.inPayment || false}
                onChange={() => toggleModTransactions(samplePlan.sample_plan_id)}
              />
            )}
          </TableCol>
        )}
      </TableRow>
      {showDetails && isNewFinancialTransaction && (
        <ActivityDetailRowExpanded samplePlan={samplePlan} />
      )}
    </>
  );
};

export default ActivityDetailRow;
