import {
  InputType,
  InputsActionType,
  RECEIVE_INPUTS,
  REQUEST_INPUTS,
  REQUEST_INPUTS_ERROR,
} from './types';

export const receiveInputs = (inputs: InputType[]): InputsActionType => ({
  type: RECEIVE_INPUTS,
  payload: {
    inputs,
  },
});

export const requestInputs = { type: REQUEST_INPUTS };

export const requestInputsError = {
  type: REQUEST_INPUTS_ERROR,
};
