import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { RecommendationType } from './types';

export const requestPastUserRecommmendations = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/recommendations/`));

export const requestUpdateRecommendation = async (body: Partial<RecommendationType>, id: number) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/recommendation/${id}`, {
      body,
    }),
  );

export const requestDeleteRecommendation = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/recommendation/${id}`));

export const requestCreateDefaultRecommendation = async (body: Partial<RecommendationType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/default_recommendation/`, { body }));

export const requestUpdateDefaultRecommendation = async (
  body: Partial<RecommendationType>,
  id: number,
) => handleJsonResponse(await requestPut(`${SERVICE_URL}/default_recommendation/${id}`, { body }));

export const requestGetSamplingPlanRecommendations = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/sampling_plan_recommendations/${id}`));

export const requestPutSamplePlanRecommendations = async (
  recommendationId: number,
  planId: number,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/sampling_plan_recommendation/${planId}`, {
      body: {
        recommendation_id: recommendationId,
      },
    }),
  );
