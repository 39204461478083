import React, { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Input.module.css';

export type InputProps = {
  autoComplete?: string;
  wrapperClassName?: string;
  className?: string;
  name?: string;
  round?: boolean;
  dataTestId?: string;
  units?: string;
  type?: string;
  value?: string;
  disabled?: boolean;
  error?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, round, error, dataTestId, type, ...props }, ref) => {
    className = classNames(
      styles.Input,
      { [styles.Round]: round, [styles.Error]: error },
      className,
    );

    return (
      <input ref={ref} className={className} data-test-id={dataTestId} type={type} {...props} />
    );
  },
);

Input.displayName = 'Input';

export const InputWithUnits = React.forwardRef<HTMLInputElement, InputProps>(
  ({ wrapperClassName, className, round, dataTestId, type, units, ...props }, ref) => {
    className = classNames(styles.Input, { [styles.Round]: round }, className);

    return (
      <div className={classNames(styles.UnitsWrapper, wrapperClassName)}>
        <input ref={ref} className={className} data-test-id={dataTestId} type={type} {...props} />
        <span className={styles.UnitsInInput}>{units}</span>
      </div>
    );
  },
);

InputWithUnits.displayName = 'InputWithUnits';

type TextareaProps = {
  className?: string;
  disabled?: boolean;
  round?: boolean;
  dataTestId?: string;
  value?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, round, dataTestId, ...props }, ref) => {
    className = classNames(styles.Textarea, { [styles.Round]: round }, className);

    return <textarea ref={ref} className={className} data-test-id={dataTestId} {...props} />;
  },
);

Textarea.displayName = 'Textarea';
