import React, { FunctionComponent } from 'react';
import styles from './Banner.module.css';

type BannerPropsType = {
  headingText: string;
  bodyText?: string;
};

const Banner: FunctionComponent<BannerPropsType> = ({ headingText, bodyText }) => {
  return (
    <div className={styles.BannerWrapper}>
      <div className={styles.Row}>
        <div className={styles.BannerHeader}>
          <div className={styles.BannerHeadline}>
            <p>{headingText}</p>
            <p>{bodyText || ''}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
