import React from 'react';
import { Flex, Stack, Text } from '@mantine/core';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import { AWAITING_RESULTS, NOT_ANALYZED, NO_DATA } from 'constants/fieldRisks';
import { SingleAnalyticType } from 'store/analytics/types';
import { FieldType } from 'store/fields/types';
import { RecommendationType } from 'store/recommendations/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getCompactionMeta } from 'util/proMaps';
import styles from './Summary.module.css';

type SummaryPropsType = {
  field: FieldType;
  planAnalytic: SingleAnalyticType;
  recommendation: RecommendationType | undefined;
  risk: string;
  toggleEditRecommendation: (arg0: boolean) => void;
};

const CompactionSummary = ({
  planAnalytic,
  recommendation,
  risk,
  toggleEditRecommendation,
  field,
}: SummaryPropsType) => {
  const language = useBroswerLanguage();

  const { score, average, compaction } = getCompactionMeta(
    language,
    planAnalytic,
    field.features[0].properties,
  );

  return (
    <Stack>
      <Flex
        columnGap="md"
        display="grid"
        rowGap="xs"
        style={{
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Text fw="bold">{score.label}:</Text>
        <Text>{score.value}</Text>
        <Text fw="bold">{average.label}:</Text>
        <Text>{average.value}</Text>
        <Text fw="bold">{compaction.label}:</Text>
        <Text>{compaction.value}</Text>
      </Flex>
      <div>
        <Text fw="bold">{getString('recommendation', language)}</Text>
        <Text
          className={styles.Clickable}
          onClick={() =>
            toggleEditRecommendation(![NOT_ANALYZED, NO_DATA, AWAITING_RESULTS].includes(risk))
          }
        >
          {recommendation?.recommendation || (risk === AWAITING_RESULTS ? 'TBD' : NOT_APPLICABLE)}
        </Text>
      </div>
    </Stack>
  );
};

export default CompactionSummary;
