import React from 'react';
import { Header } from 'common';
import styles from './UserAgreements.module.css';
import UserAgreementLinks from 'apps/Operations/Common/UserAgreementLinks';
import { UserRole } from 'store/user/types';

interface Props {
  role: UserRole;
}

const UserAgreements = ({ role }: Props) => {
  return (
    <div className={styles.Wrapper}>
      <Header underline="thin" size="H2" title="User Agreements" />
      <UserAgreementLinks role={role} />
    </div>
  );
};

export default UserAgreements;
