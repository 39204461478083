import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './ColumnSorter.module.css';

export type ColumnSorterPropsType = {
  sorting: {
    attribute: string;
    order: string;
  };
  setSorting: (arg0: { attribute: string; order: string }) => void;
  attribute: string;
  children: ReactNode;
  className?: string;
};

export const ColumnSorter = ({
  sorting,
  setSorting,
  attribute,
  children,
  className,
}: ColumnSorterPropsType) => {
  const isActive = attribute === sorting.attribute;
  return (
    <div className={classNames(styles.SortedColumn, className)}>
      <span>{children}</span>
      <div
        onClick={() => {
          if (isActive) {
            setSorting({
              attribute,
              order: sorting.order === 'desc' ? 'asc' : 'desc',
            });
          } else {
            setSorting({
              attribute,
              order: 'desc',
            });
          }
        }}
        className={classNames(styles.SortingArrow, {
          [styles.Active]: isActive,
        })}
      >
        {isActive && (sorting.order === 'asc' ? <span>&darr;</span> : <span>&uarr;</span>)}
        {!isActive && <span>&darr;</span>}
      </div>
    </div>
  );
};
