import React, { useEffect } from 'react';
import { Spinner } from 'common';
import { useAuth } from 'Auth';

const LogoutContainer = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  }, []);

  return <Spinner fill />;
};

export default LogoutContainer;
