import { User } from 'store/user/types';

export const PAYMENTS_REQUEST_ERROR = 'PAYMENTS_REQUEST_ERROR';
export const RECEIVE_OPEN_TRANSACTIONS = 'RECEIVE_OPEN_TRANSACTIONS';
export const RECEIVE_OPEN_TRANSACTION = 'RECEIVE_OPEN_TRANSACTION';
export const RECEIVE_APPROVED_PAYMENTS = 'RECEIVE_APPROVED_PAYMENTS';
export const RECEIVE_APPROVED_PAYMENT = 'RECEIVE_APPROVED_PAYMENT';
export const REQUEST_PAYMENTS = 'REQUEST_PAYMENTS';

export type SummaryType = {
  collections: number | null;
  commissions: number | null;
  adjustments: number | null;
  earliest_sampled_date: string;
  sampling: number | null;
  balance: number;
};

export type SamplePlanTransactionsType = {
  sample_plan_name: string | null;
  sample_plan_id: number;
  field_id: number;
  field_name: string;
  operation: string;
  collections: number | null;
  commissions: number | null;
  sampling: number | null;
  adjustments: number | null;
  balance: number;
  num_of_samples: number | null;
  plan_acres: number | null;
  price_summary: {
    [product: string]: {
      num_zones: number;
      collection: number;
      commission: number;
      total: number;
    };
  };
  inPayment?: boolean;
};

export type AdjustmentsType = {
  trigger?: string;
  created_at?: string;
  transaction_type: 'credit' | 'debit';
  sample?: string | null;
  created_by_id?: number;
  updated_at?: string | null;
  id?: number;
  user_id: number;
  payment_uuid?: string;
  processed_at?: string | null;
  sample_uuid?: string | null;
  description: string;
  amount: number;
  transaction_uuid?: string;
};

export type AdjustmentsSimpleType = {
  id?: number;
  description: string | null;
  amount: number;
  created_by: string;
  transaction_type?: 'credit' | 'debit';
};

export type TransactionsList = {
  summary: SummaryType;
  transactions_by_sample_plan: {
    [sample_plan_id: number]: SamplePlanTransactionsType;
  };
  adjustments: AdjustmentsSimpleType[];
};

export type ApprovedPaymentsType = {
  adjustments: AdjustmentsType[];
  transactions: TransactionsList;
  user: User;
  payment_type: 'invoice' | 'payment_due';
  id: number;
  approved_by: number;
  approved_by_user_name: string;
  amount: number;
  status: 'approved' | 'settled';
  payment_uuid: string;
  user_id: number;
  settled_at: string | null;
  created_at: string;
  settled_by: number | null;
  settled_by_user_name: string | null;
  billing_party_name: string;
};

export type OpenTransactionsType = User & {
  billing_party_name: string;
  open_transactions: TransactionsList;
};

export interface PaymentsState {
  approvedById: {
    [uuid: string]: ApprovedPaymentsType;
  };
  approved: ApprovedPaymentsType[];
  open: OpenTransactionsType[];
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
}

type RequestPaymentsType = {
  type: typeof REQUEST_PAYMENTS;
};

type ReceiveApprovedPaymentType = {
  type: typeof RECEIVE_APPROVED_PAYMENT;
  payload: {
    [key: string]: any;
  };
};

type ReceiveApprovedPaymentsType = {
  type: typeof RECEIVE_APPROVED_PAYMENTS;
  payload: {
    [key: string]: any;
  };
};

type ReceiveOpenTransactionType = {
  type: typeof RECEIVE_OPEN_TRANSACTION;
  payload: {
    [key: string]: any;
  };
};

type ReceiveOpenTransactionsType = {
  type: typeof RECEIVE_OPEN_TRANSACTIONS;
  payload: {
    [key: string]: any;
  };
};

type PaymentsRequestError = {
  type: typeof PAYMENTS_REQUEST_ERROR;
};

export type PaymentsActionType =
  | RequestPaymentsType
  | ReceiveApprovedPaymentType
  | ReceiveApprovedPaymentsType
  | ReceiveOpenTransactionType
  | ReceiveOpenTransactionsType
  | PaymentsRequestError;
