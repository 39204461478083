import { AppThunk } from 'store';
import setToast from 'actions/toastActions';
import { getFieldGeometry } from 'store/fields/thunks';
import { requestPestGroups, receivePestGroups, pestRequestError } from './actions';
import { requestGetPestGroups, requestPostCropPlan, requestPutCropPlan } from './requests';
import { CropPlanType } from './types';
import { getString } from 'strings/translation';

export const getPestGroups = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestPestGroups);
    const pestGroups = await requestGetPestGroups();
    dispatch(receivePestGroups(pestGroups));
  } catch (e) {
    dispatch(pestRequestError({ message: e.message }));
  }
};

export const putPostCropTargetYield =
  (
    language: string,
    fieldId: number,
    crop: string,
    cropPlanId?: number,
    targetYield?: string | number,
    onSuccess?: () => void,
  ): AppThunk =>
  async (dispatch) => {
    try {
      const body = { crop, field_id: fieldId } as Partial<CropPlanType>;
      if (targetYield) {
        body.target_yield_per_acre = Number(targetYield);
      }
      if (cropPlanId) {
        await requestPutCropPlan(cropPlanId, body);
      } else {
        await requestPostCropPlan(body);
      }
      dispatch(getFieldGeometry(fieldId));
      onSuccess && onSuccess();
      setToast(getString('updateFieldCropSuccessMsg', language));
    } catch (e) {
      setToast(getString('updateFieldCropErrorMsg', language), 'error');
    }
  };
