import React from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Container, Header } from 'common';
import { BatchType } from 'store/batches/types';
import { updateBatch } from 'store/batches/thunks';
import showToast from 'actions/toastActions';
import { formatToNumericDate } from 'util/date';

import styles from './RunPlanned.module.css';

type RunPlannedPropsType = {
  batch: BatchType;
};

export const RunPlanned = ({ batch }: RunPlannedPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const updateEstimationDate = async (date: string) => {
    try {
      dispatch(updateBatch(batch.id, { estimation_date: date }));
    } catch (e) {
      showToast(e.message);
    }
  };

  return (
    <Container vertical>
      <Header
        title={
          <div>
            <span>{`${getString('estimatedCompletionDate', language)}: `}</span>
            <span className={styles.Date}>
              {batch.estimation_date
                ? formatToNumericDate(batch.estimation_date)
                : getString('tbd', language)}
            </span>
          </div>
        }
        underline="none"
        size="H2"
      >
        <DatePicker
          customInput={<Button onClick={() => {}}>{getString('edit', language)}</Button>}
          selected={batch.estimation_date ? new Date(batch.estimation_date) : undefined}
          onChange={updateEstimationDate}
        />
      </Header>
    </Container>
  );
};
