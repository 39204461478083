import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Button } from './Button';
import { DropdownArrowIcon } from './Icon';

import styles from './DropdownButton.module.css';

type DropdownButtonProps = {
  buttonClassName?: string;
  buttonProps?: any;
  className?: string;
  render: (props: { close: () => void }) => any;
  dataTestId?: string;
  title?: string;
};

export const DropdownButton: FC<DropdownButtonProps> = ({
  buttonClassName,
  buttonProps,
  className,
  render,
  dataTestId,
  title,
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const onToogle = useCallback(
    (e: any) => {
      e.stopPropagation();
      setOpened(!opened);
    },
    [opened],
  );

  const handleClickOutside = useCallback((event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpened(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={ref} className={classNames(styles.Wrapper, className)} data-test-id={dataTestId}>
      <Button
        {...buttonProps}
        className={classNames(styles.Button, buttonClassName)}
        data-test-id={dataTestId}
        onClick={onToogle}
      >
        <span className={styles.Title}>{title}</span> <DropdownArrowIcon className={styles.Icon} />
      </Button>

      {opened && (
        <div className={styles.DropdownMenu}>{render({ close: () => setOpened(false) })}</div>
      )}
    </div>
  );
};

DropdownButton.defaultProps = {
  buttonClassName: '',
  className: '',
  dataTestId: '',
  title: '',
};
