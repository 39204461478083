import { plateTypesList } from 'constants/plates';
import {
  PlateActionsType,
  PlatesStateType,
  PLATES_REQUEST_ERROR,
  RECEIVE_HOMOGEN_RELATED_PLATES,
  RECEIVE_PLATE,
  RECEIVE_PLATES,
  RECEIVE_PLATE_WELL,
  RECEIVE_RELATED_PLATES,
  REQUEST_PLATES,
  REQUEST_PLATES_INFO,
} from './types';

const initialState: PlatesStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: true,
  isFetchingInfo: false,
  // request ID, used to cancel the effects of outdated requests
  byBarcode: {},
  plates: {
    items: [],
    page: 1,
    total: 1,
  },
};

/**
 * Use this function to normalize data that is not correctly formated to be used
 * in a react context. IE: null values that need to have a type.
 */
const parsePlate = (plate) => {
  return {
    ...plate,
    ...plate.meta_data,
    meta_data: plate.meta_data || {},
    wells: plate.wells,
  };
};

export const PlateReducer = (state = initialState, action: PlateActionsType) => {
  switch (action.type) {
    case REQUEST_PLATES:
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        isFetchingInfo: false,
      };
    case REQUEST_PLATES_INFO:
      return {
        ...state,
        isFetchingInfo: true,
      };
    case RECEIVE_PLATES:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        byBarcode: action.payload.plates.reduce((col, plate) => {
          if (plateTypesList.some((t) => t === plate.plate_type)) {
            return {
              ...col,
              [plate.barcode]: parsePlate(plate),
            };
          }
          return col;
        }, {}),
        plates: {
          ...state.plates,
          items: action.payload.plates,
          page: action.payload.page || 1,
          total: action.payload.per_page
            ? Math.ceil(action.payload.total / action.payload.per_page)
            : 1,
        },
      };
    case RECEIVE_PLATE:
      return {
        ...state,
        isFetching: false,
        byBarcode: {
          ...state.byBarcode,
          [action.payload.barcode]: {
            ...state.byBarcode[action.payload.barcode],
            ...parsePlate(action.payload),
          },
        },
      };
    case RECEIVE_PLATE_WELL:
      return {
        ...state,
        isFetching: false,
        byBarcode: {
          ...state.byBarcode,
          [action.payload.plateBarcode]: {
            ...state.byBarcode[action.payload.plateBarcode],
            wells: [
              ...(state.byBarcode[action.payload.plateBarcode].wells || []),
              action.payload.well,
            ],
          },
        },
      };
    case RECEIVE_RELATED_PLATES:
      return {
        ...state,
        isFetchingInfo: false,
        byBarcode: {
          ...state.byBarcode,
          [action.payload.plateBarcode]: {
            ...state.byBarcode[action.payload.plateBarcode],
            related_plates: action.payload.relatedPlates,
          },
        },
      };
    case RECEIVE_HOMOGEN_RELATED_PLATES:
      return {
        ...state,
        isFetchingInfo: false,
        byBarcode: {
          ...state.byBarcode,
          [action.payload.plateBarcode]: {
            ...state.byBarcode[action.payload.plateBarcode],
            related_plates: {
              ...state.byBarcode[action.payload.plateBarcode].related_plates,
              homogenization: action.payload.homogenRelated,
            },
          },
        },
        plates: {
          ...state.plates,
          items: state.plates.items.map((plate) => {
            if (plate.barcode === action.payload.plateBarcode) {
              plate.related_plates = {
                ...plate.related_plates,
                homogenization: action.payload.homogenRelated,
              };
            }
            return plate;
          }),
        },
      };
    case PLATES_REQUEST_ERROR:
      return {
        ...state,
        hasFetched: false,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default PlateReducer;
