import React, { useEffect, useRef, useState, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from './Button';
import { DropdownArrowIcon } from './Icon';

import styles from './Selector.module.css';

type Option = {
  id: string | number;
  displayName: string | ReactNode;
  inactive?: boolean;
};

type SelectorProps = {
  activeIndex?: number;
  className?: string;
  disabled?: boolean;
  icon?: string;
  menuClassName?: string;
  onChange: (idx: number) => void;
  options: Option[];
  placeholder?: string;
  required?: boolean;
  dataTestId?: string;
};

export const Selector: FunctionComponent<SelectorProps> = ({
  activeIndex,
  className,
  disabled,
  icon,
  menuClassName,
  onChange,
  options,
  placeholder,
  required,
  dataTestId,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      event.target instanceof Node &&
      !menuRef.current.contains(event.target)
    ) {
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const Menu = (
    <div className={classNames(styles.Menu, menuClassName)}>
      {options.map((option, index) => (
        <Button
          light
          square
          className={classNames(styles.Option, {
            [styles.OptionInactive]: option.inactive,
          })}
          key={option.id}
          onClick={() => {
            if (!option.inactive) {
              onChange(index);
              setIsMenuVisible(false);
            }
          }}
        >
          {option.displayName}
        </Button>
      ))}
    </div>
  );
  const activeOption = activeIndex !== undefined ? options[activeIndex] : undefined;

  const wrapperClassname = classNames(styles.Wrapper, className, {
    [styles.RequiredError]: !activeOption && required,
  });

  return (
    <div className={wrapperClassname} data-test-id={dataTestId} ref={menuRef}>
      <div
        className={classNames(styles.Selector, {
          [styles.Disabled]: disabled || options.length === 0,
        })}
        onClick={(e) => {
          if (disabled || options.length === 0) {
            return;
          }
          // TODO preventDefault prevents misfiring clicks on the Menu while the menu is opening, why?
          // without this, the menu item will be selected by the same click that opens the Menu
          e.preventDefault();
          setIsMenuVisible(!isMenuVisible);
        }}
      >
        {icon ? <img alt="" className={styles.Icon} src={icon} /> : null}
        <span className={styles.Title} data-test-id={dataTestId}>
          {(activeOption && activeOption.displayName) || placeholder || '---'}
        </span>
        <DropdownArrowIcon className={styles.DropdownIcon} />
      </div>
      {isMenuVisible ? Menu : null}
    </div>
  );
};

Selector.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  menuClassName: '',
  dataTestId: '',
};
