import { BELOW_GROUND, FOLIAR } from 'constants/cropPlan';
import { CORN, SOYBEANS } from 'constants/variables';
import { AnalyticType } from 'store/analytics/types';

export const RECEIVE_PEST_GROUPS = 'RECEIVE_PEST_GROUPS';
export const REQUEST_PEST_GROUPS = 'REQUEST_PEST_GROUPS';
export const PEST_GROUP_ERROR = 'PEST_GROUP_ERROR';

export type PestGroupPayloadType = {
  [CORN]: {
    [BELOW_GROUND]: number[];
    [FOLIAR]: number[];
  };
  [SOYBEANS]: {
    [BELOW_GROUND]: number[];
    [FOLIAR]: number;
  };
};

type ReceivePestGroupsActionType = {
  type: typeof RECEIVE_PEST_GROUPS;
  payload: PestGroupPayloadType;
};

type RequestPestGroupsActionType = {
  type: typeof REQUEST_PEST_GROUPS;
};

type PestGroupErrorActionType = {
  type: typeof PEST_GROUP_ERROR;
  payload: {
    message: string;
  };
};

type OutstandingRisk = {
  analyzed_risk: number;
  adjusted_risk: number;
  seed_credits: { [id: number]: number };
  product_credits: { [id: number]: number };
};

export type CropPlanType = {
  active: boolean;
  created_at: string;
  crop_year: number;
  crop: string;
  field_id: number;
  id: number;
  target_yield_per_acre: number;
  updated_at: string;
  seeds: SeedType[];
  agronomic_products: AgronomicProductType[];
  outstanding_risks: {
    outstanding_risks: number;
    outstanding_risks_show_in_ui: number;
    analytics: {
      [id: number]: OutstandingRisk;
    };
  };
};

export type CropPlanningStateType = {
  pestGroups: PestGroupPayloadType | null;
  hasFetched: boolean;
  isFetching: boolean;
  hasFailed: boolean;
};

export type CoverageRatingType = {
  analytic: AnalyticType;
  coverage_rating: number;
};

export type AgronomicProductType = {
  pattern_product_type: string | null;
  id: number;
  name: string;
  registrant: string;
  coverage_ratings: CoverageRatingType[];
  category?: string;
  crop: string;
};

export type SeedMetadataType = {
  brand: string;
  coverage_ratings: CoverageRatingType[];
  crop: string;
  hybrid: string;
  id: number;
  manufacturer: string;
  relative_maturity: string;
  traits: string[];
};

export type SeedType = {
  id: number;
  hybrid: string;
  traits?: string[];
  crop: string;
  coverage_ratings: CoverageRatingType[];
};

export type CombinedAnalyticType = AnalyticType & { coverage_rating: number };

export type CropPlanningActionType =
  | ReceivePestGroupsActionType
  | RequestPestGroupsActionType
  | PestGroupErrorActionType;
