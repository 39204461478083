import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Input, Loader, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { editSampleValues } from 'store/labSamples/thunks';
import { KEYS } from 'constants/keyCodes';

type EditStorageLocationPropsType = {
  sample: LabSampleType;
};

export const EditStorageLocation = ({ sample }: EditStorageLocationPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storageLocation, setStorageLocation] = useState(
    String(sample.rnd_meta?.storage_location || ''),
  );

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setStorageLocation(String(sample.rnd_meta?.storage_location || ''));
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    if (sample.barcode) {
      try {
        setIsEditing(false);
        setIsSubmitting(true);
        const propertiesRndUpdateData: Partial<LabSampleType> = {
          rnd_meta: {
            ...sample.rnd_meta,
            storage_location: storageLocation,
          },
        };
        await dispatch(editSampleValues(sample.id, sample.barcode, propertiesRndUpdateData));
        setIsSubmitting(false);
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      handleSubmit();
    }
  };

  const storageInputDisplay = (
    <Text>
      {sample.rnd_meta?.storage_location
        ? sample.rnd_meta?.storage_location
        : getString('noData', language)}
    </Text>
  );

  const storageLocationInput = (
    <Input
      w="10rem"
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setStorageLocation(String(event.target.value))
      }
      onKeyUp={handleOnKeyUp}
      value={storageLocation}
    />
  );

  const startEditButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={() => setIsEditing(true)}
        disabled={!sample.arrived_at || isSubmitting}
      >
        {getString(isSubmitting ? 'submitting' : 'update', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const finishEditButtons = (
    <Group gap="xs">
      <Button variant="outline" onClick={handleSubmit} disabled={!storageLocation.length}>
        {getString('submit', language)}
      </Button>
      <Button variant="filled" color="red.8" onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </Group>
  );

  return (
    <Group>
      <Group gap="sm" w="13.5rem" wrap="nowrap">
        <Group gap="xs" miw="6rem">
          <Text fw="700">{`Storage Location: `}</Text>
          {isEditing ? storageLocationInput : storageInputDisplay}
        </Group>
      </Group>
      {isEditing ? finishEditButtons : startEditButton}
    </Group>
  );
};
