import React, { useEffect, useState } from 'react';
import { Stack, Center } from '@mantine/core';
import showToast, { type ToastType } from 'actions/toastActions';
import { ACCESS_TOKEN, ACTIVE_USER_ID, CELL, EMAIL, PASSWORD, REFRESH_TOKEN } from 'constants/auth';
import { getLocalStorage, setLocalStorage } from 'util/localStorage';
import { refreshToken } from 'requesters/authenticationRequester';
import { hostNameLogoParse } from 'util/auth';
import { getString } from 'strings/translation';
import EmailForm from './Forms/EmailForm';

import styles from '../Shared/Container.module.css';
import { requestUserPasswordlessCode, requestUserPrelogin } from 'store/user/requests';
import useBroswerLanguage from 'util/hooks/useLanguage';
import SelectLoginTypeForm from './Forms/SelectLoginTypeForm';
import { PasswordlessUserEmailType } from 'store/user/types';
import EnterCodeForm from './Forms/EnterCodeForm';
import PasswordForm from './Forms/PasswordForm';
import { useAuth } from 'Auth';

const redirectToDashboard = () => {
  window.location.href = '/';
};

const attemptRefresh = async () => {
  try {
    const response = await refreshToken();
    const { access_token } = response;
    setLocalStorage(ACCESS_TOKEN, access_token);
    redirectToDashboard();
  } catch (error) {
    // pass
  }
};

const LoginContainer = () => {
  const auth = useAuth();
  const language = useBroswerLanguage();
  const [logo, setLogo] = useState<string | null>(null);
  const [userPre, setUserPre] = useState<PasswordlessUserEmailType | null>(null);
  const [loginType, setLoginType] = useState<string | null>(null);
  const setToastMessage = (message: string, type?: ToastType, timeout?: boolean | number) =>
    showToast(message, type, timeout);

  const allFieldsArePresent = () =>
    getLocalStorage(ACCESS_TOKEN) &&
    getLocalStorage(ACTIVE_USER_ID) &&
    getLocalStorage(REFRESH_TOKEN);

  useEffect(() => {
    const logoFromUrl = hostNameLogoParse(true);
    if (logoFromUrl) {
      setLogo(logoFromUrl);
    }
  }, []);

  useEffect(() => {
    if (allFieldsArePresent()) {
      attemptRefresh();
    }
  }, []);

  const getUserPrelogin = async (emailStr: string) => {
    try {
      const res = await requestUserPrelogin(emailStr);
      setUserPre(res);
    } catch (error) {
      setToastMessage(getString('noUserFoundMsg', language), 'error');
    }
  };

  const clearEmailCell = () => {
    setUserPre(null);
  };

  const handleNextSelectedLogin = async (selectedLoginType: string) => {
    if (selectedLoginType !== PASSWORD && userPre) {
      const body = {
        email: userPre.email,
      } as Partial<PasswordlessUserEmailType>;
      if (selectedLoginType === 'cell') {
        body.cell = userPre.cell;
      }
      try {
        await requestUserPasswordlessCode(body);
        setToastMessage(getString('codeSentSuccessMsg', language));
        setLoginType(selectedLoginType);
      } catch (error) {
        setToastMessage(getString('codeSentFailMsg', language), 'error');
      }
    } else {
      setLoginType(selectedLoginType);
    }
  };

  const submitCode = async (code: string) => {
    if (userPre) {
      auth.loginWithToken({ email: userPre.email, code }, () =>
        setToastMessage(getString('codeIncorrectMsg', language), 'error'),
      );
    }
  };

  const getFormDisplayed = () => {
    if (userPre && !loginType) {
      return (
        <SelectLoginTypeForm
          emailCell={userPre}
          handleNextSelectedLogin={handleNextSelectedLogin}
          clearEmailCell={clearEmailCell}
        />
      );
    }
    if (loginType && userPre && [CELL, EMAIL].includes(loginType)) {
      return (
        <EnterCodeForm
          emailCell={userPre}
          loginType={loginType}
          clearLoginType={() => setLoginType(null)}
          resendCode={() => handleNextSelectedLogin(loginType)}
          submitCode={submitCode}
        />
      );
    }
    if (loginType === PASSWORD && userPre) {
      return <PasswordForm emailCell={userPre} clearLoginType={() => setLoginType(null)} />;
    }

    return <EmailForm getUserPrelogin={getUserPrelogin} setToastMessage={setToastMessage} />;
  };

  return (
    <Center h="80vh" maw="100vw">
      <Stack>
        {logo && <img alt="Pattern Ag" className={styles.Logo} src={logo} />}
        <Center>{getFormDisplayed()}</Center>
      </Stack>
    </Center>
  );
};

export default LoginContainer;
