import React from 'react';
import { Stack, Text, Group, Input, Switch, TextInput } from '@mantine/core';
import { CatalogType } from 'store/catalogs/types';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';

type SetAttributesPropsType = {
  catalog?: Partial<CatalogType>;
  onValueChange: (attributeKey: keyof CatalogType, newValue: string | boolean) => void;
};

const SetAttributes = ({ catalog, onValueChange }: SetAttributesPropsType) => {
  const language = useBroswerLanguage();

  return (
    <Stack mr="xl" mb="xl">
      <Group justify="space-between" align="flex-start" gap="xl">
        <Group justify="flex-start" align="flex-start" gap="xl">
          <Input.Wrapper label={getString('name', language)}>
            <TextInput
              onChange={(event) => onValueChange('name', event.currentTarget.value)}
              value={catalog?.name || ''}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Created By Id">
            <Text>{catalog?.created_by_id}</Text>
          </Input.Wrapper>
        </Group>
        <Input.Wrapper label={getString('active', language)}>
          <Switch
            checked={catalog?.show_in_ui}
            onChange={() => onValueChange('show_in_ui', !catalog?.show_in_ui)}
            mt="0.25rem"
          />
        </Input.Wrapper>
      </Group>
    </Stack>
  );
};

export default SetAttributes;
