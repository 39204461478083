import { replaceOrAdd, groupBy } from 'util/collectionUtils';
import { parseSample, sortPrimaryFirst } from 'util/sample';
import {
  LabSamplesActionsType,
  LabSampleStateType,
  LAB_SAMPLES_REQUEST_ERROR,
  RECEIVE_FIELD_LAB_SAMPLES,
  RECEIVE_LAB_SAMPLE,
  RECEIVE_LAB_SAMPLES,
  REQUEST_LAB_SAMPLES,
} from './types';

export const initialState: LabSampleStateType = {
  errorMessage: '',
  hasFetched: false,
  isFetching: false,
  hasFailed: false,
  foundSample: undefined,
  list: [],
  byBarcode: {},
  byFieldId: {},
};

export const LabSamplesReducer = (state = initialState, action: LabSamplesActionsType) => {
  switch (action.type) {
    case REQUEST_LAB_SAMPLES:
      return {
        ...state,
        errorMessage: '',
        hasFetched: false,
        isFetching: true,
      };
    case RECEIVE_LAB_SAMPLES:
      return {
        ...state,
        errorMessage: '',
        hasFetched: true,
        hasFailed: false,
        isFetching: false,
        list: action.payload.map(parseSample),
        byBarcode: groupBy(sortPrimaryFirst(action.payload.map(parseSample)), 'barcode'),
      };
    case RECEIVE_FIELD_LAB_SAMPLES:
      return {
        ...state,
        errorMessage: '',
        hasFetched: true,
        hasFailed: false,
        isFetching: false,
        list: action.payload.samples.map(parseSample),
        byFieldId: {
          ...state.byFieldId,
          [action.payload.id]: action.payload.samples.map(parseSample),
        },
      };
    case RECEIVE_LAB_SAMPLE:
      return {
        ...state,
        errorMessage: '',
        hasFetched: true,
        hasFailed: false,
        isFetching: false,
        list: replaceOrAdd(state.list, parseSample(action.payload)),
        byBarcode: {
          ...state.byBarcode,
          [action.payload.barcode]: replaceOrAdd(
            state.byBarcode[action.payload.barcode] || [],
            parseSample(action.payload),
          ),
        },
      };
    case LAB_SAMPLES_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        errorMessage: action.payload.message,
        isFetching: false,
      };
    default:
      return state;
  }
};
