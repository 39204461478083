import { sortByCreatedAt } from 'util/date';
import {
  RECEIVE_SHIPPING_ADDRESSES,
  RECEIVE_SHIPPING_BATCHES,
  SHIPPING_REQUEST_ERROR,
  ShippingActionType,
  ShippingStateType,
} from './types';

export const initialState: ShippingStateType = {
  shippingAddresses: [],
  labAddresses: [],
  ready: [],
  pending: [],
  failed: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

const getPendingBatches = (existing_pending, new_pending, ready) => {
  // There's a gap between when the shipping job is completed and when the labels show up in the ready list.
  // We want to just hold onto the pending jobs until they show up in the ready list
  const existing_pending_batch_ids = existing_pending.map((job) => job.shippo_batch_id);
  const new_pending_batch_ids = new_pending.map((job) => job.shippo_batch_id);
  const ready_batch_ids = ready.map((batch) => batch.shippo_batch_id);
  if (new Set(existing_pending_batch_ids) === new Set(new_pending_batch_ids)) {
    return new_pending;
  }
  return [...existing_pending_batch_ids, ...new_pending_batch_ids].reduce((pending, batch_id) => {
    if (pending.map((job) => job.shippo_batch_id).includes(batch_id)) {
      return pending;
    }
    if (new_pending_batch_ids.includes(batch_id)) {
      return [...pending, new_pending.find((job) => job.shippo_batch_id === batch_id)];
    }
    if (!ready_batch_ids.includes(batch_id)) {
      return [...pending, existing_pending.find((job) => job.shippo_batch_id === batch_id)];
    }
    return pending;
  }, []);
};

export const ShippingReducer = (state = initialState, action: ShippingActionType) => {
  switch (action?.type) {
    case RECEIVE_SHIPPING_ADDRESSES:
      return {
        ...state,
        shippingAddresses: action.payload.shipping_addresses,
        labAddresses: action.payload.lab_addresses,
        hasFetched: true,
        isFetching: false,
      };
    case RECEIVE_SHIPPING_BATCHES:
      return {
        ...state,
        ready: sortByCreatedAt(action.payload.ready),
        pending: getPendingBatches(state.pending, action.payload.pending, action.payload.ready),
        failed: sortByCreatedAt(action.payload.failed),
      };
    case SHIPPING_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
