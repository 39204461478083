import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Center, Loader } from '@mantine/core';

import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { userIsSampler } from 'store/user/selectors';
import { RootState } from 'store/index';
import { getPestGroups } from 'store/cropPlans/thunks';
import useFirebase from 'util/hooks/useFirebase';

import CropPlanningFieldList from './List/Container';

type ParamsType = {
  operationId: string;
};

const CropPlanningContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operationId } = useParams<ParamsType>();
  const numericId = Number(operationId);

  const location = useLocation();
  const firebase = useFirebase();

  const { operation, isSampler } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[numericId],
    isSampler: userIsSampler(state),
  }));

  const { pestGroups } = useSelector((state: RootState) => ({
    pestGroups: state.cropPlanning.pestGroups,
  }));

  useEffect(() => {
    if (isSampler) {
      navigate('/payments/open');
    }
  }, [isSampler]);

  useEffect(() => {
    setActiveOperation(numericId);
  }, [numericId]);

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericId));
    }
  }, [operation]);

  useEffect(() => {
    if (!pestGroups) {
      dispatch(getPestGroups());
    }
  }, [pestGroups]);

  useEffect(() => {
    firebase.pageView(window.location.href, location.pathname, document?.title);
  }, [firebase, location]);

  if (!operation) {
    return (
      <Center h="30rem">
        <Loader />
      </Center>
    );
  }

  return <CropPlanningFieldList />;
};

export default CropPlanningContainer;
