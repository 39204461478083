export const CHEMISTRY_RESULTS_REQUEST_ERROR = 'CHEMISTRY_RESULTS_REQUEST_ERROR';
export const RECEIVE_CHEMISTRY_RESULTS = 'RECEIVE_CHEMISTRY_RESULTS';
export const REQUEST_CHEMISTRY_RESULTS = 'REQUEST_CHEMISTRY_RESULTS';

export type ChemistryResultType = {
  id: number;
  barcode?: string;
  field_id: number;
  sample_uuid?: string;
  created_at: string;
  file_name?: string;
  method?: string;
  analytic_id: number;
  value: number;
};

export type ChemistryResultsStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  byBarcode: { [key: string]: ChemistryResultType[] };
};

type requestChemistryResultsActionType = {
  type: typeof REQUEST_CHEMISTRY_RESULTS;
};

type receiveChemistryResultsActionType = {
  type: typeof RECEIVE_CHEMISTRY_RESULTS;
  payload: {
    barcode: string;
    results: ChemistryResultType[];
  };
};

type chemistryResultsRequestErrorActionType = {
  type: typeof CHEMISTRY_RESULTS_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type ChemistryResultActionType =
  | requestChemistryResultsActionType
  | receiveChemistryResultsActionType
  | chemistryResultsRequestErrorActionType;
