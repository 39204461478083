import React from 'react';
import { Group } from '@mantine/core';
import { Header } from 'common/Components/Mantine/Header';
import { PlateType } from 'store/plates/types';

import ActionsDropdown from './Actions/ActionsDropdown';
import { PlateHeader } from './PlateTitle';

type PageHeaderPropsType = {
  plate: PlateType;
};

const PageHeader = ({ plate }: PageHeaderPropsType) => {
  return (
    <Header title={<PlateHeader plate={plate} />}>
      <Group justify="space-between" align="center">
        <ActionsDropdown plate={plate} />
      </Group>
    </Header>
  );
};

export default PageHeader;
