import { Feature, Polygon } from 'geojson';
import { InputType } from 'store/inputs/types';

export const RECEIVE_PRESCRIPTION_ZONES = 'RECEIVE_PRESCRIPTION_ZONES';
export const REQUEST_PRESCRIPTION_ZONES = 'REQUEST_PRESCRIPTION_ZONES';
export const PRESCRIPTION_ZONE_REQUEST_ERROR = 'PRESCRIPTION_ZONE_REQUEST_ERROR';

export const REQUEST_EXPORTED_PRESCRIPTIONS = 'REQUEST_EXPORTED_PRESCRIPTIONS';
export const RECEIVE_EXPORTED_PRESCRIPTIONS = 'RECEIVE_EXPORTED_PRESCRIPTIONS';
export const PRESCRIPTION_EXPORT_REQUEST_ERROR = 'PRESCRIPTION_EXPORT_REQUEST_ERROR';

export const REQUEST_PRO_PRESCRIPTIONS = 'REQUEST_PRO_PRESCRIPTIONS';
export const RECEIVE_PRO_PRESCRIPTIONS = 'RECEIVE_PRO_PRESCRIPTIONS';
export const PRO_PRESCRIPTION_REQUEST_ERROR = 'PRO_PRESCRIPTION_REQUEST_ERROR';

export type PrescriptionZonePropertiesType = {
  id: number;
  prescription_id: number;
  sample_uuid: string;
  amount: number;
  updated_at?: Date;
  created_at?: Date;
  calculated_area_acres: number;
};

export type PrescriptionExportJobType = {
  complete: number;
  id: number;
  field_id: number;
  prescription_id: number;
  external_user_uuid: string;
  external_provider_name: string;
  created_at: string;
};

export type PrescriptionZoneType = Feature<Polygon, PrescriptionZonePropertiesType>;

export type PrescriptionType = {
  id: number;
  name: string;
  type: string;
  crop: string;
  timing: string;
  formula_name: string;
  input_id: number;
  event_id?: number;
  cost_per_ton?: number;
  percent_target_application?: number;
  minimum_rate?: number;
  maximum_rate?: number;
  expected_yield?: number;
  credits?: number;
  created_at?: Date;
  updated_at?: Date;
  field_id: number;
  zones: PrescriptionZonePropertiesType[];
  show_in_ui: boolean;
  input: InputType;
  no_till?: boolean;
  field_rate_average?: number;
  total_amount_applied?: number;
  custom_input_concentration?: number | null;
  target_value?: number | null;
  pro_density: string | null;
  sampling_plan_id: number | null;
  amount_range: number[];
  geojson_uri: string | null;
};

export interface PrescriptionZoneState {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  isFetchingExported: boolean;
  isFetchingPro: boolean;
  zones: {
    [prescriptionId: number]: PrescriptionZoneType[];
  };
  scriptExports: {
    [prescription: number]: {
      field_view?: PrescriptionExportJobType;
      john_deere?: PrescriptionExportJobType;
      cnhi?: PrescriptionExportJobType;
    };
  };
  proPrescriptions: {
    [fieldId: number]: PrescriptionType[];
  };
}

export type PrescriptionZoneActionType = {
  type:
    | typeof REQUEST_PRESCRIPTION_ZONES
    | typeof RECEIVE_PRESCRIPTION_ZONES
    | typeof PRESCRIPTION_ZONE_REQUEST_ERROR
    | typeof REQUEST_EXPORTED_PRESCRIPTIONS
    | typeof RECEIVE_EXPORTED_PRESCRIPTIONS
    | typeof PRESCRIPTION_EXPORT_REQUEST_ERROR
    | typeof REQUEST_PRO_PRESCRIPTIONS
    | typeof RECEIVE_PRO_PRESCRIPTIONS
    | typeof PRO_PRESCRIPTION_REQUEST_ERROR;
  payload?: any;
};
