import React from 'react';

import { TableRow, TableCol } from 'common';
import { capitalizeEveryWord, numsToInvoiceDollars } from 'util/stringUtils';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import classNames from 'classnames';
import { formatCalendarMonthDayYear } from 'util/date';
import { NOT_APPLICABLE } from 'constants/defaultValues';
import styles from './PaymentTables.module.css';

type OpenRowPropTypes = {
  rowClick?: (param?: any) => void;
  userId?: number;
  agencyPrimary: boolean;
  billingName: string;
  role?: string | null;
  billedAmount?: number | null;
  commissions?: number | null;
  sampling?: number | null;
  other?: number | null;
  balance: number;
  isSampler: boolean;
  earliest_sampled_date: string;
};

const OpenRow = ({
  rowClick,
  userId,
  agencyPrimary,
  billingName,
  role,
  billedAmount,
  commissions,
  other,
  balance,
  sampling,
  isSampler,
  earliest_sampled_date,
}: OpenRowPropTypes) => {
  const language = useBroswerLanguage();

  const getCommissions = () => {
    if (isSampler && sampling) {
      return numsToInvoiceDollars(sampling);
    }
    return !isSampler && commissions ? numsToInvoiceDollars(commissions) : numsToInvoiceDollars(0);
  };

  return (
    <TableRow
      onClick={() => rowClick && rowClick(userId)}
      className={classNames(rowClick && styles.HoverClick, styles.RowPadding)}
    >
      <TableCol className={styles.TransactionTitleColumn}>
        {capitalizeEveryWord(billingName)}
      </TableCol>
      {role && (
        <TableCol className={styles.TransactionTitleColumn}>
          {agencyPrimary ? getString('agency', language) : getString(role, language)}
        </TableCol>
      )}
      <TableCol>
        {earliest_sampled_date ? formatCalendarMonthDayYear(earliest_sampled_date) : NOT_APPLICABLE}
      </TableCol>
      <TableCol>
        {billedAmount ? numsToInvoiceDollars(billedAmount * -1) : numsToInvoiceDollars(0)}
      </TableCol>
      <TableCol>{getCommissions()}</TableCol>
      <TableCol>{other ? numsToInvoiceDollars(other) : numsToInvoiceDollars(0)}</TableCol>
      <TableCol>{numsToInvoiceDollars(balance)}</TableCol>
    </TableRow>
  );
};

export default OpenRow;
