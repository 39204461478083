import React, { forwardRef, MouseEventHandler, ButtonHTMLAttributes } from 'react';

import classNames from 'classnames';

import styles from './Button.module.css';

type ButtonProps = {
  circle?: boolean;
  className?: string;
  danger?: boolean;
  light?: boolean;
  inactive?: boolean;
  onClick?: MouseEventHandler;
  primary?: boolean;
  square?: boolean;
  dataTestId?: string;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      circle,
      className,
      danger,
      light,
      inactive,
      onClick,
      primary,
      square,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    className = classNames(styles.Button, className, {
      [styles.Circle]: circle,
      [styles.Danger]: danger,
      [styles.Light]: light,
      [styles.Inactive]: inactive,
      [styles.Primary]: primary,
      [styles.Square]: square,
    });

    return (
      <button
        className={className}
        onClick={onClick}
        ref={ref}
        data-test-id={dataTestId}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
