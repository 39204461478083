import {
  ACRES_IN_HECTARE,
  BU_AC_IN_SACA_HA,
  KG_IN_LBS,
  KG_IN_METRIC_TON,
  LB_ACRES_IN_KG_HA,
  LBS_IN_TON,
  REAL_IN_DOLLAR,
  TONS_IN_METRIC_TON,
} from 'constants/translations';
import { AC, ENGLISH, HA, PORTUGUESE } from 'constants/languages';

// Using functions to handle conversions based on field property 'acreage_unit'
// Making the assumption that, at some point, we won't be able to rely on language for lbs vs kg, etc

// Try not to use this unless a field is not readily available
export const getAcreageUnitFromLang = (language: string) => {
  if (language === ENGLISH) {
    return AC;
  }
  return HA;
};

export const haToAcres = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount * ACRES_IN_HECTARE;
};

export const acToHectares = (amount: number, unitOrLanguage: string) => {
  if ([AC, ENGLISH].includes(unitOrLanguage)) {
    return amount;
  }
  return amount / ACRES_IN_HECTARE;
};

// Keep value in acres, but visual in hectares
export const acToHectaresList = (
  valueList: { id: number; displayName: number; value: number }[],
  unit: string,
) => {
  return valueList.map((val) => ({
    ...val,
    displayName: acToHectares(val.value, unit),
  }));
};

export const lbsToKg = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount / KG_IN_LBS;
};

export const getUnitLbsAc = (unit: string) => {
  if (unit === AC) {
    return 'lbs/ac';
  }
  return 'kg/ha';
};

export const getUnitBu = (unit: string, lang?: string) => {
  if (unit === AC || lang === ENGLISH) {
    return 'bu';
  }
  return 'sc';
};

export const getUnitDollarBu = (unit: string, lang?: string) => {
  if (unit === AC || lang === ENGLISH) {
    return '$/bu';
  }
  return '$/sc';
};

export const getUnitLbs = (unit: string) => {
  if (unit === AC) {
    return 'lbs';
  }
  return 'kg';
};

export const lbsAcToKgHa = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount * LB_ACRES_IN_KG_HA;
};

export const KgHaToLbsAc = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount / LB_ACRES_IN_KG_HA;
};

export const getUnitBuAc = (unit: string, lang?: string) => {
  if (unit === AC || lang === ENGLISH) {
    return 'bu/ac';
  }
  return 'sc/ha';
};

export const buAcToSacaHa = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount / BU_AC_IN_SACA_HA;
};

export const sacaHaToBuAc = (amount: number, unit: string) => {
  if (unit === AC) {
    return amount;
  }
  return amount * BU_AC_IN_SACA_HA;
};

export const getTonSize = (unit: string) => {
  // Return 2000lbs or 1000kg
  if (unit === AC) {
    return LBS_IN_TON;
  }
  return KG_IN_METRIC_TON;
};

export const realMetricTonToDollarsTon = (amount: number, unit: string) => {
  // real/metric ton => dollars/ton
  if (unit === AC) {
    return amount;
  }
  return (amount * TONS_IN_METRIC_TON) / REAL_IN_DOLLAR;
};

export const dollarsTonToRealMetricTon = (amount: number, unit: string) => {
  // real/metric ton => dollars/ton
  if (unit === AC) {
    return amount;
  }
  return (amount * REAL_IN_DOLLAR) / TONS_IN_METRIC_TON;
};

export const dollarsToReal = (amount: number, language: string) => {
  if (language === ENGLISH) {
    return amount.toFixed(2);
  }
  return (amount * REAL_IN_DOLLAR).toFixed(2);
};

export const currencyFormatFromLanguage = (
  amount: number,
  language: string,
  decimalPlaces = 2,
): string => {
  if ([PORTUGUESE].includes(language)) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: decimalPlaces,
    }).format(amount);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
  }).format(amount);
};

export default acToHectares;
