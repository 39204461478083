import {
  PAYMENTS_REQUEST_ERROR,
  RECEIVE_OPEN_TRANSACTIONS,
  RECEIVE_OPEN_TRANSACTION,
  RECEIVE_APPROVED_PAYMENTS,
  RECEIVE_APPROVED_PAYMENT,
  REQUEST_PAYMENTS,
  OpenTransactionsType,
  ApprovedPaymentsType,
  PaymentsActionType,
} from './types';

export const paymentsRequestError = {
  type: PAYMENTS_REQUEST_ERROR,
};

export const receiveOpenTransactions = (response: {
  page: number;
  total: number;
  per_page: number;
  items: OpenTransactionsType[];
}): PaymentsActionType => ({
  type: RECEIVE_OPEN_TRANSACTIONS,
  payload: {
    open: response.items,
    page: response.page,
    total: response.total,
    per_page: response.per_page,
  },
});

export const receiveApprovedPayments = (response: {
  page: number;
  total: number;
  per_page: number;
  items: ApprovedPaymentsType[];
}): PaymentsActionType => ({
  type: RECEIVE_APPROVED_PAYMENTS,
  payload: {
    approved: response.items,
    page: response.page,
    total: response.total,
    per_page: response.per_page,
  },
});

export const receiveOpenTransaction = (openById: OpenTransactionsType): PaymentsActionType => ({
  type: RECEIVE_OPEN_TRANSACTION,
  payload: {
    id: openById.id,
    openById: openById,
  },
});

export const receiveApprovedPayment = (approvedById: ApprovedPaymentsType): PaymentsActionType => ({
  type: RECEIVE_APPROVED_PAYMENT,
  payload: {
    uuid: approvedById.payment_uuid,
    approvedById: approvedById,
  },
});

export const requestPayments = {
  type: REQUEST_PAYMENTS,
};
