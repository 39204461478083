import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Label, Input, Selector, OperationSearchBar, RestrictedGeo } from 'common';
import { RootState } from 'store';
import { getString } from 'strings/translation';
import { OperationType } from 'store/operation/types';
import { getSampleByBarcode } from 'store/samples/requests';
import { getFieldSamplePlans } from 'store/samplePlans/thunks';
import { LabSampleType } from 'store/labSamples/types';
import sortByName from 'util/sortByName';
import { getPlanName } from 'util/samplePlan';
import { WellType } from 'store/plates/types';

import { SharedFormInputs } from './FormInputs/SharedInputs';
import styles from './Container.module.css';

type NewSampleFormPropsType = {
  well: WellType;
  onValueChange: (
    attributeKey: keyof WellType,
    newValue: string | string[] | { [key: string]: any } | number | boolean | null,
  ) => void;
  setIsFormValid: (isValid: boolean) => void;
  setErrorMessage: (message: string) => void;
  errorMessage?: string | null;
  barcodeRef: any;
};

export const NewSampleForm = ({
  well,
  onValueChange,
  setIsFormValid,
  setErrorMessage,
  errorMessage,
  barcodeRef,
}: NewSampleFormPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { plansByField } = useSelector((state: RootState) => ({
    plansByField: state.samplePlans.plans,
  }));
  const [selectedOperation, setSelectedOperation] = useState<OperationType | null>(null);
  const [fieldIndex, setFieldIndex] = useState(-1);
  const [samplePlanIndex, setSamplePlanIndex] = useState(-1);

  const operationFields = selectedOperation ? sortByName(selectedOperation.fields) : [];

  const fieldOptions = operationFields.map((field: { id: number; name: string }) => ({
    id: field.id,
    displayName: field.name,
  }));

  const selectedField = fieldOptions?.find((f) => f.id === fieldOptions[fieldIndex]?.id) || null;

  const samplePlanOptions =
    (selectedField && plansByField[selectedField.id])?.map((plan) => ({
      id: plan.id,
      displayName: getPlanName(plan),
    })) || [];

  const handleOperationSelection = (operation: OperationType) => {
    setSelectedOperation(operation);
  };

  const handleFieldSelector = (idx: number) => {
    const field = operationFields[idx];
    onValueChange('field_id', field ? field.id : null);
    setFieldIndex(idx);
  };

  useEffect(() => {
    if (well.field_id && !plansByField[well.field_id]) {
      dispatch(getFieldSamplePlans(well.field_id));
    }
  }, [well, plansByField]);

  const handleSamplePlanSelection = (idx: number) => {
    const selectedPlan = samplePlanOptions[idx];
    onValueChange('sampling_plan_id', selectedPlan.id);
    setSamplePlanIndex(idx);
  };

  const checkBarcode = async (barcode: string) => {
    try {
      const matches: LabSampleType[] = await getSampleByBarcode(barcode);

      if (matches.length > 0) {
        setIsFormValid(false);
        setErrorMessage(getString('sampleAlreadyExistsMsg', language));
      } else {
        setIsFormValid(true);
      }
    } catch (e) {
      setErrorMessage(e.message);
      onValueChange('sample', {});
    }
  };

  const debouncedCheckBarcode = useDebouncedCallback(
    (barcode: string) => checkBarcode(barcode),
    250,
    { trailing: true },
  );

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: barcode } = event.target;
    setErrorMessage('');
    onValueChange('sample_barcode', barcode);
    if (barcode.trim().length > 0) {
      // prevent empty strings
      debouncedCheckBarcode(barcode);
    } else {
      setErrorMessage('');
      onValueChange('sample', {});
      setIsFormValid(true);
    }
  };

  return (
    <>
      <Label label={getString('sampleBarcode', language)}>
        <Input
          placeholder={getString('enterBarcodeMsg', language)}
          value={well.sample_barcode || ''}
          onChange={handleBarcodeChange}
          ref={barcodeRef}
        />
        {errorMessage && <span className={styles.Error}>{errorMessage}</span>}
      </Label>
      <Label label={getString('operation', language)}>
        <OperationSearchBar
          placeholder={selectedOperation?.name}
          onSelect={handleOperationSelection}
          className={styles.FullWidth}
        />
      </Label>
      <Label label={getString('field', language)}>
        <Selector
          disabled={!selectedOperation}
          activeIndex={fieldIndex}
          className={styles.FullWidth}
          onChange={handleFieldSelector}
          options={fieldOptions}
        />
      </Label>
      <RestrictedGeo sample={well.sample as LabSampleType} />
      <Label label={getString('samplingPlan', language)}>
        <Selector
          disabled={!selectedField}
          activeIndex={samplePlanIndex}
          className={styles.FullWidth}
          options={samplePlanOptions}
          onChange={handleSamplePlanSelection}
        />
      </Label>
      <Label label={getString('latitude', language)}>
        <Input
          value={well.lat || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onValueChange('lat', e.target.value)
          }
        />
      </Label>
      <Label label={getString('longitude', language)}>
        <Input
          value={well.lng || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onValueChange('lng', e.target.value)
          }
        />
      </Label>
      <SharedFormInputs
        well={well}
        onValueChange={onValueChange}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        rndToggle
        sampleType
        rndAttributes
      />
    </>
  );
};
