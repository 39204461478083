import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.css';

type SpinnerProps = {
  className?: string;
  fill?: Boolean;
  'data-test-id'?: string;
  wrapperClassName?: string;
};

export const Spinner: FunctionComponent<SpinnerProps> = ({
  className,
  wrapperClassName,
  fill,
  'data-test-id': testId,
}) => (
  <div
    className={classNames(styles.Wrapper, { [styles.Fill]: fill }, wrapperClassName)}
    data-test-id={testId}
  >
    <div className={classNames(styles.Spinner, className)} />
  </div>
);
