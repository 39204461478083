import React from 'react';
import fieldOverview from 'images/sales/fieldOverview.png';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import SlideStyles from '../../Shared.module.css';
import Styles from './FieldOverview.module.css';

const FieldOverview = () => {
  const language = useBroswerLanguage();

  return (
    <div>
      <div
        className={SlideStyles.Header}
        dangerouslySetInnerHTML={{
          __html: getString('createYourPlan', language),
        }}
      />
      <div className={SlideStyles.Images}>
        <img alt={getString('overview', language)} className={Styles.Image} src={fieldOverview} />
      </div>
    </div>
  );
};

export default FieldOverview;
