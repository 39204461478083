export const JOHN_DEERE = 'john_deere';
export const FIELDVIEW = 'fieldview';
export const CNHI = 'cnhi';
export const AG_LEADER = 'ag_leader';

export const accountDisplayNames = {
  [JOHN_DEERE as string]: 'John Deere',
  [FIELDVIEW as string]: 'Climate Field View',
  [CNHI as string]: 'CNH Industrial',
  [AG_LEADER as string]: 'Ag Leader',
};
