import { ShippingLabel } from 'store/shipping/types';

export type SamplingDateType = {
  sampled_date: string;
  sampled_fields: SamplingHistorySummaryType[];
};

export type SamplingHistorySummaryType = {
  operation_id: number;
  operation_name: string;
  field_id: number;
  field_name: string;
  sampling_plan_id: number;
  sample_count: number;
  sampled_area: number;
  arrived_at?: string;
  shipping_labels: ShippingLabel[];
};

export type SamplingHistoryStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  paginated: {
    items: SamplingDateType[];
    page: number;
    total: number;
  };
};

export const REQUEST_SAMPLING_HISTORY = 'REQUEST_SAMPLING_HISTORY';
export const RECEIVE_SAMPLING_HISTORY = 'RECEIVE_SAMPLING_HISTORY';
export const SAMPLING_HISTORY_REQUEST_ERROR = 'SAMPLING_HISTORY_REQUEST_ERROR';

type RequestSamplingHistoryType = {
  type: typeof REQUEST_SAMPLING_HISTORY;
};

type ReceiveSamplingHistoryType = {
  type: typeof RECEIVE_SAMPLING_HISTORY;
  payload: {
    page: number;
    per_page: number;
    total: number;
    items: SamplingDateType[];
  };
};

type SamplingHistoryRequestErrorType = {
  type: typeof SAMPLING_HISTORY_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type SamplingHistoryActionsType =
  | RequestSamplingHistoryType
  | ReceiveSamplingHistoryType
  | SamplingHistoryRequestErrorType;
