import { AppThunk } from 'store';
import setToast from 'actions/toastActions';
import { getSamplePlan } from 'store/samplePlans/requests';
import { FeatureCollection } from '@turf/helpers';
import { GeoJsonProperties, Geometry } from 'geojson';
import { getPrescription } from 'store/prescriptions/requests';
import { requestOptimizeCropPlans } from 'store/cropPlans/requests';
import chunkArray from 'util/chunkArray';

import {
  requestFieldGeometry,
  receiveFieldGeometry,
  fieldGeometryError,
  receiveFieldGeometries,
  fieldGeometriesError,
  removeFieldAction,
  receiveSamplePlan,
  requestFieldGeometries,
  requestSetFieldsWillFetch,
  receivePrescription,
  requestCropPlanOptimize,
  receiveCropPlanOptimize,
  cropPlanOptimizeError,
  refreshFields as refreshFieldsAction,
} from './actions';
import { FieldType } from './types';
import {
  fetchFieldGeometry,
  putFieldGeometry,
  deleteFieldGeometry,
  deleteField,
  fetchFieldGeometryNoSamples,
} from './requests';
import { updatePlanAnalyticResults } from 'util/sample';

export const setFieldsWillFetchList =
  (fieldIds: number[]): AppThunk =>
  (dispatch) => {
    dispatch(requestSetFieldsWillFetch(fieldIds));
  };

export const refreshFields = (): AppThunk => async (dispatch) => {
  dispatch(refreshFieldsAction());
};

export const getFieldGeometry =
  (fieldId: number, removeSamplesFromPlans?: boolean): AppThunk =>
  async (dispatch, getState) => {
    dispatch(requestFieldGeometry(fieldId));
    try {
      const state = getState();
      const response = removeSamplesFromPlans
        ? await fetchFieldGeometryNoSamples(fieldId)
        : await fetchFieldGeometry(fieldId);
      const newFields = updatePlanAnalyticResults(response, state.analytics.analytics);
      dispatch(receiveFieldGeometry(fieldId, newFields));
    } catch (e) {
      dispatch(fieldGeometryError(fieldId));
    }
  };

export const getFieldGeometries =
  (fieldIds: Array<number>): AppThunk =>
  async (dispatch) => {
    dispatch(requestFieldGeometries(fieldIds));
    try {
      const allFetches = fieldIds.map((id) => fetchFieldGeometry(id));
      const response = await Promise.all(allFetches);
      dispatch(receiveFieldGeometries(fieldIds, response));
    } catch (e) {
      dispatch(fieldGeometriesError(fieldIds));
    }
  };

export const updateFieldGeometry =
  (body: FieldType | FeatureCollection<Geometry, GeoJsonProperties>, fieldId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestFieldGeometry(fieldId));
      const response = body.features.length
        ? await putFieldGeometry(fieldId, body)
        : await deleteFieldGeometry(fieldId);
      dispatch(receiveFieldGeometry(fieldId, response));
    } catch (err) {
      dispatch(fieldGeometryError(fieldId));
    }
  };

export const getFieldSamplePlan =
  (planId: number, fieldId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestFieldGeometry(fieldId));
    try {
      const response = await getSamplePlan(planId);
      dispatch(receiveSamplePlan(fieldId, response));
    } catch (e) {
      dispatch(fieldGeometryError(fieldId));
    }
  };

export const removeField =
  (fieldId: number): AppThunk =>
  async (dispatch) => {
    try {
      await deleteField(fieldId);
      return removeFieldAction(fieldId);
    } catch (e) {
      return dispatch(fieldGeometryError(fieldId));
    }
  };

export const getFieldPrescription =
  (fieldId: number, prescriptionId: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestFieldGeometry(fieldId));
    try {
      const response = await getPrescription(prescriptionId);
      dispatch(receivePrescription(fieldId, response));
    } catch (e) {
      return dispatch(fieldGeometryError(fieldId));
    }
  };

export const optimizeCropPlans =
  (cropPlanIds: number[], onSuccessMsg: string, onFailureMsg: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestCropPlanOptimize(cropPlanIds));
      const chunkedIds = chunkArray(cropPlanIds, 5);
      const optimizedPlans = await Promise.all(
        chunkedIds.map(async (chunk) => {
          const cropPlans = await requestOptimizeCropPlans(chunk);
          return cropPlans;
        }),
      );
      dispatch(receiveCropPlanOptimize(optimizedPlans.flat()));
      setToast(onSuccessMsg);
    } catch (e) {
      dispatch(cropPlanOptimizeError({ message: e.message }));
      setToast(onFailureMsg, 'error');
    }
  };
