import {
  CHEMISTRY_RESULTS_REQUEST_ERROR,
  RECEIVE_CHEMISTRY_RESULTS,
  REQUEST_CHEMISTRY_RESULTS,
  ChemistryResultActionType,
  ChemistryResultType,
} from './types';

export const requestChemistryResults = (): ChemistryResultActionType => ({
  type: REQUEST_CHEMISTRY_RESULTS,
});

export const receiveChemistryResults = (
  barcode: string,
  results: ChemistryResultType[],
): ChemistryResultActionType => ({
  type: RECEIVE_CHEMISTRY_RESULTS,
  payload: {
    barcode,
    results,
  },
});

export const chemistryResultsRequestError = (payload: {
  message: string;
}): ChemistryResultActionType => ({
  type: CHEMISTRY_RESULTS_REQUEST_ERROR,
  payload,
});
