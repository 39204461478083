import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { ControlType } from './types';

export const requestDeleteControl = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/control/${id}`));

export const requestGetControls = async () =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/controls/`));

export const requestPutControl = async (id: number, body: Partial<ControlType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/control/${id}`, { body }));

export const requestPostControl = async (body: Partial<ControlType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/control/`, { body }));
