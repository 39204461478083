import { roundTwoOrZero } from 'util/numUtils';
import { buAcToSacaHa, dollarsTonToRealMetricTon, lbsAcToKgHa, lbsToKg } from 'util/units';
import { getBrowserLanguage } from 'util/hooks/useLanguage';
import { DNA_COPIES, EGGS, WORMS } from 'constants/results';
import { ENGLISH } from 'constants/languages';
import { getString } from 'strings/translation';
import {
  FieldType,
  FieldActionsType,
  DELETE_FIELD,
  FIELD_GEOMETRY_ERROR,
  RECEIVE_FIELD_GEOMETRY,
  RECEIVE_FIELD_GEOMETRIES,
  REQUEST_FIELD_GEOMETRIES,
  FIELD_GEOMETRIES_ERROR,
  RECEIVE_SAMPLE_PLAN,
  REQUEST_FIELD_GEOMETRY,
  GeometryType,
  SamplingPlanType,
  SET_FIELDS_WILL_FETCH,
  FieldPropertiesType,
  RECEIVE_PRESCRIPTION,
  CLEAR_FIELD_GEOMETRIES,
  REQUEST_CROP_PLAN_OPTIMIZE,
  RECEIVE_CROP_PLAN_OPTIMIZE,
  CROP_PLAN_OPTIMIZE_ERROR,
  REFRESH_FIELDS,
} from './types';
import { PrescriptionType } from '../prescriptions/types';
import { CropPlanType } from 'store/cropPlans/types';

export type FieldStateType = {
  geometries: GeometryType;
  hasFailedList: number[];
  hasFetchedList: number[];
  isFetchingList: number[];
  willFetchList: number[];
  isOptimizingCropPlans: boolean;
  hasFailedOptimize: boolean;
};

export const initialState: FieldStateType = {
  geometries: {},
  hasFailedList: [],
  hasFetchedList: [],
  isFetchingList: [],
  willFetchList: [],
  isOptimizingCropPlans: false,
  hasFailedOptimize: false,
};

const convertPrescriptions = (prescriptions: PrescriptionType[], unit: string) =>
  prescriptions.map((script) => ({
    ...script,
    minimum_rate: roundTwoOrZero(script.minimum_rate, lbsAcToKgHa, unit),
    expected_yield: roundTwoOrZero(script.expected_yield, buAcToSacaHa, unit),
    cost_per_ton: roundTwoOrZero(script.cost_per_ton, dollarsTonToRealMetricTon, unit),
    field_rate_average: roundTwoOrZero(script.field_rate_average, lbsAcToKgHa, unit),
    total_amount_applied: roundTwoOrZero(script.total_amount_applied, lbsToKg, unit),
  }));

const translatePlan = (plan: SamplingPlanType, language: string) => {
  if (language === ENGLISH) {
    return plan;
  }
  return JSON.parse(
    [EGGS, DNA_COPIES, WORMS].reduce((all, key) => {
      return all.replaceAll(key, getString(key, language).toLowerCase());
    }, JSON.stringify(plan)),
  );
};

const translatePlanUnits = (properties: FieldPropertiesType) => {
  const language = getBrowserLanguage(navigator.language);
  return properties.sampling_plans.map((plan) => translatePlan(plan, language));
};
const applyNewGeometry = (
  geometries: GeometryType,
  { fieldId, geometry }: { fieldId: number; geometry: FieldType },
) => ({
  ...geometries,
  [fieldId]: {
    ...geometry,
    features: geometry.features.map((feat) => ({
      ...feat,
      properties: {
        ...feat.properties,
        sampling_plans: translatePlanUnits(feat.properties),
        prescriptions: convertPrescriptions(
          feat.properties.prescriptions,
          feat.properties.acreage_unit,
        ),
      },
    })),
  },
});

const applyNewGeometries = (
  oldGeometries: GeometryType,
  { fieldIds, geometries }: { fieldIds: Array<number>; geometries: Array<FieldType> },
) => {
  const tempGeometries = { ...oldGeometries };
  fieldIds.forEach((id, index) => {
    tempGeometries[id] = geometries[index];
  });

  return tempGeometries;
};

const updateSamplePlan = (
  geometries: GeometryType,
  { fieldId, samplePlan }: { fieldId: number; samplePlan: SamplingPlanType },
) => {
  if (!geometries[fieldId]) {
    return geometries;
  }

  const newSamplePlans = geometries[fieldId].features[0].properties.sampling_plans.map((plan) =>
    plan.id === samplePlan.id ? samplePlan : plan,
  );
  geometries[fieldId].features[0].properties.sampling_plans = newSamplePlans;
  return {
    ...geometries,
    [fieldId]: { ...geometries[fieldId] },
  };
};

const updatePrescriptions = (
  geometries: GeometryType,
  { fieldId, prescription }: { fieldId: number; prescription: PrescriptionType },
) => {
  if (!geometries[fieldId]) {
    return geometries;
  }

  const newPrescriptions = geometries[fieldId].features[0].properties.prescriptions.map(
    (existing) => (existing.id === prescription.id ? prescription : existing),
  );
  geometries[fieldId].features[0].properties.prescriptions = newPrescriptions;
  return {
    ...geometries,
    [fieldId]: { ...geometries[fieldId] },
  };
};

const removeField = (geometries: GeometryType, fieldId: number) => {
  return Object.keys(geometries)
    .filter((gId) => gId !== String(fieldId))
    .reduce(
      (newGeometry, key) =>
        applyNewGeometry(newGeometry, {
          fieldId: Number(key),
          geometry: geometries[Number(key)],
        }),
      {},
    );
};

const updateOptimiziedCropPlans = (cropPlans: CropPlanType[], geometries: GeometryType) => {
  const newGeometries = { ...geometries };
  return cropPlans.reduce((geoms, plan) => {
    const field = newGeometries[plan.field_id];
    return {
      ...geoms,
      [plan.field_id]: {
        ...field,
        features: field.features.map((feat) => ({
          ...feat,
          properties: {
            ...feat.properties,
            crop_plans: feat.properties.crop_plans.map((existing) =>
              existing.id === plan.id ? plan : existing,
            ),
          },
        })),
      },
    };
  }, geometries);
};

export const fieldGeometryReducer = (
  state = initialState,
  action: FieldActionsType,
): FieldStateType => {
  switch (action.type) {
    case REFRESH_FIELDS:
      return {
        ...state,
        hasFetchedList: [],
        isFetchingList: [],
      };
    case RECEIVE_FIELD_GEOMETRY:
      return {
        ...state,
        geometries: applyNewGeometry(state.geometries, action.payload),
        hasFetchedList: state.hasFetchedList.concat(action.payload.fieldId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.fieldId),
      };
    case RECEIVE_SAMPLE_PLAN:
      return {
        ...state,
        geometries: updateSamplePlan(state.geometries, action.payload),
        hasFetchedList: state.hasFetchedList.concat(action.payload.fieldId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.fieldId),
      };
    case RECEIVE_PRESCRIPTION:
      return {
        ...state,
        geometries: updatePrescriptions(state.geometries, action.payload),
        hasFetchedList: state.hasFetchedList.concat(action.payload.fieldId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.fieldId),
      };
    case REQUEST_FIELD_GEOMETRY:
      return {
        ...state,
        isFetchingList: state.isFetchingList.concat(action.payload.fieldId),
      };
    case FIELD_GEOMETRY_ERROR:
      return {
        ...state,
        hasFailedList: state.hasFailedList.concat(action.payload.fieldId),
        isFetchingList: state.isFetchingList.filter((id) => id !== action.payload.fieldId),
      };
    case DELETE_FIELD:
      return {
        ...state,
        geometries: removeField(state.geometries, action.payload.fieldId),
      };
    case SET_FIELDS_WILL_FETCH:
      return {
        ...state,
        willFetchList: action.payload.fieldIds,
      };
    case RECEIVE_FIELD_GEOMETRIES:
      return {
        ...state,
        geometries: applyNewGeometries(state.geometries, action.payload),
        hasFetchedList: state.hasFetchedList.concat(action.payload.fieldIds),
        isFetchingList: state.isFetchingList.filter((id) => !action.payload.fieldIds.includes(id)),
      };
    case REQUEST_FIELD_GEOMETRIES:
      return {
        ...state,
        isFetchingList: state.isFetchingList.concat(action.payload.fieldIds),
      };
    case CLEAR_FIELD_GEOMETRIES:
      return {
        ...state,
        geometries: {},
      };
    case FIELD_GEOMETRIES_ERROR:
      return {
        ...state,
        hasFailedList: state.hasFailedList.concat(action.payload.fieldIds),
        isFetchingList: state.isFetchingList.filter((id) => !action.payload.fieldIds.includes(id)),
      };
    case REQUEST_CROP_PLAN_OPTIMIZE:
      return {
        ...state,
        isOptimizingCropPlans: true,
      };
    case RECEIVE_CROP_PLAN_OPTIMIZE:
      return {
        ...state,
        isOptimizingCropPlans: false,
        geometries: updateOptimiziedCropPlans(action.payload.cropPlans, state.geometries),
        hasFailedOptimize: false,
      };
    case CROP_PLAN_OPTIMIZE_ERROR:
      return {
        ...state,
        isOptimizingCropPlans: false,
        hasFailedOptimize: true,
      };
    default:
      return state;
  }
};
