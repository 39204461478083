import { AppThunk } from '../index';
import {
  requestAllTerritories,
  requestPostCommercialTerritory,
  requestTerritory,
} from './requests';
import {
  requestTerritories,
  receiveAllTerritories,
  territoryRequestError,
  receiveSingleTerritory,
} from './actions';
import { CommercialTerritoryType } from './types';

export const getAllTerritories = (): AppThunk => async (dispatch) => {
  dispatch(requestTerritories());
  try {
    const response = await requestAllTerritories();
    return dispatch(receiveAllTerritories(response));
  } catch (e) {
    return dispatch(territoryRequestError());
  }
};

export const getTerritory =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(requestTerritories(id));
    try {
      const response = await requestTerritory(id);
      return dispatch(receiveSingleTerritory(id, response));
    } catch (e) {
      return dispatch(territoryRequestError(id));
    }
  };

export const updateAgencyDetails =
  (id: number, territory: CommercialTerritoryType): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestPostCommercialTerritory(territory);
      dispatch(receiveSingleTerritory(id, response));
    } catch (e) {
      throw dispatch(territoryRequestError());
    }
  };
