// eslint-disable-next-line import/prefer-default-export
export const taxonomicRanks = {
  DOMAIN: 'domain',
  KINGDOM: 'kingdom',
  PHYLUM: 'phylum',
  CLASS: 'class',
  ORDER: 'order',
  FAMILY: 'family',
  GENUS: 'genus',
  SPECIES: 'species',
};
