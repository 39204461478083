import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Input, Loader, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { editChemistryResults } from 'store/labSamples/thunks';
import { endEllipsis, formatFloatString } from 'util/stringUtils';
import { KEYS } from 'constants/keyCodes';

type EditPhPropsType = {
  sample: LabSampleType;
};

export const EditPh = ({ sample }: EditPhPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ph, setPh] = useState(String(sample.ph || ''));
  const [bph, setBph] = useState(String(sample.bph || ''));

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setPh(String(sample.ph || ''));
    setBph(String(sample.bph || ''));
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    if (sample.barcode) {
      try {
        setIsEditing(false);
        setIsSubmitting(true);
        await dispatch(editChemistryResults(sample.barcode, parseFloat(ph), parseFloat(bph)));
        setIsSubmitting(false);
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      handleSubmit();
    }
  };

  const phDisplay = (
    <Text>{sample.ph ? endEllipsis(String(sample.ph), 5) : getString('noData', language)}</Text>
  );
  const bphDisplay = (
    <Text>{sample.bph ? endEllipsis(String(sample.bph), 5) : getString('noData', language)}</Text>
  );

  const phInput = (
    <Input
      w="3.5rem"
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setPh(formatFloatString(event.target.value))
      }
      onKeyUp={handleOnKeyUp}
      value={ph}
    />
  );

  const bphInput = (
    <Input
      w="3.5rem"
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setBph(formatFloatString(event.target.value))
      }
      onKeyUp={handleOnKeyUp}
      value={bph}
    />
  );

  const startEditButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={() => setIsEditing(true)}
        disabled={!sample.arrived_at || isSubmitting}
      >
        {getString(isSubmitting ? 'submitting' : 'update', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const finishEditButtons = (
    <Group gap="xs">
      <Button variant="outline" onClick={handleSubmit} disabled={!ph.length && !bph.length}>
        {getString('submit', language)}
      </Button>
      <Button variant="filled" color="red.8" onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </Group>
  );

  return (
    <Group>
      <Group gap="sm" w="13.5rem" wrap="nowrap">
        <Group gap="xs" miw="6rem">
          <Text fw="700">{`pH: `}</Text>
          {isEditing ? phInput : phDisplay}
        </Group>
        <Group gap="xs" miw="6rem">
          <Text fw="700">{`BpH: `}</Text>
          {isEditing ? bphInput : bphDisplay}
        </Group>
      </Group>
      {isEditing ? finishEditButtons : startEditButton}
    </Group>
  );
};
