import React from 'react';
import { Group, Text } from '@mantine/core';
import { DataRangeType } from 'store/analytics/types';
import { getString } from 'strings/translation';
import { rangeToString } from 'util/results';
import styles from './Mapbook.module.css';

type Props = {
  language: string;
  dataSummary: DataRangeType;
};

const MapbookRangesSection = ({ language, dataSummary }: Props) => {
  const { low, moderate, high } = dataSummary;

  return (
    <Group gap="xs">
      <Text fw={500}>{getString('ranges', language)}: </Text>
      <Text>
        <b>{getString('low', language)}: </b>
        {rangeToString(low, 2)}
      </Text>
      <Text>
        <b>{getString('mod', language)}: </b>
        {rangeToString(moderate, 2)}
      </Text>
      <Text className={styles.InfoSpace}>
        <b>{getString('high', language)}: </b>
        {rangeToString(high, 2)}
      </Text>
    </Group>
  );
};

export default MapbookRangesSection;
