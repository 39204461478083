import React from 'react';
import { Group, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { ProductBin } from 'common';
import { LabSampleType } from 'store/labSamples/types';

type ProductOrderedPropsType = {
  sample: LabSampleType;
  hideLabel?: boolean;
};

export const ProductOrdered = ({ sample, hideLabel = false }: ProductOrderedPropsType) => {
  const language = useBroswerLanguage();
  return (
    <Group align="center" gap="xl">
      {!hideLabel && (
        <Text fw={700} w="4.2rem">
          {getString('productOrdered', language)}
        </Text>
      )}
      <ProductBin sample={sample} />
    </Group>
  );
};
