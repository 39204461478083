import {
  AA_ANALYSIS,
  AMPLICON_QPCR,
  AMPLICON_QPCR_384,
  AMPLICON_QPCR_96,
  basicPlateStatusList,
  DNA_EXTRACTION,
  HOMOGENIZATION,
  NITRATE_ANALYSIS,
  OM_ANALYSIS,
  M3_ANALYSIS,
  NORMALIZED_DNA,
  PH_ANALYSIS,
  plateFormRequiredAttributes,
  plateStatusDisplayNames,
  plateTypeDisplayNames,
  plateTypeShortNames,
  QPCR,
  QPCR_384,
  QPCR_96,
  searchPlateTypes,
  SHOTGUN,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
} from 'constants/plates';
import { PlateType, WellLookupType, WellType } from 'store/plates/types';
import { getString } from 'strings/translation';
import { LabSampleType, SamplePlateSummaryType } from 'store/labSamples/types';
import { analysisToPlateTypes, genomicAnalysisList } from 'constants/analysis';
import { getBatchNamePlusText } from 'util/batches';
import { getAnalysisDisplayName } from 'util/analysis';
import { batchTypesForPlate } from 'constants/batches';
import {
  extractionMethodDescriptions,
  extractionMethodDisplayNames,
  extractionMethods,
} from 'constants/extraction';
import { soilProcessingTypes } from 'constants/soilProcessing';

export const getPlateDisplayName = (plateType: string, language: string) => {
  if (
    [
      HOMOGENIZATION,
      DNA_EXTRACTION,
      NORMALIZED_DNA,
      SHOTGUN,
      M3_ANALYSIS,
      NITRATE_ANALYSIS,
      PH_ANALYSIS,
      AA_ANALYSIS,
      OM_ANALYSIS,
      PHOSPHATE_ANALYSIS,
      CHEMISTRY_BACKUP,
    ].includes(plateType)
  ) {
    return getString(plateTypeDisplayNames[plateType], language);
  }
  if ([AMPLICON_QPCR_96, AMPLICON_QPCR_384].includes(plateType)) {
    return plateTypeDisplayNames[AMPLICON_QPCR];
  }

  return plateTypeDisplayNames[plateType];
};

export const getPlateShortName = (plateType: string, language: string) => {
  if (
    [
      HOMOGENIZATION,
      DNA_EXTRACTION,
      NORMALIZED_DNA,
      SHOTGUN,
      AMPLICON_QPCR,
      NITRATE_ANALYSIS,
      CHEMISTRY_BACKUP,
    ].includes(plateType)
  ) {
    return getString(plateTypeShortNames[plateType], language);
  }
  if ([QPCR_96, QPCR_384].includes(plateType)) {
    return plateTypeShortNames[QPCR];
  }
  if ([AMPLICON_QPCR_96, AMPLICON_QPCR_384].includes(plateType)) {
    return getString(plateTypeShortNames[AMPLICON_QPCR], language);
  }

  return plateTypeShortNames[plateType];
};

export const getPlateStatusDisplayName = (statusType: string, language: string) => {
  if (statusType !== QPCR) {
    return getString(plateStatusDisplayNames[statusType], language);
  }

  return plateStatusDisplayNames[statusType];
};

export const getPlateNamePlusText = (plateType: string, stringKey: string, language: string) => {
  return `${getPlateShortName(plateType, language)} ${getString(stringKey, language)}`;
};

const aChar = 'a'.charCodeAt(0);

export const getMaxWellsByPlateType = (plateType?: string) => {
  switch (plateType) {
    case M3_ANALYSIS:
    case NITRATE_ANALYSIS:
    case AA_ANALYSIS:
    case PHOSPHATE_ANALYSIS:
      return { maxRows: 4, maxCols: 6 };
    case PH_ANALYSIS:
      return { maxRows: 18, maxCols: 10 };
    case OM_ANALYSIS:
      return { maxRows: 10, maxCols: 4 };
    default:
      return { maxRows: 8, maxCols: 12 };
  }
};

export const getWellCountByPlateType = (plateType?: string) => {
  const { maxRows, maxCols } = getMaxWellsByPlateType(plateType);
  return maxRows * maxCols;
};

export const wellRows = (maxRows: number = 8) =>
  Array.from(Array(maxRows).keys()).map((i) => String.fromCharCode(aChar + i));

export const wellCols = (maxCols: number = 12) =>
  Array.from(Array(maxCols).keys()).map((i) => String(i + 1));

export const getWellRowColLists = (plateType?: string) => {
  const { maxRows, maxCols } = getMaxWellsByPlateType(plateType);
  return {
    rows: wellRows(maxRows),
    columns: wellCols(maxCols),
  };
};

export const getNextWell = (
  row: string,
  column: string,
  maxRows: number = 8,
  maxCols: number = 12,
) => {
  const rows = wellRows(maxRows);
  const cols = wellCols(maxCols);
  const rIdx = rows.indexOf(row);
  const cIdx = cols.indexOf(column);
  if (cIdx >= cols.length - 1) {
    if (rIdx >= rows.length - 1) {
      return null;
    }
    return { row: rows[rIdx + 1], column: cols[0] };
  }
  return { row, column: cols[cIdx + 1] };
};

export const isWellEmpty = (well: Partial<WellType>) => {
  return !well.sample_uuid;
};

export const getNextEmptyWell = (
  wellsIndex: WellLookupType,
  currentRow: string,
  currentColumn: string,
  maxRows: number = 8,
  maxCols: number = 12,
): { row: string; column: string } | null => {
  const nextWell = getNextWell(currentRow, currentColumn, maxRows, maxCols);
  if (!nextWell) {
    return null;
  }
  if (wellsIndex[nextWell.row] && wellsIndex[nextWell.row][nextWell.column]) {
    return getNextEmptyWell(wellsIndex, nextWell.row, nextWell.column, maxRows, maxCols);
  }
  return nextWell;
};

export const generateWellsIndex = (wells: WellType[] = [], quadrant?: number) => {
  return wells
    .filter((well) => (quadrant ? well.plate_quadrant === quadrant : true))
    .reduce(
      (acc, well) => {
        const row = well.row.toLowerCase();
        return {
          ...acc,
          [row]: {
            ...(acc[row] || {}),
            [well.column]: {
              ...well,
            },
          },
        };
      },
      {} as { [row: string]: { [column: string]: WellType } },
    );
};

export const getPlatingStatusForAnalysis = (
  sample: LabSampleType,
  analysis: string | null,
  language: string,
) => {
  if (analysis) {
    for (const plateType of analysisToPlateTypes[analysis]) {
      const summary = sample.plates_summary?.[plateType];
      if (summary?.length) {
        const batchType = batchTypesForPlate[plateType] ?? null;
        return batchedOrPlatedText(summary, plateType, batchType, language);
      }
    }
  }
  return null;
};

const batchedOrPlatedText = (
  plateSummary: SamplePlateSummaryType[],
  plateType: string,
  batchType: string | null,
  language: string,
) => {
  const batched = batchType ? getBatchNamePlusText(batchType, 'batched', language) : null;
  const plated = getPlateNamePlusText(plateType, 'plated', language);
  return plateSummary.filter((p) => p.batch_id).length && batched ? batched : plated;
};

export const isPlateFormValid = (plate: Partial<PlateType>, plateType: string) => {
  const requiredAttributes = plateFormRequiredAttributes[plateType] as (keyof PlateType)[];

  if (requiredAttributes?.length) {
    if (requiredAttributes.includes('quadrant1_plate_barcode') && !validateQuadrants(plate)) {
      return false;
    }

    return requiredAttributes.every((attribute) => Boolean(plate[attribute]));
  }

  return false;
};

const validateQuadrants = (plate: Partial<PlateType>) => {
  const quadrants = [1, 2, 3, 4].filter(
    (n) =>
      plate[`quadrant${n}_plate_barcode`] &&
      (plate[`quadrant${n}_qpcr_target`] || plate[`quadrant${n}_index_set_name`]),
  );
  return quadrants.every((n, i) => n === i + 1);
};

export const cleanupPlateForm = (plate: Partial<PlateType>) => {
  [1, 2, 3, 4].forEach((n) => {
    const barcode = plate[`quadrant${n}_plate_barcode`];
    plate[`quadrant${n}_plate_barcode`] = barcode || undefined;
    plate[`quadrant${n}_qpcr_target`] = barcode ? plate[`quadrant${n}_qpcr_target`] : undefined;
    plate[`quadrant${n}_index_set_name`] = barcode
      ? plate[`quadrant${n}_index_set_name`]
      : undefined;
  });
  return plate;
};

export const getPlateTypeOptions = (language: string, plates: string[] = searchPlateTypes) =>
  plates.map((plateType, idx) => ({
    id: idx + 1,
    displayName: getPlateDisplayName(plateType, language),
    label: getPlateDisplayName(plateType, language),
    value: plateType,
  }));

export const getPlateStatusOptions = (
  language: string,
  statuses: string[] = basicPlateStatusList,
) =>
  statuses.map((statusType, idx) => ({
    id: idx + 1,
    displayName: getPlateStatusDisplayName(statusType, language),
    label: getPlateStatusDisplayName(statusType, language),
    value: statusType,
  }));

export const getPlateAnalysisOptions = (
  language: string,
  analyses: string[] = genomicAnalysisList,
) =>
  analyses.map((analysisType, idx) => ({
    id: idx + 1,
    displayName: getAnalysisDisplayName(analysisType, language),
    label: getAnalysisDisplayName(analysisType, language),
    value: analysisType,
  }));

export const getExtractionMethodOptions = (
  language: string,
  extractions: string[] = extractionMethods,
) =>
  extractions.map((method, idx) => ({
    id: idx,
    label: extractionMethodDisplayNames[method],
    value: method,
    description: getString(extractionMethodDescriptions[method], language),
  }));

export const getSoilProcessingOptions = (
  language: string,
  processingTypes: string[] = soilProcessingTypes,
) =>
  processingTypes.map((soil, idx) => ({
    id: idx,
    label: getString(soil, language),
    value: soil,
  }));
