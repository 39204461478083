import React, { useEffect, useRef, useState, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from './Button';
import { DropdownArrowIcon } from './Icon';

import styles from './MultiSelector.module.css';

type Option = {
  id: string | number;
  displayName: string | ReactNode;
  inactive?: boolean;
};

type MultiSelectorProps = {
  activeIndices: number[];
  className?: string;
  disabled?: boolean;
  icon?: string;
  menuClassName?: string;
  onSelect: (idx: number) => void;
  onDeselect: (idx: number) => void;
  options: Option[];
  placeholder?: string;
  required?: boolean;
  testId?: string;
  hideOverflow?: boolean;
};

export const MultiSelector: FunctionComponent<MultiSelectorProps> = ({
  activeIndices,
  className,
  disabled,
  icon,
  menuClassName,
  onSelect,
  onDeselect,
  options,
  placeholder,
  required,
  testId,
  hideOverflow,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      event.target instanceof Node &&
      !menuRef.current.contains(event.target)
    ) {
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const activeOptions =
    activeIndices.length !== undefined
      ? options.filter((option, index) => activeIndices.includes(index))
      : [];

  const handleSelect = (index: number) => {
    if (!activeIndices.includes(index)) {
      onSelect(index);
    } else {
      onDeselect(index);
    }
  };

  const Menu = (
    <div className={classNames(styles.Menu, menuClassName)}>
      {options.map((option, index) => {
        return (
          <Button
            light
            square
            className={classNames(styles.Option, {
              [styles.OptionInactive]: option.inactive,
              [styles.OptionSelected]: activeOptions.includes(option),
            })}
            key={option.id}
            onClick={() => {
              if (!option.inactive) {
                handleSelect(index);
                setIsMenuVisible(false);
              }
            }}
          >
            {option.displayName}
          </Button>
        );
      })}
    </div>
  );

  const wrapperClassname = classNames(styles.Wrapper, className, {
    [styles.RequiredError]: !activeOptions && required,
  });

  return (
    <div className={wrapperClassname} data-test-id={testId} ref={menuRef}>
      <div
        className={classNames(styles.Selector, {
          [styles.Disabled]: disabled || options.length === 0,
        })}
        onClick={(e) => {
          if (disabled || options.length === 0) {
            return;
          }
          // TODO preventDefault prevents misfiring clicks on the Menu while the menu is opening, why?
          // without this, the menu item will be selected by the same click that opens the Menu
          e.preventDefault();
          setIsMenuVisible(!isMenuVisible);
        }}
      >
        {icon ? <img alt="" className={styles.Icon} src={icon} /> : null}
        <span className={classNames(styles.Title, { [styles.HideOverflow]: hideOverflow })}>
          {(activeOptions.length && activeOptions.map((option) => option.displayName).join(', ')) ||
            placeholder ||
            '---'}
        </span>
        <DropdownArrowIcon className={styles.DropdownIcon} />
      </div>
      {isMenuVisible ? Menu : null}
    </div>
  );
};

MultiSelector.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  menuClassName: '',
  testId: '',
};
