import { handleJsonResponse, requestGet, SERVICE_URL, requestPut } from 'util/request';

export const requestGetSearchSample = async (searchValue: string) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/samples_search/${searchValue}`));

export const requestGetLabSamplesForField = async (fieldId: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/lab_samples/${fieldId}`));

export const requestPutMakeSampleInstancePrimary = async (uuid: string) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/make_sample_primary/${uuid}`));

export const requestPutMarkSampleInstanceArrived = async (sampleUuid: string) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/make_arrived/${sampleUuid}`));

export const requestPutMarkSampleInstanceSterilized = async (sampleUuid: string) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/mark_sterilized/${sampleUuid}`));

export const requestPutSampleChemistryResults = async (
  barcode: string,
  ph: number | null,
  bph: number | null,
) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/chemistry_result_manual_record/${barcode}`, {
      body: { ph, bph },
    }),
  );
