import React from 'react';
import { PlateType } from 'store/plates/types';

import { EditNotes } from '../../Sections/Inputs/EditNotes';
import { ChildrenPlates } from '../../Sections/RelatedPlates/ChildrenPlates';
import { ParentPlates } from '../../Sections/RelatedPlates/ParentPlates';
import { PlateDocumentsContainer } from '../../Sections/PlateDocuments/Container';
import DNAConcentrationCalculator from '../../Sections/DNAConcentration/DNAConcentrationCalculator';
import styles from '../Container.module.css';

type NormalizedDNALayoutPropsType = {
  plate: PlateType;
};

export const NormalizedDNALayout = ({ plate }: NormalizedDNALayoutPropsType) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <div className={styles.Column}>
          <ParentPlates plate={plate} />
          {plate.related_plates?.parents_plates?.length ? (
            <DNAConcentrationCalculator
              plateBarcode={plate.related_plates?.parents_plates[0].barcode}
              targetDilution={plate.meta_data?.target_plate_dilution}
              totalDNAMultiplier={plate.meta_data?.total_dna_multiplier}
              totalPCRMultiplier={plate.meta_data?.total_pcr_multiplier}
            />
          ) : null}
        </div>
        <div className={styles.Column}>
          <ChildrenPlates plate={plate} />
          <EditNotes plate={plate} />
          <PlateDocumentsContainer plate={plate} />
        </div>
      </div>
    </div>
  );
};
