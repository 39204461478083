import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Banner } from 'common';
import { LabSampleType } from 'store/labSamples/types';

type RestrictedGeoPropsType = {
  sample: LabSampleType;
  className?: string;
};

export const RestrictedGeo = ({ sample, className }: RestrictedGeoPropsType) => {
  const language = useBroswerLanguage();
  return (
    <>
      {sample.county?.restricted && (
        <div className={className}>
          <Banner
            headingText={`${getString('restrictedGeography', language)}:`}
            bodyText={`${getString('restrictedGeographyFromMsg', language)} ${
              sample.county.county
            }, ${sample.county.state}. ${getString('restrictedGeogrpahyProtocolsMsg', language)}.`}
          />
        </div>
      )}
    </>
  );
};
