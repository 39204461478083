import { LabSampleType } from 'store/labSamples/types';

export type StorePlateData = {
  freezer: string;
  temp: string;
  rack: string;
  shelf: string;
};

export type PlateUploadedFileType = {
  id: number;
  plate_barcode: string;
  file_name: string;
  url: string;
  document_type?: string;
  notes?: string;
  updated_at: string;
  created_at: string;
  uploaded: boolean;
  upload_url: string;
  download_url: string;
};

export type PlateType = {
  amplicon: null | string;
  barcode: string;
  created_at: string;
  freezer?: string;
  homogenization_plate_barcode?: string;
  homogenization_plate_id?: number;
  id: number;
  meta_data?: {
    qpcr_cycles?: number;
    [key: string]: any;
  };
  notes: null | string;
  plate_type: string;
  quadrant1_index_set_name?: string;
  quadrant1_plate_barcode?: string;
  quadrant2_index_set_name?: string;
  quadrant2_plate_barcode?: string;
  quadrant3_index_set_name?: string;
  quadrant3_plate_barcode?: string;
  quadrant4_index_set_name?: string;
  quadrant4_plate_barcode?: string;
  rack?: number;
  shelf?: string;
  source_plate_barcode?: string;
  source_plate_quadrant?: string;
  status: string;
  temp?: number;
  updated_at?: string;
  uploaded_files?: string[];
  related_plates?: {
    children_plates?: PlateType[];
    parents_plates?: PlateType[];
    homogenization?: PlateType[];
  };
  related_documents?: PlateUploadedFileType[];
  wells: Array<WellType>;
  analysis_type: string;
  sample_sheet_url?: string;
  sample_sheet_name?: string;
  barcode_sheet_url?: string;
  barcode_sheet_name?: string;
  batch_order: number;
  batch_id?: number;
  well_count?: number;
  samples_arrived_at?: string;
  arrived_at?: string;
  is_regulated?: boolean;
  extraction_method: string | null;
  soil_processing_type: string;

  quadrant1_qpcr_target?: string;
  quadrant2_qpcr_target?: string;
  quadrant3_qpcr_target?: string;
  quadrant4_qpcr_target?: string;
  lanes?: number[];
};

export type WellType = {
  id: number;
  created_at: string;
  updated_at?: string;
  sample_uuid?: string;
  row: string;
  column: string;
  source_well?: number;
  plate: number;
  plate_barcode: string;
  plate_quadrant?: number;
  // Sample attributes
  sample_barcode?: LabSampleType['barcode'];
  sample_type?: LabSampleType['sample_type_repr'];
  sample_weight?: LabSampleType['sample_weight'];
  sampling_plan_id?: LabSampleType['sampling_plan_id'];
  field_name?: LabSampleType['field_name'];
  field_id?: LabSampleType['field_id'];
  operation_name?: LabSampleType['operation_name'];
  operation_id?: LabSampleType['operation_id'];
  lab_notes?: LabSampleType['lab_notes'];
  replica_type?: LabSampleType['replica_type'];
  rnd?: LabSampleType['rnd'];
  rnd_meta?: LabSampleType['rnd_meta'];
  rnd_notes?: LabSampleType['rnd_notes'];
  tag_names?: LabSampleType['tag_names'];
  // Submission attributes
  sample?: Partial<LabSampleType>;
  type?: 'soil' | 'residue' | 'root_ball' | 'rhizosphere' | 'isolate' | 'root_tissue';
  notes?: LabSampleType['lab_notes'];
  lat?: string;
  lng?: string;
  // Grid attributes
  value?: string | number;
};

export type WellLookupType = {
  [row: string]: {
    [col: string]: WellType;
  };
};

export type PlatesStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  isFetchingInfo: boolean;
  byBarcode: {
    [key: string]: PlateType;
  };
  plates: {
    items: PlateType[];
    page: number;
    total: number;
  };
};

export const REQUEST_PLATES = 'REQUEST_PLATES';
export const REQUEST_PLATES_INFO = 'REQUEST_PLATES_INFO';
export const RECEIVE_PLATES = 'RECEIVE_PLATES';
export const RECEIVE_PLATE = 'RECEIVE_PLATE';
export const RECEIVE_PLATE_WELL = 'RECEIVE_PLATE_WELL';
export const RECEIVE_RELATED_PLATES = 'RECEIVE_RELATED_PLATES';
export const RECEIVE_HOMOGEN_RELATED_PLATES = 'RECEIVE_HOMOGEN_RELATED_PLATES';
export const PLATES_REQUEST_ERROR = 'PLATES_REQUEST_ERROR';

type RequestPlatesActionType = {
  type: typeof REQUEST_PLATES;
};

type RequestPlatesInfoActionType = {
  type: typeof REQUEST_PLATES_INFO;
};

type ReceivePlatesActionType = {
  type: typeof RECEIVE_PLATES;
  payload: {
    page: number;
    per_page: number;
    total: number;
    plates: PlateType[];
  };
};

type ReceivePlateActionType = {
  type: typeof RECEIVE_PLATE;
  payload: PlateType;
};

type ReceivePlateWellActionType = {
  type: typeof RECEIVE_PLATE_WELL;
  payload: {
    plateBarcode: string;
    well: WellType;
  };
};

type ReceiveRelatedPlatesActionType = {
  type: typeof RECEIVE_RELATED_PLATES;
  payload: {
    plateBarcode: string;
    relatedPlates: PlateType['related_plates'];
  };
};

type ReceiveHomogenRelatedPlatesActionType = {
  type: typeof RECEIVE_HOMOGEN_RELATED_PLATES;
  payload: {
    plateBarcode: string;
    homogenRelated: PlateType[];
  };
};

type PlateRequestErrorType = {
  type: typeof PLATES_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type PlateActionsType =
  | RequestPlatesActionType
  | RequestPlatesInfoActionType
  | ReceivePlatesActionType
  | ReceivePlateActionType
  | ReceivePlateWellActionType
  | ReceiveRelatedPlatesActionType
  | ReceiveHomogenRelatedPlatesActionType
  | PlateRequestErrorType;
