import {
  BORON_ID,
  BPH_SIKORA_ID,
  CALCIUM_ID,
  CEC_ID,
  COPPER_ID,
  IRON_ID,
  MAGNESIUM_ID,
  MANGANESE_ID,
  NITROGEN_ID,
  OM_ID,
  PHOSPHORUS_ID,
  PH_ID,
  POTASSIUM_ID,
  P_BRAY_ID,
  SULFUR_ID,
  ZINC_ID,
} from 'constants/results';
import { SampleType } from 'store/fields/types';
import { getString } from 'strings/translation';
import { roundToNPlaces } from 'util/numUtils';

const getRoundedValueIfExists = (value?: number | null, numberOfDecimalPlaces = 0): '' | number => {
  if (typeof value !== 'number') {
    return '';
  }

  if (!numberOfDecimalPlaces) {
    return Math.round(value);
  }

  const roundedValue = roundToNPlaces(numberOfDecimalPlaces)(value);

  return typeof roundedValue === 'number' ? roundedValue : '';
};

export const get590RowData = ({
  analytics,
  start_depth,
  end_depth,
  phosphate_processed,
}: SampleType) => {
  const { 'soil attributes': chem } = analytics;

  return {
    boron: getRoundedValueIfExists(chem[BORON_ID]?.quantity, 2),
    calcium: getRoundedValueIfExists(chem[CALCIUM_ID]?.quantity),
    cec: getRoundedValueIfExists(chem[CEC_ID]?.quantity, 1),
    copper: getRoundedValueIfExists(chem[COPPER_ID]?.quantity, 2),
    depth: `${start_depth || 0}-${end_depth || 6} in`,
    iron: getRoundedValueIfExists(chem[IRON_ID]?.quantity, 2),
    magnesium: getRoundedValueIfExists(chem[MAGNESIUM_ID]?.quantity),
    manganese: getRoundedValueIfExists(chem[MANGANESE_ID]?.quantity, 2),
    nitrogen: getRoundedValueIfExists(chem[NITROGEN_ID]?.quantity),
    om: getRoundedValueIfExists(chem[OM_ID]?.quantity, 1),
    ph: getRoundedValueIfExists(chem[PH_ID]?.quantity, 1),
    // TODO: rm the phosphate_processed check once backend handles things
    phosphorusBray: phosphate_processed ? getRoundedValueIfExists(chem[P_BRAY_ID]?.quantity) : '',
    phosphorusM3: getRoundedValueIfExists(chem[PHOSPHORUS_ID]?.quantity),
    potassiumBray: '', // TODO: when Jamil sets this up, set this to the correct value
    potassiumM3: getRoundedValueIfExists(chem[POTASSIUM_ID]?.quantity),
    sikoraBph: getRoundedValueIfExists(chem[BPH_SIKORA_ID]?.quantity, 1),
    sulfur: getRoundedValueIfExists(chem[SULFUR_ID]?.quantity, 2),
    zinc: getRoundedValueIfExists(chem[ZINC_ID]?.quantity, 2),
  };
};

export const get590ColumnHeadings = (
  language: string,
): [heading: string, secondLine?: string][] => [
  ['ID'],
  [getString('depth', language)],
  ['OM', '%'],
  ['N', 'ppm'],
  ['P-M3', 'ppm'],
  ['P-Bray', 'ppm'],
  ['K-M3', 'ppm'],
  ['K-Bray', 'ppm'],
  ['Ca-M3', 'ppm'],
  ['Mg-M3', 'ppm'],
  ['pH'],
  ['BpH', '(Sikora)'],
  ['CEC', 'meq/100'],
  ['B-M3', 'ppm'],
  ['Cu-M3', 'ppm'],
  ['Fe-M3', 'ppm'],
  ['Mn-M3', 'ppm'],
  ['S-M3', 'ppm'],
  ['Zn-M3', 'ppm'],
];
