import React from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Table, TableCol, TableRow, ColumnSorter } from 'common';
import { getRoleListDisplayName } from 'util/userRoles';
import { RoleType } from 'store/roles/types';
import { User } from 'store/user/types';

import { FIRST_NAME, LAST_NAME } from 'constants/propTypes/user';
import UserRow from './UserRow';
import styles from './UsersTable.module.css';

type UsersTablePropsType = {
  roles: RoleType[];
  users: User[];
  setToastMessage: Function;
  isSuperAdmin: boolean;
  navigateToUser: Function;
  sortAttribute: string;
  sortOrder: string;
  setSortAttribute: (arg0: string) => void;
  setSortOrder: (arg0: string) => void;
  reloadUsers: Function;
};

const UsersTable = ({
  roles,
  users,
  setToastMessage,
  isSuperAdmin,
  navigateToUser,
  sortAttribute,
  sortOrder,
  setSortAttribute,
  setSortOrder,
  reloadUsers,
}: UsersTablePropsType) => {
  const language = useBroswerLanguage();
  const nonAgencySelectorOptions = getRoleListDisplayName(roles);

  const handleSorting = ({ attribute, order }: { attribute: string; order: string }) => {
    setSortAttribute(attribute);
    setSortOrder(order);
  };

  return (
    <Table>
      <TableRow header className={styles.TableHeader}>
        <TableCol>
          <ColumnSorter
            className={styles.ColumnLeft}
            attribute={FIRST_NAME}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
          >
            {getString('firstName', language)}
          </ColumnSorter>
        </TableCol>
        <TableCol className={styles.ColumnLeft}>
          <ColumnSorter
            className={styles.ColumnLeft}
            attribute={LAST_NAME}
            sorting={{ attribute: sortAttribute, order: sortOrder }}
            setSorting={handleSorting}
          >
            {getString('lastName', language)}
          </ColumnSorter>
        </TableCol>
        <TableCol>{getString('email', language)}</TableCol>
        <TableCol>{getString('agency', language)}</TableCol>
        <TableCol>{getString('agencyPrimary', language)}</TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          {getString('role', language)}
        </TableCol>
        <TableCol shrink className={styles.ShrinkedColumn}>
          {getString('language', language)}
        </TableCol>
      </TableRow>
      {users.map((user) => (
        <UserRow
          key={user.id}
          user={user}
          setToastMessage={setToastMessage}
          isSuperAdmin={isSuperAdmin}
          navigateToUser={navigateToUser}
          roles={nonAgencySelectorOptions}
          reloadUsers={reloadUsers}
        />
      ))}
    </Table>
  );
};

export default UsersTable;
