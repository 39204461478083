import { ACTIVE_OPERATION_ID } from 'constants/auth';
import { setLocalStorage } from 'util/localStorage';

import { AppThunk } from 'store';

import {
  deleteUserOperation,
  requestOperationsPage,
  requestSingleOperation,
  requestResultsDownloadLink,
  putField,
  putOperation,
  putUserOperation,
  requestConnectedAccountsInfo,
  requestOperationsTotalStats,
  requestPutResultPreferences,
} from './requests';

import {
  operationRequestError,
  receiveSingleField,
  receiveSingleOperation,
  receiveOperations,
  setActiveOperation as setActiveOperationAction,
  requestResultsLink,
  receiveResultsLink,
  requestOperations,
  requestConnectedAccounts,
  receiveConnectedAccounts,
  receiveOperationsTotalStats,
  receiveResultPreferences,
  receiveOperationUsers,
} from './actions';
import { ResultPreferencesType } from './types';
import { requestOperationUsers } from '../user/requests';

export const getOperation =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(requestOperations);
    return requestSingleOperation(id)
      .then((response) => dispatch(receiveSingleOperation(response)))
      .catch(() => dispatch(operationRequestError));
  };

export const getOperations =
  (
    acreageUnit: string,
    all: boolean = false,
    page: number = 1,
    name?: string,
    users?: number[],
    agency?: { agency_id: number; agency_primary_id: number } | null,
    sortby?: string,
    desc?: boolean,
    include_agencies?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestOperations);
      const response = await requestOperationsPage(
        all,
        page,
        name,
        users,
        agency,
        sortby,
        desc,
        include_agencies,
      );
      dispatch(receiveOperations(response, acreageUnit));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };

export const getOperationsTotalStats =
  (
    acreageUnit: string,
    all: boolean = false,
    name?: string,
    users?: number[],
    agency?: { agency_id: number; agency_primary_id: number } | null,
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestOperations);
      const response = await requestOperationsTotalStats(all, name, users, agency);
      dispatch(receiveOperationsTotalStats(response, acreageUnit));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };

export const getResultsLinkByFormat =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestResultsLink);
      const response = await requestResultsDownloadLink(id);
      dispatch(receiveResultsLink(response, id));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };

export const updateField =
  (body: any, id: number): AppThunk =>
  (dispatch) => {
    return putField(body, id)
      .then((response) => dispatch(receiveSingleField(response)))
      .catch(() => dispatch(operationRequestError));
  };

export const updateOperation =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      const response = await putOperation(body);
      dispatch(receiveSingleOperation(response));
      await dispatch(getOperation(body.id));
    } catch (err) {
      dispatch(operationRequestError);
    }
  };

export const setActiveOperation =
  (id: number): AppThunk =>
  (dispatch) => {
    setLocalStorage(ACTIVE_OPERATION_ID, id);
    dispatch(setActiveOperationAction(id));
    return Promise.resolve();
  };

export const addUsersToOperation =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      const response = await putUserOperation(body);
      dispatch(receiveSingleOperation(response));
    } catch (error) {
      dispatch(operationRequestError);
      throw error;
    }
  };

export const removeUserFromOperation =
  (body: any): AppThunk =>
  async (dispatch) => {
    try {
      const response = await deleteUserOperation(body);
      return dispatch(receiveSingleOperation(response));
    } catch (e) {
      return dispatch(operationRequestError);
    }
  };

export const getConnectedAccountsInfo =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestConnectedAccounts);
      const response = await requestConnectedAccountsInfo(id);
      dispatch(receiveConnectedAccounts(response, id));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };

export const updateOperationResultPreferences =
  (operationId: number, body: Partial<ResultPreferencesType>): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestPutResultPreferences(operationId, body);
      dispatch(receiveResultPreferences(response, operationId));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };

export const getOperationUsers =
  (operationId: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await requestOperationUsers(operationId);
      dispatch(receiveOperationUsers(response.users, operationId));
    } catch (error) {
      dispatch(operationRequestError);
    }
  };
