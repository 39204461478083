// Event names can only contain letters, numbers, or underscore
export const LOGIN = 'login';
export const PAGE_VIEW = 'page_view';

// TODO: move to deployment secrets
export const firebaseConfig = {
  apiKey: 'AIzaSyDwAwQ5oDek_2wTsnQ04gu04dE2Kdx8zxs',
  authDomain: 'patternag-firebase.firebaseapp.com',
  projectId: 'patternag-firebase',
  storageBucket: 'patternag-firebase.appspot.com',
  messagingSenderId: '1021117021710',
  appId: '1:1021117021710:web:9e6d1c0de57d44fab8065f',
  measurementId: 'G-F3RBC1V04R',
};

// Crop Planning
export const CROP_PLANNING_OPTIMIZE = 'CROP_PLANNING_OPTIMIZE';
export const CROP_PLANNING_REMOVE_INPUT = 'CROP_PLANNING_REMOVE_INPUT';
export const CROP_PLANNING_ADD_INPUT = 'CROP_PLANNING_ADD_INPUT';
