import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import classNames from 'classnames';
import { Spinner, Table, TableCol, TableRow } from 'common';
import { getHomogenizationRelatedPlates } from 'store/plates/thunks';
import { PlateType } from 'store/plates/types';
import { getPlateDisplayName, getPlateStatusDisplayName } from 'util/plates';
import { formatToDateHourFormat } from 'util/date';

import styles from './Container.module.css';

type PlatesRowExpandedPropsType = {
  plate: PlateType;
};

export const PlatesRowExpanded = ({ plate }: PlatesRowExpandedPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!plate.related_plates?.homogenization) {
      dispatch(getHomogenizationRelatedPlates(plate.barcode));
    }
  }, [plate]);

  const relatedPlates = plate.related_plates?.homogenization;

  return (
    <Table className={styles.ExpandedTable}>
      <TableRow className={styles.ExpandedHeader} borderless>
        <TableCol className={styles.ExpandedCol}>{getString('barcode', language)}</TableCol>
        <TableCol className={styles.ExpandedCol}>{getString('type', language)}</TableCol>
        <TableCol className={styles.ExpandedCol}>{getString('status', language)}</TableCol>
        <TableCol className={styles.ExpandedCol}>{getString('comments', language)}</TableCol>
        <TableCol className={styles.ExpandedCol}>{getString('createdAt', language)}</TableCol>
        <TableCol />
      </TableRow>
      {relatedPlates?.length ? (
        relatedPlates.map((p) => (
          <TableRow borderless key={p.barcode}>
            <TableCol className={styles.ExpandedCol}>
              <Link className={styles.Link} to={`/lab/plates/details/${p.barcode}`}>
                {p.barcode}
              </Link>
            </TableCol>
            <TableCol className={styles.ExpandedCol}>
              {getPlateDisplayName(p.plate_type, language)}
            </TableCol>
            <TableCol className={styles.ExpandedCol}>
              {getPlateStatusDisplayName(p.status, language)}
            </TableCol>
            <TableCol className={styles.ExpandedCol}>{p.notes}</TableCol>
            <TableCol className={styles.ExpandedCol}>
              {formatToDateHourFormat(p.created_at)}
            </TableCol>
            <TableCol />
          </TableRow>
        ))
      ) : (
        <TableRow className={classNames(styles.ExpandedBackground, styles.PaginationRow)}>
          <TableCol size="x4">
            {!relatedPlates ? <Spinner /> : getString('noPlatesFoundMsg', language)}
          </TableCol>
        </TableRow>
      )}
    </Table>
  );
};
