import { Text } from '@mantine/core';
import React from 'react';

import styles from './MapLegend.module.css';

export type LegendGradientProps = {
  colorStops?: string[];
  marks?: (number | string)[];
  showPlus?: boolean;
};

type MarkProps = {
  value: number | string;
};

/**
 * Some maps are "flat" and have the same value for every cell, so the legend needs to reflect this.
 *
 * @param marks array of legend numbers or strings to check
 * @returns true if there are at least two marks and they are not the same
 */
const getDoesRangeExist = (marks: Array<number | string>): boolean => {
  if (marks.length < 2) {
    return false;
  }

  const firstMark = marks[0];
  const lastMark = marks[marks.length - 1];

  return firstMark !== lastMark;
};

const LegendGradientMark = (props: MarkProps) => {
  const { value } = props;

  return (
    <div className={styles.gradientMarkRoot}>
      <div className={styles.gradientMarkMark} />
      <Text className={styles.gradientMarkText} component="div">
        {value}
      </Text>
    </div>
  );
};

export const LegendGradient = ({ colorStops = [], marks = [], showPlus }: LegendGradientProps) => {
  const rangeExists = getDoesRangeExist(marks);
  const gradientColors = rangeExists ? colorStops : [colorStops[0], colorStops[0]];

  return (
    <div className={styles.legendGradient}>
      <div
        className={styles.legendGradientBar}
        style={{
          backgroundImage: `linear-gradient(45deg, ${gradientColors.join(', ')})`,
        }}
      />
      <div className={styles.gradientMarks}>
        {marks.map((mark, i) => {
          const shouldShowPlusSign = i === 0 && showPlus;
          const plusSign = shouldShowPlusSign ? '+' : '';

          return <LegendGradientMark key={`${mark}-${i}`} value={`${mark}${plusSign}`} />;
        })}
      </div>
    </div>
  );
};
