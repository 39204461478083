import { UserRole } from 'store/user/types';

export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const REQUEST_ROLES = 'REQUEST_ROLES';
export const ROLE_REQUEST_ERROR = 'ROLE_REQUEST_ERROR';

export type RoleType = {
  id: number;
  name: UserRole;
  description: string;
};

export type RoleStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  roles: RoleType[];
};

interface ReceiveRolesAction {
  type: typeof RECEIVE_ROLES;
  payload: RoleType[];
}

interface RequestRolesAction {
  type: typeof REQUEST_ROLES;
}

interface RoleRequestErrorAction {
  type: typeof ROLE_REQUEST_ERROR;
}

export type RoleActionTypes = ReceiveRolesAction | RequestRolesAction | RoleRequestErrorAction;
