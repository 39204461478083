import React, { useState } from 'react';
import { Agency } from 'store/agencies/types';
import { FiX } from 'react-icons/fi';
import { Button, Group, Loader, Text, Table } from '@mantine/core';
import { UserAgencySearch } from 'common';
import { getString } from 'strings/translation';
import { userFullName } from 'util/userRoles';
import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  requestDeleteOperationAgencyConnection,
  requestPostOperationAgencyConnection,
} from 'store/operation/requests';
import { useDispatch } from 'react-redux';
import showToast, { type ToastType } from 'actions/toastActions';
import { receiveAllAgencies } from 'store/agencies/actions';
import { UserAgencySelectorType } from 'store/user/types';
import styles from './ManageOperationAgencies.module.css';
import DeleteAgencyOperationConnection from './DeleteAgencyOperationConnection';

interface Props {
  agencies: Agency[];
  operationId: number;
}
const ManageOperationAgencies = ({ agencies, operationId }: Props) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState<Agency | null>(null);
  const [isSubmitting, toggleIsSubmitting] = useState(false);
  const [isTypeaheadOpen, toggleIsTypeaheadOpen] = useState(false);
  const [isSearchingAgency, toggleIsSearching] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState<{ id: number; displayName: string } | null>(
    null,
  );

  const setToastMessage = (message: string, type?: ToastType) => showToast(message, type);

  const handleAgencySelection = (selected: UserAgencySelectorType | null) => {
    if (selected) {
      const { agency_id, displayName } = selected;
      if (agency_id) {
        setSelectedAgency({ id: agency_id, displayName });
      }
    }
  };

  const deleteAgencyConnection = async () => {
    if (deleteModalOpen) {
      try {
        toggleIsSubmitting(true);
        const receivedAgencies = await requestDeleteOperationAgencyConnection(
          deleteModalOpen?.id,
          operationId,
        );
        dispatch(receiveAllAgencies(receivedAgencies.agencies));
        setDeleteModalOpen(null);
        setToastMessage(getString('agencyConnectionRemoved', language));
      } catch (error) {
        setToastMessage(getString('unableToDisconnectAgency', language), 'error');
      } finally {
        toggleIsSubmitting(false);
      }
    }
  };

  const addAgencyToOperation = async () => {
    if (selectedAgency) {
      try {
        toggleIsSubmitting(true);
        const receivedAgencies = await requestPostOperationAgencyConnection(
          selectedAgency?.id,
          operationId,
        );
        dispatch(receiveAllAgencies(receivedAgencies.agencies));
        toggleIsSearching(false);
        setSelectedAgency(null);
        toggleIsTypeaheadOpen(false);
        setToastMessage(getString('agencyConnectionAdded', language));
      } catch (error) {
        setToastMessage(getString('unableToConnectAgency', language), 'error');
      } finally {
        toggleIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <div className={styles.TitleContainer}>
        <Text size="xl">{getString('agencies', language)}</Text>
        <Group>
          {isTypeaheadOpen && (
            <>
              {(isSubmitting || isSearchingAgency) && <Loader size="sm" />}
              <UserAgencySearch
                placeholder={getString('searchAgency', language)}
                onSelect={handleAgencySelection}
                onDeselect={() => setSelectedAgency(null)}
                className={styles.SearchBar}
                toggleIsSearching={toggleIsSearching}
                selected={
                  selectedAgency?.displayName
                    ? { id: String(selectedAgency?.id), displayName: selectedAgency?.displayName }
                    : null
                }
                isAgencyOnly
              />
            </>
          )}
          <Button
            data-test-id="add-agency-to-operation"
            disabled={isSubmitting || (isTypeaheadOpen && !selectedAgency?.id)}
            onClick={() =>
              isTypeaheadOpen ? addAgencyToOperation() : toggleIsTypeaheadOpen(!isTypeaheadOpen)
            }
          >
            {isTypeaheadOpen ? getString('add', language) : getString('addAgency', language)}
          </Button>
          {isTypeaheadOpen && (
            <Button variant="outline" onClick={() => toggleIsTypeaheadOpen(false)}>
              {getString('cancel', language)}
            </Button>
          )}
        </Group>
      </div>
      <div className={styles.Divider} />
      <Table>
        {!!deleteModalOpen && (
          <DeleteAgencyOperationConnection
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            isSubmitting={isSubmitting}
            deleteAgencyConnection={deleteAgencyConnection}
            open={!!deleteModalOpen}
          />
        )}
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{getString('name', language)}</Table.Th>
            <Table.Th>{getString('primary', language)}</Table.Th>
            <Table.Th>{getString('email', language)}</Table.Th>
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        {agencies.map((agency) => (
          <Table.Tr key={agency.id}>
            <Table.Td>{agency.name}</Table.Td>
            <Table.Td>{agency.primary_user ? userFullName(agency.primary_user) : 'N/A'}</Table.Td>
            <Table.Td>{agency.primary_user?.email}</Table.Td>
            <Table.Td>
              <div className={styles.DeleteIcon}>
                <FiX onClick={() => setDeleteModalOpen(agency)} />
              </div>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table>
    </>
  );
};

export default ManageOperationAgencies;
