import React from 'react';
import classNames from 'classnames';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { capitalizeEveryWord } from 'util/stringUtils';
import { CropAnalysisType } from 'store/salesFlow/types';
import { displayPriceOrBar } from 'util/salesFlow';
import Styles from './AnalyticExpanded.module.css';

interface AnalyticExpandedProps {
  analyticData: CropAnalysisType;
}

const AnalyticExpanded = ({ analyticData }: AnalyticExpandedProps) => {
  const language = useBroswerLanguage();

  const percentAtRisk = Math.round((analyticData.high_risk_acres / analyticData.acreage) * 100);

  const protectionLevel = analyticData.protection_level
    ? `${capitalizeEveryWord(
        analyticData.protection_level.replace('_', ' '),
      )}($${analyticData.over_protection_cost_per_acre}/ac)`
    : '--';

  const analyticNames = analyticData.associated_analytics
    .map((analytic) => analytic.name)
    .join(', ');

  return (
    <div className={Styles.Wrapper}>
      <div className={classNames(Styles.FlexRowSpace, Styles.HeaderRow)}>
        <div>
          <b>{getString('estAcresAtRisk', language)}:</b> {percentAtRisk}%
        </div>
        <div>
          <b>{getString('protection', language)}:</b> {protectionLevel}
        </div>
        <div>
          <b>{getString('estYieldAtRisk', language)}:</b>{' '}
          {Math.round(100 * analyticData.yield_loss)}%
        </div>
      </div>
      <div className={classNames(Styles.FlexRowSpace, Styles.MetaWrapper)}>
        <div className={classNames(Styles.FlexRowSpace, Styles.MetaMainRow)}>
          <span>
            <b>{getString('highRiskAcres', language)}</b>
          </span>
          <span>
            <b>{analyticData.high_risk_acres}</b>
          </span>
        </div>
        <div className={classNames(Styles.FlexRowSpace, Styles.MetaMainRow)}>
          <span>
            <b>{getString('lowRiskAcres', language)}</b>
          </span>
          <span>
            <b>{analyticData.low_risk_acres}</b>
          </span>
        </div>
      </div>
      <div className={classNames(Styles.FlexRowSpace)}>
        <div className={classNames(Styles.FlexRowSpace, Styles.MetaMinorRow)}>
          <span>{getString('estYieldImpact', language)}</span>
          <span>{displayPriceOrBar(analyticData.estimated_yield_impact, '')}</span>
        </div>
        <div className={classNames(Styles.FlexRowSpace, Styles.MetaMinorRow)}>
          <span>{getString('inputSavings', language)}</span>
          <span>{displayPriceOrBar(analyticData.input_savings, '')}</span>
        </div>
      </div>
      <div className={classNames(Styles.FlexRowSpace)}>
        <div className={classNames(Styles.FlexRowSpace, Styles.MetaMinorRow)}>
          <span>{getString('additionalInputSpend', language)}</span>
          <span>{displayPriceOrBar(analyticData.additional_input_spend, '')}</span>
        </div>
      </div>
      {!!analyticNames.length && (
        <div
          className={classNames(
            Styles.FlexRowSpace,
            Styles.MetaWrapper,
            Styles.AssociatedAnalytics,
          )}
        >
          <div>
            <b>
              {analyticData.grouping_name} {getString('includes', language)}:
            </b>{' '}
            {analyticNames}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticExpanded;
