import React, { useState } from 'react';
import { MdPlayCircleOutline } from 'react-icons/md';
import { TableCol, TableRow } from 'common';
import classNames from 'classnames';
import { CropReportType } from 'store/salesFlow/types';
import { displayPriceOrBar } from 'util/salesFlow';
import Styles from './ReportTable.module.css';
import AnalyticExpanded from './AnalyticExpanded';

interface AnalyticsRowsProps {
  cropInfo: CropReportType;
}

const AnalyticsRows = ({ cropInfo }: AnalyticsRowsProps) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <>
      {cropInfo.analysis.map((analyticData, index) => (
        <div key={index}>
          <TableRow className={Styles.BackgroundGrey}>
            <TableCol size="x2" className={Styles.PaddingLeft}>
              <div className={Styles.ColumnLeft}>
                {analyticData.grouping_name}{' '}
                <span className={Styles.IconWrapper}>
                  <MdPlayCircleOutline
                    className={classNames(openIndex === index ? Styles.RotatedIcon : undefined)}
                    onClick={() => setOpenIndex(index === openIndex ? null : index)}
                  />
                </span>
              </div>
            </TableCol>
            <TableCol className={Styles.Column}>
              {displayPriceOrBar(analyticData.boost_yield, '/ ac')}
            </TableCol>
            <TableCol className={Styles.Column}>
              {displayPriceOrBar(analyticData.optimize_spend, '/ ac')}
            </TableCol>
            <TableCol className={classNames(Styles.Column, Styles.ColumnEnd)}>
              {displayPriceOrBar(analyticData.total_return_per_acre, '/ ac')}
            </TableCol>
          </TableRow>
          {openIndex === index && <AnalyticExpanded analyticData={analyticData} />}
        </div>
      ))}
    </>
  );
};

export default AnalyticsRows;
