import { SOIL, ZYMO_SOIL, ZYMO } from 'constants/controls';
import { ControlType } from 'store/controls/types';
import { getString } from 'strings/translation';

export const getControlDilutionType = (control: ControlType) => {
  if (control.dilution.soil && control.dilution.zymo) {
    return ZYMO_SOIL;
  }
  return control.dilution.soil ? SOIL : ZYMO;
};

export const getControlTypeOptions = (language: string) => [
  {
    id: 0,
    displayName: getString(ZYMO, language),
  },
  {
    id: 1,
    displayName: getString(SOIL, language),
  },
  {
    id: 2,
    displayName: getString(ZYMO_SOIL, language),
  },
];
