import React from 'react';
import farmerBucket from 'images/sales/farmerBucket.png';
import resultsDisplay from 'images/sales/resultsDisplay.png';
import { FiChevronRight } from 'react-icons/fi';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import SlideStyles from '../../Shared.module.css';
import Styles from './MapPressure.module.css';

const MapPressure = () => {
  const language = useBroswerLanguage();
  return (
    <div>
      <div
        className={SlideStyles.Header}
        dangerouslySetInnerHTML={{
          __html: getString('mapYourPressure', language),
        }}
      />
      <div className={SlideStyles.Images}>
        <img alt={getString('overview', language)} className={Styles.Image} src={farmerBucket} />
        <FiChevronRight className={Styles.CenterChevron} size="5em" color="#b8c1d0" />
        <img alt={getString('overview', language)} className={Styles.Image} src={resultsDisplay} />
      </div>
    </div>
  );
};

export default MapPressure;
