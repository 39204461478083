import {
  RoleStateType,
  RECEIVE_ROLES,
  REQUEST_ROLES,
  ROLE_REQUEST_ERROR,
  RoleActionTypes,
} from 'store/roles/types';

export const initialState: RoleStateType = {
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
  roles: [],
};

export const RoleReducer = (state = initialState, action: RoleActionTypes) => {
  switch (action.type) {
    case RECEIVE_ROLES:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        roles: action.payload,
      };
    case REQUEST_ROLES:
      return {
        ...state,
        isFetching: true,
      };
    case ROLE_REQUEST_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default RoleReducer;
