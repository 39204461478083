import { HEARTLAND_AGENCY_ID } from 'constants/agency';
import { User } from 'store/user/types';
import { isProduction } from './request';
import { userIsAdmin, userIsSuperAdmin } from './userRoles';

export const canGetExternalNutrientLabs = (currentUser: User | null) => {
  // TODO: rm prod check when backend is ready
  if (!currentUser || isProduction) {
    return false;
  }

  return (
    currentUser.agencies.some(({ id }) => id === HEARTLAND_AGENCY_ID) ||
    userIsAdmin(currentUser.roles) ||
    userIsSuperAdmin(currentUser.roles)
  );
};
