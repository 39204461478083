import React from 'react';
import { FiList, FiMap } from 'react-icons/fi';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { HeatMapAnalyticType } from 'store/heatMap/types';
import { getStatsForHeatMap } from 'util/heatMap';
import acToHectares from 'util/units';
import { ActionIcon, Group, Text } from '@mantine/core';

type SummaryBarPropsType = {
  analyticId: number;
  heatMap: HeatMapAnalyticType[];
  mapView: boolean;
  setMapView: (mode: boolean) => void;
};

export const SummaryBar = ({ analyticId, heatMap, mapView, setMapView }: SummaryBarPropsType) => {
  const language = useBroswerLanguage();
  const {
    totalAcres,
    highRiskAcres,
    moderateRiskAcres,
    lowRiskAcres,
    highRiskPercent,
    moderateRiskPercent,
    lowRiskPercent,
  } = getStatsForHeatMap(heatMap, analyticId);

  const generateSummary = (label: string, acres: number, percent: number) =>
    Boolean(acres) && (
      <Text span>
        <b>{`${getString(label, language)}: `}</b>
        {`${Math.round(acToHectares(acres, language)).toLocaleString()} (${percent}%)`}
      </Text>
    );

  return (
    <Group
      p="sm"
      justify="space-between"
      align="center"
      style={{ border: '2px solid', borderRadius: 'var(--mantine-radius-sm)' }}
    >
      <Group gap="xs" flex={1}>
        <Text>{getString('filteredResults', language)}</Text>
        <Text>-</Text>
        <Group gap="sm" flex={1} wrap="wrap" align="center">
          <Text>
            <b>{`${getString('totalAcres', language)}: `}</b>
            {Math.round(acToHectares(totalAcres, language)).toLocaleString()}
          </Text>
          {generateSummary('highRisk', highRiskAcres, highRiskPercent)}
          {generateSummary('modRisk', moderateRiskAcres, moderateRiskPercent)}
          {generateSummary('lowRisk', lowRiskAcres, lowRiskPercent)}
        </Group>
      </Group>
      <ActionIcon
        onClick={() => setMapView(!mapView)}
        variant="outline"
        radius="xl"
        size="lg"
        style={{ borderWidth: '2px' }}
      >
        {mapView ? <FiList /> : <FiMap />}
      </ActionIcon>
    </Group>
  );
};
