import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Group, Input, Loader, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { LabSampleType } from 'store/labSamples/types';
import { editSampleValues } from 'store/labSamples/thunks';
import { formatFloatString } from 'util/stringUtils';
import { KEYS } from 'constants/keyCodes';
import { convertDecimalToPercent } from 'util/numUtils';

type EditMoisturePropsType = {
  sample: LabSampleType;
  searchBarFocus: () => void;
};

export const EditMoisture = ({ sample, searchBarFocus }: EditMoisturePropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sampleMoisture, setSampleMoisture] = useState(
    String(sample.soil_moisture_percentage || ''),
  );

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const resetValues = () => {
    setSampleMoisture(String(sample.soil_moisture_percentage || ''));
    setIsEditing(false);
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      setIsEditing(false);
      setIsSubmitting(true);
      await dispatch(
        editSampleValues(sample.id, sample.barcode, {
          soil_moisture_percentage: parseFloat(sampleMoisture),
        }),
      );
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleOnKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYS.ENTER) {
      await handleSubmit();
      searchBarFocus();
    }
  };

  const massDisplay = (
    <Text>
      {sample.soil_moisture_percentage
        ? `${convertDecimalToPercent(sample.soil_moisture_percentage, 10)}%`
        : getString('noData', language)}
    </Text>
  );

  const massInput = (
    <Input
      ref={inputRef}
      maw="5rem"
      autoComplete="off"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setSampleMoisture(formatFloatString(event.target.value))
      }
      onKeyUp={handleOnKeyUp}
      value={String(sampleMoisture)}
    />
  );

  const startEditButton = (
    <Group gap="xs" pos="relative">
      <Button
        variant="outline"
        onClick={() => setIsEditing(true)}
        disabled={!sample.arrived_at || isSubmitting}
      >
        {getString(isSubmitting ? 'submitting' : 'update', language)}
      </Button>
      {isSubmitting && <Loader size="xs" pos="absolute" top="0.5rem" right="-1.5rem" />}
    </Group>
  );

  const finishEditButtons = (
    <Group gap="xs">
      <Button variant="outline" onClick={handleSubmit} disabled={!sampleMoisture.length}>
        {getString('submit', language)}
      </Button>
      <Button variant="filled" color="red.8" onClick={resetValues}>
        {getString('cancel', language)}
      </Button>
    </Group>
  );

  return (
    <Group>
      <Group gap="xs" w="13.5rem" wrap="nowrap">
        <Text
          fw="700"
          style={{ textWrap: 'nowrap' }}
        >{`${getString('moisture', language)} (%): `}</Text>
        {isEditing ? massInput : massDisplay}
      </Group>
      {isEditing ? finishEditButtons : startEditButton}
    </Group>
  );
};
