import { User } from 'store/user/types';

export type OperationFieldType = {
  nutrient_results_available?: boolean;
  acreage?: number;
  name: string;
  farm_name: string | null;
  id: number;
  crop_years: number[];
};

export type ResultsLinkType = {
  id: number;
  created_at: string;
  signed_url: string;
  name: string;
};

export type ResultsLinksType = {
  pdf: ResultsLinkType[];
  shapefile: ResultsLinkType[];
  csv: ResultsLinkType[];
  pro_shapefile: ResultsLinkType[];
  pro_csv: ResultsLinkType[];
  mapbook_available_fields: number[];
  is_puppeteer_running: boolean;
};

export type AcreageCountType = {
  created: number;
  ready: number;
  unassigned: number;
  sampled: number;
  received: number;
  partial: number;
  completed: number;
  cancelled: number;
  operation_total_acres: number;
};

export type ResultPreferencesType = {
  id: number;
  operation_id?: number;
  p_method_shown?: string;
  ca_k_mg_method_shown?: string;
  micro_method_shown?: string;
};

export type OperationType = {
  is_not_billable: boolean;
  billing_agency_name: string | null;
  id: number;
  name: string;
  total_acerage: number;
  updated_at: string | null;
  created_at: string;
  is_active: boolean;
  users: number[];
  billing_user_id: number;
  billing_agency_id?: number | null;
  commissions_user_id: number;
  excluded_from_analysis: boolean;
  crop_years: number[];
  external_connected_accounts: {
    accounts: string[];
    leaf_user_uuid?: string;
  };
  discount_name: string | null;
  max_acres: number | null;
  locked_in_acres_status: string | null;
  lockable_acres: number | null;
  has_active_agency_contract?: boolean;
  operationUsers?: User[];
  sampling_status: {
    created: number;
    ready: number;
    unassigned: number;
    sampled: number;
    received: number;
    partial: number;
    completed: number;
    cancelled: number;
  };
  acreage_count: AcreageCountType;
  pending_delivery: boolean;
  fields: OperationFieldType[];
  inactive_fields: OperationFieldType[];
  recommendation_set_id?: number | null;
  agency_recommendation_set_id?: number | null;
  result_links?: ResultsLinksType;
  connectedAccounts?: ConnectedAccountType | null;
  locked_in_acres: number | null;
  locked_in_acres_at: string | null;
  plan_acres_since_lock_in: number | null;
  result_preferences?: ResultPreferencesType;
  default_bill_to_agency_id: number | null;
  catalog_id?: number | null;
};

export type OperationTotalStats = {
  operations: number;
  fields: number;
  sampling_status: {
    created: number;
    ready: number;
    unassigned: number;
    sampled: number;
    received: number;
    partial: number;
    completed: number;
    cancelled: number;
  };
  acreage_count: AcreageCountType;
  locked_in_acres: number;
};

export type ConnectedAccountType = {
  updated_at: string | null;
  leaf_user_uuid: string;
  john_deere_session_terminated: boolean | null;
  john_deere_authorized: boolean | null;
  fieldview_authorized: boolean | null;
  cnhi_authorized: boolean | null;
  agleader_authorized: boolean | null;
  last_sync: string | null;
  operation_id: number;
  created_at: string | null;
  id: number;
  connection_initiated_by_user_id: number;
};

export const REQUEST_RESULTS_LINK = 'REQUEST_RESULTS_LINK';
export const RECEIVE_RESULTS_LINK = 'RECEIVE_RESULTS_LINK';

export const DELETE_FIELD = 'DELETE_FIELD';
export const OPERATION_REQUEST_ERROR = 'OPERATION_REQUEST_ERROR';
export const RECEIVE_OPERATIONS = 'RECEIVE_OPERATIONS';
export const RECEIVE_TOTAL_STATS = 'RECEIVE_TOTAL_STATS';
export const RECEIVE_SINGLE_FIELD = 'RECEIVE_SINGLE_FIELD';
export const RECEIVE_SINGLE_OPERATION = 'RECEIVE_SINGLE_OPERATION';
export const REQUEST_OPERATIONS = 'REQUEST_OPERATIONS';
export const SET_ACTIVE_OPERATION = 'SET_ACTIVE_OPERATION';
export const REQUEST_CONNECTED_ACCOUNTS = 'REQUEST_CONNECTED_ACCOUNTS';
export const RECEIVE_CONNECTED_ACCOUNTS = 'RECEIVE_CONNECTED_ACCOUNTS';
export const RECEIVE_RESULT_PREFERENCES = 'RECEIVE_RESULT_PREFERENCES';

export const RECEIVE_OPERATION_USERS = 'RECEIVE_OPERATION_USERS';

type RequestOperationsActionType = {
  type: typeof REQUEST_OPERATIONS;
};

type OperationRequestErrorActionType = {
  type: typeof OPERATION_REQUEST_ERROR;
};

type ReceiveOperationsActionType = {
  type: typeof RECEIVE_OPERATIONS;
  payload: {
    [key: string]: any;
  };
  acreageUnit;
};

type ReceiveTotalStatsActionType = {
  type: typeof RECEIVE_TOTAL_STATS;
  payload: OperationTotalStats;
  acreageUnit: string;
};

type ReceiveSingleFieldActionType = {
  type: typeof RECEIVE_SINGLE_FIELD;
  payload: void;
};

type ReceiveSingleOperationActionType = {
  type: typeof RECEIVE_SINGLE_OPERATION;
  payload: OperationType;
};

type SetActiveOperationActionType = {
  type: typeof SET_ACTIVE_OPERATION;
  payload: number;
};

type DeleteFieldActionType = {
  type: typeof DELETE_FIELD;
  payload: {
    fieldId: number;
  };
};

type ReceiveResultsLinkActionType = {
  type: typeof RECEIVE_RESULTS_LINK;
  payload: {
    links: ResultsLinksType;
    id: number;
  };
};

type ReceiveConnectedAccountsActionType = {
  type: typeof RECEIVE_CONNECTED_ACCOUNTS;
  payload: { id: number; connectedAccounts: ConnectedAccountType };
};

type ReceiveResultPreferencesActionType = {
  type: typeof RECEIVE_RESULT_PREFERENCES;
  payload: {
    preferences: ResultPreferencesType;
    id: number;
  };
};

type ReceiveOperationUsersActionType = {
  type: typeof RECEIVE_OPERATION_USERS;
  payload: {
    operationUsers: User[];
    id: number;
  };
};

export type OperationActionsType =
  | DeleteFieldActionType
  | OperationRequestErrorActionType
  | ReceiveOperationsActionType
  | ReceiveTotalStatsActionType
  | ReceiveSingleFieldActionType
  | ReceiveSingleOperationActionType
  | RequestOperationsActionType
  | SetActiveOperationActionType
  | ReceiveResultsLinkActionType
  | ReceiveConnectedAccountsActionType
  | ReceiveResultPreferencesActionType
  | ReceiveOperationUsersActionType;
