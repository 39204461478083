import { handleJsonResponse, requestGet, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';

export const requestGetAnalyticsHeatmap = (filter: {
  all?: boolean;
  agencyId?: number;
  operationId?: number;
  cropYear?: number;
  state?: string;
}) =>
  requestGet(
    `${SERVICE_URL}/analytics_heatmap/?${queryStringify({
      all: filter.all || false,
      agency_id: filter.agencyId,
      operation_id: filter.operationId,
      crop_year: filter.cropYear,
      state: filter.state,
    })}`,
  ).then(handleJsonResponse);
