import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeoJSON } from 'geojson';
import { getPlanAnalytic } from 'util/results';
import { AnalyticType } from 'store/analytics/types';
import { FieldType, SampleType, SamplingPlanType } from 'store/fields/types';
import { filterSamplesWithPlan } from 'util/sample';
import { RootState } from 'store';
import getSamplesForField from 'store/samples/thunks';
import { getSamplePlanRecommendations } from 'store/recommendations/thunks';
import { Loader, Modal, Center } from '@mantine/core';
import Mapbook from '../../common/Mapbook';

interface Props {
  hideModal: () => void;
  activeAnalytic: AnalyticType;
  samplingPlan: SamplingPlanType;
  field: FieldType;
  opened: boolean;
}

const ChartModal = ({ hideModal, activeAnalytic, samplingPlan, field, opened }: Props) => {
  const dispatch = useDispatch();
  const samples = useSelector((state: RootState) => state.samples.samples[samplingPlan.field_id]);

  const { recommendations, analytics } = useSelector((state: RootState) => ({
    recommendations: state.recommendations.byPlanId[samplingPlan.id],
    analytics: state.analytics.analytics,
  }));

  useEffect(() => {
    if (samplingPlan?.id) {
      dispatch(getSamplePlanRecommendations(samplingPlan.id));
    }
  }, [dispatch, samplingPlan]);

  useEffect(() => {
    if (!samples) {
      dispatch(getSamplesForField(samplingPlan.field_id, analytics));
    }
  }, [samples, analytics]);

  const filteredSamples = useMemo(
    () => filterSamplesWithPlan(samples, samplingPlan),
    [samples, samplingPlan],
  ) as GeoJSON.Feature<GeoJSON.Geometry, SampleType>[];
  const planAnalytic = getPlanAnalytic(samplingPlan, activeAnalytic.category, activeAnalytic.id);

  if (!planAnalytic) {
    return null;
  }

  return (
    <Modal
      opened={opened}
      onClose={() => hideModal()}
      size="100%"
      centered
      mih="20rem"
      withCloseButton={false}
    >
      {planAnalytic && filteredSamples.length ? (
        <Mapbook
          key={activeAnalytic?.id}
          activeAnalytic={activeAnalytic}
          field={field}
          samples={filteredSamples}
          samplingPlan={samplingPlan}
          recommendations={recommendations}
        />
      ) : (
        <Center h="20rem">
          <Loader />
        </Center>
      )}
    </Modal>
  );
};

export default ChartModal;
