import React from 'react';
import {
  AA_ANALYSIS,
  CHEMISTRY,
  DNA_EXTRACTION,
  HOMOGENIZATION,
  NITRATE_ANALYSIS,
  OM_ANALYSIS,
  M3_ANALYSIS,
  NORMALIZED_DNA,
  PH_ANALYSIS,
  QPCR_384,
  QPCR_96,
  SHOTGUN,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
} from 'constants/plates';
import { PlateType } from 'store/plates/types';
import { DNAExtractionForm } from './PlateTypes/DNAExtraction';
import { HomogenizationForm } from './PlateTypes/Homogenization';
import { NormalizedDNAForm } from './PlateTypes/NormalizedDNA';
import { QPCR384Form } from './PlateTypes/QPCR_384';
import { QPCR96Form } from './PlateTypes/QPCR_96';
import { ShotgunForm } from './PlateTypes/Shotgun';
import { ChemistryForm } from './PlateTypes/Chemistry';

type PlateFormContainerPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const PlateFormContainer = ({ plate, onValueChange }: PlateFormContainerPropsType) => {
  const getPlateForm = () => {
    switch (plate.plate_type) {
      case HOMOGENIZATION:
        return <HomogenizationForm plate={plate} onValueChange={onValueChange} />;
      case DNA_EXTRACTION:
        return <DNAExtractionForm plate={plate} onValueChange={onValueChange} />;
      case NORMALIZED_DNA:
        return <NormalizedDNAForm plate={plate} onValueChange={onValueChange} />;
      case SHOTGUN:
        return <ShotgunForm plate={plate} onValueChange={onValueChange} />;
      case QPCR_384:
        return <QPCR384Form plate={plate} onValueChange={onValueChange} />;
      case QPCR_96:
        return <QPCR96Form plate={plate} onValueChange={onValueChange} />;
      case CHEMISTRY:
      case M3_ANALYSIS:
      case PH_ANALYSIS:
      case NITRATE_ANALYSIS:
      case AA_ANALYSIS:
      case OM_ANALYSIS:
      case PHOSPHATE_ANALYSIS:
      case CHEMISTRY_BACKUP:
        return <ChemistryForm plate={plate} onValueChange={onValueChange} />;
      default:
        return <></>;
    }
  };

  return getPlateForm();
};
