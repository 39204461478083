import {
  RECEIVE_SINGLE_RECOMMENDATION_SET,
  RECEIVE_RECOMMENDATION_SETS,
  RecommendationSetActionsType,
  RecommendationSetType,
  RECOMMENDATION_SET_REQUEST_ERROR,
  REQUEST_RECOMMENDATION_SETS,
} from './types';

export const receiveRecommendationSets = (
  recommendation_sets: RecommendationSetType[],
): RecommendationSetActionsType => ({
  type: RECEIVE_RECOMMENDATION_SETS,
  payload: {
    recommendation_sets,
  },
});

export const receiveSingleRecommendationSet = (
  recommendation_set: RecommendationSetType,
): RecommendationSetActionsType => ({
  type: RECEIVE_SINGLE_RECOMMENDATION_SET,
  payload: {
    recommendation_set,
  },
});

export const requestRecommendationSets = (): RecommendationSetActionsType => ({
  type: REQUEST_RECOMMENDATION_SETS,
});

export const recommendationSetRequestError = (): RecommendationSetActionsType => ({
  type: RECOMMENDATION_SET_REQUEST_ERROR,
});
