import { RoleType } from 'store/roles/types';
import { User, UserRole } from 'store/user/types';
import { getString } from 'strings/translation';
import { capitalizeEveryWord } from './stringUtils';

const userIsRole = (userRole: string) => (roles: RoleType[]) =>
  Boolean(roles && roles.some((role) => role.name === userRole));

export const userIsAdmin = userIsRole('admin');
export const userIsSampler = userIsRole('sampler');
export const userIsSuperAdmin = userIsRole('superadmin');
export const userIsOperator = userIsRole('operator');
export const userIsLab = userIsRole('lab');
export const userIsAgent = userIsRole(UserRole.Agent);
export const userIsAgencyAdmin = userIsRole(UserRole.AgencyAdmin);

export const isAgencyRole = (role: RoleType) =>
  UserRole.AgencyAdmin === role.name || UserRole.Agent === role.name;

export const getUserRoleDisplayName = (u: User) => {
  if (u && u?.roles.length > 0) {
    return u?.roles[0].name;
  }
  return '';
};

export const getRoleListDisplayName = (roles: RoleType[]) => {
  return roles.map((role) => ({
    ...role,
    displayName: capitalizeEveryWord(role.name.replace('_', ' ')),
    label: capitalizeEveryWord(role.name.replace('_', ' ')),
    value: role.name,
    role: role,
    id: role.id,
  }));
};

export const checkRole = (roles: RoleType[], role_id: number, userType: Function) => {
  const givenRole = roles.find((role) => role.id === Number(role_id));
  if (givenRole) {
    return userType([givenRole]);
  }
  return false;
};

export const userFullName = (user: User) =>
  capitalizeEveryWord(`${user.first_name} ${user.last_name}`);

export const agencyRoles = (roles: RoleType[]) => roles.filter(isAgencyRole);

// TODO: Delete when Common is removed
export const getRoleOptions = (roles: RoleType[], language: string) =>
  roles.map((r, index) => ({
    id: index,
    displayName: getString(r.name, language),
    label: getString(r.name, language),
    value: r,
  }));

export const getRoleOptionsV2 = (roles: RoleType[], language: string) =>
  roles.map((r, index) => ({
    id: index,
    label: getString(r.name, language),
    role: r,
    value: r.name,
  }));

export const getCreateUserRoleOption = (roles: RoleType[], language: string) =>
  (roles || [])
    .filter(
      (role) =>
        !userIsAdmin([role]) &&
        !userIsSuperAdmin([role]) &&
        !userIsOperator([role]) &&
        !userIsLab([role]),
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((role) => ({
      ...role,
      displayName: getString(role.name, language),
    }));
