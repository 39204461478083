import React from 'react';
import {
  Button,
  Collapse,
  Divider,
  Flex,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NUTRIENT } from 'constants/analysis';
import { BR } from 'constants/countries';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import {
  CURRENT_PRODUCTS,
  NITRATE_PANEL,
  NUTRIENT_PANEL,
  PRESSURE_NUTRIENT,
} from 'constants/products';
import {
  DEFAULT_GRID_SIZE,
  DEFAULT_PRO_BIO_DENSITY,
  DEFAULT_PRO_NUTRIENT_DENSITY,
  is590Capable,
  ZONE_BY_ZONE,
} from 'constants/samplePlanning';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { OperationType } from 'store/operation/types';
import { updateZoneAnalysis } from 'store/zoneAnalysisV2/actions';
import { ZoneAnalysisKeyType } from 'store/zoneAnalysisV2/types';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import useSampleTiming from 'util/hooks/useSampleTiming';
import {
  getSamplePlanProductsBio,
  getSamplePlanProductsNutrient,
  getSelectedBioProduct,
  getSelectedBioProductFromAnalysis,
  getSelectedNutrientProduct,
  isNutrientPanel,
} from 'util/product';
import { getNewAnalysisFromUpdate, getSamplerOptions, getScannerOptions } from 'util/samplePlan';

interface AnalysisFormProps {
  fieldGeometry: FieldType;
  operation: OperationType;
}

const DROPDOWN_WIDTH = '45%';

const AnalysisForm = ({ fieldGeometry, operation }: AnalysisFormProps) => {
  const language = useBroswerLanguage();
  const { timings } = useSampleTiming();
  const dispatch = useDispatch();

  const { analysis, availableExternalLabs } = useSelector((state: RootState) => ({
    analysis: state.zoneAnalysisV2.plan,
    availableExternalLabs: state.zoneAnalysisV2.availableExternalLabs,
  }));

  const fieldProperties = fieldGeometry.features[0].properties;
  const isRestricted = Boolean(fieldProperties.county?.restricted);
  const isBrazil = fieldProperties.country_code === BR;
  const possibleProductsBio = getSamplePlanProductsBio(language, isRestricted);
  const selectedBioProduct = getSelectedBioProduct(analysis, language, isRestricted);
  const possibleProductsNutrient = getSamplePlanProductsNutrient(language, isRestricted);
  const selectedNutrientProduct = getSelectedNutrientProduct(analysis, language, isRestricted);
  const samplerOptions = getSamplerOptions(operation);
  const scannerOptions = getScannerOptions(operation);
  const isProScanOrTillRx = analysis.isProScan || analysis.isTillRx;

  const [showLabSection, { toggle: toggleLabSection }] = useDisclosure(
    !!analysis.nutrientExternalLabIdentifier,
  );

  const atLeastOneExternalLabAvailable = !!availableExternalLabs.length;

  const updatePlanState = (metaKeyValue: ZoneAnalysisKeyType) =>
    dispatch(updateZoneAnalysis(metaKeyValue));

  const handleNutrientSelection = (value: string | null) => {
    const isNutrientEnabled = value === NUTRIENT_PANEL;

    const newProducts =
      value === PRESSURE_NUTRIENT
        ? analysis.products.filter((p) => ![NUTRIENT_PANEL, NITRATE_PANEL].includes(p))
        : [...analysis.products, NUTRIENT_PANEL];

    updatePlanState({
      zones: analysis.isProScan && isNutrientPanel(newProducts) ? null : analysis.zones,
      products: newProducts,
      isProScan: Boolean(isNutrientEnabled && analysis.isProScan),
      analysisMode: ZONE_BY_ZONE,
      nutrientExternalLabIdentifier: null,
    });
  };

  const setupProScan = async () => {
    const isProScan = !analysis.isProScan;
    const newAnalysis = {
      ...analysis,
      isProScan,
      analysisMode: ZONE_BY_ZONE,
      isSplitDensityNitrate: false,
      scanDensity: isProScan ? DEFAULT_PRO_NUTRIENT_DENSITY : DEFAULT_GRID_SIZE,
      density:
        isProScan && !analysis.zones?.features.length ? DEFAULT_PRO_BIO_DENSITY : analysis.density,
    };

    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const setup590Analysis = async () => {
    const is590Analysis = !analysis.is590Analysis;
    const newAnalysis = {
      ...analysis,
      is590Analysis,
    };
    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const handleBioSelection = (product: string | null) => {
    if (product) {
      const { panels } = CURRENT_PRODUCTS[product];
      const isNutrientEnabled = panels.includes(NUTRIENT);
      const newAnalysis = {
        ...analysis,
        products: panels,
        isProScan: Boolean(isNutrientEnabled && analysis.isProScan),
        analysisMode: ZONE_BY_ZONE,
      };

      // Clear lab things and close the section if it's already open
      if (!isNutrientEnabled && showLabSection) {
        toggleLabSection();
      }

      updatePlanState({
        ...getNewAnalysisFromUpdate(newAnalysis, fieldGeometry),
        nutrientExternalLabIdentifier: null,
      });
    }
  };

  const updateNitratePanel = () => {
    if (analysis.products.includes(NITRATE_PANEL)) {
      const newProducts = analysis.products.filter((product) => product !== NITRATE_PANEL);
      updatePlanState({
        products: newProducts,
      });
    } else {
      updatePlanState({
        products: [...analysis.products, NITRATE_PANEL],
      });
    }
  };

  const updateTillageRx = () => {
    const newAnalysis = {
      ...analysis,
      isTillRx: !analysis.isTillRx,
      scanDensity: analysis.isProScan ? DEFAULT_PRO_NUTRIENT_DENSITY : DEFAULT_GRID_SIZE,
      analysisMode: ZONE_BY_ZONE,
    };
    updatePlanState(getNewAnalysisFromUpdate(newAnalysis, fieldGeometry));
  };

  const updateExternalNutrientLabId = (id: string | null) => {
    updatePlanState({
      nutrientExternalLabIdentifier: id,
    });
  };

  const handleExternalLabCancel = () => {
    toggleLabSection();
    updatePlanState({ nutrientExternalLabIdentifier: null });
  };

  const isNitratePanel = analysis.products.includes(NITRATE_PANEL);

  const BioSelect = (
    <Select
      data={possibleProductsBio}
      description={selectedBioProduct?.msg}
      inputWrapperOrder={['label', 'input', 'description', 'error']}
      label={getString('biological_analysis', language)}
      maw={DROPDOWN_WIDTH}
      value={getSelectedBioProductFromAnalysis(analysis, language, isRestricted)?.value}
      onChange={handleBioSelection}
    />
  );

  const LabSection = atLeastOneExternalLabAvailable ? (
    <Collapse in={showLabSection}>
      <Stack flex={1}>
        <Select
          label={getString('selectExternalLab', language)}
          value={analysis.nutrientExternalLabIdentifier}
          onChange={updateExternalNutrientLabId}
          data={availableExternalLabs.map(({ name, identifier, tests }) => ({
            value: identifier,
            label: `${name} (${tests.join(' + ')})`,
          }))}
        />
      </Stack>
    </Collapse>
  ) : null;

  const NutrientSection = (
    <Flex justify="space-between" gap="xl">
      <Stack flex={1} maw={DROPDOWN_WIDTH}>
        <Select
          data={possibleProductsNutrient}
          description={selectedNutrientProduct?.msg}
          disabled={showLabSection}
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          label={getString('nutrient_analysis', language)}
          value={analysis.products.includes(NUTRIENT_PANEL) ? NUTRIENT_PANEL : PRESSURE_NUTRIENT}
          onChange={handleNutrientSelection}
        />
        {!isBrazil && (
          <Switch
            checked={analysis.isProScan}
            disabled={!analysis.products.includes(NUTRIENT_PANEL)}
            label={getString('pro10Res', language)}
            size="md"
            onChange={setupProScan}
          />
        )}
      </Stack>
      {atLeastOneExternalLabAvailable && (
        <>
          {showLabSection && <Divider orientation="vertical" />}
          <Stack flex={1}>
            {LabSection}
            <div style={{ marginLeft: 'auto' }}>
              <Button
                size="xs"
                variant={showLabSection ? 'outline' : 'subtle'}
                onClick={handleExternalLabCancel}
                disabled={!analysis.products.includes(NUTRIENT_PANEL)}
              >
                {getString(showLabSection ? 'cancel' : 'addExternalLab', language)}
              </Button>
            </div>
          </Stack>
        </>
      )}
    </Flex>
  );

  const AnalysisOptionsSection = (
    <Stack>
      <Text fz="h4" fw={FONT_WEIGHT_BOLD} component="h3">
        {getString('analysisOptions', language)}
      </Text>
      {is590Capable(fieldProperties.country_code || '', fieldProperties.state) &&
      analysis.products.includes(NUTRIENT_PANEL) ? (
        <Switch
          checked={analysis.is590Analysis}
          description={getString('analysis590Description', language)}
          label={getString('analysis590Title', language)}
          size="md"
          onChange={setup590Analysis}
        />
      ) : null}
      <Switch
        checked={isNitratePanel}
        data-test-id="nitrate-toggle-slider"
        description={getString('nitrateAnalysisMsg', language)}
        disabled={!analysis.products.includes(NUTRIENT_PANEL)}
        label={getString('nitrateAnalysis', language)}
        size="md"
        onChange={updateNitratePanel}
      />
    </Stack>
  );

  const SamplingSection = (
    <Stack maw={DROPDOWN_WIDTH}>
      <Text fz="h3" fw={FONT_WEIGHT_BOLD} component="h2">
        {getString('sampling', language)}
      </Text>
      {analysis.products.length ? (
        <>
          {operation.billing_user_id ? (
            <Select
              data-test-id="sampled-by"
              data={samplerOptions}
              label={getString('sampledBy', language)}
              value={samplerOptions[analysis.samplerIndex]?.value}
              onChange={(value) =>
                value &&
                updatePlanState({
                  samplerIndex: samplerOptions?.findIndex((sam) => sam.value === value),
                })
              }
            />
          ) : (
            <Text c="darkRed">**{getString('addBillingResponsibility', language)}**</Text>
          )}
        </>
      ) : null}
      {isProScanOrTillRx && (
        <Select
          data-test-id="sampled-by"
          data={scannerOptions}
          label={getString('scannedBy', language)}
          value={scannerOptions[analysis.scannerIndex]?.value}
          onChange={(value) => {
            value &&
              updatePlanState({
                scannerIndex: scannerOptions?.findIndex((sam) => sam.value === value),
              });
          }}
        />
      )}
      <Select
        required
        data-test-id="sample-timing"
        label={getString('sampleTiming', language)}
        value={timings[analysis.sampleTimingIndex]?.value}
        data={timings.map((timing) => ({
          value: timing.value,
          label: timing.displayName,
        }))}
        onChange={(val) =>
          val &&
          updatePlanState({
            sampleTimingIndex: timings.findIndex((timing) => timing.value === val),
          })
        }
      />
      <Textarea
        data-test-id="sampler-instructions"
        label={getString('samplerInstructions', language)}
        minRows={3}
        value={analysis?.notes}
        onChange={(e) => updatePlanState({ notes: e.target.value })}
      />
    </Stack>
  );

  return (
    <Stack p="xl" maw="55rem" gap="md">
      <Text fz="h3" fw={FONT_WEIGHT_BOLD} component="h2">
        {getString('soilAnalysis', language)}
      </Text>
      {BioSelect}
      <Divider my="xs" />
      {NutrientSection}
      <Divider my="xs" />
      {AnalysisOptionsSection}
      {!isBrazil && (
        <Stack maw={DROPDOWN_WIDTH}>
          <Text fz="h4" fw={FONT_WEIGHT_BOLD} component="h3">
            {getString('compactionAnalysis', language)}
          </Text>
          <Switch
            checked={analysis.isTillRx}
            data-test-id="tillrx-toggle-slider"
            label={getString('tillMapper', language)}
            size="md"
            onChange={updateTillageRx}
          />
        </Stack>
      )}
      <Divider my="xs" />
      {SamplingSection}
    </Stack>
  );
};

export default AnalysisForm;
