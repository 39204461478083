import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { Header, Container, Button } from 'common';
import { downloadDNAConcentration, getDNAConcentration } from 'store/dnaConcentration/thunks';
import { DNAConcentrationOptionType } from 'store/dnaConcentration/types';

import { WellGrid } from '../../Wells/Grid/Grid';
import { ImportDataButton } from './ImportDataButton';

type DNAWellGridPropsType = {
  plateBarcode: string;
  quadrant?: number;
  options?: DNAConcentrationOptionType;
  allowImport?: boolean;
  children?: ReactNode;
};

export const DNAWellGrid = ({
  plateBarcode,
  quadrant = 0,
  options,
  allowImport,
  children,
}: DNAWellGridPropsType) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { setsByPlateBarcode } = useSelector((state: RootState) => ({
    setsByPlateBarcode: state.DNAConcentrations.setsByPlateBarcode,
  }));

  const handleDownload = () =>
    dispatch(
      downloadDNAConcentration(plateBarcode, quadrant, `dna_concentration-quadrant_${quadrant}`, {
        dna: true,
      }),
    );

  const quadrants = setsByPlateBarcode[plateBarcode] || {};
  const wells = quadrants[quadrant] || {};
  const hasData = Object.keys(wells).length > 0;

  useEffect(() => {
    dispatch(getDNAConcentration(plateBarcode, quadrant, options || { dna: true }));
  }, [dispatch, plateBarcode, quadrant, options]);

  return (
    <Container vertical>
      <Header title={getString('dnaConcentration', language)} underline="none" size="H2">
        <>
          {children}
          {allowImport && (
            <ImportDataButton
              plateBarcode={plateBarcode}
              quadrant={quadrant}
              isUpdating={hasData}
            />
          )}
          {hasData && <Button onClick={handleDownload}>{getString('exportData', language)}</Button>}
        </>
      </Header>
      <WellGrid wells={wells} disableHighlight />
    </Container>
  );
};
