import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Selector } from 'common';
import showToast from 'actions/toastActions';
import { activeOperationId } from 'selectors/operationSelectors';
import { requestAddBillingResponsibility } from 'store/operation/requests';
import { getOperation } from 'store/operation/thunks';
import { User } from 'store/user/types';
import { Agency } from 'store/agencies/types';
import styles from './Container.module.css';
import BillingUserDetails from './BillingUserDetails';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Modal, Stack, Text, Button, Space } from '@mantine/core';

interface Props {
  onClose: () => void;
  users: User[];
  agencies: Agency[];
  open: boolean;
}

const AddBillingResponsibilityDialog = ({ onClose, users, agencies, open }: Props) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const operationId = useSelector(activeOperationId);
  const [activeBillingIndex, setActiveBillingIdex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const options = useMemo(() => {
    return [
      ...agencies.map((agency) => ({
        id: agency.id,
        displayName: agency.name,
        agency: agency.name,
        user: agency.primary_user,
      })),
      ...users.map((user) => ({
        id: user.id,
        displayName: `${user.first_name} ${user.last_name}`,
        agency: null,
        user: user,
      })),
    ];
  }, [users, agencies]);

  const handleSubmit = useCallback(async () => {
    const selectedUserId = options[activeBillingIndex].user?.id;
    if (selectedUserId) {
      setIsSubmitted(true);
      try {
        await requestAddBillingResponsibility(operationId, selectedUserId);
        dispatch(getOperation(operationId));
      } catch (e) {
        showToast(getString('errorSettingBillingResMsg', language), 'error');
      }
      setIsSubmitted(false);
      onClose();
    }
  }, [options, activeBillingIndex, operationId]);

  return (
    <Modal
      onClose={onClose}
      opened={open}
      title={getString('addBillingResponsibility', language)}
      size="xl"
    >
      <Stack className={styles.Dialog}>
        <Text size="lg">{getString('whereSendInvoiceMsg', language)}:</Text>
        <Selector
          className={styles.BillingUserSelector}
          options={options}
          onChange={(idx) => setActiveBillingIdex(idx)}
          activeIndex={activeBillingIndex}
        />

        {options[activeBillingIndex]?.user && (
          <BillingUserDetails
            user={options[activeBillingIndex].user}
            agencyName={options[activeBillingIndex].agency}
          />
        )}
        <Space h="lg" />
        <div className={styles.Center}>
          <Button
            data-test-id="dialog-add-billing-responsibility"
            style={{ marginRight: '1rem' }}
            onClick={handleSubmit}
            disabled={isSubmitted || !options[activeBillingIndex]?.user?.id}
          >
            {getString('addBillingResponsibility', language)}
          </Button>
          <Button variant="outline" onClick={onClose}>
            {getString('cancel', language)}
          </Button>
        </div>
      </Stack>
    </Modal>
  );
};

export default AddBillingResponsibilityDialog;
