import React from 'react';
import { Group, Stack } from '@mantine/core';
import { PlateType } from 'store/plates/types';

import { AnalysisSelector } from '../FormInputs/AnalysisSelector';

type HomogenizationFormPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const HomogenizationForm = ({ plate, onValueChange }: HomogenizationFormPropsType) => (
  <Stack>
    <Group>
      <AnalysisSelector
        plate={plate}
        onValueChange={onValueChange}
        miw="12.5rem"
        maw="20rem"
        w="40%"
      />
    </Group>
  </Stack>
);
