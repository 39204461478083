export const RECEIVE_CONVERSION = 'RECEIVE_CONVERSION';
export const REQUEST_CONVERSION = 'REQUEST_CONVERSION';
export const CONVERSION_REQUEST_ERROR = 'CONVERSION_REQUEST_ERROR';

export type CurrencyStateType = {
  hasFailed: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  realsToDollars: number;
};

interface ReceiveConversionAction {
  type: typeof RECEIVE_CONVERSION;
  payload: number;
}

interface RequestConversionAction {
  type: typeof REQUEST_CONVERSION;
}

interface ConversionRequestErrorAction {
  type: typeof CONVERSION_REQUEST_ERROR;
}

export type ConversionActionTypes =
  | ReceiveConversionAction
  | RequestConversionAction
  | ConversionRequestErrorAction;
