import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { ReactComponent as Logo } from 'images/horizontalLogo.svg';
import { UserRole } from 'store/user/types';
import styles from './Container.module.css';
import Welcome from './Welcome';
import PasswordResetForm from './PasswordResetForm';

interface TokenInfo {
  roles: UserRole[];
}

const PasswordResetContainer = () => {
  const [isReset, setIsRest] = useState(false);
  const location = useLocation();
  const token = useMemo(() => location.hash.slice(1), [location.hash]);
  const parsedToken: TokenInfo = jwt_decode(token);
  return (
    <div className={styles.Wrapper}>
      <Logo className={styles.Logo} />
      <>
        {!isReset && (
          <Welcome showResetPassword={() => setIsRest(true)} role={parsedToken.roles[0]} />
        )}
        {isReset && <PasswordResetForm />}
      </>
    </div>
  );
};

export default PasswordResetContainer;
