import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Button, Header } from 'common';
import { BatchOverview } from './BatchOverview';
import styles from './Container.module.css';

const BatchesOverviewContainer = () => {
  const language = useBroswerLanguage();
  const navigate = useNavigate();

  // Fetch Batches and filter them by name
  return (
    <div className={styles.Wrapper}>
      <Header
        title={
          <div className={styles.Header}>
            <span>{getString('batches', language)}</span>
            <Button
              className={styles.NewButton}
              primary
              onClick={() => navigate('/lab/batches/create/')}
            >
              {getString('newBatch', language)}
            </Button>
          </div>
        }
      />
      <BatchOverview />
    </div>
  );
};

export default BatchesOverviewContainer;
