import { handleJsonResponse, requestGet, requestPost, requestPut, SERVICE_URL } from 'util/request';
import { queryStringify } from 'util/stringUtils';
import { CatalogType } from './types';

export const requestGetCatalogs = async (operationId?: number, showInUi: boolean = false) =>
  handleJsonResponse(
    await requestGet(
      `${SERVICE_URL}/catalogs?${queryStringify({ operation_id: operationId || undefined, show_in_ui: showInUi })}`,
    ),
  );

export const requestGetCatalog = async (id: number) =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/catalog/${id}`));

export const requestPostCatalog = async (body: Partial<CatalogType>) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/catalog/`, { body }));

export const requestPutCatalog = async (id: number, body: Partial<CatalogType>) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/catalog/${id}`, { body }));
