import { GeoJSON } from 'geojson';

export const REQUEST_STATE_GEOMETRY = 'REQUEST_STATE_GEOMETRY';
export const RECEIVE_STATE_GEOMETRY = 'RECEIVE_STATE_GEOMETRY';
export const STATE_GEOMETRY_ERROR = 'FIELD_GEOMETRY_ERROR';

export type StateGeometryType = {
  [id: string]: StateType;
};

export type StateType = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>;

type StateGeometryErrorType = {
  type: typeof STATE_GEOMETRY_ERROR;
  payload: {
    stateId: string;
  };
};

type ReceiveStateGeometryType = {
  type: typeof RECEIVE_STATE_GEOMETRY;
  payload: {
    stateId: string;
    countryId: string;
    geometry: StateType;
  };
};

type RequestStateGeometryType = {
  type: typeof REQUEST_STATE_GEOMETRY;
  payload: {
    stateId: string;
  };
};

export type StateActionsType =
  | StateGeometryErrorType
  | ReceiveStateGeometryType
  | RequestStateGeometryType;
