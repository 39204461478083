import {
  InputsActionType,
  InputsState,
  RECEIVE_INPUTS,
  REQUEST_INPUTS,
  REQUEST_INPUTS_ERROR,
} from './types';

export const initialState: InputsState = {
  inputs: [],
  hasFailed: false,
  hasFetched: false,
  isFetching: false,
};

export const inputsReducer = (state = initialState, action: InputsActionType): InputsState => {
  switch (action.type) {
    case RECEIVE_INPUTS:
      return {
        ...state,
        inputs: action.payload.inputs,
        hasFetched: true,
        isFetching: false,
      };
    case REQUEST_INPUTS:
      return {
        ...state,
        isFetching: true,
      };
    case REQUEST_INPUTS_ERROR:
      return {
        ...state,
        hasFailed: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
