import {
  AGENCY,
  agencyProductTypes,
  COMPLETE_BIO,
  contractDensities,
  contractEditableProductsMap,
  contractTypeDisplayNames,
  CONTRACT_TYPES,
  LIST,
  NUTRIENT_PANEL,
  PATTERN_360,
  PERFORMANCE_PANEL,
} from 'constants/products';
import { getString } from 'strings/translation';
import {
  ContractMinMaxType,
  ContractPriceBucketType,
  ContractResponseType,
  ContractType,
  DefaultContractPriceType,
} from 'store/agencies/types';
import { dollarsToReal } from './units';
import { DEFAULT_COMMITTED_SAMPLES } from '../constants/variables';

export const getProductPriceDisplay = (panel: string, priceType: string, language: string) => {
  return {
    panelType: panel,
    priceType: `${panel}_${priceType}` as keyof ContractPriceBucketType,
    label: `${getString(panel, language)} ${getString(priceType, language)} ($)`,
  };
};

export const getContractTypeOptions = (
  language: string,
  contractTypes: string[] = CONTRACT_TYPES,
) => {
  return contractTypes.map((contractType) => ({
    label: getString(contractTypeDisplayNames[contractType], language),
    value: contractType,
  }));
};

export const getSamplePriceDisplay = (
  panel: string,
  priceBucket: ContractPriceBucketType | undefined,
  language: string,
) => {
  if (!priceBucket) {
    return '';
  }
  return `$${dollarsToReal(priceBucket[panel] || 0, language)} / ${getString('sample', language)}`;
};

export const splitOutListProducts = (contract: ContractResponseType) => {
  const newPriceBuckets = contract.price_buckets.reduce(
    (list, bucket) => {
      const { density } = bucket;
      if (!density) {
        return list;
      }
      return {
        ...list,
        [density]: bucket,
      };
    },
    {} as ContractType['price_buckets'],
  );
  return {
    ...contract,
    price_buckets: newPriceBuckets,
  };
};

export const setupDefaultContracts = (defaultContract: DefaultContractPriceType) =>
  Object.keys(defaultContract).reduce((acc, tier) => {
    const buckets = defaultContract[tier];
    return {
      ...acc,
      [tier]: {
        price_buckets: buckets,
        contract_type: tier,
        committed_samples: DEFAULT_COMMITTED_SAMPLES,
      },
    };
  }, {});

export const fillMissingDensities = (
  contract: ContractType,
  fillBucket: ContractType['price_buckets'],
) => {
  const filledContract = { ...contract };
  contractDensities.forEach((density) => {
    if (!filledContract.price_buckets[density]) {
      filledContract.price_buckets[density] = fillBucket[density];
    }
  });
  return filledContract;
};

const getProductPriceType = (product: string, priceType: string) => {
  if (product === PERFORMANCE_PANEL && priceType === LIST) {
    return COMPLETE_BIO;
  }
  if (product === NUTRIENT_PANEL && priceType === LIST) {
    return PATTERN_360;
  }
  return product;
};
const getContractMinMaxError = (
  product: string,
  priceType: string,
  lowHigh: string,
  language: string,
) => {
  const contractPriceType = getProductPriceType(product, priceType);
  return `${getString(contractPriceType, language)} ${getString(priceType, language)}: ${getString(
    lowHigh,
    language,
  )}`;
};

export const checkProductPriceRequirements = (
  contract: ContractType,
  minMaxPrices: ContractMinMaxType,
  language: string,
) => {
  let errorMsg: string | null = null;
  for (const priceType of [AGENCY, LIST]) {
    const products = contractEditableProductsMap[priceType];
    for (const product of products) {
      const contractPriceName = `${product}_${priceType}`;
      for (const density of contractDensities) {
        const priceBucket = contract.price_buckets[density];
        if (priceBucket[contractPriceName] < minMaxPrices[product][priceType][0]) {
          errorMsg = getContractMinMaxError(product, priceType, 'tooLowMsg', language);
          break;
        }
        if (priceBucket[contractPriceName] > minMaxPrices[product][priceType][1]) {
          errorMsg = getContractMinMaxError(product, priceType, 'tooHighMsg', language);
          break;
        }
      }
    }
    if (errorMsg) {
      break;
    }
  }
  return errorMsg;
};

export const removeUnusedPriceBucketKeys = (contract: ContractType) => {
  const allowedKeys = agencyProductTypes.flatMap((type) =>
    contractEditableProductsMap[type].map((product) => `${product}_${type}`),
  );
  const newPriceBuckets = Object.entries(contract.price_buckets).reduce(
    (list, [density, bucket]) => {
      list[density] = Object.keys(bucket).reduce((newBucket, priceKey) => {
        if (allowedKeys.includes(priceKey)) {
          newBucket[priceKey] = bucket[priceKey];
        }
        return newBucket;
      }, {});
      return list;
    },
    {},
  );
  return {
    ...contract,
    price_buckets: newPriceBuckets,
  };
};
