import React from 'react';
import smallSquareLogo from 'images/smallSquareLogo.svg';

import styles from './WelcomeOperator.module.css';

const WelcomeOperator = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Container}>
      <img className={styles.Logo} src={smallSquareLogo} alt="Logo" />
      <h1>
        You aren't connected to any accounts. Ask for an invite from your Agent or Regional Manager
        to get started!
      </h1>
    </div>
  </div>
);

export default WelcomeOperator;
