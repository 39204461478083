import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spinner, Pagination } from 'common';
import { RootState } from 'store';
import { getOpenTransactions } from 'store/payments/thunks';
import { userIsAdmin, userIsSuperAdmin, userIsSampler } from 'store/user/selectors';
import OpenTable from '../common/Tables/OpenTable';
import styles from './Container.module.css';

type OpenListPropsType = {
  filter: string;
  page: number;
  setPage: (page: number) => void;
  selectedRole: string | undefined;
};

const OpenList = ({ filter, page, setPage, selectedRole }: OpenListPropsType) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const fetchOpenTransactions = useCallback(
    (pageNum, filterVal, role) => dispatch(getOpenTransactions(pageNum, filterVal, role)),
    [dispatch],
  );

  const { open, totalOpenPages, isFetching, isAdmin, isSuperAdmin, isSampler } = useSelector(
    (state: RootState) => ({
      open: state.payments.open.items,
      totalOpenPages: state.payments.open.total,
      isFetching: state.payments.isFetching,
      isAdmin: userIsAdmin(state),
      isSuperAdmin: userIsSuperAdmin(state),
      isSampler: userIsSampler(state),
    }),
  );

  useEffect(() => {
    fetchOpenTransactions(page, filter, selectedRole);
  }, [fetchOpenTransactions, page, filter, selectedRole]);

  const openRow = (userId: number) => {
    navigate(`/payments/open/details/${userId}`);
  };

  const hasOpenTransactions = () => {
    if (isAdmin || isSuperAdmin) {
      return true;
    }
    if (isSampler && open.length && open[0].open_transactions.summary?.balance > 0) {
      return true;
    }
    if (
      open.length &&
      (Object.keys(open[0].open_transactions.transactions_by_sample_plan).length ||
        open[0].open_transactions.adjustments.length)
    ) {
      return true;
    }
    return false;
  };

  return isFetching ? (
    <>
      <Spinner fill />
    </>
  ) : (
    <>
      {hasOpenTransactions() ? (
        <>
          <OpenTable rowClick={openRow} openTransactions={open} showRole isSampler={isSampler} />
          <div className={styles.Pagination}>
            <Pagination page={page} setPage={setPage} totalPages={totalOpenPages} />
          </div>
        </>
      ) : (
        <div className={styles.NoPaymentsWrapper}>
          <h2>
            You have no &apos;Open&apos; payments awaiting approval. To view &apos;Approved&apos;
            payments, click the &apos;Approved&apos; selector above.
          </h2>
        </div>
      )}
    </>
  );
};

export default OpenList;
