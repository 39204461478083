export const RECEIVED = 'received';
export const PROCESSED = 'processed';
export const CANCELLED = 'cancelled';
export const WONT_SAMPLE = "won't sample";

export const SOIL = 'soil';
export const RESIDUE = 'residue';
export const ROOT_BALL = 'root_ball';
export const RHIZOSPHERE = 'rhizosphere';
export const ROOT_TISSUE = 'root_tissue';
export const ISOLATES = 'isolates';

export const sampleTypeDisplayNames = {
  [SOIL as string]: 'soil',
  [RESIDUE as string]: 'residue',
  [ROOT_BALL as string]: 'rootBall',
  [RHIZOSPHERE as string]: 'rhizosphere',
  [ROOT_TISSUE as string]: 'rootTissue',
  [ISOLATES as string]: 'isolates',
};

export const TECHNICAL = 'technical';
export const RERUN = 'rerun';
export const PROCESS = 'process';

export const replicaTypeList = [RERUN, TECHNICAL, PROCESS];

export const replicaTypeDisplayNames = {
  [TECHNICAL as string]: 'technical',
  [RERUN as string]: 'rerun',
  [PROCESS as string]: 'process',
};
