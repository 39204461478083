import React from 'react';
import { AMPLICON, GENOMIC } from 'constants/analysis';
import { BatchFormType } from 'store/batches/types';
import { FormRows } from './FormRows';

type BatchFormContainerPropsType = {
  batch: BatchFormType;
  onValueChange: (
    attributeKey: keyof BatchFormType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const BatchFormContainer = ({ batch, onValueChange }: BatchFormContainerPropsType) => {
  const getPlateForm = () => {
    switch (batch.type) {
      case AMPLICON:
      case GENOMIC:
        return <FormRows batch={batch} onValueChange={onValueChange} showLanes />;
      default:
        return <FormRows batch={batch} onValueChange={onValueChange} />;
    }
  };

  return getPlateForm();
};
