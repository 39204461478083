import {
  RECEIVE_COLLECTIONS,
  REQUEST_COLLECTIONS,
  COLLECTION_REQUEST_ERROR,
  EOCollectionStateType,
  EOCollectionActionType,
} from './types';

export const initialState: EOCollectionStateType = {
  hasFetched: false,
  isFetching: false,
  hasFailed: false,
  fieldCollections: [],
};

const eoCollectionsReducer = (state = initialState, action: EOCollectionActionType) => {
  switch (action.type) {
    case REQUEST_COLLECTIONS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
        hasFailed: false,
      };
    case RECEIVE_COLLECTIONS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        hasFailed: false,
        fieldCollections: action.payload.fields,
      };
    case COLLECTION_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};

export default eoCollectionsReducer;
