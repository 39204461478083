export type ControlType = {
  id?: number;
  barcode: string;
  enabled?: boolean;
  notes?: string;
  type?: string;
  dilution: {
    zymo?: number | null;
    soil: number | null;
  };
};

export type ControlsStateType = {
  controls: ControlType[];
  hasFetched: boolean;
  isFetching: boolean;
  hasFailed: boolean;
};

export const RECEIVE_CONTROL = 'RECEIVE_CONTROL';
export const RECEIVE_CONTROLS = 'RECEIVE_CONTROLS';
export const REQUEST_CONTROLS = 'REQUEST_CONTROLS';
export const CONTROL_REQUEST_ERROR = 'REQUEST_CONTROLS_ERROR';

type RequestControlsActionType = {
  type: typeof REQUEST_CONTROLS;
};

type ReceiveControlActionType = {
  type: typeof RECEIVE_CONTROL;
  payload: {
    [key: string]: any;
  };
};

type ReceiveControlsActionType = {
  type: typeof RECEIVE_CONTROLS;
  payload: {
    controls: ControlType[];
  };
};

type ControlRequestErrorType = {
  type: typeof CONTROL_REQUEST_ERROR;
  payload: {
    message: string;
  };
};

export type ControlsActionType =
  | ReceiveControlActionType
  | RequestControlsActionType
  | ReceiveControlsActionType
  | ControlRequestErrorType;
