import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { RootState } from 'store';
import { userFullName } from 'util/userRoles';
import { confirmCollectionAssignment, fetchCollections } from 'store/eoCollections/thunks';
import {
  type DataReviewGroupedCollections,
  type DataReviewGroupViewType,
  type EOCollectionFeatureType,
  type EOCollectionFieldFeatureType,
} from 'store/eoCollections/types';
import { FONT_WEIGHT_BOLD } from 'constants/mantine';
import { RED } from 'util/mapImageryColors';
import showToast from 'actions/toastActions';
import CollectionsGroup from './CollectionsGroup';

import GroupDataSwitcher from './GroupDataSwitcher';
import BackToTopBtn from 'common/Components/BackToTopBtn';

/**
 * Munge the collections into groups by grower or dealer
 *
 * @param fieldCollections array of geojson features
 * @param groupBy grouping type
 * @param noDealerString text to use if no dealer is found
 * @returns object with grouped collections by operation (grower) or agency (dealer)
 */
const getGroupedCollections = (
  fieldCollections: EOCollectionFieldFeatureType[],
  groupBy: DataReviewGroupViewType,
  noDealerString: string,
) => {
  const NO_DEALER_ID = 'no-dealer';

  return fieldCollections.reduce(
    ({ groupedCollections, allAgencyIds }, field) => {
      const { operation, agency } = field.features[0].properties;
      const groupObject = groupBy === 'grower' ? operation : agency;
      const id = groupObject?.id || NO_DEALER_ID;
      const fields = (groupedCollections[id]?.fields || []).concat(field);
      const secondaryNames = groupedCollections[id]?.secondaryNames || [];
      const agencyIdNotInList = agency && !allAgencyIds.includes(agency.id);

      return {
        allAgencyIds: agencyIdNotInList ? [...allAgencyIds, agency.id] : allAgencyIds,
        groupedCollections: {
          ...groupedCollections,
          [id]: {
            name: groupObject?.name || noDealerString,
            fields,
            secondaryNames: [
              ...secondaryNames,
              groupBy === 'grower' ? agency?.name || noDealerString : operation.name,
            ],
          },
        },
      };
    },
    { groupedCollections: {}, allAgencyIds: [] } as {
      groupedCollections: DataReviewGroupedCollections;
      allAgencyIds: number[];
    },
  );
};

const DataInboxContainer = () => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const noDealerString = getString('noDealer', language);
  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [modalVisible, { open, close }] = useDisclosure(false);
  const [groupBy, setGroupBy] = useState<DataReviewGroupViewType>('grower');

  const { currentUser, fieldCollections, hasFetched } = useSelector((state: RootState) => ({
    currentUser: state.user.currentUser,
    fieldCollections: state.eoCollections.fieldCollections,
    hasFetched: state.eoCollections.hasFetched,
  }));

  const { groupedCollections, allAgencyIds } = getGroupedCollections(
    fieldCollections,
    groupBy,
    noDealerString,
  );

  const shouldShowGroupSwitcher = allAgencyIds.length > 1;
  const getCollections = () => dispatch(fetchCollections());

  useEffect(() => {
    getCollections();
  }, []);

  if (!currentUser || (!hasFetched && !fieldCollections.length)) {
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    );
  }

  const handleSubmit = async () => {
    try {
      setIsConfirming(true);
      const activeFieldCollections = fieldCollections.filter((field) =>
        selectedFieldIds.includes(field.features[0].properties.id),
      );
      const allCollections = activeFieldCollections.reduce(
        (result, field) => result.concat(field.features[0].properties.pending_eo_collections),
        [] as EOCollectionFeatureType[],
      );
      const assignments = allCollections.reduce(
        (result, collection) => {
          const { id: collectionId, sampling_plan_id } = collection.properties;
          const ids = (result[sampling_plan_id] || []).concat(collectionId);
          return {
            ...result,
            [sampling_plan_id]: ids,
          };
        },
        {} as { [samplingPlanId: number]: number[] },
      );
      const payload = { assignments };
      await dispatch(confirmCollectionAssignment(payload, language));
      setIsConfirming(false);
      close();
    } catch (error) {
      showToast(getString('dataConfirmationErrorMsg', language), 'error', false);
    }
  };

  const toggleField = (fieldId: number) => {
    if (selectedFieldIds.includes(fieldId)) {
      setSelectedFieldIds(selectedFieldIds.filter((id) => id !== fieldId));
    } else {
      setSelectedFieldIds(selectedFieldIds.concat(fieldId));
    }
  };

  return (
    <>
      <Box pos="sticky" top={0} bg="var(--mantine-color-body)" style={{ zIndex: 3 }} pt="xl">
        <Group justify="space-between" mb="lg">
          <Title order={1}>
            {getString('scanHistory', language)} - {userFullName(currentUser)}
          </Title>
          {shouldShowGroupSwitcher && (
            <GroupDataSwitcher currentValue={groupBy} onChange={setGroupBy} language={language} />
          )}
        </Group>
        <Divider size="sm" />
      </Box>
      {fieldCollections.length ? (
        <>
          {Object.keys(groupedCollections).map((groupId) => (
            <CollectionsGroup
              collections={groupedCollections[groupId]}
              key={groupId}
              selectedFieldIds={selectedFieldIds}
              toggleField={toggleField}
              shouldShowGrowerSubheading={groupBy === 'dealer'}
            />
          ))}
          <Flex justify="flex-end" pos="sticky" py="lg" bottom={0} bg="var(--mantine-color-body)">
            <Flex gap="sm">
              <Button disabled={!selectedFieldIds.length} onClick={open}>
                {getString('confirmFieldAssignment', language)}
              </Button>
            </Flex>
          </Flex>
        </>
      ) : (
        <Center h="50vh">
          <Title order={3}>{getString('noDataToReview', language)}</Title>
        </Center>
      )}
      <BackToTopBtn />
      {modalVisible && (
        <Modal
          opened={modalVisible}
          onClose={close}
          centered
          withCloseButton={false}
          size="lg"
          ta="center"
        >
          <Stack p="2rem">
            <Title
              order={1}
              fw={FONT_WEIGHT_BOLD}
            >{`${getString('confirmFieldAssignment', language)}?`}</Title>
            <Text>{getString('confirmCollectionMsg1', language)}</Text>
            <Text>{getString('confirmCollectionMsg2', language)}</Text>
            <Flex justify="flex-end">
              <Button bg={RED} onClick={close} variant="filled">
                {getString('cancel', language)}
              </Button>
              <Button loading={isConfirming} ml="1rem" onClick={handleSubmit}>
                {getString('confirm', language)}
              </Button>
            </Flex>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default DataInboxContainer;
