import React from 'react';
import { Group, Stack } from '@mantine/core';
import { PlateType } from 'store/plates/types';
import { plateTypesToAnalysis } from 'constants/analysis';

import { AnalysisSelector } from '../FormInputs/AnalysisSelector';
import { SoilProcessingSelector } from '../FormInputs/SoilProcessingSelector';

type ChemistryFormPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const ChemistryForm = ({ plate, onValueChange }: ChemistryFormPropsType) => {
  const analysisList = plate?.plate_type ? plateTypesToAnalysis[plate.plate_type] : [];
  return (
    <Stack>
      {analysisList.length > 1 && (
        <Group>
          <AnalysisSelector
            plate={plate}
            onValueChange={onValueChange}
            analysis={analysisList}
            miw="12.5rem"
            maw="20rem"
            w="40%"
          />
        </Group>
      )}
      <Group>
        <SoilProcessingSelector
          plate={plate}
          onValueChange={onValueChange}
          miw="12.5rem"
          maw="20rem"
          w="40%"
        />
      </Group>
    </Stack>
  );
};
