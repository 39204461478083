import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSamplingTimings } from 'store/samplingTimings/thunks';

const useSampleTiming = () => {
  const dispatch = useDispatch();
  const { timings, currentTiming, fetchedTimings } = useSelector((state: RootState) => ({
    currentTiming: state.timings.currentTiming,
    fetchedTimings: state.timings.hasFetched,
    timings: state.timings.timings,
  }));

  useEffect(() => {
    if (!fetchedTimings) {
      dispatch(getSamplingTimings());
    }
  }, [fetchedTimings, currentTiming]);

  return { timings, currentTiming };
};

export default useSampleTiming;
