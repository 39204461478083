import { AppThunk } from 'store';
import {
  receiveSalesFlowMeta,
  requestOperationInfo,
  requestSalesFlowMeta,
  salesFlowRequestError,
  receiveOperationCropInfo,
} from './actions';
import { requestPostOperationCropInfo, requestSalesFlowData } from './requests';
import { OperationDetailsPostType } from './types';

export const getSalesFlowMeta = (): AppThunk => async (dispatch) => {
  dispatch(requestSalesFlowMeta());
  try {
    const response = await requestSalesFlowData();
    return dispatch(receiveSalesFlowMeta(response));
  } catch (e) {
    return dispatch(salesFlowRequestError());
  }
};

export const postOperationCropInfo =
  (
    operationId: number,
    operationInfo: OperationDetailsPostType,
    setNextStep: Function,
    setErrorToast: Function,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(requestOperationInfo(operationId));
    try {
      const response = await requestPostOperationCropInfo(operationInfo);
      dispatch(receiveOperationCropInfo(operationId, response));
      return setNextStep();
    } catch (e) {
      setErrorToast();
      return dispatch(salesFlowRequestError());
    }
  };
