import React, { FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './TooltipWrapper.module.css';

type EventHandler = () => void;

type TooltipWrapperProps = {
  className?: string;
  content: any;
  left?: boolean;
  onEnter?: EventHandler;
  onLeave?: EventHandler;
  right?: boolean;
  dataTestId?: string;
  tooltipClassName?: string;
  children: ReactNode;
};

export const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({
  children,
  className,
  content,
  left,
  onEnter,
  onLeave,
  right,
  dataTestId,
  tooltipClassName,
}) => {
  const [visible, setVisible] = useState(false);
  const timeout = useRef<any | null>(null);

  const onMouseEnter = useCallback(() => {
    if (timeout.current !== null) clearTimeout(timeout.current);
    setVisible(true);
    onEnter && onEnter();
  }, [onEnter]);

  const onMouseLeave = useCallback(() => {
    if (timeout.current !== null) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setVisible(false);
      onLeave && onLeave();
    }, 300);
  }, [onLeave]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classnames(styles.Wrapper, className)}
    >
      <div className={styles.Children}>{children}</div>
      <div
        className={classnames(
          styles.Tooltip,
          {
            [styles.Visible]: visible,
            [styles.Right]: right,
            [styles.Left]: left,
          },
          tooltipClassName,
        )}
        data-test-id={dataTestId}
      >
        {content}
      </div>
    </div>
  );
};

TooltipWrapper.defaultProps = {
  className: '',
  onEnter: () => {},
  onLeave: () => {},
  dataTestId: '',
  tooltipClassName: '',
};
