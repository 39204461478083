import {
  ControlsActionType,
  ControlsStateType,
  ControlType,
  CONTROL_REQUEST_ERROR,
  RECEIVE_CONTROL,
  RECEIVE_CONTROLS,
  REQUEST_CONTROLS,
} from './types';

export const initialState: ControlsStateType = {
  hasFetched: false,
  isFetching: false,
  hasFailed: false,
  controls: [],
};

const processNewControl = (controls: ControlType[], newControl: ControlType): ControlType[] => {
  const duplicatesRemoved = controls.filter((control) => control.id !== newControl.id);
  return duplicatesRemoved.concat(newControl);
};

const controlsReducer = (state = initialState, action: ControlsActionType) => {
  switch (action.type) {
    case REQUEST_CONTROLS:
      return {
        ...state,
        hasFetched: false,
        isFetching: true,
        hasFailed: false,
      };
    case RECEIVE_CONTROLS:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        controls: action.payload.controls,
      };
    case RECEIVE_CONTROL:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        controls: processNewControl(state.controls, action.payload.control),
      };
    case CONTROL_REQUEST_ERROR:
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    default:
      return state;
  }
};

export default controlsReducer;
