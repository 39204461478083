import React from 'react';
import { Group, Stack } from '@mantine/core';
import { PlateType } from 'store/plates/types';

import { SourceBarcodeInput } from '../FormInputs/SourceBarcodeInput';
import { ExtractionSelector } from '../FormInputs/ExtractionSelector';

type DNAExtractionFormPropsType = {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const DNAExtractionForm = ({ plate, onValueChange }: DNAExtractionFormPropsType) => (
  <Stack>
    <Group>
      <SourceBarcodeInput
        plate={plate}
        onValueChange={onValueChange}
        miw="12.5rem"
        maw="20rem"
        w="40%"
      />
    </Group>
    <Group>
      <ExtractionSelector
        plate={plate}
        onValueChange={onValueChange}
        miw="12.5rem"
        maw="20rem"
        w="40%"
      />
    </Group>
  </Stack>
);
