import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Feature, GeometryCollection } from '@turf/helpers';
import { generateZonesForPoints } from 'util/geospatial';
import { AnalyticType } from 'store/analytics/types';
import { Modal, Group, Box, Stack, Button, Text, Input, Select } from '@mantine/core';
import { defaultPrescriptionName, getNutrientSelectorOptions } from 'util/prescription';
import { FieldType } from 'store/fields/types';
import { getFieldGeometry } from 'store/fields/thunks';
import {
  getPlanName,
  getProNutrientMapLayer,
  hasSoilAttributes,
  isPlanCreated,
  isPointBased,
  sortPlansByDate,
} from 'util/samplePlan';
import { DEFAULT_RX_VALUES_BR, DEFAULT_RX_VALUES_US } from 'constants/prescription';
import { SampleFeatureType } from 'store/samples/types';
import { POLYGON } from 'constants/mapbox';
import { postPrescription } from 'store/prescriptions/requests';
import {
  BPH_SIKORA_ID,
  DEFAULT_RX_DENSITY,
  PH_ID,
  POTASSIUM_ID,
  PRO_EXPORT_ACRE_DENSITY_OPTIONS,
  SOIL_ATTRIBUTES,
} from 'constants/results';
import showToast, { type ToastType } from 'actions/toastActions';
import { BR } from 'constants/countries';
import { sortByCreatedAt } from 'util/date';
import { NUTRIENT_PANEL } from 'constants/products';

import MapThumbnail from './MapThumbnail';

import styles from './CreatePrescriptionModal.module.css';
import { getProPrescriptions } from 'store/prescriptions/thunks';

type CreatePrescriptionModalProps = {
  analytics: AnalyticType[];
  field: FieldType;
  onClose: () => void;
  onSubmit: (id: number) => void;
  samples: SampleFeatureType[];
  opened: boolean;
};

const CreatePrescriptionModal = ({
  analytics,
  field,
  onClose,
  onSubmit,
  samples,
  opened,
}: CreatePrescriptionModalProps) => {
  const language = useBroswerLanguage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(defaultPrescriptionName());

  const [maxRate, setMaxRate] = useState<number | undefined>();

  const nutrientSelectorOptions = getNutrientSelectorOptions(language);
  const dispatch = useDispatch();

  const samplingPlanOptions = (() => {
    const { sampling_plans } = field.features[0].properties;
    const plansWithAttributes = sortPlansByDate(
      sampling_plans.filter((plan) => hasSoilAttributes(plan) && !isPlanCreated(plan)),
    );
    return plansWithAttributes.map((plan) => ({
      id: plan.id,
      displayName: getPlanName(plan),
      label: getPlanName(plan),
      value: String(plan.id),
      plan,
    }));
  })();

  const [activeSamplePlan, setActiveSamplePlan] = useState<string>(
    String(samplingPlanOptions[0].id) || '',
  );
  const selectedPlan = samplingPlanOptions.find((plan) => plan.id === Number(activeSamplePlan));

  const planNutrientOptions = selectedPlan
    ? nutrientSelectorOptions
        .filter((option) => selectedPlan.plan.analytics[SOIL_ATTRIBUTES][option.id])
        .map((option) => ({
          id: option.id,
          label: option.displayName,
          value: String(option.id),
        }))
    : [];

  const proDensityOptions = selectedPlan?.plan.pro_densities.length
    ? PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
        selectedPlan.plan.pro_densities.includes(option.value),
      ).map((option) => ({
        id: option.id,
        label: option.label,
        value: option.value,
      }))
    : [];

  const [activeNutrient, setActiveNutrient] = useState<string>(
    String(planNutrientOptions?.[0]?.id) || '',
  );
  const [activeDensity, setActiveDensity] = useState(
    String(
      proDensityOptions.find((option) => option.value === DEFAULT_RX_DENSITY.value)?.value || '',
    ),
  );
  const selectedNutrient = analytics.find((analytic) => analytic.id === Number(activeNutrient));

  const proLayer =
    selectedPlan && selectedNutrient
      ? getProNutrientMapLayer(selectedPlan.plan, selectedNutrient)
      : null;

  useEffect(() => {
    setMaxRate(undefined);
  }, [activeNutrient]);

  const samplingPlanSamples = (() => {
    if (selectedPlan) {
      const planSamples = samples.filter(
        (sample) =>
          sample.properties.sampling_plan_id === selectedPlan.id &&
          !sample.properties.biological_subsample,
      );
      if (!isPointBased(selectedPlan.plan)) {
        return planSamples;
      }
      return generateZonesForPoints(
        planSamples as Feature<GeometryCollection>[],
        field,
      ) as SampleFeatureType[];
    }
  })();

  const showMessage = (message: string, type?: ToastType, timeout = 5000) =>
    showToast(message, type, timeout);

  const submit = async () => {
    const { id: fieldId, country_code: countryCode, crop_plans } = field.features[0].properties;
    setIsSubmitting(true);
    const crop_plan = sortByCreatedAt(crop_plans || [])?.[0];
    // for ph prescriptions, we want to show ph on the map but use buffer ph for
    // the amount calculations
    const analyticId = Number(activeNutrient) === PH_ID ? BPH_SIKORA_ID : Number(activeNutrient);
    const proDensity = PRO_EXPORT_ACRE_DENSITY_OPTIONS.find(
      (density) => density.value === activeDensity,
    );

    const zones = proDensity
      ? undefined
      : (samplingPlanSamples || [])
          .map((sample) => ({
            ...sample,
            geometry: sample.geometry.geometries.find((geom) => geom.type === POLYGON),
            properties: {
              sample_uuid: sample.properties.sample_uuid,
              amount:
                sample.properties.analytics[SOIL_ATTRIBUTES]?.[analyticId]?.quantity ||
                selectedPlan?.plan.analytics[SOIL_ATTRIBUTES]?.[analyticId].quantity ||
                null,
              products: sample.properties.products,
            },
          }))
          .filter(
            (sample) =>
              Boolean(sample.geometry) && sample.properties.products.includes(NUTRIENT_PANEL),
          );
    // @ts-ignore
    const defaultValues =
      (countryCode === BR ? DEFAULT_RX_VALUES_BR[analyticId] : DEFAULT_RX_VALUES_US[analyticId]) ||
      {};
    const payload = {
      ...defaultValues,
      field_id: fieldId,
      name,
      zones,
      sampling_plan_id: selectedPlan?.id,
      crop: crop_plan?.crop || defaultValues.crop,
      expected_yield: crop_plan?.target_yield_per_acre || defaultValues.expected_yield,
      maximum_rate: maxRate || defaultValues.maximum_rate,
      pro_density: proDensity?.value,
    };
    const response = await postPrescription(payload);
    await Promise.all([
      dispatch(getFieldGeometry(fieldId)),
      dispatch(getProPrescriptions(fieldId)),
    ]);
    showMessage(getString('prescriptionCreatedMsg', language), 'success');
    onSubmit(response.id);
  };

  const map = (() => {
    const planAnalytic =
      selectedPlan?.plan.analytics[SOIL_ATTRIBUTES]?.[selectedNutrient?.id || -1];
    if (!(samplingPlanSamples && selectedNutrient && selectedPlan && planAnalytic)) {
      return null;
    }
    return (
      <MapThumbnail
        activeAnalytic={selectedNutrient}
        field={field}
        planAnalytic={planAnalytic}
        proLayer={proLayer}
        samples={samplingPlanSamples}
        samplingPlan={selectedPlan.plan}
      />
    );
  })();

  const isBrazilK =
    field.features[0].properties?.country_code === BR && Number(activeNutrient) === POTASSIUM_ID;

  return (
    <Modal
      size="60rem"
      title={getString('createNutrientRx', language)}
      onClose={onClose}
      opened={opened}
      centered
    >
      <Group data-test-id="create-rx-modal-body" gap="md">
        <Stack w="25rem">
          <Group justify="space-between">
            <Text>{getString('rxName', language)}:</Text>
            <Input
              data-test-id="rx-name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              value={name}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('generateRxFrom', language)}:</Text>
            <Select
              value={activeSamplePlan}
              onChange={(val) => val && setActiveSamplePlan(String(val))}
              data={samplingPlanOptions}
            />
          </Group>
          <Group justify="space-between">
            <Text>{getString('nutrient', language)}:</Text>
            <Select
              value={activeNutrient}
              onChange={(val) => val && setActiveNutrient(String(val))}
              data={planNutrientOptions}
            />
          </Group>
          {proLayer && proDensityOptions.length ? (
            <Group justify="space-between">
              <Text>{getString('density', language)}:</Text>
              <Select
                value={activeDensity}
                onChange={(val) => val && setActiveDensity(val)}
                data={proDensityOptions}
              />
            </Group>
          ) : null}
          {isBrazilK && (
            <Group justify="space-between">
              <Text>{getString('maximumRate', language)}:</Text>
              <Input
                data-test-id="rx-maximum-rate"
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMaxRate(Number(e.target.value || 0))
                }
                value={maxRate}
              />
            </Group>
          )}
        </Stack>
        <Box className={styles.Map}>{map}</Box>
      </Group>
      <Group justify="flex-end">
        <Button
          data-test-id="create-edit-rx"
          className={styles.Submit}
          onClick={submit}
          loading={isSubmitting}
          disabled={!name || !activeNutrient || !activeSamplePlan}
        >
          {getString('createAndEditRx', language)}
        </Button>
      </Group>
    </Modal>
  );
};

export default CreatePrescriptionModal;
