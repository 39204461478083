import React from 'react';
import ChartBar from './ChartBar';

import Styles from './Container.module.css';

interface BarChartProps {
  barColor: string;
  chartData: { name: string; value: number }[];
  blurbParagraph: string;
  barText: string;
  increaseText: string;
  legendText: string;
  domainRange: number[];
}

const ChartBarWrapper = ({
  barColor,
  chartData,
  blurbParagraph,
  barText,
  increaseText,
  legendText,
  domainRange,
}: BarChartProps) => {
  return (
    <div className={Styles.ChartGrouping}>
      <div className={Styles.TextNextToDifferenceLine}>{blurbParagraph}</div>
      <div className={Styles.TextOnDifferenceLine}>
        <div>
          <b>{barText}</b>
        </div>
        <div>
          <b>{increaseText}</b>
        </div>
      </div>
      <div className={Styles.DifferenceLine} />
      <div className={Styles.ChartWrapper}>
        <ChartBar
          barColor={barColor}
          chartData={chartData}
          legendText={legendText}
          domainRange={domainRange}
        />
      </div>
    </div>
  );
};
export default ChartBarWrapper;
