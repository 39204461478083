import { AppThunk } from 'store';
import { controlRequestError, receiveControl, receiveControls, requestControls } from './actions';
import {
  requestDeleteControl,
  requestGetControls,
  requestPostControl,
  requestPutControl,
} from './requests';
import { ControlType } from './types';

export const createControl =
  (body: Partial<ControlType>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestControls());
      const control = await requestPostControl(body);
      dispatch(receiveControl(control));
    } catch (e) {
      dispatch(controlRequestError({ message: e.message }));
    }
  };

export const disableControl =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestControls());
      const control = await requestDeleteControl(id);
      dispatch(receiveControl(control));
    } catch (e) {
      dispatch(controlRequestError({ message: e.message }));
    }
  };

export const editControl =
  (id: number, body: { enabled: boolean }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestControls());
      const control = await requestPutControl(id, body);
      dispatch(receiveControl(control));
    } catch (e) {
      dispatch(controlRequestError({ message: e.message }));
    }
  };

export const getAllControls = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestControls());
    const controls = await requestGetControls();
    dispatch(receiveControls(controls));
  } catch (e) {
    dispatch(controlRequestError({ message: e.message }));
  }
};
