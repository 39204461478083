import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { Container, Header, Toggle } from 'common';
import { LabShipment, LabShipmentFormType } from 'store/labShipments/types';

import styles from './RegulatedMaterial.module.css';

type RegulatedMaterialPropsType = {
  shipment: LabShipment | LabShipmentFormType;
  updateShipment: (attributeName: string, newValue: boolean) => void;
};

export const RegulatedMaterial = ({ shipment, updateShipment }: RegulatedMaterialPropsType) => {
  const language = useBroswerLanguage();

  const updateIsRegulated = (inputValue: boolean) => {
    updateShipment('is_regulated', inputValue);
  };

  return (
    <Container vertical>
      <Header
        title={
          <div className={styles.Header}>
            <div>{getString('regulatedMaterial', language)}</div>
            <div>
              <Toggle
                checked={shipment.is_regulated}
                onChange={(e: React.ChangeEvent<HTMLFormElement>) =>
                  updateIsRegulated(e.target.checked)
                }
              />
            </div>
            <div className={styles.InfoText}>{getString('regulatedMaterialMsg', language)}</div>
          </div>
        }
        underline="thin"
        size="H2"
      />
    </Container>
  );
};
