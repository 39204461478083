import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'store';
import { getFieldGeometry, setFieldsWillFetchList } from 'store/fields/thunks';
import { OperationType } from 'store/operation/types';
import { getEventsForField } from 'store/fieldEvents/thunks';
import { FIELDS_PAGINATE_BY } from 'constants/variables';

const useOperationFieldGeometries = (
  operation: OperationType | undefined,
  removeSamplesFromPlans?: boolean,
  inactiveFields?: boolean,
  cropYear?: number | null,
) => {
  const dispatch = useDispatch();

  let allFields = operation?.fields || [];
  if (operation?.fields && inactiveFields) {
    allFields = allFields.concat(operation.inactive_fields);
  }
  const operationFieldIds = allFields.map((field) => field.id);
  const isOperationField = (ids: number[]) => ids.filter((id) => operationFieldIds.includes(id));

  const {
    allFieldGeometries,
    geometries,
    isFetchingList,
    hasFailedList,
    hasFetchedList,
    willFetchList,
  } = useSelector((state: RootState) => ({
    allFieldGeometries: state.fieldGeometry.geometries,
    geometries: Object.keys(state.fieldGeometry.geometries)
      .filter((field_id) => operationFieldIds.includes(Number(field_id)))
      .map((field_id) => state.fieldGeometry.geometries[Number(field_id)]),
    hasFailedList: isOperationField(state.fieldGeometry.hasFailedList),
    hasFetchedList: isOperationField(state.fieldGeometry.hasFetchedList),
    isFetchingList: isOperationField(state.fieldGeometry.isFetchingList),
    willFetchList: isOperationField(state.fieldGeometry.willFetchList),
  }));

  const getFields = useCallback(
    (neededFieldIds: number[]) => {
      neededFieldIds.forEach((fieldId) => {
        if (inactiveFields) {
          dispatch(getEventsForField(fieldId));
        }
        return dispatch(getFieldGeometry(fieldId, removeSamplesFromPlans));
      });
    },
    [dispatch, removeSamplesFromPlans],
  );

  useEffect(() => {
    if (!isFetchingList.length) {
      const newFieldIds = allFields
        .filter((field) => !cropYear || (cropYear && field.crop_years.includes(cropYear)))
        .map((field) => field.id);
      const alreadyExistIds = Object.keys(geometries).map((str) => parseInt(str, 10));
      const neededFieldIds = newFieldIds.filter(
        (nfid) =>
          !alreadyExistIds.includes(nfid) &&
          !hasFetchedList.includes(nfid) &&
          !hasFailedList.includes(nfid),
      );

      if (neededFieldIds.length) {
        dispatch(
          setFieldsWillFetchList(neededFieldIds.slice(FIELDS_PAGINATE_BY, neededFieldIds.length)),
        );
        getFields(neededFieldIds.slice(0, FIELDS_PAGINATE_BY));
      }
    }
  }, [
    allFields,
    dispatch,
    geometries,
    getFields,
    hasFetchedList,
    isFetchingList,
    hasFailedList,
    removeSamplesFromPlans,
  ]);

  return {
    allFieldGeometries,
    isFetchingList,
    willFetchList,
    hasFetchedList,
    hasFailedList,
  } as const;
};

export default useOperationFieldGeometries;
