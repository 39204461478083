import React, { FunctionComponent, ReactNode } from 'react';

import { PrintHeaderFooter } from 'common';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return <PrintHeaderFooter>{children}</PrintHeaderFooter>;
};

export default Layout;
