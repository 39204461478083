import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Label, Selector, Textarea } from 'common';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { RecommendationType } from 'store/recommendations/types';
import { RecommendationSetType } from 'store/recommendationSets/types';
import { getRecommendationSet } from 'store/recommendationSets/thunks';
import {
  requestCreateDefaultRecommendation,
  requestUpdateDefaultRecommendation,
} from 'store/recommendations/requests';
import { HIGH_RISK, LOW_RISK, MODERATE_RISK } from 'constants/fieldRisks';

import styles from '../Container.module.css';

type EditRecommendationPropsType = {
  recSet: Partial<RecommendationSetType>;
  onSuccess: (msg: string) => void;
  onError: (msg: string) => void;
  isSaving: boolean;
  toggleIsSaving: (saving: boolean) => void;
};

const EditRecommendationContainer = ({
  recSet,
  onSuccess,
  onError,
  isSaving,
  toggleIsSaving,
}: EditRecommendationPropsType) => {
  const dispatch = useDispatch();
  const { analytics } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
  }));

  const [analytic, setAnalytic] = useState<AnalyticType>();
  const [lowRecommendation, setLowRecommendation] = useState<Partial<RecommendationType>>();
  const [medRecommendation, setMedRecommendation] = useState<Partial<RecommendationType>>();
  const [highRecommendation, setHighRecommendation] = useState<Partial<RecommendationType>>();

  useEffect(() => {
    if (analytic) {
      const low = recSet.recommendations?.find(
        (r) => r.analytic_id === analytic.id && r.risk_level === LOW_RISK,
      );
      const med = recSet.recommendations?.find(
        (r) => r.analytic_id === analytic.id && r.risk_level === MODERATE_RISK,
      );
      const high = recSet.recommendations?.find(
        (r) => r.analytic_id === analytic.id && r.risk_level === HIGH_RISK,
      );

      setLowRecommendation(low || { recommendation: '' });
      setMedRecommendation(med || { recommendation: '' });
      setHighRecommendation(high || { recommendation: '' });
    }
  }, [recSet, analytic]);

  const fetchRecSet = useCallback((id: number) => dispatch(getRecommendationSet(id)), [dispatch]);

  const analyticOptions = useMemo(() => {
    return analytics.map((a) => ({
      id: a.id,
      value: a,
      displayName: a.name,
    }));
  }, [analytics]);

  const updateRecommendation = useCallback(
    async (risk_level: 'low' | 'moderate' | 'high', recommendation: string, recId?: number) => {
      // if has an id, update existing recommendation. else create a new one
      if (recId) {
        try {
          await requestUpdateDefaultRecommendation(
            {
              recommendation,
            },
            recId,
          );
          onSuccess('Recommendation Updated');
          toggleIsSaving(false);
        } catch (e) {
          toggleIsSaving(false);
          onError(`Recommendation Update Error ${(e as Error).message}`);
          return null;
        }
      } else if (analytic) {
        try {
          const body = {
            recommendation: recommendation,
            analytic_id: analytic.id,
            risk_level: risk_level,
            recommendation_set_id: recSet.id,
          };
          await requestCreateDefaultRecommendation(body);
          onSuccess('Recommendation Created');
          toggleIsSaving(false);
        } catch (e) {
          toggleIsSaving(false);
          onError(`Recommendation Creation Error ${(e as Error).message}`);
          return null;
        }
      }
    },
    [onSuccess, onError, toggleIsSaving, analytic, recSet],
  );

  const onSubmit = async () => {
    toggleIsSaving(true);
    await updateRecommendation(
      LOW_RISK,
      lowRecommendation?.recommendation || '',
      lowRecommendation?.id,
    );
    await updateRecommendation(
      MODERATE_RISK,
      medRecommendation?.recommendation || '',
      medRecommendation?.id,
    );
    await updateRecommendation(
      HIGH_RISK,
      highRecommendation?.recommendation || '',
      highRecommendation?.id,
    );
    if (recSet.id) {
      fetchRecSet(recSet.id);
    }
  };

  return (
    <>
      <h2>Default Recommendations</h2>
      {!recSet ? (
        <Container className={styles.MainAttributes}>
          <div>Save the Recommendation Set first before adding Custom Defaults!</div>
        </Container>
      ) : (
        <>
          <Selector
            className={styles.CategorySelector}
            options={analyticOptions}
            placeholder="Select a category"
            activeIndex={analyticOptions.findIndex((a) => a.id === analytic?.id)}
            onChange={(idx) => setAnalytic(analyticOptions[idx].value)}
          />
          <Container className={styles.TextFields}>
            <Label label={<span>Low Risk Recommendation</span>}>
              <Textarea
                onChange={(e) =>
                  setLowRecommendation({
                    ...lowRecommendation,
                    recommendation: e.target.value,
                  })
                }
                value={lowRecommendation?.recommendation}
              />
            </Label>
            <Label label={<span>Medium Risk Recommendation</span>}>
              <Textarea
                onChange={(e) =>
                  setMedRecommendation({
                    ...medRecommendation,
                    recommendation: e.target.value,
                  })
                }
                value={medRecommendation?.recommendation}
              />
            </Label>
            <Label label={<span>High Risk Recommendation</span>}>
              <Textarea
                onChange={(e) =>
                  setHighRecommendation({
                    ...highRecommendation,
                    recommendation: e.target.value,
                  })
                }
                value={highRecommendation?.recommendation}
              />
            </Label>
          </Container>
          <Button disabled={!analytic || isSaving} onClick={() => onSubmit()}>
            Update Recommendations
          </Button>
        </>
      )}
    </>
  );
};

export default EditRecommendationContainer;
