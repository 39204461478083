import React, { FunctionComponent, useCallback, useEffect, useRef, ReactNode } from 'react';

type ClickOutsideWrapperProps = {
  className?: string;
  onClickOutside: Function;
  'data-test-id'?: string;
  children?: ReactNode;
};

export const ClickOutsideWrapper: FunctionComponent<ClickOutsideWrapperProps> = ({
  children,
  className,
  onClickOutside,
  'data-test-id': testId,
}) => {
  const childRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (childRef.current && !childRef.current.contains(event.target)) {
        onClickOutside(event);
      }
    },
    [onClickOutside],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className={className} data-test-id={testId} ref={childRef}>
      {children}
    </div>
  );
};
