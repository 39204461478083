export const ZYMO = 'zymo';
export const SOIL = 'soil';
export const ZYMO_SOIL = 'zymoSoil';

export const PH_4 = 'PH 4';
export const PH_7 = 'PH 7';

// TODO: Return with NAPT controls from backend
export const phCalibrations = [{ control_code: PH_4 }, { control_code: PH_7 }];

export const naptElements = [
  'P',
  'B',
  'Fe',
  'Mg',
  'Mn',
  'Cu',
  'Ca',
  'Zn',
  'K',
  'S',
  'Na',
  'pH',
  'BpH',
  'N',
];
