import React from 'react';
import { bool, func } from 'prop-types';

import styles from './ButtonFooter.module.css';

const ButtonFooter = ({ hasFormChanged, reset, submit }) => (
  <div className={styles.Wrapper}>
    {hasFormChanged ? (
      <button onClick={reset} type="button">
        Cancel
      </button>
    ) : null}
    <button disabled={!hasFormChanged} onClick={submit} type="button">
      Submit
    </button>
  </div>
);

ButtonFooter.propTypes = {
  hasFormChanged: bool.isRequired,
  reset: func.isRequired,
  submit: func.isRequired,
};

export default ButtonFooter;
