import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select, SelectProps, Stack, Text } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { getExtractionMethodOptions } from 'util/plates';
import { PlateType } from 'store/plates/types';
import { US_EMERYVILLE } from 'constants/shipping';
import { defaultExtractionByLocation } from 'constants/extraction';

type ExtractionSelectorPropTypes = SelectProps & {
  plate: Partial<PlateType>;
  onValueChange: (
    attributeKey: keyof PlateType,
    newValue: string | string[] | { [key: string]: any },
  ) => void;
};

export const ExtractionSelector = ({
  plate,
  onValueChange,
  ...props
}: ExtractionSelectorPropTypes) => {
  const language = useBroswerLanguage();
  const extractionOptions = getExtractionMethodOptions(language);

  const { lab_location: labLocation } = useSelector((state: RootState) => ({
    lab_location: state.user.currentUser?.lab_location,
  }));

  const defaultExtraction = defaultExtractionByLocation[labLocation || US_EMERYVILLE];

  const handleOnChange = (value: string | null) => {
    if (value) {
      onValueChange('extraction_method', value);
    }
  };

  useEffect(() => {
    if (plate.extraction_method === undefined) {
      handleOnChange(defaultExtraction);
    }
  }, [plate, labLocation]);

  const handleRenderOption = ({ option }) => (
    <Stack gap="0">
      {option.label}
      <Text size="xs" c="dimmed">
        {(option as (typeof extractionOptions)[0]).description}
      </Text>
    </Stack>
  );

  return (
    <Select
      onChange={handleOnChange}
      data={extractionOptions}
      value={plate.extraction_method}
      label={getString('extractionMethod', language)}
      renderOption={handleRenderOption}
      {...props}
    />
  );
};
