import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Stack, Text, Title, Input, SimpleGrid, Button, Space } from '@mantine/core';
import { Header, Spinner } from 'common';
import formatPhoneNumber from 'util/formatPhone';
import { joinStrings } from 'util/stringUtils';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { submitAgencyDetails } from 'store/agencies/thunks';
import { useDispatch, useSelector } from 'react-redux';
import showToast, { type ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import styles from './ManageAgency.module.css';
import { Agency } from 'store/agencies/types';
import { LOCATION } from 'constants/agency';

type PropTypes = {
  agency: Agency;
};

const ManageAgency = ({ agency }: PropTypes) => {
  const language = useBroswerLanguage();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [agencyName, setAgencyName] = useState('');

  const { primary_user } = useSelector((state: RootState) => ({
    primary_user: state.agencies.byId[agency.id]?.primary_user,
  }));

  useEffect(() => {
    if (!agencyName && agency) {
      setAgencyName(agency.name);
    }
  }, [agency, agencyName]);

  const setToastMessage = (message: string, type?: ToastType, time?: number) =>
    showToast(message, type, time);

  const submitAgency = async () => {
    try {
      setSubmitting(true);
      if (agencyName && agency) {
        const newAgency = { name: agencyName };
        await dispatch(submitAgencyDetails(agency.id, newAgency));
        setSubmitting(false);
        setToastMessage(getString('updatingAgencySuccessMsg', language));
      }
    } catch (error) {
      setSubmitting(false);
      setToastMessage(getString('updatingAgencyErrorMsg', language), 'error', 7000);
    }
  };

  const parentOrAgency = () => {
    if (agency.hierarchy === LOCATION) {
      return getString('agency', language);
    }
    return getString('parentAgency', language);
  };

  const agencyMeta = [
    {
      title: getString('primaryAgent', language),
      value: joinStrings([primary_user?.first_name, primary_user?.last_name], ' '),
    },
    { title: getString('phone', language), value: formatPhoneNumber(primary_user.cell) },
    { title: getString('email', language), value: primary_user.email },
    {
      title: getString('billingAddress', language),
      value: primary_user?.billing_addresses?.[0]?.street,
    },
    {
      title: `${getString('city', language)} & ${getString('state', language)}`,
      value: joinStrings(
        [primary_user?.billing_addresses?.[0]?.city, primary_user?.billing_addresses?.[0]?.state],
        ', ',
      ),
    },
    { title: getString('zipcode', language), value: primary_user?.billing_addresses?.[0]?.zip },
  ];

  return (
    <>
      <Header
        title={
          <Stack gap="xs">
            <Title order={2}>
              {getString('manage', language)} {getString(agency.hierarchy, language)} -{' '}
              {agency.name}
            </Title>
            {agency.parent_agency?.id && (
              <Text>
                <b>{parentOrAgency()}:</b>{' '}
                <Link to={`/agencies/${agency.parent_agency.id}`}>
                  {agency.parent_agency?.name}
                </Link>
              </Text>
            )}
          </Stack>
        }
      >
        <Button disabled={submitting} onClick={submitAgency}>
          {submitting ? <Spinner className={styles.SmallSpinner} /> : getString('save', language)}
        </Button>
      </Header>
      {agency && (
        <>
          <div className={styles.FormControlContainer}>
            <Input.Wrapper label={getString('name', language)}>
              <Input onChange={(e) => setAgencyName(e.target.value)} value={agencyName} />
            </Input.Wrapper>
          </div>
          <SimpleGrid cols={3} verticalSpacing="xl">
            {agencyMeta.map((meta) => (
              <div className={styles.MetaWrapper} key={meta.title}>
                <div>
                  <Text size="sm" className={styles.MetaTitle}>
                    {meta.title}
                  </Text>
                  <Text className={styles.MetaDesc}>{meta.value}</Text>
                </div>
              </div>
            ))}
          </SimpleGrid>
          <Space h="xl" />
        </>
      )}
    </>
  );
};

export default ManageAgency;
