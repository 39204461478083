import React from 'react';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { SALES_FLOW_COLORS } from 'util/mapImageryColors';
import SlideStyles from '../Shared.module.css';
import Styles from './Container.module.css';
import ChartBarWrapper from './ChartBarWrapper';

const BenefitsContainer = () => {
  const language = useBroswerLanguage();

  const headerString = (() => {
    const str = `${getString('theTypicalMidwestFarm', language)} $60+ per acre`;
    return (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: str,
          }}
        />
      </div>
    );
  })();

  const chartData1 = [
    {
      name: 'Base Case',
      value: 1005,
    },
    {
      name: 'Pattern 360',
      value: 1055,
    },
  ];

  const chartData2 = [
    {
      name: 'Base Case',
      value: 38,
    },
    {
      name: 'Pattern 360',
      value: 28,
    },
  ];

  return (
    <div>
      <div className={SlideStyles.Header}>{headerString}</div>
      <div className={SlideStyles.Images}>
        <ChartBarWrapper
          barColor={SALES_FLOW_COLORS.GREEN}
          chartData={chartData1}
          blurbParagraph={getString('applyRecommendedInputs', language)}
          barText={getString('boostYield', language)}
          increaseText="$50 / ac"
          legendText={`${getString('revenue', language)} / Ac`}
          domainRange={[990, 1075]}
        />
        <div className={Styles.DottedLine} />
        <ChartBarWrapper
          barColor={SALES_FLOW_COLORS.RED}
          chartData={chartData2}
          blurbParagraph={getString('optimizeInputs', language)}
          barText={getString('optimizeSpend', language)}
          increaseText="$10 / ac"
          legendText={`${getString('cropProtectionCosts', language)} / Ac`}
          domainRange={[20, 45]}
        />
      </div>
    </div>
  );
};

export default BenefitsContainer;
