import { FieldType, SamplingPlanType } from 'store/fields/types';
import { sampleIsActive, sampleIsPrimary } from './sample';
import { haToAcres } from './units';

export const getFieldName = (fieldGeometry: FieldType, samplingPlan: SamplingPlanType) => {
  const { acreage, name } = fieldGeometry.features[0].properties;

  const acreageString = `${Math.round(acreage)} Acres`;

  if (!samplingPlan) {
    return `${name} (${acreageString})`;
  }

  const { samples } = samplingPlan;
  const primarySamples = samples.filter(sampleIsActive).filter(sampleIsPrimary);
  const samplesString =
    primarySamples && `${primarySamples.length} Sample${primarySamples.length !== 1 ? 's' : ''}`;

  return `${name} (${acreageString} / ${samplesString})`;
};

export const getFieldAcresDisplay = (fieldGeometry: FieldType) => {
  const { acreage, acreage_unit } = fieldGeometry.features[0].properties;
  const convertedAcres = haToAcres(acreage, acreage_unit);
  return `${convertedAcres.toFixed(0)} ${acreage_unit}`;
};

export const hasGeom = (field: FieldType) => {
  return field.features[0]?.geometry !== null;
};

export default { getFieldName };
