import React, { useMemo } from 'react';

import { Table, TableCol, TableRow } from 'common';
import { FieldType } from 'store/fields/types';
import { FieldEventType } from 'store/fieldEvents/types';
import { OperationType } from 'store/operation/types';

import styles from './FileEditor.module.css';
import FieldEditorRow from './FieldEditorRow';

const DOWN_ARROW = '\u2193';

type FieldEditorPropsType = {
  fieldEvents: FieldEventType[];
  fieldGeometries: { [id: number]: FieldType };
  idsToExclude: number[];
  operation: OperationType;
  setIdsToExclude: (ids: number[]) => void;
  updateEvent: (event: FieldEventType, newFieldId: number) => void;
};

const FieldEditor = ({
  fieldEvents,
  fieldGeometries,
  idsToExclude,
  operation,
  setIdsToExclude,
  updateEvent,
}: FieldEditorPropsType) => {
  const groupedEvents = useMemo(() => {
    const newFields = fieldEvents.filter((event) =>
      operation.inactive_fields.some((field) => field.id === event.field_id),
    );
    const existingFields = fieldEvents.filter((event) =>
      operation.fields.some((field) => field.id === event.field_id),
    );

    return {
      existingFields,
      newFields,
    };
  }, [fieldEvents, operation]);

  const makeIdExcluder = (id: number) => () => setIdsToExclude(idsToExclude.concat(id));
  const makeIdIncluder = (id: number) => () =>
    setIdsToExclude(idsToExclude.filter((i) => i !== id));

  return (
    <div>
      <h2 className={styles.Title}>Existing Fields</h2>
      <Table>
        <TableRow className={styles.Header}>
          <TableCol size="x2">{`Field ${DOWN_ARROW}`}</TableCol>
          <TableCol size="x2">{`Filename ${DOWN_ARROW}`}</TableCol>
          <TableCol>{`Filetype ${DOWN_ARROW}`}</TableCol>
          <TableCol>{`Crop Year ${DOWN_ARROW}`}</TableCol>
          <TableCol size="x2" />
        </TableRow>
        {groupedEvents.existingFields.map((event) => (
          <FieldEditorRow
            key={event.id}
            event={event}
            operation={operation}
            fieldGeometries={fieldGeometries}
            excludedIds={idsToExclude}
            onInclude={makeIdIncluder(event.id)}
            onExclude={makeIdExcluder(event.id)}
            updateEvent={updateEvent}
          />
        ))}
      </Table>
      <h2 className={styles.Title}>Newly Created Fields</h2>
      <Table>
        <TableRow className={styles.Header}>
          <TableCol size="x2">{`Field ${DOWN_ARROW}`}</TableCol>
          <TableCol size="x2">{`Filename ${DOWN_ARROW}`}</TableCol>
          <TableCol>{`Filetype ${DOWN_ARROW}`}</TableCol>
          <TableCol>{`Crop Year ${DOWN_ARROW}`}</TableCol>
          <TableCol size="x2" />
        </TableRow>
        {groupedEvents.newFields.map((event) => (
          <FieldEditorRow
            key={event.id}
            event={event}
            operation={operation}
            fieldGeometries={fieldGeometries}
            excludedIds={idsToExclude}
            onInclude={makeIdIncluder(event.id)}
            onExclude={makeIdExcluder(event.id)}
            updateEvent={updateEvent}
          />
        ))}
      </Table>
    </div>
  );
};

export default FieldEditor;
