import React from 'react';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { Stack, Table, Text, Title } from '@mantine/core';
import { getString } from 'strings/translation';
import { formatToNumericDate } from 'util/date';
import { THREE_EM_DASH } from 'constants/defaultValues';
import { ChemistryResultType } from 'store/chemistryResults/types';
import { getAnalysisShortName } from 'util/analysis';
import { AnalyticType } from 'store/analytics/types';
import { roundTwoDecimal } from 'util/numUtils';

type ChemistryResultsPropsType = {
  analytics: AnalyticType[];
  chemistryResults: ChemistryResultType[];
  isFetching: boolean;
};

export const ChemistryResults = ({
  analytics,
  chemistryResults,
  isFetching,
}: ChemistryResultsPropsType) => {
  const language = useBroswerLanguage();
  return (
    <Stack gap="0">
      <Title size="1.5rem" fw={500}>
        {getString('chemistryResults', language)}
      </Title>
      {!chemistryResults.length && !isFetching ? (
        <Text mt="sm">{getString('noChemistryResultsMsg', language)}</Text>
      ) : (
        <Table highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{getString('source', language)}</Table.Th>
              <Table.Th>{getString('created', language)}</Table.Th>
              <Table.Th>{getString('method', language)}</Table.Th>
              <Table.Th>{getString('analytic', language)}</Table.Th>
              <Table.Th>{getString('value', language)}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {chemistryResults?.map((result) => (
              <Table.Tr key={result.id}>
                <Table.Td>
                  <Text span size="sm">
                    {result.file_name || THREE_EM_DASH}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text span size="sm">
                    {result.created_at ? formatToNumericDate(result.created_at) : THREE_EM_DASH}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text span size="sm">
                    {result.method ? getAnalysisShortName(result.method, language) : THREE_EM_DASH}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text span size="sm">
                    {analytics.find((analytic) => analytic.id === result.analytic_id)?.name ||
                      THREE_EM_DASH}
                  </Text>
                </Table.Td>
                <Table.Td>
                  <Text span size="sm">
                    {roundTwoDecimal(result.value)}
                  </Text>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
};
