import React, { useState } from 'react';
import { CarouselSelector } from 'common';
import Styles from './Container.module.css';
import MapPressure from './Slides/MapPressure';
import BoostYield from './Slides/BoostYield';
import PredictRisk from './Slides/PredictRisk';
import FieldOverview from './Slides/FieldOverview';

const OverviewContainer = () => {
  const [selectedCarouselIndex, setSelectedCarouselIndex] = useState(0);

  const selectedSlide = (() => {
    switch (selectedCarouselIndex) {
      case 1:
        return <MapPressure />;
      case 2:
        return <PredictRisk />;
      case 3:
        return <FieldOverview />;
      default:
        return <BoostYield />;
    }
  })();

  return (
    <>
      <div className={Styles.Wrapper}>{selectedSlide}</div>
      <div className={Styles.CarouselSelector}>
        <CarouselSelector
          setSelectedIndex={setSelectedCarouselIndex}
          indexes={[0, 1, 2, 3]}
          selectedIndex={selectedCarouselIndex}
        />
      </div>
    </>
  );
};

export default OverviewContainer;
