import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  SERVICE_URL,
} from 'util/request';
import { CollectionConfirmationPayloadType, CollectionsResponseType } from './types';

export const getCollections = async (page = 1): Promise<CollectionsResponseType> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/eo_collections/?page=${page}`));

export const deleteCollection = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/eo_collection/${id}`));

export const requestConfirmCollections = async (body: CollectionConfirmationPayloadType) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/eo_confirm_collections_assignment/`, { body }),
  );
