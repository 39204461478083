import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import classNames from 'classnames';
import { TableCol, TableRow } from 'common';
import { PlateType } from 'store/plates/types';
import { hasConnectedPlatesStatuses } from 'constants/plates';
import { getAnalysisShortName } from 'util/analysis';
import {
  getPlateDisplayName,
  getPlateStatusDisplayName,
  getWellCountByPlateType,
} from 'util/plates';
import { formatToDateHourFormat, formatToNumericDate } from 'util/date';

import { PlatesRowExpanded } from './PlateRowExtended';
import styles from './Container.module.css';

type PlatesRowPropsType = {
  plate: PlateType;
  hideTypeColumn?: boolean;
};

export const PlatesRow = ({ plate, hideTypeColumn }: PlatesRowPropsType) => {
  const language = useBroswerLanguage();
  const [showDetails, toggleShowDetails] = useState(false);

  const expandable = hasConnectedPlatesStatuses.includes(plate.status);
  const labId = plate.homogenization_plate_id;
  return (
    <>
      <TableRow
        className={classNames({
          [showDetails ? styles.ClickedRow : styles.UnclickedRow]: expandable,
        })}
        onClick={() => toggleShowDetails(!showDetails)}
      >
        <TableCol size="x2">
          <Link className={styles.Link} to={`/lab/plates/details/${plate.barcode}`}>
            {`${labId ? `P${labId} / ` : ''}${plate.barcode}`}
          </Link>
        </TableCol>
        {!hideTypeColumn && (
          <TableCol size="x2" className={plate.is_regulated ? styles.Regulated : undefined}>
            <div>{getPlateDisplayName(plate.plate_type, language)}</div>
            {plate.is_regulated && <div>{`(${getString('regulated', language)})`}</div>}
          </TableCol>
        )}
        <TableCol size="x2">
          {plate.analysis_type ? getAnalysisShortName(plate.analysis_type, language) : ''}
        </TableCol>
        <TableCol size="x2">{getPlateStatusDisplayName(plate.status, language)}</TableCol>
        <TableCol>{`${plate.well_count} / ${getWellCountByPlateType(plate.plate_type)}`}</TableCol>
        <TableCol>
          {plate.batch_id ? (
            <Link className={styles.Link} to={`/lab/batches/details/${plate.batch_id}`}>
              {plate.batch_id}
            </Link>
          ) : null}
        </TableCol>
        <TableCol size="x2">
          {plate.samples_arrived_at && formatToNumericDate(plate.samples_arrived_at)}
        </TableCol>
        <TableCol size={hideTypeColumn ? 'x4' : 'x2'}>{plate.notes}</TableCol>
        <TableCol size="x2">{formatToDateHourFormat(plate.created_at)}</TableCol>
      </TableRow>
      {expandable && showDetails && <PlatesRowExpanded plate={plate} />}
    </>
  );
};
