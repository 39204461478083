import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Selector, Spinner } from 'common';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { getOperation, setActiveOperation } from 'store/operation/thunks';
import { getSortedAndActivePlans, getPlanName } from 'util/samplePlan';

import { FieldLabSamplesChart } from './FieldLabSamplesChart';
import styles from './Container.module.css';

type SampleStatusParams = {
  operationId: string;
  fieldId: string;
  planId: string;
};

const SampleStatusContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operationId, fieldId, planId } = useParams<SampleStatusParams>();
  const numericOperationId = Number(operationId);
  const numericFieldId = Number(fieldId);
  const { operation, fieldGeometry, fieldHasFetched, fieldIsFetching } = useSelector(
    (state: RootState) => ({
      operation: state.operations.operationsById[numericOperationId],
      fieldGeometry: state.fieldGeometry.geometries[numericFieldId],
      fieldHasFetched: [
        ...state.fieldGeometry.hasFetchedList,
        ...state.fieldGeometry.hasFailedList,
      ].includes(numericFieldId),
      fieldIsFetching: [...state.fieldGeometry.isFetchingList].includes(numericFieldId),
    }),
  );

  useEffect(() => {
    if (!operation) {
      dispatch(getOperation(numericOperationId));
    }
  }, [dispatch, numericOperationId, operation]);

  useEffect(() => {
    dispatch(getFieldGeometry(numericFieldId, true));
  }, [fieldId, dispatch, numericFieldId]);

  useEffect(() => {
    if (operation) {
      dispatch(setActiveOperation(operation.id));
    }
  }, [dispatch, operation]);

  const sortedPlans = useMemo(() => {
    if (fieldGeometry?.features[0].properties) {
      return getSortedAndActivePlans(fieldGeometry.features[0].properties);
    }
    return [];
  }, [fieldGeometry]);

  const selectedPlan = useMemo(
    () => sortedPlans.find((plan) => plan.id === Number(planId)),
    [sortedPlans, planId],
  );

  const samplePlanOptions = useMemo(
    () =>
      sortedPlans.map((plan, idx) => ({
        id: idx,
        displayName: getPlanName(plan),
        value: plan.id,
      })),
    [sortedPlans],
  );

  const optionIndex = samplePlanOptions.findIndex((option) => String(option.value) === planId);

  const showAnotherPlan = useCallback(
    (optionId: number) => {
      const nextPlanId = samplePlanOptions[optionId].value;
      navigate(`/view-sample-status/${operationId}/${fieldId}/${nextPlanId}`);
    },
    [navigate, samplePlanOptions, operationId, fieldId],
  );

  useEffect(() => {
    if (samplePlanOptions.length && !planId) {
      showAnotherPlan(0);
    }
  }, [samplePlanOptions, showAnotherPlan]);

  if (!operation || !fieldGeometry?.features[0].properties || !selectedPlan) {
    return <Spinner fill />;
  }

  return (
    <div className={styles.Wrapper}>
      <Header title={`${operation.name} - ${fieldGeometry.features[0].properties.name}`}>
        <div>
          {samplePlanOptions.length > 0 && (
            <Selector
              activeIndex={optionIndex}
              onChange={showAnotherPlan}
              options={samplePlanOptions}
            />
          )}
        </div>
      </Header>
      <div>
        {fieldIsFetching ? (
          <Spinner />
        ) : (
          <FieldLabSamplesChart
            field={fieldGeometry}
            samplingPlan={selectedPlan}
            hasFetched={fieldHasFetched}
          />
        )}
      </div>
    </div>
  );
};

export default SampleStatusContainer;
