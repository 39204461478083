export const HOMOGENIZATION = 'homogenization';
export const DNA_EXTRACTION = 'dna_extraction';
export const NORMALIZED_DNA = 'normalized_dna';
export const AMPLICON_QPCR = 'amplicon_qpcr';
export const AMPLICON_QPCR_96 = 'amplicon_qpcr_96';
export const AMPLICON_QPCR_384 = 'amplicon_qpcr_384';
export const QPCR = 'qpcr';
export const QPCR_96 = 'qpcr_96';
export const QPCR_384 = 'qpcr_384';
export const AMPLICON = 'amplicon';
export const SHOTGUN = 'shotgun';
export const M3_ANALYSIS = 'm3_analysis';
export const PH_ANALYSIS = 'ph_analysis';
export const NITRATE_ANALYSIS = 'nitrate_analysis';
export const AA_ANALYSIS = 'aa_analysis';
export const OM_ANALYSIS = 'om_analysis';
export const PHOSPHATE_ANALYSIS = 'phosphate_analysis';
export const CHEMISTRY_BACKUP = 'chemistry_backup';
export const CHEMISTRY = 'chemistry';

export const plateTypesList = [
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  AMPLICON_QPCR,
  SHOTGUN,
  M3_ANALYSIS,
  PH_ANALYSIS,
  NITRATE_ANALYSIS,
  AA_ANALYSIS,
  OM_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
];
export const searchPlateTypes = [
  SHOTGUN,
  QPCR,
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  M3_ANALYSIS,
  PH_ANALYSIS,
  NITRATE_ANALYSIS,
  AA_ANALYSIS,
  OM_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
  AMPLICON_QPCR,
];
export const creationPlateTypes = [
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  SHOTGUN,
  QPCR_384,
  M3_ANALYSIS,
  PH_ANALYSIS,
  NITRATE_ANALYSIS,
  AA_ANALYSIS,
  OM_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
];
export const genomicPlateTypes = [
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  AMPLICON_QPCR,
  SHOTGUN,
];

export const mergeablePlateTypes = [HOMOGENIZATION, DNA_EXTRACTION, NORMALIZED_DNA, SHOTGUN];

export const chemistryPlateTypes = [
  M3_ANALYSIS,
  PH_ANALYSIS,
  NITRATE_ANALYSIS,
  AA_ANALYSIS,
  OM_ANALYSIS,
  PHOSPHATE_ANALYSIS,
  CHEMISTRY_BACKUP,
];

export const summaryPlateTypes = [HOMOGENIZATION];

export const plateTypeDisplayNames = {
  [HOMOGENIZATION as string]: 'homogenization',
  [DNA_EXTRACTION as string]: 'dnaExtraction',
  [NORMALIZED_DNA as string]: 'normalizedDna',
  [SHOTGUN as string]: 'shotgun',
  [QPCR as string]: 'qPCR',
  [QPCR_96 as string]: 'qPCR 96',
  [QPCR_384 as string]: 'qPCR 384',
  [M3_ANALYSIS as string]: 'm3Analysis',
  [PH_ANALYSIS as string]: 'phAnalysis',
  [NITRATE_ANALYSIS as string]: 'nitrateAnalysis',
  [AA_ANALYSIS as string]: 'aaAnalysis',
  [OM_ANALYSIS as string]: 'omAnalysis',
  [PHOSPHATE_ANALYSIS as string]: 'phosphateAnalysis',
  [CHEMISTRY_BACKUP as string]: 'chemistryBackup',
  // Deprecated
  [AMPLICON_QPCR as string]: 'amplicon',
};

export const plateTypeShortNames = {
  [HOMOGENIZATION as string]: 'homogen',
  [DNA_EXTRACTION as string]: 'dnaExtr',
  [NORMALIZED_DNA as string]: 'normDna',
  [SHOTGUN as string]: 'shotgun',
  [QPCR as string]: 'qPCR',
  [M3_ANALYSIS as string]: 'M3',
  [PH_ANALYSIS as string]: 'pH',
  [NITRATE_ANALYSIS as string]: 'nitrate',
  [OM_ANALYSIS as string]: 'OM',
  [AA_ANALYSIS as string]: 'AA',
  [PHOSPHATE_ANALYSIS as string]: 'P',
  [CHEMISTRY_BACKUP as string]: 'chemistryBackup',
};

export const nextPlateTypeMap = {
  [HOMOGENIZATION as string]: DNA_EXTRACTION,
  [DNA_EXTRACTION as string]: NORMALIZED_DNA,
  [NORMALIZED_DNA as string]: QPCR_384,
  [SHOTGUN as string]: SHOTGUN,
  [QPCR as string]: QPCR_384,
  [M3_ANALYSIS as string]: M3_ANALYSIS,
  [PH_ANALYSIS as string]: PH_ANALYSIS,
  [NITRATE_ANALYSIS as string]: NITRATE_ANALYSIS,
  [AA_ANALYSIS as string]: AA_ANALYSIS,
  [OM_ANALYSIS as string]: OM_ANALYSIS,
  [PHOSPHATE_ANALYSIS as string]: PHOSPHATE_ANALYSIS,
  [CHEMISTRY_BACKUP as string]: CHEMISTRY_BACKUP,
  // Deprecated
  [AMPLICON_QPCR as string]: AMPLICON_QPCR_384,
};

export const prevPlateTypeMap = {
  [HOMOGENIZATION as string]: HOMOGENIZATION,
  [DNA_EXTRACTION as string]: HOMOGENIZATION,
  [NORMALIZED_DNA as string]: DNA_EXTRACTION,
  [SHOTGUN as string]: NORMALIZED_DNA,
  [QPCR as string]: NORMALIZED_DNA,
  [M3_ANALYSIS as string]: M3_ANALYSIS,
  [PH_ANALYSIS as string]: PH_ANALYSIS,
  [NITRATE_ANALYSIS as string]: NITRATE_ANALYSIS,
  [AA_ANALYSIS as string]: AA_ANALYSIS,
  [OM_ANALYSIS as string]: OM_ANALYSIS,
  [PHOSPHATE_ANALYSIS as string]: PHOSPHATE_ANALYSIS,
  [CHEMISTRY_BACKUP as string]: CHEMISTRY_BACKUP,
  // Deprecated
  [AMPLICON_QPCR as string]: NORMALIZED_DNA,
};

export const plateFormRequiredAttributes = {
  [HOMOGENIZATION as string]: ['plate_type', 'barcode', 'analysis_type'],
  [DNA_EXTRACTION as string]: [
    'plate_type',
    'barcode',
    'source_plate_barcode',
    'extraction_method',
  ],
  [NORMALIZED_DNA as string]: ['plate_type', 'barcode', 'source_plate_barcode'],
  [SHOTGUN as string]: [
    'plate_type',
    'barcode',
    'quadrant1_plate_barcode',
    'quadrant1_index_set_name',
  ],
  [QPCR_384 as string]: [
    'plate_type',
    'barcode',
    'quadrant1_plate_barcode',
    'quadrant1_qpcr_target',
  ],
  [QPCR_96 as string]: [
    'plate_type',
    'barcode',
    'quadrant1_plate_barcode',
    'quadrant1_qpcr_target',
  ],
  [M3_ANALYSIS as string]: ['plate_type', 'barcode'],
  [PH_ANALYSIS as string]: ['plate_type', 'barcode'],
  [NITRATE_ANALYSIS as string]: ['plate_type', 'barcode'],
  [AA_ANALYSIS as string]: ['plate_type', 'barcode'],
  [OM_ANALYSIS as string]: ['plate_type', 'barcode'],
  [PHOSPHATE_ANALYSIS as string]: ['plate_type', 'barcode'],
  [CHEMISTRY_BACKUP as string]: ['plate_type', 'barcode'],
};

export const PROCESSING = 'processing';
export const STORAGE = 'storage';
export const DISCARDED = 'discarded';
export const EMPTY = 'empty';
export const PARTIAL = 'partial';
export const SHOTGUN_QPCR = 'shotgun_qpcr';

export const plateStatusList = [
  PROCESSING,
  EMPTY,
  PARTIAL,
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  SHOTGUN,
  SHOTGUN_QPCR,
  STORAGE,
  DISCARDED,
];
export const processingPlateStatuses = [
  PROCESSING,
  EMPTY,
  PARTIAL,
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  SHOTGUN,
  SHOTGUN_QPCR,
];
export const homogenStatusList = [
  EMPTY,
  PARTIAL,
  HOMOGENIZATION,
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  SHOTGUN,
  SHOTGUN_QPCR,
];
export const hasConnectedPlatesStatuses = [
  DNA_EXTRACTION,
  NORMALIZED_DNA,
  QPCR,
  SHOTGUN,
  SHOTGUN_QPCR,
];
export const basicPlateStatusList = [PROCESSING, STORAGE, DISCARDED];

export const plateStatusDisplayNames = {
  [PROCESSING as string]: 'processing',
  [STORAGE as string]: 'storage',
  [DISCARDED as string]: 'discarded',
  [EMPTY as string]: 'empty',
  [PARTIAL as string]: 'partiallyFilled',
  [HOMOGENIZATION as string]: 'homogenizationComplete',
  [DNA_EXTRACTION as string]: 'dnaExtraction',
  [NORMALIZED_DNA as string]: 'normalizedDna',
  [QPCR as string]: 'qPCR',
  [SHOTGUN as string]: 'shotgun',
  [SHOTGUN_QPCR as string]: 'shotgunQpcr',
};

export const SCN = 'scn';
export const WCRW = 'wcrw';
export const NCRW = 'ncrw';
export const targetList = [SCN, WCRW, NCRW];
