export const US_MIDWEST_VIEWPORT = {
  latitude: 38.947150884024914,
  longitude: -92.19310729903594,
  zoom: 5.5,
};

export const BRAZIL_VIEWPORT = {
  latitude: -15.793889,
  longitude: -47.882778,
  zoom: 5.5,
};
