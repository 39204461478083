import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@mantine/core';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { ContractType } from 'store/agencies/types';
import { fillMissingDensities, getContractTypeOptions, setupDefaultContracts } from 'util/pricing';
import { contractDensities, CONTRACT_TYPES } from 'constants/products';
import { getSubContractTiers } from 'store/agencies/thunks';

type ContractTypeSelectPropsType = {
  contract: Partial<ContractType>;
  setContractForm: (contract: Partial<ContractType>) => void;
  agencyId: number;
};

export const ContractTypeSelect = ({
  contract,
  setContractForm,
  agencyId,
}: ContractTypeSelectPropsType) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();

  const { default_prices, tiersForAgency } = useSelector((state: RootState) => ({
    default_prices: state.agencies.default_prices,
    tiersForAgency: state.agencies.contractTiers[agencyId],
  }));

  const contractTypes =
    tiersForAgency && Object.keys(tiersForAgency).length
      ? Object.keys(tiersForAgency)
      : CONTRACT_TYPES;
  const defaultContractTiers = setupDefaultContracts({ ...default_prices, ...tiersForAgency });

  const contractTypeOptions = getContractTypeOptions(language, contractTypes);

  const handleContractTypeChange = (value: string | null) => {
    if (value) {
      setContractForm({
        ...contract,
        ...defaultContractTiers[value],
        contract_type: value,
      });
    }
  };

  useEffect(() => {
    if (!tiersForAgency) {
      dispatch(getSubContractTiers(agencyId));
    } else if (defaultContractTiers && !contract.contract_type) {
      setContractForm(defaultContractTiers[contractTypes[0]]);
    } else if (
      defaultContractTiers &&
      contract.contract_type &&
      contract.price_buckets &&
      Object.keys(contract.price_buckets).length < contractDensities.length
    ) {
      const contractType = contractTypes.includes(contract.contract_type)
        ? contract.contract_type
        : contractTypes[0];
      const withMissingDensities = fillMissingDensities(
        contract as ContractType,
        defaultContractTiers[contractType].price_buckets,
      );
      setContractForm(withMissingDensities);
    }
  }, [defaultContractTiers, contractTypes, contract, tiersForAgency, agencyId]);

  return (
    <Select
      label={getString('contractType', language)}
      onChange={handleContractTypeChange}
      value={contract.contract_type}
      data={contractTypeOptions}
      disabled={contract?.is_not_editable || !defaultContractTiers || !tiersForAgency}
    />
  );
};
