import React, { useEffect, useRef, useState } from 'react';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';
import turfBbox from '@turf/bbox';

import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { AnalyticType, SingleAnalyticType } from 'store/analytics/types';
import { FieldType, MapboxSample, SamplingPlanType } from 'store/fields/types';
import {
  adjustSamplesForInterpolation,
  getAnalyticFillColor,
  getFieldRisk,
  getSampleAnalyticQuantity,
  setupNonTillageProLayer,
} from 'util/results';
import { Text, Box, Group } from '@mantine/core';
import { getRiskColorFill } from 'util/chartUtils';
import { WHITE } from 'util/mapImageryColors';
import useMapboxGl from 'common/MapHooks';
import { NUTRIENT_PANEL } from 'constants/products';
import Popup from 'common/Maps/Popup';
import { getNonTillageProMapPaintFill, setNonTillageProHoverHandlers } from 'util/proMaps';
import { getProNutrientPopupSettings } from 'apps/Results/common/MapPopup';

import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './MapThumbnail.module.css';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FIELD_OUTLINE } from 'constants/mapbox';
import { PopupState } from 'common/Maps/types';

interface MapThumbnailProps {
  activeAnalytic: AnalyticType;
  field: FieldType;
  planAnalytic: SingleAnalyticType;
  proLayer: EOInferenceLayerType | null | undefined;
  samples: MapboxSample[];
  samplingPlan: SamplingPlanType;
}

const MapThumbnail = ({
  activeAnalytic,
  field,
  planAnalytic,
  proLayer,
  samples,
  samplingPlan,
}: MapThumbnailProps) => {
  const language = useBroswerLanguage();
  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];

  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const shouldShowProMap = proLayer && samplingPlan.pro_densities.length;

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, false);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  const risk = getFieldRisk(planAnalytic, samplingPlan, activeAnalytic);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;
      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
      }
      if (map.getSource(FIELD_OUTLINE)) {
        map.removeSource(FIELD_OUTLINE);
      }

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source,
        paint: { 'line-color': WHITE, 'line-width': 2 },
      });

      map.fitBounds(bbox, {
        duration: 0,
        padding: 30,
      });
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      if (shouldShowProMap && planAnalytic.data_summary) {
        const style = map.getStyle();
        const proPrefix = 'pro-results';
        const sourceLayerId = `${proPrefix}-${activeAnalytic.id}`;

        /*
          To support switching between analytics,
          we need to remove pro result layers before adding new ones
        */
        style.layers.forEach((layer) => {
          if (layer.id.startsWith(proPrefix)) {
            map.removeLayer(layer.id);
          }
        });
        Object.keys(style.sources).forEach((source) => {
          if (source.startsWith(proPrefix)) {
            map.removeSource(source);
          }
        });
        const fillColor = getNonTillageProMapPaintFill(
          activeAnalytic,
          planAnalytic.data_summary,
          proLayer,
        );
        const popupSettings = getProNutrientPopupSettings(
          activeAnalytic.id,
          proLayer,
          planAnalytic.unit,
        );
        setNonTillageProHoverHandlers(map, sourceLayerId);
        setupNonTillageProLayer(
          map,
          sourceLayerId,
          fillColor,
          proLayer.geojson_uri,
          (info) => setPopupInfo(info),
          popupSettings,
        );
      } else {
        const mapId = 'samples';
        if (map.getLayer(mapId)) {
          map.removeLayer(mapId);
          map.removeSource(mapId);
          map.removeLayer(`${mapId}-quantity`);
          map.removeSource(`${mapId}-quantity`);
        }
        const validSamples = samples.filter(
          (sample) =>
            sample.geometry !== null && sample.properties.products.includes(NUTRIENT_PANEL),
        );
        const mappedFeatures = validSamples.map((val) => ({
          ...val,
          properties: {
            ...val.properties,
            'fill-color': getAnalyticFillColor(val.properties, activeAnalytic, samplingPlan),
            quantity: getSampleAnalyticQuantity(val.properties, activeAnalytic),
          },
        }));
        const newFeatureCollection = featureCollection(mappedFeatures);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;
        // adjust quantity values to show asterisks if a specific processing is not available
        const analysisAdjustedSamples = adjustSamplesForInterpolation(
          // @ts-expect-error
          mappedFeatures,
          activeAnalytic,
          samplingPlan,
        );
        const adjustedSource = {
          type: 'geojson',
          data: featureCollection(analysisAdjustedSamples),
        };
        if (!map.getLayer(mapId)) {
          if (validSamples.length) {
            map.addLayer({
              id: mapId,
              type: 'fill',
              source,
              paint: {
                'fill-color': ['get', 'fill-color'],
                'fill-outline-color': WHITE,
              },
            });
            map.addLayer({
              id: `${mapId}-quantity`,
              type: 'symbol',
              // @ts-expect-error
              source: adjustedSource,
              layout: {
                'text-field': ['get', 'quantity'],
                'text-justify': 'center',
                'text-size': 12,
              },
            });
          }
        }
      }
      map.getStyle();
    }
  }, [mapHasLoaded, activeAnalytic, samplingPlan]);

  return (
    <Box className={styles.FieldMap}>
      <Group
        gap="sm"
        style={{
          paddingLeft: '0.5rem',
          backgroundColor: getRiskColorFill(planAnalytic.risk_level),
        }}
      >
        <Text fw={700}>{activeAnalytic.name}</Text>
        <Text>
          <b>{getString('risk', language)}:</b> {getString(risk, language)}
        </Text>
      </Group>
      <div ref={mapContainerRef} className={styles.MapWrapper} />
      {popupInfo && mapRef.current && (
        <Popup
          {...popupInfo}
          map={mapRef.current}
          anchor="bottom"
          onClose={() => setPopupInfo(null)}
        >
          {popupInfo.content}
        </Popup>
      )}
    </Box>
  );
};

export default MapThumbnail;
