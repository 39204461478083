import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'common';
import { Modal, Input, Group, Select, Space, Button } from '@mantine/core';
import { getString } from 'strings/translation';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { STATE_OPTIONS } from 'constants/states';
import { requestCreateShippingAddress } from 'store/shipping/requests';
import { getAllShippingAddresses } from 'store/shipping/thunks';
import { PartialShippingAddress } from 'store/shipping/types';
import showToast from 'actions/toastActions';
import styles from './Container.module.css';

interface Props {
  open: boolean;
  hideModal: () => void;
}

const ShippingAddressModal = ({ open, hideModal }: Props) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState<PartialShippingAddress>({
    street: '',
    city: '',
    state: '',
    zip: '',
  });

  const createIsDisabled = (() =>
    isLoading || !address.street || !address.city || !address.state || address.zip?.length !== 5)();

  const changeAddressValue = (addressKey: string, value: string) => {
    setErrorMessage('');
    setAddress({ ...address, [addressKey]: value });
  };

  const addShippingAddress = async () => {
    try {
      if (!createIsDisabled && address) {
        setIsLoading(true);
        await requestCreateShippingAddress(address);
        showToast(getString('addressCreatedMsg', language));
        dispatch(getAllShippingAddresses());
        hideModal();
        setIsLoading(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      size="xl"
      opened={open}
      onClose={hideModal}
      centered
      title={getString('createShippingAddress', language)}
    >
      <div className={styles.ModalWrapper}>
        <Group>
          <Input.Wrapper label={getString('street', language)}>
            <Input
              className={styles.Street}
              data-test-id="street"
              value={address?.street}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeAddressValue('street', e.target.value)
              }
              placeholder="123 Sampler Way"
            />
          </Input.Wrapper>
        </Group>
        <Space h="md" />
        <Group>
          <Input.Wrapper label={getString('city', language)}>
            <Input
              data-test-id="city"
              value={address?.city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeAddressValue('city', e.target.value)
              }
              className={styles.City}
              placeholder="Sampler City"
            />
          </Input.Wrapper>
          <Select
            className={styles.Zip}
            data-test-id="state"
            label={getString('state', language)}
            onChange={(val) => val && changeAddressValue('state', val)}
            value={address?.state}
            data={STATE_OPTIONS}
          />
          <Input.Wrapper label={getString('zipcode', language)} className={styles.Zip}>
            <Input
              data-test-id="zipcode"
              value={address?.zip}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeAddressValue('zip', e.target.value)
              }
              placeholder="00000"
            />
          </Input.Wrapper>
        </Group>
        <Group className={styles.ErrorMessage}>
          <p>{errorMessage}</p>
        </Group>
        <Group justify="flex-end">
          {isLoading && <Spinner className={styles.SmallSpinner} />}
          <Button
            data-test-id="create"
            className={styles.MarginRight}
            disabled={createIsDisabled}
            onClick={addShippingAddress}
          >
            {getString('create', language)}
          </Button>
          <Button className={styles.MarginRight} color="darkRed" onClick={hideModal}>
            {getString('cancel', language)}
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default ShippingAddressModal;
