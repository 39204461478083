import React from 'react';
import useSampleTiming from 'util/hooks/useSampleTiming';
import { Loader, Center } from '@mantine/core';
import useFieldGeometry from 'util/hooks/useFieldGeometry';
import useOperation from 'util/hooks/useOperation';
import ZoneAnalysis from './ZoneAnalysis';
import useOperationUsers from 'util/hooks/useOperationUsers';

const ZoneAnalysisContainerV2 = () => {
  const fieldGeometry = useFieldGeometry();
  const { operation } = useOperation();
  useSampleTiming();
  const { operationUsers } = useOperationUsers();

  if (!operation || !fieldGeometry || !operationUsers) {
    return (
      <Center h="90vh">
        <Loader />
      </Center>
    );
  }

  return <ZoneAnalysis />;
};

export default ZoneAnalysisContainerV2;
